import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableContextMenu,
  Icon,
  Switch,
} from '@jll-labs/azara-ui-components';
import {
  useUpdateUserTenantRegularSettings,
  useGetUserDefaultTenantId,
  useGetUserAlertRuleSettings,
  UserAlertRuleType,
  useGetTenant,
  useCheckFeatureFullAccess,
} from '@apollo-red/hooks';
import { ContentContainer } from '@components/pageContainer';
import NoData from '@components/noData';
import SettingsPageWrapper from '../pageWrapper';
import {
  constants,
  paramToAlertNameTranslation,
} from './AlertSettings.constants';
import { SelectedCategoriesCell } from './alertCategories';
import { findRule } from './AlertSettings.utils';
import RuleDialog from './RuleDialog';
import {
  RoleMenuItems,
  RoleOtherSubMenuItems,
} from '@components/pageContainer/SideBar/Navigation/navHelpers.types';
import { useLaunchDarklyUserAlertSettings, filterAlertTypes } from './utils';

const AlertSettings: React.FC = () => {
  const { t } = useTranslation();
  const tenantId = useGetUserDefaultTenantId();
  const { loading } = useGetTenant(tenantId);
  const { updateUserTenantRegularSettings } =
    useUpdateUserTenantRegularSettings();
  const { rules } = useGetUserAlertRuleSettings();
  const [open, setOpen] = React.useState(false);
  const [selectedRule, setSelectedRule] = React.useState<
    UserAlertRuleType | undefined
  >();
  const { isFullAccess } = useCheckFeatureFullAccess(
    RoleMenuItems.Settings,
    RoleOtherSubMenuItems.AlertSettings,
  );
  const launchDarklyAlertSettings = useLaunchDarklyUserAlertSettings();

  const handleToggleStatus = (alert_enabled, alertType) => {
    const foundRule = findRule(rules, alertType);
    if (foundRule) {
      updateUserTenantRegularSettings(tenantId, {
        user_alert_rule_builder_settings: rules.map(rule => {
          if (rule.alert_type === alertType) {
            return {
              ...rule,
              alert_enabled,
            };
          }
          return rule;
        }),
      });
    }
  };

  const handleEditClick = (id: UserAlertRuleType) => {
    setSelectedRule(id);
    setOpen(true);
  };

  return (
    <SettingsPageWrapper breadcrumbsParts={[{ label: t('alertSettings') }]}>
      <ContentContainer
        title={t('alertSettings')}
        autoOverflow={true}
        loading={loading}
      >
        {rules.length === 0 ? (
          <NoData message="noDataAvailable" />
        ) : (
          <Table
            contextual={true}
            header={{ cells: constants.HEADERS }}
            rows={rules
              .map(item => {
                if (
                  !filterAlertTypes(item.alert_type, launchDarklyAlertSettings)
                ) {
                  return { cells: [] };
                }
                return {
                  cells: [
                    <TableContextMenu
                      key={item.alert_type}
                      id={item.alert_type}
                      disabled={
                        !isFullAccess ||
                        item.alert_type ===
                          UserAlertRuleType.OccupancyEfficiency ||
                        item.alert_type === UserAlertRuleType.SubleaseRisk ||
                        item.alert_type ===
                          UserAlertRuleType.SameDayWorkOrderCompletion
                      }
                      buttons={[
                        {
                          label: t('edit'),
                          icon: <Icon name="Edit" fontSize="inherit" />,
                          onClick: handleEditClick,
                        },
                      ]}
                    />,
                    t(paramToAlertNameTranslation[item.alert_type]),
                    <SelectedCategoriesCell
                      key="internal"
                      selectedRule={item}
                      type="internal"
                    />,
                    <SelectedCategoriesCell
                      key="external"
                      selectedRule={item}
                      type="external"
                    />,
                    <Switch
                      key={item.alert_type}
                      disabled={!isFullAccess}
                      onChange={(_, alertStatus) =>
                        handleToggleStatus(alertStatus, item.alert_type)
                      }
                      checked={item.alert_enabled}
                      label={item.alert_type}
                    />,
                  ],
                };
              })
              .filter(r => r?.cells?.length > 0)}
          />
        )}
        <RuleDialog
          isOpen={open}
          currentUserRules={rules}
          editId={selectedRule}
          setOpen={setOpen}
        />
      </ContentContainer>
    </SettingsPageWrapper>
  );
};

export default AlertSettings;
