import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Theme, useTheme } from '@mui/material';
import BrandedTopBar from '@components/brandedTopBar';
import { useLogin } from '@components/okta';
import { SystemStyleObject } from '@mui/system';

const useStyles = (): Record<'loginBtn', SystemStyleObject<Theme>> => {
  const theme = useTheme();
  return {
    loginBtn: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

const TopBar = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { login } = useLogin();

  const handleClick = () => {
    login();
  };

  return (
    <BrandedTopBar
      toolbarRightSideItems={
        <Button
          data-testid="login-btn"
          sx={styles.loginBtn}
          disableElevation={true}
          color="primary"
          variant="contained"
          onClick={handleClick}
        >
          {t('logIn')}
        </Button>
      }
    />
  );
};

export default TopBar;
