import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';
import {
  TableCellText,
  utils,
  TableRow,
  Icon,
  InfiniteScrollingTable,
} from '@jll-labs/azara-ui-components';
import {
  InsightsAlertUserHistoryActionPerformed,
  useGetInsightsAlertUserHistory,
} from '@apollo-red/hooks';
import { AlertNoDataTableRow, AlertLoadingTableRow } from '../alertTableRows';
import { alertActionToComment, severityConfig } from '../utils';

const { getFormattedDate } = utils;

interface Props {
  threadId: string;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
  },
  comment: {
    maxWidth: '10vw',
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  collapsibleContent: {
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const headers = ['Date', 'Name', 'Activities Performed', 'Comment'];

const shouldShowCollapsibleContent = content => {
  return content && content.split(' ').length > 3;
};

const AlertUserHistory: React.FC<Props> = ({ threadId }) => {
  const { userHistory, loading, loadMore, hasMore } =
    useGetInsightsAlertUserHistory(threadId);

  const [isFetchingMore, setIsFetchingMore] = React.useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const hasData = (userHistory && userHistory?.length > 0) ?? false;

  React.useEffect(() => {
    if (!isFetchingMore) {
      return;
    }

    setIsFetchingMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHistory?.length]);

  const handleLoadMore = () => {
    if (isFetchingMore) {
      return;
    }

    setIsFetchingMore(true);
    loadMore();
  };

  const rows: TableRow[] =
    userHistory?.map(h => {
      const severityIcon = severityConfig.find(
        s => s.value === h.comment,
      )?.icon;
      return {
        cells: [
          <TableCellText key="userName">{h.user_name}</TableCellText>,
          <TableCellText key="actionPerformed">
            {alertActionToComment[h.action_performed]}
          </TableCellText>,
          <TableCellText key="comment">
            <div className={classes.comment}>
              {h.action_performed ===
              InsightsAlertUserHistoryActionPerformed.SeverityUpdated
                ? severityIcon && <Icon name={severityIcon} />
                : h.comment}
            </div>
          </TableCellText>,
        ],
        collapsibleContent: h.action_performed !==
          InsightsAlertUserHistoryActionPerformed.SeverityUpdated &&
          shouldShowCollapsibleContent(h.comment) && (
            <div className={classes.collapsibleContent}>{h.comment}</div>
          ),
        collapsibleLabel: (
          <div>
            {getFormattedDate(
              new Date(parseInt(h.commented_at, 10)),
              undefined,
              'LLL d',
            )}
          </div>
        ),
      };
    }) || [];

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('userHistory')}</div>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{
          height: '30vh',
        }}
        renderView={props => (
          <div
            {...props}
            style={{ ...props.style, overflowX: 'hidden', height: '100%' }}
          />
        )}
      >
        <InfiniteScrollingTable
          isLoading={!hasData && loading}
          hasData={hasData}
          hasMore={hasMore}
          isFetchingMore={isFetchingMore}
          onScrollEnd={handleLoadMore}
          header={{ cells: headers }}
          rows={rows}
          collapsible={true}
          collapsibleWithLabel={true}
          variant="simple"
          tableComponent="div"
          noDataComponent={<AlertNoDataTableRow />}
          loaderComponent={<AlertLoadingTableRow />}
          rowRenderOffset={7}
        />
      </Scrollbars>
    </div>
  );
};

export default AlertUserHistory;
