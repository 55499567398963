import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useTheme } from '@mui/material';
import {
  MenuSelect,
  TableHeaderText,
  UserAvatarIcon,
  SelectOption,
  Icon,
} from '@jll-labs/azara-ui-components';
import {
  InsightsAlertTypeFilter,
  InsightsAlertImportanceFilter,
  InsightsAlertSeverityFilter,
  useGetAllNonInternalUsers,
  useGetUserDefaultTenantId,
  InsightsAlertSeverity,
  InsightsAlertSortOrder,
} from '@apollo-red/hooks';
import {
  AlertImportancePickListOption,
  importanceOptionsConfig,
  AlertTypePickListOption,
  useAlertTypeOptionsConfig,
  useGetSeverityOptions,
  AlertSelectSeverity,
  AlertSelectUser,
} from '../alertBodyComponents';
import {
  alertSeverityFilterToInsightsAlertSeverity,
  alertTypeFilterToInsightsAlertTitleTranslation,
} from '../utils';

interface AlertTableHeaderRowProps {
  selectedAlertTypeFilter: InsightsAlertTypeFilter;
  selectedAlertImportanceFilter: InsightsAlertImportanceFilter;
  selectedAlertSeverityFilter: InsightsAlertSeverityFilter;
  selectedAlertAssignedToFilter: string;
  selectedSortOrders: InsightsAlertSortOrder;
  onImportanceFilterChange: (selectedOption: any) => void;
  onTypeFilterChange: (selectedOption: any) => void;
  onSeverityFilterChange: (selectedOption: any) => void;
  onAssignedUserFilterChange: (selectedOption: any) => void;
  onDateSortChange: () => void;
}

const useStyles = () => {
  const theme = useTheme();

  return {
    root: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    userName: {
      marginLeft: theme.spacing(1),
    },
    dateHeader: {
      marginRight: theme.spacing(-1),
      cursor: 'pointer',
      textAlign: 'center',
    },
    label: {
      padding: theme.spacing(0, 1),
    },
    icon: {
      minWidth: '20%',
    },
  };
};

export const useAlertTableHeader = ({
  onImportanceFilterChange,
  onTypeFilterChange,
  onDateSortChange,
  selectedAlertTypeFilter,
  selectedAlertImportanceFilter,
  selectedAlertAssignedToFilter,
  selectedAlertSeverityFilter,
  selectedSortOrders,
  onSeverityFilterChange,
  onAssignedUserFilterChange,
}: AlertTableHeaderRowProps) => {
  const defaultTenantId = useGetUserDefaultTenantId();
  const { users } = useGetAllNonInternalUsers(defaultTenantId);
  const styles = useStyles();
  const { severityOptions } = useGetSeverityOptions();
  const { t } = useTranslation();

  const importanceOptions = importanceOptionsConfig.map(
    ({ value, label, icon }) => ({
      value,
      label: (
        <AlertImportancePickListOption
          icon={icon}
          value={value}
          label={label}
        />
      ),
    }),
  );

  const alertTypeOptionsConfig = useAlertTypeOptionsConfig();

  const alertTypeOptions = alertTypeOptionsConfig.map(({ value, label }) => ({
    value,
    label: <AlertTypePickListOption label={label} />,
  }));

  const importanceFilter = (
    <TableHeaderText>
      <MenuSelect
        options={importanceOptions}
        value={selectedAlertImportanceFilter}
        label={<div style={{ marginRight: '-8px' }}>Imp.</div>}
        size="sm"
        onChange={onImportanceFilterChange}
      />
    </TableHeaderText>
  );

  const alertType = (
    <MenuSelect
      options={alertTypeOptions}
      value={selectedAlertTypeFilter}
      label={
        <div style={{ marginRight: '-8px' }}>
          {t(
            alertTypeFilterToInsightsAlertTitleTranslation[
              selectedAlertTypeFilter
            ],
          )}
        </div>
      }
      size="sm"
      onChange={onTypeFilterChange}
    />
  );

  const dateSortOptions = [
    {
      label: 'Ascending',
      value: InsightsAlertSortOrder.Asc,
    },
    {
      label: 'Descending',
      value: InsightsAlertSortOrder.Desc,
    },
  ];

  const dateSort = (
    <MenuSelect
      options={dateSortOptions}
      value={selectedSortOrders}
      label={<Box sx={styles.dateHeader}>{'Date'}</Box>}
      size="sm"
      onChange={onDateSortChange}
    />
  );

  const allSeverityFilterOption: SelectOption = {
    label: <Box sx={styles.label}>All</Box>,
    value: 'all',
  };

  const otherSeverityOptions = severityOptions.filter(
    so => so.value !== InsightsAlertSeverity.None,
  );

  const unassignedSeverityFilterOption: SelectOption = {
    label: (
      <Grid container={true} alignItems="center" justifyContent="space-between">
        <div>
          <Icon name="Flag" fontSize="inherit" />
        </div>
        <Box sx={styles.label}>Unassigned</Box>
      </Grid>
    ),
    value: InsightsAlertSeverity.None,
  };

  const severityFilterOptions = [
    allSeverityFilterOption,
    unassignedSeverityFilterOption,
    ...otherSeverityOptions,
  ];

  const alertSeverity = (
    <AlertSelectSeverity
      severity={
        alertSeverityFilterToInsightsAlertSeverity[
          selectedAlertSeverityFilter
        ] || 'all'
      }
      handleSeverityChange={onSeverityFilterChange}
      changeLabel={false}
      severityOptions={severityFilterOptions}
      fontSize="small"
      isFilter
    />
  );

  const tenantUserOptions: SelectOption[] = users.map((user, index) => {
    return {
      label: (
        <Grid container={true} alignItems="center" key={index}>
          <UserAvatarIcon active={true} />
          <Box sx={styles.userName}>{user?.user_full_name}</Box>
        </Grid>
      ),
      value: user.id,
    };
  });

  const userOptions: SelectOption[] = [
    {
      label: <Box sx={styles.userName}>All</Box>,
      value: 'all',
    },
    {
      label: (
        <Grid container={true} alignItems="center" key={defaultTenantId}>
          <UserAvatarIcon />
          <Box sx={styles.userName}>Unassigned</Box>
        </Grid>
      ),
      value: 'unassigned',
    },
    ...tenantUserOptions,
  ];

  const alertAssignee = (
    <AlertSelectUser
      userId={selectedAlertAssignedToFilter}
      handleUserChange={onAssignedUserFilterChange}
      userOptions={userOptions}
      activeLabel={false}
      fontSize="small"
    />
  );

  return {
    dateSort,
    alertType,
    importanceFilter,
    alertSeverity,
    alertAssignee,
  };
};
