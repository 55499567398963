import * as React from 'react';
import { Scroll } from '@jll-labs/azara-ui-components';
import { useRoutes } from '@routes';
import { useGetUserInfo } from '@apollo-red/hooks/user';
import { styled } from '@mui/material';
import {
  useGetUserReportCategories,
  useGetUserPATReportCategories,
} from '@apollo-red/hooks';
import UserProfile from './UserProfile';
import Navigation from './Navigation';

interface Props {
  isExpanded: boolean;
  expandSidebar: () => void;
  toggleSidebar: () => void;
}

const StyledDiv = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.secondary.light,
}));

const SideBar: React.FC<Props> = ({
  toggleSidebar,
  isExpanded,
  expandSidebar,
}) => {
  const routes = useRoutes();
  const sidebarRef = React.createRef<HTMLDivElement>();
  const userProfileRef = React.createRef<HTMLDivElement>();
  const [height, setHeight] = React.useState(0);
  const { user } = useGetUserInfo();
  const { categories } = useGetUserReportCategories();
  const { categories: patCategories } = useGetUserPATReportCategories();

  const handleSetSize = React.useCallback(() => {
    if (sidebarRef.current && userProfileRef.current) {
      setHeight(
        sidebarRef.current.offsetHeight - userProfileRef.current.offsetHeight,
      );
    }
  }, [sidebarRef, userProfileRef]);

  React.useEffect(() => {
    handleSetSize();
  }, [handleSetSize, sidebarRef, userProfileRef]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleSetSize);
    return () => {
      window.removeEventListener('resize', handleSetSize);
    };
  }, [handleSetSize, sidebarRef, userProfileRef]);

  return (
    <StyledDiv ref={sidebarRef}>
      <UserProfile
        ref={userProfileRef}
        toggleSidebar={toggleSidebar}
        isExpanded={isExpanded}
        fullName={user.user_full_name}
        email={user.user_email}
      />
      <Scroll maxHeight={height}>
        <Navigation
          onClick={expandSidebar}
          isSidebarExpanded={isExpanded}
          navItems={{
            dashboards: categories.map(category => ({
              id: category.id,
              label: category.category_description,
              link: routes.dashboards.category.getLink(category.id),
            })),
            PATDashboards: patCategories.map(category => ({
              id: category.id,
              label: category.category_description,
              link: routes.PATDashboards.category.getLink(category.id),
            })),
          }}
        />
      </Scroll>
    </StyledDiv>
  );
};

export default SideBar;
