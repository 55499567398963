import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@jll-labs/azara-ui-components';
import {
  ReportExtended,
  useGetUserFavReportsIds,
  useGetUserCurrentTenantReports,
  sortReportsBasedOnSortIndex,
} from '@apollo-red/hooks';
import { usePendoTrackDashboardEvent } from '@utils/pendo';
import { getDashboardEventProps } from '@components/favoriteButton/FavoriteButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxes: {
    display: 'block',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    maxHeight: '60vh',
    overflow: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2),
  },
}));

interface Props {
  useSaveHandler: (reports: ReportExtended[]) => { saveHandler: () => void };
  isPATMenu?: boolean;
}

interface ReportCheckboxes extends Props {
  reports: ReportExtended[];
  hiddenReports?: ReportExtended[];
  handleClose: () => void;
  isPATMenu: boolean;
}

const ReportsCheckboxes: React.FC<ReportCheckboxes> = ({
  handleClose,
  reports,
  hiddenReports,
  useSaveHandler,
  isPATMenu = false,
}) => {
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();
  const { favReportsIds } = useGetUserFavReportsIds();
  const [checkboxReports, setCheckboxReports] = React.useState(
    reports
      .map(report => ({
        ...report,
        checked: favReportsIds.includes(report.id),
        hidden: false,
      }))
      .concat(
        (hiddenReports ?? []).map(report => ({
          ...report,
          checked: favReportsIds.includes(report.id),
          hidden: true,
        })),
      ),
  );
  const { t } = useTranslation();
  const { saveHandler } = useSaveHandler(
    checkboxReports.filter(r => r.checked),
  );
  const classes = useStyles();

  const handleUpdate = (reportId: string) => () => {
    setCheckboxReports(currentReports =>
      currentReports.map(r => ({
        ...r,
        checked: r.id === reportId ? !r.checked : r.checked,
      })),
    );
  };

  const handleSave = () => {
    const originalFavoritedReports = checkboxReports.filter(
      report => report.isFav === true && report.isPatReport === isPATMenu,
    );
    const allFavoritedReports = checkboxReports.filter(
      report => report.checked === true && report.isPatReport === isPATMenu,
    );
    const newFavoritedReports = allFavoritedReports.filter(report => {
      return originalFavoritedReports.indexOf(report) === -1;
    });
    const newUnfavoritedReports = originalFavoritedReports.filter(report => {
      return allFavoritedReports.indexOf(report) === -1;
    });

    newFavoritedReports.forEach(report => {
      const dashboardEventProps = getDashboardEventProps(report);
      trackDashboardEvent('dashboardFavorited', dashboardEventProps);
    });

    newUnfavoritedReports.forEach(report => {
      const dashboardEventProps = getDashboardEventProps(report);
      trackDashboardEvent('dashboardUnfavorited', dashboardEventProps);
    });

    saveHandler();
    handleClose();
  };

  return (
    <div className={classes.root}>
      <div>
        {
          'To add reports to Favorites section, please select interesting dashboards from the list below.'
        }
      </div>
      <div className={classes.checkBoxes}>
        {checkboxReports
          .filter(report => !report.hidden)
          .map(report => (
            <Grid
              onClick={handleUpdate(report.id)}
              className={classes.row}
              key={report.id}
              container={true}
              alignItems="center"
            >
              <Checkbox
                checked={report.checked}
                value={report.id}
                label={report.report_name}
              />
              <div>{report.report_name}</div>
            </Grid>
          ))}
      </div>
      <div className={classes.actions}>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          {t('submit')}
        </Button>
      </div>
    </div>
  );
};

const AddReportsMenu: React.FC<Props> = ({ useSaveHandler, isPATMenu }) => {
  const [open, setOpen] = React.useState(false);
  const { allReports, patReports } = useGetUserCurrentTenantReports();
  const { t } = useTranslation();
  const sortedPATReports = sortReportsBasedOnSortIndex(patReports ?? []);
  const reports = (isPATMenu ? sortedPATReports : allReports) ?? [];
  const hiddenReports = (isPATMenu ? allReports : sortedPATReports) ?? [];

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCancel() {
    handleClose();
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClick}>
        {t('addReports')}
      </Button>
      <Dialog open={open} scroll="paper" handleClose={handleCancel}>
        <DialogTitle title={t('addReports')} handleClose={handleCancel} />
        <DialogContent>
          <ReportsCheckboxes
            isPATMenu={isPATMenu ?? false}
            reports={reports}
            hiddenReports={hiddenReports}
            useSaveHandler={useSaveHandler}
            handleClose={handleCancel}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddReportsMenu;
