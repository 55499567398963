import { useFeatureFlag } from '@utils/launchDarkly';
import { useGenerateTrustedReportUrl } from './useGenerateTrustedReportUrl';

export interface GetTrustedReportUrlArgs {
  tenantId: string;
  reportId: string;
  reportUrl: string;
}

export interface GetTrustedReportUrl {
  reportUrl?: string;
  isValidTicket: boolean;
}

export const useGetTrustedReportUrl = () => {
  const dashboardsTrustedAuthentication = useFeatureFlag(
    'dashboardsTrustedAuthentication',
  );
  const { generateTrustedReportUrl } = useGenerateTrustedReportUrl();

  const getTrustedReportUrl = async ({
    tenantId,
    reportId,
    reportUrl,
  }: GetTrustedReportUrlArgs): Promise<GetTrustedReportUrl> => {
    if (!dashboardsTrustedAuthentication) {
      return {
        reportUrl,
        isValidTicket: false,
      };
    }

    const result = await generateTrustedReportUrl({
      tenant_id: tenantId,
      report_id: reportId,
      report_url: reportUrl,
    });

    const { data } = result ?? {};
    const { generateTrustedReportUrl: urlInfo } = data ?? {};

    if (!urlInfo) {
      return {
        isValidTicket: false,
      };
    }

    return {
      reportUrl: urlInfo.report_url,
      isValidTicket: urlInfo.is_valid_ticket,
    };
  };

  return {
    getTrustedReportUrl,
  };
};
