import * as React from 'react';
import { Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@jll-labs/azara-ui-components';

interface Props {
  rightColumn: React.ReactNode;
  leftColumn: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  leftColumn: {
    textAlign: 'center',
  },
}));

const AccountWrapper: React.FC<Props> = ({ rightColumn, leftColumn }) => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container={true}>
        <Grid item={true} xs={2} className={classes.leftColumn}>
          <Box px={5} my={5}>
            {leftColumn}
          </Box>
        </Grid>
        <Grid item={true} xs={10}>
          <Box px={5} my={5}>
            {rightColumn}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AccountWrapper;
