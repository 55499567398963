import * as React from 'react';
import { format } from 'date-fns';
import {
  InsightsAlertScope,
  InsightsAlertSubleaseRiskTemplateData,
  InsightsAlertInbox,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { getInternalLink } from './utils';
import OpenAlertReport from './OpenAlertReport';
import { useAlertTextStyles } from './useAlertTextStyles';
import { useRoutes } from '@routes';

interface SubleaseRiskAlertProps {
  threadId: string;
  template: InsightsAlertSubleaseRiskTemplateData;
  boldText?: boolean;
  inboxType?: InsightsAlertInbox;
  alertType: InsightsAlertType;
}

const DATE_FORMAT = 'dd MMM yyyy';

export const SubleaseRiskAlert: React.FC<SubleaseRiskAlertProps> = ({
  threadId,
  inboxType,
  template,
  boldText,
  alertType,
}) => {
  const {
    property_name,
    notice_period,
    natural_expiry,
    termination_options,
    contraction_options,
    month_to_month_tenures,
    mainly_parking_leases,
    total_cost_obligations,
    total_liquidity_options,
    sublease_with_expiry,
    income_at_risk,
  } = template;
  const classes = useAlertTextStyles({ boldText });
  const routes = useRoutes();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  return (
    <OpenAlertReport>
      <div className={classes.fontWeight}>
        You have upcoming lease tenure(s) with liquidity options or risks
        available for action at your <b>{property_name}</b> property within next{' '}
        <b>{notice_period}</b> months.
        <br />
        <br />
        {[
          natural_expiry,
          termination_options,
          contraction_options,
          month_to_month_tenures,
          mainly_parking_leases,
        ].some(item => item && item?.length > 0) && (
          <div>
            <b>Liquidity Options:</b>
            <ul>
              {natural_expiry && natural_expiry?.length > 0 && (
                <li>
                  <div>Natural Expiries:</div>
                  <ul>
                    {natural_expiry?.map((ne, index) => {
                      return (
                        <li key={index}>
                          Lease ID {ne?.tenure_id} with Expiration Date{' '}
                          {ne?.tenure_ended_on &&
                            format(ne?.tenure_ended_on, DATE_FORMAT)}
                          .
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}

              {termination_options && termination_options?.length > 0 && (
                <li>
                  <div>Termination Options:</div>
                  <ul>
                    {termination_options?.map((to, index) => {
                      return (
                        <li key={index}>
                          Lease ID {to?.tenure_id} with Termination Notice Date{' '}
                          {to?.event_notice_on &&
                            format(to?.event_notice_on, DATE_FORMAT)}
                          .
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}

              {contraction_options && contraction_options?.length > 0 && (
                <li>
                  <div>Contraction Options:</div>
                  <ul>
                    {contraction_options?.map((co, index) => {
                      return (
                        <li key={index}>
                          Lease ID {co?.tenure_id} with Contraction Option Date{' '}
                          {co?.event_notice_on &&
                            format(co?.event_notice_on, DATE_FORMAT)}
                          .
                          <ul>
                            <li>{co?.event_notes}</li>
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}

              {month_to_month_tenures && month_to_month_tenures?.length > 0 && (
                <li>
                  <div>Month to Month Tenures:</div>
                  <ul>
                    {month_to_month_tenures?.map((mom, index) => {
                      return (
                        <li key={index}>
                          Lease ID {mom?.tenure_id} with annual cost{' '}
                          {mom?.annualized_cost_usd} USD
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}

              {mainly_parking_leases && mainly_parking_leases?.length > 0 && (
                <li>
                  <div>Parking Tenures:</div>
                  <ul>
                    {mainly_parking_leases?.map((mpl, index) => {
                      return (
                        <li key={index}>
                          Lease ID {mpl?.tenure_id} with parking rent{' '}
                          {mpl?.annualized_cost_usd} USD
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}
              {total_liquidity_options && (
                <li>
                  Total Options: <b>{total_liquidity_options}</b>{' '}
                </li>
              )}

              {total_cost_obligations && (
                <li>
                  Total Cost Obligations: <b>{total_cost_obligations} USD</b>
                </li>
              )}
            </ul>
            <br />
          </div>
        )}
        {income_at_risk &&
          sublease_with_expiry &&
          sublease_with_expiry.length > 0 && (
            <div>
              <b>Risks:</b>
              <ul>
                <li>
                  <div>
                    Subleases:
                    <ul>
                      {sublease_with_expiry?.map((se, index) => {
                        return (
                          <li key={index}>
                            Lease ID {se?.tenure_id} which expires{' '}
                            {se?.tenure_ended_on &&
                              format(se?.tenure_ended_on, DATE_FORMAT)}{' '}
                            with annual cost {se?.annualized_cost_usd} USD
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
                <li>
                  Income at Risk: <b>{income_at_risk} USD</b>
                </li>
              </ul>
            </div>
          )}
        {inboxType && (
          <>
            See notification details{' '}
            <a
              onClick={() => {
                trackInsightsAlertEvent('alertShowNotificationDetails', {
                  alertThreadId: threadId ?? '',
                  alertType: alertType ?? '',
                });
              }}
              href={getInternalLink(
                threadId,
                inboxType,
                InsightsAlertScope.Internal,
                routes,
              )}
              className={classes.anchorText}
            >
              here
            </a>
          </>
        )}
      </div>
    </OpenAlertReport>
  );
};

export default SubleaseRiskAlert;
