import { ReportControlStaticListItem } from '@apollo-red/hooks';
import { FilterExtended } from '../../types';

export const getInitialFilterAppliedValues = (
  filter: FilterExtended,
): ReportControlStaticListItem[] | undefined => {
  const { appliedValues, isAllSelected } = filter;

  if (isAllSelected || !appliedValues) {
    return;
  }

  return appliedValues.map(
    ({ formattedValue, value }) =>
      ({
        label: formattedValue ?? value,
        value: formattedValue ?? value,
      } as ReportControlStaticListItem),
  );
};
