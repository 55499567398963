import * as React from 'react';
import { InsightsAlert } from '@apollo-red/hooks';

interface UseAlertReportEffectsPayload {
  alert?: InsightsAlert;
  alertLabel?: string;
  alertsInboxLabel: string;
  alertsInboxLink: string;
  alertsLabel: string;
  closeSidebar: () => void;
  setBreadcrumbsData: (data: any) => void;
  setInsightsInboxRecentAlert: (alert: InsightsAlert) => void;
}

export const useAlertReportEffects = ({
  alert,
  alertLabel,
  alertsInboxLabel,
  alertsInboxLink,
  alertsLabel,
  closeSidebar,
  setBreadcrumbsData,
  setInsightsInboxRecentAlert,
}: UseAlertReportEffectsPayload) => {
  React.useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  React.useEffect(() => {
    if (alert) {
      setInsightsInboxRecentAlert(alert);
    }
  }, [alert, setInsightsInboxRecentAlert]);

  React.useEffect(() => {
    setBreadcrumbsData([
      alertsLabel,
      [
        { label: alertsLabel },
        {
          label: alertsInboxLabel,
          link: alertsInboxLink,
        },
        {
          label: alertLabel,
        },
      ],
    ]);
  }, [
    alert,
    alertsLabel,
    alertsInboxLabel,
    alertsInboxLink,
    alertLabel,
    setBreadcrumbsData,
  ]);
};
