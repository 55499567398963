import * as SchemaTypes from '@apollo-red/schema/generated/operations';

/* eslint-disable-next-line */
export import AreaUnit = SchemaTypes.AreaUnit;

/* eslint-disable-next-line */
export import CurrencyUnit = SchemaTypes.CurrencyUnit;

export const CURRENCY_TRANSLATION_KEYS = {
  [CurrencyUnit.Usd]: 'usd',
  [CurrencyUnit.Gbp]: 'gbp',
  [CurrencyUnit.Eur]: 'eur',
  [CurrencyUnit.Aud]: 'aud',
};

export const UNIT_OF_MEASUREMENT_TRANSLATION_KEYS = {
  [AreaUnit.Sqft]: 'sqft',
  [AreaUnit.Sqm]: 'sqm',
};

export const UNIT_OF_MEASUREMENT_TRANSLATION_KEYS_LONG = {
  [AreaUnit.Sqft]: 'squareFeet',
  [AreaUnit.Sqm]: 'squareMeters',
};

export const unitsOfMeasurement = Object.values(AreaUnit);

export const unitsOfCurrency = Object.values(CurrencyUnit);
