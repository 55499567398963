import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

type LaunchDarklyQaEnv = 'app' | 'demo' | 'qa' | '';

export interface LaunchDarklyUserCustomAttributes {
  qaEnv: LaunchDarklyQaEnv;
}

export interface LaunchDarklyUser {
  key?: string;
  name?: string;
  email?: string;
  custom?: LaunchDarklyUserCustomAttributes;
}

export const LaunchDarklyProvider = async (
  user?: Partial<LaunchDarklyUser>,
) => {
  const { key, name, email, custom } = user ?? {};

  return await asyncWithLDProvider({
    clientSideID: process.env.LAUNCHDARKLY_SDK_KEY ?? '',
    user: {
      key: key ?? 'unauthorized-user',
      name,
      email,
      custom: {
        ...custom,
      },
    },
  });
};
