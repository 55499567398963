import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Theme, useTheme } from '@mui/material';
import { utils } from '@jll-labs/azara-ui-components';
import KeyMetrics from '@components/keyMetrics';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';
import {
  PageContainerContext,
  ContentContainer,
} from '@components/pageContainer';
import { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { TableauLicenseValidator } from '@components/tableau';
import { NoDataReport } from '@components/fullReport';
import {
  Report,
  isPATApplicationEnabledForTenant,
  useGetAllTenantApplications,
  useGetUserDefaultTenantId,
} from '@apollo-red/hooks';
import { Header } from '@components/typography';
import Toolbar from './Toolbar';
import { Display } from '../types';

const { createUpBreakpoints, useSpacingStyles } = utils;

interface Props {
  breadcrumbsTitle: string;
  breadcrumbsParts: BreadcrumbsRoutePart[];
  title?: React.ReactNode;
  useSaveHandler?: (reports: Report[]) => { saveHandler: () => void };
  isPATDashboard?: boolean;
  showAddReports?: boolean;
  showKeyMetrics?: boolean;
  showToolbar?: boolean;
  drag?: boolean;
  autoOverflow?: boolean;
  children: (props: {
    displayMode: Display;
    deleteMode: boolean;
  }) => React.ReactNode;
  noRightSidePadding?: boolean;
}

const createToolbarBreakpoints =
  (
    showKeyMetrics: boolean,
    isPATDashboard?: boolean,
  ): CreateBreakpointFn<Theme> =>
  (theme, breakpoint) => {
    return {
      marginTop: !showKeyMetrics
        ? theme.spacing(5)
        : breakpoint === 'xl'
        ? theme.spacing(-3)
        : breakpoint === 'xs'
        ? theme.spacing(isPATDashboard ? 0 : 5)
        : theme.spacing(-2),
    };
  };

const createTitleBarBreakpoints =
  (showKeyMetrics: boolean): CreateBreakpointFn<Theme> =>
  (theme, breakpoint) => {
    return {
      paddingTop: !showKeyMetrics
        ? theme.spacing(5)
        : breakpoint === 'lg' || breakpoint === 'xl'
        ? theme.spacing(0)
        : theme.spacing(5),
    };
  };

interface Styles {
  showKeyMetrics: boolean;
  isPATDashboard?: boolean;
}

const useStyles = ({ showKeyMetrics, isPATDashboard }: Styles) => {
  const theme = useTheme();
  return {
    titleBar: {
      padding: theme.spacing(0, 1),
      ...createUpBreakpoints(
        ['xs', 'lg', 'xl'],
        theme,
        createTitleBarBreakpoints(showKeyMetrics),
      ),
    },
    toolbar: {
      ...createUpBreakpoints(
        ['xs', 'lg', 'xl'],
        theme,
        createToolbarBreakpoints(showKeyMetrics, isPATDashboard),
      ),
      padding: theme.spacing(0, 1, 2, 1),
    },
    title: {
      maxWidth: '100%',
    },
    keyMetrics: {
      maxWidth: '70%',
    },
  };
};

const BaseDashboardsPageWrapper: React.FC<Props> = ({
  children,
  breadcrumbsTitle,
  breadcrumbsParts,
  title,
  showKeyMetrics = true,
  showToolbar = true,
  drag,
  autoOverflow,
  useSaveHandler = () => {
    return {
      saveHandler: () => {},
    };
  },
  isPATDashboard,
  showAddReports,
  noRightSidePadding,
}) => {
  const { t } = useTranslation();
  const pageContext = React.useContext(PageContainerContext);
  const [deleteMode, setDeleteMode] = React.useState(false);
  const [displayMode, setDisplayMode] = React.useState(Display.grid);
  const styles = useStyles({ showKeyMetrics, isPATDashboard });
  const currentTenantId = useGetUserDefaultTenantId();
  const { applications } = useGetAllTenantApplications(currentTenantId);
  const isPATEnabled = isPATApplicationEnabledForTenant(applications);
  const spacing = useSpacingStyles();

  React.useEffect(() => {
    pageContext.setBreadcrumbsData([
      breadcrumbsTitle,
      [
        {
          label: isPATEnabled
            ? isPATDashboard
              ? t('legacyDashboards')
              : t('coreDashboards')
            : t('dashboards'),
        },
        ...breadcrumbsParts,
      ],
    ]);
  }, [
    breadcrumbsParts,
    breadcrumbsTitle,
    isPATDashboard,
    isPATEnabled,
    pageContext,
    t,
  ]);

  return (
    <ContentContainer
      compactPadding={true}
      autoOverflow={autoOverflow}
      drag={drag}
      fillParent={true}
      noRightSidePadding={noRightSidePadding}
    >
      {(title || showKeyMetrics) && (
        <Grid
          container={true}
          justifyContent="space-between"
          alignItems="center"
          sx={styles.titleBar}
        >
          <Box sx={styles.title}>
            <Grid container={true} alignItems="center">
              <Header>{title}</Header>
            </Grid>
          </Box>
          <Box sx={styles.keyMetrics}>
            {showKeyMetrics && <KeyMetrics noData={<NoDataReport />} />}
          </Box>
        </Grid>
      )}
      <TableauLicenseValidator height="50vh">
        {showToolbar && (
          <Box sx={styles.toolbar}>
            <Toolbar
              deleteMode={deleteMode}
              setDeleteMode={setDeleteMode}
              displayMode={displayMode}
              setDisplayMode={setDisplayMode}
              useSaveHandler={useSaveHandler}
              isPATMenu={isPATDashboard}
              showAddReports={showAddReports}
            />
          </Box>
        )}
        <Box sx={[!noRightSidePadding && spacing.pxSm]}>
          {children({ displayMode, deleteMode })}
        </Box>
      </TableauLicenseValidator>
    </ContentContainer>
  );
};

export default BaseDashboardsPageWrapper;
