import * as React from 'react';

export enum OutboundLinkType {
  GeneralLink,
  DocumentationLink,
}

interface Props {
  href: string;
  linkType?: OutboundLinkType;
}

const OutboundLink: React.FC<Props> = ({ children, href }) => {
  const childrenWithNewProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        href,
        target: '_blank',
      });
    }
  });

  return <>{childrenWithNewProps}</>;
};

export default OutboundLink;
