import { gql } from '@apollo/client';
import {
  UserBase,
  UserKpiBase,
  ReportBase,
  UserUniversalFiltersBase,
  UserTenantExtended,
  UserPersonaBase,
  UserTenantRegularSettingsBase,
  UserAgreementBase,
  UserWorkRoleBase,
  KeywordBase,
} from '@apollo-red/fragments';

export const LOGIN_USER = gql`
  mutation loginUser($user_id: ID!, $tenant_ovc_id: ID) {
    loginUser(user_id: $user_id, tenant_ovc_id: $tenant_ovc_id) {
      ...UserBase
    }
  }
  ${UserBase.fragment}
`;

export const LOGOUT_USER = gql`
  mutation logoutUser($user_id: ID!, $tenant_id: ID!) {
    logoutUser(user_id: $user_id, tenant_id: $tenant_id)
  }
`;

export const SET_INSIGHTS_INBOX_IMPORTANCE_FILTER = gql`
  mutation setInsightsInboxImportanceFilter(
    $userId: ID!
    $tenantId: ID!
    $importanceFilter: InsightsAlertImportanceFilter!
  ) {
    setInsightsInboxImportanceFilter(
      userId: $userId
      tenantId: $tenantId
      importanceFilter: $importanceFilter
    ) @client
  }
`;

export const SET_INSIGHTS_INBOX_REMOVE_RECENT_ALERT = gql`
  mutation setInsightsInboxRemoveRecentAlert(
    $userId: ID!
    $tenantId: ID!
    $inboxType: InsightsAlertInbox!
  ) {
    setInsightsInboxRemoveRecentAlert(
      userId: $userId
      tenantId: $tenantId
      inboxType: $inboxType
    ) @client
  }
`;

export const SET_INSIGHTS_INBOX_RECENT_ALERT = gql`
  mutation setInsightsInboxRecentAlert(
    $userId: ID!
    $tenantId: ID!
    $threadId: ID!
    $inboxType: InsightsAlertInbox!
  ) {
    setInsightsInboxRecentAlert(
      userId: $userId
      tenantId: $tenantId
      threadId: $threadId
      inboxType: $inboxType
    ) @client
  }
`;

export const SET_INSIGHTS_INBOX_ALERT_TYPE_FILTER = gql`
  mutation setInsightsInboxAlertTypeFilter(
    $userId: ID!
    $tenantId: ID!
    $alertTypeFilter: InsightsAlertTypeFilter!
  ) {
    setInsightsInboxAlertTypeFilter(
      userId: $userId
      tenantId: $tenantId
      alertTypeFilter: $alertTypeFilter
    ) @client
  }
`;

export const SET_INSIGHTS_INBOX_ALERT_SORT_ORDERS = gql`
  mutation setInsightsInboxAlertSortOrder(
    $userId: ID!
    $tenantId: ID!
    $createdSortOrder: InsightsAlertSortOrder!
  ) {
    setInsightsInboxAlertSortOrder(
      userId: $userId
      tenantId: $tenantId
      createdSortOrder: $createdSortOrder
    ) @client
  }
`;

export const SET_INSIGHTS_INBOX_ALERT_SEVERITY = gql`
  mutation setInsightsInboxAlertSeverity(
    $userId: ID!
    $tenantId: ID!
    $alertSeverity: InsightsAlertSeverityFilter!
  ) {
    setInsightsInboxAlertSeverity(
      userId: $userId
      tenantId: $tenantId
      alertSeverityFilter: $alertSeverity
    ) @client
  }
`;

export const SET_INSIGHTS_INBOX_USER_ASSIGNED = gql`
  mutation setInsightsInboxAlertUserAssignedTo(
    $userId: ID!
    $tenantId: ID!
    $userAssigned: ID!
  ) {
    setInsightsInboxAlertUserAssignedTo(
      userId: $userId
      tenantId: $tenantId
      userAssigned: $userAssigned
    ) @client
  }
`;

export const SYNC_USER = gql`
  mutation syncUser($where: WhereUpdateUserInput!) {
    syncUser(where: $where) {
      user_address {
        country {
          id
          country_desc
        }
        region {
          id
          region_name
        }
      }
    }
  }
`;

export const SYNC_USER_PROVISIONING = gql`
  mutation syncUserProvisioning($tenantId: ID!, $where: WhereUpdateUserInput!) {
    syncUserProvisioning(tenantId: $tenantId, where: $where)
  }
`;

export const SYNC_USER_KPI = gql`
  mutation syncUserTenantKpiData(
    $tenantId: ID!
    $tenantOvcId: String!
    $where: WhereUpdateUserInput!
  ) {
    syncUserTenantKpiData(
      tenantId: $tenantId
      tenantOvcId: $tenantOvcId
      where: $where
    ) {
      id
      user_kpi_data {
        ...UserKpiBase
      }
    }
  }
  ${UserKpiBase.fragment}
`;

export const UPDATE_USER_PHONE = gql`
  mutation updateUserPhone(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_comm {
        work
      }
    }
  }
`;

export const UPDATE_USER_PICTURE = gql`
  mutation updateUserPicture(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_picture_url
    }
  }
`;

export const UPDATE_USER_COUNTRY = gql`
  mutation updateUserCountry(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_address {
        country {
          id
          country_iso_code
          country_desc
        }
        region {
          id
          region_name
        }
      }
    }
  }
`;

export const UPDATE_USER_TENANT_UNIVERSAL_FILTERS = gql`
  mutation updateUserUniversalFilters(
    $data: UserTenantUniversalFiltersInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUserUniversalFilters(data: $data, where: $where) {
      id
      user_settings {
        universal_filters {
          ...UserUniversalFiltersBase
        }
      }
      user_kpi_data {
        ...UserKpiBase
      }
    }
  }
  ${UserUniversalFiltersBase.fragment}
  ${UserKpiBase.fragment}
`;

export const UPDATE_USER_PREFERRED_CONTACT = gql`
  mutation updateUserContact(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_settings {
        preferred_contact
      }
    }
  }
`;

export const UPDATE_USER_ALERTS = gql`
  mutation updateUserAlerts(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_settings {
        alerts {
          tenant_id
          contact_frequency
          contact_method_email
          contact_method_phone
        }
      }
    }
  }
`;

export const UPDATE_USER_WORK_ROLE = gql`
  mutation updateUserWorkRole(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_settings {
        work_roles {
          tenant_id
          work_role {
            work_area
            specialization
            role
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_RESPONSIBILITIES = gql`
  mutation updateUserResponsibilities(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_fav_reports {
        id
      }
      user_settings {
        responsibilities {
          tenant_id
          keywords {
            id
            keyword_name
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_DEFAULT_TENANT = gql`
  mutation updateUserDefaultTenant(
    $data: UpdateUserInput!
    $where: WhereUpdateUserInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      user_settings {
        default_tenant {
          ...UserTenantExtended
        }
      }
    }
  }
  ${UserTenantExtended.fragment}
`;

export const ADD_USER_CATEGORY_REPORT = gql`
  mutation addUserCategoryReport(
    $data: UserCatReportInput!
    $where: WhereUpdateUserInput!
  ) {
    addUserCategoryReport(data: $data, where: $where) {
      id
      user_settings {
        custom_categories {
          report {
            id
          }
          category_descriptions
        }
      }
    }
  }
`;

export const ADD_FAVORITE_REPORT = gql`
  mutation addUserFavoriteReport(
    $reportId: ID!
    $where: WhereUpdateUserInput!
  ) {
    addUserFavoriteReport(reportId: $reportId, where: $where) {
      id
      user_fav_reports {
        id
      }
    }
  }
`;

export const ADD_HIDDEN_REPORT = gql`
  mutation addUserHiddenReport($reportId: ID!, $where: WhereUpdateUserInput!) {
    addUserHiddenReport(reportId: $reportId, where: $where) {
      id
      user_settings {
        hidden_reports {
          id
        }
      }
    }
  }
`;

export const REMOVE_HIDDEN_REPORT = gql`
  mutation removeUserHiddenReport(
    $reportId: ID!
    $where: WhereUpdateUserInput!
  ) {
    removeUserHiddenReport(reportId: $reportId, where: $where) {
      id
      user_settings {
        hidden_reports {
          id
        }
      }
    }
  }
`;

export const REMOVE_FAVORITE_REPORT = gql`
  mutation removeUserFavoriteReport(
    $reportId: ID!
    $where: WhereUpdateUserInput!
  ) {
    removeUserFavoriteReport(reportId: $reportId, where: $where) {
      id
      user_fav_reports {
        id
      }
    }
  }
`;

export const REPLACE_FAVORITE_REPORTS = gql`
  mutation replaceUserFavoriteReports(
    $reportIds: [ID!]!
    $where: WhereUpdateUserInput!
  ) {
    replaceUserFavoriteReports(reportIds: $reportIds, where: $where) {
      id
      user_fav_reports {
        id
      }
    }
  }
`;

export const ADD_SAVED_VIEW_TO_USER = gql`
  mutation addSavedViewToUser(
    $data: CreateReportSavedViewInput!
    $where: WhereUpdateUserInput!
  ) {
    addSavedViewToUser(data: $data, where: $where) {
      saved_report {
        id
      }
      user {
        id
        user_saved_reports {
          ...ReportBase
        }
      }
    }
  }
  ${ReportBase.fragment}
`;

export const DELETE_SAVED_VIEW_FROM_USER = gql`
  mutation deleteSavedViewFromUser(
    $reportId: ID!
    $where: WhereUpdateUserInput!
  ) {
    deleteSavedViewFromUser(reportId: $reportId, where: $where) {
      id
      user_fav_reports {
        id
      }
      user_saved_reports {
        id
      }
    }
  }
`;

export const RESET_DEMO_USER = gql`
  mutation resetDemoUser($where: WhereUserTenantInput!) {
    resetDemoUser(where: $where) {
      ...UserBase
    }
  }
  ${UserBase.fragment}
`;

export const ADD_USER_PERSONA = gql`
  mutation addUserPersona($data: CreateUserPersonaInput!, $tenant_ids: [ID!]) {
    addUserPersona(data: $data) {
      id
      user_personas(tenant_ids: $tenant_ids) {
        ...UserPersonaBase
      }
    }
  }
  ${UserPersonaBase.fragment}
`;

export const DELETE_USER_PERSONA = gql`
  mutation deleteUserPersona(
    $where: WhereUpdateUserInput!
    $tenant_ids: [ID!]
  ) {
    deleteUser(where: $where) {
      id
      user_personas(tenant_ids: $tenant_ids) {
        ...UserPersonaBase
      }
    }
  }
  ${UserPersonaBase.fragment}
`;

export const UPDATE_USER_TENANT_REGULAR_SETTINGS = gql`
  mutation updateUserTenantRegularSettings(
    $where: WhereUserTenantInput!
    $data: UserTenantRegularSettingsInput!
  ) {
    updateUserTenantRegularSettings(where: $where, data: $data) {
      id
      user_tenant_regular_settings {
        ...UserTenantRegularSettingsBase
      }
    }
  }
  ${UserTenantRegularSettingsBase.fragment}
`;

export const UPDATE_USER_TENANT_DEFAULT_PROFILE = gql`
  mutation updateUserTenantDefaultProfile(
    $where: WhereUserTenantInput!
    $data: UserTenantRegularSettingsDefaultProfileInput!
  ) {
    updateUserTenantDefaultProfile(where: $where, data: $data) {
      id
      user_tenant_regular_settings {
        ...UserTenantRegularSettingsBase
      }
    }
  }
  ${UserTenantRegularSettingsBase.fragment}
`;

export const UPDATE_USER_AGREEMENT = gql`
  mutation updateUserAgreement(
    $where: WhereGetUserInput!
    $data: UserAgreementInput!
  ) {
    updateUserAgreement(where: $where, data: $data) {
      id
      user_agreement {
        ...UserAgreementBase
      }
    }
  }
  ${UserAgreementBase.fragment}
`;

export const UPSERT_USER_WORK_ROLE = gql`
  mutation upsertUserWorkRole(
    $where: WhereGetUserInput!
    $data: UserTenantWorkRoleInput!
  ) {
    upsertUserWorkRole(where: $where, data: $data) {
      id
      user_settings {
        work_roles {
          tenant_id
          work_role {
            ...UserWorkRoleBase
          }
        }

        responsibilities {
          tenant_id
          keywords {
            ...KeywordBase
          }
        }
      }
    }
  }
  ${UserWorkRoleBase.fragment}
  ${KeywordBase.fragment}
`;

export const UPDATE_USER_EXTENDED = gql`
  mutation updateUserExtended(
    $where: WhereUpdateUserInput!
    $data: UpdateUserExtendedInput!
  ) {
    updateUserExtended(where: $where, data: $data) {
      ...UserBase
    }
  }
  ${UserBase.fragment}
`;
