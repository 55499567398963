import * as React from 'react';
import { useLocalStorage } from '@utils/webAPI';

export interface AppPerformanceContextType {
  refreshReports: boolean;
}

export const AppPerformanceContext = React.createContext<
  AppPerformanceContextType | undefined
>(undefined);

export const useAppPerformance = () => {
  return React.useContext(AppPerformanceContext);
};

/**
 * Provides context for running while capturing Performance Engineering metrics.
 * Use provided context to determine if running with the intent to measure performance.
 */
const AppPerformanceContextProvider: React.FC<{}> = ({ children }) => {
  const storageName = 'jllt-azara-perf';
  const [storedValue, setValue] = useLocalStorage<
    AppPerformanceContextType | undefined
  >(storageName);

  const shouldChangeStoredValue =
    window.location.search.indexOf('refresh-reports=') !== -1;
  const refreshReports =
    window.location.search.indexOf('refresh-reports=1') !== -1;
  const disableRefreshReports =
    window.location.search.indexOf('refresh-reports=0') !== -1;

  React.useEffect(() => {
    if (!shouldChangeStoredValue) {
      return;
    }

    if (disableRefreshReports) {
      return setValue(undefined);
    }

    setValue({
      refreshReports,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshReports, disableRefreshReports]);

  return (
    <AppPerformanceContext.Provider value={storedValue}>
      {children}
    </AppPerformanceContext.Provider>
  );
};

export default AppPerformanceContextProvider;
