import * as React from 'react';
import * as Yup from 'yup';
import {
  FormSubmit,
  FormDialog,
  FormSection,
  FormRow,
  FormTextField,
} from '@jll-labs/azara-ui-components';
import { useAddUserPersona } from '@apollo-red/hooks';

const schema = Yup.object().shape({
  name: Yup.string().required('Required field'),
  description: Yup.string(),
});

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  name: string;
  description: string;
}

const initialValues: FormValues = {
  description: '',
  name: '',
};

export const AddPersona: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { addUserPersona } = useAddUserPersona();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit: FormSubmit<FormValues> = async values => {
    await addUserPersona(values.name, values.description);
  };

  return (
    <FormDialog
      open={isOpen}
      onClose={handleClose}
      title={'Add User Persona'}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      size="medium"
    >
      <FormSection title="Info">
        <FormRow>
          <FormTextField name="name" required={true} label="Persona Name" />
        </FormRow>
        <FormRow>
          <FormTextField
            name="description"
            label="Persona Description"
            type="textarea"
            textAreaProps={{
              minRows: 4,
            }}
          />
        </FormRow>
      </FormSection>
    </FormDialog>
  );
};
