import { getBaseUrl } from './utils';

const baseUrl = getBaseUrl();

export const main = {
  landing: {
    path: baseUrl,
    getLink: () => baseUrl,
  },
  notFound: {
    path: `${baseUrl}not-found`,
    getLink: () => `${baseUrl}not-found`,
  },
  documentation: {
    path: `${baseUrl}documentation`,
    getLink: () => `${baseUrl}documentation`,
  },
  home: {
    path: `${baseUrl}home`,
    getLink: () => `${baseUrl}home`,
  },
  client: {
    path: `${baseUrl}:tenantOvcId`,
    getLink: (tenantOvcId?: string) => `${baseUrl}${tenantOvcId ?? 'default'}`,
  },
  legacyClient: {
    path: `${baseUrl}client`,
    getLink: () => `${baseUrl}client`,
  },
  maintenance: {
    path: `${baseUrl}maintenance`,
    getLink: () => `${baseUrl}maintenance`,
  },
  networkError: {
    path: `${baseUrl}connection-error`,
    getLink: () => `${baseUrl}connection-error`,
  },
  callback: {
    path: `${baseUrl}callback`,
    getLink: () => `${baseUrl}callback`,
  },
};
