import { gql } from '@apollo/client';

export const WorkAreaBase = {
  fragment: gql`
    fragment WorkAreaBase on WorkArea {
      id
      name
      specializations {
        name
        roles {
          name
        }
      }
    }
  `,
};
