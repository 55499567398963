import { useMemo } from 'react';
import { useGetUserDefaultTenant } from '@apollo-red/hooks';
import {
  useClientRoutes,
  useClientSettingsRoutes,
  useConfigDataSetupTenantAdminRoutes,
  useCustomReportsTenantAdminRoutes,
  useDashboardManagementTenantAdminRoutes,
  useDashboardsRoutes,
  useDataLoadsDataManagementRoutes,
  useDataManagementRoutes,
  useDataStandardsDataManagementRoutes,
  useInsightsAlertsRoutes,
  useJllPortscapeRoutes,
  useKeywordsTenantAdminRoutes,
  useOnboardingRoutes,
  usePATDashboardsRoutes,
  useProductSettingsRoutes,
  useSelfServeAnalyticsRoutes,
  useSettingsRoutes,
  useTenantProductSettingsRoutes,
} from './client';
import { useDocumentationRoutes } from './documentation';

export const useRoutes = () => {
  const documentation = useDocumentationRoutes();
  const { defaultTenant } = useGetUserDefaultTenant();
  const client = useClientRoutes(defaultTenant?.tenant_ovc_id);
  const onBoarding = useOnboardingRoutes(client);
  const dashboards = useDashboardsRoutes(client);
  const PATDashboards = usePATDashboardsRoutes(client);
  const insightsAlerts = useInsightsAlertsRoutes(client);
  const settings = useSettingsRoutes(client);
  const clientSettings = useClientSettingsRoutes(client);
  const customReportsTenantAdmin =
    useCustomReportsTenantAdminRoutes(clientSettings);
  const configDataSetupTenantAdmin =
    useConfigDataSetupTenantAdminRoutes(clientSettings);
  const keywordsTenantAdmin = useKeywordsTenantAdminRoutes(clientSettings);
  const dashboardManagementTenantAdmin =
    useDashboardManagementTenantAdminRoutes(clientSettings);
  const productSettings = useProductSettingsRoutes(client);
  const tenantProductSettings = useTenantProductSettingsRoutes(productSettings);
  const dataManagement = useDataManagementRoutes(client);
  const dataStandardsDataManagement =
    useDataStandardsDataManagementRoutes(dataManagement);
  const dataLoadsDataManagement =
    useDataLoadsDataManagementRoutes(dataManagement);
  const jllPortscape = useJllPortscapeRoutes(client);
  const selfServeAnalytics = useSelfServeAnalyticsRoutes(client);

  return useMemo(
    () => ({
      client,
      clientSettings,
      configDataSetupTenantAdmin,
      customReportsTenantAdmin,
      dashboardManagementTenantAdmin,
      dashboards,
      dataLoadsDataManagement,
      dataManagement,
      dataStandardsDataManagement,
      documentation,
      insightsAlerts,
      jllPortscape,
      keywordsTenantAdmin,
      onBoarding,
      productSettings,
      selfServeAnalytics,
      settings,
      tenantProductSettings,
      PATDashboards,
    }),
    [
      client,
      clientSettings,
      configDataSetupTenantAdmin,
      customReportsTenantAdmin,
      dashboardManagementTenantAdmin,
      dashboards,
      dataLoadsDataManagement,
      dataManagement,
      dataStandardsDataManagement,
      documentation,
      insightsAlerts,
      jllPortscape,
      keywordsTenantAdmin,
      onBoarding,
      productSettings,
      selfServeAnalytics,
      settings,
      tenantProductSettings,
      PATDashboards,
    ],
  );
};
