import * as React from 'react';
import {
  Grid,
  LinearProgress,
  Divider,
  Theme,
  useTheme,
  Box,
  SxProps,
} from '@mui/material';
import { utils } from '@jll-labs/azara-ui-components';
import { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { AppTheme } from '@theme';
import { Header } from '@components/typography';
import { SystemStyleObject } from '@mui/system';

const { ScrollContext, useScroll, createUpBreakpoints, getSx } = utils;

export const createContentContainerBreakpoints =
  (
    compactPadding?: boolean,
    fillParent?: boolean,
  ): CreateBreakpointFn<AppTheme> =>
  (theme, breakpoint) => {
    return {
      padding: fillParent
        ? theme.spacing(0)
        : breakpoint === 'xl'
        ? theme.spacing(compactPadding ? 0 : 3, 1, 3, 1)
        : theme.spacing(3, 1, 1, 1),
      height: `calc(100vh - ${theme.topBar.height[breakpoint]})`,
    };
  };

interface StyleProps {
  autoOverflow?: boolean;
  compactPadding?: boolean;
  fillParent?: boolean;
  noRightSidePadding?: boolean;
}

const useStyles = ({
  autoOverflow,
  compactPadding,
  fillParent,
  noRightSidePadding,
}: StyleProps): Record<
  | 'root'
  | 'content'
  | 'bottomContent'
  | 'toolbar'
  | 'divider'
  | 'autoOverflow'
  | 'fullHeight',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  const rightSidePadding = noRightSidePadding ? 0 : 6;

  return {
    root: {
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createContentContainerBreakpoints(compactPadding, fillParent),
      ),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.secondary.light,
    },
    content: {
      padding: autoOverflow
        ? theme.spacing(
            fillParent ? 0 : 1,
            rightSidePadding,
            fillParent ? 0 : 0.5,
            6,
          )
        : theme.spacing(
            fillParent ? 0 : 1,
            rightSidePadding,
            fillParent ? 0 : 1,
            6,
          ),
    },
    bottomContent: {
      margin: theme.spacing(2, 6, 1, 6),
    },
    toolbar: {
      flexBasis: '10%',
      padding: theme.spacing(0, 6),
      flexShrink: 0,
    },
    divider: {
      margin: theme.spacing(0, 2),
    },
    autoOverflow: {
      overflow: 'auto',
      flexGrow: 1,
    },
    fullHeight: {
      height: '100%',
    },
  };
};
interface Props {
  title?: string;
  titleSecondary?: React.ReactNode;
  loading?: boolean;
  autoOverflow?: boolean;
  drag?: boolean;
  compactPadding?: boolean;
  fillParent?: boolean;
  bottomContent?: React.ReactNode;
  rightSideContent?: React.ReactNode;
  noRightSidePadding?: boolean;
  fullHeight?: boolean;
  bottomContentSx?: SxProps<Theme>;
  rootSx?: SxProps<Theme>;
}

export const ContentContainer: React.FC<Props> = ({
  children,
  title,
  titleSecondary,
  autoOverflow,
  bottomContent,
  rightSideContent,
  loading,
  compactPadding,
  drag,
  fillParent,
  noRightSidePadding,
  fullHeight,
  bottomContentSx,
  rootSx,
}) => {
  const styles = useStyles({
    autoOverflow,
    compactPadding,
    fillParent,
    noRightSidePadding,
  });
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    addEventListenerForScroll,
    removeEventListenerForScroll,
    resetScrollTop,
  } = useScroll(ref);

  return (
    <div>
      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box sx={[styles.root, ...getSx(rootSx)]}>
          {(title || titleSecondary || rightSideContent) && (
            <Grid
              container={true}
              justifyContent="space-between"
              alignItems="center"
              sx={styles.toolbar}
            >
              <div>
                <Grid container={true} alignItems="center">
                  {title && <Header>{title}</Header>}
                  {titleSecondary && (
                    <>
                      <Divider
                        sx={styles.divider}
                        orientation="vertical"
                        flexItem
                      />
                      {titleSecondary}
                    </>
                  )}
                </Grid>
              </div>
              {rightSideContent}
            </Grid>
          )}
          {bottomContent && (
            <Box sx={[styles.bottomContent, ...getSx(bottomContentSx)]}>
              {bottomContent}
            </Box>
          )}
          {drag ? (
            <ScrollContext.Provider
              value={{
                addScrollListener: addEventListenerForScroll,
                removeScrollListener: removeEventListenerForScroll,
                resetScrollTop: resetScrollTop,
              }}
            >
              <Box
                ref={ref}
                sx={[
                  styles.content,
                  ...getSx(autoOverflow && styles.autoOverflow),
                  ...getSx(fullHeight && styles.fullHeight),
                ]}
              >
                {children}
              </Box>
            </ScrollContext.Provider>
          ) : (
            <Box
              sx={[
                styles.content,
                ...getSx(autoOverflow && styles.autoOverflow),
                ...getSx(fullHeight && styles.fullHeight),
              ]}
            >
              {children}
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default ContentContainer;
