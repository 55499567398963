import React from 'react';
import { usePendoTrackLocationFilters } from '@utils/pendo';
import { FormValues } from './useUniversalFiltersForm';
import { trackLocation } from './utils';

export const useUniversalFiltersTrackLocationEvent = () => {
  const { trackLocationFiltersEvent } = usePendoTrackLocationFilters();

  const trackLocationEvent = React.useCallback(
    (initialValues: FormValues, newValues: FormValues) => {
      const {
        isLocationSelected,
        isLocationUnselected,
        isLocationEdited,
        metadata,
      } = trackLocation(initialValues, newValues);

      if (isLocationSelected) {
        trackLocationFiltersEvent('locationFiltersSelected', metadata);
      } else if (isLocationUnselected) {
        trackLocationFiltersEvent('locationFiltersUnselected', metadata);
      } else if (isLocationEdited) {
        trackLocationFiltersEvent('locationFiltersEdited', metadata);
      }
    },
    [trackLocationFiltersEvent],
  );

  return {
    trackLocationEvent,
  };
};
