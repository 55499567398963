import * as React from 'react';
import { useHistory, RouteComponentProps } from 'react-router';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import resolvers from '@apollo-red/resolvers';
import introspectionQueryResultData from '@apollo-red/schema/generated/fragments';
import { useAuth } from '@components/okta';
import { main } from '@routes';
import { getApiBaseUrl } from '@utils/apollo';
import { ErrorType } from '@utils/error';
import { typePolicies } from './typePolicies/typePolicies';

const possibleTypes = introspectionQueryResultData.possibleTypes;

interface Props {
  idToken?: string;
  history: RouteComponentProps['history'];
}

/**
 * Wrapper component for ApolloProvider.
 * Enables usage of various contexts, e.g. react-router's history, Okta id token.
 * Apollo client and Apollo links should be defined as class properties in order to avoid re-creating them with each render.
 */
class AzaraApolloProvider extends React.Component<Props> {
  /**
   * Sets authorization header.
   */
  authLink = new ApolloLink((operation, forward) => {
    const { idToken } = this.props;

    if (idToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      });
    }

    return forward(operation);
  });

  /**
   * Redirects to fallback page in case of the following issues:
   * - maintenance error
   */
  errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions) {
      switch (graphQLErrors[0].extensions.code) {
        case ErrorType.Maintenance: {
          const { start = '', end = '' } = graphQLErrors[0].extensions;
          return this.props.history.push({
            pathname: main.maintenance.getLink(),
            state: { start, end },
          });
        }
      }
    }
  });

  /**
   * Sets endpoint for Apollo server.
   */
  httpLink = new HttpLink({
    uri: getApiBaseUrl(process.env.APOLLO_URI_API_PATH),
  });

  client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: typePolicies,
    }),
    // @ts-ignore
    resolvers,
    // @ts-ignore
    link: from([this.authLink, this.errorLink, this.httpLink]),
    assumeImmutableResults: true,
    name: 'azara-ui',
    version: process.env.UI_VERSION || process.env.UI_BUILD_UTC,
  });

  render() {
    return (
      <ApolloProvider client={this.client}>
        {this.props.children}
      </ApolloProvider>
    );
  }
}

const AzaraApolloProviderWithContext: React.FC = ({ children }) => {
  const { authState } = useAuth();
  const history = useHistory();

  return (
    <AzaraApolloProvider
      history={history}
      idToken={authState?.idToken?.idToken}
    >
      {children}
    </AzaraApolloProvider>
  );
};

export default AzaraApolloProviderWithContext;
