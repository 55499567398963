import * as React from 'react';
import { useGetTrustedExternalReportUrl } from '@apollo-red/hooks';
import { isTestEnv } from '@components/featureToggle';
import { isTableauHostedReport } from './isTableauHostedReport';
import { parseTargetTableauSite } from './parseTargetTableauSite';

export interface TableauUrlWithTrustedAuthExternalReportProps {
  tenantId: string;
  reportId: string;
  url: string;
  disableTrustedAuth?: boolean;
  skip?: boolean;
}

export const useTableauUrlWithTrustedAuthExternalReport = ({
  tenantId,
  reportId,
  url,
  disableTrustedAuth,
  skip,
}: TableauUrlWithTrustedAuthExternalReportProps) => {
  const [isTrustedAuthViewUrlGenerated, setIsTrustedAuthViewUrlGenerated] =
    React.useState<boolean>(false);
  const [viewUrl, setViewUrl] = React.useState<string>(url);
  const { getTrustedExternalReportUrl } = useGetTrustedExternalReportUrl();
  const trustedAuthDisabled =
    disableTrustedAuth ||
    (isTestEnv() && process.env.TABLEAU_TRUSTED_AUTH === 'false');
  const isTableauReport = isTableauHostedReport(url);
  const targetSiteId = parseTargetTableauSite(url, true);

  React.useEffect(() => {
    const getTrustedAuthViewUrl = async () => {
      const trustedReportInfo = await getTrustedExternalReportUrl({
        tenantId,
        reportId,
        reportUrl: viewUrl,
      });

      const { reportUrl } = trustedReportInfo ?? {};

      if (reportUrl) {
        setViewUrl(reportUrl);
        setIsTrustedAuthViewUrlGenerated(true);
      }
    };

    if (trustedAuthDisabled || !viewUrl || !isTableauReport || !targetSiteId) {
      setIsTrustedAuthViewUrlGenerated(true);
    } else {
      if (
        !isTrustedAuthViewUrlGenerated &&
        viewUrl.indexOf('/trusted/') === -1 &&
        !skip
      ) {
        getTrustedAuthViewUrl();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trustedAuthDisabled,
    isTrustedAuthViewUrlGenerated,
    viewUrl,
    skip,
    isTableauReport,
    targetSiteId,
  ]);

  if (!isTrustedAuthViewUrlGenerated) {
    return;
  }

  return viewUrl;
};
