import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ThumbnailError from './ThumbnailError';

const NotFoundThumbnailError: React.FC = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () => (
      <ThumbnailError
        icon="IssueError"
        title={t('thumbnailError')}
        message={
          <Trans i18nKey={'thumbnailMissingURLError'}>
            {t('thumbnailMissingURLError')}
          </Trans>
        }
      />
    ),
    [t],
  );
};

export default NotFoundThumbnailError;
