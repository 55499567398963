import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  InsightsAlert,
  InsightsAlertWorkOrderVolumeTemplateData,
  InsightsAlertScope,
  InsightsAlertType,
  InsightsAlertInbox,
} from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import { getAlertText } from '../alertText';

interface Props {
  alert: InsightsAlert;
  inboxType: InsightsAlertInbox;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    '& a': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

/**
 * TODO Confirm Click through functionality with PM
 */
const AlertBody: React.FC<Props> = ({ alert, inboxType }) => {
  const classes = useStyles();

  if (!alert) {
    return null;
  }

  const { thread_id, alert_type, template_data } = alert;
  const alertScope =
    alert.alert_type === InsightsAlertType.WorkorderVolume
      ? (alert.template_data as InsightsAlertWorkOrderVolumeTemplateData)
          ?.alert_scope
      : undefined;

  return (
    <div className={classes.root}>
      {getAlertText({
        threadId: thread_id,
        alertType: alert_type,
        templateData: template_data,
        alertScope: alertScope as InsightsAlertScope,
        boldText: true,
        inboxType,
        stripHtmlTags: false,
      })}
    </div>
  );
};

export default AlertBody;
