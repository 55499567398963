import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';

interface StyleProps {
  hasLastUpdateDate: boolean;
}

export const useStyles = makeStyles((theme: AppTheme) => ({
  container: ({ hasLastUpdateDate }: StyleProps) => ({
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle2.fontSize,
    opacity: hasLastUpdateDate ? '1' : '0',
    paddingRight: theme.spacing(2),
    textAlign: 'right',
    transition: 'opacity 0.25s ease-in',
    whiteSpace: 'nowrap',
  }),
  label: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  dateValue: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
