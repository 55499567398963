import { translations } from '@jll-labs/azara-ui-components';
import { Translation } from '../types';

const uiComponentsTranslations = translations.en.en.translations;

const en: Translation = {
  en: {
    translations: {
      ...uiComponentsTranslations,
      about: 'About',
      accessInfo: 'Access Info',
      accountSettings: 'Account Settings',
      adGroups: 'AD Groups',
      addReports: 'Add dashboards',
      addToFavorites: 'Add dashboards to favorites',
      alerts: 'Alerts',
      userAlertCategories: 'Categories',
      alertSettings: 'Alert Settings',
      all: 'All',
      allAlerts: 'All',
      allDashboards: 'All Dashboards',
      allCoreDashboards: 'All Core Dashboards',
      allPATDashboards: 'All Legacy Dashboards',
      allMappings: 'All Mappings',
      application: 'Application',
      applications: 'Applications',
      applicationClientId: 'Application Client Id',
      authorizationError: 'Authorization Error',
      aud: 'AUD',
      browse: 'Browse',
      byGeography: 'By Geography',
      categories: 'Categories',
      category: 'Category',
      changesSaved: 'Your changes have been saved',
      changesSavedError: 'Ooops, an error has occurred.',
      chooseClient: 'Choose Client',
      chooseOne: 'Choose One',
      city: 'City',
      clientAdGroups: 'Client AD Groups',
      clientAdGroup: 'Client AD Group',
      clients: 'Clients',
      clientSettings: 'Client Settings',
      close: 'Close',
      closed: 'Closed',
      completed: 'Completed',
      confirm: 'Confirm',
      configDataSetup: 'Config Data Setup',
      country: 'Country',
      covidWorkOrderAlerts: 'Disaster Recovery Work Order Alerts',
      covidWorkOrder: 'Disaster Recovery Work Order',
      created: 'Created',
      createdDateToPickedUpDate: 'Created Date to Picked Up Date',
      custom: 'Custom',
      customReport: 'Custom Report',
      customReports: 'Custom Reports',
      customReportsLocation: 'Location for publishing custom reports',
      customReportsPublishingNote:
        'Custom reports can be published in the ‘Custom Unpublished’ project at the given location.',
      currency: 'Currency',
      currentPersona: 'Current Persona',
      dashboard: 'Dashboard',
      dashboards: 'Dashboards',
      coreDashboards: 'Core Dashboards',
      legacyDashboards: 'Legacy Dashboards',
      databaseName: 'Database name',
      dataGovernance: 'Data Governance',
      dataLoads: 'Data Loads',
      dataModelling: 'Data Modelling',
      dataShareCreated: 'Data share successfully created',
      dataShareCreatedPoint0:
        'You may now import the shared data to your Snowflake instance. Database created from the share will be Read-Only.',
      dataShareCreatedPoint1:
        'Only users with ‘Report Developer’ role will have access to publish custom reports in the ‘Custom Unpublished’ project at the shared location.',
      dataShareCreatedPoint2:
        'You can reference the attached sample script to import the data, create roles and grant relevant accesses to these roles.',
      dataShareCreatedPoint3:
        'This sample script is only to help you in setting up the environment. Please make necessary updates to the script to suit your requirements.',
      dataStandards: 'Data Standards',
      dataViews: 'Data Views',
      date: 'Date',
      daysToExpectedCompletion: 'Days to Expected Completion Date',
      disabled: 'Disabled',
      deactivate: 'Deactivate',
      deactivated: 'Deactivated',
      deactivatedBy: 'Deactivated By',
      defaults: 'Defaults',
      defaultUnitOfCurrency: 'Default Unit Of Currency',
      defaultUnitOfMeasurment: 'Default Unit Of Measurment',
      deleteUser: 'Delete User',
      deleteUserWarningMessage:
        'Are you sure you want to <strong>delete</strong> this user?',
      details: 'Details',
      documentation: 'Documentation',
      dontRemove: "Don't remove",
      download: 'Download',
      downloadSampleScript: 'Download sample script',
      email: 'Email address',
      enabled: 'Enabled',
      error: 'Error',
      errorOccurredMsg: 'An error has occurred. Please try again later.',
      errorPersistsMsg:
        'If the error persists, please contact RED2.0 support at RED2.Support@ap.jll.com for further assistance.',
      externalAlerts: 'External Alerts',
      eur: 'EUR',
      facilitiesExperience: 'Facility Experience Work Order',
      facilitiesExperienceAlerts: 'Facility Experience Work Order Alerts',
      favorite: 'Favorite',
      favorites: 'Favorites',
      financeData: 'Finance Data',
      actualsLedgerTypeCode: 'Actuals Ledger Type Code',
      budgetForecastLedgerTypeCode: 'Budget / Forecast Ledger Type Code',
      budgetAccuracyVarianceRatings: 'Budget Accuracy Variance Ratings',
      glAccountsForExclusion: 'Sub-accounts to Exclude from Finance Reporting',
      fullDashboard: 'Full Dashboard',
      gbp: 'GBP',
      goToApp: 'Go to app',
      help: 'Help',
      high: 'High',
      importUsers: 'Import Users',
      import: 'Import',
      info: 'Info',
      internalAlerts: 'Internal Alerts',
      keywords: 'Keywords',
      keywordManagement: 'Keyword Management',
      leaseEvent: 'Lease Event',
      leaseEvents: 'Lease Events',
      leaseEventAlerts: 'Lease Event Alerts',
      listView: 'List View',
      listOfReports: 'List of Dashboards',
      load: 'Load View',
      logIn: 'Sign in',
      logInErrorStatusMsg: 'Sign In Error',
      logInErrorSecondaryMsg:
        'Please clear browser cookies and close any open browser windows if the error persists.',
      logInErrorDetailsMsg: 'Technical details:',
      logOut: 'Log out',
      low: 'Low',
      maintenanceStatusMsg: 'System is under maintenance',
      maintenanceSecondaryMsg: "We're preparing to serve you better.",
      maintenanceDetailsMsg: 'Please check back soon.',
      maintenanceStart: 'Start Date/Time',
      maintenanceEnd: 'End Date/Time',
      myAccount: 'My Account',
      maintenanceError: 'System Under Maintenance',
      manageReports: 'Manage Reports',
      medium: 'Medium',
      myPersonas: 'My Personas',
      mySaved: 'My Saved Views',
      name: 'Name',
      networkErrorStatusMsg: 'Connection Error',
      networkErrorSecondaryMsg: 'There appears to be a connection error',
      next: 'next',
      new: 'New',
      noAccess: 'You do not have access to this page!',
      noUsersFound: 'No users found.',
      noAdGroups: 'No AD Groups.',
      noClientsAssigned:
        'You do not have any clients assigned. Please contact your administrator for further details.',
      noDataAvailable: 'No Data Available',
      noFavorites: 'No Favorites Yet!',
      noFavoritesLong:
        'You have no reports in your favorites yet. Click the ADD DASHBOARDS button to add a favorite.',
      noReports: 'No Reports yet!',
      noRoleAssigned:
        'No role assigned. Please contact your administrator to get a role.',
      noSearchResults: 'No Search Results',
      note: 'Please note',
      notFoundStatusMsg: '404',
      notFoundSecondaryMsg: 'PAGE NOT FOUND',
      notRelevant: 'Not Relevant',
      noticePeriodMonthsForAlertsGeneration:
        'Notice Period in Months for Alert Generation',
      options: 'Options',
      pdfFile: 'PDF file',
      phone: 'Phone',
      photoHint: 'Choose File',
      pickedUpDateToDueDate: 'Picked Up Date to Due Date',
      pictureUploadError: 'Picture uploading error',
      previous: 'previous',
      preview: 'Preview',
      previewed: 'Previewed',
      previewedBy: 'Previewed By',
      preferredContactMethod: 'Preferred Contact Method',
      preferredUnitOfCurrency: 'Preferred Unit of Currency',
      preferredUnitOfMeasurment: 'Preferred Unit of Measurement',
      print: 'Print',
      productSettings: 'Product Settings',
      profile: 'Profile',
      properties: 'Properties',
      propertyName: 'Property Name',
      publish: 'Publish',
      published: 'Published',
      publishedBy: 'Published By',
      queued: 'Queued',
      readAlerts: 'Read',
      region: 'Region',
      remove: 'Remove',
      removeCategoryConfirmation:
        'Are you sure you want to remove this category?',
      removeReports: 'Remove reports',
      required: 'Required',
      reportSettings: 'Dashboard Settings',
      retry: 'Retry',
      role: 'Role',
      roleManagement: 'Role Management',
      roles: 'Roles',
      save: 'Save View',
      successful: 'Successful',
      stateProvince: 'State/Province',
      saveForLater: 'Saved for Later',
      selectUserRole: 'Select user role',
      selectCountry: 'Select a country',
      systemError: `Sorry, It's Not Your Fault`,
      sqft: 'SQFT',
      squareFeet: 'Square Feet',
      sqm: 'SQM',
      squareMeters: 'Square Meters',
      dataQualityReview: 'Data Quality Review',
      settings: 'Settings',
      share: 'Share',
      shareName: 'Share name',
      status: 'Status',
      tableauTimeout: 'Dashboard Time Out',
      tableauTimeoutRetry: 'Click <strong>here</strong> to retry.',
      tableauAuthenticationIssue: `Allow third-party cookies for Azara (in browser's third-party cookies settings) and <strong>refresh</strong> the page.`,
      tableauAuthenticationIssueTitle: 'Authentication Issue',
      tenants: 'Clients',
      thresholdForAlerts: 'Threshold For Alerts',
      thresholdForMedPrioAlerts: 'Threshold For Medium Priority Alerts',
      thresholdForHighPrioAlerts: 'Threshold For High Priority Alerts',
      thresholdForMinimum: 'Threshold For Minimum #',
      workOrdersAboveMedian: 'Work Orders Above Median',
      thumbnailView: 'Thumbnail View',
      unableToAction: 'Unable To Action',
      universalFilters: 'Universal Filters',
      unreadAlerts: 'Unread',
      uploadPhoto: 'Upload photo',
      userInactive:
        'User deactivated. Please contact your administrator for activation.',
      userImportInProgress: 'User Import In Progress',
      users: 'Users',
      userManagement: 'User Management',
      pleaseAddPersona: 'Please add a persona...',
      resetPersona: 'Reset',
      switchPersona: 'Switch persona',
      validationError: 'Validation Error',
      unassigned: 'Unassigned',
      unitOfMeasurement: 'Unit of Measurement',
      unsavedChangesTitle: 'Unsaved Changes',
      unsavedChanges:
        'There are unsaved changes on this page. Please save to update the settings.',
      usd: 'USD',
      userDetails: 'User Details',
      user: 'User',
      workArea: 'Work Area',
      workOrderVolume: 'Work Order Volume',
      workOrderVolumeAlerts: 'Work Order Volume Alerts',
      workOrdersData: 'Work Orders Data',
      workOrderCompletionDate: 'Work Order Completion Date',
      workOrderFirstCompletionDate: 'Work Order First Completion Date',
      workOrderLastCompletionDate: 'Work Order Last Completion Date',
      onTimeCompletionRatings: 'On-Time Completion Ratings',
      pmOnTimeCompletionRatings: 'PM On-Time Completion Ratings',
      emergencyOnTimeCompletionRatings: 'Emergency On-Time Completion Ratings',
      customerSatisfactionRatings: 'Customer Satisfaction Ratings',
      invoicesSubmittedLess30DaysRatings:
        'Invoices Submitted < 30 Days Ratings',
      kpiThresholds: 'KPI Thresholds',
      hide: 'Hide',
      savedView: 'Saved View',
      edit: 'Edit',
      delete: 'Delete',
      refresh: 'Refresh',
      refreshToFetchReports:
        '<strong>Refresh</strong> to fetch new reports from server',
      synchronize: 'Synchronize',
      deleteSavedViewDashboard:
        'Are you sure you want to <strong>delete</strong> this saved view?',
      deleteSavedViewDashboardWarning: 'Deleted view cannot be retrieved.',
      deleteSavedViewDashboardTitle: 'Delete Saved View',
      hideDashboard:
        'Are you sure you want to <strong>hide</strong> this dashboard?',
      hideDashboardWarning:
        'You can unhide the dashboard on the Dashboard Settings page.',
      hideDashboardTitle: 'Hide Dashboard',
      na: 'NA',
      total: 'Total',
      deleteTenant: 'Delete Client',
      deleteTenantText:
        'Are you sure you want to <strong>delete</strong> this client?',
      updateSettings: 'Update Settings',
      updateAlertSettingsText:
        '<br/>Are you sure you want to <strong>Update</strong> the settings?<br/><br/>These changes will come into effect immediately. New alerts will be generated based on the updated settings.',
      updateDashboardSettingsText:
        '<br/>Are you sure you want to <strong>Update</strong> the settings?<br/><br/>These changes may impact the data in Azara dashboards and the data update may take up to 24 hours to be visible in the dashboards.',
      mismatch: 'Mismatch',
      allMappingsError: 'All Mapping Errors',
      noMappingErrors: 'No Mapping Errors',
      noMappings: 'No Mappings',
      noCustomReports: 'No Custom Reports Available',
      noExternalReports: 'No External Reports Available',
      mappingErrors: 'Mapping Errors',
      source: 'Source',
      sourceValue: 'Source Value',
      record: 'Record',
      targetValue: 'Target Value',
      propertyType: 'Property Type',
      propertySubtype: 'Property Subtype',
      woPriority: 'Work Order Priority',
      woSpeciality: 'Work Order Speciality',
      woStatus: 'Work Order Status',
      woType: 'Work Order Type',
      industry: 'Industry',
      metro: 'Metro',
      viewAll: 'View All',
      unmapped: 'Unmapped',
      addValue: 'Add Value',
      downloadAllCSV: 'Complete Data',
      downloadErrorsCSV: 'Only the Errors',
      select: 'Select',
      azaraHostedReports: 'Azara Hosted Reports',
      externalReports: 'External Reports',
      addReport: 'Add Report',
      editReport: 'Edit Report',
      addedBy: 'Added By',
      reportName: 'Report Name',
      reportUrl: 'Report URL',
      updatedBy: 'Updated By',
      deleteReport: 'Delete Report',
      deleteReportText:
        'Are you sure you want to <strong>delete</strong> this report?',
      deleteRoleWarningMessage:
        'Are you sure you want to <strong>delete</strong> this role?',
      roleName: 'Role Name',
      roleDescription: 'Role Description',
      featuresAllowed: 'Features Allowed',
      dashboardsAllowed: 'Dashboards Allowed',
      alertsAllowed: 'Alerts Allowed',
      userList: 'User List',
      createdBy: 'Created By',
      lastUpdatedBy: 'Last Updated By',
      thumbnailError: 'An error has occurred.',
      thumbnailMissingURLError: 'Thumbnail Not Found',
      dataManagement: 'Data Management',
      version: 'Version',
      pendingUserOnboarding: 'Pending User Onboarding',
      rerunOnboarding: 'Complete it now',
      remindOnboarding: 'Remind me later',
      skipOnboarding: 'Do not remind me again',
      showFilters: 'Show Filters',
      hideFilters: 'Hide Filters',
      filters: 'Filters',
      variables: 'Variables',
      export: 'Export',
      resetView: 'Reset View',
      manageKeywords: 'Manage Keywords',
      add: 'Add',
      noKeywords: 'No Keywords',
      dashboardKeywords: 'Dashboard Keywords',
      patReportsConfig: 'Legacy Dashboard Settings',
      batchUploadSettings: 'Batch Upload Settings',
      dataUpload: 'Data Upload',
      csv: 'CSV',
      pdf: 'PDF',
      image: 'Image',
      submittedBy: 'Submitted By',
      enterSubmittedBy: 'Enter Submitted By',
      selectApplication: 'Select Application',
      fileName: 'File Name',
      uploadStatus: 'Upload Status',
      upload: 'Upload',
      selectUploadStatus: 'Select Upload Status',
      unknown: 'Unknown',
      dataProcessing: 'Data Processing',
      failed: 'Failed',
      fileValidation: 'File Validation',
      partialSuccessful: 'Partially Successful',
      from: 'From',
      to: 'To',
      loadMore: 'Load More',
      processingStart: 'Processing Start',
      processingEnd: 'Processing End',
      input: 'Input',
      output: 'Output',
      resetDataLoadsContainer: 'Reset',
      resetDataLoadsContainerText:
        'Are you sure you want to <strong>RESET</strong> the key? Existing key will become non-functional on reset',
      location: 'Location',
      enterFileName: 'Enter File Name',
      estimateRoi: 'Estimate ROI',
      estimatedRoi: 'Estimated ROI',
      description: 'Description',
      selectParameters: 'Select Parameters',
      selectedParameters: 'Selected Parameters',
      comment: 'Comment',
      userHistory: 'User History',
      assignUser: 'Assign User',
      headCount: 'Head Count',
      seatCount: 'Seat Count',
      assignedToMe: 'Assigned to Me',
      chartOfAccount: 'Chart of Account',
      accessDetails: 'Access Details',
      client_settings: 'Client Settings',
      data_management: 'Data Management',
      features: 'Features',
      fullAccess: 'Full Access',
      includeAllFutureDashboards: 'Include All Future Dashboards',
      listOfDashboards: 'List of Dashboards',
      listOfAlerts: 'List of Alerts',
      includeAllFutureAlerts: 'Include All Future Alerts',
      account_settings: 'Account Settings',
      alert_settings: 'Alert Settings',
      dashboard_settings: 'Dashboard Settings',
      config_data_setup: 'Config Data Setup',
      custom_reports: 'Custom Reports',
      keyword_management: 'Keyword Management',
      pat_reports_config: 'Legacy Dashboard Settings',
      role_management: 'Role Management',
      user_management: 'User Management',
      data_standards: 'Data Standards',
      data_loads: 'Data Loads',
      data_views: 'Data Views',
      selectFeatures:
        'Select the Features, to grant access and permissions for this role.',
      milestoneIdentification: 'Milestone Identification',
      transactionData: 'Transaction Data',
      successCount: 'Success Count',
      failureCount: 'Failure Count',
      totalCount: 'Total Count',
      subApplication: 'Sub Application',
      universal_filters: 'Universal Filters',
      readOnly: 'Read Only',
      my_account: 'My Account',
      newTab: 'New Tab',
      openReport: 'Open Report In',
      all_alerts: 'All Alerts',
      all_dashboards: 'All Dashboards',
      refreshClient: 'Refresh Client',
      refreshClientWarningMessage:
        'Are you sure you want to <strong>refresh</strong> the client? This will recreate the data and workbooks for the client.',
      revert: 'Revert',
      dashboardSettings: 'Dashboard Settings',
      coreDashboardSettings: 'Core Dashboard Settings',
      dashboardName: 'Dashboard Name',
      showDashboard: 'Show Dashboard',
      categoryName: 'Category Name',
      managed: 'JLL Managed',
      federated: 'Federated',
      userIntegration: 'Okta Integration',
      asaIDP: 'JLL WD / Employee',
      managedIDP: 'JLL Managed Okta',
      federatedIDP: 'Federated Okta',
      unsupportedIDP: 'Unsupported',
      userStatusActivationExpired: 'Activation Expired',
      userStatusActive: 'Active',
      userStatusLocked: 'Locked',
      userStatusPendingActivation: 'Pending Activation',
      userStatusInactive: 'Inactive',
      reset: 'Reset',
      reactivate: 'Resend Activation',
      unlock: 'Unlock',
      unsuspend: 'Unsuspend',
      userStatusSuspended: 'Suspended',
      userStatusRecovery: 'Recovery',
      passwordResetSuccessMessage: 'Password reset was successful.',
      reactivateSuccessMessage: 'User reactivation was successful.',
      acceptableVarianceOnCurrentCost: 'Acceptable Variance on Current Cost',
      noticePeriodInMonths: 'Notice Period in Months',
      thresholdForAlertGeneration: 'Threshold for Alert Generation',
      marketBenchmarkAlerts: 'Lease Cost Benchmarking Alerts',
      marketBenchmark: 'Lease Cost Benchmarking',
      passwordResetFailureMessage: 'Password reset was not successful.',
      reactivateFailureMessage: 'User reactivation was not successful.',
      userUnlockSuccessMessage: 'User unlocked successfully.',
      userUnlockFailureMessage: 'User unlock was not successful.',
      asaUserNotFound: 'User not found in ASA. Please contact your ASA Admin.',
      deleteRole: 'Delete Role',
      alertType: 'Alert Type',
      tenureCompany: 'Tenure Company',
      businessUnit: 'Business Unit',
      spaceType: 'Space Type',
      leaseData: 'Lease Data',
      spaceData: 'Space Data',
      active: 'Active',
      cs: 'CS',
      nonCs: 'Non-CS',
      demo: 'Demo',
      portscapeAccessLink: 'Portscape Access Link',
      propertyData: 'Property Data',
      propertyId: 'Property_ID',
      addressLine1: 'Address Line 1',
      leasedOwned: 'Leased/Owned',
      propertyArea: 'Property Area',
      unitOfMeasure: 'Unit of Measure',
      siteGroup: 'Site Group',
      state: 'State',
      bldg: 'Bldg',
      campus: 'Campus',
      inactive: 'Inactive',
      leased: 'Leased',
      owned: 'Owned',
      permissibleValues: 'Permissible Values',
      property_data: 'Property Data',
      jll_portscape: 'JLL Portscape',
      occupancyDetails: 'Occupancy Details',
      occupancyEfficiency: 'Occupancy Efficiency',
      occupancyEfficiencyAlerts: 'Occupancy Efficiency Alerts',
      EMEA: 'EMEA',
      AM: 'AM',
      APAC: 'APAC',
      OtherOrNone: 'Other/None',
      headcountDensity: 'Headcount Density',
      vacancyRate: 'Vacancy Rate',
      occupancyRate: 'Occupancy Rate',
      addressLine2: 'Address Line 2',
      addressLine3: 'Address Line 3',
      addressLine4: 'Address Line 4',
      countryIsoCode2: 'Country ISO Code 2',
      countryIsoCode3: 'Country ISO Code 3',
      countryIsoNumeric: 'Country ISO Numeric',
      industrySector: 'Industry Sector',
      latitude: 'Latitude',
      longitude: 'Longitude',
      propertySubType: 'Property Sub Type',
      regionHierarchy1: 'Region Hierarchy 1',
      regionHierarchy2: 'Region Hierarchy 2',
      regionHierarchy3: 'Region Hierarchy 3',
      regionHierarchy4: 'Region Hierarchy 4',
      regionHierarchy5: 'Region Hierarchy 5',
      regionHierarchy6: 'Region Hierarchy 6',
      regionHierarchy7: 'Region Hierarchy 7',
      regionHierarchy8: 'Region Hierarchy 8',
      serviceEndDate: 'Service End Date',
      serviceStartDate: 'Service Start Date',
      zipPostCode: 'Zip / Post Code',
      parameters: 'Parameters',
      sqf: 'SQF',
      dashboardsLicenseErrorMessage:
        'Your Dashboards license is in an invalid state. Please contact your Client Admin for assistance.',
      mappingForBusinessUnit: 'Mapping for Business Unit',
      mappingForSpaceType: 'Mapping for Space Type',
      mappingForTenureCompany: 'Mapping for Tenure Company',
      mappingForCostCategory: 'Mapping for Cost Category',
      transactionType: 'Transaction Type',
      transactionSubtype: 'Transaction Subtype',
      transactionStatus: 'Transaction Status',
      criticalEventType: 'Critical Event Type',
      taskStatusCategory: 'Task Status Category',
      leaseType: 'Lease Type',
      termStatus: 'Term Status',
      tenureStatus: 'Tenure Status',
      costCategory: 'Cost Category',
      eventType: 'Event Type',
      eventCategory: 'Event Category',
      eventActionStatus: 'Event Action Status',
      enterPropertyId: 'Enter Property ID',
      enterPropertyName: 'Enter Property Name',
      selectCity: 'Select City',
      selectPropertyType: 'Select Property Type',
      termsAndConditionsDeclaration: 'I have read and agree to the',
      termsAndConditions: 'Terms & Conditions',
      loggingOut: 'Logging out',
      leaseLiquidityOptions: 'Lease Liquidity Options',
      naturalExpiry: 'Natural Expiry',
      mainlyParkingLeases: 'Mainly Parking Leases',
      monthToMonthAndHoldoverLeases: 'Month to Month and Holdover Leases',
      contractionOptions: 'Contraction Options',
      terminationOptions: 'Termination Options',
      noticePeriod: 'Notice Period',
      subleaseRiskAlerts: 'Lease Liquidity / Sublease Risk Alerts',
      subleaseRisk: 'Lease Liquidity / Sublease Risk',
      sameDayWorkOrderCompletion: 'Same Day Work Order Completion',
      sameDayWorkOrderCompletionAlerts: 'Same Day Work Order Completion Alerts',
      percentSameDayWorkOrdersCompletedInTime:
        '% Same Day Work Orders Completed in Time',
      externalReportEmbedWarningMessage: `This report may have issues opening in Azara. It is recommended to use the <strong>New Tab</strong> option for this report.`,
      kpiSettings: 'KPI Settings',
      showKpiBox: 'Show KPI box',
      actualCosts: 'Actual Costs',
      size: 'Size',
      propertyCount: 'Property Count',
      viewFlexibleMappingSampleValues: 'View Flexible Mapping Sample Values',
      FIN: 'Finance',
      cost_category: 'Cost Category',
      SPC: 'Space',
      business_unit: 'Business Unit',
      noData: 'No data.',
      space_type: 'Space Type',
      mappingForReportingBusinessUnit: 'Mapping for Reporting Business Unit',
      LSE: 'Lease',
      reporting_business_unit: 'Reporting Business Unit',
      internalUser: 'Internal User',
      clientCorporateUser: 'Client JLL User',
      clientUser: 'Client User',
      userClassification: 'User Classification',
      lastLogin: 'Last Login',
      multipleClients: 'Multiple Clients',
      type: 'Type',
      userEmail: 'User Email',
      totalUsers: 'Total Users',
      clientUsers: 'Client Users',
      internalUsers: 'Internal Users',
      clientJllUsers: 'Client JLL Users',
      afterThisChangeTheUserTypeWillBeAssignedTo:
        'After this change, the user type will be assigned to',
      doYouWantToChangeTheUserTypeFor:
        'Do you want to change the user type for',
      changeUserType: 'Change User Type',
      yes: 'Yes',
      assignDate: 'Assign Date',
      back: 'Back',
      clientName: 'Client Name',
      currentRole: 'Current Role',
      in_progress: 'In Progress',
      onboardingStatus: 'Onboarding Status',
      skipped: 'Skipped',
      activate: 'Activate',
      deactivateUserWarningMessage:
        'Are you sure you want to <strong>deactivate</strong> this user?',
      deactivateUser: 'Deactivate User',
      resetPassword: 'Reset Password',
      activateUser: 'Activate User',
      unlockUser: 'Unlock User',
      unsuspendUser: 'Unsuspend User',
      editUser: 'Edit User',
      reactivateUser: 'Reactivate User',
      accountNotActivated:
        'Account not activated. Please contact Account Admin for further details.',
      externalReportLoadingMessage:
        'Please wait while we are opening your report',
      enableSelfServeAnalytics: 'Enable Self-Serve Analytics',
      enterSelfServeAnalyticsUrl: 'Enter Self-Serve Analytics URL',
      saveButton: 'Save',
      self_serve_analytics: 'Self Serve Analytics',
      occupancyData: 'Occupancy Data',
      standardVacancyThresholds: 'Standard Vacancy Thresholds',
      standardOccupancyThresholds: 'Standard Occupancy Thresholds',
      on_track: 'On Track',
      superiorData: 'Superior Data',
      deficientData: 'Deficient Data',
      userType: 'User Type',
      userName: 'User Name',
      emailAddress: 'Email Address',
      snowflakeURL: 'Snowflake Account for Data Share',
      addAccount: 'Add New Account',
      addSnowflakeUrl: 'Please add Snowflake URL.',
      deleteTenantDataShareMessage:
        'Are you sure you want to <strong>Remove</strong> data share from this account?',
      updateTenantDataShareMessage:
        'Are you sure you want to <strong>Update</strong> data share? Data share will be removed from current account and created for new account.',
      deleteSnowflakeUrl: 'Delete Snowflake Account',
      addSnowflakeUrlDialogHeader: 'Add Snowflake Account',
      editSnowflakeUrlDialogHeader: 'Edit Snowflake Account',
      veryHigh: 'Very High',
      veryLow: 'Very Low',
      clear: 'Clear',
      filter: 'Filter',
      userAdditionFailurePleaseRetry: 'User addition failure. Please retry.',
      userStatus: 'User Status',
      environment_setup: 'Environment Setup',
      environmentSetup: 'Environment Setup',
      alertIssue: 'Alert Settings Issue',
      pleaseContactSupportForAssistance:
        'Please contact support for assistance.',
      promote: 'Promote',
      promoteDemoClientWarningText:
        'This will deploy the latest version of code to all Demo clients. This process cannot be rolled back. Please confirm if you want to proceed.',
      updateTesting: 'Update Testing',
      discard: 'Discard',
      promoteWdQaClientWarningText:
        'This will deploy the current QA code to WD-Live clients and refresh WD-QA to latest code. This process cannot be rolled back. Please confirm if you want to proceed.',
      discardWdQaClientWarningText:
        'This will discard the current QA code and refresh WD-QA to latest code. This process cannot be rolled back. Please confirm if you want to proceed.',
      wdlive: 'WD - Live',
      wdqa: 'WD - QA',
      nonwd: 'Non WD',
      legacy: 'Legacy',
      environment: 'Environment',
      releaseId: 'Release Id',
      releaseStatus: 'Release Status',
      testingStatus: 'Testing Status',
      releaseDate: 'Release Date',
      promoted: 'Promoted',
      discarded: 'Discarded',
      live: 'Live',
      upgraded: 'Upgraded',
      testingFailed: 'Testing Failed',
      testingSuccessful: 'Testing Successful',
      lastUpdate: 'Last Update',
      ACTIVE: 'ACTIVE',
      APP_SETUP_ERROR: 'APP SETUP ERROR',
      APP_SETUP_RUNNING: 'APP SETUP RUNNING',
      DATA_SETUP_ERROR: 'DATA SETUP ERROR',
      DATA_SETUP_RUNNING: 'DATA SETUP RUNNING',
      DELETION_ERROR: 'DELETION ERROR',
      DELETION_IN_PROGRESS: 'DELETION IN PROGRESS',
      NEW: 'NEW',
      emptyCategoryNameWarningText:
        'Category Name cannot be empty. Change has not been saved.',
      emptyDashboardNameWarningText:
        'Dashboard Name cannot be empty. Change has not been saved.',
      configureDashboards: 'Configure Dashboards',
      clientsDashboards: 'Client’s Dashboards',
      dashboardsVisibility: 'Dashboard’s Visibility',
      ts: 'ThoughtSpot',
      tableau: 'Tableau',
      other: 'Other',
      general: 'General',
      reportType: 'Report Type',
      thumbnailImage: 'Thumbnail Image',
      clearPinnedFiltersHelpMessage:
        'To display whole selection, use the Clear Button.',
      dataUpdated: 'Data Updated',
      customExternalDashboardName: 'Custom/External Dashboard Name',
    },
  },
};

export default en;
