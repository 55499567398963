import * as React from 'react';
import { useHistory } from 'react-router';

const OpenAlertReport: React.FC<{}> = ({ children }) => {
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (target.nodeName === 'A') {
      e.preventDefault();
      const link = target.getAttribute('href');
      if (link) {
        history.push(link);
      }
    }
  };

  return <div onClick={handleClick}>{children}</div>;
};

export default OpenAlertReport;
