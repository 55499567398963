import * as React from 'react';
import Grid from '@mui/material/Grid';

interface Props {
  importanceFilter: JSX.Element;
  alertType: JSX.Element;
  alertSeverity: JSX.Element;
  alertAssignee: JSX.Element;
  dateSort: JSX.Element;
}

const InsightsAlertHeaderTableCell: React.FC<Props> = ({
  importanceFilter,
  alertType,
  alertSeverity,
  alertAssignee,
  dateSort,
}) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid container item xs={8}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={3} style={{ overflowX: 'hidden' }}>
            {importanceFilter}
          </Grid>
          <Grid item xs={9}>
            {alertType}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={4}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            {alertSeverity}
          </Grid>
          <Grid item xs={3}>
            {alertAssignee}
          </Grid>
          <Grid item xs={4}>
            {dateSort}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InsightsAlertHeaderTableCell;
