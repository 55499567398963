import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_ALERT_ROI_DATA } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import {
  InsightsAlert,
  InsightsAlertUserHistory,
  InsightsAlertUserHistoryRecord,
  InsightsAlertUserHistoryActionPerformed,
  InsightsAlertRoiData,
  ROIData,
} from '../insightsAlerts.types';

export const useSetInsightsAlertRoiData = (alert?: InsightsAlert) => {
  const { userId, tenantId, userName } = useGetInsightsInboxAlertState();
  const [setInsightsAlertRoiData] = useAppMutation<
    SchemaTypes.SetInsightsAlertRoiDataMutation,
    SchemaTypes.SetInsightsAlertRoiDataMutationVariables
  >(SET_INSIGHTS_ALERT_ROI_DATA);

  return {
    setInsightsAlertRoiData: (threadId: string, roiData: ROIData) => {
      const isRoiEnabled = alert?.is_roi_enabled ?? false;
      const { comment, frequency, opportunityType, unit, value } = roiData;
      const timeStamp = `${new Date().getTime()}`;

      setInsightsAlertRoiData({
        variables: {
          where: {
            created_at: timeStamp,
            tenant_id: tenantId,
            thread_id: threadId,
            user_id: userId,
            opportunity_type: opportunityType,
            unit,
            frequency,
            value: value || undefined,
            comment: comment || undefined,
          },
        },
        optimisticResponse: {
          setInsightsAlertRoiData: true,
        },
        update(cache) {
          const threadCacheId = cache.identify({
            __typename: 'InsightsAlertThread',
            thread_id: threadId,
          });
          cache.modify({
            id: threadCacheId,
            fields: {
              alert_user_history(existing: InsightsAlertUserHistory) {
                const userHistory: InsightsAlertUserHistoryRecord = {
                  commented_at: timeStamp,
                  action_performed: isRoiEnabled
                    ? InsightsAlertUserHistoryActionPerformed.RoiEdit
                    : InsightsAlertUserHistoryActionPerformed.RoiAdded,
                  user_name: userName,
                  comment,
                };

                return {
                  ...existing,
                  user_history: [userHistory, ...existing.user_history],
                };
              },
              alert_roi_data(existing: InsightsAlertRoiData) {
                const {
                  comment: oldComment,
                  frequency: oldFrequency,
                  opportunity_type: oldOpportunityType,
                  unit: oldUnit,
                  value: oldValue,
                } = existing ?? {};

                return {
                  __typename: 'InsightsAlertRoiData',
                  comment: comment ?? oldComment,
                  frequency: frequency ?? oldFrequency,
                  opportunity_type: opportunityType ?? oldOpportunityType,
                  unit: unit ?? oldUnit,
                  value: value ?? oldValue,
                };
              },
            },
            optimistic: true,
          });
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlert',
              thread_id: threadId,
            }),
            fields: {
              is_roi_enabled() {
                return true;
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
