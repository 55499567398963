/* eslint-disable no-console */
import * as React from 'react';
import { uniqBy } from 'lodash';
import { utils } from '@jll-labs/azara-ui-components';
import { useGetUserCurrentUniversalFilters } from '@apollo-red/hooks';
import { FlattenedFilter, Viz } from '@components/tableau/types';
import { useFeatureFlag } from '@utils/launchDarkly';
import { parseAndFlattenFilters } from './parseAndFlattenFilters';
import { useGetFiltersSheet } from './useGetFiltersSheet';
import { ReportControlWithoutTypename } from '../types';

const { useToggleValue } = utils;

export const useGetFilters = (
  reportId: string,
  reportControls: ReportControlWithoutTypename[],
  viz?: Viz,
) => {
  const [loading, toggleLoading] = useToggleValue(false);
  const [tableauFilters, setTableauFilters] = React.useState<FlattenedFilter[]>(
    [],
  );
  const debugFilters = useFeatureFlag('dashboardsFiltersDebugLogging');
  const filtersOnFirstWorksheet = useFeatureFlag(
    'dashboardsFiltersSourceFromFirstWorksheet',
  );
  const filtersSheet = useGetFiltersSheet(viz);
  const { loading: userUniversalFiltersLoading, universalFilters } =
    useGetUserCurrentUniversalFilters();

  React.useEffect(() => {
    if (!viz || !filtersSheet) {
      return;
    }

    toggleLoading();

    const getFilters = async () => {
      try {
        const log = debugFilters ? console.log : () => {};
        const table = debugFilters ? console.table : () => {};

        debugFilters &&
          console.groupCollapsed(
            `Dashboard Filters Info - ${viz.getWorkbook().getName()}`,
          );
        log(`Dashboard ID: ${reportId}`);

        const filters = await filtersSheet.getFiltersAsync();

        log(
          `${
            filters.length
          } filters read from Sheet ${filtersSheet.getName()}.`,
        );
        const uniqueFilters = filtersOnFirstWorksheet
          ? filters
          : uniqBy(filters, filter => filter.getFieldName());
        const parsedFilters = parseAndFlattenFilters(
          uniqueFilters ?? [],
          universalFilters,
          reportControls,
        );

        setTableauFilters(parsedFilters);
        log(`${parsedFilters.length} unique filters processed.`);
        table(parsedFilters);
      } finally {
        toggleLoading();
        debugFilters && console.groupEnd();
      }
    };

    if (!userUniversalFiltersLoading) {
      getFilters();
    }
  }, [
    debugFilters,
    filtersOnFirstWorksheet,
    filtersSheet,
    reportId,
    toggleLoading,
    viz,
    userUniversalFiltersLoading,
    universalFilters,
    reportControls,
  ]);

  return { tableauFilters, loading };
};
