import { useMemo } from 'react';
import { UseClientSettingsRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useDashboardManagementTenantAdminRoutes = ({
  dashboardSettings: { getLink, path },
}: UseClientSettingsRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      dashboardSettings: getCommonRoute(link, 'dashboard-settings', path),
      kpiSettings: getCommonRoute(link, 'kpi-settings', path),
    };
  }, [getLink, path]);
