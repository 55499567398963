import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_USER_TENANT_INSIGHTS_ALERT_THREAD } from '@apollo-red/queries';
import { useAppQuery } from '../../../queries';

interface GetInsightsAlertThread {
  userId: string;
  tenantId: string;
  threadId: string;
  alertHistoryContinuationToken?: string;
  userHistoryContinuationToken?: string;
  skip?: boolean;
}

export const useGetInsightsAlertThreadQuery = ({
  userId,
  tenantId,
  threadId,
  skip,
}: GetInsightsAlertThread) => {
  return useAppQuery<
    SchemaTypes.GetInsightsAlertThreadQuery,
    SchemaTypes.GetInsightsAlertThreadQueryVariables
  >(GET_USER_TENANT_INSIGHTS_ALERT_THREAD, {
    variables: {
      where: { id: userId },
      whereInsightsAlert: {
        tenant_id: tenantId,
        thread_id: threadId,
      },
    },
    skip: !threadId || !userId || !tenantId || skip,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
};
