import { gql } from '@apollo/client';
import { RoleBase } from '@apollo-red/fragments';

export const GET_ALL_ROLES = gql`
  query getRoles {
    getRoles {
      ...RoleBase
    }
  }
  ${RoleBase.fragment}
`;

export const GET_TENANT_CLIENT_DEFINED_ROLES = gql`
  query getTenantClientDefinedRoles($tenant_id: ID!) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_client_defined_roles {
        ...RoleBase
      }
    }
  }
  ${RoleBase.fragment}
`;
