import {
  InsightsAlert,
  InsightsAlertScope,
  InsightsAlertWorkOrderVolumeTemplateData,
} from '@apollo-red/hooks';

/**
 * Prepares value for 'p_peer' filter.
 * Uses scoped peer group (internal or external) if it exists.
 * Fallbacks to non-scoped peer_group for compatibility with old alerts.
 *
 * @param alert alert document
 * @param scope alert scope
 */
export const getPeerGroupFilterValues = (
  alert: InsightsAlert,
  scope?: InsightsAlertScope,
) => {
  const templateData =
    alert.template_data as InsightsAlertWorkOrderVolumeTemplateData;
  const withFallback = (val?: string | null) => {
    if (val) {
      return [val];
    }
    return [];
  };

  switch (scope) {
    case InsightsAlertScope.Internal: {
      return withFallback(templateData.peer_group_internal);
    }
    case InsightsAlertScope.External: {
      return withFallback(templateData.peer_group_external);
    }
    default: {
      return withFallback();
    }
  }
};
