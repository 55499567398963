import { differenceWith } from 'lodash';

export const mergeOrders = (
  oldOrder: string[],
  order: string[] | undefined | null,
) => {
  if (!order) {
    return null;
  }

  const newReports = differenceWith(order, oldOrder, (o, n) => o === n);
  let orderIndex = 0;

  const newOrder = [...oldOrder, ...newReports].map(r => {
    const oi = order.some(o => o === r);

    if (!oi) {
      return r;
    }

    return order[orderIndex++];
  });

  return newOrder;
};
