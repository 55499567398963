import { GET_RELEASES } from '@apollo-red/queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppQuery } from '../queries';

export const useGetReleases = () => {
  const { data, loading, startPolling, stopPolling } = useAppQuery<
    SchemaTypes.GetReleasesQuery,
    SchemaTypes.GetReleasesQueryVariables
  >(GET_RELEASES);

  return {
    loading,
    releases: data?.getReleases ?? [],
    startPolling,
    stopPolling,
  };
};
