import * as React from 'react';

const isDocumentVisible = () => {
  return !document.hidden;
};

export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = React.useState(isDocumentVisible());
  const onVisibilityChange = () => setIsVisible(isDocumentVisible());

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return { isVisible };
};
