import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { ADD_INSIGHTS_ALERT_USER_COMMENT } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import {
  InsightsAlertUserHistory,
  InsightsAlertUserHistoryRecord,
  InsightsAlertUserHistoryActionPerformed,
} from '../insightsAlerts.types';

export const useAddInsightsAlertUserComment = () => {
  const { userId, tenantId, userName } = useGetInsightsInboxAlertState();
  const [addInsightsAlertUserComment] = useAppMutation<
    SchemaTypes.AddInsightsAlertUserCommentMutation,
    SchemaTypes.AddInsightsAlertUserCommentMutationVariables
  >(ADD_INSIGHTS_ALERT_USER_COMMENT);

  return {
    addInsightsAlertUserComment: (threadId: string, comment: string) => {
      const timeStamp = `${new Date().getTime()}`;
      addInsightsAlertUserComment({
        variables: {
          where: {
            tenant_id: tenantId,
            thread_id: threadId,
            user_id: userId,
            comment,
            commented_at: timeStamp,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          addInsightsAlertUserComment: true,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlertThread',
              thread_id: threadId,
            }),
            fields: {
              alert_user_history(existing: InsightsAlertUserHistory) {
                const userHistory: InsightsAlertUserHistoryRecord = {
                  commented_at: timeStamp,
                  action_performed:
                    InsightsAlertUserHistoryActionPerformed.CommentAdded,
                  user_name: userName,
                  comment,
                };

                return {
                  ...existing,
                  user_history: [userHistory, ...existing.user_history],
                };
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
