import { useMemo } from 'react';
import { UseClientSettingsRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useConfigDataSetupTenantAdminRoutes = ({
  configDataSetup: { getLink, path },
}: UseClientSettingsRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      alerts: getCommonRoute(link, 'alert-settings', path),
      reportSettings: getCommonRoute(link, 'dashboard-settings', path),
    };
  }, [getLink, path]);
