import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCleanupInsightsInboxAlerts,
  useGetInsightsAlertThread,
  useGetInsightsInboxRecentlySelectedAlert,
  useGetUserCurrentTenantReports,
  useGetUserAlertReportById,
  useSetInsightsInboxRecentAlert,
} from '@apollo-red/hooks';
import { PageContainerContext } from '@components/pageContainer';
import { Viz } from '@components/tableau/types';
import { useRoutes, InsightsAlertsInboxTypeParam } from '@routes';
import { useParamsCustom } from '@utils/router';
import {
  paramToInboxType,
  alertInboxTranslationKeys,
  alertTypeDashboardNameTranslationKeys,
} from '../../utils';
import { paramToScope } from './paramToScope';
import { useAlertReport } from './useAlertReport';
import { useAlertReportEffects } from './useAlertReportEffects';

interface MatchParams {
  alertId: string;
  inboxType: InsightsAlertsInboxTypeParam;
  alertScope?: 'internal' | 'external';
}

export const useAlertReportData = () => {
  const params = useParamsCustom<MatchParams>();
  const routes = useRoutes();
  const { t } = useTranslation();
  const { closeSidebar, setBreadcrumbsData } =
    React.useContext(PageContainerContext);
  const [viz, setViz] = React.useState<Viz | undefined>(undefined);
  const { loading, alert } = useGetInsightsAlertThread(params.alertId);
  const inboxType =
    paramToInboxType[params.inboxType as InsightsAlertsInboxTypeParam];
  const { recentlySelectedAlert, loading: recentLoading } =
    useGetInsightsInboxRecentlySelectedAlert(inboxType);
  const { cleanupInboxAlertThread } = useCleanupInsightsInboxAlerts(
    inboxType,
    recentlySelectedAlert,
  );
  const { setInsightsInboxRecentAlert } = useSetInsightsInboxRecentAlert(
    inboxType,
    cleanupInboxAlertThread,
  );
  const alertScope = params.alertScope && paramToScope[params.alertScope];
  const { alertReports } = useGetUserCurrentTenantReports();
  const alertReport = useAlertReport(alertReports, alert, alertScope);
  const { report: alertReportExtended } = useGetUserAlertReportById(
    alertReport?.alertReportId ?? '',
  );
  const alertsLabel = t('alerts');
  const alertsInboxLabel = t(alertInboxTranslationKeys[inboxType]);
  const alertsInboxLink = alert
    ? routes.insightsAlerts.inbox.getLink(
        params.inboxType as InsightsAlertsInboxTypeParam,
      )
    : '';
  const alertLabel =
    alert?.alert_type &&
    t(alertTypeDashboardNameTranslationKeys[alert.alert_type]);

  useAlertReportEffects({
    closeSidebar,
    alert,
    setInsightsInboxRecentAlert,
    setBreadcrumbsData,
    alertsLabel,
    alertsInboxLabel,
    alertsInboxLink,
    alertLabel,
  });

  return {
    alert,
    alertsInboxLabel,
    alertsInboxLink,
    alertsLabel,
    alertLabel,
    alertReport,
    alertReportExtended,
    loading,
    recentLoading,
    viz,
    closeSidebar,
    setBreadcrumbsData,
    setInsightsInboxRecentAlert,
    setViz,
  };
};
