import * as React from 'react';
import cx from 'classnames';
import { Icon, IconName } from '@jll-labs/azara-ui-components';
import ThumbnailImageWrapper from '../ThumbnailImageWrapper';
import { useThumbnailErrorStyles } from './styles';

export interface ThumbnailErrorProps {
  forFullDashboard?: boolean;
  handleRetry?: () => void;
  icon: IconName;
  isSidebarExpanded?: boolean;
  message: React.ReactNode;
  title: string;
}

const ThumbnailError: React.FC<ThumbnailErrorProps> = ({
  forFullDashboard = false,
  handleRetry,
  icon,
  isSidebarExpanded = false,
  message,
  title,
}) => {
  const classes = useThumbnailErrorStyles({
    forFullDashboard,
    isSidebarExpanded,
  });

  return (
    <ThumbnailImageWrapper forFullDashboard={forFullDashboard}>
      <div className={classes.error}>
        <div
          className={cx(classes.wrapper, handleRetry && classes.click)}
          onClick={handleRetry}
        >
          <Icon name={icon} fontSize="large" className={classes.icon} />
          <span className={classes.title}>{title}</span>
          <span className={classes.message}>{message}</span>
        </div>
      </div>
    </ThumbnailImageWrapper>
  );
};

export default ThumbnailError;
