import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardProps } from '../types';
import {
  isPATApplicationEnabledForTenant,
  useGetAllTenantApplications,
  useGetUserDefaultTenantId,
} from '@apollo-red/hooks';
import BaseDashboards from './BaseDashboards';

const BaseAllDashboards: React.FC<DashboardProps> = ({
  reports,
  dashboardRoutes,
  useSaveHandler,
  showKeyMetrics,
  drag,
}) => {
  const { t } = useTranslation();
  const currentTenantId = useGetUserDefaultTenantId();
  const { applications } = useGetAllTenantApplications(currentTenantId);

  const isPATEnabled = isPATApplicationEnabledForTenant(applications);
  return (
    <BaseDashboards
      reports={reports}
      title={isPATEnabled ? t('allCoreDashboards') : t('allDashboards')}
      breadcrumbsParts={[
        { label: isPATEnabled ? t('allCoreDashboards') : t('allDashboards') },
      ]}
      breadcrumbsTitle={
        isPATEnabled ? t('allCoreDashboards') : t('allDashboards')
      }
      dashboardRoutes={dashboardRoutes}
      showKeyMetrics={showKeyMetrics}
      drag={drag}
      autoOverflow={true}
      useSaveHandler={useSaveHandler}
      categoryId="all"
    />
  );
};

export default BaseAllDashboards;
