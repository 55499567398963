import { InsightsAlertImportanceFilter } from '../insightsAlerts.types';
import { useGetInsightsInboxSettings } from './useGetInsightsInboxSettings';

export const useGetInsightsInboxImportanceFilter = () => {
  const { loading, inboxSettings } = useGetInsightsInboxSettings();

  return {
    alertImportanceFilter:
      inboxSettings?.insights_selected_filters.insights_importance_filter ??
      InsightsAlertImportanceFilter.All,
    loading,
  };
};
