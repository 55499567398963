import * as React from 'react';
import {
  useGetUserCategory,
  useGetUserReportById,
  isPATApplicationEnabledForTenant,
  useGetAllTenantApplications,
  useGetUserDefaultTenantId,
} from '@apollo-red/hooks';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@utils/launchDarkly';
import { FullReport } from '@components/fullReport';
import { TableauLicenseValidator } from '@components/tableau';
import { PageContainerContext } from '@components/pageContainer';
import { useRoutes } from '@routes';
import { useParamsCustom } from '@utils/router';
import DashboardTitle from '@components/dashboardTitle';
import { Viz } from '@components/tableau/types';
import { useTableauDataAvailability } from '@utils/tableau';
import { BaseDashboardsPageWrapper } from '../baseDashboards';

interface MatchParams {
  categoryId: string;
  dashboardId: string;
}

const Dashboard: React.FC = () => {
  const routes = useRoutes();
  const { dashboardId, categoryId } = useParamsCustom<MatchParams>();
  const { closeSidebar } = React.useContext(PageContainerContext);
  const { report } = useGetUserReportById(dashboardId);
  const { category } = useGetUserCategory(categoryId);
  const { t } = useTranslation();
  const [viz, setViz] = React.useState<Viz | undefined>(undefined);
  const { dataQuality, dataQualityExplanation } =
    useTableauDataAvailability(viz);
  const isDashboardsCoreFiltersEnabled = useFeatureFlag(
    'dashboardsCoreFilters',
  );

  const currentTenantId = useGetUserDefaultTenantId();
  const { applications } = useGetAllTenantApplications(currentTenantId);

  const isPATEnabled = isPATApplicationEnabledForTenant(applications);

  const specialCategoryIds = {
    all: isPATEnabled ? 'allCoreDashboards' : 'allDashboards',
    favorites: 'favorites',
    saved: 'mySaved',
  };

  const categoryName = specialCategoryIds[categoryId]
    ? t(specialCategoryIds[categoryId])
    : category.category_description;

  React.useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  if (!report) {
    return null;
  }

  return (
    <BaseDashboardsPageWrapper
      key={report.id}
      breadcrumbsTitle={isPATEnabled ? t('coreDashboards') : t('dashboards')}
      breadcrumbsParts={[
        {
          label: categoryName,
          link: routes.dashboards.category.getLink(categoryId),
        },
        { label: report.report_name },
      ]}
      showKeyMetrics={false}
      showToolbar={false}
      title={
        <DashboardTitle
          title={report.report_name}
          dataQuality={dataQuality}
          dataQualityExplanation={dataQualityExplanation}
        />
      }
      noRightSidePadding={isDashboardsCoreFiltersEnabled ? true : false}
    >
      {() => (
        <TableauLicenseValidator height="50vh">
          <FullReport report={report} setVizToDashboard={setViz} />
        </TableauLicenseValidator>
      )}
    </BaseDashboardsPageWrapper>
  );
};

export default Dashboard;
