import { useGetInsightsInboxAlertState } from '../inbox';
import { useGetInsightsAlertUserHistoryQuery } from './useGetInsightsAlertUserHistoryQuery';

export const useGetInsightsAlertUserHistory = (threadId: string) => {
  const { userId, tenantId } = useGetInsightsInboxAlertState();
  const { loading, data, fetchMore, variables, networkStatus } =
    useGetInsightsAlertUserHistoryQuery({
      userId,
      tenantId,
      threadId,
    });

  const { alert_user_history, thread_id } =
    data?.getUser?.user_insights_alert ?? {};

  const { continuation_token, user_history } = alert_user_history || {};

  const loadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        whereInsightsAlertUserHistory: {
          ...variables?.whereInsightsAlertUserHistory,
          continuation_token,
        },
      },
    });
  };

  return {
    thread_id,
    loading,
    userHistory: user_history,
    loadMore,
    hasMore: !!continuation_token,
    networkStatus,
  };
};
