import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useGetUserCurrentTenantRegularSettings } from '../settings';

export type UserAlertRuleSettings = NonNullable<
  SchemaTypes.UserTenantRegularSettingsBaseFragment['user_alert_rule_builder_settings']
>;

export type UserAlertRule = SchemaTypes.UserAlertSettingDataBaseFragment;

/* eslint-disable-next-line */
export import UserAlertRuleType = SchemaTypes.TenantAlertSettingsType;

export const useGetUserAlertRuleSettings = (): {
  rules: UserAlertRule[];
  loading: boolean;
} => {
  const { userTenantRegularSettings, loading: tenantRegularSettingsLoading } =
    useGetUserCurrentTenantRegularSettings();

  const rules =
    userTenantRegularSettings?.user_alert_rule_builder_settings || [];

  return {
    rules,
    loading: tenantRegularSettingsLoading,
  };
};
