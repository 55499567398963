import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Box, Grid, Theme, useTheme } from '@mui/material';
import { Icon } from '@jll-labs/azara-ui-components';
import { SystemStyleObject } from '@mui/system';

interface Props {
  item: {
    link: string;
    path: string;
    label: string;
    short: React.ReactNode;
    expanded: React.ReactNode;
    expandable?: boolean;
  };
  isSidebarExpanded: boolean;
}

const useStyles = (): Record<'root' | 'selected', SystemStyleObject<Theme>> => {
  const theme = useTheme();

  return {
    root: {
      cursor: 'pointer',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      position: 'relative',
      borderRadius: '2px',
      transition: 'background-color 0.2s',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fill: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  };
};

const MenuItem: React.FC<Props> = ({ isSidebarExpanded, item, children }) => {
  const [expandedItem, setExpandedItem] = React.useState(true);
  const styles = useStyles();
  const selected = useRouteMatch({
    path: item.path,
    strict: false,
    sensitive: false,
    exact: false,
  });
  const history = useHistory();
  const showExpandedItem = selected && expandedItem && isSidebarExpanded;
  const Arrow = showExpandedItem ? 'ExpandLess' : 'ExpandMore';
  const isExpandable = item.expandable ?? true;

  const handleMenuItemClick = () => {
    if (!selected) {
      history.push(item.link);
      setExpandedItem(true);
    } else if (isSidebarExpanded) {
      setExpandedItem(current => !current);
    }
  };

  return (
    <div>
      <Box
        onClick={handleMenuItemClick}
        sx={[styles.root, selected && styles.selected]}
        my={2}
        data-testid={`menuitem-container`}
      >
        <Grid
          container={true}
          alignItems="center"
          justifyContent={isSidebarExpanded ? 'space-between' : 'center'}
          wrap="nowrap"
          data-testid={`menuitem-${item.label}-container`}
        >
          <div>{isSidebarExpanded ? item.expanded : item.short}</div>
          {isSidebarExpanded && isExpandable ? (
            <Icon data-testid={`menuitem-arrow`} name={Arrow} />
          ) : null}
        </Grid>
      </Box>
      {showExpandedItem && <div>{children}</div>}
    </div>
  );
};

export default MenuItem;
