import * as React from 'react';
import { useHistory } from 'react-router';
import { useRoutes } from '@routes';
import { useGetUserInfo, useUpdateUserAgreement } from '@apollo-red/hooks';
import { useLogout } from '@components/okta';
import { useFeatureFlag } from '@utils/launchDarkly';
import TermsAndConditions from './TermsAndConditions';

const UserAgreement: React.FC = ({ children }) => {
  const routes = useRoutes();
  const [loggingOut, setLoggingOut] = React.useState(false);
  const history = useHistory();
  const { user } = useGetUserInfo();
  const { updateUserAgreement } = useUpdateUserAgreement();
  const { logout } = useLogout();
  const userOnboardingLegalTermsAcceptance = useFeatureFlag(
    'userOnboardingLegalTermsAcceptance',
  );

  if (!userOnboardingLegalTermsAcceptance) {
    return <>{children}</>;
  }

  const handleConfirm = () => {
    updateUserAgreement(user?.id, {
      terms_accepted: true,
    });
    history.push(routes.dashboards.favorites.getLink());
  };

  const handleCancel = async () => {
    setLoggingOut(true);
    await logout();
  };

  const didUserAcceptedTermsAndConditions =
    user?.user_agreement?.terms_accepted;

  if (!didUserAcceptedTermsAndConditions) {
    return (
      <TermsAndConditions
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        loggingOut={loggingOut}
      />
    );
  }

  return <>{children}</>;
};

export default UserAgreement;
