import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip, useTheme } from '@mui/material';
import { Icon, IconName, utils } from '@jll-labs/azara-ui-components';
import { useRoutes } from '@routes';
import { hasTenantFeature } from '@components/featureToggle';
import {
  isApplicationEnabled,
  isPATApplicationEnabledForTenant,
  TenantType,
  useGetAllTenantApplications,
  useGetTenant,
  useGetUserDefaultTenantId,
} from '@apollo-red/hooks';
import { useFeatureFlag } from '@utils/launchDarkly';
import {
  MenuItems,
  MenuItemsLabels,
  MenuItemsInfo,
  MenuItem,
  NavItemsProps,
  MenuAlertsSubItems,
  MenuSettingsSubItems,
  MenuClientSettingsSubItems,
  MenuProductSettingsSubItems,
  MenuDataManagementSubItems,
} from './navHelpers.types';

const { useSpacingStyles } = utils;

const useStyles = () => {
  const theme = useTheme();
  return {
    notification: {
      textAlign: 'center',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      padding: '0.1em 0.2em',
      margin: '0 1em',
      borderRadius: '5px',
      border: `1px solid ` + theme.palette.error.contrastText,
    },
    notificationCollpased: {
      width: '2.5em',
      height: '1.7em',
      position: 'absolute',
      right: '-0.7em',
      margin: 0,
      top: 'calc(50% - 0.85em)',
      zIndex: 100,
    },
    portscape: {
      backgroundColor: theme.palette.text.secondary,
      textAlign: 'center',
      color: theme.palette.common.white,
      padding: theme.spacing(0, 0.5),
      margin: theme.spacing(0, 1),
      borderRadius: theme.spacing(0.5),
    },
  };
};

export const NavShort: React.FC<{
  iconName: IconName;
}> = ({ iconName }) => {
  return <Icon name={iconName} />;
};

export const NavExpanded: React.FC<{
  iconName: IconName;
  label: string;
}> = ({ iconName, label }) => {
  const spacing = useSpacingStyles();
  return (
    <Grid container={true} alignItems="center">
      <Box sx={spacing.mrLg}>
        <Icon name={iconName} />
      </Box>
      <div>{label}</div>
    </Grid>
  );
};

const getUnreadAlerts = (unread: number) => {
  return unread >= 100 ? '99+' : String(unread);
};

export const NavNotificationShort: React.FC<{
  value?: string;
  iconName: IconName;
}> = ({ value, iconName }) => {
  const styles = useStyles();
  return (
    <div>
      <Icon name={iconName} />
      {value ? (
        <Box sx={[styles.notification, styles.notificationCollpased]}>
          {value}
        </Box>
      ) : null}
    </div>
  );
};

export const NavNotificationExpanded: React.FC<{
  value?: string;
  label: MenuItemsLabels;
  iconName: IconName;
}> = ({ value, iconName, label }) => {
  const styles = useStyles();
  const spacing = useSpacingStyles();
  return (
    <Grid container={true} wrap="nowrap" alignItems="center">
      <Box sx={spacing.mrLg}>
        <Icon name={iconName} />
      </Box>
      <div>{label}</div>
      {value ? <Box sx={styles.notification}>{value}</Box> : null}
    </Grid>
  );
};

export const UnassignedUsersShort: React.FC<{
  unassigned: number;
}> = ({ unassigned }) => {
  return (
    <Tooltip title="Unassigned users">
      <div>
        <NavNotificationShort
          iconName="ClientAdmin"
          value={String(unassigned)}
        />
      </div>
    </Tooltip>
  );
};

export const UnassignedUsersExpanded: React.FC<{
  unassigned: number;
}> = ({ unassigned }) => {
  return (
    <Tooltip title="Unassigned users">
      <div>
        <NavNotificationExpanded
          value={String(unassigned)}
          iconName="ClientAdmin"
          label={MenuItemsLabels.ClientSettings}
        />
      </div>
    </Tooltip>
  );
};

export const NavAlertsShort: React.FC<{
  unread: number;
}> = ({ unread }) => {
  return (
    <NavNotificationShort
      iconName="Notifications"
      value={unread ? getUnreadAlerts(unread) : ''}
    />
  );
};

export const NavAlertsExpanded: React.FC<{
  unread: number;
}> = ({ unread }) => {
  return (
    <NavNotificationExpanded
      value={unread ? `${getUnreadAlerts(unread)} new` : ''}
      iconName="Notifications"
      label={MenuItemsLabels.InsightsAlerts}
    />
  );
};

export const NavInsightsAlertsExpanded: React.FC<{
  unread: number;
}> = ({ unread }) => {
  return (
    <NavNotificationExpanded
      value={unread ? `${getUnreadAlerts(unread)} new` : ''}
      iconName="Notifications"
      label={MenuItemsLabels.InsightsAlerts}
    />
  );
};

export const NavPortscapeExpanded: React.FC<{
  badge: string;
  label: MenuItemsLabels;
  iconName: IconName;
}> = ({ badge, iconName, label }) => {
  const styles = useStyles();
  const spacing = useSpacingStyles();
  return (
    <Grid container={true} wrap="nowrap" alignItems="center">
      <Box sx={spacing.mrLg}>
        <Icon name={iconName} />
      </Box>
      <div>{label}</div>
      <Box sx={styles.portscape}>{badge}</Box>
    </Grid>
  );
};

export const NavSelfServeAnalyticsExpanded: React.FC<{
  label: MenuItemsLabels;
  iconName: IconName;
}> = ({ label, iconName }) => {
  const spacing = useSpacingStyles();
  return (
    <Grid container={true} wrap="nowrap" alignItems="center">
      <Box sx={spacing.mrLg}>
        <Icon name={iconName} />
      </Box>
      <div>{label}</div>
    </Grid>
  );
};

export const useCreateMenuItems = (info?: MenuItemsInfo): MenuItem[] => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const currentTenantId = useGetUserDefaultTenantId();
  const { applications } = useGetAllTenantApplications(currentTenantId);
  const { tenant } = useGetTenant(currentTenantId);

  const isPATEnabled = isPATApplicationEnabledForTenant(applications);
  const isPropertyDataEnabled = useFeatureFlag('dataManagementPropertyData');
  const isMdmApplicationEnabled = isApplicationEnabled('MDM', applications);
  const isNonCsTenant = tenant?.tenant_type === TenantType.NonCs;
  const isPortscapeEnabled =
    useFeatureFlag('productSettingsClientsPortscapeAccess') &&
    !!tenant?.tenant_features?.portscape;
  const isEnvironmentSetupEnabled = useFeatureFlag(
    'productSettingsEnvironmentSetup',
  );
  const isUserClassificationEnabled = useFeatureFlag(
    'productSettingsUserClassification',
  );
  const isSelfServeAnalyticsEnabled =
    (useFeatureFlag('clientSettingsCustomReportsSelfServeAnalytics') &&
      tenant?.tenant_custom_reports_settings
        ?.tenant_self_serve_analytics_settings?.self_serve_analytics_enabled) ??
    false;

  return [
    {
      id: MenuItems.Dashboards,
      label: MenuItemsLabels.Dashboards,
      short: <NavShort iconName="TrendingUp" />,
      expanded: (
        <NavExpanded
          iconName="TrendingUp"
          label={
            isPATEnabled
              ? MenuItemsLabels.AzaraDashboards
              : MenuItemsLabels.Dashboards
          }
        />
      ),
      link: routes.dashboards.favorites.getLink(),
      path: routes.client.dashboards.path,
      navItems: (navItems: NavItemsProps) => navItems.dashboards,
    },
    {
      id: MenuItems.Dashboards,
      label: MenuItemsLabels.PATDashboards,
      short: <NavShort iconName="LegacyDashboard" />,
      expanded: (
        <NavExpanded
          iconName="LegacyDashboard"
          label={MenuItemsLabels.PATDashboards}
        />
      ),
      link: routes.PATDashboards.favorites.getLink(),
      path: routes.client.PATDashboards.path,
      navItems: (navItems: NavItemsProps) => navItems.PATDashboards,
      restrict: _ => isPATEnabled,
    },
    {
      id: MenuItems.InsightsAlerts,
      label: MenuItemsLabels.InsightsAlerts,
      short: <NavAlertsShort unread={info?.insightsUnreadAlertsCount ?? 0} />,
      expanded: (
        <NavInsightsAlertsExpanded
          unread={info?.insightsUnreadAlertsCount ?? 0}
        />
      ),
      link: routes.insightsAlerts.inbox.getLink('all'),
      path: routes.client.insightsAlerts.path,
      restrict: user =>
        hasTenantFeature(['alerts'], user.user_settings.default_tenant),
      navItems: () => [
        {
          id: MenuAlertsSubItems.All,
          label: `All (${info?.insightsAllAlertsCount ?? 0})`,
          link: routes.insightsAlerts.inbox.getLink('all'),
        },
        {
          id: MenuAlertsSubItems.AssignToMe,
          label: 'Assigned to Me',
          link: routes.insightsAlerts.inbox.getLink('assigned-to-me'),
        },
        {
          id: MenuAlertsSubItems.Read,
          label: 'Read',
          link: routes.insightsAlerts.inbox.getLink('read'),
        },
        {
          id: MenuAlertsSubItems.Unread,
          label: `Unread (${info?.insightsUnreadAlertsCount ?? 0})`,
          link: routes.insightsAlerts.inbox.getLink('unread'),
        },
        {
          id: MenuAlertsSubItems.SavedForLater,
          label: 'Saved for Later',
          link: routes.insightsAlerts.inbox.getLink('saved-for-later'),
        },
        {
          id: MenuAlertsSubItems.DataQualityReview,
          label: 'Data Quality Review',
          link: routes.insightsAlerts.inbox.getLink('data-quality-review'),
        },
        {
          id: MenuAlertsSubItems.Completed,
          label: 'Closed',
          link: routes.insightsAlerts.inbox.getLink('closed'),
        },
      ],
    },
    {
      id: MenuItems.Settings,
      label: MenuItemsLabels.Settings,
      short: <NavShort iconName="Settings" />,
      expanded: (
        <NavExpanded iconName="Settings" label={MenuItemsLabels.Settings} />
      ),
      link: routes.settings.accountSettings.getLink(),
      path: routes.client.settings.path,
      navItems: () => [
        {
          id: MenuSettingsSubItems.AccountSettings,
          label: 'Account Settings',
          link: routes.settings.accountSettings.getLink(),
        },
        {
          id: MenuSettingsSubItems.DashboardSettings,
          label: t('dashboardSettings'),
          link: routes.settings.reportSettings.getLink(),
        },
        {
          id: MenuSettingsSubItems.AlertSettings,
          label: 'Alert Settings',
          link: routes.settings.alertSettings.getLink(),
          restrict: user =>
            hasTenantFeature(['alerts'], user.user_settings.default_tenant),
        },
      ],
    },
    {
      id: MenuItems.ClientSettings,
      label: MenuItemsLabels.ClientSettings,
      short:
        info?.unassignedUsers ?? 0 ? (
          <UnassignedUsersShort unassigned={info?.unassignedUsers ?? 0} />
        ) : (
          <NavShort iconName="ClientAdmin" />
        ),
      expanded:
        info?.unassignedUsers ?? 0 ? (
          <UnassignedUsersExpanded unassigned={info?.unassignedUsers ?? 0} />
        ) : (
          <NavExpanded
            iconName="ClientAdmin"
            label={MenuItemsLabels.ClientSettings}
          />
        ),
      link: routes.clientSettings.users.getLink(),
      path: routes.client.clientSettings.path,
      navItems: () => [
        {
          id: MenuClientSettingsSubItems.UserManagement,
          label:
            info?.unassignedUsers ?? 0
              ? `${t('userManagement')} (${info?.unassignedUsers ?? 0})`
              : t('userManagement'),
          link: routes.clientSettings.users.getLink(),
        },
        {
          id: MenuClientSettingsSubItems.RoleManagement,
          label: t('roleManagement'),
          link: routes.clientSettings.roleManagement.getLink(),
        },
        {
          id: MenuClientSettingsSubItems.CustomReports,
          label: t('customReports'),
          link: routes.clientSettings.customReports.getLink(),
        },
        {
          id: MenuClientSettingsSubItems.ConfigDataSetup,
          label: t('configDataSetup'),
          link: routes.clientSettings.configDataSetup.getLink(),
        },
        {
          id: MenuClientSettingsSubItems.KeywordManagement,
          label: t('keywordManagement'),
          link: routes.keywordsTenantAdmin.dashboards.getLink(),
        },
        {
          id: MenuClientSettingsSubItems.DashboardSettings,
          label: isPATEnabled
            ? t('coreDashboardSettings')
            : t('dashboardSettings'),
          link: routes.clientSettings.dashboardSettings.getLink(),
        },
        {
          id: MenuClientSettingsSubItems.PatReportsConfig,
          label: t('patReportsConfig'),
          link: routes.clientSettings.PATDashboards.getLink(),
          restrict: _ => isPATEnabled,
        },
      ],
    },
    {
      id: MenuItems.ProductSettings,
      label: MenuItemsLabels.ProductSettings,
      short: <NavShort iconName="SystemAdmin" />,
      expanded: (
        <NavExpanded
          iconName="SystemAdmin"
          label={MenuItemsLabels.ProductSettings}
        />
      ),
      link: routes.productSettings.tenants.getLink(),
      path: routes.client.productSettings.path,
      navItems: () => [
        {
          id: MenuProductSettingsSubItems.Clients,
          label: 'Clients',
          link: routes.productSettings.tenants.getLink(),
        },
        {
          id: MenuProductSettingsSubItems.Applications,
          label: 'Applications',
          link: routes.productSettings.applications.getLink(),
        },
        {
          id: MenuProductSettingsSubItems.Roles,
          label: 'Roles',
          link: routes.productSettings.roles.getLink(),
        },
        {
          id: MenuProductSettingsSubItems.UserClassification,
          label: t('userClassification'),
          link: routes.productSettings.userClassification.getLink(),
          restrict: _ => isUserClassificationEnabled,
        },
        {
          id: MenuProductSettingsSubItems.KeywordManagement,
          label: t('keywordManagement'),
          link: routes.productSettings.keywords.getLink(),
        },
        {
          id: MenuProductSettingsSubItems.EnvironmentSetup,
          label: t('environment_setup'),
          link: routes.productSettings.environmentSetup.getLink(),
          restrict: _ => isEnvironmentSetupEnabled,
        },
      ],
    },
    {
      id: MenuItems.DataManagement,
      label: MenuItemsLabels.DataManagement,
      short: <NavShort iconName="DataUsage" />,
      expanded: (
        <NavExpanded
          iconName="DataUsage"
          label={MenuItemsLabels.DataManagement}
        />
      ),
      link: routes.dataManagement.dataLoads.getLink(),
      path: routes.client.dataManagement.path,
      navItems: () => [
        {
          id: MenuDataManagementSubItems.DataLoads,
          label: t('dataLoads'),
          link: routes.dataManagement.dataLoads.getLink(),
        },
        {
          id: MenuDataManagementSubItems.DataStandards,
          label: t('dataStandards'),
          link: routes.dataManagement.dataStandards.getLink(),
        },
        {
          id: MenuDataManagementSubItems.DataViews,
          label: 'Data Views',
          link: routes.dataManagement.dataViews.getLink(),
        },
        {
          id: MenuDataManagementSubItems.PropertyData,
          label: t('propertyData'),
          link: routes.dataManagement.propertyData.getLink(),
          restrict: () =>
            isPropertyDataEnabled && isMdmApplicationEnabled && isNonCsTenant,
        },
      ],
    },
    {
      id: MenuItems.JLLPortscape,
      label: MenuItemsLabels.JLLPortscape,
      short: <NavShort iconName="Portscape" />,
      expanded: (
        <NavPortscapeExpanded
          label={MenuItemsLabels.JLLPortscape}
          iconName="Portscape"
          badge="BETA"
        />
      ),
      expandable: false,
      link: routes.jllPortscape.home.getLink(),
      path: routes.client.jllPortscape.path,
      navItems: () => [],
      restrict: _ => isPortscapeEnabled,
    },
    {
      id: MenuItems.SelfServeAnalytics,
      label: MenuItemsLabels.SelfServeAnalytics,
      short: <NavShort iconName="Timeline" />,
      expanded: (
        <NavSelfServeAnalyticsExpanded
          label={MenuItemsLabels.SelfServeAnalytics}
          iconName="Timeline"
        />
      ),
      expandable: false,
      link: routes.selfServeAnalytics.home.getLink(),
      path: routes.client.selfServeAnalytics.path,
      navItems: () => [],
      restrict: _ => isSelfServeAnalyticsEnabled,
    },
  ];
};
