import * as React from 'react';
import {
  TableCell,
  TableRow,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { useAlertTableRowStyles } from './useAlertTableRowStyles';

interface AlertLoadingTableRowProps {
  colSpan?: number;
  loader?: 'circular' | 'linear';
}

const AlertLoadingTableRow: React.FC<AlertLoadingTableRowProps> = ({
  colSpan = 5,
  loader = 'circular',
}) => {
  const styles = useAlertTableRowStyles();

  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        sx={styles.progressTableCell}
        size="small"
        align="center"
      >
        {loader === 'circular' && (
          <CircularProgress
            disableShrink
            size={25}
            sx={styles.circularProgress}
          />
        )}
        {loader !== 'circular' && <LinearProgress sx={styles.linearProgress} />}
      </TableCell>
    </TableRow>
  );
};

export default AlertLoadingTableRow;
