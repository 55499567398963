import * as React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { MessagePopper, utils } from '@jll-labs/azara-ui-components';
import { InsightsAlert } from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { alertsRecommendation } from '../alertRecommendations';

const { useToggleValue } = utils;

const useStyles = makeStyles((theme: AppTheme) => ({
  text: {
    marginRight: '1em',
  },
  anchorText: {
    color: `${theme.palette.action.active} !important`,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

interface Props {
  alert: InsightsAlert;
}
const AlertRecommendations: React.FC<Props> = ({ alert }) => {
  const [open, toggleOpen] = useToggleValue(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const classes = useStyles();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  const handleToggle = () => {
    toggleOpen();
  };

  const handleClose = () => {
    toggleOpen();
  };

  const handleRecommendedActionsClick = () => {
    trackInsightsAlertEvent('alertShowRecommendation', {
      alertThreadId: alert?.thread_id ?? '',
      alertType: alert?.alert_type ?? '',
    });
  };

  return (
    <div>
      <Grid container={true} alignItems="center" onClick={handleToggle}>
        <div className={classes.text}>
          See recommended actions{' '}
          <span
            className={classes.anchorText}
            onClick={handleRecommendedActionsClick}
          >
            here
          </span>
        </div>
        <div ref={setAnchorEl}></div>
      </Grid>
      <MessagePopper
        message={{
          position: 'right',
        }}
        isOpen={open}
        anchorEl={anchorEl}
        handleConfirmClick={handleClose}
        handleClose={handleClose}
        showDismissButton={false}
      >
        {alertsRecommendation[alert.alert_type]}
      </MessagePopper>
    </div>
  );
};

export default AlertRecommendations;
