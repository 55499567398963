import * as React from 'react';
import {
  useLogError,
  useLogPerformance,
  LogPerformanceContextPayload,
} from '@apollo-red/hooks';
import {
  isExternalTableauHostedReport,
  useTableauViewEventTracking,
} from '@utils/tableau';
import { Viz } from './types';
import { TableauViewBaseProps } from './TableauViewBase';
import TableauViewWithTrustedAuth from './TableauViewWithTrustedAuth';
import TableauViewWithTrustedAuthExternalReport from './TableauViewWithTrustedAuthExternalReport';

export interface TableauViewLoggingProps extends TableauViewBaseProps {
  logContext: LogPerformanceContextPayload;
}

export const TableauViewWithLogging: React.FC<TableauViewLoggingProps> = ({
  logContext,
  isExternalReport,
  ...baseProps
}) => {
  const {
    dashboardTrackEventContext,
    initTimestamp,
    duration,
    trackFirstInteractive,
    trackVizDispose,
  } = useTableauViewEventTracking();
  const { logError } = useLogError();
  const { logPerformance } = useLogPerformance();

  const handleFirstInteractive = (viz: Viz) => {
    trackFirstInteractive(viz);

    const vizLoadedTimestamp = new Date().getTime();
    const duration = vizLoadedTimestamp - initTimestamp;

    logPerformance({
      duration,
      context: logContext,
    });

    if (baseProps.handleFirstInteractive) {
      baseProps.handleFirstInteractive(viz);
    }
  };

  const handleInitError = (e: Error) => {
    logError(e);
  };

  const handleVizDispose = (viz: Viz) => {
    trackVizDispose(viz);

    if (baseProps.handleVizDispose) {
      baseProps.handleVizDispose(viz);
    }
  };

  const TableauViewWithTrustedReportAuth = isExternalReport
    ? isExternalTableauHostedReport(baseProps.url)
      ? TableauViewWithTrustedAuthExternalReport
      : TableauViewWithTrustedAuth
    : TableauViewWithTrustedAuth;

  return (
    <>
      <TableauViewWithTrustedReportAuth
        {...baseProps}
        tenantId={logContext.tenant_id}
        reportId={logContext.report_id}
        originalReportId={logContext.original_report_id ?? undefined}
        handleFirstInteractive={handleFirstInteractive}
        handleInitError={handleInitError}
        handleVizDispose={handleVizDispose}
      />
      <div
        style={{ visibility: 'hidden' }}
        data-testid={`report-perf-${logContext.report_id}`}
        data-report-type={logContext.report_type}
        data-report-name={dashboardTrackEventContext.dashboardName ?? ''}
        data-duration={duration}
      />
    </>
  );
};

export default TableauViewWithLogging;
