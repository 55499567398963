import { v4 as uuid } from 'uuid';
import { useGetBlobStorageUploadSasUrl } from '@apollo-red/hooks/blobStorage';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_PICTURE } from '@apollo-red/mutations';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { utils } from '@jll-labs/azara-ui-components';
import { useGetUserInfo } from '../user';

export const useUpdateUserPicture = () => {
  const {
    user: { id },
  } = useGetUserInfo();

  const [updatePicture] = useAppMutation<
    SchemaTypes.UpdateUserPictureMutation,
    SchemaTypes.UpdateUserPictureMutationVariables
  >(UPDATE_USER_PICTURE);

  return {
    updateUserPicture: (userPicture: string) =>
      updatePicture({
        variables: {
          where: { id: id },
          data: { user_picture_url: [userPicture] },
        },
      }),
  };
};

export const useUploadUserPicture = () => {
  const { getBlobStorageUploadSasUrl } = useGetBlobStorageUploadSasUrl();
  const { updateUserPicture } = useUpdateUserPicture();
  const {
    user: { id },
  } = useGetUserInfo();

  return {
    uploadUserPicture: async (file: File) => {
      const sasUrl = await getBlobStorageUploadSasUrl(
        `${process.env.CLIENT_API_ENV}-user-avatars`,
        id,
      );
      const fileName = uuid();

      await utils.uploadFile({
        file,
        filePath: fileName,
        sasContainerUrl: sasUrl,
      });
      await updateUserPicture(fileName);
    },
  };
};
