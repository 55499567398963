import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Button, Icon } from '@jll-labs/azara-ui-components';
import { DocumentationLink } from '@components/link';
import { usePendoTrackDashboardEvent } from '@utils/pendo';
import { DashboardTrackEventContext } from '../FullReportContextProvider';

interface Props {
  disabled?: boolean;
  documentationTopic: string;
  dashboardTrackEventContext: DashboardTrackEventContext;
}

const ToolbarHelpItem: React.FC<Props> = ({
  disabled = false,
  documentationTopic,
  dashboardTrackEventContext,
}) => {
  const { t } = useTranslation();
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();

  const handleHelpClick = () => {
    trackDashboardEvent('dashboardHelp', dashboardTrackEventContext);
  };

  return (
    <Box
      component="span"
      mr={2}
      mb={2}
      display="inline-block"
      onClick={handleHelpClick}
    >
      <DocumentationLink topic={documentationTopic}>
        <Button
          variant="text"
          color="primary"
          disabled={disabled}
          data-testid="documentation"
        >
          <Icon name="HelpOutline" />
          <Box component="span" mx={2} my={0}>
            {t('help')}
          </Box>
        </Button>
      </DocumentationLink>
    </Box>
  );
};

export default ToolbarHelpItem;
