import * as React from 'react';
import {
  Dashboard,
  MarksEvent,
  SheetType,
  TableauEventName,
  Viz,
  Worksheet,
} from '@components/tableau/types';

export interface WorksheetMarksSelectionCount {
  [key: string]: number;
}

export const useMarksSelection = (viz?: Viz) => {
  const [marksCount, setMarksCount] =
    React.useState<WorksheetMarksSelectionCount>({});

  const clearMarksSelection = React.useCallback(async () => {
    if (!viz) {
      return 0;
    }

    const activeSheet = viz.getWorkbook().getActiveSheet();
    const activeSheetType = activeSheet.getSheetType();
    let worksheets: Worksheet[];

    if (activeSheetType === SheetType.DASHBOARD) {
      const dashboard = activeSheet as Dashboard;
      worksheets = dashboard.getWorksheets() ?? [];
    } else if (activeSheetType === SheetType.WORKSHEET) {
      worksheets = [activeSheet] as Worksheet[];
    } else {
      // Story SheetTypes are not supported at this time.
      return 0;
    }

    if (worksheets.length === 0) {
      return 0;
    }

    const results = await Promise.all(
      Object.entries(marksCount).map(async ([worksheetName, count]) => {
        const worksheet = worksheets.find(
          sheet => sheet.getName() === worksheetName,
        ) as Worksheet;

        if (!worksheet || count === 0) {
          return Promise.resolve(0);
        }

        await worksheet.clearSelectedMarksAsync();
        return Promise.resolve(1);
      }),
    );

    return results.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
  }, [marksCount, viz]);

  const handleMarksSelection = React.useCallback((event: MarksEvent) => {
    if (!event) {
      return;
    }

    event.getMarksAsync().then(marks => {
      setMarksCount(marksSelection => {
        const worksheetName = event.getWorksheet().getName();
        return { ...marksSelection, [worksheetName]: marks?.length ?? 0 };
      });
    });
  }, []);

  React.useEffect(() => {
    if (viz) {
      viz.addEventListener(
        TableauEventName.MARKS_SELECTION,
        handleMarksSelection,
      );
    }

    return () => {
      viz?.removeEventListener(
        TableauEventName.MARKS_SELECTION,
        handleMarksSelection,
      );
    };
  }, [viz, handleMarksSelection]);

  return { marksCount, clearMarksSelection };
};
