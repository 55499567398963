import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { Country } from '@apollo-red/hooks';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_COUNTRY } from '@apollo-red/mutations';
import { useGetUserInfo } from '../../user';

export const useUpdateUserCountryAndRegion = () => {
  const { user } = useGetUserInfo();
  const [updateCountryAndRegion] =
    useAppMutation<SchemaTypes.UpdateUserCountryMutation>(UPDATE_USER_COUNTRY);

  return {
    updateCountryAndRegion: (country: Country) => {
      return updateCountryAndRegion({
        variables: {
          data: {
            user_address: {
              region: country.country_continent?.code,
              country: country.country_iso_code,
            },
          },
          where: {
            id: user.id,
          },
        },
        optimisticResponse: {
          updateUser: {
            id: user.id,
            user_address: {
              country: {
                id: country.country_iso_code,
                country_desc: country.country_desc,
                country_iso_code: country.country_iso_code,
                __typename: 'Country',
              },
              region: {
                id: country.country_continent?.code ?? '',
                region_name: country.country_continent?.name ?? '',
                __typename: 'Region',
              },
              __typename: 'Address',
            },
            __typename: 'User',
          },
        },
      });
    },
  };
};
