import { useUploadFileSasUrl } from './useUploadFileSasUrl';
import { utils } from '@jll-labs/azara-ui-components';

export const useUploadFile = (tenantId: string) => {
  const { uploadFileSasUrl } = useUploadFileSasUrl(tenantId);

  return {
    uploadFile: async (
      application: string,
      file: File,
      applicationSubCode?: string,
    ) => {
      const response = (await uploadFileSasUrl(application, applicationSubCode))
        .data?.uploadFileSasUrl;

      if (response) {
        await utils.uploadFile({
          file: file,
          filePath: `${response?.folder_path}/${file.name}`,
          sasContainerUrl: response?.sas_url,
          httpHeaders: {
            blobContentType: 'application/zip',
            blobContentEncoding: 'zip',
          },
        });
      }
    },
  };
};
