import { gql } from '@apollo/client';

export const DataLoadsJobLinksBase = {
  fragment: gql`
    fragment DataLoadsJobLinksBase on DataLoadsJobLinks {
      id
      source_download_url
      output_download_url
    }
  `,
};

export const DataLoadsJobBase = {
  fragment: gql`
    fragment DataLoadsJobBase on DataLoadsJob {
      id
      application {
        app_name
        app_description
      }
      application_sub_code {
        app_subcode
        app_subcode_description
      }
      submitted_by
      submitted_by_user {
        user_full_name
      }
      status
      created_at
      processing_started_at
      processing_ended_at
      file_source_name
      error_info {
        error_code
        description
      }
      record_info {
        total_count
        success_count
        failed_count
      }
    }
  `,
};

export const DataLoadsJobsBase = {
  fragment: gql`
    fragment DataLoadsJobsBase on DataLoadsJobs {
      cursor
      jobs {
        ...DataLoadsJobBase
      }
    }
    ${DataLoadsJobBase.fragment}
  `,
};
