import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from '@jll-labs/azara-ui-components';
import { Report } from '@apollo-red/hooks';
import AddReportsMenu from './AddReportsMenu';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '72vh',
  },
  innerBox: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  widgetContainer: {
    backgroundColor: theme.palette.action.selected,
    borderRadius: '50%',
    width: 275,
    height: 275,
    marginBottom: '2em',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tip: {
    fontSize: theme.typography.body2.fontSize,
    marginTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.action.active,
    margin: theme.spacing(0, 1),
  },
  subtitle: {
    color: theme.palette.primary.light,
    width: '80%',
  },
}));

interface Props {
  useSaveHandler: (reports: Report[]) => { saveHandler: () => void };
  isPATMenu?: boolean;
}

const AddFavsWidget: React.FC<Props> = ({ useSaveHandler, isPATMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.widgetContainer}>
      <div className={classes.innerBox}>
        <AddReportsMenu useSaveHandler={useSaveHandler} isPATMenu={isPATMenu} />
        <Grid container={true} alignItems="center" className={classes.tip}>
          <span>{t('addToFavorites')}</span>
          <Icon name="Favorite" className={classes.icon} />
        </Grid>
      </div>
    </div>
  );
};

const DashboardsNotFound: React.FC<Props> = ({ useSaveHandler, isPATMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container={true}
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <div className={classes.innerBox}>
        <AddFavsWidget useSaveHandler={useSaveHandler} isPATMenu={isPATMenu} />
        <Typography variant="h4">{t('noFavorites')}</Typography>
        <Typography variant="h6" component="p" className={classes.subtitle}>
          {t('noFavoritesLong')}
        </Typography>
      </div>
    </Grid>
  );
};

export default DashboardsNotFound;
