import * as React from 'react';
import { IconButton, FavoriteIcon } from '@jll-labs/azara-ui-components';
import { getFullReportEventTrackingType } from '@components/fullReport';
import {
  usePendoTrackDashboardEvent,
  DashboardToolbarEventMetadata,
} from '@utils/pendo';
import { ReportExtended } from '@apollo-red/hooks';

interface Props {
  report: ReportExtended;
  toggleFavoriteReport: () => void;
  dataTestId?: string;
}

export const getDashboardEventProps = (
  report: ReportExtended,
): DashboardToolbarEventMetadata => {
  const {
    id: dashboardId,
    report_name: dashboardName,
    primaryCategory: dashboardCategory,
    originalCategory: dashboardOriginalCategory,
    report_tableau_full_report_url: dashboardUrl,
  } = report;
  const dashboardType = getFullReportEventTrackingType(report);

  return {
    dashboardId,
    dashboardName,
    dashboardType,
    dashboardOriginalCategory,
    dashboardCategory,
    dashboardUrl,
  };
};

const FavoriteButton: React.FC<Props> = ({
  report,
  toggleFavoriteReport,
  dataTestId = '',
}) => {
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();
  const { isFav } = report;

  const onClick = () => {
    const dashboardEventProps = getDashboardEventProps(report);

    if (isFav) {
      trackDashboardEvent('dashboardUnfavorited', dashboardEventProps);
    } else {
      trackDashboardEvent('dashboardFavorited', dashboardEventProps);
    }

    toggleFavoriteReport();
  };

  return (
    <IconButton
      dataTestId={dataTestId}
      label={isFav ? 'Unfavorite' : 'Favorite'}
      size="small"
      onClick={onClick}
    >
      <FavoriteIcon fav={isFav} />
    </IconButton>
  );
};

export default FavoriteButton;
