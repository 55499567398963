import { formatInTimeZone } from 'date-fns-tz';

export const lastUpdateDateFromTimestamp = (timestamp?: string | null) => {
  if (!timestamp) {
    return '';
  }

  const milliseconds = parseInt(timestamp, 10);

  if (isNaN(milliseconds)) {
    return '';
  }

  const parsedDate = new Date(milliseconds);

  return formatInTimeZone(parsedDate, 'UTC', 'dd-MMM-yyyy HH:mm:ss z');
};
