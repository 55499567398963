import { useMemo } from 'react';
import { getEncodedLink } from '@utils/router';
import { UseProductSettingsRoutes } from '../../types';

export const useTenantProductSettingsRoutes = ({
  tenantSettings: { getLink, path },
}: UseProductSettingsRoutes) =>
  useMemo(
    () => ({
      dashboardSettings: {
        path: `${path}/dashboard-settings`,
        getLink: (tenantOvcId: string) =>
          getEncodedLink([getLink(tenantOvcId), 'dashboard-settings']),
      },
    }),
    [getLink, path],
  );
