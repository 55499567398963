import * as React from 'react';
import { useGetUserInfo, useGetUserDefaultTenant } from '@apollo-red/hooks';
import {
  PendoTrackEventCommonMetadata,
  PendoTrackEventType,
  PendoTrackEventMetadata,
  trackEventTypeToName,
} from './types';
import { usePendoEnvironmentPrefix } from './usePendoEnvironmentPrefix';

export const usePendoTrackEvent = () => {
  const environmentPrefix = usePendoEnvironmentPrefix();
  const { user } = useGetUserInfo();
  const { defaultTenant } = useGetUserDefaultTenant();
  const { id, user_email, user_type } = user;
  const { tenant_ovc_id, tenant_name } = defaultTenant ?? {};
  const accountIdPrefix = tenant_ovc_id ? environmentPrefix : '';
  const clientId = tenant_ovc_id ? `${accountIdPrefix}${tenant_ovc_id}` : '';
  const clientType = defaultTenant?.tenant_type ?? '';
  const userClassification = user_type ?? '';

  const trackEvent = React.useCallback(
    <T extends PendoTrackEventType>(
      trackEventType: T,
      trackEventMetadata: PendoTrackEventMetadata<T>,
    ) => {
      if (!window.pendo) {
        return;
      }

      const trackType = trackEventTypeToName[trackEventType];
      const commonMetadata: PendoTrackEventCommonMetadata = {
        userId: id,
        userEmail: user_email,
        userClassification,
        clientId,
        clientName: tenant_name ?? '',
        clientType,
      };
      const metadata: pendo.Metadata = {
        ...commonMetadata,
        ...trackEventMetadata,
      };

      pendo.track(trackType, metadata);
    },
    [clientId, clientType, id, tenant_name, userClassification, user_email],
  );

  return {
    trackEvent,
  };
};
