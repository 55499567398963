import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  IconButton,
  MenuSelect,
  utils,
} from '@jll-labs/azara-ui-components';
import { Box, Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  InsightsAlert,
  InsightsAlertUserStatus,
  InsightsAlertStatusUpdate,
  ROIData,
} from '@apollo-red/hooks';
import { RoiDialog } from '@components/roi';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { alertUserStatusTranslationKeys } from '../utils';
import OptionalCommentButton from './OptionalCommentButton';

interface Props {
  activeAlert: InsightsAlert;
  handleStatusChange: (
    threadId: string,
    status: InsightsAlertStatusUpdate,
    comment?: string,
  ) => void;
  handleROIDataChange: (threadId: string, roiData: ROIData) => void;
  roiData: ROIData;
}

const useStyles = makeStyles(() => ({
  roiIcon: {
    fontSize: '4.5rem',
  },
}));

const dismissedStatusOptions = translate => [
  {
    label: translate(alertUserStatusTranslationKeys.save_for_later),
    value: InsightsAlertUserStatus.SaveForLater,
  },
  {
    label: translate(alertUserStatusTranslationKeys.unable_to_action),
    value: InsightsAlertUserStatus.UnableToAction,
  },
];

const DismissButton = ({ handleBtnClick, forceClick }) => {
  const [clicked, setClicked] = React.useState(false);
  const ref = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    if (ref && ref.current && forceClick && !clicked) {
      ref.current.click();
      setClicked(true);
    }
  }, [ref, forceClick, clicked]);

  return (
    <Button
      ref={ref}
      color="primary"
      startIcon={<Icon name="Cancel" />}
      variant="outlined"
      onClick={handleBtnClick}
    >
      {'Dismiss'}
    </Button>
  );
};

const AlertStatusButtons: React.FC<Props> = ({
  activeAlert,
  handleStatusChange,
  handleROIDataChange,
  roiData,
}) => {
  const { thread_id, user_status, is_roi_enabled, alert_type } = activeAlert;
  const [dismissCommentBoxOpen, setDismissCommentBoxOpen] =
    React.useState(false);
  const [openRoi, toggleRoi] = utils.useToggleValue(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  const handleDismissCommentBoxClose = () => {
    setDismissCommentBoxOpen(false);
  };

  const handleDismissOptionSelect = option => {
    trackInsightsAlertEvent('alertDismiss', {
      alertThreadId: thread_id ?? '',
      alertType: alert_type ?? '',
    });

    if (option.value === InsightsAlertUserStatus.UnableToAction) {
      setDismissCommentBoxOpen(true);
    } else {
      if (handleStatusChange) {
        handleStatusChange(thread_id, option.value);
      }
    }
  };

  const handleROIDataSubmit = (values: ROIData) => {
    trackInsightsAlertEvent('alertRoi', {
      alertThreadId: thread_id ?? '',
      alertType: alert_type ?? '',
    });

    handleROIDataChange(thread_id, values);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Grid container={true} alignItems="center" wrap="wrap">
          <Box mr={1} my={0.5}>
            <OptionalCommentButton
              activeAlert={activeAlert}
              type={InsightsAlertStatusUpdate.Closed}
              handleStatusChange={handleStatusChange}
              startIcon={<Icon name="DoneCircle" />}
              variant="outlined"
            />
          </Box>
          <Box mr={1} my={0.5}>
            {dismissCommentBoxOpen ? (
              <OptionalCommentButton
                activeAlert={activeAlert}
                type={InsightsAlertStatusUpdate.UnableToAction}
                handleStatusChange={handleStatusChange}
                onClose={handleDismissCommentBoxClose}
                getButton={({ handleBtnClick }) => (
                  <DismissButton
                    handleBtnClick={handleBtnClick}
                    forceClick={true}
                  />
                )}
              />
            ) : (
              <MenuSelect
                options={dismissedStatusOptions(t)}
                value={user_status}
                onChange={handleDismissOptionSelect}
                getButton={({ handleBtnClick }) => (
                  <DismissButton
                    handleBtnClick={e => {
                      handleBtnClick(e);
                    }}
                    forceClick={false}
                  />
                )}
              />
            )}
          </Box>
          <Box mr={1} my={0.5}>
            <Button
              color="primary"
              startIcon={<Icon name="ProblemOutlined" />}
              variant="outlined"
              onClick={() => {
                trackInsightsAlertEvent('alertMarkedForDataQualityReview', {
                  alertThreadId: thread_id ?? '',
                  alertType: alert_type ?? '',
                });

                return handleStatusChange(
                  thread_id,
                  InsightsAlertStatusUpdate.DataQualityReview,
                  '',
                );
              }}
            >
              {t('dataQualityReview')}
            </Button>
          </Box>
        </Grid>
        <IconButton
          label="roi"
          disableHover={true}
          onClick={toggleRoi}
          size="large"
        >
          <Icon
            name="ROI"
            className={classes.roiIcon}
            checkmark={activeAlert?.is_roi_enabled}
          />
        </IconButton>
      </Box>
      <RoiDialog
        open={openRoi}
        toggleOpen={toggleRoi}
        handleSubmit={handleROIDataSubmit}
        values={roiData}
        edit={!is_roi_enabled}
      />
    </>
  );
};

export default AlertStatusButtons;
