import * as React from 'react';
import { FormValues } from './useUniversalFiltersForm';
import {
  useGetCurrentTenantUniversalFilters,
  useGetUserDefaultTenant,
} from '@apollo-red/hooks';

const mapValue = ({ value }) => value;

export const useGeoUniversalFiltersValues = ({
  countries,
  states,
  cities,
}: FormValues) => {
  const { defaultTenant } = useGetUserDefaultTenant();
  const isDemo = !!defaultTenant?.tenant_features.demo;

  const selectedCountryNodes = React.useMemo(() => {
    return countries.map(mapValue);
  }, [countries]);

  const selectedStateNodes = React.useMemo(() => {
    return states.map(mapValue);
  }, [states]);

  const selectedCityNodes = React.useMemo(() => {
    return cities.map(mapValue);
  }, [cities]);

  const { countries: tenantCountries, loading: countriesLoading } =
    useGetCurrentTenantUniversalFilters();

  const {
    states: tenantStates,
    cities: tenantCities,
    properties: tenantProperties,
    loading: nonCountryFiltersLoading,
  } = useGetCurrentTenantUniversalFilters(
    { country: selectedCountryNodes },
    isDemo ? false : selectedCountryNodes.length === 0,
  );

  const { cities: demoTenantCities, loading: demoTenantCitiesLoading } =
    useGetCurrentTenantUniversalFilters(
      {
        country: selectedCountryNodes,
        state: selectedStateNodes,
      },
      !isDemo,
    );

  const {
    properties: demoTenantProperties,
    loading: demoTenanPropertiesLoading,
  } = useGetCurrentTenantUniversalFilters(
    {
      country: selectedCountryNodes,
      state: selectedStateNodes,
      city: selectedCityNodes,
    },
    !isDemo,
  );

  const citiesLoading = demoTenantCitiesLoading || nonCountryFiltersLoading;
  const propertiesLoading =
    demoTenanPropertiesLoading || nonCountryFiltersLoading;
  const anyLoading =
    countriesLoading ||
    nonCountryFiltersLoading ||
    citiesLoading ||
    propertiesLoading;

  return {
    tenantCountries,
    countriesLoading,
    tenantStates,
    statesLoading: nonCountryFiltersLoading,
    groupStates: !isDemo,
    tenantCities: isDemo ? demoTenantCities : tenantCities,
    citiesLoading,
    groupCities: !isDemo,
    tenantProperties: isDemo ? demoTenantProperties : tenantProperties,
    propertiesLoading,
    groupProperties: !isDemo,
    anyLoading,
    byPassFilter: isDemo,
  };
};
