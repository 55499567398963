import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { InfiniteScrollingTable } from '@jll-labs/azara-ui-components';
import {
  InsightsAlertHeader,
  InsightsAlert,
  InsightsAlertInbox,
  InsightsAlertTypeFilter,
  InsightsAlertImportanceFilter,
  InsightsAlertSortOrder,
  InsightsAlertImportance,
  InsightsAlertSeverityFilter,
} from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import {
  useAlertTableHeader,
  AlertLoadingTableRow,
  useAlertTableRowStyles,
  AlertNoDataTableRow,
} from './alertTableRows';
import {
  InsightsAlertHeaderTableCell,
  InsightsAlertTableCell,
} from './alertTableCells';
import { alertsShortDescription } from './alertText';
import { Box } from '@mui/material';
import { Row } from '@jll-labs/azara-ui-components/types/Table/types';

const shouldScrollAlertIntoView = (
  alert: InsightsAlertHeader,
  activeAlertId?: string,
  preventScroll = false,
  isFirstRow = false,
): boolean => {
  if (preventScroll) {
    return false;
  }

  return activeAlertId === alert.thread_id || (!activeAlertId && isFirstRow);
};

interface AlertProps {
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isLoadingMore: boolean;
  inboxType: InsightsAlertInbox;
  alerts: InsightsAlert[];
  selectedAlertTypeFilter: InsightsAlertTypeFilter;
  selectedAlertImportanceFilter: InsightsAlertImportanceFilter;
  selectedSortOrders: InsightsAlertSortOrder;
  handleImportanceFilterChange: (selectedOption: any) => void;
  handleTypeFilterChange: (selectedOption: any) => void;
  handleAssignedUserFilterChange: (selectedOption: any) => void;
  handleSeverityFilterChange: (selectedOption: any) => void;
  handleSortByDate: () => void;
  handleAlertClick: (item: InsightsAlert) => void;
  activeAlert?: InsightsAlert;
  selectedAlertSeverityFilter: InsightsAlertSeverityFilter;
  selectedAlertAssignedToFilter: string;
  handleImportanceChange: (
    threadId: string,
    importance: InsightsAlertImportance,
  ) => void;
}

const AlertList: React.FC<AlertProps> = ({
  hasNextPage,
  loadMore,
  loading,
  isLoadingMore,
  alerts,
  handleImportanceFilterChange,
  handleTypeFilterChange,
  handleSortByDate,
  handleAlertClick,
  activeAlert,
  selectedAlertTypeFilter,
  selectedAlertImportanceFilter,
  selectedSortOrders,
  handleAssignedUserFilterChange,
  handleSeverityFilterChange,
  selectedAlertSeverityFilter,
  selectedAlertAssignedToFilter,
  handleImportanceChange,
}) => {
  const [isFetchingMore, setIsFetchingMore] = React.useState<boolean>(false);
  const [preventScroll, setPreventScroll] = React.useState<boolean>(false);
  const rowStyles = useAlertTableRowStyles();
  const alertsLength = alerts?.length ?? 0;
  const hasAlerts = alertsLength > 0;
  const { thread_id: activeAlertId } = activeAlert ?? {};
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  React.useEffect(() => {
    setPreventScroll(false);
  }, [activeAlertId]);

  const memoisedHandleImportanceChange = React.useCallback(
    (threadId: string, importance: InsightsAlertImportance) => {
      handleImportanceChange(threadId, importance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (!isFetchingMore) {
      return;
    }

    setIsFetchingMore(false);
    setPreventScroll(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMore]);

  const rows: Row[] = alerts.map((item, index) => {
    const handleClick = item => () => {
      handleAlertClick(item);
    };

    const canScrollIntoView = shouldScrollAlertIntoView(
      item,
      activeAlert?.thread_id,
      preventScroll,
      index === 0,
    );

    const rowStyle =
      activeAlert && activeAlert.thread_id === item.thread_id
        ? rowStyles.activeContainer
        : rowStyles.inactiveContainer;

    const userName = item?.user_info.find(
      user => user.user_id === item.assigned_user_id,
    )?.user_name;

    const shortDescription = alertsShortDescription[item.alert_type](item);

    return {
      id: item.thread_id,
      onClick: handleClick(item),
      colSpanOverride: 5,
      cells: [
        <InsightsAlertTableCell
          key={item.thread_id}
          threadId={item.thread_id}
          alertCount={item.alert_count}
          alertStatus={item.alert_status}
          alertType={item.alert_type}
          assignedUserId={item.assigned_user_id ?? ''}
          createdAt={item.created_at}
          userImportance={item.user_importance}
          severity={item.severity}
          userStatus={item.user_status}
          userName={userName ?? ''}
          handleImportanceChange={memoisedHandleImportanceChange}
          shortDescription={shortDescription}
        />,
      ],
      autoScroll: canScrollIntoView,
      highlighted: canScrollIntoView,
      rowSx: rowStyle,
    };
  });

  const onLoadMore = () => {
    if (isFetchingMore) {
      return;
    }

    setIsFetchingMore(true);
    loadMore();
  };

  const onImportanceFilterChange = (selectedOption: any) => {
    handleImportanceFilterChange(selectedOption);
  };

  const onTypeFilterChange = (selectedOption: any) => {
    trackInsightsAlertEvent('alertFilterAlertType', {
      alertType: selectedOption?.value ?? '',
    });
    handleTypeFilterChange(selectedOption);
  };

  const onDateSortChange = () => {
    handleSortByDate();
  };

  const onSeverityFilterChange = (selectedOption: any) => {
    handleSeverityFilterChange(selectedOption);
  };

  const onAssignedUserFilterChange = (selectedOption: any) => {
    handleAssignedUserFilterChange(selectedOption);
  };

  const {
    importanceFilter,
    dateSort,
    alertType,
    alertAssignee,
    alertSeverity,
  } = useAlertTableHeader({
    selectedAlertTypeFilter,
    selectedAlertImportanceFilter,
    onImportanceFilterChange,
    onTypeFilterChange,
    onDateSortChange,
    onSeverityFilterChange,
    onAssignedUserFilterChange,
    selectedAlertSeverityFilter,
    selectedAlertAssignedToFilter,
    selectedSortOrders,
  });

  const headers = {
    cells: [
      <InsightsAlertHeaderTableCell
        key={0}
        importanceFilter={importanceFilter}
        alertType={alertType}
        alertSeverity={alertSeverity}
        alertAssignee={alertAssignee}
        dateSort={dateSort}
      />,
    ],
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Scrollbars
        data-testid="alertList-scrollbar"
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{
          height: '97%',
        }}
        renderView={props => (
          <div
            {...props}
            style={{ ...props.style, overflowX: 'hidden', height: '100%' }}
          />
        )}
      >
        <InfiniteScrollingTable
          header={headers}
          variant="simple"
          rows={rows}
          isLoading={!hasAlerts && loading}
          hasMore={hasNextPage}
          loaderComponent={<AlertLoadingTableRow />}
          onScrollEnd={() => {
            onLoadMore();
          }}
          isFetchingMore={isFetchingMore}
          tableComponent="div"
          hasData={hasAlerts}
          noDataComponent={<AlertNoDataTableRow />}
        />
      </Scrollbars>
    </Box>
  );
};

export default AlertList;
