import { ReportAnchorTarget, ReportExtended } from '@apollo-red/hooks';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { transformReportUrl } from './transformReportUrl';

export const getReportUrl = (
  report: ReportExtended,
  dashboardRoutes: DashboardRouteProps,
  categoryId: string,
  isExternalReportEmbeddingEnabled = false,
) => {
  const { id, report_external_url, report_external_anchor_target } = report;

  if (!report_external_url) {
    return dashboardRoutes.dashboard.getLink(categoryId, id);
  }

  if (
    report_external_anchor_target === ReportAnchorTarget.Self &&
    isExternalReportEmbeddingEnabled
  ) {
    return dashboardRoutes.dashboard.getLink(categoryId, 'report', id);
  } else {
    return transformReportUrl(report_external_url);
  }
};
