import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPLOAD_FILE_SAS_URL } from '@apollo-red/mutations';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';

export const useUploadFileSasUrl = (tenantId: string) => {
  const [uploadFile, { loading }] = useAppMutation<
    SchemaTypes.UploadFileSasUrlMutation,
    SchemaTypes.UploadFileSasUrlMutationVariables
  >(UPLOAD_FILE_SAS_URL, {
    fetchPolicy: 'no-cache',
  });

  return {
    uploadFileSasUrl: (application: string, applicationSubCode?: string) =>
      uploadFile({
        variables: {
          where: {
            application,
            tenant_id: tenantId,
            application_sub_code: applicationSubCode,
          },
        },
      }),
    loading,
  };
};
