import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { InsightsAlert, InsightsAlertInbox } from '@apollo-red/hooks';
import { alertInboxToInsightsAlertTab } from '../utils';
import { useGetInsightsInboxAlertState } from './useGetInsightsInboxAlertState';

export const useCleanupInsightsInboxAlerts = (
  inboxType: InsightsAlertInbox,
  recentlySelectedAlert?: Partial<InsightsAlert> | null,
) => {
  const client = useApolloClient();
  const { cache } = client;
  const status = alertInboxToInsightsAlertTab[inboxType];
  const { userId, tenantOvcId } = useGetInsightsInboxAlertState(status);
  const cleanupInboxAlerts = React.useCallback(() => {
    cache.evict({
      id: cache.identify({
        __typename: 'InsightsAlerts',
        client_id: tenantOvcId,
        user_id: userId,
      }),
    });
    cache.gc();
  }, [cache, tenantOvcId, userId]);
  const cleanupInboxAlertThread = React.useCallback(() => {
    const threadId = recentlySelectedAlert?.thread_id;

    if (!threadId) {
      return;
    }

    cache.evict({
      id: cache.identify({
        __typename: 'InsightsAlertThread',
        thread_id: threadId,
      }),
    });
    cache.gc();
  }, [cache, recentlySelectedAlert?.thread_id]);

  return { cleanupInboxAlerts, cleanupInboxAlertThread };
};
