import * as React from 'react';
import { Link } from 'react-router-dom';
import { Hidden, Grid, Theme, useTheme } from '@mui/material';
import { Icon } from '@jll-labs/azara-ui-components';
import { SystemStyleObject } from '@mui/system';

const useStyles = (): Record<
  'breadcrumbsContainer' | 'spacer',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    breadcrumbsContainer: {
      fontSize: theme.typography.body1.fontSize,
    },
    spacer: {
      margin: theme.spacing(0, 1),
    },
  };
};

export interface BreadcrumbsRoutePart {
  label: string;
  link?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any;
  state?: any;
}

interface Props {
  routeParts: BreadcrumbsRoutePart[];
  pageTitle: string;
}

export const FirstRoutePart: React.FC<{ part: BreadcrumbsRoutePart }> = ({
  part,
}) => {
  if (!part || !part.label) {
    return null;
  }

  return (
    <span data-testid={`breadcrumbs-route-${part.label}`}>{part.label}</span>
  );
};

export const RegularRoutePart: React.FC<{
  part: BreadcrumbsRoutePart;
  highlighted: boolean;
}> = ({ part }) => {
  const styles = useStyles();

  if (!part || !part.label) {
    return null;
  }

  const linkOrLabel = part.link ? (
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      data-testid={`breadcrumbs-route-link-${part.label}`}
      to={{ pathname: part.link, state: part.state }}
      onClick={part.onClick}
    >
      {part.label}
    </Link>
  ) : (
    <span data-testid={`breadcrumbs-route-${part.label}`}>{part.label}</span>
  );

  return (
    <>
      <Icon fontSize="small" name="KeyboardArrowRight" sx={styles.spacer} />
      {linkOrLabel}
    </>
  );
};

const Breadcrumbs: React.FC<Props> = ({ routeParts }) => {
  const styles = useStyles();
  const [firstPart, ...restParts] = routeParts;
  const middleParts = restParts.slice(0, restParts.length - 1);
  const lastPart = restParts[restParts.length - 1];

  return (
    <Hidden lgDown={true}>
      <Grid
        sx={styles.breadcrumbsContainer}
        container={true}
        alignItems="center"
      >
        <FirstRoutePart part={firstPart} />
        {middleParts.map(part => (
          <RegularRoutePart key={part.label} part={part} highlighted={true} />
        ))}
        {lastPart && (
          <RegularRoutePart
            key={lastPart.label}
            part={lastPart}
            highlighted={false}
          />
        )}
      </Grid>
    </Hidden>
  );
};

export default Breadcrumbs;
