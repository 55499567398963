import * as React from 'react';
import { Box, styled } from '@mui/material';
import MenuItemComponent from './MenuItem';
import MenuSubItemComponent from './MenuSubItem';
import {
  useGetUserInfo,
  useGetAllDirectTenantUsers,
  useGetInsightsAlertSummary,
  useCheckFeature,
  UserProvisioningStatus,
} from '@apollo-red/hooks';
import { useCreateMenuItems } from './navHelpers';
import { isTestEnv } from '@components/featureToggle';
import {
  MenuItem,
  MenuSubItem,
  NavItemsProps,
  RoleMenuItems,
  RoleOtherMenuItems,
  RoleOtherSubMenuItems,
} from './navHelpers.types';

interface Props {
  navItems: NavItemsProps;
  onClick: () => void;
  isSidebarExpanded: boolean;
}

const StyledDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  overflow: 'hidden',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const Navigation: React.FC<Props> = ({
  navItems,
  isSidebarExpanded,
  onClick,
}) => {
  const { user } = useGetUserInfo();
  const currentTenantId = user.user_settings.default_tenant?.id ?? '';
  const { users } = useGetAllDirectTenantUsers(currentTenantId);
  const containerEl = React.useRef<HTMLDivElement>(null);

  const {
    allAlertsCount: insightsAllAlertsCount,
    unreadAlertsCount: insightsUnreadAlertsCount,
  } = useGetInsightsAlertSummary(user.id, currentTenantId, true);
  const { checkFeature } = useCheckFeature();

  const restrictFilter = (item: MenuItem | MenuSubItem) =>
    item.restrict ? item.restrict(user, currentTenantId) : true;

  const restrictMenuItem = (menuItem: MenuItem) => {
    return Object.values(RoleMenuItems).some(rmi => rmi === menuItem.id)
      ? checkFeature(menuItem.id as RoleMenuItems)
      : true;
  };

  const restrictSubMenuItem = (
    menuItem: MenuItem,
    subMenuItem: MenuSubItem,
  ) => {
    return Object.values(RoleOtherMenuItems).some(
      romi => romi === menuItem.id,
    ) &&
      Object.values(RoleOtherSubMenuItems).some(
        rosmi => rosmi === subMenuItem.id,
      )
      ? checkFeature(
          menuItem.id as RoleOtherMenuItems,
          subMenuItem.id as RoleOtherSubMenuItems,
        )
      : true;
  };

  const menuItemsInfo = {
    unassignedUsers: users.filter(user => {
      const foundTenant = user.user_tenant_settings.find(
        t => t.user_tenant.id === currentTenantId,
      );
      return (
        !foundTenant?.user_role ||
        foundTenant.user_provisioning_status === UserProvisioningStatus.Error
      );
    }).length,
    isSidebarExpanded,
    insightsAllAlertsCount,
    insightsUnreadAlertsCount,
  };

  const menuItems = useCreateMenuItems(menuItemsInfo);

  return (
    <StyledDiv
      ref={containerEl}
      onClick={onClick}
      data-testid={'navigation-container'}
    >
      {menuItems
        .filter(restrictMenuItem)
        .filter(restrictFilter)
        .map(menuItem => {
          return (
            <MenuItemComponent
              key={menuItem.label}
              isSidebarExpanded={isSidebarExpanded}
              item={menuItem}
            >
              <StyledBox data-testid={'navigation-menusubitem-container'}>
                {menuItem
                  .navItems(navItems)
                  .filter(menuSubItem =>
                    isTestEnv() ? true : !menuSubItem.isTestFeature,
                  )
                  .filter(menuSubItem =>
                    restrictSubMenuItem(menuItem, menuSubItem),
                  )
                  .filter(restrictFilter)
                  .map(subItem => {
                    return (
                      <MenuSubItemComponent
                        item={subItem}
                        key={subItem.label}
                      />
                    );
                  })}
              </StyledBox>
            </MenuItemComponent>
          );
        })}
    </StyledDiv>
  );
};

export default Navigation;
