import * as React from 'react';
import { Avatar, Box, useTheme } from '@mui/material';

export enum AvatarSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

enum HeaderFontSize {
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

const AvatarFontSize: { [key in AvatarSize]: keyof typeof HeaderFontSize } = {
  [AvatarSize.xs]: HeaderFontSize.h6,
  [AvatarSize.sm]: HeaderFontSize.h5,
  [AvatarSize.md]: HeaderFontSize.h3,
  [AvatarSize.lg]: HeaderFontSize.h2,
};

interface Props {
  fullName: string;
  size: keyof typeof AvatarSize;
  src?: string;
}

interface StyleProps {
  size: keyof typeof AvatarSize;
}

const useStyles = ({ size }: StyleProps) => {
  const theme = useTheme();
  return {
    root: {
      fontSize: theme.typography[AvatarFontSize[size]].fontSize,
      width: '2em',
      height: '2em',
      backgroundColor: 'transparent',
      border: '1px solid',
      color: theme.palette.text.primary,
    },
    initialsAvatar: {
      display: 'inline-block',
      verticalAlign: 'text-top',
      textAlign: 'center',
      lineHeight: '2em',
    },
  };
};

const getFirstLetter = (name?: string) =>
  String(name).substr(0, 1).toUpperCase();

const getInitials = (fullName: string) => {
  const names = fullName.split(' ');
  if (names.length === 0) {
    return '';
  } else if (names.length === 1) {
    return getFirstLetter(names[0]);
  } else {
    return `${getFirstLetter(names[0])}${getFirstLetter(names[1])}`;
  }
};

const AppAvatar: React.FC<Props> = ({ fullName, src, ...styleProps }) => {
  const styles = useStyles(styleProps);
  const initials = fullName ? getInitials(fullName) : '';

  return (
    <Avatar data-testid="avatar-img" sx={styles.root} src={src}>
      <Box data-testid="avatar-initials" sx={styles.initialsAvatar}>
        {initials}
      </Box>
    </Avatar>
  );
};

export default AppAvatar;
