import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  InsightsAlertType,
  InsightsAlertScope,
  InsightsAlertInbox,
} from '@apollo-red/hooks';
import Covid19Alert from './Covid19Alert';
import FacilitiesExperienceAlert from './FacilitiesExperienceAlert';
import LeaseEventAlert from './LeaseEventAlert';
import WorkOrderVolume from './WorkOrderVolume';
import WOVExternalAlert from './WOVExternalAlert';
import WOVInternalAlert from './WOVInternalAlert';
import MarketBenchmarkAlert from './MarketBenchmarkAlert';
import OccupancyEfficiencyAlert from './OccupancyEfficiencyAlert';
import SubleaseRiskAlert from './SubleaseRiskAlert';
import SameDayWorkOrderCompletionAlert from './SameDayWorkOrderCompletionAlert';

export type AlertText = React.ReactElement | string | null;

interface AlertTextParams {
  threadId: string;
  alertType: InsightsAlertType;
  templateData: any;
  boldText?: boolean;
  alertScope?: InsightsAlertScope;
  inboxType?: InsightsAlertInbox;
  stripHtmlTags?: boolean;
}

export const getAlertText = (params: AlertTextParams) => {
  const {
    threadId,
    alertType,
    templateData,
    boldText,
    alertScope,
    inboxType,
    stripHtmlTags,
  } = params;
  let alertText: AlertText = null;

  switch (alertType) {
    case InsightsAlertType.Covid_19WorkOrder:
      alertText = (
        <Covid19Alert
          template={templateData}
          boldText={boldText}
          alertType={alertType}
          threadId={threadId}
        />
      );
      break;
    case InsightsAlertType.FacilitiesExperience:
      alertText = (
        <FacilitiesExperienceAlert
          template={templateData}
          boldText={boldText}
          alertType={alertType}
          threadId={threadId}
        />
      );
      break;
    case InsightsAlertType.LeaseEvent:
      alertText = (
        <LeaseEventAlert
          template={templateData}
          boldText={boldText}
          threadId={threadId}
          inboxType={inboxType}
          alertType={alertType}
        />
      );
      break;
    case InsightsAlertType.WorkorderVolume:
      if (alertScope === InsightsAlertScope.Internal) {
        alertText = (
          <WOVInternalAlert
            template={templateData}
            boldText={boldText}
            threadId={threadId}
            inboxType={inboxType}
            alertType={alertType}
          />
        );
      } else if (alertScope === InsightsAlertScope.External) {
        alertText = (
          <WOVExternalAlert
            template={templateData}
            boldText={boldText}
            threadId={threadId}
            inboxType={inboxType}
            alertType={alertType}
          />
        );
      } else {
        alertText = (
          <WorkOrderVolume
            template={templateData}
            boldText={boldText}
            threadId={threadId}
            inboxType={inboxType}
          />
        );
      }
      break;
    case InsightsAlertType.MarketBenchmark:
      alertText = (
        <MarketBenchmarkAlert
          template={templateData}
          boldText={boldText}
          threadId={threadId}
          inboxType={inboxType}
          alertType={alertType}
        />
      );
      break;
    case InsightsAlertType.OccupancyEfficiency:
      alertText = (
        <OccupancyEfficiencyAlert
          template={templateData}
          boldText={boldText}
          threadId={threadId}
          inboxType={inboxType}
          alertType={alertType}
        />
      );
      break;
    case InsightsAlertType.SubleaseRisk:
      alertText = (
        <SubleaseRiskAlert
          template={templateData}
          boldText={boldText}
          threadId={threadId}
          inboxType={inboxType}
          alertType={alertType}
        />
      );
      break;

    case InsightsAlertType.SameDayWorkOrderCompletion:
      alertText = (
        <SameDayWorkOrderCompletionAlert
          template={templateData}
          boldText={boldText}
        />
      );
      break;
  }

  if (!alertText || !stripHtmlTags) {
    return alertText;
  }

  return ReactDOMServer.renderToStaticMarkup(alertText)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&amp;/g, '&');
};
