import * as React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

const AppBrowserRouter: React.FC = ({ children }) => {
  return <BrowserRouter history={history}>{children}</BrowserRouter>;
};

export default AppBrowserRouter;
