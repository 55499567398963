import * as React from 'react';
import { Viz } from '@components/tableau/types';
import { useIfMountedCallback } from '@utils/various';

export const useTableauViz = () => {
  const [viz, setViz] = React.useState<undefined | Viz>(undefined);
  const [vizLoading, setVizLoading] = React.useState(true);

  const handleLoadComplete = useIfMountedCallback((loadedViz: Viz) => {
    setViz(loadedViz);
    setVizLoading(false);
  });

  return {
    viz,
    vizLoading,
    handleLoadComplete,
  };
};
