import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MessageIcon,
  Icon,
  Snackbar,
  utils,
} from '@jll-labs/azara-ui-components';
import { AppTheme, useAppTheme } from '@theme';
import { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { MessageComponentProps, SuccessMessageType } from './MessageContext';

const { createUpBreakpoints } = utils;

export const createSnackbarBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    top: theme.topBar.height[breakpoint],
    margin: '1em',
  };
};

const useStyles = () => {
  const theme = useAppTheme();

  return {
    snackbar: {
      top: theme.topBar.height.md,
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createSnackbarBreakpoints,
      ),
    },
  };
};

type SuccessTypeConfig = {
  [key in SuccessMessageType]: { icon: React.ReactNode; text: string };
};

/**
 * Wrapper around generic Snackbar. It handles various types of success notifications appearing in RED2.
 */
const SuccessSnackbar: React.FC<MessageComponentProps> = ({
  message,
  handleClose,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const config: SuccessTypeConfig = {
    default: {
      icon: <MessageIcon name="success" />,
      text: message.text ?? '',
    },
    saved: {
      icon: <Icon name="Save" />,
      text: t('changesSaved'),
    },
    warning: {
      icon: <MessageIcon name="warning" />,
      text: message.text ?? '',
    },
  };

  const { icon, text } =
    message.successType && config[message.successType]
      ? config[message.successType]
      : config.default;

  return (
    <Snackbar
      sx={styles.snackbar}
      message={text}
      open={true}
      handleClose={handleClose}
      leftSideComponent={icon}
      origin={{ vertical: 'top', horizontal: 'center' }}
    />
  );
};

export default SuccessSnackbar;
