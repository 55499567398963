import * as React from 'react';
import { Viz, Dashboard, SheetType } from '@components/tableau/types';
import { parseDataAvailabilitySheetValues } from './parseDataAvailabilitySheetValues';
import { DataAvailabilityFieldValues } from './types';

export const useTableauDataAvailability = (viz?: Viz) => {
  const [vizData, setVizData] = React.useState<DataAvailabilityFieldValues>({
    hasData: false,
    isLoading: true,
  });

  React.useEffect(() => {
    if (!viz) {
      return;
    }

    const activeSheet = viz.getWorkbook().getActiveSheet();
    const dashboard =
      activeSheet.getSheetType() === SheetType.DASHBOARD
        ? (activeSheet as Dashboard)
        : undefined;

    if (!dashboard) {
      return;
    }

    const dataAvailabilitySheet = dashboard
      .getWorksheets()
      .find(worksheet => worksheet.getName() === 'DataAvailabilitySheet');

    if (!dataAvailabilitySheet) {
      return;
    }

    dataAvailabilitySheet.getSummaryDataAsync().then(dataObject => {
      const data = dataObject.getData()?.[0] ?? [];
      const { hasData, quality, qualityExplanation } =
        parseDataAvailabilitySheetValues(data);

      setVizData({
        hasData,
        quality,
        qualityExplanation,
        isLoading: false,
      });
    });
  }, [viz]);

  return {
    isDataLoading: vizData.isLoading,
    isDataAvailable: vizData.hasData,
    dataQuality: vizData.quality,
    dataQualityExplanation: vizData.qualityExplanation,
  };
};
