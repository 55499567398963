import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { InsightsAlert } from '@apollo-red/hooks';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_RECENT_ALERT } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';
import { InsightsAlertInbox } from '../insightsAlerts.types';

export const useSetInsightsInboxRecentAlert = (
  inboxType: InsightsAlertInbox,
  onDeselect: () => void,
) => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const [setInsightsInboxRecentAlert] = useAppMutation<
    SchemaTypes.SetInsightsInboxRecentAlertMutation,
    SchemaTypes.SetInsightsInboxRecentAlertMutationVariables
  >(SET_INSIGHTS_INBOX_RECENT_ALERT);

  return {
    setInsightsInboxRecentAlert: React.useCallback(
      (alert: InsightsAlert) => {
        setInsightsInboxRecentAlert({
          variables: {
            userId: user.id,
            tenantId,
            threadId: alert.thread_id,
            inboxType,
          },
        });
        onDeselect();
      },
      [inboxType, onDeselect, setInsightsInboxRecentAlert, tenantId, user.id],
    ),
  };
};
