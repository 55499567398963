import {
  WorkOrderVolumeAlertSettingsBaseFragment,
  FacilitiesExperienceAlertSettingsBaseFragment,
  LeaseEventAlertSettingsBaseFragment,
} from '@apollo-red/schema/generated/operations';
import { useGetTenant, TenantAlertSettingsType } from './tenants';
import { useGetUserDefaultTenantId } from '../user';

export type WovAlertSettings = WorkOrderVolumeAlertSettingsBaseFragment;
export type FxAlertSettings = FacilitiesExperienceAlertSettingsBaseFragment;
export type LeaseEventAlertSettings = LeaseEventAlertSettingsBaseFragment;

export const useGetTenantAlertSettings = (
  id: TenantAlertSettingsType,
  tenantId: string,
) => {
  const { tenant, loading, refetch } = useGetTenant(tenantId);
  const alertValues = (tenant?.tenant_alert_settings || []).find(
    alert => alert.id === id,
  );
  return {
    loading,
    alertValues,
    refetch,
  };
};

export const useGetCurrentTenantAlertSettings = (
  id: TenantAlertSettingsType,
) => {
  const defaultTenantId = useGetUserDefaultTenantId();
  return useGetTenantAlertSettings(id, defaultTenantId);
};

export const useGetCurrentTenantEnabledAlertTypes = () => {
  const defaultTenantId = useGetUserDefaultTenantId();
  const { loading, tenant } = useGetTenant(defaultTenantId);

  return {
    loading,
    tenantEnabledAlertTypes: tenant?.tenant_enabled_alert_types,
  };
};
