import * as React from 'react';
import { Redirect } from 'react-router';
import { main } from '@routes';

interface Props {
  redirectTo?: string;
}

export const ClientHomeRedirect: React.FC<Props> = ({ redirectTo }) => {
  return <Redirect to={redirectTo ?? main.client.getLink('login')} />;
};
