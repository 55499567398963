import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ToolbarItem from './ToolbarItem';
import ToolbarItemsWithMenu from './ToolbarItemsWithMenu';
import { ToolbarButton } from './types';

interface Props {
  buttons: ToolbarButton[];
  disabled?: boolean;
  helpItem: React.ReactNode;
  showMenu: boolean;
}

const ToolbarItems: React.FC<Props> = ({
  buttons,
  disabled = false,
  helpItem,
  showMenu,
}) => {
  const { t } = useTranslation();

  if (showMenu) {
    return (
      <ToolbarItemsWithMenu
        disabled={disabled}
        helpItem={helpItem}
        buttons={buttons}
      />
    );
  }

  return (
    <>
      {helpItem}
      {buttons.map(button => (
        <ToolbarItem
          key={button.name}
          button={button}
          disabled={disabled}
          label={t(button.name)}
        />
      ))}
    </>
  );
};

export default ToolbarItems;
