import * as React from 'react';
import { Table } from '@jll-labs/azara-ui-components';
import { useGetUserInfo } from '@apollo-red/hooks';
import { getUserPrimaryRole } from '@utils/auth';

const AccessInfo: React.FC = () => {
  const { user } = useGetUserInfo();

  return (
    <Table
      header={{
        cells: ['Client Name', 'Client Role'],
      }}
      rows={user.user_tenant_settings.map(settings => ({
        cells: [
          settings.user_tenant.tenant_name,
          getUserPrimaryRole(settings) || '',
        ],
      }))}
    />
  );
};

export default AccessInfo;
