import { InsightsAlertType, TenantAlertSettingsType } from '@apollo-red/hooks';

// FIXME to be updated after relevant changes to client/user settings are merged
export const insightsAlertTypeToTenantAlertSettingsType: {
  [key in Exclude<
    InsightsAlertType,
    'same_day_work_order_completion'
  >]: TenantAlertSettingsType;
} = {
  [InsightsAlertType.WorkorderVolume]: TenantAlertSettingsType.WorkorderVolume,
  [InsightsAlertType.MarketBenchmark]: TenantAlertSettingsType.MarketBenchmark,
  [InsightsAlertType.LeaseEvent]: TenantAlertSettingsType.LeaseEvent,
  [InsightsAlertType.FacilitiesExperience]:
    TenantAlertSettingsType.FacilitiesExperience,
  [InsightsAlertType.Covid_19WorkOrder]:
    TenantAlertSettingsType.Covid_19WorkOrder,
  [InsightsAlertType.OccupancyEfficiency]:
    TenantAlertSettingsType.OccupancyEfficiency,
  [InsightsAlertType.SubleaseRisk]: TenantAlertSettingsType.SubleaseRisk,
};
