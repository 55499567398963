import { InsightsAlertType } from '@apollo-red/schema/generated/operations';
import { InsightsAlertTypeFilter } from '../insightsAlerts.types';

export const alertTypeFilterToTenantAlertSettingsType: Record<
  InsightsAlertTypeFilter,
  InsightsAlertType | undefined
> = {
  [InsightsAlertTypeFilter.All]: undefined,
  [InsightsAlertTypeFilter.FacilitiesExperience]:
    InsightsAlertType.FacilitiesExperience,
  [InsightsAlertTypeFilter.WorkorderVolume]: InsightsAlertType.WorkorderVolume,
  [InsightsAlertTypeFilter.LeaseEvent]: InsightsAlertType.LeaseEvent,
  [InsightsAlertTypeFilter.Covid_19WorkOrder]:
    InsightsAlertType.Covid_19WorkOrder,
  [InsightsAlertTypeFilter.MarketBenchmark]: InsightsAlertType.MarketBenchmark,
  [InsightsAlertTypeFilter.OccupancyEfficiency]:
    InsightsAlertType.OccupancyEfficiency,
  [InsightsAlertTypeFilter.SubleaseRisk]: InsightsAlertType.SubleaseRisk,
  [InsightsAlertTypeFilter.SameDayWorkOrderCompletion]:
    InsightsAlertType.SameDayWorkOrderCompletion,
};
