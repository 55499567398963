//@ts-nocheck
import { TypedTypePolicies } from '@apollo-red/schema/generated/apollo-helpers';
import { tenantTypePolicy } from './tenant';
import { userTypePolicy } from './user';
import { reportTypePolicy } from './report';
import { roleTypePolicy } from './role';
import { insightsAlertTypePolicy } from './insightsAlert';

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      getUserClassification: {
        keyArgs: ['where', ['filters']],
        merge: true,
      },
    },
  },
  ...tenantTypePolicy,
  ...userTypePolicy,
  ...reportTypePolicy,
  ...roleTypePolicy,
  ...insightsAlertTypePolicy,
};
