import * as SchemaTypes from '@apollo-red/schema/generated/operations';

export type UserWorkRole = SchemaTypes.UserWorkRoleBaseFragment;

export type UserRole = SchemaTypes.UserRoleBaseFragment;

export type UserTenantSettings = SchemaTypes.UserTenantSettingsBaseFragment;

export type UserAdminSettings = SchemaTypes.UserAdminSettingsBaseFragment;

export type User = SchemaTypes.UserBaseFragment;

export type SearchedUserResult = SchemaTypes.SearchedUserResult;

/* eslint-disable-next-line */
export import UserManagementType = SchemaTypes.UserManagementType;

/* eslint-disable-next-line */
export import UserClassificationType = SchemaTypes.UserClassificationType;

/* eslint-disable-next-line */
export import UserStatus = SchemaTypes.UserStatus;

/* eslint-disable-next-line */
export import UserSiteRole = SchemaTypes.UserSiteRole;
