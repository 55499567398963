import * as React from 'react';
import {
  ReportControlStaticListItem,
  ReportControlType,
} from '@apollo-red/hooks';
import { MultiSelect, Option, SingleSelect } from '../../controls';
import { FilterExtended, PinFilter } from '../../types';

interface CategoricalFilterOptionsProps {
  filter: FilterExtended;
  filterOptions: Option[];
  filterState: PinFilter;
  loading: boolean;
  onClear: () => void;
  selectedOption?: ReportControlStaticListItem;
  updateFilter: (values: ReportControlStaticListItem[]) => void;
}

const CategoricalFilterOptions: React.FC<CategoricalFilterOptionsProps> = ({
  filter,
  filterOptions,
  filterState,
  loading,
  onClear,
  selectedOption,
  updateFilter,
}) => {
  const [initialValue] = filterState.selectedValues ?? [];

  switch (filter.control_type) {
    case ReportControlType.SelectList:
      return (
        <MultiSelect
          label={filter.label}
          loading={loading}
          onClear={onClear}
          options={filterOptions}
          updateValues={updateFilter}
          values={filterState?.selectedValues}
        />
      );
    case ReportControlType.SingleSelectList:
      return (
        <SingleSelect
          initialValue={initialValue}
          label={filter.label}
          loading={loading}
          options={filterOptions}
          updateValues={updateFilter}
        />
      );
    case ReportControlType.SingleSelectListDef:
      return (
        <SingleSelect
          hasDefaultSelection
          initialValue={selectedOption}
          label={filter.label}
          loading={loading}
          options={filterOptions}
          updateValues={updateFilter}
        />
      );
    default:
      return null;
  }
};

export default CategoricalFilterOptions;
