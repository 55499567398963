import * as React from 'react';
import { DiscreteSlider as Slider } from '@jll-labs/azara-ui-components';
import { RangeOption } from '../types';

interface Props {
  rangeOption: RangeOption;
  loading: boolean;
  updateRange: (options: RangeOption) => void;
  initialValues?: RangeOption;
}

const parseValue = value => (typeof value === 'number' ? value : 0);

const SliderRange: React.FC<Props> = ({
  loading,
  updateRange,
  rangeOption,
  initialValues,
}) => {
  const rangeMaxValue = parseValue(rangeOption?.max);
  const rangeMinValue = parseValue(rangeOption?.min);
  const initialMaxValue = parseValue(initialValues?.max) || rangeMaxValue;
  const initialMinValue = parseValue(initialValues?.min) || rangeMinValue;
  const [value, setValue] = React.useState([initialMaxValue, initialMinValue]);

  const handleChange = (value: number[]) => {
    setValue(value);
    const [min, max] = value;
    updateRange({
      min,
      max,
    });
  };

  return (
    <Slider
      step={1}
      max={rangeMaxValue}
      min={rangeMinValue}
      setValues={handleChange}
      value={value}
      disabled={loading}
    />
  );
};

export default SliderRange;
