import * as React from 'react';
import { useTableauViewEventTracking } from '@utils/tableau';
import { TableauCacheContext } from './TableauCacheContextProvider';
import { TableauViewProps } from './TableauView';
import { Viz } from './types';

interface Props extends TableauViewProps {
  cacheId: string;
  container: string;
  offset: {
    top: number;
    left: number;
    width: number;
  };
  handleLoadComplete: (viz: Viz) => void;
  containerStyle?: React.CSSProperties;
  hidden?: boolean;
}

const TableauViewCached: React.FC<Props> = ({
  cacheId,
  offset,
  container,
  containerStyle,
  handleLoadComplete,
  hidden,
  ...rest
}) => {
  const { trackFirstInteractive, trackVizDispose } =
    useTableauViewEventTracking();
  const cache = React.useContext(TableauCacheContext);
  const current = cache.getById(cacheId);

  React.useEffect(() => {
    if (current && current.viz && current.mounted) {
      cache.update(cacheId, { visible: !hidden });
      handleLoadComplete(current.viz);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current && current.mounted, hidden]);

  React.useEffect(() => {
    cache.update(cacheId, {
      containerStyle,
      offset,
      tableauContainerStyle: rest.tableauContainerStyle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerStyle, offset, rest.tableauContainerStyle]);

  React.useEffect(() => {
    if (!current) {
      cache.add({
        id: cacheId,
        visible: false,
        mounted: false,
        reset: false,
        container,
        containerStyle,
        props: {
          ...rest,
          handleFirstInteractive: (viz: Viz) => {
            trackFirstInteractive(viz);
            cache.update(cacheId, { mounted: true, viz });

            if (rest.handleFirstInteractive) {
              rest.handleFirstInteractive(viz);
            }
          },
        },
      });
    }

    return () => {
      if (current?.viz) {
        trackVizDispose(current.viz);
      }

      cache.update(cacheId, { visible: false, reset: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default TableauViewCached;
