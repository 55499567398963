import * as React from 'react';
import { Button, Icon } from '@jll-labs/azara-ui-components';
import { Viz } from '@components/tableau/types';

interface ToolbarFilterButtonsProps {
  viz?: Viz;
  filtersClassName: string;
  filterClassName: string;
  buttonClassName: string;
  clearDisabled?: boolean;
  clearLabel: string;
  onClear: () => Promise<void>;
  filterDisabled?: boolean;
  filterLabel: string;
  onFilter: () => void;
  appliedFilterCount: number;
}

const ToolbarFilterButtons: React.FC<ToolbarFilterButtonsProps> = ({
  viz,
  filtersClassName,
  filterClassName,
  buttonClassName,
  clearDisabled = false,
  clearLabel,
  onClear,
  filterDisabled = false,
  filterLabel,
  onFilter,
  appliedFilterCount,
}) => {
  if (!viz) {
    return null;
  }

  return (
    <div className={filtersClassName}>
      <div className={filterClassName}>
        <Button
          variant="text"
          color="primary"
          disabled={clearDisabled}
          data-testid="filters"
          onClick={onClear}
        >
          <Icon name="ClearFilters" />
          <div className={buttonClassName}>{clearLabel}</div>
        </Button>
        <Button
          variant="text"
          color="primary"
          disabled={filterDisabled}
          data-testid="filters"
          onClick={onFilter}
        >
          <Icon name="Filters" count={appliedFilterCount} />
          <div className={buttonClassName}>{filterLabel}</div>
        </Button>
      </div>
    </div>
  );
};

export default ToolbarFilterButtons;
