import { FormSelectOption } from '@jll-labs/azara-ui-components';
import {
  useGetUserInfo,
  getTenantSetting,
  UserWorkRole,
  useGetUserDefaultTenantId,
  useGetWorkAreas,
  useUpsertUserWorkRole,
} from '@apollo-red/hooks';

export interface FormValues {
  workArea?: FormSelectOption;
  specialization?: FormSelectOption;
  role?: FormSelectOption;
  additionalRole?: string;
}

const initField = (field: string, userWorkRole?: UserWorkRole) => {
  return userWorkRole && userWorkRole[field]
    ? { value: userWorkRole[field], label: userWorkRole[field] }
    : undefined;
};

export const useWorkRolesForm = () => {
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();
  const { workAreas, loading } = useGetWorkAreas();
  const { upsertUserWorkRole } = useUpsertUserWorkRole();

  const userTenantWorkRole = getTenantSetting(
    user.user_settings.work_roles,
    defaultTenantId,
  );

  const userWorkRole = userTenantWorkRole
    ? userTenantWorkRole.work_role
    : undefined;

  const initialValues: FormValues = {
    workArea: initField('work_area', userWorkRole),
    specialization: initField('specialization', userWorkRole),
    role: initField('role', userWorkRole),
    additionalRole: '',
  };

  const onSubmit = async (values: FormValues) => {
    const payload = {
      work_area: values.workArea?.value || '',
      specialization: values.specialization?.value || '',
      role: values.additionalRole || values.role?.value || '',
    };
    await upsertUserWorkRole(user.id, {
      tenant_id: defaultTenantId,
      work_role: payload,
    });
  };

  const selectedWorkArea = workAreas.find(
    wA => wA.name === initialValues.workArea?.value,
  );
  const hasPreDefinedRole =
    initialValues.role &&
    selectedWorkArea?.specializations.some(spec =>
      spec.roles.some(r => initialValues.role?.value === r.name),
    );

  if (!hasPreDefinedRole && !loading) {
    initialValues.additionalRole = initialValues.role?.value ?? '';
    initialValues.role = undefined;
  }

  return {
    initialValues,
    workAreas,
    loadingWorkAreas: loading,
    onSubmit,
    userWorkRole,
  };
};
