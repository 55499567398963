import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_ALERT_TYPE_FILTER } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';
import { InsightsAlertTypeFilter } from '../insightsAlerts.types';

export const useSetInsightsInboxAlertTypeFilter = () => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const [setInboxAlertTypeFilter] = useAppMutation<
    SchemaTypes.SetInsightsInboxAlertTypeFilterMutation,
    SchemaTypes.SetInsightsInboxAlertTypeFilterMutationVariables
  >(SET_INSIGHTS_INBOX_ALERT_TYPE_FILTER);

  return {
    setInboxAlertTypeFilter: (filter: InsightsAlertTypeFilter) => {
      setInboxAlertTypeFilter({
        variables: {
          userId: user.id,
          tenantId,
          alertTypeFilter: filter,
        },
      });
    },
  };
};
