import { useMemo } from 'react';
import { UseClientRoutes } from '../../types';
import { getCommonBaseRoute } from '../../utils';

export const useSelfServeAnalyticsRoutes = ({
  selfServeAnalytics: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      home: getCommonBaseRoute(link),
    };
  }, [getLink]);
