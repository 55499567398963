import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { TenantQaType, useApiVersion } from '@apollo-red/hooks';
import { styled, Typography } from '@mui/material';

const StyledFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.light,
}));

const StyledItem = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

export const parseVersionDate = (ms: number) => {
  if (isNaN(ms)) {
    return '';
  }

  const parsedDate = format(new Date(ms), 'd/MM/yy h:mma');
  return parsedDate && parsedDate !== 'Invalid date' ? parsedDate : '';
};

export const getVersion = (explicitVersion: string, buildDate: string) => {
  return explicitVersion
    ? explicitVersion
    : parseVersionDate(parseInt(buildDate || '', 10));
};

const FooterInfo: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { apiVersion, apiBuildUtc, tenantQaType } = useApiVersion();
  const clientApiEnv: TenantQaType | undefined = process.env.CLIENT_API_ENV
    ? (process.env.CLIENT_API_ENV as TenantQaType)
    : undefined;
  const uiEnv = clientApiEnv ? t(clientApiEnv) : '';
  const apiEnv = tenantQaType ? t(tenantQaType) : '';
  const env = uiEnv === apiEnv ? uiEnv : `${uiEnv} (A: ${apiEnv})`;

  return (
    <StyledFooter>
      <StyledItem variant="body1" data-testid="version">
        Version:
        {` U ${getVersion(
          process.env.UI_VERSION || '',
          process.env.UI_BUILD_UTC || '',
        )} | A ${getVersion(apiVersion, apiBuildUtc)} | E ${env}`}
      </StyledItem>
      <StyledItem variant="body1" data-testid="copyright">
        {`Copyright ${new Date().getFullYear()} Jones Lang LaSalle IP, Inc.`}
      </StyledItem>
    </StyledFooter>
  );
};

export default FooterInfo;
