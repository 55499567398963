import * as React from 'react';
import { ReportExtended } from '@apollo-red/hooks';
import { useFullReportWithFilters } from './FullReportWithFiltersProvider';
import PATFullReport from './PATFullReport';
import CoreFullReport from './CoreFullReport';

interface Props {
  report: ReportExtended;
}

const FullReportWithSections: React.FC<Props> = ({ children, report }) => {
  const { isCoreDashboard } = useFullReportWithFilters();
  const Report = isCoreDashboard ? CoreFullReport : PATFullReport;

  return <Report report={report}>{children}</Report>;
};

export default FullReportWithSections;
