import * as React from 'react';
import { Typography, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RadioGroup, Radio } from '@jll-labs/azara-ui-components';
import { AppTheme } from '@theme';
import {
  useUpdateUserPreferredContact,
  useGetUserInfo,
} from '@apollo-red/hooks';

const useStyles = makeStyles((theme: AppTheme) => ({
  formControlLabel: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  disabled?: boolean;
}

const PreferredContactMethod: React.FC<Props> = ({ disabled }) => {
  const { updateUserPreferredContact } = useUpdateUserPreferredContact();
  const { user } = useGetUserInfo();
  const classes = useStyles();

  const handleChange = (value: string) => () => {
    updateUserPreferredContact(value);
  };

  return (
    <RadioGroup value={user.user_settings.preferred_contact ?? 'email'}>
      <FormControlLabel
        value="email"
        control={<Radio label="email" />}
        onClick={handleChange('email')}
        disabled={disabled}
        label={
          <Typography className={classes.formControlLabel}>
            {'E-mail'}
          </Typography>
        }
      />
      <FormControlLabel
        value="phone"
        control={<Radio label="phone" />}
        onClick={handleChange('phone')}
        disabled={disabled}
        label={
          <Typography className={classes.formControlLabel}>
            {'Phone'}
          </Typography>
        }
      />
    </RadioGroup>
  );
};

export default PreferredContactMethod;
