import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, useTheme } from '@mui/material';
import { IconName, Icon } from '@jll-labs/azara-ui-components';
import { DashboardCommandLink } from '@components/tiles/utils';
import { DashboardRouteProps } from '@pages/dashboards/types';

const useStyles = () => {
  const theme = useTheme();

  return {
    container: {
      fontSize: theme.typography.body2.fontSize,
    },
    icon: {
      marginRight: '0.3em',
    },
    link: {
      color: theme.palette.text.secondary,
      textDecoration: 'none',
    },
  };
};

export enum DashboardCommand {
  share = 'share',
  print = 'print',
}

const icons: { [key in DashboardCommand]: IconName } = {
  [DashboardCommand.share]: 'Share',
  [DashboardCommand.print]: 'SaveAlt',
};

interface Props {
  reportId: string;
  command: DashboardCommand;
  routes: DashboardRouteProps;
}

const TileAction: React.FC<Props> = React.forwardRef<HTMLElement, Props>(
  ({ reportId, command, routes }, _ref) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const dataTestId = `tile-action-${reportId}-${command}`;
    return (
      <DashboardCommandLink
        data-testid={dataTestId}
        style={styles.link}
        command={command}
        id={reportId}
        routes={routes}
      >
        <MenuItem>
          <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            sx={styles.container}
          >
            <Icon
              data-testid={`${dataTestId}-icon`}
              sx={styles.icon}
              name={icons[command]}
            />
            <span data-testid={`${dataTestId}-label`}>{t(command)}</span>
          </Grid>
        </MenuItem>
      </DashboardCommandLink>
    );
  },
);

export default TileAction;
