import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useGetReportDataUpdateById } from '@apollo-red/hooks';
import { useFeatureFlag } from '@utils/launchDarkly';
import { useStyles } from './lastUpdateDateStyles';
import { lastUpdateDateFromTimestamp } from './lastUpdateDateFromTimestamp';

interface LastUpdateDateProps {
  reportId: string;
  hidden?: boolean;
}

const LastUpdateDate: React.FC<LastUpdateDateProps> = ({
  reportId,
  hidden = false,
}) => {
  const enabled = useFeatureFlag('dashboardsLastUpdateDate');
  const { t } = useTranslation();
  const skip = !enabled || hidden;
  const { loading, report } = useGetReportDataUpdateById(reportId, skip);
  const { report_data_update_date } = report;
  const date = lastUpdateDateFromTimestamp(report_data_update_date);
  const hasLastUpdateDate = enabled && !loading && !!date;
  const classes = useStyles({ hasLastUpdateDate });

  if (!hasLastUpdateDate) {
    return null;
  }

  return (
    <Grid container wrap="nowrap" justifyContent="flex-end">
      <Grid item xs={12} className={classes.container}>
        <span className={classes.label}>{t('dataUpdated')} </span>
        <span className={classes.dateValue}>{date}</span>
      </Grid>
    </Grid>
  );
};

export default LastUpdateDate;
