import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_DOWNLOAD_TEMPLATE_SAS_URL } from '@apollo-red/mutations';
import { useAppMutation } from '../../queries';

export const useGetDownloadTemplateSasUrl = (tenantId: string) => {
  const [getTemplate] = useAppMutation<
    SchemaTypes.GetDownloadTemplateSasUrlMutation,
    SchemaTypes.GetDownloadTemplateSasUrlMutationVariables
  >(GET_DOWNLOAD_TEMPLATE_SAS_URL, {
    fetchPolicy: 'no-cache',
  });
  return {
    getDownloadTemplateSasUrl: (application: string) => {
      return getTemplate({
        variables: {
          where: {
            application,
            tenant_id: tenantId,
          },
        },
      });
    },
  };
};
