import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import {
  Icon,
  IconButton,
  Table,
  TableCellText,
} from '@jll-labs/azara-ui-components';
import type { OnDropEnd } from '@jll-labs/azara-ui-components/types/utils';
import { ReportExtended, useToggleFavorite } from '@apollo-red/hooks';
import ButtonLink from '@components/buttonLink';
import ExternalButtonLink from '@components/externalButtonLink';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { DashboardsPopupTypes } from './DashboardsPopup';
import { DashboardCommandLink } from './utils';
import { FavoriteButton } from '@components/favoriteButton';

const useStyles = () => ({
  buttonLink: {
    textTransform: 'none',
  },
  buttonLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  savedIcon: {
    fontSize: '1rem',
  },
});

interface Props {
  reports: ReportExtended[];
  categoryId: string;
  deleteMode: boolean;
  deleteTile: (id: string) => () => void;
  handleDialogOpen: (type: DashboardsPopupTypes, reportId: string) => void;
  onDropEnd?: OnDropEnd;
  draggable?: boolean;
  dashboardRoutes: DashboardRouteProps;
  showVisibilityButton?: boolean;
}

const TilesTable: React.FC<Props> = ({
  reports,
  categoryId,
  handleDialogOpen,
  onDropEnd,
  draggable,
  dashboardRoutes,
  showVisibilityButton = false,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { toggleFavorite } = useToggleFavorite();

  const header = React.useMemo(
    () => ({
      cells: [
        t('dashboard'),
        t('category'),
        <TableCellText key="favorite" textAlign="center">
          {t('favorite')}
        </TableCellText>,
        <TableCellText key="download" textAlign="center">
          {t('download')}
        </TableCellText>,
        <TableCellText key="share" textAlign="center">
          {t('share')}
        </TableCellText>,
        showVisibilityButton && (
          <TableCellText key="hide" textAlign="center">
            {t('hide')}
          </TableCellText>
        ),
        <TableCellText key="remove" textAlign="center">
          {t('remove')}
        </TableCellText>,
      ].filter(header => !!header),
    }),
    [showVisibilityButton, t],
  );

  const rows = React.useMemo(
    () =>
      reports.map(report => ({
        id: report.id,
        cells: [
          <Box key="name" sx={styles.buttonLinkWrapper}>
            {report?.isExternalReport ? (
              <ExternalButtonLink
                report={report}
                categoryId={categoryId}
                dashboardRoutes={dashboardRoutes}
                label={report.report_name}
                isTableRow={true}
              />
            ) : (
              <ButtonLink
                to={dashboardRoutes.dashboard.getLink(categoryId, report.id)}
                buttonSx={styles.buttonLink}
                label={report.report_name}
                minWidth={false}
              />
            )}

            {report.isSavedView && (
              <Tooltip title={t('savedView') ?? ''}>
                <Icon name="Save" sx={styles.savedIcon} />
              </Tooltip>
            )}
          </Box>,
          report.primaryCategory.length ? report.primaryCategory : <div />,
          <TableCellText key="favorite" textAlign="center">
            <FavoriteButton
              report={report}
              toggleFavoriteReport={() => toggleFavorite(report)}
            />
          </TableCellText>,
          <TableCellText key="link" textAlign="center">
            <DashboardCommandLink
              command="print"
              id={report.id}
              disabled={report.isExternalReport}
              routes={dashboardRoutes}
            >
              <IconButton
                disabled={report.isExternalReport}
                label="Save"
                size="large"
              >
                <Icon name="SaveAlt" />
              </IconButton>
            </DashboardCommandLink>
          </TableCellText>,
          <TableCellText key="share" textAlign="center">
            <DashboardCommandLink
              command="share"
              id={report.id}
              disabled={report.isExternalReport}
              routes={dashboardRoutes}
            >
              <IconButton
                label="Share"
                disabled={report.isExternalReport}
                size="large"
              >
                <Icon name="Share" />
              </IconButton>
            </DashboardCommandLink>
          </TableCellText>,
          showVisibilityButton && (
            <TableCellText key="visibility" textAlign="center">
              <IconButton
                onClick={() =>
                  handleDialogOpen(DashboardsPopupTypes.Hide, report.id)
                }
                label="Visibility"
                size="large"
              >
                <Icon name="Visibility" />
              </IconButton>
            </TableCellText>
          ),
          <TableCellText key="view" textAlign="center">
            {report.isSavedView ? (
              <IconButton
                onClick={() =>
                  handleDialogOpen(
                    DashboardsPopupTypes.DeleteSavedView,
                    report.id,
                  )
                }
                label="Delete"
                size="large"
              >
                <Icon name="Delete" />
              </IconButton>
            ) : (
              <span>{t('na')}</span>
            )}
          </TableCellText>,
        ].filter(row => !!row),
      })),
    [
      reports,
      styles.buttonLinkWrapper,
      styles.buttonLink,
      styles.savedIcon,
      categoryId,
      dashboardRoutes,
      t,
      showVisibilityButton,
      toggleFavorite,
      handleDialogOpen,
    ],
  );
  return (
    <Table
      header={header}
      rows={rows}
      draggable={draggable}
      onDropEnd={onDropEnd}
    />
  );
};

export default TilesTable;
