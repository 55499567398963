import { useTranslation } from 'react-i18next';
import { alertTypeTranslationKeys } from '@pages/insightsAlert/utils';
import { usePendoTrackEvent } from '@utils/pendo';
import { PendoTrackEventType, PendoTrackEventMetadata } from './types';

export const usePendoTrackInsightsAlertEvent = () => {
  const { t } = useTranslation();
  const { trackEvent } = usePendoTrackEvent();
  const trackInsightsAlertEvent = <
    T extends Extract<
      PendoTrackEventType,
      | 'alertRoi'
      | 'alertRead'
      | 'alertShowNotificationDetails'
      | 'alertDismiss'
      | 'alertAssignSeverity'
      | 'alertShowRecommendation'
      | 'alertAddComment'
      | 'alertAssignToUser'
      | 'alertSetImportance'
      | 'alertFilterAlertType'
      | 'alertCloseAlert'
      | 'alertMarkedForDataQualityReview'
    >,
  >(
    trackEventType: T,
    trackEventMetadata: PendoTrackEventMetadata<T>,
  ) => {
    const alertType = t(alertTypeTranslationKeys[trackEventMetadata.alertType]);

    trackEvent(trackEventType, {
      ...trackEventMetadata,
      alertType,
    });
  };

  return { trackInsightsAlertEvent };
};
