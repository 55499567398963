import {
  InsightsAlert,
  useSetInsightsInboxImportanceFilter,
  useSetInsightsInboxAlertTypeFilter,
  useSetInsightsInboxAlertSeverityFilter,
} from '@apollo-red/hooks';
import {
  alertImportanceToAlertImportanceFilter,
  tenantAlertSettingsTypeToAlertTypeFilter,
  alertInsightsSeverityToAlertSeverityFilter,
  CreateInsightsInboxFiltersChangeHandlerArgs,
  InsightsAlertFilterType,
  InsightsAlertFilterValueTypeMap,
  InsightsAlertFilterValueMappingTypeMap,
} from '../utils';

const shouldDeselectAlert = <
  T extends InsightsAlertFilterType,
  U extends InsightsAlertFilterValueTypeMap[T],
  M extends InsightsAlertFilterValueMappingTypeMap[T],
>(
  filterType: T,
  filterMapping: M,
  selectedFilterValue: U,
  selectedAlert?: InsightsAlert,
) => {
  if (!selectedAlert) {
    return false;
  }

  if (selectedFilterValue === 'all') {
    return false;
  }

  const selected = {
    [InsightsAlertFilterType.AlertType]: selectedAlert.alert_type,
    [InsightsAlertFilterType.Importance]: selectedAlert.user_importance,
    [InsightsAlertFilterType.Severity]: selectedAlert.severity,
  };
  const selectedAlertFilterValue = selected[filterType];

  if (!selectedAlertFilterValue) {
    return false;
  }

  const key = Object.keys(filterMapping).find(
    k => k === selectedAlertFilterValue,
  );
  const mapped = key ? filterMapping[key] : undefined;

  if (mapped !== selectedFilterValue) {
    return true;
  }

  return false;
};

export const useInsightsInboxFilters = (
  onResetInbox: () => void,
  onDeselectAlert: () => void,
  selectedAlert?: InsightsAlert,
) => {
  const { setInboxAlertTypeFilter } = useSetInsightsInboxAlertTypeFilter();
  const { setInboxImportanceFilter } = useSetInsightsInboxImportanceFilter();
  const { setInboxAlertSeverityFilter } =
    useSetInsightsInboxAlertSeverityFilter();

  const createFiltersChangeHandler = <
    T extends InsightsAlertFilterType,
    U extends InsightsAlertFilterValueTypeMap[T],
  >({
    filterType,
    filterHandler,
    filterMapping,
  }: CreateInsightsInboxFiltersChangeHandlerArgs<T>) => {
    return (selectedFilterValue: U) => {
      onResetInbox();
      filterHandler(selectedFilterValue);

      if (
        shouldDeselectAlert(
          filterType,
          filterMapping,
          selectedFilterValue,
          selectedAlert,
        )
      ) {
        onDeselectAlert();
      }
    };
  };

  return {
    handleImportanceFilterChange: createFiltersChangeHandler({
      filterType: InsightsAlertFilterType.Importance,
      filterHandler: setInboxImportanceFilter,
      filterMapping: alertImportanceToAlertImportanceFilter,
    }),
    handleSeverityFilterChange: createFiltersChangeHandler({
      filterType: InsightsAlertFilterType.Severity,
      filterHandler: setInboxAlertSeverityFilter,
      filterMapping: alertInsightsSeverityToAlertSeverityFilter,
    }),
    handleTypeFilterChange: createFiltersChangeHandler({
      filterType: InsightsAlertFilterType.AlertType,
      filterHandler: setInboxAlertTypeFilter,
      filterMapping: tenantAlertSettingsTypeToAlertTypeFilter,
    }),
  };
};
