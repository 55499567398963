import * as React from 'react';
import NoData from '@components/noData';
import { FilterType, RangeFilterOptions } from '@components/tableau/types';
import { ApplyFilterProps, FilterExtended } from '../../types';
import { sortFilters } from '../../utils';
import CategoricalFilter from './CategoricalFilter';
import QuantitativeFilter from './QuantitativeFilter';

interface Props {
  filters: FilterExtended[];
  applyFilter: ApplyFilterProps;
  applyRangeFilter: (name: string, rangeOptions: RangeFilterOptions) => void;
  loading: boolean;
}

const Filters: React.FC<Props> = ({
  filters,
  applyFilter,
  loading,
  applyRangeFilter,
}) => {
  const sortedFilters = sortFilters(filters);

  if (!sortedFilters && !loading) {
    return <NoData message="No Filters" noMargin />;
  }

  return (
    <>
      {sortedFilters.map(filter => {
        if (filter.filterType === FilterType.CATEGORICAL) {
          return (
            <CategoricalFilter
              key={filter.id}
              loading={loading}
              filter={filter}
              applyFilter={applyFilter}
            />
          );
        } else if (filter.filterType === FilterType.QUANTITATIVE) {
          return (
            <QuantitativeFilter
              key={filter.id}
              loading={loading}
              filter={filter}
              applyRangeFilter={applyRangeFilter}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default Filters;
