import { gql } from '@apollo/client';
import { CustomReport, ReportBase } from '../Report';
import { ApplicationBase } from '../Application';
import { RoleBase } from '../Role';

export const TenantFeaturesBase = {
  fragment: gql`
    fragment TenantFeaturesBase on TenantFeatures {
      alerts
      demo
      portscape
    }
  `,
};

export const TenantUniversalFilterGeoValueBase = {
  fragment: gql`
    fragment TenantUniversalFilterGeoValueBase on TenantUniversalFilterGeoValue {
      node
      ancestors
      values
    }
  `,
};

export const TenantGeoUniversalFiltersBase = {
  fragment: gql`
    fragment TenantGeoUniversalFiltersBase on TenantGeoUniversalFilters {
      countries {
        ...TenantUniversalFilterGeoValueBase
      }
      states {
        ...TenantUniversalFilterGeoValueBase
      }
      cities {
        ...TenantUniversalFilterGeoValueBase
      }
      properties {
        ...TenantUniversalFilterGeoValueBase
      }
    }
    ${TenantUniversalFilterGeoValueBase.fragment}
  `,
};

export const TenantAlertSettingData = {
  workOrderVolumeFragment: gql`
    fragment TenantWorkOrderVolumeAlertSettingDataBase on TenantWorkOrderVolumeAlertSettingData {
      id
      categories {
        internal
        external
      }
    }
  `,
  facilitiesExperienceFragment: gql`
    fragment TenantFacilitiesExperienceAlertSettingDataBase on TenantFacilitiesExperienceAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
  leaseEventFragment: gql`
    fragment TenantLeaseEventAlertSettingDataBase on TenantLeaseEventAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
  covidWorkOrderFragment: gql`
    fragment TenantCovidWorkOrderAlertSettingDataBase on TenantCovidWorkOrderAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
  marketBenchmarkFragment: gql`
    fragment TenantMarketBenchmarkAlertSettingDataBase on TenantMarketBenchmarkAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
  occupancyEfficiencyFragment: gql`
    fragment TenantOccupancyEfficiencyAlertSettingDataBase on TenantOccupancyEfficiencyAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
  subleaseRiskFragment: gql`
    fragment TenantSubleaseRiskAlertSettingDataBase on TenantSubleaseRiskAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
  sameDayWorkOrderCompletionFragment: gql`
    fragment TenantSameDayWorkOrderCompletionAlertSettingDataBase on TenantSameDayWorkOrderCompletionAlertSettingData {
      id
      categories {
        internal
      }
    }
  `,
};

export const TenantKpiSettingsBase = {
  fragment: gql`
    fragment TenantKpiSettingsBase on TenantKpiSettings {
      id
      kpi_enabled
      app_code
    }
  `,
};

export const TenantRuleBuilderSettingsBase = {
  fragment: gql`
    fragment TenantRuleBuilderSettingsBase on TenantAlertSettingData {
      ...TenantWorkOrderVolumeAlertSettingDataBase
      ...TenantFacilitiesExperienceAlertSettingDataBase
      ...TenantLeaseEventAlertSettingDataBase
      ...TenantCovidWorkOrderAlertSettingDataBase
      ...TenantMarketBenchmarkAlertSettingDataBase
      ...TenantOccupancyEfficiencyAlertSettingDataBase
      ...TenantSubleaseRiskAlertSettingDataBase
      ...TenantSameDayWorkOrderCompletionAlertSettingDataBase
    }
    ${TenantAlertSettingData.workOrderVolumeFragment}
    ${TenantAlertSettingData.facilitiesExperienceFragment}
    ${TenantAlertSettingData.leaseEventFragment}
    ${TenantAlertSettingData.covidWorkOrderFragment}
    ${TenantAlertSettingData.marketBenchmarkFragment}
    ${TenantAlertSettingData.occupancyEfficiencyFragment}
    ${TenantAlertSettingData.subleaseRiskFragment}
    ${TenantAlertSettingData.sameDayWorkOrderCompletionFragment}
  `,
};

export const TenantAlertSettingsBase = {
  workOrderVolumeFragment: gql`
    fragment WorkOrderVolumeAlertSettingsBase on WorkOrderVolumeAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      wov_above_median
      internal_medium
      internal_high
      external_medium
      external_high
    }
  `,
  facilitiesExperienceFragment: gql`
    fragment FacilitiesExperienceAlertSettingsBase on FacilitiesExperienceAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      time_to_expected_completion_date_medium
      time_to_expected_completion_date_high
      time_from_created_to_picked_up_date
      time_from_picked_up_to_due_date
    }
  `,
  covidWorkOrderFragment: gql`
    fragment CovidWorkOrderAlertSettingsBase on CovidWorkOrderAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      time_to_expected_completion_date_medium
      time_to_expected_completion_date_high
      time_from_created_to_picked_up_date
      time_from_picked_up_to_due_date
    }
  `,
  leaseEventFragment: gql`
    fragment LeaseEventAlertSettingsBase on LeaseEventAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      notice_period_time_medium
      notice_period_time_high
      preferred_unit_of_measurement
      preferred_unit_of_currency
    }
  `,
  marketBenchmarkFragment: gql`
    fragment MarketBenchmarkAlertSettingsBase on MarketBenchmarkAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      notice_period
      current_cost
    }
  `,
  occupancyEfficiencyFragment: gql`
    fragment OccupancyEfficiencyAlertSettingsBase on OccupancyEfficiencyAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      headcount_density_emea
      headcount_density_am
      headcount_density_apac
      headcount_density_other_or_none
      occupancy_rate_high
      occupancy_rate_low
    }
  `,
  subleaseRiskFragment: gql`
    fragment SubleaseRiskAlertSettingsBase on SubleaseRiskAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      lease_liquidity_options
      notice_period
    }
  `,
  sameDayWorkOrderCompletionFragment: gql`
    fragment SameDayWorkOrderCompletionAlertSettingsBase on SameDayWorkOrderCompletionAlertSettings {
      id
      alert_tenant_id
      alert_enabled
      threshold_for_alert_generation
    }
  `,
};

export const TenantCustomReportsSetupBase = {
  fragment: gql`
    fragment TenantCustomReportsSetupBase on TenantCustomReportsSetup {
      tenant_snowflake_urls {
        id
        tenant_snowflake_url
      }
      tenant_snowflake_locator
      tenant_snowflake_database
      tenant_snowflake_share
      tenant_tableau_project_custom_site_url
      tenant_snowflake_setup_status
      tenant_snowflake_setup_error
      tenant_snowflake_share_consumers
    }
  `,
};

export const TenantSelfServeAnalyticsSettingsBase = {
  fragment: gql`
    fragment TenantSelfServeAnalyticsSettingsBase on TenantSelfServeAnalyticsSettings {
      self_serve_analytics_enabled
      self_serve_analytics_url
      self_serve_analytics_updated_at
    }
  `,
};

export const TenantCustomReportsSettingsBase = {
  fragment: gql`
    fragment TenantCustomReportsSettingsBase on TenantCustomReportsSettings {
      tenant_custom_reports(filters: $report_filters) {
        ...CustomReport
      }
      tenant_custom_reports_setup {
        ...TenantCustomReportsSetupBase
      }
      tenant_self_serve_analytics_settings {
        ...TenantSelfServeAnalyticsSettingsBase
      }
    }
    ${CustomReport.fragment}
    ${TenantCustomReportsSetupBase.fragment}
    ${TenantSelfServeAnalyticsSettingsBase.fragment}
  `,
};

export const TenantApplicationBase = {
  fragment: gql`
    fragment TenantApplicationBase on TenantApplication {
      app {
        ...ApplicationBase
      }
      app_provisioned
      app_tenant_id
    }
    ${ApplicationBase.fragment}
  `,
};

export const TenantBase = {
  fragment: gql`
    fragment TenantBase on Tenant {
      id
      tenant_name
      tenant_code
      tenant_description
      tenant_created_at
      tenant_activated_at
      tenant_ovc_id
      tenant_applications {
        ...TenantApplicationBase
      }
      tenant_ad_groups {
        group_name
      }
      tenant_features {
        ...TenantFeaturesBase
      }
      tenant_status
      tenant_user_integration
      tenant_synchronized_at
      tenant_type
      tenant_kpi_settings {
        ...TenantKpiSettingsBase
      }
      tenant_qa_type
    }
    ${TenantApplicationBase.fragment}
    ${TenantFeaturesBase.fragment}
    ${TenantKpiSettingsBase.fragment}
  `,
};

export const TenantExtended = {
  fragment: gql`
    fragment TenantExtended on Tenant {
      ...TenantBase
      tenant_reports(filters: $report_filters) {
        ...ReportBase
      }
      tenant_external_reports(filters: $report_filters) {
        ...ReportBase
      }
      tenant_alert_settings {
        ...WorkOrderVolumeAlertSettingsBase
        ...FacilitiesExperienceAlertSettingsBase
        ...LeaseEventAlertSettingsBase
        ...CovidWorkOrderAlertSettingsBase
        ...MarketBenchmarkAlertSettingsBase
        ...OccupancyEfficiencyAlertSettingsBase
        ...SubleaseRiskAlertSettingsBase
        ...SameDayWorkOrderCompletionAlertSettingsBase
      }
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
      tenant_kpi_settings {
        ...TenantKpiSettingsBase
      }
      tenant_enabled_alert_types
    }
    ${TenantBase.fragment}
    ${ReportBase.fragment}
    ${TenantAlertSettingsBase.workOrderVolumeFragment}
    ${TenantAlertSettingsBase.facilitiesExperienceFragment}
    ${TenantAlertSettingsBase.leaseEventFragment}
    ${TenantAlertSettingsBase.covidWorkOrderFragment}
    ${TenantAlertSettingsBase.marketBenchmarkFragment}
    ${TenantCustomReportsSettingsBase.fragment}
    ${TenantAlertSettingsBase.occupancyEfficiencyFragment}
    ${TenantAlertSettingsBase.subleaseRiskFragment}
    ${TenantAlertSettingsBase.sameDayWorkOrderCompletionFragment}
    ${TenantKpiSettingsBase.fragment}
  `,
};

export const TenantUserTenantSettingsBase = {
  fragment: gql`
    fragment TenantUserTenantSettingsBase on UserTenantSettings {
      id
      user_role {
        ...RoleBase
      }
      user_tenant {
        id
      }
      user_added_at
      user_added_by
      user_updated_at
      user_updated_by
      user_enabled
      user_provisioning_status
      user_provisioning_error
      user_provisioned_at
    }
    ${RoleBase.fragment}
  `,
};

export const TenantUserBase = {
  fragment: gql`
    fragment TenantUserBase on User {
      id
      user_email
      user_full_name
      user_status
      user_management_type
      user_tenant_settings(tenant_ids: [$tenant_id], access_list_as_is: true) {
        ...TenantUserTenantSettingsBase
      }
      user_type
      user_lifecycle_options {
        user_can_be_activated
        user_password_can_be_reset
        user_can_be_unlocked
        user_can_be_unsuspended
      }
    }
    ${TenantUserTenantSettingsBase.fragment}
  `,
};

export const TenantAdUserBase = {
  fragment: gql`
    fragment TenantAdUserBase on User {
      id
      user_email
      user_full_name
      user_tenant_settings(tenant_ids: [$tenant_id], access_list_as_is: true) {
        ...TenantUserTenantSettingsBase
      }
      user_ad_groups(tenant_id: $tenant_id) {
        group_name
      }
    }
    ${TenantUserTenantSettingsBase.fragment}
  `,
};

export const PostMappingDataStandardResponseBase = {
  fragment: gql`
    fragment PostMappingDataStandardResponseBase on PostMappingDataStandardResponse {
      id
      category
      source_value
      target_value
    }
  `,
};
