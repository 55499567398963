import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { WithoutTypename } from '@utils/various';

export type DataLoadsContainers =
  WithoutTypename<SchemaTypes.DataLoadsContainers>;

export type DataLoadsJob = SchemaTypes.DataLoadsJobBaseFragment;

export type DataLoadsJobLink = SchemaTypes.DataLoadsJobLinksBaseFragment;

export type DataLoadsJobsFiltersInput = SchemaTypes.DataLoadsJobsFiltersInput;

/* eslint-disable-next-line */
export import DataLoadsJobsStatus = SchemaTypes.DataLoadsJobsStatus;
