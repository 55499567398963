import * as React from 'react';
import { getApiBaseUrl } from '@utils/apollo';

export const useDocumentationBaseUrl = () => {
  const docsBaseUrl = getApiBaseUrl('/api/docs');

  return React.useMemo(
    () => ({
      docsBaseUrl,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
