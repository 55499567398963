import { gql } from '@apollo/client';
import {
  TenantConfigurationDataBase,
  TenantConfigurationDataSampleValuesBase,
} from '@apollo-red/fragments';

export const GET_TENANTS_CONFIGURATION_DATA = gql`
  query getTenantsConfigurationData(
    $tenant_ids: [ID!]
    $applications: [TenantConfigurationDataType!]
  ) {
    getTenants(tenant_ids: $tenant_ids) {
      id
      tenant_configuration_data(applications: $applications) {
        ...TenantConfigurationDataBase
      }
    }
  }
  ${TenantConfigurationDataBase.fragment}
`;

export const GET_TENANTS_CONFIGURATION_DATA_SAMPLE_VALUES = gql`
  query getTenantsConfigurationDataSampleValues(
    $tenant_ids: [ID!]
    $applications: [TenantConfigurationDataType!]
  ) {
    getTenants(tenant_ids: $tenant_ids) {
      id
      tenant_configuration_data(applications: $applications) {
        ...TenantConfigurationDataSampleValuesBase
      }
    }
  }
  ${TenantConfigurationDataSampleValuesBase.fragment}
`;
