import {
  InsightsAlert,
  InsightsAlertScope,
  InsightsAlertWorkOrderVolumeTemplateData,
} from '@apollo-red/hooks';

/**
 * Prepares value for 'p_benchmark' filter.
 *
 * @param alert alert document
 * @param scope alert scope
 */
export const getBenchmarkFilterValues = (
  alert: InsightsAlert,
  scope?: InsightsAlertScope,
) => {
  const templateData =
    alert.template_data as InsightsAlertWorkOrderVolumeTemplateData;
  switch (scope) {
    case InsightsAlertScope.Internal: {
      return templateData.benchmark_internal
        ? [templateData.benchmark_internal]
        : [];
    }
    case InsightsAlertScope.External: {
      return templateData.benchmark_external
        ? [templateData.benchmark_external]
        : [];
    }
    default: {
      return [];
    }
  }
};
