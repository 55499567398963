import * as React from 'react';
import { useParams } from 'react-router-dom';
import { DocumentationProps } from '../types';
import { useDocumentationBaseUrl } from './useDocumentationBaseUrl';
import { useDocumentationLogin } from './useDocumentationLogin';

export const useDocumentation = () => {
  const { category, topic, subtopic } = useParams() as DocumentationProps;
  const { docsBaseUrl } = useDocumentationBaseUrl();
  const { loggedIn } = useDocumentationLogin();

  React.useEffect(() => {
    if (!loggedIn) {
      return;
    }

    const path = [category, topic, subtopic].filter(part => !!part).join('/');

    window.location.href = `${docsBaseUrl}/Content/${path}.htm`;
  }, [category, docsBaseUrl, loggedIn, subtopic, topic]);

  return {
    category,
    loggedIn,
    subtopic,
    topic,
  };
};
