import * as React from 'react';
import {
  InsightsAlertBaseWorkOrderTemplateData,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { formatDate } from './utils';
import { useAlertTextStyles } from './useAlertTextStyles';

interface FacilitiesExperienceAlertProps {
  template: InsightsAlertBaseWorkOrderTemplateData;
  boldText?: boolean;
  alertType: InsightsAlertType;
  threadId?: string;
}

const FacilitiesExperienceAlert: React.FC<FacilitiesExperienceAlertProps> = ({
  template,
  boldText,
  threadId,
  alertType,
}) => {
  const {
    speciality,
    work_order_number,
    work_order_priority,
    property_name,
    due_at,
    description,
    last_action_at,
    work_order_url = '#',
  } = template;
  const classes = useAlertTextStyles({ boldText });
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  return (
    <div className={classes.fontWeight}>
      A/n{' '}
      <b>
        {work_order_priority} – {speciality}{' '}
      </b>
      work order <b>({work_order_number}) </b> at your
      <b> {property_name} property</b> with an expected completion date of{' '}
      {formatDate(due_at)} has been flagged for its potential impact on employee
      experience.
      <ul>
        <li>Description: {description}</li>
        <li>Last Action Date: {formatDate(last_action_at)}</li>
      </ul>
      {work_order_url && (
        <>
          See notification details{' '}
          <a
            onClick={() => {
              trackInsightsAlertEvent('alertShowNotificationDetails', {
                alertThreadId: threadId ?? '',
                alertType: alertType ?? '',
              });
            }}
            href={work_order_url}
            className={classes.anchorText}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </>
      )}
    </div>
  );
};

export default FacilitiesExperienceAlert;
