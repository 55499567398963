import { TenantSetting } from './types';

export const getTenantSetting = <T extends TenantSetting>(
  current?: T[] | null,
  tenantId?: string | null,
) => {
  if (!current || !tenantId) {
    return undefined;
  }

  return current.find(setting => setting.tenant_id === tenantId);
};
