import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_ALERT_USER_IMPORTANCE } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import { InsightsAlertImportance } from '../insightsAlerts.types';

export const useSetInsightsAlertUserImportance = () => {
  const { userId, tenantId } = useGetInsightsInboxAlertState();
  const [setInsightsAlertUserImportance] = useAppMutation<
    SchemaTypes.SetInsightsAlertUserImportanceMutation,
    SchemaTypes.SetInsightsAlertUserImportanceMutationVariables
  >(SET_INSIGHTS_ALERT_USER_IMPORTANCE);

  return {
    setInsightsAlertUserImportance: (
      threadId: string,
      importance: InsightsAlertImportance,
    ) => {
      setInsightsAlertUserImportance({
        variables: {
          where: {
            tenant_id: tenantId,
            thread_id: threadId,
            user_id: userId,
            importance,
          },
        },
        optimisticResponse: {
          setInsightsAlertUserImportance: true,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlert',
              thread_id: threadId,
            }),
            fields: {
              user_importance() {
                return importance;
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
