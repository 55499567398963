import * as React from 'react';
import { Menu } from '@mui/material';
import { DashboardRouteProps } from '@pages/dashboards/types';
import TileAction, { DashboardCommand } from './TileAction';

interface TileActionMenuProps {
  anchorEl: HTMLElement | null;
  dashboardRoutes: DashboardRouteProps;
  reportId: string;
  onClose: () => void;
}

const TileActionMenu: React.FC<TileActionMenuProps> = ({
  anchorEl,
  dashboardRoutes,
  reportId,
  onClose,
}) => (
  <Menu
    data-testid={`actions-menu-${reportId}`}
    disableScrollLock={true}
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    <TileAction
      reportId={reportId}
      command={DashboardCommand.share}
      routes={dashboardRoutes}
    />
    <TileAction
      reportId={reportId}
      command={DashboardCommand.print}
      routes={dashboardRoutes}
    />
  </Menu>
);

export default TileActionMenu;
