import { isEqual } from 'lodash';
import { FormValues } from './useUniversalFiltersForm';
import { GeoFiltersOption } from './useGeoUniversalFiltersForm';

const currentPageName = () => {
  return window.location.pathname
    .split('/')
    .filter(el => !!el)
    .join('>');
};

const joinLocations = (locations?: GeoFiltersOption[]) => {
  return (locations ?? []).map(({ value }) => value).join(', ');
};

export const trackLocation = (
  initialValues?: FormValues,
  newValues?: FormValues,
) => {
  const {
    countries: initialCountries,
    states: initialStates,
    cities: initialCities,
  } = initialValues ?? {};

  const { countries, states, cities } = newValues ?? {};

  const hasInitialCountries = (initialCountries ?? []).length > 0;
  const hasInitialStates = (initialStates ?? []).length > 0;
  const hasInitialCities = (initialCities ?? []).length > 0;
  const hasCountries = (countries ?? []).length > 0;
  const hasStates = (states ?? []).length > 0;
  const hasCities = (cities ?? []).length > 0;

  const isLocationSelected =
    !hasInitialCountries &&
    !hasInitialStates &&
    !hasInitialCities &&
    (hasCountries || hasStates || hasCities);

  const isLocationUnselected =
    !hasCountries &&
    !hasStates &&
    !hasCities &&
    (hasInitialCountries || hasInitialStates || hasInitialCities);

  const isLocationEdited =
    !isLocationSelected &&
    !isLocationUnselected &&
    !isEqual(initialValues, newValues);

  const metadata = {
    pageName: currentPageName(),
    initialCountry: joinLocations(initialCountries),
    initialState: joinLocations(initialStates),
    initialCity: joinLocations(initialCities),
    country: joinLocations(countries),
    state: joinLocations(states),
    city: joinLocations(cities),
  };

  return {
    isLocationSelected,
    isLocationUnselected,
    isLocationEdited,
    metadata,
  };
};
