import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ReportExtended } from '@apollo-red/hooks';
import {
  ToolbarItems,
  ToolbarDownloadItems,
  ToolbarHelpItem,
  ToolbarItemsWrapper,
} from '../../toolbar';
import { ToolbarSettings } from '../types';
import ToolbarFilterButtons from './ToolbarFilterButtons';
import { useWithFiltersToolbar } from './useWithFiltersToolbar';
import { useWithFiltersToolbarStyles } from './useWithFiltersToolbarStyles';

export interface WithFiltersToolbarProps {
  enabledSettings?: ToolbarSettings;
  isSidebarExpanded?: boolean;
  report: ReportExtended;
  resetPinnedValues: () => void;
}

const WithFiltersToolbar: React.FC<WithFiltersToolbarProps> = ({
  report,
  enabledSettings = {
    share: true,
    favorites: true,
    revert: true,
    csv: true,
    image: true,
    pdf: true,
  },
  isSidebarExpanded = false,
  resetPinnedValues,
}) => {
  const { t } = useTranslation();
  const {
    dashboardTrackEventContext,
    documentationTopic,
    downloadButtonGroup,
    enableClearButton,
    getAppliedControlsCount,
    hasInteractionConfig,
    isCoreDashboard,
    resetFilters,
    showFiltersPanel,
    showMenu,
    showFilters,
    toggleFiltersPanel,
    toolbarButtonsWithoutButtonGroups,
    viz,
  } = useWithFiltersToolbar({
    enabledSettings,
    isSidebarExpanded,
    report,
    resetPinnedValues,
  });
  const classes = useWithFiltersToolbarStyles({
    showFiltersPanel,
    isCoreDashboard,
  });

  return (
    <Grid container={true} className={classes.wrapper} wrap="nowrap">
      <ToolbarItemsWrapper
        items={
          <ToolbarItems
            disabled={!viz}
            helpItem={
              !enabledSettings.help ? null : (
                <ToolbarHelpItem
                  disabled={!viz}
                  documentationTopic={documentationTopic}
                  dashboardTrackEventContext={dashboardTrackEventContext}
                />
              )
            }
            buttons={toolbarButtonsWithoutButtonGroups}
            showMenu={showMenu}
          />
        }
        downloadItems={
          <ToolbarDownloadItems
            disabled={!viz}
            buttonGroup={downloadButtonGroup}
          />
        }
      />
      {!showFilters ? null : (
        <ToolbarFilterButtons
          viz={viz}
          filtersClassName={classes.filterButtons}
          filterClassName={classes.filterButton}
          buttonClassName={classes.button}
          clearDisabled={!viz || !enableClearButton}
          clearLabel={t('clear')}
          onClear={resetFilters}
          filterDisabled={!viz || !hasInteractionConfig}
          filterLabel={t('filter')}
          onFilter={toggleFiltersPanel}
          appliedFilterCount={getAppliedControlsCount()}
        />
      )}
    </Grid>
  );
};

export default WithFiltersToolbar;
