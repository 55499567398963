export const isExternalTableauHostedReport = (url: string) => {
  const testUrl = url.toLowerCase();
  const origins = [
    process.env.TABLEAU_SERVER_ORIGIN_EXTERNAL_A4 ?? 'invalid',
    process.env.TABLEAU_SERVER_ORIGIN_EXTERNAL_A4TST ?? 'invalid',
  ];

  for (const origin of origins) {
    if (testUrl.indexOf(origin) === 0) {
      return true;
    }
  }

  return false;
};
