import * as React from 'react';
import { useHistory } from 'react-router';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@jll-labs/azara-ui-components';
import { LinearProgress, Box } from '@mui/material';
import { useRoutes } from '@routes';

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  handleSaveCustomView: (name: string) => Promise<string>;
}

const SaveCustomViewDialog: React.FC<Props> = ({
  isOpen,
  setOpen,
  handleSaveCustomView,
}) => {
  const history = useHistory();
  const routes = useRoutes();
  const [inputValue, setInputValue] = React.useState('');
  const [err, setErr] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setErr(false);
    setLoading(false);
    handleClose();
  };

  const handleSave = async () => {
    if (inputValue) {
      setLoading(true);
      try {
        const savedReportId = await handleSaveCustomView(inputValue);
        history.push(
          routes.dashboards.dashboard.getLink('saved', savedReportId),
        );
      } catch (e) {
        setErr(true);
        setLoading(false);
      }
    }
  };

  const Content = () => {
    if (err) {
      return (
        <>
          <DialogTitle handleClose={handleClose} title="An error occurred" />
          <DialogContent>{'Ooops, something went wrong.'}</DialogContent>
          <DialogActions handleDismissClick={handleCancel} showDismiss={true} />
        </>
      );
    }
    return (
      <>
        <DialogTitle handleClose={handleClose} title="Save Custom View" />
        <DialogContent>
          <Box mb={3}>
            {'To save current view, please type new custom view name.'}
          </Box>
          <TextField
            autoFocus={true}
            value={inputValue}
            onChange={handleChange}
            label="Custom view name"
          />
        </DialogContent>
        <DialogActions
          handleDismissClick={handleCancel}
          handleConfirmClick={handleSave}
          showDismiss={true}
        />
      </>
    );
  };

  return (
    <Dialog open={isOpen} handleClose={handleClose}>
      <Content />
      {loading && <LinearProgress />}
    </Dialog>
  );
};

export default SaveCustomViewDialog;
