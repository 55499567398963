import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { ADD_TENANT_CONFIGURATION_DATA } from '@apollo-red/mutations';
import { stripProp } from '@utils/various';

export const useAddTenantConfigurationData = () => {
  const [addTenantConfigurationData] = useAppMutation<
    SchemaTypes.AddTenantConfigurationDataMutation,
    SchemaTypes.AddTenantConfigurationDataMutationVariables
  >(ADD_TENANT_CONFIGURATION_DATA);

  return {
    addTenantConfigurationData: (
      tenantId: string,
      configurationData: SchemaTypes.TenantConfigurationDataInput,
    ) => {
      return addTenantConfigurationData({
        variables: {
          data: stripProp(configurationData, '__typename'),
          where: {
            id: tenantId,
          },
        },
      });
    },
  };
};
