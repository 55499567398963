import { useFeatureFlag } from '@utils/launchDarkly';
import { UserAlertRuleType } from '@apollo-red/hooks';

export const filterAlertTypes = (
  alertType: UserAlertRuleType,
  launchDarklySettings: {
    [key in Extract<
      UserAlertRuleType,
      UserAlertRuleType.OccupancyEfficiency | UserAlertRuleType.SubleaseRisk
    >]: boolean;
  },
) => {
  if (launchDarklySettings[alertType] === undefined) {
    return true;
  }

  return launchDarklySettings[alertType];
};

export const useLaunchDarklyUserAlertSettings = () => {
  const insightsAlertsOccupancyEfficiency = useFeatureFlag(
    'insightsAlertsOccupancyEfficiency',
  );

  const insightsAlertsSubleaseRisk = useFeatureFlag(
    'insightsAlertsSubleaseRisk',
  );

  const insightsAlertsSameDayWorkOrderCompletion = useFeatureFlag(
    'insightsAlertsSameDayWorkOrderCompletion',
  );

  return {
    [UserAlertRuleType.OccupancyEfficiency]: insightsAlertsOccupancyEfficiency,
    [UserAlertRuleType.SubleaseRisk]: insightsAlertsSubleaseRisk,
    [UserAlertRuleType.SameDayWorkOrderCompletion]:
      insightsAlertsSameDayWorkOrderCompletion,
  };
};
