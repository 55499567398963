import { useGetInsightsAlertHistoryQuery } from './useGetInsightsAlertHistoryQuery';

export const useGetInsightsAlertHistory = (
  userId: string,
  tenantId: string,
  threadId: string,
) => {
  const { loading, data, fetchMore, variables } =
    useGetInsightsAlertHistoryQuery({
      userId,
      tenantId,
      threadId,
    });

  const { thread_id, alert_history } = data?.getUser?.user_insights_alert ?? {};
  const { continuation_token, history } = alert_history || {};

  const loadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        whereInsightsAlertHistory: {
          ...variables?.whereInsightsAlertHistory,
          continuation_token,
        },
      },
    });
  };

  return {
    thread_id,
    loading,
    history,
    loadMore,
    hasNextPage: !!continuation_token,
  };
};
