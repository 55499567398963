import * as React from 'react';
import { useThumbnailStyles } from './styles';
import ThumbnailImageWrapper from './ThumbnailImageWrapper';

interface FallbackThumbnailImageProps {
  reportId: string;
}

const FallbackThumbnailImage: React.FC<FallbackThumbnailImageProps> = ({
  reportId,
}) => {
  const classes = useThumbnailStyles();

  return React.useMemo(
    () => (
      <ThumbnailImageWrapper>
        <div
          className={`${classes.fallbackThumbnail} ${classes.image}`}
          data-testid={`thumbnail-img-${reportId}`}
        />
      </ThumbnailImageWrapper>
    ),
    [classes.fallbackThumbnail, classes.image, reportId],
  );
};

export default FallbackThumbnailImage;
