import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@jll-labs/azara-ui-components';
import { SxProps, Theme } from '@mui/material';

const linkStyle = {
  textDecoration: 'none',
};

interface Props {
  to: string;
  label: string;
  onClick?: () => void;
  buttonSx?: SxProps<Theme>;
  disabled?: boolean;
  minWidth?: boolean;
}

const ButtonLink: React.FunctionComponent<Props> = ({
  to,
  buttonSx,
  label,
  onClick,
  disabled,
  minWidth,
}) => {
  if (disabled) {
    return <Button disabled={disabled}>{label}</Button>;
  }

  return (
    <Link to={to} style={linkStyle} data-testid="link">
      <Button
        onClick={onClick}
        color="primary"
        data-testid="button"
        sx={buttonSx}
        style={!minWidth ? { minWidth: 0 } : {}}
        variant="text"
      >
        {label}
      </Button>
    </Link>
  );
};

export default ButtonLink;
