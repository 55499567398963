import * as React from 'react';
import cx from 'classnames';
import { LinearProgress, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface StyleProps {
  loaderContainerStyles?: object;
  loaderStyles?: object;
}

const useStyles = makeStyles({
  loading: (styleProps: StyleProps) => ({
    opacity: 0,
    ...styleProps.loaderContainerStyles,
  }),
  circularProgress: (styleProps: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...styleProps.loaderStyles,
  }),
});

export enum TableauLoaderStyle {
  Linear,
  Circular,
}

interface Props {
  loading: boolean;
  loader: TableauLoaderStyle;
  loaderStyles?: object;
  loaderContainerStyles?: object;
}

const TableauLoader: React.FC<Props> = ({
  loaderContainerStyles,
  loaderStyles,
  loading,
  children,
  loader,
}) => {
  const classes = useStyles({
    loaderContainerStyles,
    loaderStyles,
  });

  const isLinear = loader === TableauLoaderStyle.Linear;
  const isCircular = loader === TableauLoaderStyle.Circular;

  return (
    <>
      {isCircular && loading && (
        <div className={classes.circularProgress}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={cx(loading && classes.loading)}>{children}</div>
      {isLinear && loading && <LinearProgress color="primary" />}
    </>
  );
};

export default TableauLoader;
