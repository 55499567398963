import * as React from 'react';
import { Box, Grid, SxProps, Theme, useTheme } from '@mui/material';
import { Button } from '@jll-labs/azara-ui-components';
import { HomePageBg, Screenshot2 } from '@assets';
import Title from './Title';
import BulletList from './BulletList';

const useStyles = (): Record<
  | 'root'
  | 'banner'
  | 'bannerText'
  | 'externalLinkContainer'
  | 'externalLinks'
  | 'externalLink'
  | 'hero'
  | 'screenshot',
  SxProps<Theme>
> => {
  const theme = useTheme();
  return {
    root: {
      backgroundImage: `url(${HomePageBg})`,
      backgroundSize: 'cover',
      backgroundColor: theme.palette.secondary.light,
    },
    banner: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.secondary.dark,
      width: '100%',
      opacity: 0.9,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
    bannerText: {
      padding: theme.spacing(1, 1),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 0),
        textAlign: 'left',
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(0.5, 0),
        textAlign: 'right',
      },
    },
    externalLinkContainer: {
      marginLeft: theme.spacing(4),
    },
    externalLinks: {
      marginLeft: theme.spacing(1),
      textTransform: 'none',
    },
    externalLink: {
      color: theme.palette.text.secondary,
      textDecoration: 'none',
    },
    hero: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(12),
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
    screenshot: {
      width: '100%',
    },
  };
};

const points = [
  'save money',
  'better manage risk',
  'improve owner and occupier satisfaction',
];

const LEARN_MORE =
  'https://www.jllt.com/azara-cre-bi-platform/?utm_medium=jllt&utm_source=azara-jllt-com&utm_term=stripe';
const REQUEST_A_DEMO =
  'https://www.jllt.com/azara-cre-bi-platform/?utm_medium=jllt&utm_source=azara-jllt-com&utm_term=stripe#contact';

const Hero = () => {
  const styles = useStyles();

  const handleClick = (url: string) => _ => {
    window.open(url);
  };

  return (
    <Box component="div" sx={styles.root}>
      <div>
        <Grid
          container={true}
          sx={styles.banner}
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <Grid
            item={true}
            sx={styles.bannerText}
            xs={9}
            sm={6}
            md={7}
            lg={5}
            xl={7}
          >
            <div>
              {`Interested in learning more about how JLL Azara can help your
                organization gain deeper, actionable insights into your CRE portfolio
                + operations?`}
            </div>
          </Grid>
          <Grid item={true} sx={styles.externalLinkContainer} xl={3}>
            <Button
              variant="outlined"
              size="medium"
              onClick={handleClick(LEARN_MORE)}
              type="button"
              sx={styles.externalLinks}
            >
              Learn more
            </Button>
            <Button
              variant="outlined"
              size="medium"
              onClick={handleClick(REQUEST_A_DEMO)}
              type="button"
              sx={styles.externalLinks}
            >
              Request a demo
            </Button>
          </Grid>
        </Grid>
        <Grid container={true} sx={styles.hero}>
          <Grid item={true} xs={12} lg={6}>
            <Title
              title="Grow with"
              subtitle="Shift from analytics to insight."
            />
            <BulletList
              description={
                <div>
                  <div>
                    <Box
                      component="a"
                      sx={styles.externalLink}
                      href="https://www.jllt.com/azara-cre-bi-platform/?utm_medium=jllt&utm_source=azara-jllt-com&utm_term=text"
                    >
                      Azara
                    </Box>{' '}
                    {'is intelligent CRE, transforming data into insights'}
                  </div>
                  <div>{'and proactive recommendations to help you:'}</div>
                </div>
              }
              points={points}
            />
          </Grid>
          <Grid
            item={true}
            lg={6}
            sx={{ display: { xs: 'none', lg: 'block' } }}
          >
            <Box component="img" src={Screenshot2} sx={styles.screenshot} />
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Hero;
