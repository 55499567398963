import { useTranslation } from 'react-i18next';
import { SelectOption } from '@jll-labs/azara-ui-components';
import { useFeatureFlag } from '@utils/launchDarkly';
import { TenantQaType } from '@apollo-red/hooks';

export const useSelectOptions = () => {
  const { t } = useTranslation();
  const qaEnvSwitchWdlive = useFeatureFlag('qaEnvSwitchWdlive');
  const qaEnvSwitchWdqa = useFeatureFlag('qaEnvSwitchWdqa');
  const qaEnvSwitchNonwd = useFeatureFlag('qaEnvSwitchNonwd');
  const qaEnvSwitchDemo = useFeatureFlag('qaEnvSwitchDemo');

  const envs: Record<TenantQaType, boolean> = {
    demo: qaEnvSwitchDemo,
    nonwd: qaEnvSwitchNonwd,
    wdlive: qaEnvSwitchWdlive,
    wdqa: qaEnvSwitchWdqa,
  };

  const selectOptions: SelectOption[] = Object.values(TenantQaType)
    .filter(tenantQaType => envs[tenantQaType])
    .map(tenantQaType => ({
      label: t(tenantQaType),
      value: tenantQaType,
    }));

  return selectOptions;
};
