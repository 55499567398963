import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_USER_DATA } from '@apollo-red/queries';
import { useAppQuery } from '../../queries';

interface UseUserQueryArgs {
  email?: string;
}

const useUserQuery = ({ email }: UseUserQueryArgs) => {
  const skip = !email;
  const { loading, data, error } = useAppQuery<
    SchemaTypes.GetUserQuery,
    SchemaTypes.GetUserQueryVariables
  >(GET_USER_DATA, {
    variables: {
      where: { email },
    },
    skip,
  });

  return React.useMemo(
    () => ({
      loading: loading || skip,
      error,
      data,
    }),
    [data, error, loading, skip],
  );
};

export default useUserQuery;
