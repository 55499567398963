import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  GET_TENANTS,
  GET_TENANTS_LIST,
  GET_TENANT_DATA_STANDARDS_GLOBAL_VIEWS,
  GET_TENANT_DATA_STANDARDS_MAPPED,
  GET_TENANT_DATA_STANDARDS_UNMAPPED,
  GET_TENANT_DATA_STANDARDS_TARGET_VALUES,
  GET_QA_TENANTS,
} from '@apollo-red/queries';
import {
  ADD_TENANT,
  UPDATE_TENANT,
  SYNC_TENANTS,
  ADD_TENANT_ALERT_SETTINGS,
  POST_MAPPING_DATA_STANDARD,
  UPDATE_TENANT_REPORTS,
  UPDATE_TENANT_KPI_SETTINGS,
  UPSERT_TENANT_SELF_SERVE_ANALYTICS_SETTINGS,
  PROVISION_TENANT,
  DELETE_TENANT,
} from '@apollo-red/mutations';
import { filterItems, WithoutTypename } from '@utils/various';
import { filterSpecialReports } from '../reports';
import { useAppQuery, useAppMutation } from '../queries';
import { useGetUserDefaultTenantId } from '../user';
import { TenantQaType } from '@apollo-red/hooks';

export type TenantShort = SchemaTypes.TenantBaseFragment;

export type TenantApplication = SchemaTypes.TenantApplicationBaseFragment;

export type TenantFeatures = SchemaTypes.TenantFeaturesBaseFragment;

export type Tenant = SchemaTypes.TenantExtendedFragment;

export type TenantAlertSettingsInput = SchemaTypes.TenantAlertSettingsInput;

export type TenantFinanceDataOptions = SchemaTypes.TenantFinanceDataOptions;

export type TenantTransactionDataOptions =
  SchemaTypes.TenantTransactionDataOptions;

export type TenantTransactionDataValues =
  WithoutTypename<SchemaTypes.TenantTransactionDataValues>;

export type TenantDefaultsDataValues =
  WithoutTypename<SchemaTypes.TenantDefaultsDataValues>;

export type TenantFinanceDataValues =
  WithoutTypename<SchemaTypes.TenantFinanceDataValues>;

export type TenantWorkOrdersDataValues =
  WithoutTypename<SchemaTypes.TenantWorkOrdersDataValues>;

export type DataThreshold = WithoutTypename<SchemaTypes.DataThreshold>;

export type TenantLeaseDataValues =
  WithoutTypename<SchemaTypes.TenantLeaseDataValues>;

export type TenantSpaceDataValues =
  WithoutTypename<SchemaTypes.TenantSpaceDataValues>;

export type TenantLeaseDataOptions = SchemaTypes.TenantLeaseDataOptions;

export type TenantSpaceDataOptions = SchemaTypes.TenantSpaceDataOptions;

export { TenantWorkOrderCompletionDate } from '@apollo-red/schema/generated/operations';

/* eslint-disable-next-line */
export import TenantAlertSettingsType = SchemaTypes.TenantAlertSettingsType;

/* eslint-disable-next-line */
export import TenantKpiSettingsType = SchemaTypes.TenantKpiSettingsType;

export import MappingType = SchemaTypes.MappingType;
export type GlobalView = WithoutTypename<SchemaTypes.GlobalView>;
export type GlobalData = WithoutTypename<SchemaTypes.GlobalData>;
export type GroupMapping = WithoutTypename<SchemaTypes.GroupMapping>;
export type Mapped = WithoutTypename<SchemaTypes.Mapped>;
export type Unmapped = WithoutTypename<SchemaTypes.Unmapped>;
export import MappingOperation = SchemaTypes.PostMappingDataStandardMappingOperation;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import TenantStatus = SchemaTypes.TenantStatus;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import TenantUserIntegration = SchemaTypes.TenantUserIntegration;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import TenantType = SchemaTypes.TenantType;
import { updateTenantReportsElements, useGetAllTenantReports } from '.';

export const useGetTenantsList = () => {
  const { loading, data, startPolling, stopPolling } =
    useAppQuery<SchemaTypes.GetTenantsQuery>(GET_TENANTS_LIST);

  return {
    loading,
    tenants: filterItems(data?.getTenants ?? []),
    startPolling,
    stopPolling,
  };
};

export const getTenantFromQuery = (
  tenantId: string,
  data?: SchemaTypes.GetTenantFullQuery,
) => filterItems(data?.getTenants ?? []).find(t => t.id === tenantId);

export const useGetTenant = (
  tenantId: string,
  showHiddenReports?: boolean,
  showDisabledReports?: boolean,
) => {
  const { loading, data, refetch } = useAppQuery<
    SchemaTypes.GetTenantFullQuery,
    SchemaTypes.GetTenantFullQueryVariables
  >(GET_TENANTS, {
    variables: {
      tenant_ids: [tenantId],
      report_filters: {
        show_hidden: showHiddenReports,
        show_disabled: showDisabledReports,
      },
    },
  });

  return {
    loading,
    refetch,
    tenant: getTenantFromQuery(tenantId, data),
  };
};

export const useGetAllReportsFromTenant = (tenantId: string) => {
  const { loading, tenant } = useGetTenant(tenantId, true);

  return {
    loading,
    allReports: filterSpecialReports(tenant?.tenant_reports || []),
  };
};

export const useAddTenantAlertSettings = () => {
  const [addTenantAlertSettings] = useAppMutation<
    SchemaTypes.AddTenantAlertSettingsMutation,
    SchemaTypes.AddTenantAlertSettingsMutationVariables
  >(ADD_TENANT_ALERT_SETTINGS);
  return {
    addTenantAlertSettings: (
      id: string,
      alertFields: SchemaTypes.TenantAlertSettingsInput,
    ) => {
      return addTenantAlertSettings({
        variables: {
          data: alertFields,
          where: {
            id,
          },
        },
      });
    },
  };
};

export const useAddTenant = () => {
  const [addTenant] = useAppMutation<
    SchemaTypes.AddTenantMutation,
    SchemaTypes.AddTenantMutationVariables
  >(ADD_TENANT);

  return {
    addTenant: (addTenantPayload: SchemaTypes.TenantCreateInput) => {
      return addTenant({
        variables: {
          data: addTenantPayload,
        },
        refetchQueries: [
          {
            query: GET_TENANTS_LIST,
          },
          ...(addTenantPayload.tenant_qa_type === TenantQaType.Wdqa
            ? [
                {
                  query: GET_QA_TENANTS,
                  variables: {
                    tenant_qa_type: TenantQaType.Wdqa,
                  },
                },
              ]
            : []),
        ],
      });
    },
  };
};

export const useDeleteTenant = () => {
  const [addTenant] = useAppMutation<
    SchemaTypes.DeleteTenantMutation,
    SchemaTypes.DeleteTenantMutationVariables
  >(DELETE_TENANT);

  return {
    deleteTenant: (tenantOvcId: string) => {
      return addTenant({
        variables: {
          where: { tenant_ovc_id: tenantOvcId },
        },
      });
    },
  };
};

export const useSyncTenants = () => {
  const [syncTenants] =
    useAppMutation<SchemaTypes.SyncTenantsMutation>(SYNC_TENANTS);

  return {
    syncTenants: (tenantsIds: string[]) => {
      const data = {
        variables: {
          where: { ids: tenantsIds },
        },
      };
      return syncTenants(data);
    },
  };
};

export const useUpdateTenant = () => {
  const [updateTenant] = useAppMutation<
    SchemaTypes.UpdateTenantMutation,
    SchemaTypes.UpdateTenantMutationVariables
  >(UPDATE_TENANT);

  return {
    updateTenant: (
      id: string,
      updateTenantPayload: SchemaTypes.TenantUpdateInput,
    ) => {
      const data = {
        variables: {
          data: updateTenantPayload,
          where: {
            id,
          },
        },
      };
      return updateTenant(data);
    },
  };
};

export const useUpdateTenantReports = () => {
  const tenantId = useGetUserDefaultTenantId();
  const {
    reports,
    publishedCustomReports,
    externalReports,
    tenantCustomReportsSettings,
  } = useGetAllTenantReports({
    tenantId,
    showHidden: true,
  });
  const [updateTenant] = useAppMutation<
    SchemaTypes.UpdateTenantReportsMutation,
    SchemaTypes.UpdateTenantReportsMutationVariables
  >(UPDATE_TENANT_REPORTS);

  return {
    updateTenantReports: (data: SchemaTypes.UpdateTenantReportsInput) => {
      const { elements_update, order_update } = data;
      let updatedTenantReports = reports;
      let updatedExternalReports = externalReports;
      let updatedPublishedCustomReports = publishedCustomReports;

      if (order_update && updatedTenantReports) {
        updatedTenantReports.sort((r1, r2) => {
          const r1c = order_update.indexOf(r1.id);
          const r2c = order_update.indexOf(r2.id);
          return r1c - r2c;
        });
      }

      if (elements_update) {
        updatedExternalReports = updateTenantReportsElements(
          updatedExternalReports,
          elements_update,
        );
        updatedPublishedCustomReports = updateTenantReportsElements(
          updatedPublishedCustomReports,
          elements_update,
        );
        updatedTenantReports = updateTenantReportsElements(
          updatedTenantReports,
          elements_update,
        );
      }

      return updateTenant({
        variables: {
          data,
          where: {
            id: tenantId,
          },
          report_filters: {
            show_hidden: true,
          },
        },
        optimisticResponse: {
          updateTenantReports: {
            id: tenantId,
            tenant_reports: updatedTenantReports ?? [],
            tenant_external_reports: updatedExternalReports,
            tenant_custom_reports_settings: {
              ...tenantCustomReportsSettings,
              tenant_custom_reports: updatedPublishedCustomReports,
              __typename: 'TenantCustomReportsSettings',
            },
            __typename: 'Tenant',
          },
        },
      });
    },
  };
};

export const useProvisionTenant = () => {
  const [provision] = useAppMutation<
    SchemaTypes.ProvisionTenantMutation,
    SchemaTypes.ProvisionTenantMutationVariables
  >(PROVISION_TENANT);

  return {
    provisionTenant: (id: string) =>
      provision({
        variables: {
          where: {
            id: id,
          },
        },
      }),
  };
};

export const useGetDataStandardsGlobalViews = (
  tenantId: string,
  mappingType: MappingType,
) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantDataStandardsGlobalViewsQuery,
    SchemaTypes.GetTenantDataStandardsGlobalViewsQueryVariables
  >(GET_TENANT_DATA_STANDARDS_GLOBAL_VIEWS, {
    variables: {
      tenant_id: tenantId,
      mapping_type: mappingType,
    },
  });

  return {
    loading,
    globalViews:
      data?.getTenants?.[0]?.tenant_data_standards.global_views.global_data,
    groupMappings:
      data?.getTenants?.[0]?.tenant_data_standards.global_views.group_mapping,
  };
};

export const useGetDataStandardsMapped = (
  tenantId: string,
  category: string,
) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantDataStandardsMappedQuery,
    SchemaTypes.GetTenantDataStandardsMappedQueryVariables
  >(GET_TENANT_DATA_STANDARDS_MAPPED, {
    variables: {
      tenant_id: tenantId,
      category: category,
    },
  });

  return {
    loading,
    mapped: data?.getTenants?.[0]?.tenant_data_standards.mapped,
  };
};

export const useGetDataStandardsUnmapped = (
  tenantId: string,
  category: string,
) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantDataStandardsUnmappedQuery,
    SchemaTypes.GetTenantDataStandardsUnmappedQueryVariables
  >(GET_TENANT_DATA_STANDARDS_UNMAPPED, {
    variables: {
      tenant_id: tenantId,
      category: category,
    },
  });

  return {
    loading,
    unmapped: data?.getTenants?.[0]?.tenant_data_standards.unmapped,
  };
};

export const useGetDataStandardsTargetValues = (
  tenantId: string,
  category: string,
) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantDataStandardsTargetValuesQuery,
    SchemaTypes.GetTenantDataStandardsTargetValuesQueryVariables
  >(GET_TENANT_DATA_STANDARDS_TARGET_VALUES, {
    variables: {
      tenant_id: tenantId,
      category: category,
    },
  });

  return {
    loading,
    targetValues:
      data?.getTenants?.[0]?.tenant_data_standards.target_possible_values,
  };
};

export interface PostMappingPayload {
  tenantOvcId: string;
  data: Array<{
    id: string;
    category: string;
    sourceValue: string;
    targetValue: string;
    standard_data_remapping?: MappingOperation;
  }>;
}

export const usePostMappingDataStandard = (tenantId: string) => {
  const [postMappingDataStandard] = useAppMutation<
    SchemaTypes.PostMappingDataStandardMutation,
    SchemaTypes.PostMappingDataStandardMutationVariables
  >(POST_MAPPING_DATA_STANDARD, {
    update: (proxy, { data }) => {
      const cacheGlobalViews = proxy.readQuery<
        SchemaTypes.GetTenantDataStandardsGlobalViewsQuery,
        SchemaTypes.GetTenantDataStandardsGlobalViewsQueryVariables
      >({
        query: GET_TENANT_DATA_STANDARDS_GLOBAL_VIEWS,
        variables: {
          mapping_type: MappingType.ClientValueMapping,
          tenant_id: tenantId,
        },
      });
      const tenant = cacheGlobalViews?.getTenants?.[0];
      const globalViews = tenant?.tenant_data_standards.global_views;

      const result = data?.postMappingDataStandard;
      if (globalViews && result && tenant) {
        const updatedGlobalViews = globalViews.global_data.map(globalView => {
          const { category, mismatch, match, total } = globalView;

          try {
            const cacheMapped = proxy.readQuery<
              SchemaTypes.GetTenantDataStandardsMappedQuery,
              SchemaTypes.GetTenantDataStandardsMappedQueryVariables
            >({
              query: GET_TENANT_DATA_STANDARDS_MAPPED,
              variables: {
                tenant_id: tenantId,
                category: category,
              },
            });

            const cacheUnmapped = proxy.readQuery<
              SchemaTypes.GetTenantDataStandardsUnmappedQuery,
              SchemaTypes.GetTenantDataStandardsUnmappedQueryVariables
            >({
              query: GET_TENANT_DATA_STANDARDS_UNMAPPED,
              variables: {
                tenant_id: tenantId,
                category: category,
              },
            });

            const mapped =
              cacheMapped?.getTenants?.[0]?.tenant_data_standards.mapped;
            const unmapped =
              cacheUnmapped?.getTenants?.[0]?.tenant_data_standards.unmapped;

            const removedUnmapped = unmapped?.filter(un =>
              result.some(res => res.id === un?.id),
            );
            const newMapped = result
              .filter(
                res =>
                  res.category === category &&
                  !mapped?.some(map => map?.id === res.id),
              )
              .map(
                res =>
                  ({
                    id: res.id,
                    source_value: res.source_value,
                    target_value: res.target_value,
                    __typename: 'Mapped',
                  } as SchemaTypes.Mapped),
              );
            const updatedMapped = result.filter(
              res =>
                res.category === category &&
                mapped?.some(map => map?.id === res.id),
            );

            proxy.writeQuery<
              SchemaTypes.GetTenantDataStandardsMappedQuery,
              SchemaTypes.GetTenantDataStandardsMappedQueryVariables
            >({
              query: GET_TENANT_DATA_STANDARDS_MAPPED,
              variables: {
                tenant_id: tenantId,
                category: category,
              },
              data: {
                getTenants: [
                  {
                    ...tenant,
                    tenant_data_standards: {
                      mapped: [
                        ...newMapped,
                        ...(mapped?.map(oldDataStandard => {
                          const map = updatedMapped.find(
                            umapped => umapped.id === oldDataStandard?.id,
                          );
                          if (map) {
                            return {
                              ...oldDataStandard,
                              target_value: map.target_value,
                            } as SchemaTypes.Mapped;
                          } else return oldDataStandard;
                        }) ?? []),
                      ],
                      __typename: 'TenantDataStandard',
                    },
                  },
                ],
              },
            });

            proxy.writeQuery<
              SchemaTypes.GetTenantDataStandardsUnmappedQuery,
              SchemaTypes.GetTenantDataStandardsUnmappedQueryVariables
            >({
              query: GET_TENANT_DATA_STANDARDS_UNMAPPED,
              variables: {
                tenant_id: tenantId,
                category: category,
              },
              data: {
                getTenants: [
                  {
                    ...tenant,
                    tenant_data_standards: {
                      unmapped:
                        unmapped?.filter(
                          unmap =>
                            !removedUnmapped?.some(
                              runmapped => runmapped?.id === unmap?.id,
                            ),
                        ) ?? [],
                      __typename: 'TenantDataStandard',
                    },
                  },
                ],
              },
            });
            return {
              ...globalView,
              mismatch:
                mismatch > 0 ? mismatch - (removedUnmapped?.length ?? 0) : 0,
              match: match + newMapped.length + (removedUnmapped?.length ?? 0),
              total: total + newMapped.length - (removedUnmapped?.length ?? 0),
            };
          } catch (e) {
            return globalView;
          }
        });
        proxy.writeQuery<
          SchemaTypes.GetTenantDataStandardsGlobalViewsQuery,
          SchemaTypes.GetTenantDataStandardsGlobalViewsQueryVariables
        >({
          query: GET_TENANT_DATA_STANDARDS_GLOBAL_VIEWS,
          variables: {
            mapping_type: MappingType.ClientValueMapping,
            tenant_id: tenantId,
          },
          data: {
            getTenants: [
              {
                ...tenant,
                tenant_data_standards: {
                  global_views: {
                    global_data: updatedGlobalViews,
                    group_mapping: globalViews.group_mapping,
                  },
                  __typename: 'TenantDataStandard',
                },
              },
            ],
          },
        });
      }
    },
  });

  return {
    postMapping: (payload: PostMappingPayload) =>
      postMappingDataStandard({
        variables: {
          where: {
            tenant_ovc_id: payload.tenantOvcId,
          },
          data: payload.data.map(d => ({
            id: d.id,
            category: d.category,
            source_value: d.sourceValue,
            target_value: d.targetValue,
            standard_data_remapping: d.standard_data_remapping,
          })),
        },
      }),
  };
};

export const useGetAllTenantApplications = (tenantId: string) => {
  const { loading, tenant } = useGetTenant(tenantId);
  return {
    loading,
    applications: tenant?.tenant_applications || [],
  };
};

export const useGetActiveTenantApplications = (tenantId: string) => {
  const { tenant, loading } = useGetTenant(tenantId);
  return {
    loading,
    applications:
      tenant?.tenant_applications
        .filter(({ app }) => app.app_active)
        .map(({ app }) => app) ?? [],
  };
};

export const useGetActiveProvisionedTenantApplications = (tenantId: string) => {
  const { tenant, loading } = useGetTenant(tenantId);
  return {
    loading,
    applications:
      tenant?.tenant_applications
        .filter(({ app, app_provisioned }) => app.app_active && app_provisioned)
        .map(({ app }) => app) ?? [],
  };
};

export const useUpdateTenantKpiSettings = () => {
  const [updateTenantKpiSettings] = useAppMutation<
    SchemaTypes.UpdateTenantKpiSettingsMutation,
    SchemaTypes.UpdateTenantKpiSettingsMutationVariables
  >(UPDATE_TENANT_KPI_SETTINGS);

  return {
    updateTenantKpiSettings: (
      tenant: Tenant,
      payload: SchemaTypes.TenantKpiSettingsInput[],
    ) =>
      updateTenantKpiSettings({
        variables: {
          where: {
            id: tenant.id,
          },
          data: payload.map(item => {
            return { ...item, __typename: undefined };
          }),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateTenantKpiSettings: {
            ...tenant,
            tenant_kpi_settings: payload,
            __typename: 'Tenant',
          },
        },
      }),
  };
};

export const useUpsertTenantSelfServeAnalyticsSettings = () => {
  const [upsertTenantSelfServeAnalyticsSettings] = useAppMutation<
    SchemaTypes.UpsertTenantSelfServeAnalyticsSettings,
    SchemaTypes.UpsertTenantSelfServeAnalyticsSettingsMutationVariables
  >(UPSERT_TENANT_SELF_SERVE_ANALYTICS_SETTINGS);

  return {
    upsertTenantSelfServeAnalyticsSettings: (
      tenantId: string,
      data: SchemaTypes.UpsertTenantSelfServeAnalyticsSettings,
    ) =>
      upsertTenantSelfServeAnalyticsSettings({
        variables: {
          where: {
            id: tenantId,
          },
          data,
        },
      }),
  };
};
