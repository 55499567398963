import * as React from 'react';
import {
  InsightsAlertLeaseEventTemplateData,
  InsightsAlertInbox,
  InsightsAlertScope,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { formatDate, getInternalLink } from './utils';
import { useAlertTextStyles } from './useAlertTextStyles';
import OpenAlertReport from './OpenAlertReport';
import { useRoutes } from '@routes';

interface LeaseEventAlertProps {
  template: InsightsAlertLeaseEventTemplateData;
  boldText?: boolean;
  inboxType?: InsightsAlertInbox;
  threadId: string;
  alertType: InsightsAlertType;
}

const LeaseEventAlert: React.FC<LeaseEventAlertProps> = ({
  template,
  boldText,
  inboxType,
  threadId,
  alertType,
}) => {
  const {
    event_type,
    property_name,
    event_created_date,
    event_notice_date,
    property_type,
    tenure_type,
    tenure_size,
    tenure_cost,
    annual_cost,
    head_count,
    desk_count,
  } = template;
  const classes = useAlertTextStyles({ boldText });
  const routes = useRoutes();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  return (
    <OpenAlertReport>
      <div className={classes.fontWeight}>
        A/n <b>{event_type} </b> event at your <b>{property_name} </b> property
        on
        <b> {formatDate(event_created_date)} </b> with a notice date of
        <b> {formatDate(event_notice_date)} </b> has not yet been actioned.
        <ul>
          <li>Property Type: {property_type} </li>
          <li>Tenure Type: {tenure_type}</li>
          <li>Tenure Size: {tenure_size}</li>
          <li>Tenure Cost: {tenure_cost}</li>
          <li>Annual Cost: {annual_cost}</li>
          {head_count && <li>Head Count: {head_count}</li>}
          {desk_count && <li>Seat Count: {desk_count}</li>}
        </ul>
        {inboxType && (
          <>
            See notification details{' '}
            <a
              onClick={() => {
                trackInsightsAlertEvent('alertShowNotificationDetails', {
                  alertThreadId: threadId ?? '',
                  alertType: alertType ?? '',
                });
              }}
              href={getInternalLink(
                threadId,
                inboxType,
                InsightsAlertScope.Internal,
                routes,
              )}
              className={classes.anchorText}
            >
              here
            </a>
          </>
        )}
      </div>
    </OpenAlertReport>
  );
};

export default LeaseEventAlert;
