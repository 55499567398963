import * as numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import {
  TenantKpiData,
  CURRENCY_TRANSLATION_KEYS,
  UNIT_OF_MEASUREMENT_TRANSLATION_KEYS,
  Report,
  useGetUserCurrentTenantReports,
  useGetActiveTenantApplications,
  useGetUserDefaultTenantId,
  useGetTenant,
  TenantKpiSettingsType,
} from '@apollo-red/hooks';

export interface KpiBoxData {
  value?: string;
  change?: string;
  lastYear?: string;
}

export interface KeyMetricBox {
  title: string;
  data: KpiBoxData;
  report?: Report;
  applicationCodes: string[];
}

export const parseVal = (format: string, val?: number | null) =>
  val === undefined ? undefined : numeral(val).format(format);

const getCurrency = (tenantKpiData: TenantKpiData) => {
  if (tenantKpiData.kpi_data?.actual_costs && tenantKpiData.kpi_data.currency) {
    return {
      value: tenantKpiData.kpi_data?.actual_costs,
      label: CURRENCY_TRANSLATION_KEYS[tenantKpiData.kpi_data.currency],
    };
  }

  return {
    value: tenantKpiData.kpi_data?.actual_costs_usd,
    label: CURRENCY_TRANSLATION_KEYS.usd,
  };
};

const getSize = (tenantKpiData: TenantKpiData) => {
  const common = {
    value: tenantKpiData.kpi_data?.size_current,
    change: tenantKpiData.kpi_data?.size_change,
    lastYear: tenantKpiData.kpi_data?.size_last_yr,
  };

  if (tenantKpiData.kpi_data?.unit_of_measurement) {
    return {
      ...common,
      label:
        UNIT_OF_MEASUREMENT_TRANSLATION_KEYS[
          tenantKpiData.kpi_data.unit_of_measurement
        ],
    };
  }

  return {
    ...common,
    label: UNIT_OF_MEASUREMENT_TRANSLATION_KEYS.sqft,
  };
};

export const useKeyMetricBoxes = (
  tenantKpiData: TenantKpiData,
): KeyMetricBox[] => {
  const { t } = useTranslation();
  const { kpiDetailReports } = useGetUserCurrentTenantReports();
  const currency = getCurrency(tenantKpiData);
  const size = getSize(tenantKpiData);
  const kpiData = tenantKpiData.kpi_data;
  const tenantId = useGetUserDefaultTenantId();
  const { applications, loading } = useGetActiveTenantApplications(tenantId);
  const { tenant, loading: tenantLoading } = useGetTenant(tenantId);
  const enabledKpiTypes =
    tenant?.tenant_kpi_settings
      ?.filter(setting => setting?.kpi_enabled)
      ?.map(setting => setting.id) ?? [];

  if (loading || tenantLoading || !applications) {
    return [];
  }

  return [
    {
      id: TenantKpiSettingsType.PropertyCount,
      title: 'Property Count',
      applicationCodes: ['MDM'],
      report: kpiDetailReports?.properties,
      data: {
        value: parseVal('0,0', kpiData?.properties_current),
        change: parseVal('0a', kpiData?.properties_change || undefined),
        lastYear: parseVal('0a', kpiData?.properties_last_yr),
      },
    },
    {
      id: TenantKpiSettingsType.Size,
      title: `Area (${t(size.label)})`,
      applicationCodes: ['MDM'],
      report: kpiDetailReports?.area,
      data: {
        value: parseVal('0.00a', size.value),
        change: parseVal('0.00a', kpiData?.size_change || undefined),
        lastYear: parseVal('0.00a', kpiData?.size_last_yr),
      },
    },
    {
      id: TenantKpiSettingsType.ActualCosts,
      title: `Cost (${t(currency.label)})`,
      applicationCodes: ['FIN'],
      report: kpiDetailReports?.cost,
      data: {
        value: parseVal('0.00a', currency.value),
      },
    },
    {
      id: TenantKpiSettingsType.HeadCount,
      title: t('headCount'),
      // There is no dashboard associated with head count.
      applicationCodes: ['SPC'],
      report: undefined,
      data: {
        value: parseVal('0,0', kpiData?.head_count),
        change: undefined,
        lastYear: undefined,
      },
    },
    {
      id: TenantKpiSettingsType.SeatCount,
      title: t('seatCount'),
      // There is no dashboard associated with seat count.
      applicationCodes: ['SPC'],
      report: undefined,
      data: {
        value: parseVal('0,0', kpiData?.desk_count),
        change: undefined,
        lastYear: undefined,
      },
    },
  ]
    .filter(metricBox =>
      applications.some(application =>
        metricBox.applicationCodes.includes(application.app_cat),
      ),
    )
    .filter(metricBox => enabledKpiTypes.includes(metricBox.id));
};
