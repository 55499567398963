import * as React from 'react';
import { useRoutes } from '@routes';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ActiveUserOnly } from '@components/featureToggle';
import Welcome from './Welcome';
import Role from './Role';
import Scope from './Scope';
import Favorites from './Favorites';
import Contact from './Contact';
import Summary from './Summary';
import { ValidateJumpStepper } from './OnBoarding';

interface Props {
  steps: number;
  activeStep: number;
  isLastPage: boolean;
  isFirstPage: boolean;
  handleBackClick: () => void;
  handleStepClick: (step: number) => () => void;
  handleNextClick?: (step: number) => void;
  completed: ValidateJumpStepper[];
  lastCompletedStep: number;
}

const OnBoardingRoutes: React.FC<Props> = onboardingProps => {
  const { onBoarding } = useRoutes();

  return (
    <ActiveUserOnly>
      <Switch>
        <Route exact={true} path={onBoarding.welcome.path}>
          <Welcome {...onboardingProps} activeStep={0} isFirstPage={true} />
        </Route>
        <Route exact={true} path={onBoarding.roles.path}>
          <Role {...onboardingProps} activeStep={1} />
        </Route>
        <Route exact={true} path={onBoarding.scope.path}>
          <Scope {...onboardingProps} activeStep={2} />
        </Route>
        <Route exact={true} path={onBoarding.favorites.path}>
          <Favorites {...onboardingProps} activeStep={3} />
        </Route>
        <Route exact={true} path={onBoarding.contact.path}>
          <Contact {...onboardingProps} activeStep={4} />
        </Route>
        <Route exact={true} path={onBoarding.summary.path}>
          <Summary {...onboardingProps} activeStep={5} isLastPage={true} />
        </Route>
        <Route>
          <Redirect to={onBoarding.welcome.path} />
        </Route>
      </Switch>
    </ActiveUserOnly>
  );
};

export default OnBoardingRoutes;
