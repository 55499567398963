import * as React from 'react';
import {
  AuthType,
  init,
  EmbedEvent,
  PinboardEmbed,
} from '@thoughtspot/visual-embed-sdk';
import { useGetUserInfo } from '@apollo-red/hooks';
import { useGetThoughtSpotAuthToken } from './useGetThoughtSpotAuthToken';

interface UseEmbedThoughtSpotPinboard {
  targetRef?: React.RefObject<HTMLDivElement>;
  thoughtSpotHost?: string;
  pinboardId?: string;
  visualizationId?: string;
  handleOnLoad?: () => void;
}

export const useEmbedThoughtSpotPinboard = ({
  handleOnLoad,
  pinboardId,
  targetRef,
  thoughtSpotHost,
  visualizationId,
}: UseEmbedThoughtSpotPinboard) => {
  const { user } = useGetUserInfo();
  const userEmail = user?.user_email;
  const { authToken, error: authError } = useGetThoughtSpotAuthToken(user?.id);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasData, setHasData] = React.useState(true);
  const [isAuthExpire, setIsAuthExpired] = React.useState(false);
  const [isEmbedded, setIsEmbedded] = React.useState(false);

  React.useEffect(() => {
    if (isInitialized || !authToken || !userEmail || !thoughtSpotHost) {
      return;
    }

    init({
      thoughtSpotHost,
      authType: AuthType.AuthServer,
      username: userEmail,
      getAuthToken: () => Promise.resolve(authToken),
    });
    setIsInitialized(true);
  }, [authToken, isInitialized, setIsInitialized, thoughtSpotHost, userEmail]);

  React.useEffect(() => {
    if (!isInitialized || !targetRef?.current || !pinboardId) {
      return;
    }

    const embed = new PinboardEmbed(targetRef.current, {
      frameParams: { height: '700px', width: '100%' },
      fullHeight: true,
      pinboardId,
      vizId: visualizationId,
    });

    const onInit = () => {
      setIsLoading(true);
    };

    const onLoad = () => {
      setIsLoading(false);
      if (handleOnLoad) {
        handleOnLoad();
      }
    };

    const onAuthExpire = () => {
      setIsAuthExpired(true);
    };

    const onError = () => {
      setHasData(false);
      setIsLoading(false);
    };

    embed
      .on(EmbedEvent.Init, onInit)
      .on(EmbedEvent.Load, onLoad)
      .on(EmbedEvent.AuthExpire, onAuthExpire)
      .on(EmbedEvent.Error, onError)
      .render();

    setIsEmbedded(true);
  }, [isInitialized, targetRef, pinboardId, visualizationId, handleOnLoad]);

  return {
    isEmbedded,
    isLoading,
    hasData,
    isAuthExpire,
    authError,
  };
};
