import { useGetInsightsInboxSettings } from './useGetInsightsInboxSettings';

export const useGetInsightsInboxAlertAssignedToFilter = () => {
  const { loading, inboxSettings } = useGetInsightsInboxSettings();

  return {
    alertAssignedToFilter:
      inboxSettings?.insights_selected_filters.insights_alert_assigned_to ??
      'all',
    loading,
  };
};
