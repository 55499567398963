import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';

export interface StyleProps {
  boldText?: boolean;
}

export const useAlertTextStyles = makeStyles((theme: AppTheme) => ({
  anchorText: {
    color: `${theme.palette.action.active} !important`,
  },
  fontWeight: ({ boldText }: StyleProps) => ({
    '& b': {
      fontWeight: boldText
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightRegular,
    },
  }),
}));
