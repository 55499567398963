import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, utils } from '@jll-labs/azara-ui-components';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { Tooltip, Grid } from '@mui/material';
import { Report } from '@apollo-red/hooks';
import AddReportsMenu from '@components/tiles/AddReportsMenu';
import { AppTheme } from '@theme';
import { Display } from '../types';

const { ScrollContext } = utils;

const useStyles = makeStyles((theme: AppTheme) => ({
  addDashBtn: {
    marginRight: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
  },
}));

interface Props {
  deleteMode: boolean;
  setDeleteMode: (deleteMode: boolean) => void;
  displayMode: Display;
  setDisplayMode: (displayMode: Display) => void;
  useSaveHandler: (reports: Report[]) => { saveHandler: () => void };
  isPATMenu?: boolean;
  showAddReports?: boolean;
}

const Toolbar: React.FC<Props> = ({
  showAddReports,
  displayMode,
  setDisplayMode,
  useSaveHandler,
  isPATMenu,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { resetScrollTop } = React.useContext(ScrollContext);

  const handleChangeDisplayMode = (modeName: Display) => () => {
    setDisplayMode(modeName);
    resetScrollTop();
  };

  return (
    <Grid container={true}>
      {showAddReports && (
        <div className={classes.addDashBtn}>
          <AddReportsMenu
            useSaveHandler={useSaveHandler}
            isPATMenu={isPATMenu}
          />
        </div>
      )}
      <Tooltip title={t('thumbnailView') ?? ''}>
        <Button
          variant="contained"
          color={displayMode === 'table' ? 'secondary' : 'primary'}
          onClick={handleChangeDisplayMode(Display.grid)}
        >
          <Icon name="Apps" />
        </Button>
      </Tooltip>
      <Tooltip title={t('listView') ?? ''}>
        <Button
          variant="contained"
          color={displayMode === 'grid' ? 'secondary' : 'primary'}
          className={classes.iconButton}
          onClick={handleChangeDisplayMode(Display.table)}
        >
          <Icon name="Menu" />
        </Button>
      </Tooltip>
    </Grid>
  );
};

export default Toolbar;
