import * as React from 'react';
import {
  Autocomplete,
  AutocompleteOption,
  utils,
} from '@jll-labs/azara-ui-components';
import { ReportControlStaticListItem } from '@apollo-red/hooks';
const { useToggleValue } = utils;

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  loading: boolean;
  initialValue?: ReportControlStaticListItem;
  updateValues: (selectedValues: ReportControlStaticListItem[]) => void;
  label?: string;
  hasDefaultSelection?: boolean;
}

const SingleSelect: React.FC<Props> = ({
  loading,
  updateValues,
  initialValue,
  options,
  label,
  hasDefaultSelection,
}) => {
  const [selectedValue, setSelectedValue] =
    React.useState<AutocompleteOption | null>(() => {
      if (!initialValue) {
        return null;
      }

      const { label, value, selected } = initialValue;

      return {
        label,
        value,
        selected: !!selected,
      };
    });

  const [loadingIndicator, toggleLoadingIndicator] = useToggleValue(false);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!loading && loadingIndicator) {
      toggleLoadingIndicator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadingIndicator]);

  const handleChange = (_, option: AutocompleteOption) => {
    toggleLoadingIndicator();
    setSelectedValue(option);
    updateValues([option]);
  };

  React.useEffect(() => {
    if (hasDefaultSelection && initialValue && !initialized) {
      updateValues([initialValue]);
      setInitialized(true);
    }
  }, [hasDefaultSelection, initialized, initialValue, updateValues]);

  return (
    <Autocomplete
      label={label ?? ''}
      options={options}
      onChange={handleChange}
      value={selectedValue}
      loading={loadingIndicator}
      disabled={loading}
      disableClearable={true}
      inputLabelWidth="md"
      inputMaxWidth="md"
      coloredOutline={!!selectedValue}
      size="sm"
    />
  );
};

export default SingleSelect;
