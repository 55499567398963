import * as React from 'react';
import { Form, FormSubmit } from '@jll-labs/azara-ui-components';
import RoleForm, { useWorkRolesForm, FormValues } from '@components/workRoles';
import StepWrapper, { StepWrapperProps } from './StepWrapper';

const Role: React.FC<StepWrapperProps> = props => {
  const {
    initialValues,
    workAreas,
    loadingWorkAreas,
    onSubmit: onWorkRolesSubmit,
  } = useWorkRolesForm();

  const onSubmit: FormSubmit<FormValues> = async values => {
    await onWorkRolesSubmit(values);
    props.handleNextClick?.(props.activeStep);
  };

  return (
    <Form
      key={String(loadingWorkAreas)}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {formProps => {
        const { workArea, specialization, additionalRole, role } =
          formProps.values;
        const allFieldsFilled =
          !!workArea && !!specialization && !!(additionalRole || role);

        return (
          <StepWrapper
            icon="SupervisorAccount"
            title="What is your role?"
            description={
              <span>
                Use the picklist to choose your organizational role. If you
                can’t find your role in the picklist, toggle the role entry
                field and manually enter your role. <br />
                <br />
                These selections can be updated in <b>Account Settings</b>.
              </span>
            }
            stepWrapperProps={{
              ...props,
              handleNextClick: undefined,
            }}
            isSubmitting={formProps.isSubmitting}
            isValid={formProps.isValid && allFieldsFilled}
            rightSideHeader="Your Organizational Role"
          >
            <RoleForm
              {...formProps}
              workAreas={workAreas}
              loadingWorkAreas={loadingWorkAreas}
            />
          </StepWrapper>
        );
      }}
    </Form>
  );
};
export default React.memo(Role);
