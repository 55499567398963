import { gql } from '@apollo/client';

export const RoleBase = {
  fragment: gql`
    fragment RoleBase on Role {
      id
      role_status
      role_name
      role_type
      role_description
      role_features
      role_insights_alerts {
        enabled
        all
        all_future
        alerts {
          enabled
          alert_id
        }
      }
      role_users_list
      role_dashboards {
        enabled
        all
        all_future
        dashboards {
          enabled
          report_id
        }
      }
      role_universal_filters {
        enabled
        filters {
          countries {
            name
            filters {
              id
              filter_ancestors
              filter_value
            }
          }
          states {
            name
            filters {
              id
              filter_ancestors
              filter_value
            }
          }
          cities {
            name
            filters {
              id
              filter_ancestors
              filter_value
            }
          }
          properties {
            name
            filters {
              id
              filter_ancestors
              filter_value
            }
          }
          unit_of_measurement
          currency
        }
      }
      role_other_features_all
      role_other_features {
        feature_id
        enabled
        sub_features {
          sub_feature_id
          enabled
          full_access
        }
      }
      role_added_by
      role_added_at
      role_updated_by
      role_updated_at
    }
  `,
};
