import {
  isNonSpecialReport,
  isPATReport,
  CustomReportStatus,
} from '@apollo-red/hooks/reports';
import { filterItems } from '@utils/various';
import { useGetTenant } from '../tenants';

interface GetAllTenantReports {
  tenantId: string;
  showHidden?: boolean;
  showPat?: boolean;
  showDisabled?: boolean;
}

export const useGetAllTenantReports = ({
  tenantId,
  showHidden,
  showPat,
  showDisabled,
}: GetAllTenantReports) => {
  const { loading, tenant } = useGetTenant(tenantId, showHidden, showDisabled);

  const reports = filterItems(
    tenant?.tenant_reports
      .filter(report => isNonSpecialReport(report))
      .filter(report => showPat || !isPATReport(report, true))
      .map(report => report) ?? [],
  );

  const externalReports = filterItems(tenant?.tenant_external_reports ?? []);

  const customReports = filterItems(
    tenant?.tenant_custom_reports_settings?.tenant_custom_reports ?? [],
  );

  const publishedCustomReports = customReports.filter(
    r => r.custom_report_status === CustomReportStatus.Published,
  );

  return {
    loading,
    allReports: [...reports, ...externalReports, ...publishedCustomReports],
    reports,
    externalReports,
    publishedCustomReports,
    tenantCustomReportsSettings: tenant?.tenant_custom_reports_settings,
  };
};
