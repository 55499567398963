import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@mui/material';
import { Icon, IconButton } from '@jll-labs/azara-ui-components';
import { ReportExtended } from '@apollo-red/hooks';
import ButtonLink from '@components/buttonLink';
import ExternalButtonLink from '@components/externalButtonLink';
import { FavoriteButton } from '@components/favoriteButton';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { useTileFooterStyles } from './styles';

interface TileFooterProps {
  categoryId: string;
  dashboardRoutes: DashboardRouteProps;
  onDeleteSavedView: () => void;
  onHideDashboard: () => void;
  onShowActionsMenu: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  report: ReportExtended;
  showVisibilityButton?: boolean;
  toggleFavorite: () => void;
}

const TileFooter: React.FC<TileFooterProps> = ({
  categoryId,
  dashboardRoutes,
  onDeleteSavedView,
  onHideDashboard,
  onShowActionsMenu,
  report,
  showVisibilityButton,
  toggleFavorite,
}) => {
  const styles = useTileFooterStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.actions}>
      <Grid container={true} justifyContent="space-between" alignItems="center">
        {report?.isExternalReport ? (
          <ExternalButtonLink
            report={report}
            categoryId={categoryId}
            dashboardRoutes={dashboardRoutes}
            label={t('fullDashboard')}
          />
        ) : (
          <ButtonLink
            to={dashboardRoutes.dashboard.getLink(categoryId, report.id)}
            buttonSx={styles.btnLink}
            label={t('fullDashboard')}
          />
        )}
        <div>
          <Grid container={true} alignItems="center">
            {report.isSavedView && (
              <IconButton
                title={t('delete') ?? ''}
                size="small"
                onClick={onDeleteSavedView}
                sx={styles.flipped}
                label="Delete"
              >
                <Icon name="Delete" />
              </IconButton>
            )}
            {report.isCustomReport && (
              <Tooltip title={t('customReport') ?? ''}>
                <Icon
                  name="Tune"
                  sx={[styles.actionIcon, styles.customReportIcon]}
                />
              </Tooltip>
            )}
            {showVisibilityButton && (
              <IconButton
                title={t('hide') ?? ''}
                size="small"
                onClick={onHideDashboard}
                sx={styles.flipped}
                label="Hide"
              >
                <Icon name="Visibility" />
              </IconButton>
            )}
            <FavoriteButton
              dataTestId={`toggle-fav-${report.id}`}
              report={report}
              toggleFavoriteReport={toggleFavorite}
            />
            {!report.isExternalReport && (
              <IconButton
                title={t('options') ?? ''}
                dataTestId={`toggle-actions-menu-${report.id}`}
                size="small"
                sx={[styles.actionIcon, styles.flipped]}
                onClick={onShowActionsMenu}
                label="Options"
              >
                <Icon name="Reply" />
              </IconButton>
            )}
          </Grid>
        </div>
      </Grid>
    </Box>
  );
};

export default TileFooter;
