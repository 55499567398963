import * as React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useToggleFavorite,
  useGetUserReportCategories,
} from '@apollo-red/hooks';
import { ReportName } from '@components/report';
import { AppTheme } from '@theme';
import StepWrapper, { StepWrapperProps } from './StepWrapper';
import FavoritesExpansionPanel from './FavoritesExpansionPanel';
import { FavoriteButton } from '@components/favoriteButton';

const useStyles = makeStyles((theme: AppTheme) => ({
  singleReportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
  },
  reportsContainer: {
    marginTop: theme.spacing(2),
  },
  reportControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    color: theme.palette.text.secondary,
  },
}));

enum FilteredCategories {
  all = 'all',
  favorites = 'favorites',
  saved = 'saved',
}

const FavoritesReport = ({ report }) => {
  const classes = useStyles();
  const { toggleFavorite } = useToggleFavorite();
  const toggleFavoriteReport = () => toggleFavorite(report);

  return (
    <div className={classes.singleReportContainer}>
      <span>{report.report_name}</span>
      <div className={classes.reportControl}>
        <Box mx={1}>
          <FavoriteButton
            report={report}
            toggleFavoriteReport={toggleFavoriteReport}
          />
        </Box>
        <ReportName iconOnly={true} report={report} />
      </div>
    </div>
  );
};

const Favorites: React.FC<StepWrapperProps> = props => {
  const classes = useStyles();
  const { categories } = useGetUserReportCategories();
  const filteredCategories = categories.filter(
    category => !(category.id in FilteredCategories),
  );

  const handleNextClick = () => {
    props.handleNextClick?.(props.activeStep);
  };

  return (
    <StepWrapper
      icon="Favorite"
      title="Select favorite dashboards"
      description={
        <span>
          Based on your profile, we have identified the reports that seem to be
          more relevant for you. To customize the experience, favorite or
          unfavorite dashboards according to their relevance.
          <br />
          <br /> These selections can be updated in <b>
            Dashboard Settings
          </b>{' '}
          page.
        </span>
      }
      stepWrapperProps={{ ...props, handleNextClick }}
      isValid={true}
      isSubmitting={false}
      rightSideHeader="Your Favorite Dashboards"
    >
      <div className={classes.reportsContainer}>
        {filteredCategories.map(category => (
          <FavoritesExpansionPanel
            key={category.id}
            title={category.category_description}
          >
            {category.category_reports.map(report => (
              <Box key={report.id} mx={2}>
                <FavoritesReport report={report} />
              </Box>
            ))}
          </FavoritesExpansionPanel>
        ))}
      </div>
    </StepWrapper>
  );
};

export default React.memo(Favorites);
