import * as React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  FormSubmit,
  FormDialog,
  Accordion,
} from '@jll-labs/azara-ui-components';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useUpdateUserTenantRegularSettings,
  useGetUserDefaultTenantId,
  useGetTenantAlertCategories,
  UserAlertRuleSettings,
  UserAlertRuleType,
} from '@apollo-red/hooks';
import { TenantAlertCategoriesInput } from '@apollo-red/schema/generated/operations';
import { SubHeader } from '@components/typography';
import { changesSavedMsg, useAppMessage } from '@components/messages';
import { FormValues, rulesOptions } from './AlertSettings.constants';
import AlertCategories from './alertCategories';
import AlertProperties from './AlertProperties';
import { getCategoriesExcluded } from './AlertSettings.utils';

const useStyles = makeStyles({
  dialogCustomizedWidth: {
    maxWidth: '50%',
    padding: '1em',
  },
  margin: {
    marginBottom: '1em',
  },
  dialogClose: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
});

const schema = Yup.object().shape({
  id: Yup.string().required(),
  internal: Yup.array().of(Yup.string()),
  external: Yup.array().of(Yup.string()),
  alert_enabled: Yup.boolean(),
});

interface RuleDialogProps {
  isOpen: boolean;
  currentUserRules: UserAlertRuleSettings;
  setOpen: (open: boolean) => void;
  editId?: UserAlertRuleType;
}

const initExpansionOpen = {
  categories: true,
  properties: false,
};

const RuleDialog: React.FC<RuleDialogProps> = ({
  isOpen,
  currentUserRules,
  setOpen,
  editId = UserAlertRuleType.WorkorderVolume,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setAppMessage } = useAppMessage();
  const tenantId = useGetUserDefaultTenantId();
  const [isExpansionOpen, setIsExpansionOpen] =
    React.useState(initExpansionOpen);
  const { updateUserTenantRegularSettings } =
    useUpdateUserTenantRegularSettings();
  const { tenantAlertCategories, loading } = useGetTenantAlertCategories(
    tenantId,
    editId,
  );
  const selectedRule = currentUserRules.find(
    rule => rule.alert_type === editId,
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpandOpen = (name: string) => (_, expanded: boolean) => {
    setIsExpansionOpen(current => ({
      ...current,
      [name]: expanded,
    }));
  };

  const categoriesExcluded =
    selectedRule?.categories_excluded &&
    (getCategoriesExcluded(
      tenantAlertCategories,
      selectedRule?.categories_excluded,
      editId,
    ) as TenantAlertCategoriesInput);

  const initialValues: FormValues = {
    id: selectedRule?.id ?? '',
    alert_type: editId,
    internal: categoriesExcluded
      ? [...categoriesExcluded.internal]
      : [...tenantAlertCategories.internal],
    external: categoriesExcluded?.external
      ? [...categoriesExcluded.external]
      : [...tenantAlertCategories.external],
    alert_enabled: selectedRule?.alert_enabled || true,
  };

  const onSubmit: FormSubmit<FormValues> = async values => {
    const categoriesExcluded = getCategoriesExcluded(
      tenantAlertCategories,
      values,
      values.alert_type,
    ) as TenantAlertCategoriesInput;

    const foundRule = currentUserRules.find(
      rule => rule.alert_type === values.alert_type,
    );

    if (foundRule) {
      const updatedRules = currentUserRules.map(rule => {
        if (rule.alert_type === foundRule.alert_type) {
          return {
            ...foundRule,
            alert_enabled: values.alert_enabled,
            categories_excluded: categoriesExcluded,
          };
        }
        return rule;
      });

      updateUserTenantRegularSettings(tenantId, {
        user_alert_rule_builder_settings: updatedRules,
      });
      setAppMessage(changesSavedMsg());
    } else {
      setAppMessage({
        type: 'systemError',
        description: t('pleaseContactSupportForAssistance'),
        text: t('alertIssue'),
      });
    }
  };

  return (
    <FormDialog
      onClose={handleClose}
      open={isOpen}
      title={'Edit Rule'}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {props => {
        const alertName = rulesOptions.find(
          opt => opt.value === props.values.alert_type,
        );
        return (
          <div className={classes.margin}>
            <SubHeader bold={true} size={16}>
              {`${t(alertName?.label ?? '')} Alert`}
            </SubHeader>
            <Box my={2}>
              <Accordion
                title={t('properties')}
                expanded={isExpansionOpen.properties}
                onExpand={handleExpandOpen('properties')}
                active={true}
              >
                <AlertProperties />
              </Accordion>
            </Box>
            <Box my={2}>
              <Accordion
                title={t('userAlertCategories')}
                expanded={isExpansionOpen.categories}
                onExpand={handleExpandOpen('categories')}
                active={true}
              >
                <AlertCategories
                  tenantAlertCategories={tenantAlertCategories}
                  loading={loading}
                  {...props}
                />
              </Accordion>
            </Box>
          </div>
        );
      }}
    </FormDialog>
  );
};

export default RuleDialog;
