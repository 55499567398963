import * as React from 'react';
import { useGetUserDefaultTenant, UserTenantExtended } from '@apollo-red/hooks';

type TenantFeatures = 'alerts' | 'demo' | 'portscape' | 'selfServeAnalytics';

type HasTenantFeature = (
  features: TenantFeatures[],
  tenant?: UserTenantExtended | null,
) => boolean;

export const hasTenantFeature: HasTenantFeature = (features, tenant) => {
  return tenant
    ? features.every(feature => tenant?.tenant_features?.[feature])
    : false;
};

interface Props {
  hasFeatures: TenantFeatures[];
}

const TenantFeatureToggle: React.FC<Props> = ({ hasFeatures, children }) => {
  const { loading, defaultTenant } = useGetUserDefaultTenant();
  const showComponent =
    !loading && hasTenantFeature(hasFeatures, defaultTenant);

  return showComponent ? <>{children}</> : null;
};

export default TenantFeatureToggle;
