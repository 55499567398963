import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button } from '@mui/material';
import { Icon, SelectOption } from '@jll-labs/azara-ui-components';
import { ReportExtended } from '@apollo-red/hooks';
import { useGetReportById } from '@apollo-red/hooks/reports';
import DocumentationLink from '@components/link/DocumentationLink';
import { TableauCacheContext } from '@components/tableau/TableauCacheContextProvider';
import { Viz } from '@components/tableau/types';
import { usePendoTrackDashboardEvent } from '@utils/pendo';
import { FullReportContext } from '../FullReportContextProvider';
import DownloadFileTypeOption from './DownloadFileTypeOption';
import ToolbarButtonGroup from './ToolbarButtonGroup';
import { ButtonGroup } from './types';
import { useToolbar } from './useToolbar';

interface Props {
  viz?: Viz;
  report: ReportExtended;
  enabledSettings?: {
    csv?: boolean;
    help?: boolean;
    save?: boolean;
    load?: boolean;
    pdf?: boolean;
    share?: boolean;
    favorites?: boolean;
    image?: boolean;
    revert?: boolean;
  };
}

const Toolbar: React.FC<Props> = ({
  viz,
  report,
  enabledSettings = {
    help: true,
    save: true,
    load: true,
    csv: true,
    image: false,
    pdf: true,
    share: true,
    favorites: true,
    revert: true,
  },
}) => {
  const cache = React.useContext(TableauCacheContext);
  const { dashboardTrackEventContext } = React.useContext(FullReportContext);
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();
  const { t } = useTranslation();
  const { report_name, report_original_name, report_parent } = report;
  const { report: parentReport } = useGetReportById(report_parent ?? '');
  const documentationTopic = report.isSavedView
    ? parentReport.report_original_name ?? parentReport.report_name
    : report_original_name ?? report_name;

  const handleReset = async () => {
    cache.reset(report?.id);
  };

  const { toolbarButtons } = useToolbar(
    report,
    dashboardTrackEventContext,
    viz,
    handleReset,
    false,
  );

  const handleHelpClick = () => {
    trackDashboardEvent('dashboardHelp', dashboardTrackEventContext);
  };

  const downloadButtonGroup = toolbarButtons.filter(
    tb => tb.buttonGroup === ButtonGroup.DOWNLOAD && enabledSettings[tb.name],
  );

  const otherButtonsGroup = toolbarButtons.filter(
    tb => tb.buttonGroup !== ButtonGroup.DOWNLOAD && enabledSettings[tb.name],
  );

  const options: SelectOption[] = downloadButtonGroup?.map(
    ({ name, onClick }) => {
      return {
        label:
          name === 'pdf' ? (
            <DownloadFileTypeOption label={t(name)} icon="PDF" />
          ) : (
            <DownloadFileTypeOption label={t(name)} icon="CSV" />
          ),
        value: name,
        onClick,
      };
    },
  );

  return (
    <Grid container={true} alignItems="center">
      {enabledSettings.help && (
        <Box
          component="span"
          mr={2}
          mb={2}
          display="inline-block"
          onClick={handleHelpClick}
        >
          <DocumentationLink topic={documentationTopic}>
            <Button
              variant="text"
              color="primary"
              disabled={!viz}
              data-testid="documentation"
            >
              <Icon name="HelpOutline" />
              <Box component="span" mx={2} my={0}>
                {t('help')}
              </Box>
            </Button>
          </DocumentationLink>
        </Box>
      )}
      {otherButtonsGroup.map(button => {
        if (enabledSettings[button.name]) {
          return (
            <Box
              component="span"
              key={button.name}
              mr={2}
              mb={2}
              display="inline-block"
            >
              <Button
                variant="text"
                color="primary"
                disabled={!viz}
                data-testid={button.name}
                onClick={button.onClick}
              >
                {button.icon}
                <Box component="span" mx={2} my={0}>
                  {t(button.name)}
                </Box>
              </Button>
              {button.component && button.component}
            </Box>
          );
        }
      })}
      <Box component="span" mr={2} mb={2} display="inline-block">
        <ToolbarButtonGroup
          icon="Download"
          id="download"
          name={t('download')}
          disabled={!viz}
          size="sm"
          iconButton={false}
          options={options}
        />
      </Box>
    </Grid>
  );
};

export default Toolbar;
