import { useMemo } from 'react';
import { getEncodedLink } from '@utils/router';
import { UseClientRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useClientSettingsRoutes = ({
  clientSettings: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      customReports: getCommonRoute(link, 'custom-reports'),
      keywords: getCommonRoute(link, 'keywords'),
      configDataSetup: getCommonRoute(link, 'config-data-setup'),
      users: getCommonRoute(link, 'users'),
      roleManagement: getCommonRoute(link, 'role-management'),
      dashboardSettings: getCommonRoute(link, 'dashboard-settings'),
      PATDashboards: getCommonRoute(link, 'legacy-dashboards'),
      PATDashboard: {
        path: `${link}/legacy-dashboards/:reportName`,
        getLink: (reportName: string) =>
          getEncodedLink([link, 'legacy-dashboards', reportName]),
      },
      PATDashboardSection: {
        path: `${link}/legacy-dashboards/:reportName/:sectionLabel`,
        getLink: (reportName: string, sectionLabel: string) =>
          getEncodedLink([link, 'legacy-dashboards', reportName, sectionLabel]),
      },
    };
  }, [getLink]);
