import * as React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { usePendoTrackEvent } from '@utils/pendo';

export const history = createBrowserHistory();

const pageNameFromPathname = (pathname = '') => {
  return pathname
    .split('/')
    .filter(el => !!el)
    .join('>');
};

const BrowserRouterWithPendo: React.FC = ({ children }) => {
  const [currentPathname, setCurrentPathname] = React.useState(
    window.location.pathname,
  );
  const [initTimestamp, setInitTimestamp] = React.useState(() =>
    new Date().getTime(),
  );
  const { trackEvent } = usePendoTrackEvent();

  React.useEffect(() => {
    return () => {
      const currentTimestamp = new Date().getTime();
      const duration = currentTimestamp - initTimestamp;

      if (duration < 1000) {
        return;
      }

      const pageName = pageNameFromPathname(currentPathname);
      const pageViewTime = Math.round(duration / 1000);

      trackEvent('pageView', {
        pageName,
        pageViewTime,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathname]);

  history.listen(location => {
    setInitTimestamp(() => new Date().getTime());
    setCurrentPathname(location.pathname);
  });

  return <BrowserRouter history={history}>{children}</BrowserRouter>;
};

export default BrowserRouterWithPendo;
