import { gql } from '@apollo/client';

export const GET_DOWNLOAD_TEMPLATE_SAS_URL = gql`
  mutation getDownloadTemplateSasUrl(
    $where: WhereDownloadTemplateSasUrlInput!
  ) {
    getDownloadTemplateSasUrl(where: $where)
  }
`;

export const RESET_CONTAINERS_ACCESS_SAS_URL = gql`
  mutation resetContainerAccessSasUrl(
    $where: WhereDataLoadsResetContainersInput!
  ) {
    resetContainerAccessSasUrl(where: $where) {
      type
      sasUrl
    }
  }
`;

export const UPLOAD_FILE_SAS_URL = gql`
  mutation uploadFileSasUrl($where: WhereDataLoadsFileUploadSasUrl!) {
    uploadFileSasUrl(where: $where) {
      sas_url
      folder_path
    }
  }
`;
