import { hasTenantFeature } from '@components/featureToggle';
import {
  InsightsAlertTab,
  InsightsAlertTypeFilter,
  InsightsAlertSeverityFilter,
  InsightsAlertImportanceFilter,
  useGetInsightsInboxImportanceFilter,
  useGetInsightsInboxAlertTypeFilter,
  useGetInsightsInboxAlertSortOrder,
  useGetInsightsInboxAlertSeverityFilter,
  useGetInsightsInboxAlertAssignedToFilter,
  InsightsAlertSortOrder,
} from '@apollo-red/hooks';
import { useGetUserInfo } from '../../user';

export const useGetInsightsInboxAlertState = (status?: InsightsAlertTab) => {
  const { user } = useGetUserInfo();
  const { sortOrders } = useGetInsightsInboxAlertSortOrder();
  const { alertImportanceFilter } = useGetInsightsInboxImportanceFilter();
  const { alertTypeFilter } = useGetInsightsInboxAlertTypeFilter();
  const { alertSeverityFilter } = useGetInsightsInboxAlertSeverityFilter();
  const { alertAssignedToFilter } = useGetInsightsInboxAlertAssignedToFilter();
  const defaultTenant = user.user_settings.default_tenant;
  const tenantId = defaultTenant?.id ?? '';
  const tenantOvcId = defaultTenant?.tenant_ovc_id ?? '';
  const sortBy = sortOrders.created_date ?? InsightsAlertSortOrder.Desc;
  const importanceFilter =
    alertImportanceFilter !== InsightsAlertImportanceFilter.All
      ? alertImportanceFilter
      : undefined;
  const typeFilter =
    alertTypeFilter !== InsightsAlertTypeFilter.All
      ? alertTypeFilter
      : undefined;
  const severityFilter =
    alertSeverityFilter !== InsightsAlertSeverityFilter.All
      ? alertSeverityFilter
      : undefined;
  const alertAssignedTo =
    alertAssignedToFilter !== 'all' ? alertAssignedToFilter : undefined;

  return {
    userId: user.id,
    userName: user.user_full_name,
    tenantId,
    tenantOvcId,
    hasFeatureEnabled: hasTenantFeature(['alerts'], defaultTenant),
    importance: importanceFilter,
    alertType: typeFilter,
    alertSeverity: severityFilter,
    alertAssignedTo: alertAssignedTo,
    status,
    sortBy,
  };
};
