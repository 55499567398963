import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import {
  IconButton,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@jll-labs/azara-ui-components';
import useLoadCustomViews, {
  CustomView,
} from '@components/fullReport/withFilters/hooks/useLoadCustomViews';
import {
  useDeleteSavedViewFromUser,
  useGetUserInfo,
  User,
  useGetUserSavedReports,
  ReportExtended,
} from '@apollo-red/hooks';
import { Viz } from '@components/tableau/types';

interface ButtonProps {
  view: CustomView;
  reportId: string;
  user: User;
  viz: Viz;
}

const IconButtonWithDelete: React.FC<ButtonProps> = ({
  view,
  viz,
  reportId,
  user,
}) => {
  const { deleteSavedViewFromUser } = useDeleteSavedViewFromUser();

  const deleteCustomView = (customViewName: string) => async () => {
    await viz.getWorkbook().removeCustomViewAsync(customViewName);
    await deleteSavedViewFromUser(reportId, user);
  };

  return (
    <IconButton
      onClick={deleteCustomView(view.viewName)}
      disabled={!reportId && !user.id}
      label="Delete"
      size="large"
    >
      <Icon name="Delete" />
    </IconButton>
  );
};

interface ReportToShow extends ReportExtended {
  customView: CustomView;
}

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  viz: Viz;
}

const LoadCustomViewDialog: React.FC<Props> = ({ isOpen, setOpen, viz }) => {
  const { user } = useGetUserInfo();
  const { savedReports } = useGetUserSavedReports();
  const [inProgress, setInProgress] = React.useState(false);
  const { loading, customViews } = useLoadCustomViews(viz);

  const handleClose = () => {
    setOpen(false);
    setInProgress(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleStartProcessing = () => {
    setInProgress(true);
  };

  const loadCustomView = (customViewName: string) => async () => {
    handleStartProcessing();

    await viz.getWorkbook().showCustomViewAsync(customViewName);

    handleClose();
  };

  const reportsToShow = savedReports
    .map(report => {
      const foundCustomView = customViews.find(
        customView => report.report_name === customView.viewName,
      );
      return { ...report, customView: foundCustomView };
    })
    .filter(joinedReport => !!joinedReport.customView) as ReportToShow[];

  return (
    <Dialog open={isOpen} handleClose={handleClose}>
      <DialogTitle handleClose={handleClose} title="Load Custom View" />
      <DialogContent>
        <div>{'To load new view, please select it from below list.'}</div>
        <List>
          {reportsToShow.length === 0
            ? 'No custom views saved yet.'
            : reportsToShow.map(report => (
                <ListItem
                  key={report.id}
                  onClick={loadCustomView(report.customView.viewName)}
                  button
                  dense
                >
                  <ListItemText primary={report.customView.viewName} />
                  <ListItemSecondaryAction>
                    <IconButtonWithDelete
                      viz={viz}
                      view={report.customView}
                      reportId={report.id}
                      user={user}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
        </List>
      </DialogContent>
      <DialogActions handleDismissClick={handleCancel} showDismiss={true} />
      {(loading || inProgress) && <LinearProgress />}
    </Dialog>
  );
};

export default LoadCustomViewDialog;
