import * as React from 'react';
import {
  MenuSelect,
  IconName,
  SelectOption,
  Button,
} from '@jll-labs/azara-ui-components';

interface Props {
  id: string;
  name: string;
  icon: IconName;
  size?: 'md' | 'sm';
  disabled?: boolean;
  iconButton: boolean;
  options: SelectOption[];
  tooltip?: string;
}

const ToolbarButtonGroup: React.FC<Props> = ({
  id,
  name,
  icon,
  size = 'md',
  disabled,
  options,
}) => {
  if (options.length < 1) {
    return null;
  }

  const handleChange = (opt: SelectOption) => {
    if (opt.onClick) {
      opt.onClick('');
    }
  };

  return (
    <MenuSelect
      closeOnSelect={true}
      getButton={({ handleBtnClick }) => (
        <Button
          onClick={handleBtnClick}
          color="primary"
          icon={icon}
          variant="text"
          disabled={disabled}
          data-testid={id}
        >
          {name}
        </Button>
      )}
      value={null}
      onChange={handleChange}
      options={options}
      size={size}
      disableSelectorOptions={disabled}
    />
  );
};

export default ToolbarButtonGroup;
