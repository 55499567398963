import {
  InsightsAlertOrderBy,
  InsightsAlertSortOrder,
} from '@apollo-red/schema/generated/operations';

export const sortOrderToAlertFeedSort: {
  [key in InsightsAlertSortOrder]: InsightsAlertOrderBy;
} = {
  [InsightsAlertSortOrder.Asc]: InsightsAlertOrderBy.Asc,
  [InsightsAlertSortOrder.Desc]: InsightsAlertOrderBy.Desc,
};
