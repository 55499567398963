import * as React from 'react';
import {
  ReportExtended,
  useReportMetadataSections,
  useReportMetadataInteractionConfiguration,
  useReportMetadata,
} from '@apollo-red/hooks';
import {
  getInitialPinnedParameters,
  getPinnedParameters,
  getPinnedFilters,
  getInitialPinnedFilters,
  calculatePinnedValuesLength,
  getFilterControls,
} from './utils';
import { PINNED_QUERY_VALUES_MAX_LENGTH } from './constants';
import FullReportContextProvider from '../FullReportContextProvider';
import FullReportWithFloatingPanel from './FullReportWithFloatingPanel';
import { useFullReportWithFilters } from './FullReportWithFiltersProvider';

interface Props {
  report: ReportExtended;
}

const PATFullReport: React.FC<Props> = ({ children, report }) => {
  const reportMetadata = useReportMetadata(report);
  const interactionConfig = useReportMetadataInteractionConfiguration(report);
  const sections = useReportMetadataSections(report);
  const {
    toggleFiltersPanel,
    handleHasInteractionConfig,
    pinnedData,
    isCoreDashboard,
    id,
    addInitialPinnedValues,
    addAppliedControls,
  } = useFullReportWithFilters();
  const hasConfigurableFilters =
    reportMetadata.enabled && interactionConfig.enabled && sections.length > 0;

  React.useEffect(() => {
    if (hasConfigurableFilters) {
      toggleFiltersPanel();
      handleHasInteractionConfig(hasConfigurableFilters);
    }
  }, [handleHasInteractionConfig, hasConfigurableFilters, toggleFiltersPanel]);

  React.useEffect(() => {
    const controls = getFilterControls(sections);
    const pinnedParameters = getPinnedParameters(
      pinnedData,
      isCoreDashboard,
      id,
    );
    const pinnedFilters = getPinnedFilters(pinnedData, isCoreDashboard, id);
    const initialPinnedParameters = getInitialPinnedParameters(
      pinnedParameters,
      controls,
    );
    const initialPinnedFilters = getInitialPinnedFilters(
      pinnedFilters,
      controls,
    );
    const pinnedValues = {
      ...initialPinnedParameters,
      ...initialPinnedFilters,
    };
    const initialPinnedValues =
      calculatePinnedValuesLength(pinnedValues) < PINNED_QUERY_VALUES_MAX_LENGTH
        ? pinnedValues
        : {};
    const initialPinnedControlNames = Object.entries(initialPinnedValues)?.map(
      ([key]) => key,
    );

    addInitialPinnedValues(initialPinnedValues);
    addAppliedControls(initialPinnedControlNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    isCoreDashboard,
    pinnedData,
    addInitialPinnedValues,
    addAppliedControls,
  ]);

  return (
    <FullReportContextProvider report={report}>
      <FullReportWithFloatingPanel sections={sections}>
        {children}
      </FullReportWithFloatingPanel>
    </FullReportContextProvider>
  );
};

export default PATFullReport;
