import * as React from 'react';
import { ReportFiltersInteractionSection } from '../types';
import { useFullReportWithFilters } from '../FullReportWithFiltersProvider';
import InteractionSections from './InteractionSections';

interface Props {
  sections: ReportFiltersInteractionSection[];
}

const DeferLoadInteractionSections: React.FC<Props> = ({ sections }) => {
  const { filtersLoaded } = useFullReportWithFilters();

  if (!filtersLoaded) {
    return null;
  }

  return <InteractionSections sections={sections} />;
};

export default DeferLoadInteractionSections;
