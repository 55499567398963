import {
  InsightsAlertInbox,
  useInsightsInboxFilters,
  useGetInsightsInboxRecentlySelectedAlert,
  useSetInsightsInboxRecentAlert,
  useSetInsightsInboxRemoveRecentAlert,
  useGetInsightsInboxImportanceFilter,
  useGetInsightsInboxAlertTypeFilter,
  useGetInsightsInboxAlertSeverityFilter,
  useGetInsightsInboxAlertAssignedToFilter,
  useSetInsightsInboxAlertAssignedToFilter,
  useSetInsightsInboxAlertSortOrders,
  useSetInsightsAlertUserImportance,
  useSetInsightsAlertSeverity,
  useSetInsightsAlertAssignedUser,
  useSetInsightsAlertStatus,
  useSetInsightsAlertRoiData,
  useAddInsightsAlertUserComment,
  useGetInsightsAlertThread,
} from '@apollo-red/hooks';
import { useSetInsightsAlertUserRead } from '../insightsAlert';
import { useCleanupInsightsInboxAlerts } from './useCleanupInsightsInboxAlerts';
import { useGetInboxInsightsAlerts } from './useGetInsightsInboxAlerts';

export const useInsightsAlertsInboxData = (inboxType: InsightsAlertInbox) => {
  const {
    alerts,
    hasNextPage,
    loadMore,
    loading,
    networkStatus,
    continuationToken,
  } = useGetInboxInsightsAlerts(inboxType);
  const { recentlySelectedAlert, loading: recentlySelectedAlertLoading } =
    useGetInsightsInboxRecentlySelectedAlert(inboxType);
  const { loading: alertLoading, alert: selectedAlert } =
    useGetInsightsAlertThread(recentlySelectedAlert?.thread_id);
  const selectedAlertThreadLoading =
    alertLoading || recentlySelectedAlertLoading;
  const { cleanupInboxAlerts, cleanupInboxAlertThread } =
    useCleanupInsightsInboxAlerts(inboxType, recentlySelectedAlert);
  const { alertImportanceFilter: selectedAlertImportanceFilter } =
    useGetInsightsInboxImportanceFilter();
  const { alertTypeFilter: selectedAlertTypeFilter } =
    useGetInsightsInboxAlertTypeFilter();
  const { alertSeverityFilter: selectedAlertSeverityFilter } =
    useGetInsightsInboxAlertSeverityFilter();
  const { alertAssignedToFilter: selectedAlertAssignedToFilter } =
    useGetInsightsInboxAlertAssignedToFilter();
  const { setInboxRemoveRecentAlert } =
    useSetInsightsInboxRemoveRecentAlert(inboxType);
  const { setInsightsInboxRecentAlert } = useSetInsightsInboxRecentAlert(
    inboxType,
    cleanupInboxAlertThread,
  );
  const { selectedSortOrders, setInboxAlertSortOrders } =
    useSetInsightsInboxAlertSortOrders(
      cleanupInboxAlerts,
      setInboxRemoveRecentAlert,
    );
  const { setInsightsInboxAlertUserAssignedTo } =
    useSetInsightsInboxAlertAssignedToFilter(
      cleanupInboxAlerts,
      setInboxRemoveRecentAlert,
      selectedAlert,
    );
  const { setInsightsAlertUserImportance } =
    useSetInsightsAlertUserImportance();
  const { setInsightsAlertSeverity } = useSetInsightsAlertSeverity();
  const { setInsightsAlertAssignedUser } =
    useSetInsightsAlertAssignedUser(selectedAlert);
  const { setInsightsAlertStatus } = useSetInsightsAlertStatus(
    setInboxRemoveRecentAlert,
  );
  const { setInsightsAlertUserRead } = useSetInsightsAlertUserRead();
  const { setInsightsAlertRoiData } = useSetInsightsAlertRoiData(selectedAlert);
  const { addInsightsAlertUserComment } = useAddInsightsAlertUserComment();
  const {
    handleImportanceFilterChange,
    handleSeverityFilterChange,
    handleTypeFilterChange,
  } = useInsightsInboxFilters(
    cleanupInboxAlerts,
    setInboxRemoveRecentAlert,
    selectedAlert,
  );

  return {
    alerts,
    hasNextPage,
    loadMore,
    continuationToken,
    loading,
    isLoadingMore: networkStatus === 1,
    selectedAlertThreadLoading,
    recentlySelectedAlert,
    selectedAlert,
    selectedAlertTypeFilter,
    selectedAlertImportanceFilter,
    selectedAlertSeverityFilter,
    selectedAlertAssignedToFilter,
    selectedSortOrders,
    handleAlertSelect: setInsightsInboxRecentAlert,
    handleSortByDate: setInboxAlertSortOrders,
    handleAssignedUserFilterChange: setInsightsInboxAlertUserAssignedTo,
    handleImportanceFilterChange,
    handleTypeFilterChange,
    handleSeverityFilterChange,
    handleImportanceChange: setInsightsAlertUserImportance,
    handleSeverityChange: setInsightsAlertSeverity,
    handleAssignUserChange: setInsightsAlertAssignedUser,
    handleStatusChange: setInsightsAlertStatus,
    handleAlertRead: setInsightsAlertUserRead,
    handleROIDataChange: setInsightsAlertRoiData,
    handleAddUserComment: addInsightsAlertUserComment,
    cleanupInboxAlerts,
  };
};
