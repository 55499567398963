import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_ALERT_USER_READ } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import { InsightsAlertSummaryCountsByStatus } from '../insightsAlertSummary';
import {
  InsightsAlert,
  InsightsAlertUserStatus,
} from '../insightsAlerts.types';

export const useSetInsightsAlertUserRead = () => {
  const { userId, tenantId } = useGetInsightsInboxAlertState();
  const [setInsightsAlertUserRead] = useAppMutation<
    SchemaTypes.SetInsightsAlertUserReadMutation,
    SchemaTypes.SetInsightsAlertUserReadMutationVariables
  >(SET_INSIGHTS_ALERT_USER_READ);

  return {
    setInsightsAlertUserRead: (alert: InsightsAlert) => {
      if (alert.user_status !== InsightsAlertUserStatus.Unread) {
        return;
      }

      setInsightsAlertUserRead({
        variables: {
          where: {
            tenant_id: tenantId,
            thread_id: alert.thread_id,
            user_id: userId,
          },
        },
        optimisticResponse: {
          setInsightsAlertUserRead: true,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlert',
              thread_id: alert.thread_id,
            }),
            fields: {
              user_status() {
                return 'read';
              },
            },
            optimistic: true,
          });

          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlertSummary',
              user_id: userId,
              client_id: tenantId,
            }),
            fields: {
              counts_by_status(existing: InsightsAlertSummaryCountsByStatus) {
                if (!existing) {
                  return;
                }
                return { ...existing, unread: existing.unread - 1 };
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
