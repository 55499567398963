import { useGetUserInfo } from '../../user';

export const useGetUserDefaultTenant = () => {
  const { loading, user } = useGetUserInfo();

  return {
    loading,
    defaultTenant: user.user_settings.default_tenant,
  };
};
