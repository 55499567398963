import { gql } from '@apollo/client';
import { CustomReport, ReportBase } from '@apollo-red/fragments';

export const ADD_TENANT_REPORT = gql`
  mutation addTenantReport(
    $where: TenantWhereInput!
    $data: CreateReportInput!
  ) {
    addTenantReport(where: $where, data: $data) {
      id
      tenant_external_reports {
        ...ReportBase
      }
    }
  }
  ${ReportBase.fragment}
`;

export const DELETE_TENANT_REPORT = gql`
  mutation deleteTenantReport($where: WhereDeleteReportInput!) {
    deleteTenantReport(where: $where) {
      id
      tenant_external_reports {
        ...ReportBase
      }
    }
  }
  ${ReportBase.fragment}
`;

export const UPDATE_REPORT = gql`
  mutation updateTenantReport(
    $data: UpdateReportInput!
    $where: WhereUpdateReportInput!
  ) {
    updateReport(data: $data, where: $where) {
      ...CustomReport
    }
  }
  ${CustomReport.fragment}
`;

export const PREVIEW_CUSTOM_REPORT = gql`
  mutation previewCustomReport($where: WhereMoveReportInput!) {
    previewCustomReport(where: $where) {
      ...CustomReport
    }
  }
  ${CustomReport.fragment}
`;

export const DEACTIVATE_CUSTOM_REPORT = gql`
  mutation deactivateCustomReport($where: WhereMoveReportInput!) {
    deactivateCustomReport(where: $where) {
      deactivated_reports {
        ...CustomReport
      }
      updated_report {
        ...CustomReport
      }
    }
  }
  ${CustomReport.fragment}
`;

export const PUBLISH_CUSTOM_REPORT = gql`
  mutation publishTenantCustomReport($where: WhereMoveReportInput!) {
    publishCustomReport(where: $where) {
      deactivated_reports {
        ...CustomReport
      }
      updated_report {
        ...CustomReport
      }
    }
  }
  ${CustomReport.fragment}
`;

export const VALIDATE_EXTERNAL_REPORT_EMBED_URL = gql`
  mutation validateExternalReportEmbedUrl(
    $where: WhereValidateExternalReportEmbedUrlInput!
  ) {
    validateExternalReportEmbedUrl(where: $where)
  }
`;
