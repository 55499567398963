import { gql } from '@apollo/client';

export const CountryBase = {
  fragment: gql`
    fragment CountryBase on Country {
      id
      country_iso_code
      country_desc
      country_continent {
        code
        name
      }
    }
  `,
};
