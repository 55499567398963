import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Dialog,
  DialogTitle,
  Button,
  Checkbox,
  Loader,
} from '@jll-labs/azara-ui-components';
import { TriangleBackground } from '@assets';
import { AppTheme } from '@theme';
import Content from './TermsAndConditionsContent';

const useStyles = makeStyles((theme: AppTheme) => ({
  topBgImage: {
    backgroundImage: `url(${TriangleBackground})`,
    backgroundSize: 'cover',
    height: '30vh',
  },
  logo: {
    width: '4em',
  },
  headerSection: {
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.action.selected,
  },
  header: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightLight,
  },
  content: {
    padding: theme.spacing(0, 5, 1, 5),
  },
  lastUpdated: {
    fontSize: theme.typography.subtitle2.fontSize,
    marginTop: theme.spacing(2),
  },
  actionButton: {
    padding: theme.spacing(2, 6),
  },
  checkboxLabel: {
    flex: 1,
    cursor: 'pointer',
  },
  termsAndConditions: {
    fontWeight: theme.typography.fontWeightBold,
  },
  logOut: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(5),
    textAlign: 'center',
  },
  contentWrapper: {
    overflow: 'scroll',
    maxHeight: '70vh',
    margin: theme.spacing(5, 0, 5, 0),
  },
}));

interface Props {
  handleConfirm: () => void;
  handleCancel: () => void;
  loggingOut: boolean;
}

const TermsAndConditions: React.FC<Props> = ({
  handleCancel,
  handleConfirm,
  loggingOut,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);

  const handleTermsAndConditionsChange = () => {
    setTermsAndConditions(termsAndConditions => !termsAndConditions);
  };

  return (
    <Grid container={true} direction="column" justifyContent="space-between">
      <div className={classes.topBgImage}></div>
      {loggingOut ? (
        <div>
          <div className={classes.logOut}>{t('loggingOut')}</div>
          <Loader />
        </div>
      ) : (
        <div>
          <Dialog size="medium" open={true} scroll="paper">
            <DialogTitle
              handleClose={handleCancel}
              title={
                <div>
                  <Icon
                    name="AzaraLogo"
                    fontSize="large"
                    className={classes.logo}
                  />
                </div>
              }
            />
            <div className={classes.headerSection}>
              <Grid
                container={true}
                alignItems="center"
                justifyContent="space-between"
              >
                <div className={classes.header}>
                  End User License Agreement (US)
                </div>
                <div className={classes.lastUpdated}>
                  Last updated: 06.07.2021{' '}
                </div>
              </Grid>
            </div>
            <div className={classes.contentWrapper}>
              <div className={classes.content}>
                <div>
                  <Content />
                </div>
                <Grid container>
                  <Grid item={true}>
                    <Checkbox
                      onChange={handleTermsAndConditionsChange}
                      checked={termsAndConditions}
                      label={`${t('termsAndConditionsDeclaration')} ${t(
                        'termsAndConditions',
                      )}`}
                    />
                  </Grid>
                  <Grid
                    className={classes.checkboxLabel}
                    container
                    alignItems="center"
                    onClick={handleTermsAndConditionsChange}
                  >
                    {t('termsAndConditionsDeclaration')}&nbsp;
                    <span className={classes.termsAndConditions}>
                      {t('termsAndConditions')}
                    </span>
                  </Grid>
                </Grid>
              </div>
              <DialogActions className={classes.actionButton}>
                <Button onClick={handleCancel} variant="text" size="small">
                  {t('cancel')}
                </Button>
                <Button
                  onClick={handleConfirm}
                  size="small"
                  disabled={!termsAndConditions}
                >
                  {t('submit')}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      )}
    </Grid>
  );
};

export default TermsAndConditions;
