export const transformReportUrl = (reportUrl?: string) => {
  if (!reportUrl) {
    return '';
  }

  // Fix Tableau Embed URLs that are not in the preferred format.
  return reportUrl.indexOf('/#/site/') === -1
    ? reportUrl
    : reportUrl.replace('/#/site/', '/t/');
};
