import * as React from 'react';
import { useHistory } from 'react-router';
import { TimeoutThumbnailError } from '@components/thumbnail';
import { useGetUserPATReportById, isPATReport } from '@apollo-red/hooks';
import { useRoutes } from '@routes';
import { TableauCacheContext } from './TableauCacheContextProvider';
import { Viz } from './types';

interface Props {
  viz?: Viz;
  id: string;
  height?: string;
  isExternalReport?: boolean;
  isSidebarExpanded?: boolean;
}

const TableauTimeout: React.FC<Props> = ({
  viz,
  id,
  children,
  isExternalReport,
  isSidebarExpanded,
}) => {
  const routes = useRoutes();
  const cache = React.useContext(TableauCacheContext);
  const [error, setError] = React.useState<boolean>(false);
  const { report } = useGetUserPATReportById(id);
  const history = useHistory();
  const dashboardTimeout =
    report && isPATReport(report)
      ? parseInt(process.env.PAT_DASHBOARD_TIMEOUT ?? '30000', 10)
      : parseInt(process.env.TABLEAU_DASHBOARD_TIMEOUT ?? '30000', 10);

  React.useEffect(() => {
    let timeout;

    const checkAvailability = () => {
      if (!viz && timeout) {
        setError(true);
        clearTimeout(timeout);
      } else if (!error) {
        timeout = setTimeout(checkAvailability, dashboardTimeout);
      }
    };

    if (viz) {
      setError(false);
      clearTimeout(timeout);
    } else if (!timeout) {
      checkAvailability();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [viz, error, dashboardTimeout]);

  React.useEffect(() => {
    if (error && isExternalReport) {
      const pathName =
        routes.dashboards.dashboard.getLink('external-reports', 'report', id) ??
        '';
      history.push({
        pathname: pathName,
        state: {
          isNonTableauReport: true,
        },
      });
    }
  }, [error, history, id, isExternalReport, routes.dashboards.dashboard]);

  if (error) {
    return (
      <TimeoutThumbnailError
        forFullDashboard
        handleRetry={() => {
          setError(false);
          id && cache.clearCacheById(id);
        }}
        isSidebarExpanded={isSidebarExpanded}
      />
    );
  }

  return <>{children}</>;
};

export default TableauTimeout;
