import * as React from 'react';
import { Grid, CircularProgress, Theme, useTheme, Box } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

const useStyles = (): Record<
  'root' | 'spinner' | 'info',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    root: {
      height: '100vh',
    },
    spinner: {
      margin: '2vw',
    },
    info: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

const LoaderMsg: React.FC<{ msg: string }> = ({ msg }) => {
  const styles = useStyles();
  return (
    <Grid
      sx={styles.root}
      container={true}
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Box sx={styles.info}>{msg}</Box>
      <Box sx={styles.spinner}>
        <CircularProgress color="primary" />
      </Box>
    </Grid>
  );
};

export default LoaderMsg;
