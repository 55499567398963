import * as React from 'react';
import {
  FormProps,
  FormAutocomplete,
  Switch,
  FormTextField,
} from '@jll-labs/azara-ui-components';
import { Box } from '@mui/material';
import { WorkArea } from '@apollo-red/hooks';
import { FormValues } from './useWorkRolesForm';

const workAreaChildren = {
  workArea: 'specialization',
  specialization: 'role',
};

const createOptions = (values?: { name: string }[]) => {
  if (!values) {
    return [];
  }
  return values.map(({ name }) => ({ value: name, label: name }));
};

interface Props {
  loadingWorkAreas: boolean;
  workAreas: WorkArea[];
  disabled?: boolean;
}

const RoleForm: React.FC<Props & FormProps<FormValues>> = ({
  values,
  isSubmitting,
  setFieldValue,
  loadingWorkAreas,
  workAreas,
  disabled,
}) => {
  const { workArea, specialization, additionalRole } = values;
  const [customRole, setCustomRole] = React.useState(!!additionalRole);

  const selectedWorkArea = workAreas.find(wA => wA.name === workArea?.value);

  const selectedSpecialization = selectedWorkArea?.specializations.find(
    spec => specialization && spec.name === specialization.value,
  );

  const clearChildren = (name: string) => {
    const child = workAreaChildren[name];
    if (child) {
      setFieldValue(child, undefined);
      clearChildren(child);
    }
  };

  const handleChangeSelect = (name: string, _value, reason) => {
    clearChildren(name);
    if (name === 'role' || reason === 'clear') {
      setCustomRole(false);
      setFieldValue('additionalRole', '');
    }
  };

  const toggleCustomRole = () => {
    setCustomRole(current => !current);
  };

  return (
    <div>
      <Box my={3}>
        <FormAutocomplete
          label="Choose Your Work Area"
          name="workArea"
          options={createOptions(workAreas)}
          loading={loadingWorkAreas}
          disabled={loadingWorkAreas || isSubmitting || disabled}
          onChange={handleChangeSelect}
          required={true}
        />
      </Box>
      <Box my={3}>
        <FormAutocomplete
          label="Choose Your Specialization"
          name="specialization"
          options={createOptions(selectedWorkArea?.specializations)}
          loading={loadingWorkAreas}
          disabled={!workArea || loadingWorkAreas || isSubmitting || disabled}
          onChange={handleChangeSelect}
          required={true}
        />
      </Box>
      <Box my={3}>
        <FormAutocomplete
          label="Choose Your Role"
          name="role"
          options={createOptions(selectedSpecialization?.roles)}
          loading={loadingWorkAreas}
          disabled={
            customRole ||
            !specialization ||
            loadingWorkAreas ||
            isSubmitting ||
            disabled
          }
          onChange={handleChangeSelect}
          required={true}
        />
      </Box>
      <Switch
        label="Role"
        checked={customRole}
        onChange={toggleCustomRole}
        disabled={
          !specialization || loadingWorkAreas || isSubmitting || disabled
        }
      />
      <label>{`I can't find my role on the list above`}</label>
      <Box my={3}>
        <FormTextField
          label={'Type Your Role'}
          name="additionalRole"
          disabled={!customRole || disabled}
        />
      </Box>
    </div>
  );
};

export default RoleForm;
