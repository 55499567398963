import { InsightsAlertSeverity } from '@apollo-red/schema/generated/operations';
import { InsightsAlertSeverityFilter } from '../insightsAlerts.types';

export const alertSeverityFilterToInsightsAlertSeverity: {
  [key in InsightsAlertSeverityFilter]: InsightsAlertSeverity | undefined;
} = {
  [InsightsAlertSeverityFilter.All]: undefined,
  [InsightsAlertSeverityFilter.Escalation]: InsightsAlertSeverity.Escalation,
  [InsightsAlertSeverityFilter.None]: InsightsAlertSeverity.None,
  [InsightsAlertSeverityFilter.Reminder]: InsightsAlertSeverity.Reminder,
  [InsightsAlertSeverityFilter.Urgent]: InsightsAlertSeverity.Urgent,
};
