import { externalAlertsList } from './AlertSettings.constants';
import { UserAlertRuleType, UserAlertRuleSettings } from '@apollo-red/hooks';

export const showExternalAlerts = (alertType: UserAlertRuleType) =>
  externalAlertsList.find(type => type === alertType);

export const excludeCategories = (
  allCat: string[],
  excludedCat: string[],
  getObject?: boolean,
) => {
  if (getObject) {
    return {
      selected: allCat.filter(c => !excludedCat.includes(c)),
      unselected: allCat.filter(c => excludedCat.includes(c)),
    };
  }
  return allCat.filter(c => !excludedCat.includes(c));
};

export const getCategoriesExcluded = (
  allCat: {
    internal: string[];
    external?: string[];
  },
  values: any,
  alertType: UserAlertRuleType,
  getObject?: boolean,
) => {
  const { internal, external } = values;

  if (showExternalAlerts(alertType) && allCat.external) {
    return {
      internal: excludeCategories(allCat.internal, internal || [], getObject),
      external: excludeCategories(allCat.external, external || [], getObject),
    };
  }

  return {
    internal: excludeCategories(allCat.internal, internal || [], getObject),
  };
};

export const findRule = (
  rules: UserAlertRuleSettings,
  selected?: UserAlertRuleType,
) => {
  return rules.find(rule => rule.alert_type === selected);
};

export const getTableObject = object => {
  if (object?.selected?.length) {
    return {
      name: object.selected[0],
      length: object.selected.length - 1,
      selected: !object.unselected?.length ? 'All' : object.selected.join(', '),
      unselected: object.unselected?.length ? object.unselected.join(', ') : '',
    };
  }

  return {};
};
