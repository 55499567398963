import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_TENANT_DATA_LOADS_JOBS } from '@apollo-red/queries';
import { useAppQuery } from '../../queries';

export type DataLoadsJobBase = SchemaTypes.DataLoadsJobsBaseFragment;

const getResults = (data?: SchemaTypes.GetTenantDataLoadsJobsQuery) => {
  return data?.getTenants?.[0];
};

export const useGetTenantDataLoadsJobs = (
  tenantId: string,
  filters: SchemaTypes.DataLoadsJobsFiltersInput,
) => {
  const { loading, data, fetchMore, refetch, client } = useAppQuery<
    SchemaTypes.GetTenantDataLoadsJobsQuery,
    SchemaTypes.GetTenantDataLoadsJobsQueryVariables
  >(GET_TENANT_DATA_LOADS_JOBS, {
    variables: {
      tenant_id: tenantId,
      filters,
    },
  });

  const { jobs, cursor } = getResults(data)?.tenant_data_loads_jobs ?? {};

  return {
    loading,
    refetch,
    fetchMore: () => {
      return fetchMore({
        variables: {
          cursor,
        },

        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more) return prev;
          const parsedPrev = getResults(prev);
          const parsedNext = getResults(more)?.tenant_data_loads_jobs;

          const { tenant_data_loads_jobs, ...rest } = parsedPrev ?? {};
          const { jobs: prevJobs } = tenant_data_loads_jobs ?? {};
          const { cursor: nextCursor, jobs: nextJobs } = parsedNext ?? {};

          return {
            getTenants: [
              {
                ...rest,
                tenant_data_loads_jobs: {
                  cursor: nextCursor,
                  jobs: [...(prevJobs ?? []), ...(nextJobs ?? [])],
                  __typename: 'DataLoadsJobs',
                },
              },
            ],
          };
        },
      });
    },
    hasMore: !!cursor,
    jobs: jobs ?? [],
    readCache: () => {
      try {
        const cache = client.readQuery<
          SchemaTypes.GetTenantDataLoadsJobsQuery,
          SchemaTypes.GetTenantDataLoadsJobsQueryVariables
        >({
          query: GET_TENANT_DATA_LOADS_JOBS,
          variables: {
            filters: filters,
            tenant_id: tenantId,
          },
        });
        return cache?.getTenants[0]?.tenant_data_loads_jobs ?? undefined;
      } catch (e) {
        return undefined;
      }
    },
    writeCache: (
      data: SchemaTypes.DataLoadsJobBaseFragment[],
      cursor?: string | null,
    ) => {
      try {
        client.writeQuery<
          SchemaTypes.GetTenantDataLoadsJobsQuery,
          SchemaTypes.GetTenantDataLoadsJobsQueryVariables
        >({
          query: GET_TENANT_DATA_LOADS_JOBS,
          variables: {
            filters: filters,
            tenant_id: tenantId,
          },
          data: {
            getTenants: [
              {
                id: tenantId,
                tenant_data_loads_jobs: {
                  cursor: cursor ?? null,
                  jobs: data,
                  __typename: 'DataLoadsJobs',
                },
                __typename: 'Tenant',
              },
            ],
          },
        });
      } catch (e) {
        //catch
      }
    },
  };
};
