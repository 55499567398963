import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Loader } from '@jll-labs/azara-ui-components';
import { Box, useTheme } from '@mui/material';

interface Props {
  url?: string;
  handleOpen: (val: boolean) => void;
}

const useStyles = () => {
  const theme = useTheme();
  return {
    content: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    loader: {
      marginTop: theme.spacing(3),
    },
  };
};

const OpenInNewTab: React.FC<Props> = ({ handleOpen, url }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (url) {
      window.open(url);
      handleOpen(false);
    }
  }, [handleOpen, url]);

  return (
    <Dialog open={true} size="small">
      <Box sx={styles.content}>
        <div>{t('externalReportLoadingMessage')}</div>
        <Loader spacing="mLg" sx={styles.loader} />
      </Box>
    </Dialog>
  );
};

export default OpenInNewTab;
