import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { REMOVE_INSIGHTS_ALERT_ASSIGNED_USER } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import {
  InsightsAlertUserHistory,
  InsightsAlertUserHistoryRecord,
  InsightsAlertUserHistoryActionPerformed,
} from '../insightsAlerts.types';

export const useRemoveInsightsAlertAssignedUser = () => {
  const { userId, tenantId, userName } = useGetInsightsInboxAlertState();
  const [removeInsightsAlertAssignedUser] = useAppMutation<
    SchemaTypes.RemoveInsightsAlertAssignedUserMutation,
    SchemaTypes.RemoveInsightsAlertAssignedUserMutationVariables
  >(REMOVE_INSIGHTS_ALERT_ASSIGNED_USER);

  return {
    removeInsightsAlertAssignedUser: (threadId: string) => {
      const timeStamp = `${new Date().getTime()}`;
      removeInsightsAlertAssignedUser({
        variables: {
          where: {
            tenant_id: tenantId,
            thread_id: threadId,
            user_id: userId,
            unassigned_at: timeStamp,
          },
        },
        optimisticResponse: {
          removeInsightsAlertAssignedUser: true,
          __typename: 'Mutation',
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlert',
              thread_id: threadId,
            }),
            fields: {
              assigned_user_id() {
                return '';
              },
            },
            optimistic: true,
          });

          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlertThread',
              thread_id: threadId,
            }),
            fields: {
              alert_user_history(existing: InsightsAlertUserHistory) {
                const userHistory: InsightsAlertUserHistoryRecord = {
                  commented_at: timeStamp,
                  action_performed:
                    InsightsAlertUserHistoryActionPerformed.UnAssignedUser,
                  user_name: userName,
                  comment: '',
                };

                return {
                  ...existing,
                  user_history: [userHistory, ...existing.user_history],
                };
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
