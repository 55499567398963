import * as React from 'react';
import { useLogError } from '@apollo-red/hooks';

interface ErrorLoggerProps {
  error?: Error;
}

export const ErrorLogger: React.FC<ErrorLoggerProps> = ({ error }) => {
  const { logError } = useLogError();

  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error, logError]);

  return null;
};
