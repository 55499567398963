import * as React from 'react';
import NoData from '@components/noData';
import { ParameterValueType } from '@components/tableau/types';
import { ParameterExtended } from '../../types';
import { sortParameters } from '../../utils';
import Parameter from './Parameter';

interface Props {
  parameters: ParameterExtended[];
  changeParameter: (name: string, value: ParameterValueType) => void;
  loading: boolean;
}

const Parameters: React.FC<Props> = ({
  parameters,
  changeParameter,
  loading,
}) => {
  const sortedParameters = sortParameters(parameters);

  if (!sortedParameters?.length && !loading) {
    return <NoData message="No Variables" noMargin />;
  }

  return (
    <>
      {sortedParameters?.map(parameter => {
        return (
          <Parameter
            key={parameter.id}
            parameter={parameter}
            loading={loading}
            changeParameter={changeParameter}
          />
        );
      })}
    </>
  );
};

export default Parameters;
