import * as React from 'react';
import {
  SimpleStorage,
  AccessToken,
  IDToken,
  RefreshToken,
} from '@okta/okta-auth-js';

interface OktaSimpleMemoryStorageWithMeta {
  meta: {
    version: number;
  };
  accessToken?: AccessToken;
  idToken?: IDToken;
  refreshToken?: RefreshToken;
  [key: string]: any;
}

type OktaSimpleLocalStorageWithMeta = Omit<
  OktaSimpleMemoryStorageWithMeta,
  'accessToken' | 'idToken'
>;

type OktaSimpleStorage = Omit<OktaSimpleMemoryStorageWithMeta, 'meta'>;

export const useOktaStorageProvider = () =>
  React.useMemo(() => {
    const memoryStorage: OktaSimpleMemoryStorageWithMeta = {
      meta: {
        version: 3,
      },
      accessToken: undefined,
      idToken: undefined,
    };

    const debugLog =
      process.env.NODE_ENV === 'development' &&
      process.env.OKTA_DEBUG === 'true'
        ? () => {
            // eslint-disable-next-line no-console
            console.groupCollapsed('useOktaStorageProvider');
            // eslint-disable-next-line no-console
            console.log(memoryStorage);
            // eslint-disable-next-line no-console
            console.groupEnd();
          }
        : undefined;

    const storageProvider: SimpleStorage = {
      getItem: (key: string) => {
        try {
          const value = window.localStorage.getItem(key);

          if (!value) {
            return;
          }

          const parsed: OktaSimpleLocalStorageWithMeta = JSON.parse(value);

          if (!parsed) {
            return;
          }

          const { meta: parsedMeta, refreshToken } = parsed;
          const { version: parsedVersion } = parsedMeta ?? {};
          const { meta, accessToken, idToken } = memoryStorage;

          if (parsedVersion !== meta.version || !refreshToken) {
            return;
          }

          const newValue = {
            accessToken,
            idToken,
            refreshToken,
          };

          return JSON.stringify(newValue);
        } catch (e) {
          return memoryStorage[key];
        }
      },
      setItem: (key: string, value: any) => {
        try {
          const parsed: OktaSimpleStorage = JSON.parse(value);
          const { meta } = memoryStorage;
          const { accessToken, idToken, refreshToken } = parsed;

          memoryStorage.accessToken = accessToken;
          memoryStorage.idToken = idToken;

          const newValue: OktaSimpleLocalStorageWithMeta = {
            meta,
            refreshToken,
          };

          window.localStorage.setItem(key, JSON.stringify(newValue));
        } catch (e) {
          memoryStorage[key] = value;
        } finally {
          debugLog?.();
        }
      },
      removeItem: (key: string) => {
        try {
          memoryStorage.accessToken = undefined;
          memoryStorage.idToken = undefined;

          window.localStorage.removeItem(key);
        } catch (e) {
          delete memoryStorage[key];
        } finally {
          debugLog?.();
        }
      },
    };

    return storageProvider;
  }, []);
