import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardProps } from '../types';
import { Category } from '@apollo-red/hooks';
import BaseDashboards from './BaseDashboards';

interface Props extends DashboardProps {
  categoryId: string;
  category: Category;
}

const BaseCategoryDashboards: React.FC<Props> = ({
  reports,
  categoryId,
  useSaveHandler,
  category,
  showKeyMetrics,
  drag,
  dashboardRoutes,
  isPATDashboard,
}) => {
  const { t } = useTranslation();

  return (
    <BaseDashboards
      isPATDashboard={isPATDashboard}
      reports={reports}
      title={category.category_description}
      breadcrumbsParts={[{ label: category.category_description }]}
      breadcrumbsTitle={t('dashboards')}
      dashboardRoutes={dashboardRoutes}
      showKeyMetrics={showKeyMetrics}
      drag={drag}
      autoOverflow={true}
      useSaveHandler={useSaveHandler}
      categoryId={categoryId}
    />
  );
};

export default BaseCategoryDashboards;
