import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';
import { PageContainerContext } from '@components/pageContainer';

interface Props {
  breadcrumbsParts: BreadcrumbsRoutePart[];
}

const SettingsPageWrapper: React.FC<Props> = ({
  breadcrumbsParts,
  children,
}) => {
  const { t } = useTranslation();
  const pageContainerContext = React.useContext(PageContainerContext);

  React.useEffect(() => {
    pageContainerContext.setBreadcrumbsData([
      t('myAccount'),
      [{ label: t('myAccount') }, ...breadcrumbsParts],
    ]);
  }, [breadcrumbsParts, pageContainerContext, t]);

  return <>{children}</>;
};

export default SettingsPageWrapper;
