import * as React from 'react';
import cx from 'classnames';
import { Box, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconName, Icon, SubmitButton } from '@jll-labs/azara-ui-components';
import { AppTheme } from '@theme';
import { TriangleBackground } from '@assets';
import { ValidateJumpStepper } from './OnBoarding';
import StepperContainer from './StepperContainer';
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    overflow: 'hidden',
    height: '100vh',
  },
  bottomBar: {
    height: '10vh',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  container: {
    minHeight: '90vh',
    display: 'flex',
    height: '100%',
    flexWrap: 'nowrap',
  },
  topBgImage: {
    backgroundImage: `url(${TriangleBackground})`,
    backgroundSize: 'cover',
    height: '30vh',
  },
  icon: {
    fontSize: '100px',
    margin: '25px',
    color: theme.palette.action.active,
  },
  iconBg: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    width: '150px',
    height: '150px',
    left: `calc(25% - 75px)`,
    top: `calc(30vh - 75px)`,
    borderRadius: '50%',
  },
  description: {
    padding: theme.spacing(15, 10),
  },
  descriptionText: {
    fontSize: '1.2rem',
  },
  profileData: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.secondary.dark,
  },
  formContent: {
    padding: theme.spacing(4, 6),
  },
  logoWrapper: {
    paddingLeft: theme.spacing(10),
  },
  btnsWrapper: {
    paddingRight: theme.spacing(10),
  },
  logo: {
    width: '6em',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  rightSideWrapper: {
    minWidth: '100%',
    flex: 1,
    overflowY: 'auto',
    marginBottom: '100px',
  },
}));

export interface StepWrapperProps {
  steps: number;
  activeStep: number;
  isLastPage: boolean;
  isFirstPage: boolean;
  handleBackClick: () => void;
  handleStepClick: (step: number) => () => void;
  handleNextClick?: (step: number) => void;
  completed: ValidateJumpStepper[];
  lastCompletedStep: number;
}

interface Props {
  title: string;
  description: string | React.ReactNode;
  icon: IconName;
  isSubmitting: boolean;
  isValid: boolean;
  stepWrapperProps: StepWrapperProps;
  rightSideHeader: string;
}

const StepWrapper: React.FC<Props> = ({
  title,
  description,
  icon,
  children,
  isSubmitting,
  isValid,
  stepWrapperProps,
  rightSideHeader,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={6}>
          <div className={classes.topBgImage} />
          <div className={classes.iconBg}>
            <Icon className={classes.icon} name={icon} />
          </div>
          <div className={classes.description}>
            <Typography variant="h4">{title}</Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.descriptionText}
              >
                {description}
              </Typography>
            </Box>
          </div>
        </Grid>
        <div className={classes.rightContainer}>
          <div className={cx(classes.profileData, classes.formContent)}>
            <Typography variant="h5">{rightSideHeader}</Typography>
          </div>
          <Grid item={true} xs={6} className={classes.rightSideWrapper}>
            <Scrollbars
              data-testid="onboarding-scrollbar"
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{
                height: '97%',
              }}
              renderView={props => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    overflowX: 'hidden',
                    height: '100%',
                  }}
                />
              )}
            >
              <div className={classes.formContent}>{children}</div>
            </Scrollbars>
          </Grid>
        </div>
      </Grid>

      <div className={classes.bottomBar}>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={3}>
            <div className={classes.logoWrapper}>
              <Icon name="AzaraLogo" className={classes.logo} />
            </div>
          </Grid>
          <Grid item={true} xs={6}>
            <StepperContainer
              handleStepClick={stepWrapperProps.handleStepClick}
              activeStep={stepWrapperProps.activeStep}
              completed={stepWrapperProps.completed}
              lastCompletedStep={stepWrapperProps.lastCompletedStep}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <div className={classes.btnsWrapper}>
              <Grid container={true} justifyContent="flex-end">
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={() => {
                    stepWrapperProps.handleNextClick?.(
                      stepWrapperProps.activeStep,
                    );
                  }}
                >
                  {stepWrapperProps.isLastPage ? 'Submit' : 'Next'}
                </SubmitButton>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StepWrapper;
