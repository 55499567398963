import * as React from 'react';
import { Grid, useTheme, Box, Theme } from '@mui/material';
import { IconButton, Icon } from '@jll-labs/azara-ui-components';
import { SystemStyleObject } from '@mui/system';

interface Props {
  name: string;
  pinned: boolean;
  handlePinUnpin: (pinned: boolean, name: string) => void;
  handleIncludeExclude?: (isFilterExcluded: boolean) => void;
  loading?: boolean;
  exclude?: boolean;
  filtersApplied?: boolean;
  title?: string;
}

interface StyleProps {
  filtersApplied?: boolean;
}

const useStyles = ({
  filtersApplied,
}: StyleProps): Record<string, SystemStyleObject<Theme>> => {
  const theme = useTheme();

  return {
    title: {
      fontWeight: theme.typography.fontWeightBold,
      wordWrap: 'break-word',
      padding: theme.spacing(1, 0),
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0, 2),
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    container: {
      flexWrap: 'nowrap',
      width: '20%',
    },
    childrenContainer: {
      width: '75%',
    },
    pinIconButton: {
      width: 'auto',
      color: filtersApplied
        ? theme.palette.action.active
        : theme.palette.text.disabled,
    },
    includeIconButton: {
      width: 'auto',
      color: filtersApplied
        ? theme.palette.text.secondary
        : theme.palette.text.disabled,
    },
    iconWrapper: {
      '&.MuiGrid-container': { width: 'auto' },
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  };
};

const OptionBox: React.FC<Props> = ({
  name,
  children,
  handlePinUnpin,
  pinned,
  handleIncludeExclude,
  exclude,
  loading,
  filtersApplied = false,
  title,
}) => {
  const [pin, togglePin] = React.useState(pinned);
  const [isFilterExcluded, toggleExclude] = React.useState(exclude || false);
  const tooltip = pin ? 'Pin' : 'Unpin';
  const excludeIncludeIconTitle = isFilterExcluded ? 'Exclude' : 'Include';
  const styles = useStyles({ filtersApplied });

  React.useEffect(() => {
    togglePin(pinned);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinned]);

  React.useEffect(() => {
    toggleExclude(exclude || false);
  }, [exclude]);

  const updatePin = () => {
    handlePinUnpin(!pin, name);
  };

  const updateIncludeExclude = () => {
    if (handleIncludeExclude) {
      handleIncludeExclude(!isFilterExcluded);
    }
  };

  return (
    <Box sx={styles.wrapper}>
      {title && <Box sx={styles.title}>{title}</Box>}
      <Box sx={styles.childrenContainer}>{children}</Box>
      <Grid
        container={true}
        alignItems="center"
        justifyContent="space-between"
        sx={styles.container}
      >
        <Grid
          sx={styles.iconWrapper}
          alignItems="center"
          justifyContent="flex-end"
          container={true}
        >
          <IconButton
            sx={styles.pinIconButton}
            label={tooltip}
            onClick={updatePin}
            title={filtersApplied && !loading ? tooltip : undefined}
            disabled={!filtersApplied || loading}
            size="small"
          >
            <Icon name="Pin" pinned={pinned} fontSize="small" />
          </IconButton>
          {handleIncludeExclude && (
            <IconButton
              sx={styles.includeIconButton}
              label={excludeIncludeIconTitle}
              onClick={updateIncludeExclude}
              title={
                filtersApplied && !loading ? excludeIncludeIconTitle : undefined
              }
              disabled={loading || !filtersApplied}
              size="small"
            >
              <Icon
                name="ExcludeInclude"
                excluded={isFilterExcluded}
                fontSize="small"
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OptionBox;
