import { usePendoTrackEvent } from '@utils/pendo';
import { PendoTrackEventType, PendoTrackEventMetadata } from './types';

export const usePendoTrackDashboardEvent = () => {
  const { trackEvent } = usePendoTrackEvent();
  const trackDashboardEvent = <
    T extends Extract<
      PendoTrackEventType,
      | 'dashboardLoad'
      | 'dashboardView'
      | 'dashboardSaveView'
      | 'dashboardLoadView'
      | 'dashboardFavorited'
      | 'dashboardUnfavorited'
      | 'dashboardShare'
      | 'dashboardRevert'
      | 'dashboardDownloadCSV'
      | 'dashboardDownloadPDF'
      | 'dashboardDownloadImage'
      | 'dashboardHelp'
    >,
  >(
    trackEventType: T,
    trackEventMetadata: PendoTrackEventMetadata<T>,
  ) => {
    if (trackEventMetadata?.dashboardName !== 'Login') {
      trackEvent(trackEventType, trackEventMetadata);
    }
  };

  return { trackDashboardEvent };
};
