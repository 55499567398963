import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Table,
  Icon,
  TableCellText,
  Switch,
} from '@jll-labs/azara-ui-components';
import {
  useToggleFavorite,
  useToggleHidden,
  useGetUserInfo,
  ReportExtended,
} from '@apollo-red/hooks';
import { FavoriteButton } from '@components/favoriteButton';
import { usePendoTrackDashboardHiddenEvent } from '@utils/pendo';
import ReportCategoryField from './ReportCategoryField';

const useStyles = makeStyles({
  savedIcon: {
    fontSize: '1rem',
    marginLeft: 5,
  },
  savedView: {
    display: 'flex',
    textAlign: 'center',
  },
});

interface Props {
  reports: ReportExtended[];
  disabled?: boolean;
}

const ListOfReports: React.FC<Props> = ({ reports, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useGetUserInfo();
  const { toggleFavorite } = useToggleFavorite();
  const { toggleHidden } = useToggleHidden();
  const { trackDashboardHiddenEvent } = usePendoTrackDashboardHiddenEvent();

  const rows = React.useMemo(() => {
    return reports.map(report => ({
      cells: [
        <div key="name" className={classes.savedView}>
          {report.report_name}
          {report.isSavedView && (
            <Tooltip title={t('savedView') ?? ''}>
              <Icon name="Save" className={classes.savedIcon} />
            </Tooltip>
          )}
        </div>,
        <ReportCategoryField
          key="cat"
          report={report}
          user={user}
          disabled={disabled}
        />,
        <TableCellText key={report.id}>
          <FavoriteButton
            report={report}
            toggleFavoriteReport={() => toggleFavorite(report)}
          />
        </TableCellText>,
        <TableCellText key={report.id}>
          <Switch
            disabled={disabled}
            onChange={() => {
              trackDashboardHiddenEvent(
                report.isHidden
                  ? 'dashboardUnhiddenUser'
                  : 'dashboardHiddenUser',
                {
                  dashboardName: report.report_name,
                },
              );
              toggleHidden(report);
            }}
            checked={!report.isHidden}
            label={report.isHidden ? 'Unhide' : 'Hide'}
          />
        </TableCellText>,
      ],
    }));
  }, [
    reports,
    user,
    disabled,
    toggleHidden,
    toggleFavorite,
    trackDashboardHiddenEvent,
    classes,
    t,
  ]);

  return (
    <Table
      header={{
        cells: [
          t('dashboard') + ' Name',
          t('category') + ' Name',
          t('favorite'),
          'Show ' + t('dashboard'),
        ],
      }}
      rows={rows}
    />
  );
};

export default ListOfReports;
