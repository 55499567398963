import * as React from 'react';
import { AreaUnit, CurrencyUnit } from '@apollo-red/hooks/units';
import { useFeatureFlag } from '@utils/launchDarkly';
import {
  HandleFirstInteractive,
  HandleFirstVizSizeKnown,
  HandleVizDispose,
  TableauDevice,
  Viz,
} from './types';

export interface InitFiltersProp {
  [key: string]: string[];
}

export interface InitPinnedParametersAndFilters {
  [key: string]: string;
}

export interface TableauViewBaseProps {
  url: string;
  height?: string;
  width?: string;
  device?: TableauDevice;
  handleResize?: (container: any) => void;
  handleInitError?: (e: Error) => void;
  handleLoadComplete?: HandleFirstInteractive;
  handleFirstInteractive?: HandleFirstInteractive;
  handleFirstVizSizeKnown?: HandleFirstVizSizeKnown;
  handleVizDispose?: HandleVizDispose;
  initialFilters?: InitFiltersProp;
  initialPinnedFiltersAndParameters?: InitPinnedParametersAndFilters;
  tableauContainerStyle?: React.CSSProperties;
  disableUrlActionsPopups?: boolean;
  reset?: boolean;
  isExternalReport?: boolean;
}

/**
 * Escapes commas in filter value.
 * Commas are also used to separate values in url to Tableau Server, so they need to be escaped in the values.
 *
 * @param filters list of filter values
 */
const escapeFilters = (filterValues?: string[]) => {
  if (Array.isArray(filterValues)) {
    return (filterValues || []).map(value => value.split(',').join('\\,'));
  }
};

/**
 * Parses filters and remove null or default filter values
 * before passing them to Tableau JS API.
 *
 * @param initFilters object containing lists of filter values
 */
export const parseAndStripFilters = (initFilters: InitFiltersProp) =>
  Object.entries(initFilters).reduce((acc, entry) => {
    const [filterKey, filters] = entry;
    const defaultValue = {
      ...acc,
    };

    if (filters.length === 0) {
      return defaultValue;
    }

    if (filterKey === 'p_area' && filters[0] === AreaUnit.Sqft) {
      return defaultValue;
    }

    if (filterKey === 'p_currency' && filters[0] === CurrencyUnit.Usd) {
      return defaultValue;
    }

    return {
      ...acc,
      [filterKey]: escapeFilters(filters),
    };
  }, {} as InitFiltersProp);

/**
 * Parses filters before passing them to Tableau JS API.
 *
 * @param initFilters object containing lists of filter values
 * @param stripFilters `true` if null or default filters should be removed
 */
export const parseFilters = (
  initFilters: InitFiltersProp,
  stripFilters?: boolean,
) => {
  if (stripFilters) {
    return parseAndStripFilters(initFilters);
  }

  return Object.keys(initFilters).reduce((acc, filterKey) => {
    return {
      ...acc,
      [filterKey]: escapeFilters(initFilters[filterKey]),
    };
  }, {} as InitFiltersProp);
};

export const createTableauViz = (
  htmlElement: HTMLElement,
  url: string,
  options,
): Viz => {
  const tableau = window.tableau;

  if (!url) {
    throw new Error('Invalid report url');
  }

  if (tableau) {
    return new tableau.Viz(htmlElement, url, options);
  }

  throw new Error(
    'Could not find `tableau` object on window. Have you included Tableau script?',
  );
};

export const TableauViewBase: React.FC<TableauViewBaseProps> = ({
  url,
  height,
  width,
  tableauContainerStyle,
  device,
  handleInitError,
  handleFirstInteractive,
  handleFirstVizSizeKnown,
  handleVizDispose,
  initialFilters,
  initialPinnedFiltersAndParameters,
  disableUrlActionsPopups = false,
}) => {
  const defaultOptions = {
    hideTabs: true,
    hideToolbar: true,
  };
  const tableauDiv = React.createRef<HTMLDivElement>();
  const [viz, setViz] = React.useState<Viz>();
  const stripInitialFilters = useFeatureFlag(
    'dashboardsFiltersStripInitialFilters',
  );

  const onFirstInteractive = evt => {
    if (handleFirstInteractive) {
      handleFirstInteractive(evt.getViz());
    }
  };

  const onFirstVizSizeKnown = evt => {
    if (handleFirstVizSizeKnown) {
      handleFirstVizSizeKnown(evt.getViz());
    }
  };

  React.useEffect(() => {
    try {
      if (tableauDiv.current) {
        const options = {
          ...defaultOptions,
          device,
          width,
          height,
          onFirstInteractive,
          onFirstVizSizeKnown,
          disableUrlActionsPopups,
          ...(initialFilters
            ? parseFilters(initialFilters, stripInitialFilters)
            : {}),
          ...(initialPinnedFiltersAndParameters || {}),
        };

        const _viz = createTableauViz(tableauDiv.current, url, options);
        setViz(_viz);
      }
    } catch (e) {
      if (handleInitError) {
        handleInitError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableauDiv.current]);

  React.useEffect(() => {
    return () => {
      if (viz) {
        viz.dispose();
        if (handleVizDispose) {
          handleVizDispose(viz);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viz]);

  return <div style={tableauContainerStyle} ref={tableauDiv} />;
};

export default TableauViewBase;
