import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  REACTIVATE_TENANT_USER,
  RESET_TENANT_USER_PASSWORD,
  UNLOCK_TENANT_USER,
  UNSUSPEND_TENANT_USER,
} from '@apollo-red/mutations';
import { useAppMutation } from '../queries';

export const useReactivateTenantUser = () => {
  const [reactivateTenantUser, { loading }] = useAppMutation<
    SchemaTypes.ReactivateTenantUserMutation,
    SchemaTypes.ReactivateTenantUserMutationVariables
  >(REACTIVATE_TENANT_USER);

  return {
    reactivateTenantUser: (tenantId: string, userId: string) => {
      return reactivateTenantUser({
        variables: {
          userId,
          tenant_id: tenantId,
        },
      });
    },
    loading,
  };
};

export const useResetTenantUserPassword = () => {
  const [resetTenantUserPassword, { loading }] = useAppMutation<
    SchemaTypes.ResetTenantUserPasswordMutation,
    SchemaTypes.ResetTenantUserPasswordMutationVariables
  >(RESET_TENANT_USER_PASSWORD);

  return {
    resetTenantUserPassword: (tenantId: string, userId: string) => {
      return resetTenantUserPassword({
        variables: {
          userId,
          tenant_id: tenantId,
        },
      });
    },
    loading,
  };
};

export const useUnlockTenantUser = () => {
  const [unlockTenantUser, { loading }] = useAppMutation<
    SchemaTypes.UnlockTenantUserMutation,
    SchemaTypes.UnlockTenantUserMutationVariables
  >(UNLOCK_TENANT_USER);

  return {
    unlockTenantUser: (tenantId: string, userId: string) => {
      return unlockTenantUser({
        variables: {
          userId,
          tenant_id: tenantId,
        },
      });
    },
    loading,
  };
};

export const useUnsuspendTenantUser = () => {
  const [unsuspendTenantUser, { loading }] = useAppMutation<
    SchemaTypes.UnsuspendTenantUserMutation,
    SchemaTypes.UnsuspendTenantUserMutationVariables
  >(UNSUSPEND_TENANT_USER);

  return {
    unsuspendTenantUser: (tenantId: string, userId: string) => {
      return unsuspendTenantUser({
        variables: {
          userId,
          tenant_id: tenantId,
        },
      });
    },
    loading,
  };
};
