import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@jll-labs/azara-ui-components';
import {
  useAddUserCategoryReport,
  ReportExtended,
  User,
} from '@apollo-red/hooks';
import { useAppMessage } from '@components/messages';

interface Props {
  report: ReportExtended;
  user: User;
  disabled?: boolean;
}

const ReportCategoryField: React.FC<Props> = ({ report, user, disabled }) => {
  const { addUserCategoryReport } = useAddUserCategoryReport();
  const { t } = useTranslation();
  const [category, setCategory] = React.useState(report.primaryCategory);
  const { setAppMessage } = useAppMessage();

  const handleFieldBlur = () => {
    const { id, primaryCategory } = report;

    if (!category) {
      setAppMessage({
        type: 'success',
        text: t('emptyCategoryNameWarningText'),
        successType: 'warning',
      });
      setCategory(primaryCategory);

      return;
    }

    const trimmedCategory = category.trim();

    if (trimmedCategory !== primaryCategory) {
      setCategory(trimmedCategory);
      addUserCategoryReport(id, trimmedCategory, user);
    }
  };

  const handleChange = evt => {
    setCategory(evt.currentTarget.value);
  };

  return (
    <TextField
      value={category}
      onBlur={handleFieldBlur}
      onChange={handleChange}
      editIcon={!disabled}
      required={true}
      placeholder={t('category')}
      hideBorders={true}
      disableLeftPadding={true}
      disabled={disabled}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.report.id === nextProps.report.id &&
  prevProps.report.primaryCategory === nextProps.report.primaryCategory;

export default React.memo(ReportCategoryField, areEqual);
