import * as React from 'react';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';
import { HelpPortalLinkType } from '@components/link';

export const useBreadcrumbHelpPortalLinkInfo = (breadcrumbs?: any[]) => {
  return React.useMemo(() => {
    const pageTitle: string = breadcrumbs?.[0] ?? '';
    const routeParts: BreadcrumbsRoutePart[] = breadcrumbs?.[1] ?? [];
    const firstRoutePartTitle = routeParts[0]?.label ?? '';

    let topic = '';
    let type: HelpPortalLinkType = HelpPortalLinkType.Home;

    if (firstRoutePartTitle) {
      topic = firstRoutePartTitle;

      switch (topic) {
        case 'Dashboards':
        case 'Core Dashboards':
          type = HelpPortalLinkType.Dashboard;
          break;
        case 'Alerts':
          type = HelpPortalLinkType.Alert;
          break;
        case 'My Account':
          type = HelpPortalLinkType.MyAccount;
          break;
        case 'Client Settings':
          type = HelpPortalLinkType.ClientSettings;
          break;
        case 'Data Management':
          type = HelpPortalLinkType.DataManagement;
          break;
      }

      if (routeParts.length > 1) {
        topic = routeParts[routeParts.length - 1]?.label ?? topic;
      }

      if (
        type === HelpPortalLinkType.ClientSettings ||
        type === HelpPortalLinkType.DataManagement
      ) {
        if (routeParts.length > 2) {
          topic = routeParts[1]?.label ?? topic;
        }
      }
    }

    return {
      pageTitle,
      routeParts,
      topic,
      type,
    };
  }, [breadcrumbs]);
};
