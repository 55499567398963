import { useTheme } from '@mui/material';

export const useAlertTableRowStyles = () => {
  const theme = useTheme();

  return {
    root: {
      height: '100%',
    },
    waypointTableRow: {
      height: '0px',
    },
    waypointTableCell: {
      padding: '0 !important',
      height: '0px',
      borderBottom: 'none',
    },
    smallTableCell: {
      whiteSpace: 'nowrap',
    },
    mediumTableCell: {
      whiteSpace: 'nowrap',
    },
    noWrapCell: {
      whiteSpace: 'nowrap',
      minWidth: '200px',
    },
    clickable: {
      cursor: 'pointer',
    },
    dateContainer: {
      fontSize: '0.75rem',
      textAlign: 'right',
      paddingTop: '0em',
    },
    iconContainer: {
      top: '0.25em',
      position: 'relative',
      minWidth: theme.spacing(8),
    },
    readContainer: {
      paddingTop: '0em',
    },
    unReadContainer: {
      paddingTop: '0em',
      fontWeight: theme.typography.fontWeightBold,
      '& b': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    inactiveContainer: {
      verticalAlign: 'top',
    },
    activeContainer: {
      verticalAlign: 'top',
      backgroundColor: theme.palette.secondary.light,
    },
    noDataTableCell: {
      whiteSpace: 'nowrap',
      borderBottom: 'none !important',
    },
    progressTableCell: {
      whiteSpace: 'nowrap',
      borderBottom: 'none',
    },
    circularProgress: {
      marginTop: '4px',
      transition: theme.transitions.create('stroke-dashoffset', {
        duration: 0,
      }),
    },
    linearProgress: {
      margin: '0px',
      padding: '0px',
    },
  };
};
