import * as React from 'react';
import ErrorScreen, { MainIcon } from './ErrorScreen';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      statusMsg={t('notFoundStatusMsg')}
      secondaryMsg={t('notFoundSecondaryMsg')}
      detailsMsg=""
      icon={MainIcon.Warning}
    />
  );
};

export default NotFound;
