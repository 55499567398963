import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from '@jll-labs/azara-ui-components';
import { InsightsAlertStatus, InsightsAlertType } from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import { AlertBadge } from '../alertBodyComponents';
import { alertTypeToTitleTranslation } from '../utils';
import { useTranslation } from 'react-i18next';

interface Props {
  alertCount: number;
  alertType: InsightsAlertType;
  alertStatus: InsightsAlertStatus;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  emptyBadgeCompletedIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  completedIcon: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(-1),
  },
}));

const AlertTitleCell: React.FC<Props> = ({
  alertCount,
  alertStatus,
  alertType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <AlertBadge count={alertCount}>
        <div>{t(alertTypeToTitleTranslation[alertType])}</div>
      </AlertBadge>
      {alertStatus === InsightsAlertStatus.Closed && (
        <Icon
          name="Completed"
          fontSize="small"
          className={
            alertCount > 1
              ? classes.completedIcon
              : classes.emptyBadgeCompletedIcon
          }
        />
      )}
    </div>
  );
};

export default AlertTitleCell;
