import * as React from 'react';
import {
  useReplaceFavorites,
  Report,
  useGetUserFavReports,
} from '@apollo-red/hooks';
import { BaseFavoriteDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';

const useSaveHandler = (reports: Report[]) => {
  const { replaceFavReports } = useReplaceFavorites();
  return {
    saveHandler: () => replaceFavReports(reports.map(({ id }) => id)),
  };
};

const FavoriteDashboards: React.FC = () => {
  const routes = useRoutes();
  const { favReports } = useGetUserFavReports();

  return (
    <BaseFavoriteDashboards
      reports={favReports}
      useSaveHandler={useSaveHandler}
      showAddReports={true}
      showKeyMetrics={true}
      drag={true}
      dashboardRoutes={routes.dashboards}
    />
  );
};

export default FavoriteDashboards;
