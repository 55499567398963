import { gql } from '@apollo/client';
import { ApiVersionBase } from '@apollo-red/fragments';

export const GET_API_VERSION = gql`
  query getApiVersion {
    getApiVersion {
      ...ApiVersionBase
    }
  }
  ${ApiVersionBase.fragment}
`;
