import { InsightsAlertTab } from '@apollo-red/schema/generated/operations';
import { InsightsAlertInbox } from '../insightsAlerts.types';

export const alertInboxToInsightsAlertTab: {
  [key in InsightsAlertInbox]: InsightsAlertTab | undefined;
} = {
  [InsightsAlertInbox.Unread]: InsightsAlertTab.Unread,
  [InsightsAlertInbox.Read]: InsightsAlertTab.Read,
  [InsightsAlertInbox.SavedForLater]: InsightsAlertTab.SaveForLater,
  [InsightsAlertInbox.All]: undefined,
  [InsightsAlertInbox.DataQualityReview]: InsightsAlertTab.DataQualityReview,
  [InsightsAlertInbox.Closed]: InsightsAlertTab.Closed,
  [InsightsAlertInbox.AssignedToMe]: InsightsAlertTab.AssignedToMe,
};
