import { parseFilter } from '@utils/tableau';
import {
  ConcreteFilter,
  DataValue,
  FlattenedFilter,
} from '@components/tableau/types';
import { UserUniversalFilters, UserUniversalFilter } from '@apollo-red/hooks';
import { ReportControlWithoutTypename } from '../types';

const COUNTRY_FILTER_ID = '9';
const STATE_FILTER_ID = '2';
const CITY_FILTER_ID = '3';
const PROPERTY_FILTER_ID = '7';

type UniversalFiltersMapping = Record<
  string,
  UserUniversalFilter[] | undefined
>;

type FlattenedFilterWithStaticData = Omit<FlattenedFilter, 'staticData'> & {
  staticData: DataValue[];
};

const getFilterName = (
  reportControls: ReportControlWithoutTypename[],
  filterId: string,
) => reportControls.find(({ filter_id }) => filterId === filter_id)?.name;

const createUniversalFiltersMapping = (
  universalFilters: UserUniversalFilters,
  reportControls: ReportControlWithoutTypename[],
): UniversalFiltersMapping => {
  const countryFilterName = getFilterName(reportControls, COUNTRY_FILTER_ID);
  const stateFilterName = getFilterName(reportControls, STATE_FILTER_ID);
  const cityFilterName = getFilterName(reportControls, CITY_FILTER_ID);
  const propertyFilterName = getFilterName(reportControls, PROPERTY_FILTER_ID);

  return {
    ...(countryFilterName && {
      [countryFilterName]: universalFilters.countries?.filters,
    }),
    ...(stateFilterName && {
      [stateFilterName]: universalFilters.states?.filters,
    }),
    ...(cityFilterName && {
      [cityFilterName]: universalFilters.cities?.filters,
    }),
    ...(propertyFilterName && {
      [propertyFilterName]: universalFilters.properties?.filters,
    }),
  };
};

const parseStaticFilter = (
  parsedFilter: FlattenedFilterWithStaticData,
  universalFilters?: UserUniversalFilter[],
) => {
  if (!universalFilters || universalFilters.length === 0) {
    return parsedFilter;
  }

  const parsedUniversalFilters: DataValue[] = universalFilters
    .map(({ id, filter_value }) => ({
      value: id,
      formattedValue: filter_value,
      isPreselectedUniversalFilterValue: true,
    }))
    .filter(universalFilter =>
      parsedFilter.appliedValues?.some(
        psf => psf.formattedValue === universalFilter.formattedValue,
      ),
    );

  return {
    ...parsedFilter,
    isUniversalFilter: true,
    staticData: parsedFilter.staticData.concat(parsedUniversalFilters),
  } as FlattenedFilterWithStaticData;
};

export const parseAndFlattenFilters = (
  filters: ConcreteFilter[],
  universalFilters: UserUniversalFilters,
  reportControls: ReportControlWithoutTypename[],
): FlattenedFilter[] => {
  return filters.map(filter => {
    const universalFiltersMapping = createUniversalFiltersMapping(
      universalFilters,
      reportControls,
    );
    const parsedFilter = parseFilter(filter);
    parsedFilter.staticData = [];

    return parseStaticFilter(
      parsedFilter as FlattenedFilterWithStaticData,
      universalFiltersMapping[parsedFilter.name],
    );
  });
};
