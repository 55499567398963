import * as React from 'react';
import {
  fallbackUserData,
  useGetUserInfoQuery,
  UserInfoContext,
  useSwitchUser,
} from '@apollo-red/hooks';
import { useAuth } from '@components/okta';
import { useParamsCustom } from '@utils/router';
import LoaderMsg from './LoaderMsg';
import ProvisionedUserRedirects from './ProvisionedUserRedirects';
import { useProvisionUser } from './useProvisionUser';

const UserContextProvider: React.FC = ({ children }) => {
  const { userEmail } = useAuth();
  const { tenantOvcId } = useParamsCustom();
  const { loading, user } = useGetUserInfoQuery({
    email: userEmail,
  });
  const {
    switchUser,
    user: nextUser,
    loading: nextUserLoading,
  } = useSwitchUser();
  const isProvisioningUser = useProvisionUser({
    userId: user?.id,
    tenantOvcId,
  });
  const [remindOnboarding, setRemindOnboarding] = React.useState(true);
  const [skipOnboarding, setSkipOnboarding] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [productAnalyticsReady, setProductAnalyticsReady] =
    React.useState(false);

  React.useEffect(() => {
    if (user) {
      setSkipOnboarding(onboarding => {
        if (onboarding[user?.id] === undefined) {
          return { ...onboarding, [user?.id]: false };
        }
        return { ...onboarding };
      });
    }
  }, [user]);

  const handleSetSkipOnboarding = (userId: string, skipOnboarding: boolean) => {
    setSkipOnboarding(onboarding => {
      return { ...onboarding, [userId]: skipOnboarding };
    });
  };

  const isMainUserLoading = loading || isProvisioningUser;

  return (
    <UserInfoContext.Provider
      value={{
        user: nextUser || user || fallbackUserData,
        loading,
        nextUserLoading,
        switchUser,
        onboarding: {
          remindOnboarding,
          setRemindOnboarding,
          skipOnboarding,
          handleSetSkipOnboarding,
        },
        productAnalytics: {
          isReady: productAnalyticsReady,
          setIsReady: setProductAnalyticsReady,
        },
      }}
    >
      {(isMainUserLoading || nextUserLoading) && (
        <LoaderMsg msg="Please wait while we are loading your data" />
      )}
      {!isMainUserLoading && !nextUserLoading && (
        <ProvisionedUserRedirects>{children}</ProvisionedUserRedirects>
      )}
    </UserInfoContext.Provider>
  );
};

export default UserContextProvider;
