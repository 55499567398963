import * as React from 'react';
import { Link } from '@jll-labs/azara-ui-components';
import NoData from '@components/noData';
import DocumentationLink from '@components/link/DocumentationLink';

const NoDataReport: React.FC = () => (
  <NoData>
    There is no data to populate this item. For information about data needed
    refer to Documentation{' '}
    <DocumentationLink topic={'Dashboards'}>
      <Link text="here" />
    </DocumentationLink>
    .
  </NoData>
);

export default NoDataReport;
