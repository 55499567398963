import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useGetInsightsAlertsQuery } from './useGetInsightsAlertsQuery';

export const useGetInsightsAlerts = (
  userId: string,
  tenantId: string,
  hasFeatureEnabled: boolean,
  importance?: SchemaTypes.InsightsAlertImportance,
  alertType?: SchemaTypes.InsightsAlertType,
  status?: SchemaTypes.InsightsAlertStatus,
  alertTab?: SchemaTypes.InsightsAlertTab,
  orderBy?: SchemaTypes.InsightsAlertOrderBy,
  alertSeverity?: SchemaTypes.InsightsAlertSeverity,
  assignedUserId?: string,
) => {
  const { loading, data, fetchMore, networkStatus, variables } =
    useGetInsightsAlertsQuery({
      userId,
      tenantId,
      importance,
      alertType,
      status,
      orderBy,
      alertTab,
      assignedUserId,
      alertSeverity,
      skip: !hasFeatureEnabled,
    });

  const { alerts, continuation_token, has_next_page } =
    data?.getUser?.user_insights_alerts ?? {};
  const initAlerts = alerts ?? [];

  const loadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        whereInsightsAlerts: {
          ...variables?.whereInsightsAlerts,
          continuation_token,
        },
      },
    });
  };

  return {
    loading,
    loadMore,
    networkStatus,
    alerts: initAlerts,
    hasNextPage: has_next_page,
    continuationToken: continuation_token,
  };
};
