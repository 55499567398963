import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { main, useRoutes } from '@routes';
import {
  useGetEnvironmentType,
  useGetUserDefaultTenantId,
  useGetUserInfo,
} from '@apollo-red/hooks';
import PageContainer, { PageContainerContext } from '@components/pageContainer';
import { AzaraDashboards, PATDashboards } from '@pages/dashboards';
import Settings from '@pages/settings';
import InsightsAlerts from '@pages/insightsAlert';
import Portscape from '@pages/portscape';
import SelfServeAnalytics from '@pages/selfServeAnalytics';
import TableauCacheContextProvider from '@components/tableau/TableauCacheContextProvider';
import OnBoarding from '@components/onBoarding';
import UserAgreement from '@components/userAgreement';
import '@components/pendo/types';
import Suspense from '@components/asyncLoader';
import { UserProvider } from '@components/userContext';
import { MenuItems } from '@components/pageContainer/SideBar/Navigation/navHelpers.types';
import Routes, { RoleRoutes } from '@components/routes';
import { useFeatureFlag } from '@utils/launchDarkly';
import { usePendo } from '@utils/pendo';
import { Await } from '@utils/various';
import { LaunchDarklyProvider, LaunchDarklyUser } from './LaunchDarklyProvider';
import BrowserRouterWithPendo from './BrowserRouterWithPendo';
import ClientLegacyRedirect from './ClientLegacyRedirect';
import { IdleTimer } from './IdleTimer';

type LaunchDarklyWrapper = Await<ReturnType<typeof LaunchDarklyProvider>>;

interface LaunchDarklyState {
  LaunchDarkly: LaunchDarklyWrapper;
}

const SystemAdmin = React.lazy(
  () => import(/* webpackChunkName: "system-settings" */ '@pages/systemAdmin'),
);
const TenantAdmin = React.lazy(
  () => import(/* webpackChunkName: "client-settings" */ '@pages/tenantAdmin'),
);
const DataManagement = React.lazy(
  () =>
    import(/* webpackChunkName: "data-management" */ '@pages/dataManagement'),
);

const PageContainerContent: React.FC = () => {
  const routes = useRoutes();
  const globalFeatureFlagsTest = useFeatureFlag('globalFeatureFlagsTest');
  const pageContainerCtx = React.useContext(PageContainerContext);
  const [bodyContainer, setBodyContainer] = React.useState<HTMLElement | null>(
    null,
  );

  const roleRoutes: RoleRoutes = [
    {
      path: routes.client.dashboards.path,
      component: AzaraDashboards,
      feature: MenuItems.Dashboards,
    },
    {
      path: routes.client.PATDashboards.path,
      component: PATDashboards,
      feature: MenuItems.Dashboards,
    },
    {
      path: routes.client.insightsAlerts.path,
      component: InsightsAlerts,
      feature: MenuItems.InsightsAlerts,
    },
    {
      path: routes.client.jllPortscape.path,
      component: Portscape,
      feature: MenuItems.JLLPortscape,
    },
    {
      path: routes.client.selfServeAnalytics.path,
      component: SelfServeAnalytics,
      feature: MenuItems.SelfServeAnalytics,
    },
    {
      path: routes.client.settings.path,
      component: Settings,
      feature: MenuItems.Settings,
    },
    {
      path: routes.client.productSettings.path,
      component: SystemAdmin,
      feature: MenuItems.ProductSettings,
    },
    {
      path: routes.client.clientSettings.path,
      component: TenantAdmin,
      feature: MenuItems.ClientSettings,
    },
    {
      path: routes.client.dataManagement.path,
      component: DataManagement,
      feature: MenuItems.DataManagement,
    },
  ];

  React.useEffect(() => {
    const existing = document.getElementById('tableau-body-cache');
    if (existing) {
      setBodyContainer(existing);
    } else {
      const el = document.createElement('div');
      el.id = 'tableau-body-cache';
      el.style.position = 'fixed';
      el.style.zIndex = '9999';
      el.style.top = '0px';
      el.style.left = '0px';
      document.body.appendChild(el);
      setBodyContainer(el);
    }
  }, []);

  if (globalFeatureFlagsTest) {
    return <div>Success</div>;
  }

  return (
    <TableauCacheContextProvider
      containers={{
        pageContainerContent: {
          element: pageContainerCtx.contentEl,
          hide: false,
        },
        bodyContainer: { element: bodyContainer, hide: true },
      }}
    >
      <Routes roleRoutes={roleRoutes} />
    </TableauCacheContextProvider>
  );
};

const ClientRouter: React.FC<{}> = () => {
  const [launchDarklyState, setLaunchDarklyState] =
    React.useState<LaunchDarklyState>({
      LaunchDarkly: () => null,
    });
  const { environmentIdentifier } = useGetEnvironmentType();
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();

  usePendo();

  const userId = user?.id;
  const userEmail = user?.user_email;
  const userName = user?.user_full_name;

  React.useEffect(() => {
    const initializeLaunchDarkly = async () => {
      const launchDarklyUser: LaunchDarklyUser = {
        key: userId,
        name: userName,
        email: userEmail,
        custom: {
          qaEnv: environmentIdentifier,
        },
      };
      const launchDarklyProvider = await LaunchDarklyProvider(launchDarklyUser);

      setLaunchDarklyState((prevState: LaunchDarklyState) => ({
        ...prevState,
        LaunchDarkly: launchDarklyProvider,
      }));
    };

    initializeLaunchDarkly();
  }, [environmentIdentifier, userId, userEmail, userName]);

  React.useEffect(() => {
    document.title = 'Azara';
  }, []);

  const { LaunchDarkly } = launchDarklyState;

  return (
    LaunchDarkly && (
      <Suspense>
        <LaunchDarkly>
          <Switch>
            <Route path={main.legacyClient.path}>
              <ClientLegacyRedirect />
            </Route>
            <Route>
              <UserAgreement>
                <OnBoarding
                  key={`page-container-${user.id}-${defaultTenantId}`}
                >
                  <PageContainer>
                    <Suspense>
                      <PageContainerContent />
                    </Suspense>
                  </PageContainer>
                </OnBoarding>
              </UserAgreement>
            </Route>
          </Switch>
        </LaunchDarkly>
      </Suspense>
    )
  );
};

const ClientRouterWithContext: React.FC<{}> = ({ children }) => {
  return (
    <UserProvider>
      <IdleTimer>
        <BrowserRouterWithPendo>
          <ClientRouter>{children}</ClientRouter>
        </BrowserRouterWithPendo>
      </IdleTimer>
    </UserProvider>
  );
};

export default ClientRouterWithContext;
