import * as React from 'react';
import { utils } from '@jll-labs/azara-ui-components';
import { InvalidFilterValue } from '@utils/error';
import { FilterUpdateType, Viz } from '@components/tableau/types';
import { useGetFiltersSheet } from './useGetFiltersSheet';

const { useToggleValue } = utils;

export const useApplyFilterAsync = (viz?: Viz) => {
  const [loading, toggleLoading] = useToggleValue(false);
  const filtersSheet = useGetFiltersSheet(viz);

  const applyFilterAsync = React.useCallback(
    async (
      name: string,
      values: string[],
      filterType: FilterUpdateType,
      isExcludeMode = false,
    ) => {
      if (!viz || !filtersSheet) {
        return;
      }

      try {
        toggleLoading();

        await filtersSheet.applyFilterAsync(name, values, filterType, {
          isExcludeMode,
        });
      } catch (e) {
        throw new InvalidFilterValue(name);
      } finally {
        toggleLoading();
      }
    },
    [filtersSheet, toggleLoading, viz],
  );

  return { loading, applyFilterAsync };
};
