import { useTranslation } from 'react-i18next';
import {
  InsightsAlert,
  InsightsAlertScope,
  AlertReports,
  TenantAlertSettingsType,
} from '@apollo-red/hooks';
import { alertTypeToTitleTranslation } from '../../utils';
import { AlertReport, initializeAlertReport } from './initializeAlertReport';
import { insightsAlertTypeToTenantAlertSettingsType } from './insightsAlertTypeToTenantAlertSettingsType';

/**
 * Gets report based on provided scope.
 *
 * @param alert alert document
 * @param alertScope alert scope
 */
export const useAlertReport = (
  alertReports: AlertReports,
  alert?: InsightsAlert,
  alertScope?: InsightsAlertScope,
): AlertReport | undefined => {
  const { t } = useTranslation();

  if (!alert) {
    return undefined;
  }

  const alertType = alert.alert_type;
  const tenantAlertType: TenantAlertSettingsType =
    insightsAlertTypeToTenantAlertSettingsType[alertType ?? ''];

  switch (alertScope) {
    case InsightsAlertScope.Internal:
      return initializeAlertReport({
        report: alertType ? alertReports[tenantAlertType]?.internal : undefined,
        alert,
        alertType,
        alertScope,
        alertName: t(alertTypeToTitleTranslation[alertType]),
      });
    case InsightsAlertScope.External:
      return initializeAlertReport({
        report: alertType ? alertReports[tenantAlertType]?.external : undefined,
        alert,
        alertType,
        alertScope,
        alertName: t(alertTypeToTitleTranslation[alertType]),
      });
    default:
      return undefined;
  }
};
