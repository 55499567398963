import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';

const useStyles = makeStyles((theme: AppTheme) => ({
  description: {
    height: '1.5em',
    width: '20em',
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

interface Props {
  shortDescription: string;
}

const AlertSubtitleCell: React.FC<Props> = ({ shortDescription }) => {
  const classes = useStyles();

  return <div className={classes.description}>{shortDescription}</div>;
};

export default AlertSubtitleCell;
