import * as React from 'react';
import { useRoutes } from '@routes';
import { useGetUserCurrentTenantReports } from '@apollo-red/hooks';
import { Viz, TableauEventName } from '@components/tableau/types';

export const useUrlAction = () => {
  const [viz, setViz] = React.useState<undefined | Viz>(undefined);
  const { allReports } = useGetUserCurrentTenantReports();
  const routes = useRoutes();

  const getReport = React.useCallback(
    (redirectURL: string) => {
      return allReports.find(
        report =>
          report.report_tableau_full_report_url.indexOf(redirectURL) !== -1,
      );
    },
    [allReports],
  );

  const handleUrlAction = React.useCallback(
    event => {
      const redirectURL = event.getUrl();
      const report = getReport(redirectURL);

      if (report) {
        const path = routes.dashboards.dashboard.getLink('all', report.id);
        const targetURL = `${window.location.origin}${path}`;
        window.open(targetURL);
      } else {
        window.open(redirectURL);
      }
    },
    [getReport, routes.dashboards.dashboard],
  );

  React.useEffect(() => {
    if (viz) {
      viz.addEventListener(TableauEventName.URL_ACTION, handleUrlAction);
    }

    return () => {
      viz?.removeEventListener(TableauEventName.URL_ACTION, handleUrlAction);
    };
  }, [viz, handleUrlAction]);

  const handleVizUrlAction = (viz: Viz) => {
    setViz(viz);
  };

  return { handleVizUrlAction, disableUrlActionsPopups: true };
};
