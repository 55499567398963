export const parseTargetTableauSite = (
  reportUrl: string,
  isExternalServer?: boolean,
) => {
  const invalid = 'invalid';
  const tableauServerOrigin = !isExternalServer
    ? process.env.TABLEAU_SERVER_ORIGIN
    : reportUrl
        .toLowerCase()
        .indexOf(
          process.env.TABLEAU_SERVER_ORIGIN_EXTERNAL_A4TST ?? invalid,
        ) === 0
    ? process.env.TABLEAU_SERVER_ORIGIN_EXTERNAL_A4TST
    : process.env.TABLEAU_SERVER_ORIGIN_EXTERNAL_A4;

  if (reportUrl.toLowerCase().indexOf(tableauServerOrigin ?? invalid) !== 0) {
    return '';
  }

  const hostName = tableauServerOrigin ?? '';
  const regExp = new RegExp(`${hostName}/t/(.*)/views/.*`);
  const matches = regExp.exec(reportUrl);

  if (matches?.length !== 2) {
    return '';
  }

  const [_, targetSiteId] = matches;

  return targetSiteId;
};
