import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  ADD_FAVORITE_REPORT,
  REMOVE_FAVORITE_REPORT,
  REPLACE_FAVORITE_REPORTS,
  ADD_HIDDEN_REPORT,
  REMOVE_HIDDEN_REPORT,
} from '@apollo-red/mutations';
import { filterItems } from '@utils/various';
import { useAppMutation } from '../../queries';
import { useGetUserInfo } from '../user';
import { useGetUserCurrentTenantReports, ReportExtended } from '../reports';

export const useGetUserHiddenReportsIds = () => {
  const { loading, user } = useGetUserInfo();

  return {
    loading,
    hiddenReportsIds: (user.user_settings.hidden_reports || []).map(r => r.id),
  };
};

export const useGetUserFavReportsIds = () => {
  const { loading, user } = useGetUserInfo();

  return {
    loading,
    favReportsIds: filterItems(user.user_fav_reports).map(r => r.id),
  };
};

export const useGetUserSavedReportsIds = () => {
  const { loading, user } = useGetUserInfo();

  return {
    loading,
    savedReportsIds: filterItems(user.user_saved_reports).map(r => r.id),
  };
};

export const useGetUserFavReports = () => {
  const { loading, allReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    favReports: allReports.filter(r => r.isFav),
  };
};

export const useGetUserSavedReports = () => {
  const { loading, allReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    savedReports: allReports.filter(r => r.isSavedView),
  };
};

export const useAddFavorite = () => {
  const [addFavorite] =
    useAppMutation<SchemaTypes.AddUserFavoriteReportMutation>(
      ADD_FAVORITE_REPORT,
    );

  return {
    addFavorite: (
      reportId: string,
      userId: string,
      favReportsIds: string[],
    ) => {
      return addFavorite({
        optimisticResponse: {
          addUserFavoriteReport: {
            id: userId,
            user_fav_reports: favReportsIds.concat(reportId).map(rId => ({
              id: rId,
              __typename: 'Report',
            })),
            __typename: 'User',
          },
        },
        variables: {
          reportId,
          where: {
            id: userId,
          },
        },
      });
    },
  };
};

export const useAddHidden = () => {
  const [addHidden] =
    useAppMutation<SchemaTypes.AddUserHiddenReportMutation>(ADD_HIDDEN_REPORT);
  return {
    addHidden: (
      reportId: string,
      userId: string,
      hiddenReportsIds: string[],
    ) => {
      return addHidden({
        optimisticResponse: {
          addUserHiddenReport: {
            id: userId,
            user_settings: {
              hidden_reports: hiddenReportsIds.concat(reportId).map(rId => ({
                id: rId,
                __typename: 'Report',
              })),
              __typename: 'UserSettings',
            },
            __typename: 'User',
          },
        },
        variables: {
          reportId,
          where: {
            id: userId,
          },
        },
      });
    },
  };
};

export const useRemoveHidden = () => {
  const [removeHidden] =
    useAppMutation<SchemaTypes.RemoveUserHiddenReportMutation>(
      REMOVE_HIDDEN_REPORT,
    );
  return {
    removeHidden: (
      reportId: string,
      userId: string,
      hiddenReportsIds: string[],
    ) => {
      return removeHidden({
        optimisticResponse: {
          removeUserHiddenReport: {
            id: userId,
            user_settings: {
              hidden_reports: hiddenReportsIds
                .filter(rId => rId !== reportId)
                .map(rId => ({
                  id: rId,
                  __typename: 'Report',
                })),
              __typename: 'UserSettings',
            },
            __typename: 'User',
          },
        },
        variables: {
          reportId,
          where: {
            id: userId,
          },
        },
      });
    },
  };
};

export const useRemoveFavorite = () => {
  const [removeFavorite] =
    useAppMutation<SchemaTypes.RemoveUserFavoriteReportMutation>(
      REMOVE_FAVORITE_REPORT,
    );

  return {
    removeFavorite: (
      reportId: string,
      userId: string,
      favReportsIds: string[],
    ) => {
      return removeFavorite({
        optimisticResponse: {
          removeUserFavoriteReport: {
            id: userId,
            user_fav_reports: favReportsIds
              .filter(rId => rId !== reportId)
              .map(rId => ({
                id: rId,
                __typename: 'Report',
              })),
            __typename: 'User',
          },
        },
        variables: {
          reportId,
          where: {
            id: userId,
          },
        },
      });
    },
  };
};

export const useReplaceFavorites = () => {
  const { user } = useGetUserInfo();
  const userId = user.id;
  const [replaceFavReports] =
    useAppMutation<SchemaTypes.ReplaceUserFavoriteReportsMutation>(
      REPLACE_FAVORITE_REPORTS,
    );
  return {
    replaceFavReports: (reportIds: string[]) => {
      return replaceFavReports({
        optimisticResponse: {
          replaceUserFavoriteReports: {
            id: userId,
            user_fav_reports: reportIds.map(rId => ({
              id: rId,
              __typename: 'Report',
            })),
            __typename: 'User',
          },
        },
        variables: {
          reportIds,
          where: {
            id: userId,
          },
        },
      });
    },
  };
};

export const useToggleFavorite = () => {
  const { user } = useGetUserInfo();
  const { favReportsIds } = useGetUserFavReportsIds();
  const userId = user.id;
  const { removeFavorite } = useRemoveFavorite();
  const { addFavorite } = useAddFavorite();

  const toggleFavorite = (report: ReportExtended) => {
    if (report.isFav) {
      removeFavorite(report.id, userId, favReportsIds);
    } else {
      addFavorite(report.id, userId, favReportsIds);
    }
  };

  return {
    toggleFavorite,
  };
};

export const useToggleHidden = () => {
  const { user } = useGetUserInfo();
  const { hiddenReportsIds } = useGetUserHiddenReportsIds();
  const userId = user.id;
  const { removeHidden } = useRemoveHidden();
  const { addHidden } = useAddHidden();

  const toggleHidden = (report: ReportExtended) => {
    if (report.isHidden) {
      removeHidden(report.id, userId, hiddenReportsIds);
    } else {
      addHidden(report.id, userId, hiddenReportsIds);
    }
  };

  return {
    toggleHidden,
  };
};

export const useGetUserFavPATReports = () => {
  const { loading, patReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    favReports: patReports?.filter(r => r.isFav),
  };
};
