import * as React from 'react';
import { useGetUserInfo, useSyncUser } from '@apollo-red/hooks';

const ProvisionedUserRedirects: React.FC<{}> = ({ children }) => {
  const { user } = useGetUserInfo();
  const { syncUser } = useSyncUser();

  React.useEffect(() => {
    syncUser(user.id);
  }, [syncUser, user.id]);

  return <>{children}</>;
};

export default ProvisionedUserRedirects;
