export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AlertTemplateData: [
      'InsightsAlertBaseWorkOrderTemplateData',
      'InsightsAlertLeaseEventTemplateData',
      'InsightsAlertMarketBenchmarkTemplateData',
      'InsightsAlertOccupancyEfficiencyTemplateData',
      'InsightsAlertSameDayWorkOrderCompletionTemplateData',
      'InsightsAlertSubleaseRiskTemplateData',
      'InsightsAlertWorkOrderVolumeTemplateData',
    ],
    InsightsAlertTemplateData: [
      'InsightsAlertBaseWorkOrderTemplateData',
      'InsightsAlertLeaseEventTemplateData',
      'InsightsAlertWorkOrderVolumeTemplateData',
      'InsightsAlertMarketBenchmarkTemplateData',
      'InsightsAlertOccupancyEfficiencyTemplateData',
      'InsightsAlertSubleaseRiskTemplateData',
      'InsightsAlertSameDayWorkOrderCompletionTemplateData',
    ],
    TenantAlertSettingData: [
      'TenantWorkOrderVolumeAlertSettingData',
      'TenantFacilitiesExperienceAlertSettingData',
      'TenantLeaseEventAlertSettingData',
      'TenantCovidWorkOrderAlertSettingData',
      'TenantMarketBenchmarkAlertSettingData',
      'TenantOccupancyEfficiencyAlertSettingData',
      'TenantSubleaseRiskAlertSettingData',
      'TenantSameDayWorkOrderCompletionAlertSettingData',
    ],
    TenantAlertSettings: [
      'WorkOrderVolumeAlertSettings',
      'FacilitiesExperienceAlertSettings',
      'LeaseEventAlertSettings',
      'CovidWorkOrderAlertSettings',
      'MarketBenchmarkAlertSettings',
      'OccupancyEfficiencyAlertSettings',
      'SubleaseRiskAlertSettings',
      'SameDayWorkOrderCompletionAlertSettings',
    ],
    TenantConfigurationDataOptions: [
      'TenantFinanceDataOptions',
      'TenantTransactionDataOptions',
      'TenantLeaseDataOptions',
      'TenantSpaceDataOptions',
    ],
    TenantConfigurationDataSampleValues: [
      'TenantSpaceDataSampleValues',
      'TenantFinanceDataSampleValues',
      'TenantLeaseDataSampleValues',
    ],
    TenantConfigurationDataValues: [
      'TenantDefaultsDataValues',
      'TenantWorkOrdersDataValues',
      'TenantFinanceDataValues',
      'TenantTransactionDataValues',
      'TenantLeaseDataValues',
      'TenantSpaceDataValues',
    ],
    UserAlertSettingData: [
      'WorkOrderVolumeAlertSettingData',
      'FacilitiesExperienceAlertSettingData',
      'LeaseEventAlertSettingData',
      'CovidWorkOrderAlertSettingData',
      'MarketBenchmarkAlertSettingData',
      'OccupancyEfficiencyAlertSettingData',
      'SubleaseRiskAlertSettingData',
      'SameDayWorkOrderCompletionAlertSettingData',
    ],
  },
};
export default result;
