export const isInternalUser = (email: string) => {
  const regExp = new RegExp(`(?<userName>.*)@(?<domain>.*)`, 'i');
  const matches = regExp.exec(email);
  const { domain } = matches?.groups ?? {};

  if (!domain) {
    return false;
  }

  return domain.indexOf('jll.com') !== -1;
};
