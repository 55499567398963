import * as React from 'react';
import {
  Dashboard,
  SheetType,
  Viz,
  Worksheet,
} from '@components/tableau/types';

export const useGetWorksheets = (viz?: Viz) => {
  const [worksheets, setWorksheets] = React.useState<Worksheet[]>([]);

  React.useEffect(() => {
    if (!viz) {
      return;
    }

    const activeSheet = viz.getWorkbook().getActiveSheet();
    const activeSheetType = activeSheet.getSheetType();
    let worksheets: Worksheet[];

    if (activeSheetType === SheetType.DASHBOARD) {
      const dashboard = activeSheet as Dashboard;
      worksheets = dashboard.getWorksheets();
    } else if (activeSheetType === SheetType.WORKSHEET) {
      worksheets = [activeSheet as Worksheet];
    } else {
      // Story SheetTypes not supported at this time.
      worksheets = [];
    }

    setWorksheets(worksheets);
  }, [viz]);

  return { worksheets };
};
