import {
  User,
  UserClassificationType,
  UserManagementType,
  UserStatus,
} from './types';

export const fallbackUserData: User = {
  id: '',
  user_email: '',
  user_full_name: '',
  user_saved_reports: [],
  user_tutored: true,
  user_settings: {},
  user_comm: {},
  user_fav_reports: [],
  user_picture_url: [],
  user_admin_settings: {
    user_system_roles: [],
  },
  user_tenant_settings: [],
  user_tenant_regular_settings: [],
  user_address: {},
  user_kpi_data: [],
  user_management_type: UserManagementType.Managed,
  user_status: UserStatus.Inactive,
  user_agreement: {
    terms_accepted: false,
    terms_accepted_at: '',
  },
  user_type: UserClassificationType.ClientUser,
  __typename: 'User',
};
