import * as React from 'react';
import { Grid } from '@mui/material';
import { ReportFiltersInteractionSection } from '../types';
import FiltersSection from './filters/FiltersSection';
import VariablesPanel from './parameters/VariablesPanel';
import PinnedFiltersHelpMessage from './PinnedFiltersHelpMessage';
import { useInteractionSections } from './useInteractionSections';
import { useInteractionSectionsStyles } from './useInteractionSectionsStyles';

interface Props {
  sections: ReportFiltersInteractionSection[];
}

const InteractionSections: React.FC<Props> = ({ sections }) => {
  const {
    filterSection,
    parametersSection,
    showPinHelpMessage,
    tableauFilters,
    tableauFiltersLoading,
    tableauParameters,
    tableauParametersLoading,
  } = useInteractionSections({ sections });
  const classes = useInteractionSectionsStyles({ showPinHelpMessage });

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      wrap="nowrap"
      className={classes.container}
    >
      <Grid item className={classes.sections}>
        <Grid container direction="column" wrap="nowrap">
          {parametersSection?.length > 0 &&
            parametersSection?.map(parameterSection => {
              return (
                <Grid item key={parameterSection.id}>
                  <VariablesPanel
                    section={parameterSection}
                    parametersLoading={tableauParametersLoading}
                    tableauParameters={tableauParameters}
                  />
                </Grid>
              );
            })}
          {filterSection && (
            <Grid item key={filterSection.id}>
              <FiltersSection
                tableauFilters={tableauFilters}
                filtersLoading={tableauFiltersLoading}
                section={filterSection}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <PinnedFiltersHelpMessage show={showPinHelpMessage} />
    </Grid>
  );
};

export default InteractionSections;
