import * as React from 'react';
import { useAuth } from '@components/okta';
import { useDocumentationBaseUrl } from './useDocumentationBaseUrl';
import { config } from './config';

type DocumentationApiMethod = 'POST' | 'DELETE';

export const useDocumentationApiClient = () => {
  const { docsBaseUrl } = useDocumentationBaseUrl();
  const { authState } = useAuth();
  const idToken = authState?.idToken?.idToken;

  const request = React.useCallback(
    async (method: DocumentationApiMethod) => {
      if (!idToken) {
        return false;
      }

      const init = {
        ...config,
        method,
      };

      init.headers['Authorization'] = `Bearer ${idToken}`;

      try {
        const response = await fetch(docsBaseUrl, init);

        if (!response.ok) {
          throw new Error('Invalid response');
        }

        const json: { success: boolean } = await response.json();

        return !!json.success;
      } catch {
        return false;
      }
    },
    [docsBaseUrl, idToken],
  );

  const login = React.useCallback(() => request('POST'), [request]);

  const logout = React.useCallback(() => request('DELETE'), [request]);

  return React.useMemo(
    () => ({
      login,
      logout,
    }),
    [login, logout],
  );
};
