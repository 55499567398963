import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from '@okta/okta-auth-js';
import { main } from '@routes';
import { useInitializeOktaTokens } from './useInitializeOktaTokens';
import { useOktaStorageProvider } from './useOktaStorageProvider';

const Router: React.FC = ({ children }) => {
  const history = useHistory();
  const storageProvider = useOktaStorageProvider();
  const useEnterpriseLoginForm =
    parseInt(process.env.OKTA_ELF ?? '0', 10) === 1;
  const issuer = useEnterpriseLoginForm
    ? process.env.OKTA_ISSUER_ELF
    : process.env.OKTA_ISSUER;

  const config: OktaAuthOptions = {
    issuer,
    clientId: process.env.OKTA_CLIENT_ID,
    pkce: true,
    redirectUri: window.location.origin + main.callback.getLink(),
    scopes: ['openid', 'email', 'profile', 'offline_access'],
    storageManager: {
      token: {
        storageProvider,
      },
    },
  };

  const restoreOriginalUri: React.ComponentProps<
    typeof Security
  >['restoreOriginalUri'] = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const oktaAuth = new OktaAuth(config);
  const { initialized } = useInitializeOktaTokens(oktaAuth);

  if (!initialized) {
    return null;
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default Router;
