import * as React from 'react';
import { HelpPortalLinkType } from './useHelpPortalLinkType';

interface HelpPortalLink {
  topic: string;
  link: string;
}

const HelpPortalLinks: Record<HelpPortalLinkType, HelpPortalLink[]> = {
  [HelpPortalLinkType.Home]: [
    {
      topic: 'Home',
      link: 'Home',
    },
  ],
  [HelpPortalLinkType.Dashboard]: [
    {
      topic: 'Dashboards',
      link: 'Dashboards/Dashboards%20Overview',
    },
    {
      topic: 'Facilities Management',
      link: 'Dashboards/Facilities%20Management/Facilities%20Management%20Overview',
    },
    {
      topic: 'Emergency and Urgent Work Orders',
      link: 'Dashboards/Facilities%20Management/Emergency%20and%20Urgent%20Work%20Orders',
    },
    {
      topic: 'FM KPIs',
      link: 'Dashboards/Facilities%20Management/FM%20KPIs',
    },
    {
      topic: 'Invoices Awaiting Approval',
      link: 'Dashboards/Facilities%20Management/Invoices%20Awaiting%20Approval',
    },
    {
      topic: 'Work Orders Needing Attention',
      link: 'Dashboards/Facilities%20Management/Work%20Orders%20Needing%20Attention',
    },
    {
      topic: 'FM Director KPIs',
      link: 'Dashboards/Facilities%20Management/FM%20Director%20KPIs',
    },
    {
      topic: 'Dissatisfied Work Orders',
      link: 'Dashboards/Facilities%20Management/Dissatisfied%20Work%20Orders',
    },
    {
      topic: 'Service Providers Performance',
      link: 'Dashboards/Facilities%20Management/Service%20Providers%20Performance',
    },
    {
      topic: 'Work Order Volume Analysis',
      link: 'Dashboards/Facilities%20Management/Work%20Order%20Volume%20Analysis',
    },
    {
      topic: 'Employee Experience',
      link: 'Dashboards/Facilities%20Management/Employee%20Experience',
    },
    {
      topic: 'Finance',
      link: 'Dashboards/Finance/Finance',
    },
    {
      topic: 'Property Spend',
      link: 'Dashboards/Finance/Property%20Spend',
    },
    {
      topic: 'Actuals vs Budget',
      link: 'Dashboards/Finance/Actual%20vs%20Budget',
    },
    {
      topic: 'Lease Administration',
      link: 'Dashboards/Lease%20Admin/Lease%20Admin',
    },
    {
      topic: 'Lease Events',
      link: 'Dashboards/Lease%20Admin/Lease%20Events',
    },
    {
      topic: 'Lease Expiration',
      link: 'Dashboards/Lease%20Admin/Lease%20Expirations',
    },
    {
      topic: 'Lease Financials',
      link: 'Dashboards/Lease%20Admin/Lease-Financials',
    },
    {
      topic: 'Occupancy',
      link: 'Dashboards/Occupancy/Occupancy',
    },
    {
      topic: 'Occupancy Details',
      link: 'Dashboards/Occupancy/Occupancy%20Details',
    },
    {
      topic: 'Occupancy Trend',
      link: 'Dashboards/Occupancy/Occupancy%20Trend',
    },
    {
      topic: 'Legacy Dashboards',
      link: 'Dashboards/PAT%20Dashboards/PAT-dashboards',
    },
    {
      topic: 'PAT Dashboards',
      link: 'Dashboards/PAT%20Dashboards/PAT-dashboards',
    },
    {
      topic: 'Lease Analysis',
      link: 'Dashboards/PAT%20Dashboards/Lease%20Analysis-PAT',
    },
    {
      topic: 'Lease Events PAT',
      link: 'Dashboards/PAT%20Dashboards/Lease%20Events-PAT',
    },
    {
      topic: 'Lease Expiration PAT',
      link: 'Dashboards/PAT%20Dashboards/Lease%20Expirations-PAT',
    },
    {
      topic: 'Lease Financials PAT',
      link: 'Dashboards/PAT%20Dashboards/Lease-Financials-PAT',
    },
    {
      topic: 'Market Favorability',
      link: 'Dashboards/PAT%20Dashboards/Market%20Favorability-PAT',
    },
    {
      topic: 'Portfolio Map PAT',
      link: 'Dashboards/PAT%20Dashboards/Portfolio%20Map',
    },
    {
      topic: 'Portfolio Overview',
      link: 'Dashboards/PAT%20Dashboards/Portfolio%20Overview',
    },
    {
      topic: 'Property Clock Overview',
      link: 'Dashboards/PAT%20Dashboards/Property%20Clock',
    },
    {
      topic: 'Property List',
      link: 'Dashboards/PAT%20Dashboards/Property%20List',
    },
    {
      topic: 'Occupancy Efficiency',
      link: 'Dashboards/Occupancy/Occupancy%20Efficiency',
    },
    {
      topic: 'Occupancy List',
      link: 'Dashboards/Occupancy/Occupancy%20List',
    },
    {
      topic: 'Occupancy by Dimension',
      link: 'Dashboards/Occupancy/Occupancy%20by%20Dimension',
    },
    {
      topic: 'Cost of Vacancy',
      link: 'Dashboards/Occupancy/Occupancy%20Cost%20of%20Vacancy',
    },
    {
      topic: 'Portfolio',
      link: 'Dashboards/Portfolio/Portfolio',
    },
    {
      topic: 'Alerts Summary',
      link: 'Dashboards/Portfolio/Alerts%20Summary',
    },
    {
      topic: 'Portfolio Summary',
      link: 'Dashboards/Portfolio/Portfolio%20Summary',
    },
    {
      topic: 'Property Spotlight',
      link: 'Dashboards/Portfolio/Property%20Spotlight',
    },
    {
      topic: 'Project Management',
      link: 'Dashboards/Project%20Management/Project%20Management',
    },
    {
      topic: 'PDS Heads Up',
      link: 'Dashboards/Project%20Management/PDS%20Heads%20Up',
    },
    {
      topic: 'PDS Closed Projects',
      link: 'Dashboards/Project%20Management/PDS%20Closed%20Projects',
    },
    {
      topic: 'PDS Active Projects',
      link: 'Dashboards/Project%20Management/PDS%20Active%20Projects',
    },
    {
      topic: 'Transaction Management',
      link: 'Dashboards/Transaction%20Management/Transactions%20Management',
    },
    {
      topic: 'Transactions General Overview',
      link: 'Dashboards/Transaction%20Management/Transactions%20General%20Overview',
    },
    {
      topic: 'Transactions Needing Attention',
      link: 'Dashboards/Transaction%20Management/Transactions%20Needing%20Attention',
    },
  ],
  [HelpPortalLinkType.Kpi]: [
    {
      topic: 'KPIs',
      link: 'Dashboards/KPIs/KPIs',
    },
    {
      topic: 'Property Count KPI',
      link: 'Dashboards/KPIs/Property%20Count%20KPI',
    },
    {
      topic: 'Portfolio Area KPI',
      link: 'Dashboards/KPIs/Area%20KPI',
    },
    {
      topic: 'Portfolio Cost KPI',
      link: 'Dashboards/KPIs/Cost%20KPI',
    },
  ],
  [HelpPortalLinkType.Alert]: [
    {
      topic: 'Alerts',
      link: 'Alerts/Alerts',
    },
    {
      topic: 'Work Order Volume',
      link: 'Alerts/Work-Order-Volume%20Alert',
    },
    {
      topic: 'WOV Alert External',
      link: 'Alerts/Work-Order-Volume%20Alert',
    },
    {
      topic: 'WOV Alert Internal',
      link: 'Alerts/Work-Order-Volume%20Alert',
    },
    {
      topic: 'Facility Experience Work Order',
      link: 'Alerts/Facilities-Experience-Alert',
    },
    {
      topic: 'Disaster Recovery Work Order',
      link: 'Alerts/Disaster%20Recovery%20Work%20Order',
    },
    {
      topic: 'Lease Cost Benchmark',
      link: 'Alerts/Lease%20Cost%20Alert',
    },
    {
      topic: 'Lease Cost Benchmarking',
      link: 'Alerts/Lease%20Cost%20Alert',
    },
    {
      topic: 'Lease Events',
      link: 'Alerts/Lease-Event-Alert',
    },
    {
      topic: 'Occupancy Details',
      link: 'Alerts/Alerts',
    },
    {
      topic: 'Same Day Work Order Completion',
      link: 'Alerts/Alerts',
    },
  ],
  [HelpPortalLinkType.MyAccount]: [
    {
      topic: 'My Account',
      link: 'My%20Account/MyAccount',
    },
    {
      topic: 'Account Settings',
      link: 'My%20Account/Account-Settings',
    },
    {
      topic: 'Dashboard Settings',
      link: 'My%20Account/Account-Dashboard-Settings',
    },
    {
      topic: 'Alert Settings',
      link: 'My%20Account/Alert-Settings',
    },
  ],
  [HelpPortalLinkType.ClientSettings]: [
    {
      topic: 'Client Settings',
      link: 'Client-Settings/Client-Settings',
    },
    {
      topic: 'User Management',
      link: 'Client-Settings/User-management',
    },
    {
      topic: 'Role Management',
      link: 'Client-Settings/Role-management',
    },
    {
      topic: 'Custom Reports',
      link: 'Client-Settings/Custom-Reports',
    },
    {
      topic: 'Config Data Setup',
      link: 'Client-Settings/Config-Data-Setup',
    },
    {
      topic: 'Keyword Management',
      link: 'Client-Settings/Keyword-Management',
    },
    {
      topic: 'Dashboard Settings',
      link: 'Client-Settings/Dashboard-Settings',
    },
    {
      topic: 'Core Dashboard Settings',
      link: 'Client-Settings/Dashboard-Settings',
    },
  ],
  [HelpPortalLinkType.DataManagement]: [
    {
      topic: 'Data Management',
      link: 'Data%20Management/Data%20Loads',
    },
    {
      topic: 'Data Loads',
      link: 'Data%20Management/Data%20Loads',
    },
    {
      topic: 'Data Standards',
      link: 'Data%20Management/Data%20Standards',
    },
    {
      topic: 'Data Views',
      link: 'Data%20Management/Data%20Views',
    },
  ],
  [HelpPortalLinkType.Portscape]: [],
  [HelpPortalLinkType.SelfServeAnalytics]: [],
  [HelpPortalLinkType.Faq]: [
    {
      topic: 'FAQs',
      link: 'FAQs',
    },
  ],
};

const getHelpPortalLink = (
  baseUrl: string,
  topic = '',
  type = HelpPortalLinkType.Home,
) => {
  const found = HelpPortalLinks[type].find(link => link.topic === topic);

  if (found) {
    return `${baseUrl}${found.link}`;
  }

  if (topic !== type) {
    return getHelpPortalLink(baseUrl, type, type);
  }

  const home = HelpPortalLinkType.Home;

  return getHelpPortalLink(baseUrl, home, home);
};

export const useHelpPortalLink = (
  topic?: string,
  type?: HelpPortalLinkType,
) => {
  const baseUrl = `${window.location.origin}/documentation/`;

  return React.useMemo(
    () => getHelpPortalLink(baseUrl, topic, type),
    [baseUrl, topic, type],
  );
};
