import { useGetUserCurrentUniversalFilters } from '@apollo-red/hooks';
import {
  InitFiltersProp,
  parseFilters,
} from '@components/tableau/TableauViewBase';
import { useFeatureFlag } from '@utils/launchDarkly';

export const usePropsUniversalFilters = ({
  isBasic,
  isGeographic,
  initialFilters,
}: {
  isBasic: boolean;
  isGeographic: boolean;
  initialFilters?: InitFiltersProp;
}) => {
  const stripInitialFilters = useFeatureFlag(
    'dashboardsFiltersStripInitialFilters',
  );
  const { universalFilters } = useGetUserCurrentUniversalFilters();

  const {
    currency,
    unit_of_measurement,
    countries,
    states,
    cities,
    properties,
  } = universalFilters;

  const basic = {
    p_area: unit_of_measurement ? [unit_of_measurement] : [],
    p_currency: currency ? [currency] : [],
  };

  const geographic = {
    Country: countries.filters.map(f => f.filter_value),
    'State/Province': states.filters.map(f => f.filter_value),
    City: cities.filters.map(f => f.filter_value),
    'Property Name': properties.filters.map(f => f.filter_value),
  };

  let filters = isBasic ? { ...initialFilters, ...basic } : initialFilters;

  filters = isGeographic ? { ...filters, ...geographic } : filters;

  const parsedFilters = parseFilters(filters ?? {}, stripInitialFilters);

  return Object.entries(parsedFilters).reduce((prev, curr, index, arr) => {
    const [filterKey, filters] = curr;
    const values = filters?.join(',');
    const result = `${prev}${filterKey}=${values}`;

    if (index < arr.length - 1) {
      return `${result}&`;
    }

    return result;
  }, '');
};
