import { useMemo } from 'react';
import { DocumentationRouteProps } from '@pages/documentation/types';
import { getEncodedLink } from '@utils/router';
import { getCommonRoute } from '../utils';

export const useDocumentationRoutes = () =>
  useMemo(() => {
    const link = '/documentation';

    return {
      subtopic: {
        path: `${link}/:category/:topic/:subtopic`,
        getLink: (category: string, topic: string, subtopic: string) =>
          getEncodedLink([link, category, topic, subtopic]),
      },
      topic: {
        path: `${link}/:category/:topic`,
        getLink: (category: string, topic: string) =>
          getEncodedLink([link, category, topic]),
      },
      category: {
        path: `${link}/:category`,
        getLink: (category: string) => getEncodedLink([link, category]),
      },
      home: getCommonRoute(link, 'home'),
    } as DocumentationRouteProps;
  }, []);
