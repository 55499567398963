import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  TextField,
  AutocompleteOption,
  Form,
  FormAutocomplete,
} from '@jll-labs/azara-ui-components';
import {
  useGetUserInfo,
  useGetAllCountries,
  useUpdateUserCommPhone,
  useUpdateUserCountryAndRegion,
} from '@apollo-red/hooks';
import { usePhoneNumberWithValidation } from '@utils/contact';

const noOp = () => {};

interface FormValues {
  country?: AutocompleteOption;
}

const initialValues = (country?: AutocompleteOption): FormValues => ({
  country: country,
});

interface Props {
  disabled?: boolean;
}

const PersonalDetails: React.FC<Props> = ({ disabled }) => {
  const { user } = useGetUserInfo();
  const { countries } = useGetAllCountries();
  const { t } = useTranslation();
  const [phone, setPhone, isPhoneValid] = usePhoneNumberWithValidation(
    user.user_comm.work ?? '',
  );
  const { updateUserPhone } = useUpdateUserCommPhone();
  const { updateCountryAndRegion } = useUpdateUserCountryAndRegion();

  const handleCountryChange = (values: FormValues) => {
    if (values.country) {
      const selectedCountry = (countries ?? []).find(
        country => country.id === values.country?.value,
      );
      if (selectedCountry) {
        updateCountryAndRegion(selectedCountry);
      }
    }
  };

  const handlePhoneInput = event => {
    setPhone(event.currentTarget.value);
  };

  const handleBlur = () => {
    if (isPhoneValid) {
      updateUserPhone(phone);
    }
  };

  const region = user.user_address.region
    ? user.user_address.region.region_name
    : '';

  const countryOptions = countries.map(country => ({
    label: country.country_desc,
    value: country.country_iso_code,
  }));

  const selectedCountry = countryOptions.find(
    opt => opt.value === user.user_address.country?.id,
  );

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={3}>
        <TextField
          label={t('email')}
          onChange={noOp}
          value={user.user_email}
          disabled={true}
        />
      </Grid>
      <Grid item={true} xs={3}>
        <TextField
          label={t('phone')}
          onChange={handlePhoneInput}
          onBlur={handleBlur}
          value={phone}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={3}>
        <Form
          initialValues={initialValues(selectedCountry)}
          onSubmit={() => Promise.resolve()}
          onValueChange={handleCountryChange}
        >
          <FormAutocomplete
            name="country"
            label={t('country')}
            options={countryOptions}
            disabled={disabled}
          />
        </Form>
      </Grid>
      <Grid item={true} xs={3}>
        {region && (
          <TextField
            label={t('region')}
            onChange={noOp}
            value={region}
            disabled={true}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
