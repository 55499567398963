import { useFeatureFlag } from '@utils/launchDarkly';
import { TenantAlertSettingsType } from '@apollo-red/hooks';

export const filterAlertTypes = (
  alertType: TenantAlertSettingsType,
  launchDarklySettings: {
    [key in Extract<
      TenantAlertSettingsType,
      | TenantAlertSettingsType.OccupancyEfficiency
      | TenantAlertSettingsType.SubleaseRisk
    >]: boolean;
  },
) => {
  if (launchDarklySettings[alertType] === undefined) {
    return true;
  }

  return launchDarklySettings[alertType];
};

export const useLaunchDarklyTenantAlertSettings = () => {
  const insightsAlertsOccupancyEfficiency = useFeatureFlag(
    'insightsAlertsOccupancyEfficiency',
  );

  const insightsAlertsSubleaseRisk = useFeatureFlag(
    'insightsAlertsSubleaseRisk',
  );

  const insightsAlertsSameDayWorkOrderCompletion = useFeatureFlag(
    'insightsAlertsSameDayWorkOrderCompletion',
  );

  return {
    [TenantAlertSettingsType.OccupancyEfficiency]:
      insightsAlertsOccupancyEfficiency,
    [TenantAlertSettingsType.SubleaseRisk]: insightsAlertsSubleaseRisk,
    [TenantAlertSettingsType.SameDayWorkOrderCompletion]:
      insightsAlertsSameDayWorkOrderCompletion,
  };
};
