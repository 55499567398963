import { gql } from '@apollo/client';
import { KeywordBase } from '@apollo-red/fragments';

export const GET_KEYWORDS = gql`
  query getKeywords {
    getKeywords {
      ...KeywordBase
    }
  }
  ${KeywordBase.fragment}
`;
