import {
  FLOATING_CONTENT_FULL_WIDTH,
  FLOATING_CONTENT_FULL_WIDTH_WITH_SIDEBAR,
  FLOATING_CONTENT_NO_WIDTH,
  VIZ_CONTENT_WITH_FLOATING_PANEL,
  VIZ_CONTENT_WITHOUT_FLOATING_PANEL,
} from '../../constants';
import { Offset, VizAndFloatingAreaOffset } from '../../types';

export const calculateContentOffset = (
  { width, height }: Offset,
  showFiltersPanel: boolean,
  isSidebarExpanded: boolean,
): VizAndFloatingAreaOffset => {
  const vizContentWidth = Math.floor(
    (width *
      (showFiltersPanel
        ? VIZ_CONTENT_WITH_FLOATING_PANEL
        : VIZ_CONTENT_WITHOUT_FLOATING_PANEL)) /
      100,
  );
  const floatingContentWidth = Math.floor(
    (width *
      (showFiltersPanel
        ? isSidebarExpanded
          ? FLOATING_CONTENT_FULL_WIDTH_WITH_SIDEBAR
          : FLOATING_CONTENT_FULL_WIDTH
        : FLOATING_CONTENT_NO_WIDTH)) /
      100,
  );

  return {
    vizContent: { width: vizContentWidth, height },
    floatingContent: {
      width: floatingContentWidth,
      height,
    },
  };
};
