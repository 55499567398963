import * as React from 'react';
import { Icon, TableContextMenu } from '@jll-labs/azara-ui-components';
import { InsightsAlertImportance, InsightsAlertType } from '@apollo-red/hooks';
import { Box } from '@mui/material';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { ColorPicker } from '../utils';
import { useAlertTableRowStyles } from '../alertTableRows/useAlertTableRowStyles';

export const importanceOptionsConfig = [
  { value: InsightsAlertImportance.High, label: 'High', icon: true },
  { value: InsightsAlertImportance.Medium, label: 'Medium', icon: true },
  { value: InsightsAlertImportance.Low, label: 'Low', icon: true },
  {
    value: InsightsAlertImportance.Unassigned,
    label: 'Unassigned',
    icon: true,
  },
];

interface Props {
  threadId: string;
  userImportance: InsightsAlertImportance;
  alertType: InsightsAlertType;
  handleImportanceChange: (
    threadId: string,
    importance: InsightsAlertImportance,
  ) => void;
}

const AlertImportanceFilterCell: React.FC<Props> = ({
  threadId,
  userImportance,
  handleImportanceChange,
  alertType,
}) => {
  const styles = useAlertTableRowStyles();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  const importantFilterButtons = importanceOptionsConfig.map(im => {
    return {
      label: im.label,
      icon: (
        <Icon
          name="StarRounded"
          fontSize="inherit"
          style={{ color: ColorPicker[im.value] }}
        />
      ),
      onClick: () => {
        trackInsightsAlertEvent('alertSetImportance', {
          alertThreadId: threadId ?? '',
          alertType: alertType ?? '',
        });

        handleImportanceChange(threadId, im.value);
      },
    };
  });

  const handlePropagation = e => {
    e.stopPropagation();
  };

  return (
    <Box
      onClick={handlePropagation}
      sx={[styles.unReadContainer, styles.iconContainer]}
      style={{ textAlign: 'center' }}
    >
      <TableContextMenu
        key="importanceFilter"
        id={threadId}
        buttons={importantFilterButtons}
        iconComponent={
          <Icon
            fontSize="small"
            name="StarRounded"
            style={{
              fill: ColorPicker[userImportance ?? 'none'],
            }}
          />
        }
      ></TableContextMenu>
    </Box>
  );
};

export default AlertImportanceFilterCell;
