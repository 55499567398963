declare global {
  interface Window {
    // extending Window interface with tableau property
    // it would be great if we could type tableau in future
    tableau: any;
  }
}

export enum TableauScalingBehaviors {
  AUTOMATIC = 'automatic',
  EXACTLY = 'exactly',
  RANGE = 'range',
  ATLEAST = 'atleast',
  ATMOST = 'atmost',
}

export enum TableauDevice {
  desktop = 'desktop',
}

export interface CustomViewTableau {
  getName: () => string;
  getDefault: () => boolean;
  getOwnerName: () => string;
  getUrl: () => string;
}

export interface SheetSize {
  width: number;
  height: number;
}

export interface Size {
  behavior: TableauScalingBehaviors;
  maxSize: SheetSize;
  minSize: SheetSize;
}

export interface VizSize {
  sheetSize: Size;
}

export enum SheetType {
  WORKSHEET = 'worksheet',
  DASHBOARD = 'dashboard',
  STORY = 'story',
}

export interface VizResizeEvent {
  getViz: () => Viz;
  getVizSize: () => VizSize;
}

// For more events, see the Tableau JavaScript API documentation here:
// https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#tableaueventname_enum

export enum TableauEventName {
  MARKS_SELECTION = 'marksselection',
  CUSTOM_VIEW_LOAD = 'customviewload',
  URL_ACTION = 'urlaction',
  FILTER_CHANGE = 'filterchange',
  PARAMETER_VALUE_CHANGE = 'parametervaluechange',
  STORY_POINT_SWITCH = 'storypointswitch',
}

export interface Pair {
  fieldName: string;
  value: object;
}

export interface TableauEvent {
  getViz: () => Viz;
  getWorksheet: () => Worksheet;
  getEventName: () => TableauEventName;
  getUrl: () => string;
  getFilterAsync: () => Promise<ConcreteFilter>;
  getFieldName: () => string;
}

export interface Mark {
  getPairs: () => Pair[];
}

export interface MarksEvent extends TableauEvent {
  getMarksAsync: () => Promise<Mark[]>;
}

export type HandleFirstVizSizeKnown = (viz: Viz) => void;
export type HandleFirstInteractive = (viz: Viz) => void;
export type HandleVizDispose = (viz: Viz) => void;

export type AddVizEvent = <TEvent extends TableauEvent>(
  eventName: TableauEventName,
  handler: (evt: TEvent) => void,
) => void;

export interface DataTable {
  getData: () => any;
}

export interface Sheet {
  getName: () => string;
  getIsActive: () => boolean;
  getSheetType: () => SheetType;
  getFiltersAsync: () => Promise<ConcreteFilter[]>;
  applyFilterAsync: (
    fieldName: string,
    values: string[] | string,
    updateType: FilterUpdateType,
    options?: FilterOptions,
  ) => Promise<string>;
}

export interface Dashboard extends Sheet {
  getWorksheets: () => Worksheet[];
  getSheetType: () => SheetType.DASHBOARD;
}

export interface Worksheet extends Sheet {
  getSummaryDataAsync: () => Promise<DataTable>;
  getDataSourcesAsync: () => Promise<any>;
  applyFilterAsync(
    fieldName: string,
    values: string[] | string,
    updateType: FilterUpdateType,
    options?: FilterOptions,
  ): Promise<string>;
  applyRangeFilterAsync(
    fieldName: string,
    range: RangeFilterOptions,
  ): Promise<string>;
  applyRelativeDateFilterAsync(
    fieldName: string,
    options: RelativeDateFilterOptions,
  ): Promise<string>;
  applyHierarchicalFilterAsync(
    fieldName: string,
    values: object,
    options: any,
  ): Promise<string>;
  clearFilterAsync(fieldName: string): Promise<string>;
  clearSelectedMarksAsync: () => Promise<void>;
  getSelectedMarksAsync: () => Promise<Mark[]>;
}

export enum ParameterAllowableValuesType {
  ALL = 'all',
  LIST = 'list',
  RANGE = 'range',
}

export enum PeriodType {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  MONTH = 'month',
  QUARTER = 'quarter',
  SECOND = 'second',
  WEEK = 'week',
  YEAR = 'year',
}

export enum ParameterDataType {
  FLOAT = 'float',
  INTEGER = 'integer',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
}

export interface Parameter {
  getName: () => string;
  getCurrentValue: () => DataValue;
  getAllowableValues: () => DataValue[];
  getAllowableValuesType: () => ParameterAllowableValuesType;
  getDataType: () => ParameterDataType;
  getMinValue: () => DataValue;
  getMaxValue: () => DataValue;
  getStepSize: () => number | undefined | null;
  getDateStepPeriod: () => PeriodType | undefined | null;
}

export interface FlattenedParameter {
  name: string;
  selectedValue: DataValue;
  options: DataValue[];
  allowableType: ParameterAllowableValuesType;
  dataType: ParameterDataType;
  minValue?: DataValue;
  maxValue?: DataValue;
  stepSize?: number | undefined | null;
  dateStepPeriod?: PeriodType | undefined | null;
}

export interface Workbook {
  getName: () => string;
  activateSheetAsync: (id: string) => Sheet;
  getActiveSheet: <ActiveSheet extends Sheet>() => ActiveSheet;
  getPublishedSheetsInfo: () => Sheet[];
  getActiveCustomView: () => CustomViewTableau;
  getCustomViewsAsync: () => Promise<CustomViewTableau[]>;
  showCustomViewAsync: (customViewName: string) => Promise<void>;
  rememberCustomViewAsync: (
    customViewName: string,
  ) => Promise<CustomViewTableau>;
  setActiveCustomViewAsDefaultAsync: () => Promise<void>;
  removeCustomViewAsync: (customViewName: string) => Promise<CustomViewTableau>;
  getParametersAsync: () => Promise<Parameter[]>;
  changeParameterValueAsync: (
    name: string,
    value: ParameterValueType,
  ) => Promise<Parameter>;
}

export interface Viz {
  getName: () => string;
  getParentElement: () => HTMLElement;
  getVizSize: () => VizSize;
  addEventListener: AddVizEvent;
  removeEventListener: AddVizEvent;
  getWorkbook: () => Workbook;
  dispose: () => void;
  refreshDataAsync: () => void;
  revertAllAsync: () => void;
  show: () => void;
  exportCrossTabToExcel: (worksheetInDashboard?: Sheet | string) => void;
  showDownloadWorkbookDialog: () => void;
  showExportCrossTabDialog: (worksheetInDashboard?: Sheet | string) => void;
  showExportImageDialog: () => void;
  showExportPDFDialog: () => void;
  showShareDialog: () => void;
  setFrameSize: (width: number, height: number) => void;
}

export interface Filter {
  getWorksheet(): Worksheet;
  getFilterType(): FilterType;
  getFieldName(): string;
  getFieldAsync(): Promise<Field>;
}

export enum FilterUpdateType {
  ALL = 'all',
  REPLACE = 'replace',
  ADD = 'add',
  REMOVE = 'remove',
}

export enum FilterType {
  CATEGORICAL = 'categorical',
  HIERARCHICAL = 'hierarchical',
  QUANTITATIVE = 'quantitative',
  RELATIVEDATE = 'relativedate',
}

export enum FilterInputType {
  SELECT = 'SELECT',
  LIST = 'LIST',
}

export interface DataSource {
  getName(): string;
  getIsPrimary(): boolean;
  getFields(): Field[];
}

export type FieldAggregationType =
  | 'SUM'
  | 'AVG'
  | 'MIN'
  | 'MAX'
  | 'STDEV'
  | 'STDEVP'
  | 'VAR'
  | 'VARP'
  | 'COUNT'
  | 'COUNTD'
  | 'MEDIAN'
  | 'ATTR'
  | 'NONE'
  | 'PERCENTILE'
  | 'TRUNC_YEAR'
  | 'TRUNC_QTR'
  | 'TRUNC_MONTH'
  | 'TRUNC_WEEK'
  | 'TRUNC_DAY'
  | 'TRUNC_HOUR'
  | 'TRUNC_MINUTE'
  | 'TRUNC_SECOND'
  | 'QUART1'
  | 'QUART3'
  | 'SKEWNESS'
  | 'KURTOSIS'
  | 'INOUT'
  | 'SUM_XSQR'
  | 'USER';

export interface Field {
  getName: () => string;
  getAggregation: () => FieldAggregationType;
  getDataSource: () => DataSource;
  getRole: () => FieldRoleType;
}

export interface DataValue {
  value: ParameterValueType;
  formattedValue: string;
  isPreselectedUniversalFilterValue?: boolean;
}

export enum FieldRoleType {
  DIMENSION = 'dimension',
  MEASURE = 'measure',
  UNKNOWN = 'unknown',
}

export interface FilterOptions {
  isExcludeMode: boolean;
}

export interface RangeFilterOptions {
  min?: number | Date;
  max?: number | Date;
  nullOption: NullOption;
}

export interface RelativeDateFilterOptions {
  anchorDate: Date;
  periodType: PeriodType;
  rangeType: DateRangeType;
  rangeN: number;
}

export enum NullOption {
  NULL_VALUES = 'nullValues',
  NON_NULL_VALUES = 'nonNullValues',
  ALL_VALUES = 'allValues',
}

export enum PeriodType {
  YEARS = 'years',
  QUARTERS = 'quarters',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export enum DateRangeType {
  LAST = 'last',
  LASTN = 'lastn',
  NEXT = 'next',
  NEXTN = 'nextn',
  CURRENT = 'current',
  TODATE = 'todate',
}

export interface CategoricalFilter extends Filter {
  getIsExcludeMode(): boolean;
  getAppliedValues(): DataValue[];
  getIsAllSelected(): boolean;
}

export interface QuantitativeFilter extends Filter {
  getDomainMin(): QuantitativeDataValue;
  getDomainMax(): QuantitativeDataValue;
  getMin(): QuantitativeDataValue;
  getMax(): QuantitativeDataValue;
  getIncludeNullValues(): boolean;
}

interface RelativeDateFilter extends Filter {
  getPeriod(): PeriodType;
  getRange(): DateRangeType;
  getRangeN(): number;
}

export type ConcreteFilter = CategoricalFilter &
  QuantitativeFilter &
  RelativeDateFilter;

export interface QuantitativeDataValue {
  value: Date | number;
  formattedValue: string;
}

export interface FlattenedFilter {
  name: string;
  filterType: FilterType;
  appliedValues?: DataValue[];
  isExcludeMode?: boolean;
  domainMax?: QuantitativeDataValue;
  domainMin?: QuantitativeDataValue;
  min?: DataValue;
  max?: DataValue;
  includeNullValues?: boolean;
  period?: PeriodType;
  range?: DateRangeType;
  rangeN?: number;
  staticData?: DataValue[];
  isUniversalFilter?: boolean;
  isAllSelected?: boolean;
}

export type ParameterValueType = number | string | boolean | Date;
