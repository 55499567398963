import * as React from 'react';
import { ReportExtended } from '@apollo-red/hooks';
import { FullReportEventTrackingType } from '@components/fullReport';
import { usePendoTrackDashboardEvent } from '@utils/pendo';

export const useHandleExportReportOnLoad = (report?: ReportExtended) => {
  const [loading, setLoading] = React.useState(true);
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();
  const handleOnLoad = React.useCallback(() => {
    setLoading(false);

    // FIXME: Calculate actual dashboardViewTime and track dashboardLoad event.
    report &&
      trackDashboardEvent('dashboardView', {
        dashboardId: report.id,
        dashboardName: report.report_name,
        dashboardType: FullReportEventTrackingType.CustomExternal,
        dashboardCategory: report.primaryCategory,
        dashboardOriginalCategory: report.originalCategory,
        dashboardUrl: report.report_external_url ?? '',
        dashboardViewTime: 0,
      });
  }, [report, trackDashboardEvent]);

  return { loading, handleOnLoad };
};
