import * as React from 'react';
import NoData from '@components/noData';

export const isTestEnv = () => {
  const { NODE_ENV, ENVIRONMENT_NAME } = process.env;
  return (
    NODE_ENV === 'development' ||
    ENVIRONMENT_NAME === 'DEV' ||
    ENVIRONMENT_NAME === 'TEST'
  );
};

const TestFeature: React.FC = ({ children }) => {
  return isTestEnv() ? <>{children}</> : <NoData message="noAccess" />;
};

export default TestFeature;
