import * as React from 'react';
import { MenuSelect } from '@jll-labs/azara-ui-components';
import { useGetUserInfo } from '@apollo-red/hooks';
import { Box, useTheme } from '@mui/material';
import ClientSelectOption from './ClientSelectOption';
import { useClientSelectWithPageReload } from './useClientSelectWithPageReload';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    singleTenant: {
      fontSize: theme.typography.h6.fontSize,
    },
  };
};

const ClientSelect: React.FC = () => {
  const styles = useStyles();
  const { user } = useGetUserInfo();

  // FIXME: The entire page is reloaded due to UI performance issues.
  //        After resolving the issues, use useClientSelect instead.
  const { handleClick, loadingTenant } = useClientSelectWithPageReload(user);

  const displayedTenants = user.user_tenant_settings
    .map(settings => ({
      id: settings.user_tenant.id,
      tenant_name: settings.user_tenant.tenant_name,
    }))
    .sort((a, b) => a.tenant_name.localeCompare(b.tenant_name));

  if (displayedTenants.length === 0) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      {displayedTenants.length === 1 ? (
        <Box sx={styles.singleTenant}>{displayedTenants[0].tenant_name}</Box>
      ) : (
        <MenuSelect
          value={user.user_settings.default_tenant?.id ?? null}
          onChange={handleClick}
          label={user.user_settings.default_tenant?.tenant_name ?? ''}
          closeOnSelect={false}
          disableSelectorOptions={!!loadingTenant}
          options={displayedTenants.map(tenant => ({
            value: tenant.id,
            label: (
              <ClientSelectOption
                tenantName={tenant.tenant_name}
                loading={tenant.id === loadingTenant}
              />
            ),
          }))}
        />
      )}
    </Box>
  );
};

export default ClientSelect;
