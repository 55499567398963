import * as React from 'react';
import { Loader } from '@jll-labs/azara-ui-components';
import { default as ExternalReportToolbar } from './ExternalReportToolbar';
import { useStyles } from './styles';
import { ExternalReportProps } from './types';
import { useHandleExportReportOnLoad } from './useHandleExternalReportOnLoad';
import { useHandleExternalReportResize } from './useHandleExternalReportResize';

const ExternalStandardReport: React.FC<ExternalReportProps> = ({ report }) => {
  const classes = useStyles();
  const { loading, handleOnLoad } = useHandleExportReportOnLoad(report);
  const { ref, width } = useHandleExternalReportResize();
  return (
    <div>
      <ExternalReportToolbar report={report} />
      <div className={classes.frame} ref={ref}>
        {loading && (
          <div className={classes.loadingIndicator}>
            <Loader />
          </div>
        )}
        <iframe
          src={report.report_external_url ?? ''}
          style={{
            border: 'none',
            width: width,
            height: loading ? 0 : '700px',
          }}
          id="external-frame"
          onLoad={handleOnLoad}
        />
      </div>
    </div>
  );
};

export default ExternalStandardReport;
