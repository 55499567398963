import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { AppTheme } from '@theme';

interface Props {
  title: React.ReactNode;
  rightSideContent?: React.ReactNode;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  title: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.h5.fontSize,
  },
}));

const AlertHeader: React.FC<Props> = ({ title, rightSideContent }) => {
  const classes = useStyles();
  return (
    <Grid container={true} alignItems="center" justifyContent="space-between">
      <div className={classes.title}>{title}</div>
      <Grid item={true}>{rightSideContent}</Grid>
    </Grid>
  );
};

export default AlertHeader;
