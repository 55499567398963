import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { InsightsAlert } from '@apollo-red/hooks';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_USER_ASSIGNED } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';

export const useSetInsightsInboxAlertAssignedToFilter = (
  onResetInbox: () => void,
  onDeselectAlert: () => void,
  selectedAlert?: InsightsAlert,
) => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const [setInsightsInboxAlertUserAssignedTo] = useAppMutation<
    SchemaTypes.SetInsightsInboxAlertUserAssignedToMutation,
    SchemaTypes.SetInsightsInboxAlertUserAssignedToMutationVariables
  >(SET_INSIGHTS_INBOX_USER_ASSIGNED);

  return {
    setInsightsInboxAlertUserAssignedTo: (userAssignedTo: string) => {
      onResetInbox();
      setInsightsInboxAlertUserAssignedTo({
        variables: {
          userAssigned: userAssignedTo,
          userId: user.id,
          tenantId,
        },
      });

      if (userAssignedTo === 'all') {
        return;
      }

      const { assigned_user_id: selected } = selectedAlert ?? {};

      if (!selected) {
        return;
      }

      if (selected !== userAssignedTo) {
        onDeselectAlert();
      }
    },
  };
};
