import * as React from 'react';
import { LogReportType } from '@apollo-red/hooks';
import Suspense from '@components/asyncLoader';
import { Viz } from '@components/tableau/types';
import { TableauLoaderStyle } from '@components/tableau';
import TableauLoader from '@components/tableau/TableauLoader';
import TableauViewCached from '@components/tableau/TableauViewCached';
import { useTableau, useTableauDataAvailability } from '@utils/tableau';
import { InitFiltersProp } from '@components/tableau/TableauViewBase';
import TableauView, {
  ApplyUniversalFiltersProps,
} from '@components/tableau/TableauView';
import TableauTimeout from '@components/tableau/TableauTimeout';
import { useParamsCustom } from '@utils/router';
import { useFullReportWithFilters } from './withFilters/FullReportWithFiltersProvider';
import { LastUpdateDate } from './lastUpdateDate';
import NoDataReport from './NoDataReport';

const afterLoadHashCommands = {
  print: (viz: Viz) => viz.showExportPDFDialog(),
  share: (viz: Viz) => viz.showShareDialog(),
};

interface Props {
  reportId: string;
  reportUrl: string;
  reportName: string;
  reportCategory: string;
  reportType?: LogReportType;
  setViz?: (viz?: Viz) => void;
  applyUniversalFilters?: ApplyUniversalFiltersProps;
  initialFilters?: InitFiltersProp;
  showFiltersPanel?: boolean;
  withFilters?: boolean;
  isExternalReport?: boolean;
  contentHeight?: number;
  contentWidth?: number;
  originalReportId?: string;
  showLastUpdateDate?: boolean;
}

interface MatchParams {
  command?: string;
}

const FullReportViz: React.FC<Props> = ({
  reportId,
  reportUrl,
  reportName,
  reportCategory,
  reportType = LogReportType.FullReport,
  setViz,
  applyUniversalFilters,
  showFiltersPanel = false,
  withFilters = false,
  isExternalReport = false,
  contentHeight = 0,
  contentWidth = 0,
  initialFilters,
  originalReportId,
  showLastUpdateDate = true,
}) => {
  const { command } = useParamsCustom<MatchParams>();
  const { initialPinnedValues, isSidebarExpanded } = useFullReportWithFilters();
  const {
    setRefElementCb,
    refElement,
    refElementOffset,
    handleLoadComplete,
    vizLoading,
    viz,
    handleResize,
  } = useTableau();
  const { isDataLoading, isDataAvailable } = useTableauDataAvailability(viz);
  const hasNoData = !vizLoading && !isDataLoading && !isDataAvailable;

  React.useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarExpanded]);

  React.useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFiltersPanel]);

  React.useEffect(() => {
    if (viz && command && afterLoadHashCommands[command]) {
      afterLoadHashCommands[command](viz);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viz]);

  React.useEffect(() => {
    if (setViz) {
      if (hasNoData) {
        setViz(undefined);
      } else {
        setViz(viz);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viz, hasNoData]);

  const height = withFilters ? `${contentHeight}px` : '750px';
  const loaderHeight = '500px';

  if (hasNoData) {
    return <NoDataReport />;
  }

  return (
    <div ref={setRefElementCb} style={{ height }}>
      {refElement && (
        <TableauTimeout
          viz={viz}
          id={reportId}
          height={loaderHeight}
          isExternalReport={isExternalReport}
          isSidebarExpanded={isSidebarExpanded}
        >
          <TableauLoader
            loading={vizLoading}
            loaderStyles={{ height: loaderHeight }}
            loader={TableauLoaderStyle.Circular}
          >
            {withFilters ? (
              <TableauView
                reportLogContext={{
                  reportId,
                  reportType,
                  reportName,
                  reportCategory,
                  originalReportId,
                }}
                tableauContainerStyle={{
                  height: viz ? height : 0,
                  overflowX: 'auto',
                  marginTop: '1px',
                }}
                url={reportUrl}
                initialFilters={initialFilters}
                applyUniversalFilters={applyUniversalFilters}
                handleLoadComplete={handleLoadComplete}
                handleFirstInteractive={handleLoadComplete}
                initialPinnedFiltersAndParameters={initialPinnedValues}
                height={height}
                isExternalReport={isExternalReport}
                width={`${contentWidth}px`}
              />
            ) : (
              <TableauViewCached
                cacheId={reportId}
                url={reportUrl}
                tableauContainerStyle={{
                  height,
                  overflowX: 'auto',
                  marginTop: '1px',
                }}
                applyUniversalFilters={applyUniversalFilters}
                container="pageContainerContent"
                initialFilters={initialFilters}
                offset={refElementOffset}
                handleLoadComplete={handleLoadComplete}
                handleFirstInteractive={handleLoadComplete}
                reportLogContext={{
                  reportType,
                  reportId,
                  reportName,
                  reportCategory,
                  originalReportId,
                }}
                initialPinnedFiltersAndParameters={initialPinnedValues}
                isExternalReport={isExternalReport}
              />
            )}
            <Suspense fallback={null}>
              <LastUpdateDate
                reportId={originalReportId ?? reportId}
                hidden={!showLastUpdateDate}
              />
            </Suspense>
          </TableauLoader>
        </TableauTimeout>
      )}
    </div>
  );
};

export default FullReportViz;
