import makeStyles from '@mui/styles/makeStyles';
import { FallbackThumbnail } from '@assets';

const THUMBNAIL_ASPECT_RATIO = 2.23;
const DASHBOARD_ASPECT_RATIO = 2.01;

interface ThumbnailWrapperStylesProps {
  forFullDashboard?: boolean;
}

export const useThumbnailWrapperStyles = makeStyles(() => ({
  wrapper: (props: ThumbnailWrapperStylesProps) => ({
    paddingTop: props.forFullDashboard
      ? `calc(1 / ${DASHBOARD_ASPECT_RATIO} * 60%)`
      : `calc(1 / ${THUMBNAIL_ASPECT_RATIO} * 100%)`,
    position: 'relative',
  }),
}));

export const useThumbnailStyles = makeStyles(() => ({
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: 'auto',
  },
  fallbackThumbnail: {
    backgroundImage: `url(${FallbackThumbnail})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}));
