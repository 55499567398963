import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { SelectOption } from '@jll-labs/azara-ui-components';
import ToolbarButtonGroup from './ToolbarButtonGroup';
import ToolbarMenuOption from './ToolbarMenuOption';
import { ToolbarButton } from './types';

interface Props {
  buttons: ToolbarButton[];
  disabled?: boolean;
  helpItem: React.ReactNode;
}

const ToolbarItemsWithMenu: React.FC<Props> = ({
  buttons,
  disabled = false,
  helpItem,
}) => {
  const { t } = useTranslation();

  if (!buttons || buttons.length === 0) {
    return <>{helpItem}</>;
  }

  const components = buttons.map(({ component }) => component);
  const otherOptions: SelectOption[] = buttons.map(
    ({ icon, name, onClick }) => ({
      label: <ToolbarMenuOption label={t(name)} icon={icon} />,
      value: name,
      onClick,
    }),
  );

  return (
    <>
      <Box component="span" mr={2} mb={2} display="inline-block">
        <ToolbarButtonGroup
          key="menu"
          id="dashboard-toolbar-menu"
          icon="Menu"
          name={t('Menu')}
          disabled={disabled}
          size="md"
          iconButton={false}
          options={otherOptions}
          tooltip={t('menu')}
        />
        {components}
      </Box>
      {helpItem}
    </>
  );
};

export default ToolbarItemsWithMenu;
