import { useMemo } from 'react';
import { getEncodedLink } from '@utils/router';
import { UseClientRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useProductSettingsRoutes = ({
  productSettings: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      applications: getCommonRoute(link, 'applications'),
      tenants: getCommonRoute(link, 'clients'),
      tenantSettings: {
        path: `${link}/clients/:tenantOvcIdTenantProductSettings`,
        getLink: (tenantOvcId: string) =>
          getEncodedLink([link, 'clients', tenantOvcId]),
      },
      roles: getCommonRoute(link, 'roles'),
      userClassification: getCommonRoute(link, 'user-classification'),
      userClassificationDetails: {
        path: `${link}/user-classification/user-details/:userEmail`,
        getLink: (userEmail: string) =>
          getEncodedLink([
            link,
            'user-classification',
            'user-details',
            userEmail,
          ]),
      },
      keywords: getCommonRoute(link, 'keywords'),
      environmentSetup: getCommonRoute(link, 'environment-setup'),
    };
  }, [getLink]);
