import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoutes } from '@routes';
import Dashboard from './Dashboard';
import FavoriteDashboards from './FavoriteDashboards';
import AllDashboards from './AllDashboards';
import CategoryDashboards from './CategoryDashboards';
import { ActiveUserOnly } from '@components/featureToggle';

const Dashboards: React.FC = () => {
  const { PATDashboards } = useRoutes();

  return (
    <ActiveUserOnly>
      <Switch>
        <Route exact={true} path={PATDashboards.favorites.path}>
          <FavoriteDashboards />
        </Route>
        <Route exact={true} path={PATDashboards.all.path}>
          <AllDashboards />
        </Route>
        <Route exact={true} path={PATDashboards.category.path}>
          <CategoryDashboards />
        </Route>
        <Route exact={true} path={PATDashboards.dashboard.path}>
          <Dashboard />
        </Route>
        <Route exact={true} path={PATDashboards.home.path}>
          <Redirect to={PATDashboards.favorites.path} />
        </Route>
      </Switch>
    </ActiveUserOnly>
  );
};

export default Dashboards;
