import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_TENANT_DEFAULT_PROFILE } from '@apollo-red/mutations';
import { useGetUserInfo } from '../../user';
import { useGetUserDefaultTenantId } from './useGetUserDefaultTenantId';

export const useUpdateUserDefaultProfile = () => {
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();

  const [updateDefaultProfile] = useAppMutation<
    SchemaTypes.UpdateUserTenantDefaultProfileMutation,
    SchemaTypes.UpdateUserTenantDefaultProfileMutationVariables
  >(UPDATE_USER_TENANT_DEFAULT_PROFILE);

  return {
    updateDefaultProfile: React.useCallback(
      (userId: string | null) => {
        return updateDefaultProfile({
          variables: {
            where: {
              user_id: user.id,
              tenant_id: defaultTenantId,
            },
            data: {
              default_profile: userId,
            },
          },
        });
      },
      [defaultTenantId, updateDefaultProfile, user.id],
    ),
  };
};
