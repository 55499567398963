import { utils } from '@jll-labs/azara-ui-components';
import { RelativeDateFilterOptions, Viz } from '@components/tableau/types';
import { getApplicableWorksheets } from './getApplicableWorksheets';
import { useGetWorksheets } from './useGetWorksheets';

const { useToggleValue } = utils;

export const useApplyRelativeDateFilterAsync = (viz?: Viz) => {
  const [loading, toggleLoading] = useToggleValue(false);
  const { worksheets } = useGetWorksheets(viz);

  const applyRelativeDateFilterAsync = async (
    name,
    options: RelativeDateFilterOptions,
  ) => {
    if (!viz) {
      return;
    }

    try {
      toggleLoading();

      const applicableWorksheets = await getApplicableWorksheets(
        worksheets,
        name,
      );
      const applyFiltersPromises = applicableWorksheets?.map(ws =>
        ws.applyRelativeDateFilterAsync(name, options),
      );

      if (applyFiltersPromises) {
        await Promise.all(applyFiltersPromises);
      }
    } finally {
      toggleLoading();
    }
  };

  return { loading, applyRelativeDateFilterAsync };
};
