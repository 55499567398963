import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GENERATE_TRUSTED_REPORT_URL } from '@apollo-red/mutations';
import { useAppMutation } from '../queries';

export type TrustedReportUrl = SchemaTypes.TrustedReportUrl;

export const useGenerateTrustedReportUrl = () => {
  const [generateTrustedReportUrl] = useAppMutation<
    SchemaTypes.GenerateTrustedReportUrlMutation,
    SchemaTypes.GenerateTrustedReportUrlMutationVariables
  >(GENERATE_TRUSTED_REPORT_URL);

  return {
    generateTrustedReportUrl: (
      payload: SchemaTypes.WhereGenerateTrustedReportUrlInput,
    ) => {
      return generateTrustedReportUrl({
        variables: {
          where: {
            tenant_id: payload.tenant_id,
            report_id: payload.report_id,
            report_url: payload.report_url,
          },
        },
      });
    },
  };
};
