import { uniqBy } from 'lodash';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  GET_ALL_APPLICATIONS,
  GET_APPLICATIONS_SCHEMA,
} from '@apollo-red/queries';
import { ADD_APPLICATION, UPDATE_APPLICATION } from '@apollo-red/mutations';
import { useAppQuery, useAppMutation } from '../queries';
import { useGetActiveProvisionedTenantApplications } from '../tenants';
import { useGetUserDefaultTenantId } from '../user';

export type Application = SchemaTypes.ApplicationBaseFragment;
export type ApplicationSchema = SchemaTypes.ApplicationSchemaBaseFragment;
export type ViewSchema = SchemaTypes.ViewSchemaBaseFragment;
export type ApplicationExtended = Application & {
  app_schema?: ApplicationSchema | undefined | null;
};

export type ConfigurationDataApplicationsCode =
  | 'FIN'
  | 'MDM'
  | 'WKO'
  | 'MKT'
  | 'LSE'
  | 'SPC';

export const CONFIGURATION_DATA_APPLICATIONS: ConfigurationDataApplicationsCode[] =
  ['FIN', 'MDM', 'WKO', 'MKT', 'LSE', 'SPC'];

export const useGetAllApplications = () => {
  const { loading, data } =
    useAppQuery<SchemaTypes.GetApplicationsQuery>(GET_ALL_APPLICATIONS);

  return {
    loading,
    applications: data && data.getApplications ? data.getApplications : [],
  };
};

export const useAddApplication = () => {
  const [addApplication] = useAppMutation<
    SchemaTypes.AddApplicationMutation,
    SchemaTypes.AddApplicationMutationVariables
  >(ADD_APPLICATION, {
    refetchQueries: [
      {
        query: GET_ALL_APPLICATIONS,
      },
    ],
  });

  return {
    addApplication: (
      createApplicationFields: SchemaTypes.AddApplicationMutationVariables['data'],
    ) => {
      return addApplication({
        variables: {
          data: createApplicationFields,
        },
      });
    },
  };
};

export const useUpdateApplication = () => {
  const [updateApplication] = useAppMutation<
    SchemaTypes.UpdateApplicationMutation,
    SchemaTypes.UpdateApplicationMutationVariables
  >(UPDATE_APPLICATION, {
    refetchQueries: [
      {
        query: GET_ALL_APPLICATIONS,
      },
    ],
  });

  return {
    updateApplication: (
      id: string,
      updateApplicationFields: SchemaTypes.UpdateApplicationInput,
    ) => {
      return updateApplication({
        variables: {
          data: updateApplicationFields,
          where: {
            id,
          },
        },
      });
    },
  };
};

export const useGetApplicationsSchema = (tenantId: string, apps: string[]) => {
  const { data, loading, refetch } = useAppQuery<
    SchemaTypes.GetApplicationsSchemaQuery,
    SchemaTypes.GetApplicationsSchemaQueryVariables
  >(GET_APPLICATIONS_SCHEMA, {
    variables: {
      app_ids: apps,
      tenant_id: tenantId,
    },
  });

  return {
    loading,
    applications: data?.getApplications,
    refetch,
  };
};

export const useGetConfigurationDataApplications = () => {
  const tenantId = useGetUserDefaultTenantId();
  const { loading, applications } =
    useGetActiveProvisionedTenantApplications(tenantId);

  const configDataApplications = applications.filter(app =>
    CONFIGURATION_DATA_APPLICATIONS.some(cda => cda === app.app_cat),
  );

  const filteredApplications = uniqBy(
    configDataApplications,
    configDataApplication => configDataApplication.app_cat,
  );

  return {
    loading,
    applications: filteredApplications ?? [],
  };
};
