import { useMemo } from 'react';
import { UseClientRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useOnboardingRoutes = ({
  onBoarding: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      welcome: getCommonRoute(link, 'welcome'),
      roles: getCommonRoute(link, 'organizational-role'),
      scope: getCommonRoute(link, 'scope-by-location'),
      favorites: getCommonRoute(link, 'favorite-dashboards'),
      contact: getCommonRoute(link, 'contact-information'),
      summary: getCommonRoute(link, 'summary'),
    };
  }, [getLink]);
