import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { UPDATE_USER_AGREEMENT } from '@apollo-red/mutations';
import { useAppMutation } from '@apollo-red/hooks/queries';

export const useUpdateUserAgreement = () => {
  const [updateUserAgreement] = useAppMutation<
    SchemaTypes.UpdateUserAgreementMutation,
    SchemaTypes.UpdateUserAgreementMutationVariables
  >(UPDATE_USER_AGREEMENT);

  return {
    updateUserAgreement: (id: string, data: SchemaTypes.UserAgreementInput) => {
      return updateUserAgreement({
        variables: {
          where: { id },
          data,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUserAgreement: {
            id,
            user_agreement: {
              __typename: 'UserAgreement',
              terms_accepted: true,
              terms_accepted_at: `${new Date().getTime()}`,
            },
            __typename: 'User',
          },
        },
      });
    },
  };
};
