import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserReportById } from '@apollo-red/hooks';
import { useParamsCustom } from '@utils/router';
import { useRoutes } from '@routes';
import { BaseDashboardsPageWrapper } from '../baseDashboards';
import { getExternalReportRenderProp } from './getExternalReportRenderProp';
import { ExternalReportParams } from './types';

const ExternalReport: React.FC = () => {
  const routes = useRoutes();
  const { t } = useTranslation();
  const { externalReportId, categoryId } =
    useParamsCustom<ExternalReportParams>();
  const { report } = useGetUserReportById(externalReportId);

  return !report ? null : (
    <BaseDashboardsPageWrapper
      key={report.id}
      breadcrumbsTitle={t('externalReports')}
      breadcrumbsParts={[
        {
          label: 'External Reports',
          link: routes.dashboards.category.getLink(categoryId),
        },
        { label: report.report_name },
      ]}
      showKeyMetrics={false}
      showToolbar={false}
      title={report.report_name}
      autoOverflow={true}
    >
      {getExternalReportRenderProp(report)}
    </BaseDashboardsPageWrapper>
  );
};

export default ExternalReport;
