import {
  InsightsAlertType,
  InsightsAlertTypeFilter,
  InsightsAlertSeverity,
  InsightsAlertImportanceFilter,
  InsightsAlertImportance,
  InsightsAlertSeverityFilter,
} from '@apollo-red/hooks';
import { alertInsightsSeverityToAlertSeverityFilter } from './alertInsightsSeverityToAlertSeverityFilter';
import { alertImportanceToAlertImportanceFilter } from './alertImportanceToAlertImportanceFilter';
import { tenantAlertSettingsTypeToAlertTypeFilter } from './tenantAlertSettingsTypeToAlertTypeFilter';

export enum InsightsAlertFilterType {
  AlertType = 'alert_type',
  Importance = 'importance',
  Severity = 'severity',
}

export type InsightsAlertFilterTypeMap = {
  [InsightsAlertFilterType.AlertType]: InsightsAlertTypeFilter;
  [InsightsAlertFilterType.Importance]: InsightsAlertImportanceFilter;
  [InsightsAlertFilterType.Severity]: InsightsAlertSeverityFilter;
};

export type InsightsAlertFilterValueMap = {
  [InsightsAlertFilterType.AlertType]: InsightsAlertType;
  [InsightsAlertFilterType.Importance]: InsightsAlertImportance;
  [InsightsAlertFilterType.Severity]: InsightsAlertSeverity;
};

export type InsightsAlertFilterTypeMapping = {
  [InsightsAlertFilterType.AlertType]: Record<
    InsightsAlertType,
    InsightsAlertTypeFilter
  >;
  [InsightsAlertFilterType.Importance]: Record<
    InsightsAlertImportance,
    InsightsAlertImportanceFilter
  >;
  [InsightsAlertFilterType.Severity]: Record<
    InsightsAlertSeverity,
    InsightsAlertSeverityFilter
  >;
};

export type InsightsAlertFilterValueTypeMap = {
  [InsightsAlertFilterType.AlertType]: InsightsAlertTypeFilter;
  [InsightsAlertFilterType.Importance]: InsightsAlertImportanceFilter;
  [InsightsAlertFilterType.Severity]: InsightsAlertSeverityFilter;
};

export type InsightsAlertFilterValueMappingTypeMap = {
  [InsightsAlertFilterType.AlertType]: typeof tenantAlertSettingsTypeToAlertTypeFilter;
  [InsightsAlertFilterType.Importance]: typeof alertImportanceToAlertImportanceFilter;
  [InsightsAlertFilterType.Severity]: typeof alertInsightsSeverityToAlertSeverityFilter;
};

export interface CreateInsightsInboxFiltersChangeHandlerArgs<
  T extends InsightsAlertFilterType,
> {
  filterType: T;
  filterHandler: (filter: InsightsAlertFilterValueTypeMap[T]) => void;
  filterMapping: InsightsAlertFilterValueMappingTypeMap[T];
}

export type InsightsInboxFiltersChangeHandler<
  T extends InsightsAlertFilterType,
> = (selectedFilterValue: InsightsAlertFilterValueTypeMap[T]) => void;
