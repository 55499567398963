import { v4 as uuid } from 'uuid';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { utils } from '@jll-labs/azara-ui-components';
import { useGetUserInfo, User } from '../user';
import {
  Report,
  useAddTenantReport,
  useUpdateReport,
  ReportAnchorTarget,
  ExternalReportType,
} from '../../reports';
import {
  ADD_SAVED_VIEW_TO_USER,
  DELETE_SAVED_VIEW_FROM_USER,
  RESET_DEMO_USER,
} from '@apollo-red/mutations';
import { useGetUserTenant } from '../tenants';
import { buildUserReportList } from './helpers';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { filterItems } from '@utils/various';
import { useGetBlobStorageUploadSasUrl } from '@apollo-red/hooks/blobStorage';
import { useGetUserDefaultTenantId } from '../settings';

export interface ReportExtended extends Report {
  isFav: boolean;
  isHidden: boolean;
  isSavedView: boolean;
  isCustomReport: boolean;
  isExternalReport: boolean;
  isAlertReport: boolean;
  isAuthReport: boolean;
  isKpiReport: boolean;
  isPatReport: boolean;
  primaryCategory: string;
  originalCategory: string;
}

export interface Category {
  id: string;
  category_description: string;
  category_reports: ReportExtended[];
}

export const useGetUserTenantReports = (tenantId: string) => {
  const { loading, user } = useGetUserInfo();
  const { tenant } = useGetUserTenant(tenantId);
  const { allReports } = buildUserReportList(user, tenant);

  return {
    loading,
    allReports,
  };
};

export const useGetUserCurrentTenantReports = () => {
  const { loading, user } = useGetUserInfo();
  const {
    allReports,
    allSpecialReports,
    kpiDetailReports,
    authReport,
    alertReports,
    patReports,
  } = buildUserReportList(user, user.user_settings.default_tenant);

  return {
    loading,
    authReport,
    alertReports,
    allReports: allReports.filter(r => !r.isHidden),
    fullListOfReports: allReports,
    allSpecialReports,
    kpiDetailReports,
    patReports,
  };
};

export const useGetUserReportById = (reportId: string) => {
  const { loading, fullListOfReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    report: fullListOfReports.find(report => report.id === reportId),
  };
};

export const useGetUserSpecialReportById = (reportId: string) => {
  const { loading, allSpecialReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    report: allSpecialReports.find(report => report.id === reportId),
  };
};

export const useGetUserAlertReportById = (reportId: string) => {
  const { loading, alertReports } = useGetUserCurrentTenantReports();
  const { allAlertReports } = alertReports;

  return {
    loading,
    report: allAlertReports.find(report => report.id === reportId),
  };
};

export const useAddSavedViewToUser = () => {
  const [addSavedViewToUser] = useAppMutation<
    SchemaTypes.AddSavedViewToUserMutation,
    SchemaTypes.AddSavedViewToUserMutationVariables
  >(ADD_SAVED_VIEW_TO_USER);

  return {
    addSavedViewToUser: (
      userId: string,
      reportParentId: string,
      savedViewName: string,
      savedViewParsedName: string,
    ) => {
      return addSavedViewToUser({
        variables: {
          data: {
            report_name: savedViewName,
            report_parsed_name: savedViewParsedName,
            report_parent: reportParentId,
          },
          where: {
            id: userId,
          },
        },
      });
    },
  };
};

export const useDeleteSavedViewFromUser = () => {
  const [deleteSavedViewFromUser] =
    useAppMutation<SchemaTypes.DeleteSavedViewFromUserMutation>(
      DELETE_SAVED_VIEW_FROM_USER,
    );

  return {
    deleteSavedViewFromUser: (reportId: string, user: User) => {
      return deleteSavedViewFromUser({
        variables: {
          reportId,
          where: {
            id: user.id,
          },
        },
        optimisticResponse: {
          deleteSavedViewFromUser: {
            id: user.id,
            user_fav_reports: filterItems(user.user_fav_reports)
              .map(({ id }) => id)
              .filter(rId => rId !== reportId)
              .map(rId => ({
                id: rId,
                __typename: 'Report',
              })),
            user_saved_reports: filterItems(user.user_saved_reports)
              .map(({ id }) => id)
              .filter(rId => rId !== reportId)
              .map(rId => ({
                id: rId,
                __typename: 'Report',
              })),
            __typename: 'User',
          },
        },
      });
    },
  };
};

export const useResetDemoUser = () => {
  const [resetDemoUser] = useAppMutation<
    SchemaTypes.ResetDemoUserMutation,
    SchemaTypes.ResetDemoUserMutationVariables
  >(RESET_DEMO_USER);

  return {
    resetDemoUser: async (userId: string, tenantId: string) => {
      await resetDemoUser({
        variables: {
          where: {
            tenant_id: tenantId,
            user_id: userId,
          },
        },
      });
    },
  };
};

export const useGetUserPATReportById = (reportId: string) => {
  const { loading, patReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    report: patReports?.find(report => report.id === reportId),
  };
};

interface AddExternalReport {
  reportName: string;
  reportUrl: string;
  reportAnchorTarget: ReportAnchorTarget;
  reportType: ExternalReportType;
  file?: File;
}

export const useAddExternalReport = () => {
  const { getBlobStorageUploadSasUrl } = useGetBlobStorageUploadSasUrl();
  const tenantId = useGetUserDefaultTenantId();
  const {
    user: { id, user_email },
  } = useGetUserInfo();
  const { addTenantReport } = useAddTenantReport();

  return {
    addExternalReport: async ({
      reportAnchorTarget,
      reportName,
      reportUrl,
      file,
      reportType,
    }: AddExternalReport) => {
      const fileName = `${file?.name}hash${uuid()}`;

      if (file) {
        const sasUrl = await getBlobStorageUploadSasUrl(
          `${process.env.CLIENT_API_ENV}-external-reports-thumbnails`,
          id,
        );
        await utils.uploadFile({
          file,
          filePath: fileName,
          sasContainerUrl: sasUrl,
        });
      }

      await addTenantReport(tenantId, {
        report_name: reportName,
        report_tableau_full_report_url: '',
        report_tableau_url: '',
        report_tenant: tenantId,
        report_cat: ['External'],
        report_external_url: reportUrl,
        report_added_by: user_email,
        report_updated_by: user_email,
        report_external_anchor_target: reportAnchorTarget,
        report_external_type: reportType,
        ...(file && { report_external_thumbnail_pathname: fileName }),
      });
    },
  };
};

export const useUpdateExternalReport = () => {
  const { getBlobStorageUploadSasUrl } = useGetBlobStorageUploadSasUrl();
  const {
    user: { id },
  } = useGetUserInfo();
  const { updateReport } = useUpdateReport();

  return {
    updateExternalReport: async (
      reportId: string,
      tenantId: string,
      reportName?: string,
      reportAnchorTarget?: ReportAnchorTarget,
      file?: File,
    ) => {
      const fileName = `${file?.name}hash${uuid()}`;

      if (file) {
        const sasUrl = await getBlobStorageUploadSasUrl(
          `${process.env.CLIENT_API_ENV}-external-reports-thumbnails`,
          id,
        );
        await utils.uploadFile({
          file,
          filePath: fileName,
          sasContainerUrl: sasUrl,
        });
      }
      await updateReport(reportId, tenantId, {
        ...(reportName && { report_name: reportName }),
        ...(reportAnchorTarget && {
          report_external_anchor_target: reportAnchorTarget,
        }),
        ...(file && { report_external_thumbnail_pathname: fileName }),
      });
    },
  };
};
