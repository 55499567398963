import { InsightsAlertTypeFilter } from '../insightsAlerts.types';
import { useGetInsightsInboxSettings } from './useGetInsightsInboxSettings';

export const useGetInsightsInboxAlertTypeFilter = () => {
  const { loading, inboxSettings } = useGetInsightsInboxSettings();

  return {
    alertTypeFilter:
      inboxSettings?.insights_selected_filters.insights_alert_type_filter ??
      InsightsAlertTypeFilter.All,
    loading,
  };
};
