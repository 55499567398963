import { useMemo } from 'react';
import { UseDataManagementRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useDataLoadsDataManagementRoutes = ({
  dataLoads: { getLink, path },
}: UseDataManagementRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      dataLoads: getCommonRoute(link, 'data-loads', path),
      batchUploadSettings: getCommonRoute(link, 'batch-upload-settings', path),
    };
  }, [getLink, path]);
