import { Report, ReportExtended } from '@apollo-red/hooks';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';

export enum Display {
  grid = 'grid',
  table = 'table',
}

export interface DashboardProps {
  reports: ReportExtended[];
  dashboardRoutes: DashboardRouteProps;
  useSaveHandler: (reports: Report[]) => { saveHandler: () => void };
  showKeyMetrics?: boolean;
  drag?: boolean;
  showAddReports?: boolean;
  autoOverflow?: boolean;
  isPATDashboard?: boolean;
}

export interface BaseDashboardsProps extends DashboardProps {
  title: React.ReactNode;
  categoryId: string;
  breadcrumbsTitle: string;
  breadcrumbsParts: BreadcrumbsRoutePart[];
  dashboardNotFound?: React.ReactNode;
}

export interface RouteParams {
  path: string;
  getLink: (tenantOvcId?: string) => string;
}

export interface DashboardRouteParams extends Omit<RouteParams, 'getLink'> {
  getLink: (
    categoryId: string,
    dashboardId: string,
    command?: string,
  ) => string;
}

export interface ExternalRouteParams extends Omit<RouteParams, 'getLink'> {
  getLink: (categoryId: string, externalReportId: string) => string;
}

export interface CategoryRouteParams extends Omit<RouteParams, 'getLink'> {
  getLink: (categoryId: string) => string;
}

export interface HomeRouteParams extends Omit<RouteParams, 'getLink'> {
  getLink: () => string;
}

export interface SearchRouteParams extends Omit<RouteParams, 'getLink'> {
  getLink: (keywordName: string) => string;
}
export interface DashboardRouteProps {
  home: HomeRouteParams;
  category: CategoryRouteParams;
  dashboard: DashboardRouteParams;
  favorites: RouteParams;
  saved?: RouteParams;
  all: RouteParams;
  search?: SearchRouteParams;
  external?: ExternalRouteParams;
}
