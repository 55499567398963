import * as React from 'react';
import ThoughtSpotPinboard from '@pages/selfServeAnalytics/ThoughtSpotPinboard';
import { default as ExternalReportToolbar } from './ExternalReportToolbar';
import { useStyles } from './styles';
import { ExternalReportProps } from './types';
import { useHandleExportReportOnLoad } from './useHandleExternalReportOnLoad';

const ExternalThoughtSpotReport: React.FC<ExternalReportProps> = ({
  report,
}) => {
  const classes = useStyles();
  const { handleOnLoad } = useHandleExportReportOnLoad(report);
  return (
    <div>
      <ExternalReportToolbar report={report} />
      <div className={classes.frame}>
        <ThoughtSpotPinboard
          thoughtSpotHost={report.report_external_thoughtspot_host ?? ''}
          pinboardId={report.report_external_thoughtspot_pinboard_id ?? ''}
          visualizationId={report.report_external_thoughtspot_viz_id ?? ''}
          handleOnLoad={handleOnLoad}
        />
      </div>
    </div>
  );
};

export default ExternalThoughtSpotReport;
