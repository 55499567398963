import * as React from 'react';
import { Form, FormSubmit, FormButtons } from '@jll-labs/azara-ui-components';
import UniversalFiltersForm, {
  FormValues,
  useUniversalFiltersForm,
  useUniversalFiltersTrackLocationEvent,
  universalFiltersSchema,
} from '@components/universalFilters';
import { useAppMessage, changesSavedMsg } from '@components/messages';
import { TableauCacheContext } from '@components/tableau/TableauCacheContextProvider';
import {
  useGetUserDefaultTenant,
  useGetUserUniversalFiltersForTenant,
  useUpdateUserUniversalFilters,
} from '@apollo-red/hooks';

interface Props {
  disabled?: boolean;
}

const UniversalFiltersSettings: React.FC<Props> = ({ disabled }) => {
  const { clearCache } = React.useContext(TableauCacheContext);
  const { defaultTenant } = useGetUserDefaultTenant();
  const tenantId = defaultTenant?.id ?? '';
  const tenantOvcId = defaultTenant?.tenant_ovc_id ?? '';
  const { updateUserUniversalFilters } = useUpdateUserUniversalFilters();
  const { universalFilters } = useGetUserUniversalFiltersForTenant(
    defaultTenant?.id ?? '',
  );
  const { initialValues, parseSubmit } =
    useUniversalFiltersForm(universalFilters);
  const { trackLocationEvent } = useUniversalFiltersTrackLocationEvent();
  const { setAppMessage } = useAppMessage();

  const onSubmit: FormSubmit<FormValues> = async values => {
    trackLocationEvent(initialValues, values);

    await updateUserUniversalFilters(
      tenantId,
      tenantOvcId,
      parseSubmit(values),
    );

    clearCache();
    setAppMessage(changesSavedMsg());
  };

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={universalFiltersSchema}
      initialValues={initialValues}
    >
      <>
        <UniversalFiltersForm
          disabled={disabled}
          components={{
            countries: true,
            states: true,
            cities: true,
            properties: true,
            unitOfMeasurement: true,
            currency: true,
          }}
        />
        <FormButtons disabled={disabled} />
      </>
    </Form>
  );
};

export default UniversalFiltersSettings;
