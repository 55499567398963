import { makeStyles } from '@mui/styles';
import { AppTheme } from '@theme';

interface StyleProps {
  showFiltersPanel: boolean;
  isCoreDashboard: boolean;
}

export const useWithFiltersToolbarStyles = makeStyles((theme: AppTheme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  filterButtons: ({ showFiltersPanel, isCoreDashboard }: StyleProps) => ({
    marginRight: showFiltersPanel ? 0 : isCoreDashboard ? 0 : theme.spacing(3),
    paddingRight: theme.spacing(1),
  }),
  filterButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(0, 1),
  },
}));
