import * as React from 'react';
import OutboundLink, { OutboundLinkType } from './OutboundLink';
import { useHelpPortalLink } from './useHelpPortalLink';
import {
  HelpPortalLinkType,
  useHelpPortalLinkType,
} from './useHelpPortalLinkType';

interface Props {
  children: React.ReactNode;
  topic?: string;
  type?: HelpPortalLinkType;
}

const DocumentationLink: React.FC<Props> = ({ topic = '', type, children }) => {
  const helpPortalLinkType = useHelpPortalLinkType(type);
  const helpPortalLink = useHelpPortalLink(topic, helpPortalLinkType);

  return (
    <OutboundLink
      href={helpPortalLink}
      linkType={OutboundLinkType.DocumentationLink}
    >
      {children}
    </OutboundLink>
  );
};

export default React.memo(DocumentationLink);
