export enum DataQuality {
  Superior = 'Superior',
  Deficient = 'Deficient',
}

export interface DataObject {
  value: string;
  formattedValue: string;
}

export interface DataAvailabilityFieldValues {
  hasData: boolean;
  isLoading: boolean;
  qualityExplanation?: string;
  quality?: DataQuality;
}

export interface DataAvailabilitySheetFields {
  hasData: boolean;
  quality?: DataQuality;
  qualityExplanation?: string;
}

export const HAS_DATA = 'has data';
// FIXME: Fix spelling error after discussing with data team.
export const DATA_QUALITY_EXPLANATION = 'quality explenation';
export const DATA_QUALITY = 'quality';
export const DATA_DELIMITER = ':';
