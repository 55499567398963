import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPSERT_USER_WORK_ROLE } from '@apollo-red/mutations';

export const useUpsertUserWorkRole = () => {
  const [upsertUserWorkRole] = useAppMutation<
    SchemaTypes.UpsertUserWorkRoleMutation,
    SchemaTypes.UpsertUserWorkRoleMutationVariables
  >(UPSERT_USER_WORK_ROLE);

  return {
    upsertUserWorkRole: React.useCallback(
      (id: string, data: SchemaTypes.UserTenantWorkRoleInput) => {
        return upsertUserWorkRole({
          variables: {
            where: { id },
            data,
          },
        });
      },
      [upsertUserWorkRole],
    ),
  };
};
