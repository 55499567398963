import { unionWith } from 'lodash';
import { TypedTypePolicies } from '@apollo-red/schema/generated/apollo-helpers';

export const userTypePolicy: TypedTypePolicies = {
  UserAdminSettings: {
    merge: true,
  },
  UserSettings: {
    merge: true,
  },
  UserClassification: {
    fields: {
      continuation_token: {
        merge(existing, incoming) {
          if (!existing && !incoming) {
            return '';
          }

          if (existing === '') {
            return '';
          }

          if (!incoming) {
            return '';
          }

          if (incoming) {
            return incoming;
          }

          return '';
        },
      },
      users: {
        merge(existing, incoming, { readField }) {
          if (!existing && !incoming) return [];
          if (!existing) return incoming;
          if (!incoming) return existing;

          return unionWith<any>(existing, incoming, (first, second) => {
            const firstId = readField('id', first);
            const secondId = readField('id', second);
            return firstId === secondId;
          });
        },
      },
      filters_options: {
        merge: true,
      },
    },
  },
  UserTenantRegularSettings: {
    fields: {
      user_alert_rule_builder_settings: {
        merge: false,
      },
    },
  },
};
