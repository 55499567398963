import { ClientSideResolverContext } from '@apollo-red/resolvers/context';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
type GraphQLResolveInfo = any;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} &
  { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Represents Active Directory group info. */
export type AdGroup = {
  __typename?: 'AdGroup';
  /** Name of the AD group. */
  group_name: Scalars['String'];
};

/** Input for creating demo tenant */
export type AddDemoTenantInput = {
  /** Demo client ovc id */
  test_client_id: Scalars['String'];
  /** Source client ovc id */
  source_client_id: Scalars['String'];
  /** Demo client apps */
  apps: Array<Scalars['String']>;
  /** Demo client code */
  client_code: Scalars['String'];
};

/** Input for adding tenant data share for custom reports. */
export type AddTenantDataShareForCustomReports = {
  /** Snowflake url. */
  snowflake_url: Scalars['String'];
};

/** Represents address info. */
export type Address = {
  __typename?: 'Address';
  /** Primary street info. */
  street1: Scalars['String'];
  /** Secondary street info. */
  street2?: Maybe<Scalars['String']>;
  /** City name. */
  city: Scalars['String'];
  /** State name. */
  state?: Maybe<Scalars['String']>;
  /** Region name. */
  region?: Maybe<Region>;
  /** Country name. */
  country?: Maybe<Country>;
};

export type AlertTemplateData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
};

/** Specifies version and build date of API. */
export type ApiVersion = {
  __typename?: 'ApiVersion';
  /** Version of API. This value is generated during build time from git tag. */
  version: Scalars['String'];
  /** Unix timestamp of build time. */
  build_date: Scalars['String'];
  /** Tenant QA type */
  tenant_qa_type?: Maybe<TenantQaType>;
};

/** Represents an application. Application can be regarded as data source for reports. */
export type Application = {
  __typename?: 'Application';
  /** Unique id of application. */
  id: Scalars['ID'];
  /** Application name. */
  app_name: Scalars['String'];
  /** Application category (also known as application code). Category and name are a unique combination. */
  app_cat: Scalars['String'];
  /** Indicates if application is active. */
  app_active: Scalars['Boolean'];
  /** Indicates if application is a default one. Data from default applications is provisioned before data from any other sources. */
  app_default: Scalars['Boolean'];
  /** Application description */
  app_description: Scalars['String'];
  /** Application schema */
  app_schema?: Maybe<ApplicationSchema>;
  /** Indicates if data upload is allowed for application */
  app_data_upload: Scalars['Boolean'];
  /** Application subcodes */
  app_subcodes?: Maybe<Array<ApplicationSubcode>>;
};

/** Represents an application. Application can be regarded as data source for reports. */
export type ApplicationApp_SchemaArgs = {
  client_id: Scalars['String'];
};

/** Represents application schema */
export type ApplicationSchema = {
  __typename?: 'ApplicationSchema';
  /** Application name */
  name: Scalars['String'];
  /** Views schemas */
  views: Array<ViewSchema>;
};

/** Represents application subcode. */
export type ApplicationSubcode = {
  __typename?: 'ApplicationSubcode';
  /** Application subcode */
  app_subcode: Scalars['String'];
  /** Application subcode description */
  app_subcode_description: Scalars['String'];
};

/** Input application subcode. See ApplicationSubcode for details. */
export type ApplicationSubcodeInput = {
  app_subcode: Scalars['String'];
  app_subcode_description: Scalars['String'];
};

/** Possible area units */
export enum AreaUnit {
  /** Square feet */
  Sqft = 'sqft',
  /** Square meters */
  Sqm = 'sqm',
}

/** Represents an interactive report control (e.g., filter dropdown). */
export type BaselineReportControl = {
  __typename?: 'BaselineReportControl';
  /** Id. */
  id: Scalars['ID'];
  /** Unique id that will be same across reports. */
  filter_id: Scalars['String'];
  /** Indicates if the report control is enabled. */
  enabled: Scalars['Boolean'];
  /** The control label displayed to the user. */
  label: Scalars['String'];
  /** The name of the Parameter or Filter as defined by the report author. */
  name: Scalars['String'];
  /** The type of section (i.e., Parameters, Filters), if overriding the parent section's type for this control. */
  section_type?: Maybe<ReportSectionType>;
  /** The type of report control (e.g., dropdown list). */
  control_type: ReportControlType;
  /** Static list of name-value pairs for controls of type single_select_list_def. */
  static_data: Array<ReportControlStaticListItem>;
  /** Indicates if the value should cascaded to other controls. */
  cascade_update: Scalars['Boolean'];
  /** The relative sort order for the control. */
  sort_index: Scalars['Int'];
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report control was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report control was last updated. */
  updated_by?: Maybe<Scalars['String']>;
};

/** Represents settings related to report's user interactions. */
export type BaselineReportInteractionConfiguration = {
  __typename?: 'BaselineReportInteractionConfiguration';
  /** Id. */
  id: Scalars['ID'];
  /** Indicates if the report filters panel is enabled. */
  enabled: Scalars['Boolean'];
  /** A list of report control sections for grouping purposes. */
  sections: Array<BaselineReportInteractionSection>;
};

/** Represents a section of interactive controls (e.g., Parameters, Filters). */
export type BaselineReportInteractionSection = {
  __typename?: 'BaselineReportInteractionSection';
  /** Id. */
  id: Scalars['ID'];
  /** Indicates if the report section is enabled. */
  enabled: Scalars['Boolean'];
  /** The section label displayed to the user in the report filters panel. */
  label: Scalars['String'];
  /** The relative sort order for the section. */
  sort_index: Scalars['Int'];
  /** Indicates if the section should default to expanded or collapsed. */
  expanded: Scalars['Boolean'];
  /** The type of section (i.e., Parameters, Filters). */
  section_type: ReportSectionType;
  /** A list of report controls contained within the section. */
  controls: Array<BaselineReportControl>;
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report section was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report section was last updated. */
  updated_by?: Maybe<Scalars['String']>;
};

/** Input for blob storage file upload */
export type BlobStorageUploadSasUrlInput = {
  /** Container name */
  container_name: Scalars['String'];
};

/** Represents report category. */
export type Category = {
  __typename?: 'Category';
  /** Unique id of category. */
  id: Scalars['ID'];
  /** Category description. */
  category_description: Scalars['String'];
  /** List of reports associated with this category. */
  category_reports: Array<Maybe<Report>>;
};

/** Represents report column schema. */
export type ColumnSchema = {
  __typename?: 'ColumnSchema';
  /** Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Kind */
  kind: Scalars['String'];
  /** Is nullable */
  null: Scalars['String'];
  /** Default value */
  default?: Maybe<Scalars['String']>;
  /** Is primary key */
  primary_key: Scalars['String'];
  /** Is unique key */
  unique_key: Scalars['String'];
  /** Check */
  check?: Maybe<Scalars['String']>;
  /** Expression */
  expression?: Maybe<Scalars['String']>;
  /** Comment */
  comment?: Maybe<Scalars['String']>;
};

/** Represents user communication info. */
export type Communications = {
  __typename?: 'Communications';
  /** Home phone number. */
  home?: Maybe<Scalars['String']>;
  /** Mobile phone number. */
  mobile?: Maybe<Scalars['String']>;
  /** Work phone number. */
  work?: Maybe<Scalars['String']>;
  /** Other phone number. */
  other?: Maybe<Scalars['String']>;
};

/** Represents continent. */
export type Continent = {
  __typename?: 'Continent';
  /** Continent code. */
  code?: Maybe<Scalars['String']>;
  /** Continent name. */
  name?: Maybe<Scalars['String']>;
};

/** Represents country. */
export type Country = {
  __typename?: 'Country';
  /** Country id. */
  id: Scalars['ID'];
  /** Country ISO code. */
  country_iso_code: Scalars['String'];
  /** Country name. */
  country_desc: Scalars['String'];
  /** Country continent. */
  country_continent?: Maybe<Continent>;
};

/** Data for covid-19 work order alerts. */
export type Covid19WorkOrderInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Work order client name. */
  work_order_client_name?: Maybe<Scalars['String']>;
  /** Last action at specified as Unix timestamp. */
  last_action_at?: Maybe<Scalars['String']>;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Covid-19 work order daily reason. */
  covid_19_work_order_daily_reason?: Maybe<Scalars['String']>;
  /** Time from picked up to due date specified as Unix timestamp interval. */
  time_from_picked_up_to_due_date?: Maybe<Scalars['String']>;
  /** Speciality. */
  speciality?: Maybe<Scalars['String']>;
  /** City name. */
  city_name?: Maybe<Scalars['String']>;
  /** Work order number. */
  work_order_number?: Maybe<Scalars['String']>;
  /** Due to picked up specified as Unix timestamp interval. */
  due_to_picked_up?: Maybe<Scalars['String']>;
  /** Time to expected completion date high specified as Unix timestamp interval. */
  time_to_expected_completion_date_high?: Maybe<Scalars['String']>;
  /** State name. */
  state_name?: Maybe<Scalars['String']>;
  /** Time to expected completion date medium specified as Unix timestamp interval. */
  time_to_expected_completion_date_medium?: Maybe<Scalars['String']>;
  /** Country name. */
  country_name?: Maybe<Scalars['String']>;
  /** Due at specified as Unix timestamp. */
  due_at?: Maybe<Scalars['String']>;
  /** Work order url. */
  work_order_url?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Client name. */
  client_name?: Maybe<Scalars['String']>;
  /** Work order priority. */
  work_order_priority?: Maybe<Scalars['String']>;
  /** Work order created at specified as Unix timestamp. */
  work_order_created_at?: Maybe<Scalars['String']>;
  /** Is overdue. */
  is_overdue?: Maybe<Scalars['String']>;
  /** Created to picked up duration specified as Unix timestamp interval. */
  created_to_picked_up_duration?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Due to today specified as Unix timestamp interval. */
  due_to_today?: Maybe<Scalars['String']>;
  /** Trade. */
  trade?: Maybe<Scalars['String']>;
  /** Work order id. */
  work_order_id?: Maybe<Scalars['String']>;
  /** Time from created to picked up date specified as Unix timestamp interval. */
  time_from_created_to_picked_up_date?: Maybe<Scalars['String']>;
  /** Daily priority. */
  daily_priority?: Maybe<Scalars['String']>;
  /** Internal alert type. */
  internal_alert_type?: Maybe<Scalars['String']>;
};

/** Represents settings for Covid-19 Work Order alerts categories. */
export type CovidWorkOrderAlertCategory = {
  __typename?: 'CovidWorkOrderAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Covid-19 Work Order alert. */
export type CovidWorkOrderAlertSettingData = {
  __typename?: 'CovidWorkOrderAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: CovidWorkOrderAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Covid-19 Work Order alerts. */
export type CovidWorkOrderAlertSettings = {
  __typename?: 'CovidWorkOrderAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Time (e.g. days) for expected completion date. Medium priority alerts. */
  time_to_expected_completion_date_medium: Scalars['Int'];
  /** Time (e.g. days) for expected completion date. High priority alerts. */
  time_to_expected_completion_date_high: Scalars['Int'];
  /** Time (e.g. days) from created to picked up date. */
  time_from_created_to_picked_up_date: Scalars['Int'];
  /** Time (e.g. days) from created to due date. */
  time_from_picked_up_to_due_date: Scalars['Int'];
};

/**
 * Input for updating / creating settings for Covid-19 Work Order alerts.
 * See CovidWorkOrderAlertSettings type for details.
 */
export type CovidWorkOrderAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  time_to_expected_completion_date_medium: Scalars['Int'];
  time_to_expected_completion_date_high: Scalars['Int'];
  time_from_created_to_picked_up_date: Scalars['Int'];
  time_from_picked_up_to_due_date: Scalars['Int'];
};

/**
 * Input for creating address info.
 * See Address type for details.
 */
export type CreateAddressInput = {
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country: Scalars['ID'];
};

/** Input for creating application. See Application type for details. */
export type CreateApplicationInput = {
  app_name: Scalars['String'];
  app_cat: Scalars['String'];
  app_active?: Maybe<Scalars['Boolean']>;
  app_default?: Maybe<Scalars['Boolean']>;
  app_description: Scalars['String'];
  app_data_upload?: Maybe<Scalars['Boolean']>;
  app_subcodes?: Maybe<Array<ApplicationSubcodeInput>>;
};

/**
 * Input for creating category.
 * See Category type for details.
 */
export type CreateCategoryInput = {
  id?: Maybe<Scalars['String']>;
  category_description: Scalars['String'];
  category_reports: Array<Scalars['ID']>;
};

/**
 * Input for creating country.
 * See Country type for details.
 */
export type CreateCountryInput = {
  country_iso_code: Scalars['String'];
  country_desc?: Maybe<Scalars['String']>;
};

/** Input for creating region. */
export type CreateRegionInput = {
  /** Region name. */
  region_name: Scalars['String'];
};

/**
 * Input for creating report.
 * See Report type for details.
 */
export type CreateReportInput = {
  report_name: Scalars['String'];
  report_cat?: Maybe<Array<Scalars['ID']>>;
  report_tableau_url: Scalars['String'];
  report_tenant: Scalars['ID'];
  report_tableau_full_report_url: Scalars['String'];
  report_tableau_thumbnail_url?: Maybe<Scalars['String']>;
  report_description?: Maybe<Scalars['String']>;
  report_active?: Maybe<Scalars['Boolean']>;
  report_wb?: Maybe<Scalars['String']>;
  report_parent?: Maybe<Scalars['ID']>;
  report_full_keywords?: Maybe<Array<KeywordInput>>;
  report_external_url?: Maybe<Scalars['String']>;
  report_external_thumbnail_pathname?: Maybe<Scalars['String']>;
  report_added_by?: Maybe<Scalars['String']>;
  report_updated_by?: Maybe<Scalars['String']>;
  report_external_anchor_target?: Maybe<ReportAnchorTarget>;
  report_external_type?: Maybe<ExternalReportType>;
};

/**
 * Input for creating user saved view.
 * See Report type for details.
 */
export type CreateReportSavedViewInput = {
  report_name: Scalars['String'];
  report_parent: Scalars['String'];
  /** Report name as returned from Tableau. This might be different than name provided by user. This should be used for creating a URL. */
  report_parsed_name: Scalars['String'];
};

/** Input for adding user to tenant. */
export type CreateTenantUserInput = {
  /** User email. */
  user_email: Scalars['String'];
  /** User first name. */
  user_first_name: Scalars['String'];
  /** User last name. */
  user_last_name: Scalars['String'];
  /** Indicates if user should be enabled. */
  user_enabled: Scalars['Boolean'];
  /** User role. */
  role_type: TenantRoleType;
  /** Role Id */
  role_id: Scalars['String'];
  /** Indicates user management type. */
  user_management_type: UserManagementType;
};

/** Input for creating user persona. */
export type CreateUserPersonaInput = {
  /** User id. */
  user_email: Scalars['String'];
  /** User persona details. */
  user_persona: UserPersonaInput;
};

/**
 * Input for creating work area.
 * See WorkArea type for details.
 */
export type CreateWorkAreaInput = {
  specializations: Array<WorkSpecializationInput>;
};

/** Possible currency units */
export enum CurrencyUnit {
  Usd = 'usd',
  Gbp = 'gbp',
  Eur = 'eur',
  Aud = 'aud',
}

/** Status of custom report. */
export enum CustomReportStatus {
  New = 'new',
  Previewed = 'previewed',
  Deactivated = 'deactivated',
  Published = 'published',
}

/** Represents container access sas urls */
export type DataLoadsContainers = {
  __typename?: 'DataLoadsContainers';
  input: Scalars['String'];
  output: Scalars['String'];
  error: Scalars['String'];
};

/** Represents optional container access parameters */
export type DataLoadsContainersInput = {
  start_time?: Maybe<Scalars['String']>;
  expiry_time?: Maybe<Scalars['String']>;
};

/** Represents data loads job error info */
export type DataLoadsErrorInfo = {
  __typename?: 'DataLoadsErrorInfo';
  error_code: Scalars['String'];
  description: Scalars['String'];
};

export type DataLoadsFileUploadSasUrl = {
  __typename?: 'DataLoadsFileUploadSasUrl';
  sas_url: Scalars['String'];
  folder_path: Scalars['String'];
};

/** Represents data loads job. */
export type DataLoadsJob = {
  __typename?: 'DataLoadsJob';
  id: Scalars['String'];
  tenant?: Maybe<Tenant>;
  application?: Maybe<Application>;
  application_sub_code?: Maybe<ApplicationSubcode>;
  submitted_by?: Maybe<Scalars['String']>;
  submitted_by_user?: Maybe<User>;
  status?: Maybe<DataLoadsJobsStatus>;
  created_at?: Maybe<Scalars['String']>;
  processing_started_at?: Maybe<Scalars['String']>;
  processing_ended_at?: Maybe<Scalars['String']>;
  file_source_name?: Maybe<Scalars['String']>;
  record_info?: Maybe<DataLoadsRecordInfo>;
  error_info?: Maybe<DataLoadsErrorInfo>;
};

/** Represents data loads job download links. */
export type DataLoadsJobLinks = {
  __typename?: 'DataLoadsJobLinks';
  id: Scalars['String'];
  source_download_url?: Maybe<Scalars['String']>;
  output_download_url?: Maybe<Scalars['String']>;
};

/** Represents data loads jobs. */
export type DataLoadsJobs = {
  __typename?: 'DataLoadsJobs';
  cursor?: Maybe<Scalars['String']>;
  jobs: Array<DataLoadsJob>;
};

/** Represents required and optional filters for getting a list of data loads jobs. */
export type DataLoadsJobsFiltersInput = {
  from_date: Scalars['String'];
  to_date: Scalars['String'];
  job_id?: Maybe<Scalars['String']>;
  submitted_by?: Maybe<Scalars['String']>;
  status?: Maybe<Array<DataLoadsJobsStatus>>;
  application?: Maybe<Array<Scalars['String']>>;
  file_name?: Maybe<Scalars['String']>;
};

/** Represents possible data loads job status. */
export enum DataLoadsJobsStatus {
  New = 'new',
  Queued = 'queued',
  FileValidation = 'file_validation',
  DataProcessing = 'data_processing',
  PartialSuccessful = 'partial_successful',
  Successful = 'successful',
  Failed = 'failed',
}

/** Represents data loads job record info */
export type DataLoadsRecordInfo = {
  __typename?: 'DataLoadsRecordInfo';
  total_count: Scalars['Int'];
  success_count: Scalars['Int'];
  failed_count: Scalars['Int'];
};

export type DataLoadsResetContainers = {
  __typename?: 'DataLoadsResetContainers';
  type: Scalars['String'];
  sasUrl: Scalars['String'];
};

/** Represents single data threshold */
export type DataThreshold = {
  __typename?: 'DataThreshold';
  /** Threshold name */
  threshold: Scalars['String'];
  /** Threshold value */
  value: Scalars['Float'];
};

/** Input for data threshold */
export type DataThresholdInput = {
  /** Threshold name */
  threshold: Scalars['String'];
  /** Threshold value */
  value: Scalars['Float'];
};

/** Input for deleting tenant data share for custom reports. */
export type DeleteTenantDataShareForCustomReports = {
  id: Scalars['ID'];
};

/** Type of an entity that changed. */
export enum EntityType {
  None = 'none',
  Tenant = 'tenant',
  User = 'user',
  Report = 'report',
}

/** Input for specifying error details. */
export type ErrorLogInput = {
  /** Error name. */
  name: Scalars['String'];
  /** Error message. */
  message: Scalars['String'];
  /** Error stack. */
  stack?: Maybe<Scalars['String']>;
};

/** Type of a tracked event. */
export enum EventType {
  None = 'none',
  UpdateUserAccess = 'update_user_access',
  CreateUser = 'create_user',
  RemoveUserFromTenant = 'remove_user_from_tenant',
  UpdateUserUniversalFilters = 'update_user_universal_filters',
  UpdateUserAlertSettings = 'update_user_alert_settings',
  UpdateCustomReportStatus = 'update_custom_report_status',
  DeactivateUserFromTenant = 'deactivate_user_from_tenant',
  ReactivateUserToTenant = 'reactivate_user_to_tenant',
}

/** Possible values of external report type. */
export enum ExternalReportType {
  /** Tableau external report */
  Tableau = 'tableau',
  /** Standard external report */
  Standard = 'standard',
  /** ThoughtSpot external report */
  ThoughtSpot = 'thought_spot',
}

/** Represents settings for Facilities Experience alerts categories. */
export type FacilitiesExperienceAlertCategory = {
  __typename?: 'FacilitiesExperienceAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Facilities Experience alert. */
export type FacilitiesExperienceAlertSettingData = {
  __typename?: 'FacilitiesExperienceAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: FacilitiesExperienceAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Facilities Experience alerts. */
export type FacilitiesExperienceAlertSettings = {
  __typename?: 'FacilitiesExperienceAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Time (e.g. days) for expected completion date. Medium priority alerts. */
  time_to_expected_completion_date_medium: Scalars['Int'];
  /** Time (e.g. days) for expected completion date. High priority alerts. */
  time_to_expected_completion_date_high: Scalars['Int'];
  /** Time (e.g. days) from created to picked up date. */
  time_from_created_to_picked_up_date: Scalars['Int'];
  /** Time (e.g. days) from created to due date. */
  time_from_picked_up_to_due_date: Scalars['Int'];
};

/**
 * Input for updating / creating settings for Facilities Experience alerts.
 * See FacilitiesExperienceAlertSettings type for details.
 */
export type FacilitiesExperienceAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  time_to_expected_completion_date_medium: Scalars['Int'];
  time_to_expected_completion_date_high: Scalars['Int'];
  time_from_created_to_picked_up_date: Scalars['Int'];
  time_from_picked_up_to_due_date: Scalars['Int'];
};

/** Data for facilities experience alerts. */
export type FacilitiesExperienceInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Work order client name. */
  work_order_client_name?: Maybe<Scalars['String']>;
  /** Work order priority. */
  work_order_priority?: Maybe<Scalars['String']>;
  /** Last action at specified as Unix timestamp. */
  last_action_at?: Maybe<Scalars['String']>;
  /** Work order created at specified as Unix timestamp. */
  work_order_created_at?: Maybe<Scalars['String']>;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Time from picked up to due date specified as Unix timestamp interval. */
  time_from_picked_up_to_due_date?: Maybe<Scalars['String']>;
  /** Speciality. */
  speciality?: Maybe<Scalars['String']>;
  /** City name. */
  city_name?: Maybe<Scalars['String']>;
  /** Work order number. */
  work_order_number?: Maybe<Scalars['String']>;
  /** Trade. */
  trade?: Maybe<Scalars['String']>;
  /** Time to expected completion date high specified as Unix timestamp interval. */
  time_to_expected_completion_date_high?: Maybe<Scalars['String']>;
  /** State name. */
  state_name?: Maybe<Scalars['String']>;
  /** Work order id. */
  work_order_id?: Maybe<Scalars['String']>;
  /** Time to expected completion date medium specified as Unix timestamp interval. */
  time_to_expected_completion_date_medium?: Maybe<Scalars['String']>;
  /** Country name. */
  country_name?: Maybe<Scalars['String']>;
  /** Due at specified as Unix timestamp. */
  due_at?: Maybe<Scalars['String']>;
  /** Work order url. */
  work_order_url?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Time from created to picked up date specified as Unix timestamp interval. */
  time_from_created_to_picked_up_date?: Maybe<Scalars['String']>;
  /** Client name. */
  client_name?: Maybe<Scalars['String']>;
  /** Internal alert type. */
  internal_alert_type?: Maybe<Scalars['String']>;
};

/** Sample values for flexible mapping option */
export type FlexibleMappingOptionSampleValues = {
  __typename?: 'FlexibleMappingOptionSampleValues';
  option: Scalars['String'];
  sample_data: Array<Scalars['String']>;
};

export type GlobalData = {
  __typename?: 'GlobalData';
  id: Scalars['ID'];
  /** Data standard category */
  category: Scalars['String'];
  /** Mapped values number */
  total: Scalars['Int'];
  /** Unmapped values number */
  mismatch: Scalars['Int'];
  /** Unapped values, check Unmapped type */
  match: Scalars['Int'];
};

export type GlobalView = {
  __typename?: 'GlobalView';
  /** Represents data standards parameter group mappings. */
  group_mapping?: Maybe<GroupMapping>;
  /** Represents data standard global data. */
  global_data: Array<GlobalData>;
};

/** Represents parameter groups for data standards */
export type GroupMapping = {
  __typename?: 'GroupMapping';
  /** Property data standards. */
  property?: Maybe<Array<Scalars['String']>>;
  /** Lease data standards. */
  lease?: Maybe<Array<Scalars['String']>>;
  /** Transaction data standards. */
  transactions?: Maybe<Array<Scalars['String']>>;
  /** Work order data standards. */
  work_order?: Maybe<Array<Scalars['String']>>;
};

/** Input for specifying report to hide. */
export type HiddenReportInput = {
  id?: Maybe<Scalars['String']>;
};

/**
 * Represents an alert. Alert is unique to a combination of user and tenant. It is generated based on user alert settings and tenant alert settings.
 * Some of these fields are provided by alerts / notifications engine and are meant to be read-only. Some values can be modified by user.
 */
export type InsightsAlert = {
  __typename?: 'InsightsAlert';
  /** Makes sure that we can use Alert type in client-side schema. This is not an actually used field. */
  _client_?: Maybe<Scalars['Boolean']>;
  /** Number of alerts in the alert thread. For grouping together similar alerts. */
  alert_count: Scalars['Int'];
  /** Status of alert shared by all users. */
  alert_status: InsightsAlertStatus;
  /** Corresponds to alert type, e.g. `Work Order Volume`. */
  alert_type: InsightsAlertType;
  /** Id of user for whom alert was assigned. */
  assigned_user_id?: Maybe<Scalars['ID']>;
  /** Alert creation date specified as Unix timestamp. */
  created_at: Scalars['String'];
  /** Indicates if ROI data was entered for the alert. */
  is_roi_enabled: Scalars['Boolean'];
  /** Reason for alert. */
  reason: Scalars['String'];
  /** Severity of alert. */
  severity: InsightsAlertSeverity;
  /** Template data for generating alert content. */
  template_data: InsightsAlertTemplateData;
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Importance of alert as set by user. By default is set to `unassigned`. End-user might change it to other values. */
  user_importance: InsightsAlertImportance;
  /** List of users with access to the alert. */
  user_info: Array<InsightsAlertUserInfo>;
  /** User status of alert. Alert starts as `unread` and its status is modified by end-user actions. */
  user_status: InsightsAlertUserStatus;
};

/** Template data for base work order volume alerts. */
export type InsightsAlertBaseWorkOrderTemplateData = AlertTemplateData & {
  __typename?: 'InsightsAlertBaseWorkOrderTemplateData';
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Due at date. */
  due_at?: Maybe<Scalars['String']>;
  /** Last action at date. */
  last_action_at?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Speciality. */
  speciality?: Maybe<Scalars['String']>;
  /** Work order number. */
  work_order_number?: Maybe<Scalars['String']>;
  /** Work order priority. */
  work_order_priority?: Maybe<Scalars['String']>;
  /** Work order url. */
  work_order_url?: Maybe<Scalars['String']>;
};

/** Data for creating demo insights alerts. */
export type InsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Work order client name. */
  work_order_client_name?: Maybe<Scalars['String']>;
  /** Last action at specified as Unix timestamp. */
  last_action_at?: Maybe<Scalars['String']>;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Covid-19 work order daily reason. */
  covid_19_work_order_daily_reason?: Maybe<Scalars['String']>;
  /** Time from picked up to due date specified as Unix timestamp interval. */
  time_from_picked_up_to_due_date?: Maybe<Scalars['String']>;
  /** Speciality. */
  speciality?: Maybe<Scalars['String']>;
  /** City name. */
  city_name?: Maybe<Scalars['String']>;
  /** Work order number. */
  work_order_number?: Maybe<Scalars['String']>;
  /** Due to picked up specified as Unix timestamp interval. */
  due_to_picked_up?: Maybe<Scalars['String']>;
  /** Time to expected completion date high specified as Unix timestamp interval. */
  time_to_expected_completion_date_high?: Maybe<Scalars['String']>;
  /** State name. */
  state_name?: Maybe<Scalars['String']>;
  /** Time to expected completion date medium specified as Unix timestamp interval. */
  time_to_expected_completion_date_medium?: Maybe<Scalars['String']>;
  /** Country name. */
  country_name?: Maybe<Scalars['String']>;
  /** Due at specified as Unix timestamp. */
  due_at?: Maybe<Scalars['String']>;
  /** Work order url. */
  work_order_url?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Client name. */
  client_name?: Maybe<Scalars['String']>;
  /** Work order priority. */
  work_order_priority?: Maybe<Scalars['String']>;
  /** Work order created at specified as Unix timestamp. */
  work_order_created_at?: Maybe<Scalars['String']>;
  /** Is overdue. */
  is_overdue?: Maybe<Scalars['String']>;
  /** Created to picked up duration specified as Unix timestamp interval. */
  created_to_picked_up_duration?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Due to today specified as Unix timestamp interval. */
  due_to_today?: Maybe<Scalars['String']>;
  /** Trade. */
  trade?: Maybe<Scalars['String']>;
  /** Work order id. */
  work_order_id?: Maybe<Scalars['String']>;
  /** Time from created to picked up date specified as Unix timestamp interval. */
  time_from_created_to_picked_up_date?: Maybe<Scalars['String']>;
  /** Daily priority. */
  daily_priority?: Maybe<Scalars['String']>;
  /** Internal alert type. */
  internal_alert_type?: Maybe<Scalars['String']>;
  /** [Lease event] Tenure key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** [Lease event] Event id. */
  event_id?: Maybe<Scalars['String']>;
  /** [Lease event] Event notice date specified as Unix timestamp. */
  event_notice_date?: Maybe<Scalars['String']>;
  /** [Lease event] Event created date specified as Unix timestamp. */
  event_created_date?: Maybe<Scalars['String']>;
  /** [Lease event] Notice period time medium. */
  notice_period_time_medium?: Maybe<Scalars['String']>;
  /** [Lease event] Preferred unit of currency. */
  preferred_unit_of_currency?: Maybe<Scalars['String']>;
  /** [Lease event, Market Benchmark] Tenure size. */
  tenure_size?: Maybe<Scalars['String']>;
  /** [Lease event] Property ovcp id. */
  property_ovcp_id?: Maybe<Scalars['String']>;
  /** [Lease event] Transaction found. */
  transaction_found?: Maybe<Scalars['String']>;
  /** [Lease event] Preferred unit of measurement. */
  preferred_unit_of_measurement?: Maybe<Scalars['String']>;
  /** [Lease event, Market Benchmark] Tenure type. */
  tenure_type?: Maybe<Scalars['String']>;
  /** [Lease event] Event type. */
  event_type?: Maybe<Scalars['String']>;
  /** [Lease event] Tenure cost. */
  tenure_cost?: Maybe<Scalars['String']>;
  /** [Lease event] Annual cost. */
  annual_cost?: Maybe<Scalars['String']>;
  /** [Lease event, Market Benchmark] Property type. */
  property_type?: Maybe<Scalars['String']>;
  /** [Lease event] Notice period. */
  notice_period?: Maybe<Scalars['String']>;
  /** [Lease event] Notice period time high. */
  notice_period_time_high?: Maybe<Scalars['String']>;
  /** [WOV] Internal. */
  internal?: Maybe<Scalars['String']>;
  /** [WOV] Current value internal. */
  current_value_internal?: Maybe<Scalars['String']>;
  /** [WOV] Benchmark external. */
  benchmark_external?: Maybe<Scalars['String']>;
  /** [WOV] Current value external. */
  current_value_external?: Maybe<Scalars['String']>;
  /** [WOV] External medium. */
  external_medium?: Maybe<Scalars['String']>;
  /** [WOV] Internal threshold percentage. */
  internal_threshold_percentage?: Maybe<Scalars['String']>;
  /** [WOV] Peer group internal. */
  peer_group_internal?: Maybe<Scalars['String']>;
  /** [WOV] Peer group external. */
  peer_group_external?: Maybe<Scalars['String']>;
  /** [WOV] External high. */
  external_high?: Maybe<Scalars['String']>;
  /** [WOV] Alert scope. */
  alert_scope?: Maybe<Scalars['String']>;
  /** [WOV] External. */
  external?: Maybe<Scalars['String']>;
  /** [WOV] WOV internal priority. */
  wov_internal_priority?: Maybe<Scalars['String']>;
  /** [WOV] WOV external priority. */
  wov_external_priority?: Maybe<Scalars['String']>;
  /** [WOV] Internal medium. */
  internal_medium?: Maybe<Scalars['String']>;
  /** [WOV] External threshold percentage. */
  external_threshold_percentage?: Maybe<Scalars['String']>;
  /** [WOV] Benchmark internal. */
  benchmark_internal?: Maybe<Scalars['String']>;
  /** [WOV] WOV above median. */
  wov_above_median?: Maybe<Scalars['String']>;
  /** [WOV] Internal high. */
  internal_high?: Maybe<Scalars['String']>;
  /** [Market Benchmark] Tenure name. */
  tenure_name?: Maybe<Scalars['String']>;
  /** [Market Benchmark] Event created on. */
  event_created_on?: Maybe<Scalars['String']>;
  /** [Market Benchmark] Base rent. */
  base_rent?: Maybe<Scalars['String']>;
  /** [Market Benchmark] Prediction record. */
  prediction_record?: Maybe<MarketBenchmarkInsightsAlertDataPredictionRecord>;
};

/** Possible values of insights alert generation type. */
export enum InsightsAlertGenerationType {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Realtime = 'REALTIME',
}

/** Represents historical information about the alert thread. */
export type InsightsAlertHistory = {
  __typename?: 'InsightsAlertHistory';
  /** Historical records related to the alert thread. */
  history: Array<InsightsAlertHistoryRecord>;
  /** Continuation token for fetching more alerts with the same filter criteria applied. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Represents a historical alert thread record. */
export type InsightsAlertHistoryRecord = {
  __typename?: 'InsightsAlertHistoryRecord';
  /** Reason for alert. */
  reason: Scalars['String'];
  /** Alert history record creation date specified as Unix timestamp. */
  created_at: Scalars['String'];
};

/** Possible values of insights alert importance. */
export enum InsightsAlertImportance {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Unassigned = 'unassigned',
}

/** Alert importance values for filtering. */
export enum InsightsAlertImportanceFilter {
  All = 'all',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Unassigned = 'unassigned',
}

/** Inbox types. */
export enum InsightsAlertInbox {
  All = 'all',
  Unread = 'unread',
  Read = 'read',
  DataQualityReview = 'data_quality_review',
  SavedForLater = 'saved_for_later',
  AssignedToMe = 'assigned_to_me',
  Closed = 'closed',
}

/** Template data for lease event alerts. */
export type InsightsAlertLeaseEventTemplateData = AlertTemplateData & {
  __typename?: 'InsightsAlertLeaseEventTemplateData';
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Annual cost. */
  annual_cost?: Maybe<Scalars['String']>;
  /** Desk count. */
  desk_count?: Maybe<Scalars['String']>;
  /** Event created date. */
  event_created_date?: Maybe<Scalars['String']>;
  /** Event notice date. */
  event_notice_date?: Maybe<Scalars['String']>;
  /** Event type. */
  event_type?: Maybe<Scalars['String']>;
  /** Head count. */
  head_count?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Property type. */
  property_type?: Maybe<Scalars['String']>;
  /** Tenure cost. */
  tenure_cost?: Maybe<Scalars['String']>;
  /** Tenure size. */
  tenure_size?: Maybe<Scalars['String']>;
  /** Tenure type. */
  tenure_type?: Maybe<Scalars['String']>;
};

/** Template data for market benchmark alerts. */
export type InsightsAlertMarketBenchmarkTemplateData = AlertTemplateData & {
  __typename?: 'InsightsAlertMarketBenchmarkTemplateData';
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Event type. */
  event_type?: Maybe<Scalars['String']>;
  /** Event created on. */
  event_created_on?: Maybe<Scalars['String']>;
  /** Property Id. */
  property_id?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Tenure size. */
  tenure_size?: Maybe<Scalars['String']>;
  /** Tenure name. */
  tenure_name?: Maybe<Scalars['String']>;
  /** Tenure type. */
  tenure_type?: Maybe<Scalars['String']>;
  /** Tenure key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Property type. */
  property_type?: Maybe<Scalars['String']>;
  /** Base rent. */
  base_rent?: Maybe<Scalars['String']>;
  /** Prediction record. */
  prediction_record?: Maybe<InsightsAlertMarketBenchmarkTemplateDataPredictionRecord>;
};

export type InsightsAlertMarketBenchmarkTemplateDataPredictionRecord = {
  __typename?: 'InsightsAlertMarketBenchmarkTemplateDataPredictionRecord';
  /** Rent type. */
  rent_type?: Maybe<Scalars['String']>;
  /** Space type. */
  space_type?: Maybe<Scalars['String']>;
  /** Low range. */
  low_range?: Maybe<Scalars['Float']>;
  /** High range. */
  high_range?: Maybe<Scalars['Float']>;
  /** Supporting data count. */
  supporting_data_count?: Maybe<Scalars['Int']>;
  /** Supporting data points. */
  supporting_data_points?: Maybe<
    Array<Maybe<InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint>>
  >;
};

export type InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint = {
  __typename?: 'InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint';
  /** Ground truth. */
  ground_truth?: Maybe<Scalars['Float']>;
  /** Prediction. */
  prediction?: Maybe<Scalars['Float']>;
  /** MDM Id. */
  mdm_id?: Maybe<Scalars['String']>;
  /** Space Id. */
  space_id?: Maybe<Scalars['String']>;
  /** Property Id. */
  property_id?: Maybe<Scalars['String']>;
  /** Space type. */
  space_type?: Maybe<Scalars['String']>;
  /** Costar Id. */
  costar_id?: Maybe<Scalars['String']>;
  /** Date on market. */
  date_on_market?: Maybe<Scalars['String']>;
  /** Rent type. */
  rent_type?: Maybe<Scalars['String']>;
  /** Latitude. */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude. */
  longitude?: Maybe<Scalars['Float']>;
  /** Geohash. */
  geohash_12?: Maybe<Scalars['String']>;
  /** Lease transaction Id. */
  lease_transaction_id?: Maybe<Scalars['String']>;
};

/** Template data for occupancy efficiency alerts. */
export type InsightsAlertOccupancyEfficiencyTemplateData = AlertTemplateData & {
  __typename?: 'InsightsAlertOccupancyEfficiencyTemplateData';
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Vacancy rate trigger info. */
  vacancy_rate_trigger_info?: Maybe<InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord>;
  /** Occupancy rate trigger info. */
  occupancy_rate_trigger_info?: Maybe<InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord>;
  /** Client headcount density info. */
  headcount_density_trigger_info?: Maybe<InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord>;
};

/** Template data for headcount density rate record. */
export type InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord =
  {
    __typename?: 'InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord';
    /** JLL region. */
    jll_region?: Maybe<Scalars['String']>;
    /** Headcount density. */
    headcount_density?: Maybe<Scalars['Int']>;
    /** Client headcount density. */
    client_headcount_density?: Maybe<Scalars['Int']>;
  };

/** Template data for occupancy rate trigger rate record. */
export type InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord = {
  __typename?: 'InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord';
  /** Occupancy rate percentage. */
  occupancy_rate_percentage?: Maybe<Scalars['Float']>;
  /** Client threshold. */
  client_threshold?: Maybe<Scalars['Int']>;
  /** Threshold level. */
  threshold_level?: Maybe<Scalars['String']>;
};

/** Template data for vacancy rate trigger rate record. */
export type InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord = {
  __typename?: 'InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord';
  /** Vacancy rate percentage. */
  vacancy_rate_percentage?: Maybe<Scalars['Float']>;
  /** Client threshold. */
  client_threshold?: Maybe<Scalars['Int']>;
  /** Threshold level. */
  threshold_level?: Maybe<Scalars['String']>;
};

/** Possible values of insights alert sort order. */
export enum InsightsAlertOrderBy {
  Asc = 'asc',
  Desc = 'desc',
}

/** Possible values of insights alert priority. */
export enum InsightsAlertPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

/** Represents ROI data associated with an alert thread. */
export type InsightsAlertRoiData = {
  __typename?: 'InsightsAlertRoiData';
  /** User comments about the ROI opportunity data. */
  comment?: Maybe<Scalars['String']>;
  /** Frequency at which the ROI opportunity applies. */
  frequency?: Maybe<InsightsAlertRoiFrequency>;
  /** Type of ROI opportunity. */
  opportunity_type?: Maybe<InsightsAlertRoiOpportunity>;
  /** ROI opportunity unit of measurement. */
  unit?: Maybe<InsightsAlertRoiUnit>;
  /** ROI opportunity value. */
  value?: Maybe<Scalars['Float']>;
};

/** Possible values of insights alert roi frequency. */
export enum InsightsAlertRoiFrequency {
  OneTime = 'one_time',
  RecurringMonthly = 'recurring_monthly',
  RecurringQuarterly = 'recurring_quarterly',
  RecurringAnnually = 'recurring_annually',
}

/** Possible values of insights alert roi opportunity. */
export enum InsightsAlertRoiOpportunity {
  CostReduction = 'cost_reduction',
  CycleTime = 'cycle_time',
  LabourHour = 'labour_hour',
  HumanExperience = 'human_experience',
  PropertySize = 'property_size',
}

/** Possible values of insights alert roi unit. */
export enum InsightsAlertRoiUnit {
  Usd = 'usd',
  Aud = 'aud',
  Grb = 'grb',
  Eur = 'eur',
  Hours = 'hours',
  Days = 'days',
  Sqft = 'sqft',
  Sqm = 'sqm',
}

/** Template data for same day work order completion alerts. */
export type InsightsAlertSameDayWorkOrderCompletionTemplateData =
  AlertTemplateData & {
    __typename?: 'InsightsAlertSameDayWorkOrderCompletionTemplateData';
    /** Alert type. */
    alert_type: InsightsAlertType;
    /** Property id. */
    property_id?: Maybe<Scalars['String']>;
    /** Property name. */
    property_name?: Maybe<Scalars['String']>;
    /** Total work orders count. */
    total_work_orders: Scalars['String'];
    /** Completed work orders percentage. */
    completed_work_orders_percentage: Scalars['String'];
    /** Not completed work orders percentage. */
    not_completed_work_orders_percentage: Scalars['String'];
    /** Completed work orders not on time percentage. */
    completed_work_orders_not_on_time_percentage: Scalars['String'];
    /** Threshold for alert generation. */
    threshold_for_alert_generation?: Maybe<Scalars['String']>;
  };

/** Possible values of insights alert scope (e.g., for work order volume alerts). */
export enum InsightsAlertScope {
  Both = 'both',
  External = 'external',
  Internal = 'internal',
}

/** Possible values of insights alert severity. */
export enum InsightsAlertSeverity {
  Reminder = 'reminder',
  Urgent = 'urgent',
  Escalation = 'escalation',
  None = 'none',
}

/** Alert severity for filtering. */
export enum InsightsAlertSeverityFilter {
  All = 'all',
  Reminder = 'reminder',
  Urgent = 'urgent',
  Escalation = 'escalation',
  None = 'none',
}

/** Alert created date sort order. */
export enum InsightsAlertSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** Possible values of insights alert status. */
export enum InsightsAlertStatus {
  Active = 'active',
  Closed = 'closed',
  DataQualityReview = 'data_quality_review',
}

/** Possible values of insights alert status. */
export enum InsightsAlertStatusUpdate {
  Closed = 'closed',
  DataQualityReview = 'data_quality_review',
  SaveForLater = 'save_for_later',
  UnableToAction = 'unable_to_action',
}

/** Template data for sublease risk alerts. */
export type InsightsAlertSubleaseRiskTemplateData = AlertTemplateData & {
  __typename?: 'InsightsAlertSubleaseRiskTemplateData';
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Total liquidity options. */
  total_liquidity_options?: Maybe<Scalars['String']>;
  /** Total cost obligations. */
  total_cost_obligations?: Maybe<Scalars['String']>;
  /** Subleases. */
  subleases?: Maybe<Scalars['String']>;
  /** Income at risk. */
  income_at_risk?: Maybe<Scalars['String']>;
  /** Notice Period. */
  notice_period?: Maybe<Scalars['String']>;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Lease Liquidity Options. */
  lease_liquidity_options?: Maybe<Array<Scalars['String']>>;
  /** City Name. */
  city_name?: Maybe<Scalars['String']>;
  /** State Name. */
  state_name?: Maybe<Scalars['String']>;
  /** Country Name. */
  country_name?: Maybe<Scalars['String']>;
  /** Source System Id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Natural expiry. */
  natural_expiry?: Maybe<
    Array<Maybe<InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord>>
  >;
  /** Mainly parking leases. */
  mainly_parking_leases?: Maybe<
    Array<Maybe<InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord>>
  >;
  /** Contraction Options. */
  contraction_options?: Maybe<
    Array<Maybe<InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord>>
  >;
  /** Sublease with expiry. */
  sublease_with_expiry?: Maybe<
    Array<Maybe<InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord>>
  >;
  /** Month to month tenures. */
  month_to_month_tenures?: Maybe<
    Array<Maybe<InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord>>
  >;
  /** Termination options. */
  termination_options?: Maybe<
    Array<Maybe<InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord>>
  >;
};

/** Template data for contraction options record. */
export type InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord = {
  __typename?: 'InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord';
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Event Id. */
  event_id?: Maybe<Scalars['String']>;
  /** Event notice on. */
  event_notice_on?: Maybe<Scalars['Float']>;
  /** Event notes. */
  event_notes?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Template data for Mainly parking leases record. */
export type InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord = {
  __typename?: 'InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord';
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Template data for month to month tenures record. */
export type InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord = {
  __typename?: 'InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord';
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Template data for natural expiry record. */
export type InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord = {
  __typename?: 'InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord';
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenant Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Tenure ended on. */
  tenure_ended_on?: Maybe<Scalars['Float']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Template data for sublease with expiry record. */
export type InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord = {
  __typename?: 'InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord';
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Tenure ended on. */
  tenure_ended_on?: Maybe<Scalars['Float']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Template data for termination options record. */
export type InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord = {
  __typename?: 'InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord';
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Event Id. */
  event_id?: Maybe<Scalars['String']>;
  /** Event notice on. */
  event_notice_on?: Maybe<Scalars['Float']>;
  /** Event notes. */
  event_notes?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Represents an alert summary. Alert summary is unique to a combination of user and tenant. It is generated based on user alert settings and tenant alert settings. */
export type InsightsAlertSummary = {
  __typename?: 'InsightsAlertSummary';
  /** Client id of alert summary. */
  client_id: Scalars['ID'];
  /** User id of alert summary. */
  user_id: Scalars['ID'];
  /** Counts by alert status. */
  counts_by_status: InsightsAlertSummaryCountsByStatus;
};

/** Summarizes alert counts by status. */
export type InsightsAlertSummaryCountsByStatus = {
  __typename?: 'InsightsAlertSummaryCountsByStatus';
  /** Count of all alerts. */
  all: Scalars['Int'];
  /** Count of unread alerts. */
  unread: Scalars['Int'];
};

/** Possible values of insights alert tab (i.e., category, inbox type). */
export enum InsightsAlertTab {
  Read = 'read',
  Unread = 'unread',
  Closed = 'closed',
  DataQualityReview = 'data_quality_review',
  AssignedToMe = 'assigned_to_me',
  SaveForLater = 'save_for_later',
}

/** Template data for generating alert content. */
export type InsightsAlertTemplateData =
  | InsightsAlertBaseWorkOrderTemplateData
  | InsightsAlertLeaseEventTemplateData
  | InsightsAlertWorkOrderVolumeTemplateData
  | InsightsAlertMarketBenchmarkTemplateData
  | InsightsAlertOccupancyEfficiencyTemplateData
  | InsightsAlertSubleaseRiskTemplateData
  | InsightsAlertSameDayWorkOrderCompletionTemplateData;

/** Represents a single alert thread. */
export type InsightsAlertThread = {
  __typename?: 'InsightsAlertThread';
  /** Client id of alert. */
  client_id: Scalars['ID'];
  /** User id of alert. */
  user_id: Scalars['ID'];
  /** Thread id of alert. */
  thread_id: Scalars['ID'];
  /** Alert thread details. */
  alert?: Maybe<InsightsAlert>;
  /** Alert history. */
  alert_history?: Maybe<InsightsAlertHistory>;
  /** Alert user history. */
  alert_user_history?: Maybe<InsightsAlertUserHistory>;
  /** Alert roi data. */
  alert_roi_data?: Maybe<InsightsAlertRoiData>;
};

/** Represents a single alert thread. */
export type InsightsAlertThreadAlert_HistoryArgs = {
  where?: Maybe<WhereGetInsightsAlertThreadHistoryInput>;
};

/** Represents a single alert thread. */
export type InsightsAlertThreadAlert_User_HistoryArgs = {
  where?: Maybe<WhereGetInsightsAlertThreadUserHistoryInput>;
};

/** Possible values of insights alert type. */
export enum InsightsAlertType {
  WorkorderVolume = 'workorder_volume',
  FacilitiesExperience = 'facilities_experience',
  LeaseEvent = 'lease_event',
  Covid_19WorkOrder = 'covid_19_work_order',
  MarketBenchmark = 'market_benchmark',
  OccupancyEfficiency = 'occupancy_efficiency',
  SubleaseRisk = 'sublease_risk',
  SameDayWorkOrderCompletion = 'same_day_work_order_completion',
}

/** Alert types for filtering. */
export enum InsightsAlertTypeFilter {
  All = 'all',
  WorkorderVolume = 'workorder_volume',
  FacilitiesExperience = 'facilities_experience',
  LeaseEvent = 'lease_event',
  Covid_19WorkOrder = 'covid_19_work_order',
  MarketBenchmark = 'market_benchmark',
  OccupancyEfficiency = 'occupancy_efficiency',
  SubleaseRisk = 'sublease_risk',
  SameDayWorkOrderCompletion = 'same_day_work_order_completion',
}

/** Represents historical user actions related to the alert thread. */
export type InsightsAlertUserHistory = {
  __typename?: 'InsightsAlertUserHistory';
  /** User historical records related to the alert thread. */
  user_history: Array<InsightsAlertUserHistoryRecord>;
  /** Continuation token for fetching more alerts with the same filter criteria applied. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Possible values of insights alert user history action performed. */
export enum InsightsAlertUserHistoryActionPerformed {
  CommentAdded = 'comment_added',
  Closed = 'closed',
  DataQualityReview = 'data_quality_review',
  RoiAdded = 'roi_added',
  RoiEdit = 'roi_edit',
  DismissUnableToAction = 'dismiss_unable_to_action',
  DismissSaveForLater = 'dismiss_save_for_later',
  SeverityUpdated = 'severity_updated',
  SystemClosed = 'system_closed',
  AssignedUser = 'assigned_user',
  UnAssignedUser = 'un_assigned_user',
}

/** Represents a user action historical alert thread record. */
export type InsightsAlertUserHistoryRecord = {
  __typename?: 'InsightsAlertUserHistoryRecord';
  /** Name of user. */
  user_name: Scalars['String'];
  /** Alert comment creation date specified as Unix timestamp. */
  commented_at: Scalars['String'];
  /** Action performed by user. */
  action_performed: InsightsAlertUserHistoryActionPerformed;
  /** Comment associated with the user action. */
  comment?: Maybe<Scalars['String']>;
};

/** Information about users with access to alerts. */
export type InsightsAlertUserInfo = {
  __typename?: 'InsightsAlertUserInfo';
  /** Id of user with access to the alert. */
  user_id: Scalars['String'];
  /** Name of user. */
  user_name: Scalars['String'];
};

/** Possible values of insights alert user status. */
export enum InsightsAlertUserStatus {
  Read = 'read',
  Unread = 'unread',
  UnableToAction = 'unable_to_action',
  SaveForLater = 'save_for_later',
}

/** Template data for work order volume alerts. */
export type InsightsAlertWorkOrderVolumeTemplateData = AlertTemplateData & {
  __typename?: 'InsightsAlertWorkOrderVolumeTemplateData';
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Alert scope. */
  alert_scope?: Maybe<InsightsAlertScope>;
  /** External benchmark. */
  benchmark_external?: Maybe<Scalars['String']>;
  /** Internal benchmark. */
  benchmark_internal?: Maybe<Scalars['String']>;
  /** External client value. */
  client_external_value?: Maybe<Scalars['String']>;
  /** Internal client value. */
  client_internal_value?: Maybe<Scalars['String']>;
  /** External peer group. */
  peer_group_external?: Maybe<Scalars['String']>;
  /** Internal peer group. */
  peer_group_internal?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Speciality. */
  speciality?: Maybe<Scalars['String']>;
  /** Trade. */
  trade?: Maybe<Scalars['String']>;
  /** Work order created date as Unix timestamp. */
  work_order_created_at?: Maybe<Scalars['String']>;
};

/** Represents a paged feed of alerts. */
export type InsightsAlerts = {
  __typename?: 'InsightsAlerts';
  /** Client id of alert. */
  client_id: Scalars['ID'];
  /** User id of alert. */
  user_id: Scalars['ID'];
  /** Continuation token for fetching more alerts with the same filter criteria applied. */
  continuation_token?: Maybe<Scalars['ID']>;
  /** Indicates if more alerts can be fetched. */
  has_next_page: Scalars['Boolean'];
  /** Alerts. */
  alerts: Array<InsightsAlert>;
};

/** Represents selected alert per inbox type. */
export type InsightsInboxAlerts = {
  __typename?: 'InsightsInboxAlerts';
  /** Recently selected alert. */
  insights_alert_selected?: Maybe<InsightsAlert>;
  /** Type of inbox. */
  insights_inbox_type: InsightsAlertInbox;
};

/** Represents sort order. */
export type InsightsInboxSelectedSortOrder = {
  __typename?: 'InsightsInboxSelectedSortOrder';
  /** Selected sort order by created date. */
  created_date: InsightsAlertSortOrder;
};

/** Represents filter selection. */
export type InsightsSelectedFilters = {
  __typename?: 'InsightsSelectedFilters';
  /** Assigned User */
  insights_alert_assigned_to: Scalars['String'];
  /** Selected Severity */
  insights_alert_severity: InsightsAlertSeverityFilter;
  /** Selected type of alert. */
  insights_alert_type_filter: InsightsAlertTypeFilter;
  /** Selected importance. */
  insights_importance_filter: InsightsAlertImportanceFilter;
};

/** Represents a keyword. */
export type Keyword = {
  __typename?: 'Keyword';
  /** Unique id. */
  id: Scalars['ID'];
  /** Keyword name. */
  keyword_name: Scalars['String'];
};

/**
 * Input for updating report keyword.
 * See Keyword type for details.
 */
export type KeywordInput = {
  id: Scalars['ID'];
  keyword_name: Scalars['String'];
};

/** Represents KPI data for a given tenant. Values depend on selected universal filters. */
export type KpiData = {
  __typename?: 'KpiData';
  /** Total size of properties. */
  size_current?: Maybe<Scalars['Float']>;
  /** Size change. */
  size_change?: Maybe<Scalars['Float']>;
  /** Size last year. */
  size_last_yr?: Maybe<Scalars['Float']>;
  /** Total number of properties. */
  properties_current?: Maybe<Scalars['Int']>;
  /** Number of properties change. */
  properties_change?: Maybe<Scalars['Int']>;
  /** Number of properties last year. */
  properties_last_yr?: Maybe<Scalars['Int']>;
  /** Total cost of properties in USD. */
  actual_costs_usd?: Maybe<Scalars['Float']>;
  /** Total cost of properties. */
  actual_costs?: Maybe<Scalars['Float']>;
  /** Head count. */
  head_count?: Maybe<Scalars['Float']>;
  /** Desk count. */
  desk_count?: Maybe<Scalars['Float']>;
  /** Occupancy. */
  occupancy?: Maybe<Scalars['Float']>;
  /** Cost per person. */
  cost_per_person?: Maybe<Scalars['Float']>;
  /** Unit of currency. */
  currency?: Maybe<CurrencyUnit>;
  /** Unit of measurement. */
  unit_of_measurement?: Maybe<AreaUnit>;
};

/** Represents settings for Lease Event alerts categories. */
export type LeaseEventAlertCategory = {
  __typename?: 'LeaseEventAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Lease Event alert. */
export type LeaseEventAlertSettingData = {
  __typename?: 'LeaseEventAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: LeaseEventAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Lease Event alerts. */
export type LeaseEventAlertSettings = {
  __typename?: 'LeaseEventAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Notice period for medium priority alerts. */
  notice_period_time_medium: Scalars['Int'];
  /** Notice period for high priority alerts. */
  notice_period_time_high: Scalars['Int'];
  /** Preferred unit of measurment. */
  preferred_unit_of_measurement: AreaUnit;
  /** Preferred unit of currency. */
  preferred_unit_of_currency: CurrencyUnit;
};

/**
 * Input for updating / creating settings for Lease Event alerts.
 * See LeaseEventAlertSettings type for details.
 */
export type LeaseEventAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  notice_period_time_medium: Scalars['Int'];
  notice_period_time_high: Scalars['Int'];
  preferred_unit_of_measurement: AreaUnit;
  preferred_unit_of_currency: CurrencyUnit;
};

/** Data for lease event alerts. */
export type LeaseEventInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Tenure key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Event id. */
  event_id?: Maybe<Scalars['String']>;
  /** Event notice date specified as Unix timestamp. */
  event_notice_date?: Maybe<Scalars['String']>;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Event created date specified as Unix timestamp. */
  event_created_date?: Maybe<Scalars['String']>;
  /** Notice period time medium. */
  notice_period_time_medium?: Maybe<Scalars['String']>;
  /** Preferred unit of currency. */
  preferred_unit_of_currency?: Maybe<Scalars['String']>;
  /** Tenure size. */
  tenure_size?: Maybe<Scalars['String']>;
  /** Property ovcp id. */
  property_ovcp_id?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Transaction found. */
  transaction_found?: Maybe<Scalars['String']>;
  /** Preferred unit of measurement. */
  preferred_unit_of_measurement?: Maybe<Scalars['String']>;
  /** City name. */
  city_name?: Maybe<Scalars['String']>;
  /** Tenure type. */
  tenure_type?: Maybe<Scalars['String']>;
  /** Event type. */
  event_type?: Maybe<Scalars['String']>;
  /** Tenure cost. */
  tenure_cost?: Maybe<Scalars['String']>;
  /** State name. */
  state_name?: Maybe<Scalars['String']>;
  /** Annual cost. */
  annual_cost?: Maybe<Scalars['String']>;
  /** Country name. */
  country_name?: Maybe<Scalars['String']>;
  /** Property type. */
  property_type?: Maybe<Scalars['String']>;
  /** Notice period. */
  notice_period?: Maybe<Scalars['String']>;
  /** Notice period time high. */
  notice_period_time_high?: Maybe<Scalars['String']>;
};

/** Represents log record. */
export type Log = {
  __typename?: 'Log';
  /** Log id. */
  id: Scalars['ID'];
  /** Impacted entity id. */
  entity_id: Scalars['String'];
  /** Combination of entity_type and event_type. */
  entity_event: Scalars['String'];
  /** Entity type. */
  entity_type: EntityType;
  /** Event type. */
  event_type: EventType;
  /** Stringified JSON value. Parse it accordingly. */
  entity_old_value?: Maybe<Scalars['String']>;
  /** Stringified JSON value. Parse it accordingly. */
  entity_new_value?: Maybe<Scalars['String']>;
  /** Timestamp of the change. */
  changed_at?: Maybe<Scalars['String']>;
  /** Change performer. */
  changed_by?: Maybe<Scalars['String']>;
  /** Action error. */
  error?: Maybe<Scalars['String']>;
};

/** Represents mapped data standards */
export type Mapped = {
  __typename?: 'Mapped';
  id: Scalars['ID'];
  source_value: Scalars['String'];
  target_value: Scalars['String'];
};

/** Possible types of data standards */
export enum MappingType {
  ClientValueMapping = 'client_value_mapping',
  DataStandardsErrors = 'data_standards_errors',
}

/** Represents settings for Market Benchmark alerts categories. */
export type MarketBenchmarkAlertCategory = {
  __typename?: 'MarketBenchmarkAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Market Benchmark alert. */
export type MarketBenchmarkAlertSettingData = {
  __typename?: 'MarketBenchmarkAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: MarketBenchmarkAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Lease Cost (Market Benchmark) alerts. */
export type MarketBenchmarkAlertSettings = {
  __typename?: 'MarketBenchmarkAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Notice Period in Months for Alert Generation */
  notice_period: Scalars['Int'];
  /** Acceptable Variance on Current Cost */
  current_cost: Scalars['Int'];
};

/**
 * Input for updating / creating settings for Lease Cost (Market Benchmark) alerts.
 * See MarketBenchmarkAlertSettings type for details.
 */
export type MarketBenchmarkAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  notice_period: Scalars['Int'];
  current_cost: Scalars['Int'];
};

export type MarketBenchmarkInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Event Id. */
  event_id?: Maybe<Scalars['String']>;
  /** Tenure key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Event type. */
  event_type?: Maybe<Scalars['String']>;
  /** Event created on. */
  event_created_on?: Maybe<Scalars['String']>;
  /** Property Id. */
  property_id?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Tenure size. */
  tenure_size?: Maybe<Scalars['String']>;
  /** Tenure name. */
  tenure_name?: Maybe<Scalars['String']>;
  /** Tenure type. */
  tenure_type?: Maybe<Scalars['String']>;
  /** Property type. */
  property_type?: Maybe<Scalars['String']>;
  /** Base rent. */
  base_rent?: Maybe<Scalars['String']>;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Prediction record. */
  prediction_record?: Maybe<MarketBenchmarkInsightsAlertDataPredictionRecord>;
};

export type MarketBenchmarkInsightsAlertDataPredictionRecord = {
  /** Rent type. */
  rent_type?: Maybe<Scalars['String']>;
  /** Space type. */
  space_type?: Maybe<Scalars['String']>;
  /** Low range. */
  low_range?: Maybe<Scalars['Float']>;
  /** High range. */
  high_range?: Maybe<Scalars['Float']>;
  /** Supporting data count. */
  supporting_data_count?: Maybe<Scalars['Int']>;
  /** Supporting data points. */
  supporting_data_points?: Maybe<
    Array<MarketBenchmarkInsightsAlertDataSupportingDataPoint>
  >;
};

export type MarketBenchmarkInsightsAlertDataSupportingDataPoint = {
  /** Ground truth. */
  ground_truth?: Maybe<Scalars['Float']>;
  /** Prediction. */
  prediction?: Maybe<Scalars['Float']>;
  /** MDM Id. */
  mdm_id?: Maybe<Scalars['String']>;
  /** Space Id. */
  space_id?: Maybe<Scalars['String']>;
  /** Property Id. */
  property_id?: Maybe<Scalars['String']>;
  /** Space type. */
  space_type?: Maybe<Scalars['String']>;
  /** Costar Id. */
  costar_id?: Maybe<Scalars['String']>;
  /** Date on market. */
  date_on_market?: Maybe<Scalars['String']>;
  /** Rent type. */
  rent_type?: Maybe<Scalars['String']>;
  /** Latitude. */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude. */
  longitude?: Maybe<Scalars['Float']>;
  /** Geohash. */
  geohash_12?: Maybe<Scalars['String']>;
  /** Lease transaction Id. */
  lease_transaction_id?: Maybe<Scalars['String']>;
};

/** Represents result of publishing or deactivating custom report. */
export type MoveCustomReport = {
  __typename?: 'MoveCustomReport';
  /** Updated top-level report. */
  updated_report: Report;
  /** List of reports deactivated in the process (e.g. user saved views). */
  deactivated_reports: Array<Report>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Adds new application.
   * Allowed roles: system admin.
   */
  addApplication: Application;
  /**
   * Adds new category.
   * Allowed roles: system admin.
   */
  addCategory: Category;
  /**
   * Adds new country.
   * Allowed roles: system admin.
   */
  addCountry: Country;
  /** Adds an alert thread's user comment. */
  addInsightsAlertUserComment: Scalars['Boolean'];
  /**
   * Adds new region.
   * Allowed roles: system admin.
   */
  addRegion: Region;
  /**
   * Adds saved view.
   * Allowed roles: user (own resources), system admin.
   */
  addSavedViewToUser: UserCustomReport;
  /**
   * Adds tenant profile (name, ovc id, code, applications, features, etc.). Updates or creates user profile for specified tenant admin.
   * Allowed roles: product support, system admin.
   */
  addTenant: Tenant;
  /**
   * Adds tenant alert settings.
   * Allowed roles: tenant admin, system admin.
   */
  addTenantAlertSettings: Tenant;
  /**
   * Adds tenant configuration data.
   * Allowed roles: tenant admin, system admin.
   */
  addTenantConfigurationData: Tenant;
  /**
   * Add snowflake share for custom reports.
   * Allowed roles: product support, system admin.
   */
  addTenantDataShareForCustomReports: Tenant;
  /**
   * Adds new tenant report.
   * Allowed roles: system admin, client admin.
   */
  addTenantReport: Tenant;
  /**
   * Adds user(s) to tenant, including provisioning to Tableau Server / Snowflake.
   * If more than one user is supplied, then provisioning is done in the background.
   * Allowed roles: tenant admin, system admin.
   */
  addTenantUsers: Array<User>;
  /**
   * Adds custom category to report.
   * Allowed roles: user (own resources), system admin.
   */
  addUserCategoryReport: User;
  /**
   * Adds report to the list of favorite reports.
   * Allowed roles: user (own resources), system admin.
   */
  addUserFavoriteReport: User;
  /**
   * Adds report to list of hidden reports.
   * Allowed roles: user (own resources), system admin.
   */
  addUserHiddenReport: User;
  /**
   * Creates user persona.
   * Allowed roles: user (own resources), system admin.
   */
  addUserPersona: User;
  /**
   * Adds new work area.
   * Allowed roles: system admin.
   */
  addWorkArea: WorkArea;
  /**
   * Adds demo covid-19 insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantCovid19WorkOrderInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo facilities experience insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantFacilitiesExperienceInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo lease event insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantLeaseEventInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo market benchmark insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantMarketBenchmarkInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo occupancy efficiency insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantOccupancyEfficiencyInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo same day work order completion insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantSameDayWorkOrderCompletionInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo sublease risk insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantSubleaseRiskInsightsAlert: Scalars['Boolean'];
  /**
   * Adds demo work order volume insights alert for a given demo client. It is used to generate mocked alerts for demo clients.
   * Allowed roles: product support, tenant admin.
   */
  createDemoTenantWorkOrderVolumeInsightsAlert: Scalars['Boolean'];
  /**
   * Deactivates custom report.
   * Allowed roles: tenant admin, system admin.
   */
  deactivateCustomReport: MoveCustomReport;
  /**
   * Deactivates user from tenant, including deleting from  Tableau Server / Snowflake.
   * Allowed roles: tenant admin, system admin.
   */
  deactivateUserFromTenant: User;
  /**
   * Removes application.
   * Allowed roles: system admin.
   */
  deleteApplication: Application;
  /**
   * Removes category.
   * Allowed roles: system admin.
   */
  deleteCategory: Category;
  /**
   * Removes country.
   * Allowed roles: system admin.
   */
  deleteCountry: Country;
  /**
   * Removes keyword.
   * Allowed roles: product support, system admin.
   */
  deleteKeyword: Keyword;
  /**
   * Removes region.
   * Allowed roles: system admin.
   */
  deleteRegion: Region;
  /**
   * Deletes mapping for a report.
   * Allowed roles: product support, system admin.
   */
  deleteReportMapping: ReportMapping;
  /**
   * Removes saved view.
   * Allowed roles: user (own resources), system admin.
   */
  deleteSavedViewFromUser: User;
  /**
   * Initiates process of tenant removal. It takes care of updating tenant's dependencies such as user profiles. Only allowed for WDQA and DEMO environments.
   * Allowed roles: system admin, product support.
   */
  deleteTenant: Tenant;
  /**
   * Delete snowflake share for custom reports.
   * Allowed roles: product support, system admin.
   */
  deleteTenantDataShareForCustomReports: Tenant;
  /**
   * Removes tenant. It takes care of updating tenant's dependencies such as user profiles. This should be used in non-prod environment only.
   * Allowed roles: system admin.
   */
  deleteTenantFull: Scalars['String'];
  /**
   * Removes tenant report.
   * Allowed roles: system admin, client admin.
   */
  deleteTenantReport: Tenant;
  /**
   * Deletes tenant role
   * Allowed roles: tenant admin, system admin.
   */
  deleteTenantRole: Tenant;
  /**
   * Removes user profile. Non-admin users can remove their personas only. Only admin can remove main profile.
   * Allowed roles: user (own resources), system admin.
   */
  deleteUser: User;
  /**
   * Deletes user from tenant, including deleting from Tableau Server / Snowflake.
   * Allowed roles: tenant admin, system admin.
   */
  deleteUserFromTenant: User;
  /**
   * Removes work area.
   * Allowed roles: system admin.
   */
  deleteWorkArea: WorkArea;
  /** Generates a Tableau Server VizPortal external report URL with a trusted ticket. */
  generateTrustedExternalReportUrl: TrustedReportUrl;
  /** Generates a Tableau Server VizPortal report URL with a trusted ticket. */
  generateTrustedReportUrl: TrustedReportUrl;
  /**
   * Gets sas url for template download.
   * Allowed roles: tenant admin, tenant data governor, system admin.
   */
  getDownloadTemplateSasUrl: Scalars['String'];
  /**
   * Logs client-side error.
   * Allowed roles: authenticated users.
   */
  logError?: Maybe<Scalars['Boolean']>;
  /**
   * Logs report performance.
   * Allowed roles: authenticated users.
   */
  logPerformance?: Maybe<Scalars['Boolean']>;
  /**
   * Logs user activity.
   * Allowed roles: authenticated users.
   */
  logUserActivity?: Maybe<Scalars['Boolean']>;
  /**
   * Logins user. If user's profile exists, returns that profile.
   * Allowed roles: user (own resources), system admin.
   */
  loginUser: User;
  /**
   * Logs user out. If user's profile exists, clears that profile.
   * Allowed roles: user (own resources).
   */
  logoutUser: Scalars['Boolean'];
  /**
   * Creates / updates mapping for data standards.
   * Allowed roles: tenant admin, system admin.
   */
  postMappingDataStandard: Array<PostMappingDataStandardResponse>;
  /**
   * Previews custom report.
   * Allowed roles: tenant admin, system admin.
   */
  previewCustomReport: Report;
  /**
   * Promotes release.
   * Allowed roles: product support, system admin.
   */
  promoteRelease: ReleasesRecord;
  /**
   * Provisions tenant by creating relevant back-end resources. Tenant has to be added first with `addTenant` mutation. Asynchronous.
   * Allowed roles: product support, system admin.
   */
  provisionTenant: Tenant;
  /**
   * Publishes custom report.
   * Allowed roles: tenant admin, system admin.
   */
  publishCustomReport: MoveCustomReport;
  /**
   * Reactivates client's user. Results in resending activation email to the user.
   * Allowed roles: tenant admin, system admin.
   */
  reactivateTenantUser: User;
  /**
   * Reactivates user to tenant,  including provisioning to Tableau Server / Snowflake.
   * Allowed roles: tenant admin, system admin.
   */
  reactivateUserToTenant: User;
  /**
   * Refreshes Tenant users.
   * Allowed roles: system admin, client admin.
   */
  refreshTenantUsers: Array<User>;
  /**
   * Removes tenant insights alerts from demo tenant.
   * Allowed roles: tenant admin, system admin.
   */
  removeDemoTenantInsightsAlerts: Scalars['Boolean'];
  /** Removes an alert thread's assigned user. */
  removeInsightsAlertAssignedUser: Scalars['Boolean'];
  /**
   * Removes report from the list of favorite reports.
   * Allowed roles: user (own resources), system admin.
   */
  removeUserFavoriteReport: User;
  /**
   * Removes report from list of hidden reports.
   * Allowed roles: user (own resources), system admin.
   */
  removeUserHiddenReport: User;
  /**
   * Replaces list of favorite reports.
   * Allowed roles: user (own resources), system admin.
   */
  replaceUserFavoriteReports: User;
  /**
   * Gets sas urls for container access.
   * Allowed roles tenant admin, tenant data governor, system admin.
   */
  resetContainerAccessSasUrl: DataLoadsResetContainers;
  /**
   * Resets tenant insights alerts for demo tenant.
   * Allowed roles: tenant admin, system admin.
   */
  resetDemoTenantInsightsAlerts: Scalars['Boolean'];
  /**
   * Resets settings and alerts for a given combination of user and demo tenant.
   * Allowed roles: user (own resources), system admin.
   */
  resetDemoUser: User;
  /**
   * Resets password for a client's user. Results in sending an email to the user with a link to password reset.
   * Allowed roles: tenant admin, system admin.
   */
  resetTenantUserPassword: User;
  /** Updates an alert thread's assigned user. */
  setInsightsAlertAssignedUser: Scalars['Boolean'];
  /** Updates an alert thread's ROI data. */
  setInsightsAlertRoiData: Scalars['Boolean'];
  /** Updates an alert thread's severity. */
  setInsightsAlertSeverity: Scalars['Boolean'];
  /** Updates an alert thread's status. */
  setInsightsAlertStatus: Scalars['Boolean'];
  /** Updates an alert thread's user importance. */
  setInsightsAlertUserImportance: Scalars['Boolean'];
  /** Marks an alert thread's user status to read. */
  setInsightsAlertUserRead: Scalars['Boolean'];
  /** Sets filter for alert severity */
  setInsightsInboxAlertSeverity?: Maybe<Scalars['Boolean']>;
  /** Sets sort direction by alert created date. */
  setInsightsInboxAlertSortOrder?: Maybe<Scalars['Boolean']>;
  /** Sets filter for alert type. */
  setInsightsInboxAlertTypeFilter?: Maybe<Scalars['Boolean']>;
  /** Sets filter for user assigned */
  setInsightsInboxAlertUserAssignedTo?: Maybe<Scalars['Boolean']>;
  /** Sets filter for inbox importance. */
  setInsightsInboxImportanceFilter?: Maybe<Scalars['Boolean']>;
  /** Sets recently selected alert. */
  setInsightsInboxRecentAlert?: Maybe<Scalars['Boolean']>;
  /** Removes recently selected alert. */
  setInsightsInboxRemoveRecentAlert?: Maybe<Scalars['Boolean']>;
  /**
   * Gets list of reports from `Custom Unpublished` project on tenant's Tableau Site and updates list of tenant's custom reports.
   * Allowed roles: product support, system admin.
   */
  syncTenantUnpublishedReports: Tenant;
  /**
   * Pulls list of fresh baseline reports and updates tenant accordingly.
   * Allowed roles: product support, system admin.
   */
  syncTenants: Array<Tenant>;
  /**
   * Synchronizes user country based on request IP.
   * Allowed roles: user (own resources), system admin.
   */
  syncUser: User;
  /**
   * Tries to synchronize user with Tableau / Snowflake. It does not synchronize user if she does not need such synchronization and returns false. Otherwise, if synchronization has been performed, it returns true.
   * Allowed roles: user (own resources), system admin.
   */
  syncUserProvisioning: Scalars['Boolean'];
  /**
   * Synchronizes tenant KPI data.
   * Allowed roles: user (own resources), system admin.
   */
  syncUserTenantKpiData: User;
  /**
   * Unlock client's user account.
   * Allowed roles: tenant admin, system admin.
   */
  unlockTenantUser: User;
  /**
   * Unsuspend client's user account.
   * Allowed roles: tenant admin, system admin.
   */
  unsuspendTenantUser: User;
  /**
   * Updates application.
   * Allowed roles: system admin.
   */
  updateApplication: Application;
  /**
   * Updates category.
   * Allowed roles: system admin.
   */
  updateCategory: Category;
  /**
   * Updates country.
   * Allowed roles: system admin.
   */
  updateCountry: Country;
  updateProductSettingsReport: Report;
  /**
   * Updates region.
   * Allowed roles: system admin.
   */
  updateRegion: Region;
  /**
   * Updates release.
   * Allowed roles: product support, system admin.
   */
  updateRelease: ReleasesRecord;
  /**
   * Updates report.
   * Allowed roles: tenant admin, system admin.
   */
  updateReport: Report;
  /**
   * Updates tenant.
   * Allowed roles: product support, system admin.
   */
  updateTenant: Tenant;
  /**
   * Update snowflake share for custom reports.
   * Allowed roles: product support, system admin.
   */
  updateTenantDataShareForCustomReports: Tenant;
  /** Updates tenant's KPI settings. */
  updateTenantKpiSettings: Tenant;
  updateTenantReports: Tenant;
  /**
   * Updates user settings for tenant, including change of site group in Tableau Server.
   * Allowed roles: tenant admin, system admin.
   */
  updateTenantUser: User;
  /**
   * Updates user profile.
   * Allowed roles: user (own resources), system admin.
   */
  updateUser: User;
  /** Updates user's terms and conditions declaration. */
  updateUserAgreement: User;
  /**
   * Updates user's object.
   * Allowed roles: product support, system admin.
   */
  updateUserExtended: User;
  /**
   * Updates user's default profile. Default profile is stored on main account.
   * Allowed roles: user (own resources), system admin.
   */
  updateUserTenantDefaultProfile: User;
  /**
   * Updates user's regular settings for a tenant.
   * Allowed roles: user (own resources), system admin.
   */
  updateUserTenantRegularSettings: User;
  /**
   * Updates user's universal filters.
   * Allowed roles: user (own resources), system admin.
   */
  updateUserUniversalFilters: User;
  /**
   * Updates work area.
   * Allowed roles: system admin.
   */
  updateWorkArea: WorkArea;
  /**
   * Uploads metada.json file and returns sas_url and file_path
   * Allowed roles:  tenant admin, tenant data governor, system admin.
   */
  uploadFileSasUrl: DataLoadsFileUploadSasUrl;
  /**
   * Creates or updates a keyword.
   * Allowed roles: product support, system admin.
   */
  upsertKeyword: Keyword;
  /**
   * Creates or updates mapping for a report.
   * Allowed roles: product support, system admin.
   */
  upsertReportMapping: ReportMapping;
  /**
   * Creates or updates tenant role
   * Allowed roles: tenant admin, system admin.
   */
  upsertTenantRole: Tenant;
  /**
   * Upsert tenant self serve analytics settings.
   * Allowed roles: tenant admin, system admin.
   */
  upsertTenantSelfServeAnalyticsSettings: Tenant;
  /** Updates user's tenant work role. */
  upsertUserWorkRole: User;
  /** Validates if external report can be embedded inside Azara. */
  validateExternalReportEmbedUrl: Scalars['Boolean'];
};

export type MutationAddApplicationArgs = {
  data: CreateApplicationInput;
};

export type MutationAddCategoryArgs = {
  data: CreateCategoryInput;
};

export type MutationAddCountryArgs = {
  data: CreateCountryInput;
};

export type MutationAddInsightsAlertUserCommentArgs = {
  where: WhereAddInsightsAlertUserCommentInput;
};

export type MutationAddRegionArgs = {
  data: CreateRegionInput;
};

export type MutationAddSavedViewToUserArgs = {
  data: CreateReportSavedViewInput;
  where: WhereUpdateUserInput;
};

export type MutationAddTenantArgs = {
  data: TenantCreateInput;
};

export type MutationAddTenantAlertSettingsArgs = {
  data: TenantAlertSettingsInput;
  where: TenantWhereInput;
};

export type MutationAddTenantConfigurationDataArgs = {
  data: TenantConfigurationDataInput;
  where: TenantWhereInput;
};

export type MutationAddTenantDataShareForCustomReportsArgs = {
  where: TenantWhereInput;
  data: AddTenantDataShareForCustomReports;
};

export type MutationAddTenantReportArgs = {
  where: TenantWhereInput;
  data: CreateReportInput;
};

export type MutationAddTenantUsersArgs = {
  data: Array<CreateTenantUserInput>;
  where: TenantWhereInput;
};

export type MutationAddUserCategoryReportArgs = {
  data: UserCatReportInput;
  where: WhereUpdateUserInput;
};

export type MutationAddUserFavoriteReportArgs = {
  reportId: Scalars['ID'];
  where: WhereUpdateUserInput;
};

export type MutationAddUserHiddenReportArgs = {
  reportId: Scalars['ID'];
  where: WhereUpdateUserInput;
};

export type MutationAddUserPersonaArgs = {
  data: CreateUserPersonaInput;
};

export type MutationAddWorkAreaArgs = {
  data: CreateWorkAreaInput;
};

export type MutationCreateDemoTenantCovid19WorkOrderInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateCovid19WorkOrderDemoInsightsAlertInput;
};

export type MutationCreateDemoTenantFacilitiesExperienceInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateFacilitiesExperienceDemoInsightsAlertInput;
};

export type MutationCreateDemoTenantInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateDemoInsightsAlertInput;
};

export type MutationCreateDemoTenantLeaseEventInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateLeaseEventDemoInsightsAlertInput;
};

export type MutationCreateDemoTenantMarketBenchmarkInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateMarketBenchmarkDemoInsightsAlertInput;
};

export type MutationCreateDemoTenantOccupancyEfficiencyInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateOccupancyEfficiencyInsightsAlertInput;
};

export type MutationCreateDemoTenantSameDayWorkOrderCompletionInsightsAlertArgs =
  {
    where: WhereTenantOvcIdInput;
    data: WhereCreateSameDayWorkOrderCompletionInsightsAlertInput;
  };

export type MutationCreateDemoTenantSubleaseRiskInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateSubleaseRiskInsightsAlertInput;
};

export type MutationCreateDemoTenantWorkOrderVolumeInsightsAlertArgs = {
  where: WhereTenantOvcIdInput;
  data: WhereCreateWorkOrderVolumeDemoInsightsAlertInput;
};

export type MutationDeactivateCustomReportArgs = {
  where: WhereMoveReportInput;
};

export type MutationDeactivateUserFromTenantArgs = {
  tenant_id: Scalars['ID'];
  where: WhereDeactivateUserFromTenantInput;
};

export type MutationDeleteApplicationArgs = {
  where: WhereUpdateApplicationInput;
};

export type MutationDeleteCategoryArgs = {
  where: WhereCategoryInput;
};

export type MutationDeleteCountryArgs = {
  where: WhereGeoInput;
};

export type MutationDeleteKeywordArgs = {
  where: WhereKeywordInput;
};

export type MutationDeleteRegionArgs = {
  where: WhereGeoInput;
};

export type MutationDeleteReportMappingArgs = {
  where: WhereReportMappingInput;
};

export type MutationDeleteSavedViewFromUserArgs = {
  reportId: Scalars['ID'];
  where: WhereUpdateUserInput;
};

export type MutationDeleteTenantArgs = {
  where: WhereTenantOvcIdInput;
};

export type MutationDeleteTenantDataShareForCustomReportsArgs = {
  where: TenantWhereInput;
  data: DeleteTenantDataShareForCustomReports;
};

export type MutationDeleteTenantFullArgs = {
  where: WhereTenantOvcIdInput;
};

export type MutationDeleteTenantReportArgs = {
  where: WhereDeleteReportInput;
};

export type MutationDeleteTenantRoleArgs = {
  where: WhereTenantRoleInput;
};

export type MutationDeleteUserArgs = {
  where: WhereUpdateUserInput;
};

export type MutationDeleteUserFromTenantArgs = {
  tenant_id: Scalars['ID'];
  where: WhereDeleteTenantUserInput;
};

export type MutationDeleteWorkAreaArgs = {
  where: WhereUpdateWorkAreaInput;
};

export type MutationGenerateTrustedExternalReportUrlArgs = {
  where: WhereGenerateTrustedExternalReportUrlInput;
};

export type MutationGenerateTrustedReportUrlArgs = {
  where: WhereGenerateTrustedReportUrlInput;
};

export type MutationGetDownloadTemplateSasUrlArgs = {
  where: WhereDownloadTemplateSasUrlInput;
};

export type MutationLogErrorArgs = {
  data: ErrorLogInput;
};

export type MutationLogPerformanceArgs = {
  data: ReportPerformanceLogInput;
};

export type MutationLoginUserArgs = {
  user_id: Scalars['ID'];
  tenant_ovc_id?: Maybe<Scalars['ID']>;
};

export type MutationLogoutUserArgs = {
  user_id: Scalars['ID'];
  tenant_id: Scalars['ID'];
};

export type MutationPostMappingDataStandardArgs = {
  where: WhereTenantOvcIdInput;
  data: Array<PostMappingDataStandardInput>;
};

export type MutationPreviewCustomReportArgs = {
  where: WhereMoveReportInput;
};

export type MutationPromoteReleaseArgs = {
  where: WhereReleaseInput;
  data: PromoteReleaseInput;
};

export type MutationProvisionTenantArgs = {
  where: TenantWhereInput;
};

export type MutationPublishCustomReportArgs = {
  where: WhereMoveReportInput;
};

export type MutationReactivateTenantUserArgs = {
  tenant_id: Scalars['ID'];
  user_id: Scalars['ID'];
};

export type MutationReactivateUserToTenantArgs = {
  tenant_id: Scalars['ID'];
  where: WhereReactivateUserToTenantInput;
};

export type MutationRefreshTenantUsersArgs = {
  where: TenantWhereInput;
};

export type MutationRemoveDemoTenantInsightsAlertsArgs = {
  where: WhereTenantOvcIdInput;
};

export type MutationRemoveInsightsAlertAssignedUserArgs = {
  where: WhereRemoveInsightsAlertAssignedUserInput;
};

export type MutationRemoveUserFavoriteReportArgs = {
  reportId: Scalars['ID'];
  where: WhereUpdateUserInput;
};

export type MutationRemoveUserHiddenReportArgs = {
  reportId: Scalars['ID'];
  where: WhereUpdateUserInput;
};

export type MutationReplaceUserFavoriteReportsArgs = {
  reportIds: Array<Scalars['ID']>;
  where: WhereUpdateUserInput;
};

export type MutationResetContainerAccessSasUrlArgs = {
  where: WhereDataLoadsResetContainersInput;
};

export type MutationResetDemoTenantInsightsAlertsArgs = {
  where: WhereTenantOvcIdInput;
};

export type MutationResetDemoUserArgs = {
  where: WhereUserTenantInput;
};

export type MutationResetTenantUserPasswordArgs = {
  tenant_id: Scalars['ID'];
  user_id: Scalars['ID'];
};

export type MutationSetInsightsAlertAssignedUserArgs = {
  where: WhereSetInsightsAlertAssignedUserInput;
};

export type MutationSetInsightsAlertRoiDataArgs = {
  where: WhereSetInsightsAlertRoiDataInput;
};

export type MutationSetInsightsAlertSeverityArgs = {
  where: WhereSetInsightsAlertSeverityInput;
};

export type MutationSetInsightsAlertStatusArgs = {
  where: WhereSetInsightsAlertStatusInput;
};

export type MutationSetInsightsAlertUserImportanceArgs = {
  where: WhereSetInsightsAlertUserImportanceInput;
};

export type MutationSetInsightsAlertUserReadArgs = {
  where: WhereSetInsightsAlertUserReadInput;
};

export type MutationSetInsightsInboxAlertSeverityArgs = {
  alertSeverityFilter: InsightsAlertSeverityFilter;
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetInsightsInboxAlertSortOrderArgs = {
  createdSortOrder: InsightsAlertSortOrder;
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetInsightsInboxAlertTypeFilterArgs = {
  alertTypeFilter: InsightsAlertTypeFilter;
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetInsightsInboxAlertUserAssignedToArgs = {
  tenantId: Scalars['ID'];
  userAssigned: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetInsightsInboxImportanceFilterArgs = {
  importanceFilter: InsightsAlertImportanceFilter;
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetInsightsInboxRecentAlertArgs = {
  inboxType: InsightsAlertInbox;
  tenantId: Scalars['ID'];
  threadId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetInsightsInboxRemoveRecentAlertArgs = {
  inboxType: InsightsAlertInbox;
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSyncTenantUnpublishedReportsArgs = {
  where: TenantWhereInput;
};

export type MutationSyncTenantsArgs = {
  where: TenantsWhereUpdateInput;
};

export type MutationSyncUserArgs = {
  where: WhereUpdateUserInput;
};

export type MutationSyncUserProvisioningArgs = {
  tenantId: Scalars['ID'];
  where: WhereUpdateUserInput;
};

export type MutationSyncUserTenantKpiDataArgs = {
  tenantId: Scalars['ID'];
  tenantOvcId: Scalars['String'];
  where: WhereUpdateUserInput;
};

export type MutationUnlockTenantUserArgs = {
  tenant_id: Scalars['ID'];
  user_id: Scalars['ID'];
};

export type MutationUnsuspendTenantUserArgs = {
  tenant_id: Scalars['ID'];
  user_id: Scalars['ID'];
};

export type MutationUpdateApplicationArgs = {
  data: UpdateApplicationInput;
  where: WhereUpdateApplicationInput;
};

export type MutationUpdateCategoryArgs = {
  data: UpdateCategoryInput;
  where: WhereCategoryInput;
};

export type MutationUpdateCountryArgs = {
  data: UpdateCountryInput;
  where: WhereGeoInput;
};

export type MutationUpdateProductSettingsReportArgs = {
  where: ReportWhereInput;
  data: ProductSettingsReportInput;
};

export type MutationUpdateRegionArgs = {
  data: UpdateRegionInput;
  where: WhereGeoInput;
};

export type MutationUpdateReleaseArgs = {
  where: WhereReleaseInput;
  data: UpdateReleaseInput;
};

export type MutationUpdateReportArgs = {
  data: UpdateReportInput;
  where: WhereUpdateReportInput;
};

export type MutationUpdateTenantArgs = {
  data: TenantUpdateInput;
  where: TenantWhereInput;
};

export type MutationUpdateTenantDataShareForCustomReportsArgs = {
  where: TenantWhereInput;
  data: UpdateTenantDataShareForCustomReports;
};

export type MutationUpdateTenantKpiSettingsArgs = {
  where: TenantWhereInput;
  data: Array<TenantKpiSettingsInput>;
};

export type MutationUpdateTenantReportsArgs = {
  where: TenantWhereInput;
  data: UpdateTenantReportsInput;
};

export type MutationUpdateTenantUserArgs = {
  data: UpdateTenantUserInput;
  where: TenantWhereInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  where: WhereUpdateUserInput;
};

export type MutationUpdateUserAgreementArgs = {
  where: WhereGetUserInput;
  data: UserAgreementInput;
};

export type MutationUpdateUserExtendedArgs = {
  where: WhereUpdateUserInput;
  data: UpdateUserExtendedInput;
};

export type MutationUpdateUserTenantDefaultProfileArgs = {
  where: WhereUserTenantInput;
  data: UserTenantRegularSettingsDefaultProfileInput;
};

export type MutationUpdateUserTenantRegularSettingsArgs = {
  where: WhereUserTenantInput;
  data: UserTenantRegularSettingsInput;
};

export type MutationUpdateUserUniversalFiltersArgs = {
  data: UserTenantUniversalFiltersInput;
  where: WhereUpdateUserInput;
};

export type MutationUpdateWorkAreaArgs = {
  data: UpdateWorkAreaInput;
  where: WhereUpdateWorkAreaInput;
};

export type MutationUploadFileSasUrlArgs = {
  where: WhereDataLoadsFileUploadSasUrl;
};

export type MutationUpsertKeywordArgs = {
  where?: Maybe<WhereKeywordInput>;
  data: UpsertKeywordDataInput;
};

export type MutationUpsertReportMappingArgs = {
  where?: Maybe<WhereReportMappingInput>;
  data: UpsertReportMappingDataInput;
};

export type MutationUpsertTenantRoleArgs = {
  where: WhereTenantRoleInput;
  data: RoleInput;
};

export type MutationUpsertTenantSelfServeAnalyticsSettingsArgs = {
  where: TenantWhereInput;
  data: UpsertTenantSelfServeAnalyticsSettings;
};

export type MutationUpsertUserWorkRoleArgs = {
  where: WhereGetUserInput;
  data: UserTenantWorkRoleInput;
};

export type MutationValidateExternalReportEmbedUrlArgs = {
  where: WhereValidateExternalReportEmbedUrlInput;
};

/** Represents settings for Occupancy Efficiency alerts categories. */
export type OccupancyEfficiencyAlertCategory = {
  __typename?: 'OccupancyEfficiencyAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Occupancy Efficiency alert. */
export type OccupancyEfficiencyAlertSettingData = {
  __typename?: 'OccupancyEfficiencyAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: OccupancyEfficiencyAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Occupancy Efficiency alerts. */
export type OccupancyEfficiencyAlertSettings = {
  __typename?: 'OccupancyEfficiencyAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Headcount density for EMEA */
  headcount_density_emea: Scalars['Int'];
  /** Headcount density for Americas */
  headcount_density_am: Scalars['Int'];
  /** Headcount density for Asia/Pacific */
  headcount_density_apac: Scalars['Int'];
  /** Headcount density for Other/None */
  headcount_density_other_or_none: Scalars['Int'];
  /** High occupancy rate */
  occupancy_rate_high: Scalars['Float'];
  /** Low occupancy rate */
  occupancy_rate_low: Scalars['Float'];
};

/**
 * Input for updating / creating settings for Occupancy Efficiency alerts.
 * See OccupancyEfficiencyAlertSettings type for details.
 */
export type OccupancyEfficiencyAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  headcount_density_emea: Scalars['Int'];
  headcount_density_am: Scalars['Int'];
  headcount_density_apac: Scalars['Int'];
  headcount_density_other_or_none: Scalars['Int'];
  occupancy_rate_high: Scalars['Float'];
  occupancy_rate_low: Scalars['Float'];
};

/** Data for occupancy efficiency alerts. */
export type OccupancyEfficiencyInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Property Id. */
  property_id?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Vacancy rate trigger info. */
  vacancy_rate_trigger_info?: Maybe<OccupancyEfficiencyTemplateDataVacancyRateTriggerInfoRecord>;
  /** Occupancy rate trigger info. */
  occupancy_rate_trigger_info?: Maybe<OccupancyEfficiencyTemplateDataOccupancyRateTriggerInfoRecord>;
  /** Headcount density trigger info. */
  headcount_density_trigger_info?: Maybe<OccupancyEfficiencyTemplateDataHeadcountDensityTriggerInfoRecord>;
};

/** Input data for headcount density trigger info record. */
export type OccupancyEfficiencyTemplateDataHeadcountDensityTriggerInfoRecord = {
  /** JLL region. */
  jll_region?: Maybe<Scalars['String']>;
  /** Headcount density. */
  headcount_density?: Maybe<Scalars['Int']>;
  /** Client headcount density. */
  client_headcount_density?: Maybe<Scalars['Int']>;
};

/** Input data for occupancy rate trigger info record. */
export type OccupancyEfficiencyTemplateDataOccupancyRateTriggerInfoRecord = {
  /** Occupancy rate percentage. */
  occupancy_rate_percentage?: Maybe<Scalars['Float']>;
  /** Client threshold. */
  client_threshold?: Maybe<Scalars['Int']>;
  /** Threshold level. */
  threshold_level?: Maybe<Scalars['String']>;
};

/** Input data for vacancy rate trigger info record. */
export type OccupancyEfficiencyTemplateDataVacancyRateTriggerInfoRecord = {
  /** Vacancy rate percentage. */
  vacancy_rate_percentage?: Maybe<Scalars['Float']>;
  /** Client threshold. */
  client_threshold?: Maybe<Scalars['Int']>;
  /** Threshold level. */
  threshold_level?: Maybe<Scalars['String']>;
};

/** Input for data standard post mapping */
export type PostMappingDataStandardInput = {
  /** Cache id - needed for proper cache update */
  id: Scalars['ID'];
  /** data standard category */
  category: Scalars['String'];
  /** source value */
  source_value: Scalars['String'];
  /** target value */
  target_value: Scalars['String'];
  /** mapping operation - undefined in case of mapping error, set in case of add/edit */
  standard_data_remapping?: Maybe<PostMappingDataStandardMappingOperation>;
};

/** Possible values of data standards mapping operation */
export enum PostMappingDataStandardMappingOperation {
  StandardDataRemapping = 'standard_data_remapping',
}

/** Response of postMappingDataStandard which allows proper caching */
export type PostMappingDataStandardResponse = {
  __typename?: 'PostMappingDataStandardResponse';
  /** Cache id - needed for proper cache update */
  id: Scalars['ID'];
  /** data standard category */
  category: Scalars['String'];
  /** source value */
  source_value: Scalars['String'];
  /** target value */
  target_value: Scalars['String'];
};

export type ProductSettingsReportInput = {
  disabled?: Maybe<Scalars['Boolean']>;
};

export type PromoteReleaseInput = {
  action: PromotionAction;
};

export enum PromotionAction {
  Promote = 'promote',
  Discard = 'discard',
}

export type PropertyData = {
  __typename?: 'PropertyData';
  next_page?: Maybe<Scalars['Int']>;
  tenant_id: Scalars['String'];
  records?: Maybe<Array<PropertyDataRecord>>;
};

export enum PropertyDataFilter {
  PropertyId = 'property_id',
  PropertyType = 'property_type',
  Country = 'country',
  City = 'city',
  PropertyName = 'property_name',
}

export type PropertyDataInput = {
  page_number?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Array<Scalars['String']>>;
  property_type?: Maybe<Array<Scalars['String']>>;
  country?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  property_name?: Maybe<Array<Scalars['String']>>;
};

export type PropertyDataRecord = {
  __typename?: 'PropertyDataRecord';
  document_key: Scalars['String'];
  property_id: Scalars['Int'];
  property_name?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_line_4?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_iso_code_2?: Maybe<Scalars['String']>;
  country_iso_code_3?: Maybe<Scalars['String']>;
  country_iso_numeric?: Maybe<Scalars['String']>;
  property_type?: Maybe<Scalars['String']>;
  property_sub_type?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  lease_owned?: Maybe<Scalars['String']>;
  property_area?: Maybe<Scalars['Float']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  service_end_date?: Maybe<Scalars['String']>;
  service_start_date?: Maybe<Scalars['String']>;
  site_group?: Maybe<Scalars['String']>;
  industry_sector?: Maybe<Scalars['String']>;
  business_unit?: Maybe<Scalars['String']>;
  region_hierarchy_1?: Maybe<Scalars['String']>;
  region_hierarchy_2?: Maybe<Scalars['String']>;
  region_hierarchy_3?: Maybe<Scalars['String']>;
  region_hierarchy_4?: Maybe<Scalars['String']>;
  region_hierarchy_5?: Maybe<Scalars['String']>;
  region_hierarchy_6?: Maybe<Scalars['String']>;
  region_hierarchy_7?: Maybe<Scalars['String']>;
  region_hierarchy_8?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Gets values related to API version. Exact version is available in prod and pre-prod as it corresponds to git tag. Build time is available otherwise.
   * Allowed roles: anonymous access.
   */
  getApiVersion: ApiVersion;
  /**
   * Gets list of applications for specified application ids. All applications are returned if no argument is provided.
   * Allowed roles: product support, system admin.
   */
  getApplications: Array<Application>;
  /**
   * Gets list of categories for specified category ids. All categories are returned if no argument is provided.
   * Allowed roles: system admin.
   */
  getCategories: Array<Maybe<Category>>;
  /**
   * Gets list of countries for specified country ids. All countries are returned if no argument is provided.
   * Allowed roles: all authenticated users.
   */
  getCountries: Array<Maybe<Country>>;
  /**
   * Gets list of keywords for specified keyword ids. All keywords are returned if no argument is provided.
   * Allowed roles: all authenticated users.
   */
  getKeywords: Array<Maybe<Keyword>>;
  /**
   * Gets list of work areas for specified work area ids. All work areas are returned if no argument is provided.
   * Allowed roles: all authenticated users.
   */
  getWorkAreas: Array<Maybe<WorkArea>>;
  /**
   * Gets list of regions for specified region ids. All regions are returned if no argument is provided.
   * Allowed roles: product support, system admin.
   */
  getRegions: Array<Maybe<Region>>;
  /**
   * Gets list of reports for specified report ids. All reports are returned if no argument is provided.
   * Allowed roles: system admin.
   */
  getReports: Array<Maybe<Report>>;
  /**
   * Gets list of roles for specified role ids. All roles are returned if no argument is provided.
   * Allowed roles: product support, system admin.
   */
  getRoles: Array<Maybe<Role>>;
  /**
   * Gets list of tenants for specified tenant ids. All tenants are returned if no argument is provided.
   * Allowed roles: product support, system admin, tenant users.
   */
  getTenants: Array<Maybe<Tenant>>;
  /**
   * Gets list of users for specified user ids. All users are returned if no argument is provided.
   * Allowed roles: product support, system admin.
   */
  getUsers: Array<Maybe<User>>;
  /**
   * Searches users by email.
   * Allowed roles: authenticated users.
   */
  searchUsers: Array<SearchedUserResult>;
  /**
   * Gets list of users who have a system role assigned to them.
   * Allowed roles: product support, system admin.
   */
  getSystemRoleUsers: Array<User>;
  /**
   * Gets user by id or email.
   * Allowed roles: user (own resources), system admin.
   */
  getUser?: Maybe<User>;
  /**
   * Gets list of report mappings for specified ids. All mappings are returned if no argument is provided.
   * Allowed roles: product support, system admin.
   */
  getReportMappings: Array<ReportMapping>;
  /**
   * Gets azure storage upload sas url for specified container.
   * Allowed roles: user (own resources), system admin.
   */
  getBlobStorageUploadSasUrl: Scalars['String'];
  /**
   * Gets list of keywords associated with specific work area.
   * Allowed roles: all authenticated users.
   */
  getWorkAreaKeywords: Array<Keyword>;
  /**
   * Gets user classification.
   * Allowed roles: product support, system admin.
   */
  getUserClassification: UserClassification;
  /**
   * Gets tenant reports configuration.
   * Allowed roles: user (own resources), system admin.
   */
  getTenantReportsConfiguration: TenantReportsConfiguration;
  /**
   * Gets qa tenants. Optionally from specific environment.
   * Allowed roles: product support, system admin.
   */
  getQaTenants: Array<Tenant>;
  /**
   * Gets tenant report configuration.
   * Allowed roles: product support, system admin.
   */
  getTenantReportConfiguration: ReportConfig;
  /**
   * Gets releases.
   * Allowed roles: product support, system admin.
   */
  getReleases: Array<ReleasesRecord>;
  /** Gets trusted ticket for an external ThoughtSpot report. */
  getThoughtSpotTrustedTicket: Scalars['String'];
};

export type QueryGetApplicationsArgs = {
  application_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetCategoriesArgs = {
  category_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetCountriesArgs = {
  country_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetKeywordsArgs = {
  keyword_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetWorkAreasArgs = {
  work_area_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetRegionsArgs = {
  region_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetReportsArgs = {
  report_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetRolesArgs = {
  role_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetTenantsArgs = {
  tenant_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetUsersArgs = {
  user_ids?: Maybe<Array<Scalars['ID']>>;
};

export type QuerySearchUsersArgs = {
  phrase?: Maybe<Scalars['String']>;
};

export type QueryGetUserArgs = {
  where: WhereGetUserInput;
};

export type QueryGetReportMappingsArgs = {
  report_mappings?: Maybe<Array<Scalars['ID']>>;
};

export type QueryGetBlobStorageUploadSasUrlArgs = {
  where: WhereUpdateUserInput;
  data: BlobStorageUploadSasUrlInput;
};

export type QueryGetWorkAreaKeywordsArgs = {
  tenant_id: Scalars['ID'];
};

export type QueryGetUserClassificationArgs = {
  where: UserClassificationInput;
};

export type QueryGetTenantReportsConfigurationArgs = {
  where: TenantReportsConfigurationInput;
};

export type QueryGetQaTenantsArgs = {
  tenant_qa_type: TenantQaType;
};

export type QueryGetTenantReportConfigurationArgs = {
  where: TenantReportConfigurationInput;
};

export type QueryGetThoughtSpotTrustedTicketArgs = {
  where: WhereThoughtSpotTrustedTicketInput;
};

/** Represents region. */
export type Region = {
  __typename?: 'Region';
  /** Unique id. */
  id: Scalars['ID'];
  /** Region name. */
  region_name: Scalars['String'];
};

export enum ReleaseStatus {
  Live = 'live',
  Promoted = 'promoted',
  Discarded = 'discarded',
  Upgraded = 'upgraded',
  InProgress = 'in_progress',
  Failed = 'failed',
}

export type ReleasesRecord = {
  __typename?: 'ReleasesRecord';
  id: Scalars['ID'];
  client_type: TenantQaType;
  release_id: Scalars['String'];
  status: ReleaseStatus;
  comments?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['String']>;
  last_update_by?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['String']>;
  test_status?: Maybe<TestStatus>;
};

/** Represents report. This type is bound to Tableau reports: either custom reports or baseline reports. */
export type Report = {
  __typename?: 'Report';
  /** Report id. */
  id: Scalars['ID'];
  /** Report name. Returns the tenant custom report name if overridden by the tenant admin. */
  report_name: Scalars['String'];
  /** Report original name. */
  report_original_name?: Maybe<Scalars['String']>;
  /** Report categories. */
  report_cat: Array<Category>;
  /** Url to workbook. */
  report_tableau_url: Scalars['String'];
  /** Embeddable url to full report. */
  report_tableau_full_report_url: Scalars['String'];
  /** Embeddable url to tableau report thumbnail. This field might not be present for all reports. */
  report_tableau_thumbnail_url?: Maybe<Scalars['String']>;
  /** External report url, if present changes handling of said report by ui */
  report_external_url?: Maybe<Scalars['String']>;
  /** Anchor target for external report. */
  report_external_anchor_target?: Maybe<ReportAnchorTarget>;
  /** Embeddable url to external report thumbnail. This field might not be present for all reports. */
  report_external_thumbnail_url?: Maybe<Scalars['String']>;
  /** List of keywords. */
  report_full_keywords: Array<Keyword>;
  /** Indicates if report is active. */
  report_active: Scalars['Boolean'];
  /** Report description. */
  report_description?: Maybe<Scalars['String']>;
  /** Report mapping. */
  report_mapping?: Maybe<ReportMapping>;
  /** Workbook name. */
  report_wb?: Maybe<Scalars['String']>;
  /** Report parent. Used for user saved views. */
  report_parent?: Maybe<Scalars['String']>;
  /** Tenant to which report belongs. */
  report_tenant?: Maybe<Scalars['String']>;
  /** Person which added report */
  report_added_by?: Maybe<Scalars['String']>;
  /** Person which updated report */
  report_updated_by?: Maybe<Scalars['String']>;
  /** Status of custom report. */
  custom_report_status?: Maybe<CustomReportStatus>;
  /** Creation date as Unix timestamp. */
  custom_report_created_at?: Maybe<Scalars['String']>;
  /** Preview date as Unix timestamp. */
  custom_report_previewed_at?: Maybe<Scalars['String']>;
  /** By whom report was previewed. */
  custom_report_previewed_by?: Maybe<Scalars['String']>;
  /** Publishing date as Unix timestamp. */
  custom_report_published_at?: Maybe<Scalars['String']>;
  /** By whom report was published. */
  custom_report_published_by?: Maybe<Scalars['String']>;
  /** Deactivation date as Unix timestamp. */
  custom_report_deactivated_at?: Maybe<Scalars['String']>;
  /** By whom report was deactivated. */
  custom_report_deactivated_by?: Maybe<Scalars['String']>;
  /** Current version of custom report. */
  custom_report_version?: Maybe<Scalars['String']>;
  /** Tells if report can be published. */
  custom_report_can_be_published?: Maybe<Scalars['Boolean']>;
  /** Tells if report can be previewed. */
  custom_report_can_be_previewed?: Maybe<Scalars['Boolean']>;
  /** Tells if report can be deactivated. */
  custom_report_can_be_deactivated?: Maybe<Scalars['Boolean']>;
  /** Currently published version of this report. */
  custom_report_published?: Maybe<Report>;
  /** Metadata about the current report. */
  report_metadata?: Maybe<ReportMetadata>;
  /** Configured information about a report. */
  report_config?: Maybe<ReportConfig>;
  /** Type of external report */
  report_external_type?: Maybe<ExternalReportType>;
  /** ThoughtSpot host */
  report_external_thoughtspot_host?: Maybe<Scalars['String']>;
  /** ThoughtSpot pinboard id */
  report_external_thoughtspot_pinboard_id?: Maybe<Scalars['String']>;
  /** ThoughtSpot visualization id */
  report_external_thoughtspot_viz_id?: Maybe<Scalars['String']>;
  /** Last data update date (e.g., last extract refresh date) */
  report_data_update_date?: Maybe<Scalars['String']>;
  report_hidden?: Maybe<Scalars['Boolean']>;
  report_custom_name?: Maybe<Scalars['String']>;
  report_custom_categories?: Maybe<Array<Category>>;
  report_disabled?: Maybe<Scalars['Boolean']>;
};

/** Possible values of external report anchor targets. */
export enum ReportAnchorTarget {
  Self = 'self',
  NewTab = 'new_tab',
}

/** Represents configured information about a report. */
export type ReportConfig = {
  __typename?: 'ReportConfig';
  /** Id. */
  id: Scalars['ID'];
  /** Report Id. */
  report_id: Scalars['ID'];
  /** Indicates if the report is enabled. */
  enabled: Scalars['Boolean'];
  /** The default report name for the client. */
  report_name: Scalars['String'];
  /** Report type. */
  report_type: ReportType;
  /** The description of the report. */
  description?: Maybe<Scalars['String']>;
  /** Additional configuration related to the report filters panel settings. */
  interaction_config: BaselineReportInteractionConfiguration;
};

/** Represents an interactive report control (e.g., filter dropdown). */
export type ReportControl = {
  __typename?: 'ReportControl';
  /** Id. */
  id: Scalars['ID'];
  /** Indicates if the report control is enabled. */
  enabled: Scalars['Boolean'];
  /** The control label displayed to the user. */
  label: Scalars['String'];
  /** The name of the Parameter or Filter as defined by the report author. */
  name: Scalars['String'];
  /** The type of section (i.e., Parameters, Filters), if overriding the parent section's type for this control. */
  section_type?: Maybe<ReportSectionType>;
  /** The type of report control (e.g., dropdown list). */
  control_type: ReportControlType;
  /** Static list of name-value pairs for controls of type single_select_list_def. */
  static_data: Array<ReportControlStaticListItem>;
  /** Indicates if the value should cascaded to other controls. */
  cascade_update: Scalars['Boolean'];
  /** The relative sort order for the control. */
  sort_index: Scalars['Int'];
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report control was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report control was last updated. */
  updated_by?: Maybe<Scalars['String']>;
};

/** Represents a list item for report controls with static data. */
export type ReportControlStaticListItem = {
  __typename?: 'ReportControlStaticListItem';
  /** The list item label. */
  label: Scalars['String'];
  /** The list item value. */
  value: Scalars['String'];
  /** Indicates if the item should be selected by default. */
  selected?: Maybe<Scalars['Boolean']>;
};

/** Possible values of report control. */
export enum ReportControlType {
  Other = 'Other',
  Boolean = 'Boolean',
  ContainsSearch = 'ContainsSearch',
  DateRange = 'DateRange',
  DateRangeMonth = 'DateRangeMonth',
  MultiCheckbox = 'MultiCheckbox',
  PercentGreaterThan = 'PercentGreaterThan',
  PercentLessThan = 'PercentLessThan',
  PercentRange = 'PercentRange',
  PercentStep = 'PercentStep',
  Radio = 'Radio',
  SelectList = 'SelectList',
  SingleDate = 'SingleDate',
  SingleSelectList = 'SingleSelectList',
  SingleSelectListDef = 'SingleSelectListDef',
  SliderDateRange = 'SliderDateRange',
  SliderLessThan = 'SliderLessThan',
  SliderRange = 'SliderRange',
}

/** Represents settings related to report's user interactions. */
export type ReportInteractionConfiguration = {
  __typename?: 'ReportInteractionConfiguration';
  /** Id. */
  id: Scalars['ID'];
  /** Indicates if the report filters panel is enabled. */
  enabled: Scalars['Boolean'];
  /** A list of report control sections for grouping purposes. */
  sections: Array<ReportInteractionSection>;
};

/** Represents a section of interactive controls (e.g., Parameters, Filters). */
export type ReportInteractionSection = {
  __typename?: 'ReportInteractionSection';
  /** Id. */
  id: Scalars['ID'];
  /** Indicates if the report section is enabled. */
  enabled: Scalars['Boolean'];
  /** The section label displayed to the user in the report filters panel. */
  label: Scalars['String'];
  /** The relative sort order for the section. */
  sort_index: Scalars['Int'];
  /** Indicates if the section should default to expanded or collapsed. */
  expanded: Scalars['Boolean'];
  /** The type of section (i.e., Parameters, Filters). */
  section_type: ReportSectionType;
  /** A list of report controls contained within the section. */
  controls: Array<ReportControl>;
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report section was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report section was last updated. */
  updated_by?: Maybe<Scalars['String']>;
};

/** Report types for logging purposes. */
export enum ReportLogType {
  Login = 'login',
  FullReport = 'full_report',
  Thumbnail = 'thumbnail',
  KpiDetailedReport = 'kpi_detailed_report',
  AlertReport = 'alert_report',
}

/** Represents report mapping, i.e. blueprint for report keywords, description, etc. */
export type ReportMapping = {
  __typename?: 'ReportMapping';
  /** Report mapping id. */
  id: Scalars['ID'];
  /** Name of the report this mapping refers to. */
  report_name?: Maybe<Scalars['String']>;
  /** Mapping for keywords. */
  report_keywords?: Maybe<Array<Keyword>>;
  /** Mapping for description. */
  report_description?: Maybe<Scalars['String']>;
  /** Type of report. */
  report_type?: Maybe<ReportType>;
  /** Indicates if mapping already exists. Do not attempt to delete mappings which do not exist. */
  report_mapping_exists?: Maybe<Scalars['Boolean']>;
};

/** Represents configured information about a report. */
export type ReportMetadata = {
  __typename?: 'ReportMetadata';
  /** Id. */
  id: Scalars['ID'];
  /** Indicates if the report is enabled. */
  enabled: Scalars['Boolean'];
  /** The report's parent module. */
  module: ReportModule;
  /** The report's category. */
  module_category: ReportModuleCategory;
  /** The reporting platform (e.g., Tableau). */
  platform: ReportPlatform;
  /** The default report name for the client, which might override the base report name. */
  report_name: Scalars['String'];
  /** The base report name. */
  base_report_name?: Maybe<Scalars['String']>;
  /** The description of the report. */
  description?: Maybe<Scalars['String']>;
  /** The default report URL. */
  url: Scalars['String'];
  /** The default help documentation URL. */
  reference_url?: Maybe<Scalars['String']>;
  /** The default report image (e.g., thumbnail) URL. */
  image_url?: Maybe<Scalars['String']>;
  /** The default report image as data. */
  image_blob?: Maybe<Scalars['String']>;
  /** The relative sort order of the report. */
  sort_index: Scalars['Int'];
  /** Additional configuration related to the report filters panel settings. */
  interaction_config: ReportInteractionConfiguration;
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report metadata was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report metadata was last updated. */
  updated_by?: Maybe<Scalars['String']>;
  /** Legacy source system data for linking between reporting systems. */
  source_data: ReportMetadataSourceData;
  /** Report mapping information for linking between reporting systems. */
  report_metadata_mapping: ReportMetadataMapping;
};

/** Represents data used to assist with mapping to the report metadata's content. */
export type ReportMetadataMapping = {
  __typename?: 'ReportMetadataMapping';
  /** The name of the report worksheet (e.g., a Tableau Sheet name). */
  worksheet?: Maybe<Scalars['String']>;
  /** The name of the report. */
  dashboard?: Maybe<Scalars['String']>;
  /** The category of the report. */
  category?: Maybe<Scalars['String']>;
  /** The thumbnail image mapping for the report. */
  thumbnail?: Maybe<Scalars['String']>;
  /** The subscription level mapping for the report. */
  subscription?: Maybe<Scalars['String']>;
};

/** Represents data about the report metadata's source system (e.g., for troubleshooting purposes). */
export type ReportMetadataSourceData = {
  __typename?: 'ReportMetadataSourceData';
  /** The source system name (e.g., PAT). */
  source_name?: Maybe<Scalars['String']>;
  /** The client-specific report identifier in the source system. */
  client_report_id?: Maybe<Scalars['String']>;
  /** The client identifier in the source system. */
  client_id?: Maybe<Scalars['String']>;
  /** The client code in the source system. */
  client_code?: Maybe<Scalars['String']>;
  /** The client name in the source system. */
  client_name?: Maybe<Scalars['String']>;
  /** The date that the report's data was last refreshed in the source system. */
  last_data_refresh?: Maybe<Scalars['String']>;
};

/** Represents a related group of reports that might be enabled on a per client basis. */
export type ReportModule = {
  __typename?: 'ReportModule';
  /** Indicates if the report module is enabled. */
  enabled: Scalars['Boolean'];
  /** The type of report module. */
  module_type: ReportModuleType;
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report module was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report module was last updated. */
  updated_by?: Maybe<Scalars['String']>;
};

/** Represents a related sub-group of reports that might be enabled on a per client basis. */
export type ReportModuleCategory = {
  __typename?: 'ReportModuleCategory';
  /** Indicates if the report modue category is enabled. */
  enabled: Scalars['Boolean'];
  /** The type of report module category. */
  module_category_type: ReportModuleCategoryType;
  /** Creation date as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** By whom the report module was created. */
  created_by?: Maybe<Scalars['String']>;
  /** Last update date as Unix timestamp. */
  updated_at?: Maybe<Scalars['String']>;
  /** By whom the report module was last updated. */
  updated_by?: Maybe<Scalars['String']>;
};

/** Possible values of report module category. */
export enum ReportModuleCategoryType {
  Other = 'Other',
  Cost = 'Cost',
  Lease = 'Lease',
  Market = 'Market',
  Occupancy = 'Occupancy',
  Portfolio = 'Portfolio',
  SensorAnalysis = 'SensorAnalysis',
  Trending = 'Trending',
}

/** Possible values of report module. */
export enum ReportModuleType {
  Other = 'Other',
  PortfolioAnalyticsToolEssentials = 'PortfolioAnalyticsToolEssentials',
  PortfolioAnalyticsToolPremium = 'PortfolioAnalyticsToolPremium',
}

/** Input for report performance logging context. */
export type ReportPerformanceContextInput = {
  /** Report type */
  report_type: ReportLogType;
  /** Id of requested report. */
  report_id: Scalars['String'];
  /** Id of tenant for which the report was requested. */
  tenant_id: Scalars['String'];
  /** Original id of requested report, if report_id is a virtual id (e.g., alerts reports) */
  original_report_id?: Maybe<Scalars['String']>;
};

/** Input for logging report performance. */
export type ReportPerformanceLogInput = {
  /** Report loading time. */
  duration: Scalars['Int'];
  /** Report context. */
  context: ReportPerformanceContextInput;
};

/** Possible values of report platform. */
export enum ReportPlatform {
  Other = 'Other',
  Tableau = 'Tableau',
  Weblink = 'Weblink',
  Ssrs = 'SSRS',
  MapIt = 'MapIT',
}

/** Possible values of report section. */
export enum ReportSectionType {
  Other = 'Other',
  Mixed = 'Mixed',
  Parameter = 'Parameter',
  Filter = 'Filter',
  Mark = 'Mark',
}

/** Represents type of reports. Ultimately this should be shared by reports and report mappings. */
export enum ReportType {
  Baseline = 'baseline',
  Custom = 'custom',
  Legacy = 'legacy',
  Other = 'other',
}

export type ReportWhereInput = {
  report_id: Scalars['ID'];
};

/** Represents any role (system or tenant). */
export type Role = {
  __typename?: 'Role';
  /** Unique id. */
  id: Scalars['ID'];
  /** Role type. */
  role_type: RoleType;
  /** Role name. */
  role_name: Scalars['String'];
  /** Role description. */
  role_description?: Maybe<Scalars['String']>;
  /** Role features. */
  role_features?: Maybe<Array<Scalars['String']>>;
  /** Role insights alerts. */
  role_insights_alerts?: Maybe<RoleInsightsAlerts>;
  /** Role users list. */
  role_users_list?: Maybe<Array<Scalars['String']>>;
  /** Role universal filters. */
  role_universal_filters?: Maybe<RoleUniversalFilters>;
  /** Role dashboards. */
  role_dashboards?: Maybe<RoleDashboards>;
  /** Role other features all. */
  role_other_features_all?: Maybe<Scalars['Boolean']>;
  /** Role other features. */
  role_other_features?: Maybe<Array<RoleOtherFeature>>;
  /** Role added by. */
  role_added_by?: Maybe<Scalars['String']>;
  /** Role added at. */
  role_added_at?: Maybe<Scalars['String']>;
  /** Role updated by. */
  role_updated_by?: Maybe<Scalars['String']>;
  /** Role updated at. */
  role_updated_at?: Maybe<Scalars['String']>;
  /** Role status. */
  role_status?: Maybe<RoleStatus>;
};

export type RoleDashboard = {
  __typename?: 'RoleDashboard';
  enabled: Scalars['Boolean'];
  report_id: Scalars['ID'];
};

export type RoleDashboardInput = {
  enabled: Scalars['Boolean'];
  report_id: Scalars['ID'];
};

export type RoleDashboards = {
  __typename?: 'RoleDashboards';
  enabled: Scalars['Boolean'];
  all?: Maybe<Scalars['Boolean']>;
  all_future: Scalars['Boolean'];
  dashboards: Array<RoleDashboard>;
};

export type RoleDashboardsInput = {
  enabled: Scalars['Boolean'];
  all_future: Scalars['Boolean'];
  dashboards: Array<RoleDashboardInput>;
};

/** Represents any role (system or tenant) input. */
export type RoleInput = {
  /** Role name. */
  role_name?: Maybe<Scalars['String']>;
  /** Role description. */
  role_description?: Maybe<Scalars['String']>;
  /** Role universal filters. */
  role_universal_filters?: Maybe<RoleUniversalFiltersInput>;
  /** Role dashboards. */
  role_dashboards?: Maybe<RoleDashboardsInput>;
  /** Role insights alerts. */
  role_insights_alerts?: Maybe<RoleInsightsAlertsInput>;
  /** Role other features. */
  role_other_features?: Maybe<Array<RoleOtherFeatureInput>>;
};

export type RoleInsightsAlert = {
  __typename?: 'RoleInsightsAlert';
  enabled: Scalars['Boolean'];
  alert_id: Scalars['ID'];
};

export type RoleInsightsAlertInput = {
  enabled: Scalars['Boolean'];
  alert_id: Scalars['ID'];
};

export type RoleInsightsAlerts = {
  __typename?: 'RoleInsightsAlerts';
  enabled: Scalars['Boolean'];
  all?: Maybe<Scalars['Boolean']>;
  all_future: Scalars['Boolean'];
  alerts: Array<RoleInsightsAlert>;
};

export type RoleInsightsAlertsInput = {
  enabled: Scalars['Boolean'];
  all_future: Scalars['Boolean'];
  alerts: Array<RoleInsightsAlertInput>;
};

export type RoleOtherFeature = {
  __typename?: 'RoleOtherFeature';
  feature_id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  sub_features: Array<RoleOtherSubFeature>;
};

export type RoleOtherFeatureInput = {
  feature_id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  sub_features: Array<RoleOtherSubFeatureInput>;
};

export type RoleOtherSubFeature = {
  __typename?: 'RoleOtherSubFeature';
  sub_feature_id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  full_access: Scalars['Boolean'];
};

export type RoleOtherSubFeatureInput = {
  sub_feature_id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  full_access: Scalars['Boolean'];
};

export enum RoleStatus {
  Active = 'active',
  Error = 'error',
  Queued = 'queued',
}

/** List of all roles. */
export enum RoleType {
  SystemAdmin = 'system_admin',
  ProductSupport = 'product_support',
  TenantAdmin = 'tenant_admin',
  TenantReportDeveloper = 'tenant_report_developer',
  TenantBusinessUser = 'tenant_business_user',
  TenantDataGovernance = 'tenant_data_governance',
  ClientDefinedRole = 'client_defined_role',
}

export type RoleUniversalFilters = {
  __typename?: 'RoleUniversalFilters';
  enabled: Scalars['Boolean'];
  filters: UserUniversalFilters;
};

export type RoleUniversalFiltersInput = {
  enabled: Scalars['Boolean'];
  filters?: Maybe<UserUniversalFiltersInput>;
};

/** Represents settings for Same Day Work Order Completion alerts categories. */
export type SameDayWorkOrderCompletionAlertCategory = {
  __typename?: 'SameDayWorkOrderCompletionAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Same Day Work Order Completion alert. */
export type SameDayWorkOrderCompletionAlertSettingData = {
  __typename?: 'SameDayWorkOrderCompletionAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: SameDayWorkOrderCompletionAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Same Day Work Order Completion alerts. */
export type SameDayWorkOrderCompletionAlertSettings = {
  __typename?: 'SameDayWorkOrderCompletionAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Threshold for alert generation. */
  threshold_for_alert_generation: Scalars['Int'];
};

/**
 * Input for updating / creating settings for Same Day Work Order Completion alerts.
 * See SameDayWorkOrderCompletionSettings type for details.
 */
export type SameDayWorkOrderCompletionAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  threshold_for_alert_generation: Scalars['Int'];
};

/** Data for same day work oder completion alerts. */
export type SameDayWorkOrderCompletionInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Completed work orders count. */
  completed_work_orders?: Maybe<Scalars['String']>;
  /** Completed work orders not on time count. */
  completed_work_orders_not_on_time?: Maybe<Scalars['String']>;
  /** Completed work orders percentage. */
  completed_work_orders_percentage?: Maybe<Scalars['String']>;
  /** Completed work orders not on time percentage. */
  completed_work_orders_not_on_time_percentage?: Maybe<Scalars['String']>;
  /** Not completed work orders percentage. */
  not_completed_work_orders_percentage?: Maybe<Scalars['String']>;
  /** Total work orders. */
  total_work_orders?: Maybe<Scalars['String']>;
  /** Threshold for alert generation. */
  threshold_for_alert_generation?: Maybe<Scalars['String']>;
};

/** Represents searched user. */
export type SearchedUserResult = {
  __typename?: 'SearchedUserResult';
  user_email?: Maybe<Scalars['String']>;
  user_first_name?: Maybe<Scalars['String']>;
  user_last_name?: Maybe<Scalars['String']>;
  user_management_type?: Maybe<UserManagementType>;
  user_tenants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for Segment data */
export type SegmentDataInput = {
  /** Configuration data option */
  mapping_id: Scalars['String'];
  /** Configuration data option */
  setting_id: Scalars['Int'];
  /** Configuration data option */
  setting_segment_id: Scalars['Int'];
  /** Configuration data option */
  order_index: Scalars['Int'];
  /** Configuration data option */
  setting_segment_name: Scalars['String'];
};

/** Segment options */
export type SegmentOptions = {
  __typename?: 'SegmentOptions';
  /** Configuration data option */
  mapping_id: Scalars['String'];
  /** Configuration data option */
  setting_id: Scalars['Int'];
  /** Configuration data option */
  setting_segment_id: Scalars['Int'];
  /** Configuration data option */
  order_index: Scalars['Int'];
  /** Configuration data option */
  setting_segment_name: Scalars['String'];
};

/** Represents settings for Sublease Risk alerts categories. */
export type SubleaseRiskAlertCategory = {
  __typename?: 'SubleaseRiskAlertCategory';
  internal: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Sublease Risk alert. */
export type SubleaseRiskAlertSettingData = {
  __typename?: 'SubleaseRiskAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: SubleaseRiskAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Sublease risk alerts. */
export type SubleaseRiskAlertSettings = {
  __typename?: 'SubleaseRiskAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Lease liquidity options. */
  lease_liquidity_options: Array<Scalars['String']>;
  /** Notice period. */
  notice_period: Scalars['Int'];
};

/**
 * Input for updating / creating settings for Sublease Risk alerts.
 * See SubleaseRiskAlertSettings type for details.
 */
export type SubleaseRiskAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  lease_liquidity_options: Array<Scalars['String']>;
  notice_period: Scalars['Int'];
};

/** Data for sublease risk alerts. */
export type SubleaseRiskInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** Total liquidity options. */
  total_liquidity_options?: Maybe<Scalars['String']>;
  /** Total cost obligations. */
  total_cost_obligations?: Maybe<Scalars['String']>;
  /** Subleases. */
  subleases?: Maybe<Scalars['String']>;
  /** Income at risk. */
  income_at_risk?: Maybe<Scalars['String']>;
  /** Notice period. */
  notice_period?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Lease Liquidity Options. */
  lease_liquidity_options?: Maybe<Array<Scalars['String']>>;
  /** City Name. */
  city_name?: Maybe<Scalars['String']>;
  /** State Name. */
  state_name?: Maybe<Scalars['String']>;
  /** Country Name. */
  country_name?: Maybe<Scalars['String']>;
  /** Natural expiry. */
  natural_expiry?: Maybe<Array<SubleaseRiskTemplateDataNaturalExpiryRecord>>;
  /** Mainly parking leases. */
  mainly_parking_leases?: Maybe<
    Array<SubleaseRiskTemplateDataMainlyParkingLeasesRecord>
  >;
  /** Contraction Options. */
  contraction_options?: Maybe<
    Array<SubleaseRiskTemplateDataContractionOptionsRecord>
  >;
  /** Sublease with expiry. */
  sublease_with_expiry?: Maybe<
    Array<SubleaseRiskTemplateDataSubleaseWithExpiryRecord>
  >;
  /** Month to month tenures. */
  month_to_month_tenures?: Maybe<
    Array<SubleaseRiskTemplateDataMonthToMonthTenuresRecord>
  >;
  /** Termination options. */
  termination_options?: Maybe<
    Array<SubleaseRiskTemplateDataTerminationOptionsRecord>
  >;
};

/** Input data for contraction options record. */
export type SubleaseRiskTemplateDataContractionOptionsRecord = {
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Event Id. */
  event_id?: Maybe<Scalars['String']>;
  /** Event notice on. */
  event_notice_on?: Maybe<Scalars['Float']>;
  /** Event notes. */
  event_notes?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Input data for Mainly parking leases record. */
export type SubleaseRiskTemplateDataMainlyParkingLeasesRecord = {
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Input data for month to month tenures record. */
export type SubleaseRiskTemplateDataMonthToMonthTenuresRecord = {
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Input data for natural expiry record. */
export type SubleaseRiskTemplateDataNaturalExpiryRecord = {
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenant Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Tenure ended on. */
  tenure_ended_on?: Maybe<Scalars['Float']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Input data for sublease with expiry record. */
export type SubleaseRiskTemplateDataSubleaseWithExpiryRecord = {
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Tenure ended on. */
  tenure_ended_on?: Maybe<Scalars['Float']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Input data for termination options record. */
export type SubleaseRiskTemplateDataTerminationOptionsRecord = {
  /** Tenure Id. */
  tenure_id?: Maybe<Scalars['String']>;
  /** Tenure Key. */
  tenure_key?: Maybe<Scalars['String']>;
  /** Event Id. */
  event_id?: Maybe<Scalars['String']>;
  /** Event notice on. */
  event_notice_on?: Maybe<Scalars['Float']>;
  /** Event notes. */
  event_notes?: Maybe<Scalars['String']>;
  /** Annualized cost USD. */
  annualized_cost_usd?: Maybe<Scalars['Float']>;
};

/** Represents tenant (client). */
export type Tenant = {
  __typename?: 'Tenant';
  /** Tenant id. */
  id: Scalars['ID'];
  /** Tenant name. */
  tenant_name: Scalars['String'];
  /** Tenant description. */
  tenant_description: Scalars['String'];
  /** Tenant ovc id. Unique for every tenant. */
  tenant_ovc_id: Scalars['String'];
  /** Tenant code. Unique for every tenant. */
  tenant_code: Scalars['String'];
  /** Tenant creation date as Unix timestamp. */
  tenant_created_at?: Maybe<Scalars['String']>;
  /** Tenant activation date as Unix timestamp. */
  tenant_activated_at?: Maybe<Scalars['String']>;
  /** Last date of synchronization as Unix timestamp. */
  tenant_synchronized_at?: Maybe<Scalars['String']>;
  /** List of tenant applications. */
  tenant_applications: Array<TenantApplication>;
  /** Tenant enabled features. */
  tenant_features: TenantFeatures;
  /** List of tenant baseline reports. */
  tenant_reports: Array<Report>;
  /** List of tenant users. */
  tenant_users: Array<User>;
  /** Tenant status. Check TenantStatus enum. */
  tenant_status: TenantStatus;
  /** List of settings for alerts. */
  tenant_alert_settings?: Maybe<Array<TenantAlertSettings>>;
  /** Tenant settings for custom reports. */
  tenant_custom_reports_settings?: Maybe<TenantCustomReportsSettings>;
  /** Tenant data for alert rule builder. */
  tenant_alert_rule_builder: TenantAlertSettingData;
  /** Tenant geographical universal filters. */
  tenant_geo_universal_filters: TenantGeoUniversalFilters;
  /** Tenant activity logs for given entity type / event type combination. Optionally filters results by entity id. */
  tenant_activity_logs: Array<Log>;
  /** Alert types assigned to tenant. */
  tenant_enabled_alert_types?: Maybe<Array<TenantAlertSettingsType>>;
  /** Tenant configuration data. */
  tenant_configuration_data?: Maybe<Array<TenantConfigurationData>>;
  /** Active Directory groups associated with tenant. */
  tenant_ad_groups: Array<AdGroup>;
  /** Tenant users belonging to tenant groups in Active Directory. */
  tenant_ad_users: Array<User>;
  /** Tenant data standards. */
  tenant_data_standards: TenantDataStandard;
  /** Tenant external reports. */
  tenant_external_reports: Array<Maybe<Report>>;
  /** Tenant data loads job list. */
  tenant_data_loads_jobs?: Maybe<DataLoadsJobs>;
  /** Tenant data loads download links for a job. */
  tenant_data_loads_job_links: DataLoadsJobLinks;
  /** Tenant data loads containers. */
  tenant_data_loads_containers: DataLoadsContainers;
  tenant_client_defined_roles?: Maybe<Array<Role>>;
  /** Tenant User Integration Type */
  tenant_user_integration: TenantUserIntegration;
  /** Tenant type */
  tenant_type: TenantType;
  /** Tenant property data */
  tenant_property_data?: Maybe<PropertyData>;
  /** Tenant property data filter values */
  tenant_property_data_filter_values: Array<Scalars['String']>;
  /** Tenant KPI settings. */
  tenant_kpi_settings?: Maybe<Array<TenantKpiSettings>>;
  /** Tenant QA type */
  tenant_qa_type: TenantQaType;
};

/** Represents tenant (client). */
export type TenantTenant_ReportsArgs = {
  filters?: Maybe<TenantReportsFiltersInput>;
};

/** Represents tenant (client). */
export type TenantTenant_Alert_Rule_BuilderArgs = {
  alert_type: TenantAlertSettingsType;
};

/** Represents tenant (client). */
export type TenantTenant_Geo_Universal_FiltersArgs = {
  nodes?: Maybe<TenantUniversalFilterNodeInput>;
};

/** Represents tenant (client). */
export type TenantTenant_Activity_LogsArgs = {
  entity_type: EntityType;
  event_type: EventType;
  entity_id?: Maybe<Scalars['String']>;
};

/** Represents tenant (client). */
export type TenantTenant_Configuration_DataArgs = {
  applications?: Maybe<Array<TenantConfigurationDataType>>;
};

/** Represents tenant (client). */
export type TenantTenant_External_ReportsArgs = {
  filters?: Maybe<TenantReportsFiltersInput>;
};

/** Represents tenant (client). */
export type TenantTenant_Data_Loads_JobsArgs = {
  filters: DataLoadsJobsFiltersInput;
  cursor?: Maybe<Scalars['ID']>;
};

/** Represents tenant (client). */
export type TenantTenant_Data_Loads_Job_LinksArgs = {
  job_id: Scalars['ID'];
};

/** Represents tenant (client). */
export type TenantTenant_Data_Loads_ContainersArgs = {
  data?: Maybe<DataLoadsContainersInput>;
};

/** Represents tenant (client). */
export type TenantTenant_Property_DataArgs = {
  filter?: Maybe<PropertyDataInput>;
};

/** Represents tenant (client). */
export type TenantTenant_Property_Data_Filter_ValuesArgs = {
  filter: PropertyDataFilter;
};

/** Input for updating / creating alert categories. */
export type TenantAlertCategoriesInput = {
  internal: Array<Scalars['String']>;
  external?: Maybe<Array<Scalars['String']>>;
};

/** Represents possible values for alert settings. */
export type TenantAlertSettingData =
  | TenantWorkOrderVolumeAlertSettingData
  | TenantFacilitiesExperienceAlertSettingData
  | TenantLeaseEventAlertSettingData
  | TenantCovidWorkOrderAlertSettingData
  | TenantMarketBenchmarkAlertSettingData
  | TenantOccupancyEfficiencyAlertSettingData
  | TenantSubleaseRiskAlertSettingData
  | TenantSameDayWorkOrderCompletionAlertSettingData;

/** Alert settings can be of one of the following types. */
export type TenantAlertSettings =
  | WorkOrderVolumeAlertSettings
  | FacilitiesExperienceAlertSettings
  | LeaseEventAlertSettings
  | CovidWorkOrderAlertSettings
  | MarketBenchmarkAlertSettings
  | OccupancyEfficiencyAlertSettings
  | SubleaseRiskAlertSettings
  | SameDayWorkOrderCompletionAlertSettings;

/** Input for updating / creating alert settings. */
export type TenantAlertSettingsInput = {
  workOrderVolumeData?: Maybe<WorkOrderVolumeAlertSettingsInput>;
  facilitiesExperienceData?: Maybe<FacilitiesExperienceAlertSettingsInput>;
  leaseEventData?: Maybe<LeaseEventAlertSettingsInput>;
  covidWorkOrderData?: Maybe<CovidWorkOrderAlertSettingsInput>;
  marketBenchmarkData?: Maybe<MarketBenchmarkAlertSettingsInput>;
  occupancyEfficiencyData?: Maybe<OccupancyEfficiencyAlertSettingsInput>;
  subleaseRiskData?: Maybe<SubleaseRiskAlertSettingsInput>;
  sameDayWorkOrderCompletionData?: Maybe<SameDayWorkOrderCompletionAlertSettingsInput>;
};

/** Types of alerts for which tenant can set settings. */
export enum TenantAlertSettingsType {
  WorkorderVolume = 'workorder_volume',
  FacilitiesExperience = 'facilities_experience',
  LeaseEvent = 'lease_event',
  Covid_19WorkOrder = 'covid_19_work_order',
  MarketBenchmark = 'market_benchmark',
  OccupancyEfficiency = 'occupancy_efficiency',
  SubleaseRisk = 'sublease_risk',
  SameDayWorkOrderCompletion = 'same_day_work_order_completion',
}

/** Represents tenant's application. Adds extra tenant-specific fields to application. */
export type TenantApplication = {
  __typename?: 'TenantApplication';
  /** Associated application. */
  app: Application;
  /** Indicates if application has been provisioned for tenant. */
  app_provisioned: Scalars['Boolean'];
  /** Application-specific tenant id. */
  app_tenant_id?: Maybe<Scalars['String']>;
};

/** Input for mapping an application-specific tenant id. */
export type TenantApplicationTenantIdInput = {
  application_id: Scalars['ID'];
  application_tenant_id?: Maybe<Scalars['String']>;
  is_required_mapping?: Maybe<Scalars['Boolean']>;
};

/** Base configuration data interface */
export type TenantConfigurationData = {
  __typename?: 'TenantConfigurationData';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data application code */
  app_code: Scalars['String'];
  /** Configuration data tenant id */
  tenant_id: Scalars['ID'];
  /** Configuration data values */
  values?: Maybe<TenantConfigurationDataValues>;
  /** Configuration data options */
  options?: Maybe<TenantConfigurationDataOptions>;
  /** Configuration data sample values */
  sample_values?: Maybe<TenantConfigurationDataSampleValues>;
};

/** Input for configuration data */
export type TenantConfigurationDataInput = {
  /** See TenantDefaultsDataInput for details */
  tenantDefaultsData?: Maybe<TenantDefaultsDataInput>;
  /** See TenantWorkOrdersDataInput for details */
  tenantWorkOrdersData?: Maybe<TenantWorkOrdersDataInput>;
  /** See TenantFinanceDataInput for details */
  tenantFinanceData?: Maybe<TenantFinanceDataInput>;
  /** See TenantTransactionDataInput for details */
  tenantTransactionData?: Maybe<TenantTransactionDataInput>;
  /** See TenantLeaseDataInput for details */
  tenantLeaseData?: Maybe<TenantLeaseDataInput>;
  /** See TenantSpaceDataInput for details */
  tenantSpaceData?: Maybe<TenantSpaceDataInput>;
};

export type TenantConfigurationDataOptions =
  | TenantFinanceDataOptions
  | TenantTransactionDataOptions
  | TenantLeaseDataOptions
  | TenantSpaceDataOptions;

export type TenantConfigurationDataSampleValues =
  | TenantSpaceDataSampleValues
  | TenantFinanceDataSampleValues
  | TenantLeaseDataSampleValues;

/** Types of possible configuration data */
export enum TenantConfigurationDataType {
  DefaultsData = 'defaults_data',
  WorkOrdersData = 'work_orders_data',
  FinanceData = 'finance_data',
  TransactionData = 'transaction_data',
  LeaseData = 'lease_data',
  SpaceData = 'space_data',
}

export type TenantConfigurationDataValues =
  | TenantDefaultsDataValues
  | TenantWorkOrdersDataValues
  | TenantFinanceDataValues
  | TenantTransactionDataValues
  | TenantLeaseDataValues
  | TenantSpaceDataValues;

export type TenantCovidWorkOrderAlertSettingData = {
  __typename?: 'TenantCovidWorkOrderAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: CovidWorkOrderAlertCategory;
};

/**
 * Input for creating tenant.
 * See Tenant type for details.
 */
export type TenantCreateInput = {
  tenant_name: Scalars['String'];
  tenant_ovc_id: Scalars['String'];
  tenant_code: Scalars['String'];
  tenant_applications: Array<Scalars['ID']>;
  tenant_applications_tenant_ids: Array<TenantApplicationTenantIdInput>;
  tenant_description?: Maybe<Scalars['String']>;
  tenant_features: TenantFeaturesInput;
  /** Names of Active Directory groups. */
  tenant_ad_groups?: Maybe<Array<Scalars['String']>>;
  /** Email of user who will be first tenant admin. */
  tenant_client_admin_email: Scalars['String'];
  /** First name of user who will be first tenant admin */
  tenant_client_admin_first_name: Scalars['String'];
  /** Last name of user who will be first tenant admin */
  tenant_client_admin_last_name: Scalars['String'];
  /** Last name of user who will be first tenant admin */
  tenant_user_integration: TenantUserIntegration;
  /** Tenant QA type */
  tenant_qa_type: TenantQaType;
};

/** Represents settings for custom reports. */
export type TenantCustomReportsSettings = {
  __typename?: 'TenantCustomReportsSettings';
  /** List of custom reports. */
  tenant_custom_reports?: Maybe<Array<Maybe<Report>>>;
  /** Setup settings. */
  tenant_custom_reports_setup?: Maybe<TenantCustomReportsSetup>;
  /** Self serve analytics settings. */
  tenant_self_serve_analytics_settings?: Maybe<TenantSelfServeAnalyticsSettings>;
};

/** Represents settings for custom reports. */
export type TenantCustomReportsSettingsTenant_Custom_ReportsArgs = {
  status?: Maybe<CustomReportStatus>;
  filters?: Maybe<TenantReportsFiltersInput>;
};

/** Represents database-related settings for custom reports. */
export type TenantCustomReportsSetup = {
  __typename?: 'TenantCustomReportsSetup';
  /** Snowflake url. */
  tenant_snowflake_url?: Maybe<Scalars['String']>;
  /** Tenant Snowflake URLs. */
  tenant_snowflake_urls: Array<TenantSnowflakeUrl>;
  /** Snowflake identifier. */
  tenant_snowflake_locator: Scalars['String'];
  /** Database name. */
  tenant_snowflake_database: Scalars['String'];
  /** Tableau Server url */
  tenant_tableau_project_custom_site_url: Scalars['String'];
  /** Share name. */
  tenant_snowflake_share: Scalars['String'];
  /** Status of setup. Either `not-started`, `done`, or `error`. */
  tenant_snowflake_setup_status: Scalars['String'];
  /** Error message from any errors arising during setup. */
  tenant_snowflake_setup_error?: Maybe<Scalars['String']>;
  /** Snowflake share consumers. */
  tenant_snowflake_share_consumers: Array<Scalars['String']>;
};

/** Represents data standards */
export type TenantDataStandard = {
  __typename?: 'TenantDataStandard';
  global_views: GlobalView;
  unmapped: Array<Unmapped>;
  /** Mapped values, check Mapped type */
  mapped: Array<Mapped>;
  /** Target possible values */
  target_possible_values: Array<Scalars['String']>;
};

/** Represents data standards */
export type TenantDataStandardGlobal_ViewsArgs = {
  tenant_id: Scalars['ID'];
  mapping_type: MappingType;
};

/** Represents data standards */
export type TenantDataStandardUnmappedArgs = {
  tenant_id: Scalars['ID'];
  category: Scalars['String'];
};

/** Represents data standards */
export type TenantDataStandardMappedArgs = {
  tenant_id: Scalars['ID'];
  category: Scalars['String'];
};

/** Represents data standards */
export type TenantDataStandardTarget_Possible_ValuesArgs = {
  category: Scalars['String'];
};

/**
 * Input for defaults configuration data
 * See TenantFinanceDataValues for details
 */
export type TenantDefaultsDataInput = {
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data type */
  app_code: Scalars['String'];
  default_unit_of_measurement: AreaUnit;
  default_unit_of_currency: CurrencyUnit;
};

/** Represents defaults configuration data values */
export type TenantDefaultsDataValues = {
  __typename?: 'TenantDefaultsDataValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Default measurment unit */
  default_unit_of_measurement: AreaUnit;
  /** Default currency unit */
  default_unit_of_currency: CurrencyUnit;
};

export type TenantFacilitiesExperienceAlertSettingData = {
  __typename?: 'TenantFacilitiesExperienceAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: FacilitiesExperienceAlertCategory;
};

/** Represents configurable features for tenant. */
export type TenantFeatures = {
  __typename?: 'TenantFeatures';
  /** Indicates if alerts are enabled. */
  alerts: Scalars['Boolean'];
  /** Indicates if demo features are enabled. */
  demo: Scalars['Boolean'];
  /** Indicates if portscape access is enabled. */
  portscape: Scalars['Boolean'];
};

/**
 * Input for creating / updating tenant features.
 * See TenantFeatures type for details.
 */
export type TenantFeaturesInput = {
  alerts: Scalars['Boolean'];
  demo: Scalars['Boolean'];
  portscape: Scalars['Boolean'];
};

/**
 * Input for finance configuration data
 * See TenantFinanceDataValues for details
 */
export type TenantFinanceDataInput = {
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data type */
  app_code: Scalars['String'];
  actuals_ledger_type_code: Scalars['String'];
  budget_forecast_ledger_type_code: Scalars['String'];
  gl_accounts_for_exclusion: Array<Scalars['String']>;
  budget_accuracy_variance_ratings: Array<DataThresholdInput>;
  chart_of_account: Array<Scalars['String']>;
  cost_category: SegmentDataInput;
};

/** Represents finance configuration data options */
export type TenantFinanceDataOptions = {
  __typename?: 'TenantFinanceDataOptions';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data options field */
  actuals_ledger_type_code: Array<Scalars['String']>;
  /** Configuration data options field */
  budget_forecast_ledger_type_code: Array<Scalars['String']>;
  /** Configuration data options field */
  gl_accounts_for_exclusion: Array<Scalars['String']>;
  /** Configuration data options field */
  chart_of_account: Array<Scalars['String']>;
  /** Configuration data options field */
  cost_category: Array<SegmentOptions>;
};

/** Represents sample values for finance configuration data */
export type TenantFinanceDataSampleValues = {
  __typename?: 'TenantFinanceDataSampleValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  cost_category?: Maybe<Array<FlexibleMappingOptionSampleValues>>;
};

/** Represents finance configuration data values */
export type TenantFinanceDataValues = {
  __typename?: 'TenantFinanceDataValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data values field */
  actuals_ledger_type_code: Scalars['String'];
  /** Configuration data values field */
  budget_forecast_ledger_type_code: Scalars['String'];
  /** Configuration data values field */
  gl_accounts_for_exclusion: Array<Scalars['String']>;
  /** Configuration data values field */
  budget_accuracy_variance_ratings: Array<DataThreshold>;
  /** Configuration data values field */
  chart_of_account: Array<Scalars['String']>;
  /** Configuration data values field */
  flexible_mappings: Array<SegmentOptions>;
};

/** Represents tenant's geographical filters. */
export type TenantGeoUniversalFilters = {
  __typename?: 'TenantGeoUniversalFilters';
  /** Values for countries. */
  countries: Array<TenantUniversalFilterGeoValue>;
  /** Values for states. */
  states: Array<TenantUniversalFilterGeoValue>;
  /** Values for cities. */
  cities: Array<TenantUniversalFilterGeoValue>;
  /** Values for properties. */
  properties: Array<TenantUniversalFilterGeoValue>;
};

/** Represents KPI meta for a given tenant. */
export type TenantKpiData = {
  __typename?: 'TenantKpiData';
  /** Tenant id. */
  tenant_id: Scalars['String'];
  /** Tenant ovc id. */
  tenant_ovc_id: Scalars['String'];
  /** When KPI was updated as Unix timestamp. */
  updated: Scalars['String'];
  /** Indicates if client should request synchronization. */
  request_sync?: Maybe<Scalars['Boolean']>;
  /** Indicates what has triggered synchronization. */
  triggered_by: Scalars['String'];
  /** KPI data. */
  kpi_data?: Maybe<KpiData>;
};

/** Tenant KPI settings. */
export type TenantKpiSettings = {
  __typename?: 'TenantKpiSettings';
  /** KPI type. */
  id: TenantKpiSettingsType;
  /** Indicates if this type of KPI is enabled. */
  kpi_enabled: Scalars['Boolean'];
  /** Application code. */
  app_code: Scalars['String'];
};

/** Input for tenant KPI settings. */
export type TenantKpiSettingsInput = {
  /** Input KPI type. */
  id: TenantKpiSettingsType;
  /** Input that indicates if this type of KPI is enabled. */
  kpi_enabled: Scalars['Boolean'];
  /** Input Application code. */
  app_code: Scalars['String'];
};

/** Types of KPI settings. */
export enum TenantKpiSettingsType {
  PropertyCount = 'property_count',
  Size = 'size',
  ActualCosts = 'actual_costs',
  HeadCount = 'head_count',
  SeatCount = 'seat_count',
}

/** Input for lease configuration data */
export type TenantLeaseDataInput = {
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data type */
  app_code: Scalars['String'];
  reporting_business_unit: SegmentDataInput;
};

/** Represents lease configuration data options */
export type TenantLeaseDataOptions = {
  __typename?: 'TenantLeaseDataOptions';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data options field */
  reporting_business_unit: Array<SegmentOptions>;
};

/** Represents sample values for lease configuration data */
export type TenantLeaseDataSampleValues = {
  __typename?: 'TenantLeaseDataSampleValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  reporting_business_unit?: Maybe<Array<FlexibleMappingOptionSampleValues>>;
};

/** Represents lease configuration data values */
export type TenantLeaseDataValues = {
  __typename?: 'TenantLeaseDataValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data values field */
  flexible_mappings: Array<SegmentOptions>;
};

export type TenantLeaseEventAlertSettingData = {
  __typename?: 'TenantLeaseEventAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: LeaseEventAlertCategory;
};

export type TenantMarketBenchmarkAlertSettingData = {
  __typename?: 'TenantMarketBenchmarkAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: MarketBenchmarkAlertCategory;
};

export type TenantOccupancyEfficiencyAlertSettingData = {
  __typename?: 'TenantOccupancyEfficiencyAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: OccupancyEfficiencyAlertCategory;
};

export enum TenantQaType {
  Demo = 'demo',
  Nonwd = 'nonwd',
  Wdlive = 'wdlive',
  Wdqa = 'wdqa',
}

/** Input for fetching tenant report configuration. */
export type TenantReportConfigurationInput = {
  /** Tenant ID. */
  tenant_id: Scalars['String'];
  /** Report ID. */
  report_id: Scalars['String'];
};

export type TenantReportInput = {
  report_id: Scalars['ID'];
  custom_name?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  custom_category?: Maybe<Scalars['String']>;
  report?: Maybe<UpdateReportInput>;
};

/** Represents reports configuration for the tenant. */
export type TenantReportsConfiguration = {
  __typename?: 'TenantReportsConfiguration';
  /** Configuration id. */
  id: Scalars['ID'];
  /** Tenant Id. */
  tenant_id: Scalars['String'];
  /** Tenant Name. */
  tenant_name: Scalars['String'];
  /** Tenant Ovc Id. */
  tenant_ovc_id: Scalars['String'];
  /** The description of the report. */
  tenant_reports_config: Array<Maybe<ReportConfig>>;
};

/** Input for fetching TenantReportsConfiguration. */
export type TenantReportsConfigurationInput = {
  tenant_id: Scalars['String'];
};

export type TenantReportsFiltersInput = {
  show_hidden?: Maybe<Scalars['Boolean']>;
  show_disabled?: Maybe<Scalars['Boolean']>;
};

/** Represents tenant role. */
export type TenantRole = {
  __typename?: 'TenantRole';
  /** Unique id. */
  id: Scalars['ID'];
  /** Tenant role type. */
  role_type: TenantRoleType;
  /** Role name. */
  role_name: Scalars['String'];
};

/** List of tenant roles. */
export enum TenantRoleType {
  TenantAdmin = 'tenant_admin',
  TenantReportDeveloper = 'tenant_report_developer',
  TenantBusinessUser = 'tenant_business_user',
  TenantDataGovernance = 'tenant_data_governance',
  ClientDefinedRole = 'client_defined_role',
}

export type TenantSameDayWorkOrderCompletionAlertSettingData = {
  __typename?: 'TenantSameDayWorkOrderCompletionAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: SameDayWorkOrderCompletionAlertCategory;
};

/** Represents Self Serve Analytics Settings */
export type TenantSelfServeAnalyticsSettings = {
  __typename?: 'TenantSelfServeAnalyticsSettings';
  /** Indicates if self serve analytics are enabled or disabled. */
  self_serve_analytics_enabled: Scalars['Boolean'];
  /** Self serve analytics url. */
  self_serve_analytics_url: Scalars['String'];
  /** Updated at. */
  self_serve_analytics_updated_at?: Maybe<Scalars['String']>;
};

/** Represents tenant snowflake url. */
export type TenantSnowflakeUrl = {
  __typename?: 'TenantSnowflakeUrl';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Tenant snowflake url. */
  tenant_snowflake_url: Scalars['String'];
};

/** Input for space configuration data */
export type TenantSpaceDataInput = {
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data type */
  app_code: Scalars['String'];
  business_unit: SegmentDataInput;
  space_type: SegmentDataInput;
  standard_occupancy_thresholds: Array<DataThresholdInput>;
  standard_vacancy_thresholds: Array<DataThresholdInput>;
};

/** Represents space configuration data options */
export type TenantSpaceDataOptions = {
  __typename?: 'TenantSpaceDataOptions';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data options field */
  business_unit: Array<SegmentOptions>;
  /** Configuration data options field */
  space_type: Array<SegmentOptions>;
};

/** Represents sample values for space configuration data */
export type TenantSpaceDataSampleValues = {
  __typename?: 'TenantSpaceDataSampleValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  business_unit?: Maybe<Array<FlexibleMappingOptionSampleValues>>;
  space_type?: Maybe<Array<FlexibleMappingOptionSampleValues>>;
};

/** Represents space configuration data values */
export type TenantSpaceDataValues = {
  __typename?: 'TenantSpaceDataValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Standard Occupancy Thresholds */
  standard_occupancy_thresholds: Array<DataThreshold>;
  /** Standard Vacancy Thresholds */
  standard_vacancy_thresholds: Array<DataThreshold>;
  /** Configuration data values field */
  flexible_mappings: Array<SegmentOptions>;
};

/** Possible values for tenant_status */
export enum TenantStatus {
  New = 'NEW',
  DataSetupRunning = 'DATA_SETUP_RUNNING',
  DataSetupError = 'DATA_SETUP_ERROR',
  AppSetupRunning = 'APP_SETUP_RUNNING',
  AppSetupError = 'APP_SETUP_ERROR',
  TenantRemovalRunning = 'TENANT_REMOVAL_RUNNING',
  TenantRemovalError = 'TENANT_REMOVAL_ERROR',
  Active = 'ACTIVE',
}

export type TenantSubleaseRiskAlertSettingData = {
  __typename?: 'TenantSubleaseRiskAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: SubleaseRiskAlertCategory;
};

/**
 * Input for transaction configuration data
 * See TenantTransactionDataValues for details
 */
export type TenantTransactionDataInput = {
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data type */
  app_code: Scalars['String'];
  milestone_identification: Scalars['String'];
};

export type TenantTransactionDataOptions = {
  __typename?: 'TenantTransactionDataOptions';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data options field */
  milestone_identification: Array<Scalars['String']>;
};

/** Represents transaction configuration data values */
export type TenantTransactionDataValues = {
  __typename?: 'TenantTransactionDataValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data values field */
  milestone_identification: Scalars['String'];
};

/** Possible values for tenant_type */
export enum TenantType {
  Cs = 'CS',
  NonCs = 'NON_CS',
  Demo = 'DEMO',
}

/** Represents tenant filter value. */
export type TenantUniversalFilterGeoValue = {
  __typename?: 'TenantUniversalFilterGeoValue';
  node: UniversalFilterGeoNode;
  ancestors?: Maybe<Array<Scalars['String']>>;
  values: Array<Scalars['String']>;
};

/** Represents node input for universal filters. */
export type TenantUniversalFilterNodeInput = {
  /** List of countries. */
  country?: Maybe<Array<Scalars['String']>>;
  /** List of states. */
  state?: Maybe<Array<Scalars['String']>>;
  /** List of cities. */
  city?: Maybe<Array<Scalars['String']>>;
};

/**
 * Input for updating tenant.
 * See Tenant type for details.
 */
export type TenantUpdateInput = {
  tenant_description?: Maybe<Scalars['String']>;
  tenant_applications?: Maybe<Array<Scalars['ID']>>;
  tenant_applications_tenant_ids?: Maybe<Array<TenantApplicationTenantIdInput>>;
  tenant_features?: Maybe<TenantFeaturesInput>;
  /** Names of Active Directory groups. */
  tenant_ad_groups?: Maybe<Array<Scalars['String']>>;
  tenant_user_integration: TenantUserIntegration;
};

/** Possible values for tenant user integration type. */
export enum TenantUserIntegration {
  Managed = 'MANAGED',
  Federated = 'FEDERATED',
}

/** Input for specifying tenant id. */
export type TenantWhereInput = {
  id: Scalars['ID'];
};

/** Possible values of work order completion date */
export enum TenantWorkOrderCompletionDate {
  WorkOrderFirstCompletionDate = 'work_order_first_completion_date',
  WorkOrderLastCompletionDate = 'work_order_last_completion_date',
}

export type TenantWorkOrderVolumeAlertSettingData = {
  __typename?: 'TenantWorkOrderVolumeAlertSettingData';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories: WorkOrderVolumeAlertCategory;
};

/**
 * Input for work orders configuration data
 * See TenantFinanceDataValues for details
 */
export type TenantWorkOrdersDataInput = {
  /** Configuration data type */
  id: TenantConfigurationDataType;
  app_code: Scalars['String'];
  work_order_completion_date: TenantWorkOrderCompletionDate;
  on_time_completion_ratings: Array<DataThresholdInput>;
  pm_on_time_completion_ratings: Array<DataThresholdInput>;
  emergency_on_time_completion_ratings: Array<DataThresholdInput>;
  customer_satisfaction_ratings: Array<DataThresholdInput>;
  invoices_submitted_less_30_days_ratings: Array<DataThresholdInput>;
};

/** Represents work orders configuration data values */
export type TenantWorkOrdersDataValues = {
  __typename?: 'TenantWorkOrdersDataValues';
  /** Configuration data type */
  id: TenantConfigurationDataType;
  /** Configuration data values field */
  work_order_completion_date: TenantWorkOrderCompletionDate;
  /** Configuration data values field */
  on_time_completion_ratings: Array<DataThreshold>;
  /** Configuration data values field */
  pm_on_time_completion_ratings: Array<DataThreshold>;
  /** Configuration data values field */
  emergency_on_time_completion_ratings: Array<DataThreshold>;
  /** Configuration data values field */
  customer_satisfaction_ratings: Array<DataThreshold>;
  /** Configuration data values field */
  invoices_submitted_less_30_days_ratings: Array<DataThreshold>;
};

/** Input for specifying list of tenant ids. */
export type TenantsWhereUpdateInput = {
  ids: Array<Scalars['ID']>;
};

export enum TestStatus {
  Successful = 'successful',
  Failed = 'failed',
}

/** Represents a report URL with a ticket used for Tableau Server VizPortal Trusted Authentication. */
export type TrustedReportUrl = {
  __typename?: 'TrustedReportUrl';
  /** The report URL with a trusted ticket, or -1 for invalid tickets. */
  report_url: Scalars['String'];
  /** Is the ticket valid. */
  is_valid_ticket: Scalars['Boolean'];
};

/** Possible values for geographical node. */
export enum UniversalFilterGeoNode {
  Country = 'country',
  State = 'state',
  City = 'city',
  Property = 'property',
}

/** Represents unmapped data standards */
export type Unmapped = {
  __typename?: 'Unmapped';
  id: Scalars['ID'];
  source_value: Scalars['String'];
  context_name: Array<Scalars['String']>;
  record_keys: Array<UnmappedRecordKey>;
};

/** Represents record_key element of Unmapped type */
export type UnmappedRecordKey = {
  __typename?: 'UnmappedRecordKey';
  context_name: Scalars['String'];
  record_keys: Array<Scalars['String']>;
};

/**
 * Input for updating address info.
 * See Address type for details.
 */
export type UpdateAddressInput = {
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['ID']>;
};

/** Input for updating application. See Application type for details. */
export type UpdateApplicationInput = {
  app_name?: Maybe<Scalars['String']>;
  app_cat?: Maybe<Scalars['String']>;
  app_active?: Maybe<Scalars['Boolean']>;
  app_default?: Maybe<Scalars['Boolean']>;
  app_description?: Maybe<Scalars['String']>;
  app_data_upload?: Maybe<Scalars['Boolean']>;
  app_subcodes?: Maybe<Array<ApplicationSubcodeInput>>;
};

/**
 * Input for updating category.
 * See Category type for details.
 */
export type UpdateCategoryInput = {
  category_description?: Maybe<Scalars['String']>;
  category_reports?: Maybe<Array<Scalars['ID']>>;
};

/**
 * Input for updating country.
 * See Country type for details.
 */
export type UpdateCountryInput = {
  country_iso_code?: Maybe<Scalars['String']>;
  country_desc?: Maybe<Scalars['String']>;
};

/** Input for updating region. */
export type UpdateRegionInput = {
  /** Region name. */
  region_name: Scalars['String'];
};

export type UpdateReleaseInput = {
  test_status?: Maybe<TestStatus>;
  comments?: Maybe<Scalars['String']>;
};

/**
 * Input for specifying report fields to update.
 * See Report type for details.
 */
export type UpdateReportInput = {
  report_full_keywords?: Maybe<Array<KeywordInput>>;
  report_name?: Maybe<Scalars['String']>;
  report_external_thumbnail_pathname?: Maybe<Scalars['String']>;
  report_external_anchor_target?: Maybe<ReportAnchorTarget>;
};

/** Input for specifying report id to update and data to update. */
export type UpdateReportsInput = {
  report_id: Scalars['ID'];
  report_data: UpdateReportInput;
};

/** Input for updating tenant data share for custom reports. */
export type UpdateTenantDataShareForCustomReports = {
  id: Scalars['ID'];
  /** Snowflake url. */
  snowflake_url: Scalars['String'];
};

export type UpdateTenantReportsInput = {
  order_update?: Maybe<Array<Scalars['ID']>>;
  elements_update?: Maybe<Array<TenantReportInput>>;
};

/** Input for updating user access settings for a given tenant. */
export type UpdateTenantUserInput = {
  /** User id. */
  user_id: Scalars['ID'];
  /** Indicates if user should be enabled. */
  user_enabled?: Maybe<Scalars['Boolean']>;
  /** User role. */
  role_type?: Maybe<TenantRoleType>;
  /** Role Id */
  role_id: Scalars['String'];
  /** Indicates user management type. */
  user_management_type: UserManagementType;
};

/** Input for updating extended user properties. */
export type UpdateUserExtendedInput = {
  /** User classification type. */
  user_type?: Maybe<UserClassificationType>;
};

/**
 * Input for updating user data.
 * See User type for details.
 */
export type UpdateUserInput = {
  user_settings?: Maybe<UserSettingsInput>;
  user_full_name?: Maybe<Scalars['String']>;
  user_picture_url?: Maybe<Array<Scalars['String']>>;
  user_fav_reports?: Maybe<Array<Scalars['ID']>>;
  user_address?: Maybe<UserAddressInput>;
  user_comm?: Maybe<UserCommunicationsInput>;
  user_tenants?: Maybe<Array<Scalars['ID']>>;
  user_tutored?: Maybe<Scalars['Boolean']>;
};

/**
 * Input for updating work area.
 * See WorkArea type for details.
 */
export type UpdateWorkAreaInput = {
  specializations?: Maybe<Array<WorkSpecializationUpdateInput>>;
};

/**
 * Input for keyword upsert.
 * See Keyword type for details.
 */
export type UpsertKeywordDataInput = {
  keyword_name: Scalars['String'];
};

/** Input for report mapping upsert. */
export type UpsertReportMappingDataInput = {
  report_name: Scalars['String'];
  report_type: ReportType;
  report_keywords?: Maybe<Array<Scalars['ID']>>;
  report_description?: Maybe<Scalars['String']>;
};

/** Input for creating/updating tenant self serve analytics settings. */
export type UpsertTenantSelfServeAnalyticsSettings = {
  self_serve_analytics_enabled: Scalars['Boolean'];
  self_serve_analytics_url?: Maybe<Scalars['String']>;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type User = {
  __typename?: 'User';
  /** Unique id. */
  id: Scalars['ID'];
  /** User email. */
  user_email: Scalars['String'];
  /** User global settings. */
  user_settings: UserSettings;
  /** User full name. */
  user_full_name: Scalars['String'];
  /** List of user profile pictures. */
  user_picture_url?: Maybe<Array<Scalars['String']>>;
  /** List of user favorite reports. It is a mix of reports for all tenants user has access to. */
  user_fav_reports: Array<Maybe<Report>>;
  /** List of user saved reports. It is a mix of reports for all tenants user has access to. */
  user_saved_reports: Array<Maybe<Report>>;
  /** User address info. */
  user_address: Address;
  /** User preferred communication methods. */
  user_comm: Communications;
  /** List of KPI data for all user tenants. */
  user_kpi_data: Array<TenantKpiData>;
  /** Indicates if user has completed tutorial. */
  user_tutored: Scalars['Boolean'];
  /** User admin settings. */
  user_admin_settings: UserAdminSettings;
  /** List of settings per tenant (only settings related to tenant access). */
  user_tenant_settings: Array<UserTenantSettings>;
  /** List of settings per tenant (only settings not related to tenant access). */
  user_tenant_regular_settings: Array<UserTenantRegularSettings>;
  /** List of all personas associated with user email. */
  user_personas: Array<UserPersona>;
  /** Type of user profile. */
  user_profile_type: UserProfileType;
  /** User insights alert summary. */
  user_insights_alert_summary: InsightsAlertSummary;
  /** User insights alerts. */
  user_insights_alerts: InsightsAlerts;
  /** A single alert for specified combination of user, tenant and alert thread id. */
  user_insights_alert?: Maybe<InsightsAlertThread>;
  /** List of AD groups user belongs to. Fetches groups for supplied tenant. */
  user_ad_groups: Array<AdGroup>;
  /** Shows possible lifecycle options. */
  user_lifecycle_options?: Maybe<UserLifecycleOptions>;
  /** Indicates user management type. */
  user_management_type: UserManagementType;
  /** Status of the user IDP. */
  user_status: UserStatus;
  /** Indicates user acceptance on agreements */
  user_agreement: UserAgreement;
  /** Indicates user classification type. */
  user_type: UserClassificationType;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_Tenant_SettingsArgs = {
  tenant_ids?: Maybe<Array<Scalars['ID']>>;
  access_list_as_is?: Maybe<Scalars['Boolean']>;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_Tenant_Regular_SettingsArgs = {
  tenant_ids?: Maybe<Array<Scalars['ID']>>;
  access_list_as_is?: Maybe<Scalars['Boolean']>;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_PersonasArgs = {
  tenant_ids?: Maybe<Array<Scalars['ID']>>;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_Insights_Alert_SummaryArgs = {
  where: WhereGetInsightsAlertSummaryInput;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_Insights_AlertsArgs = {
  where: WhereGetInsightsAlertsInput;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_Insights_AlertArgs = {
  where: WhereGetInsightsAlertThreadInput;
};

/**
 * Represents user profile.
 * There might be multiple profiles associated with a single email (e.g. for demo clients).
 */
export type UserUser_Ad_GroupsArgs = {
  tenant_id: Scalars['ID'];
};

/**
 * Input for updating user address info.
 * See Address type for details.
 */
export type UserAddressInput = {
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['ID']>;
};

/**
 * Represents user admin settings.
 * These settings are related to user's system-wide permissions.
 */
export type UserAdminSettings = {
  __typename?: 'UserAdminSettings';
  /** When user admin settings were updated. */
  user_updated_at?: Maybe<Scalars['String']>;
  /** By whom user admin settings were updated. */
  user_updated_by?: Maybe<Scalars['String']>;
  /** When user profile was created. */
  user_created_at: Scalars['String'];
  /** By whom user profile was created. */
  user_created_by: Scalars['String'];
  /** List of user system roles. */
  user_system_roles: Array<Role>;
};

/** Represents various user agreements. */
export type UserAgreement = {
  __typename?: 'UserAgreement';
  /** Indicates if the terms and conditions were accepted. */
  terms_accepted: Scalars['Boolean'];
  /** Indicates when the terms and conditions were accepted. */
  terms_accepted_at: Scalars['String'];
};

/**
 * Input for updating user agreement.
 * See UserAgreement type for details.
 */
export type UserAgreementInput = {
  /** Indicates if the terms and conditions were accepted. */
  terms_accepted: Scalars['Boolean'];
};

/** Represents user alert settings data for a given user. */
export type UserAlertSettingData =
  | WorkOrderVolumeAlertSettingData
  | FacilitiesExperienceAlertSettingData
  | LeaseEventAlertSettingData
  | CovidWorkOrderAlertSettingData
  | MarketBenchmarkAlertSettingData
  | OccupancyEfficiencyAlertSettingData
  | SubleaseRiskAlertSettingData
  | SameDayWorkOrderCompletionAlertSettingData;

/**
 * Input for user alert settings data.
 * See UserAlertSettingsData type for details.
 */
export type UserAlertSettingDataInput = {
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Tenant Alert Settings Type for input. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings for input. */
  categories_excluded: TenantAlertCategoriesInput;
  /** Indicates if this type of alert is enabled for input. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents user alerts settings. */
export type UserAlerts = {
  __typename?: 'UserAlerts';
  /** Tenant id. */
  tenant_id: Scalars['ID'];
  /** Contact method for alerts. */
  contact_method_email: Scalars['Boolean'];
  /** Contact method for alerts. */
  contact_method_phone: Scalars['Boolean'];
  /** Contact frequency. */
  contact_frequency: Scalars['String'];
};

/**
 * Input for updating / creating user alert settings.
 * See UserAlerts type.
 */
export type UserAlertsInput = {
  tenant_id: Scalars['ID'];
  contact_method_email: Scalars['Boolean'];
  contact_method_phone: Scalars['Boolean'];
  contact_frequency: Scalars['String'];
};

/** Input for adding / updating user custom category for a given report. */
export type UserCatReportInput = {
  category_description: Scalars['String'];
  report_id: Scalars['ID'];
};

/** Represents user-defined category name. */
export type UserCategory = {
  __typename?: 'UserCategory';
  /** Category report. */
  report?: Maybe<Report>;
  /** List of category descriptions. */
  category_descriptions: Array<Scalars['String']>;
};

/** Represents values required for user classification at system level. */
export type UserClassification = {
  __typename?: 'UserClassification';
  users: Array<User>;
  /** Total number of unique users in the system. */
  total_users: Scalars['Int'];
  /** Number of unique internal users. */
  internal_users: Scalars['Int'];
  /** Number of unique client users. */
  client_users: Scalars['Int'];
  /** Number of unique client corporate users. */
  client_corporate_users: Scalars['Int'];
  /** Continuation token for pagination. */
  continuation_token?: Maybe<Scalars['String']>;
  /** Option values for user classification search filter criteria. */
  filters_options?: Maybe<UserClassificationFiltersOptions>;
  /** User classification filters. */
  filters?: Maybe<UserClassificationFilters>;
};

/** Represents user classification search filter criteria. */
export type UserClassificationFilters = {
  __typename?: 'UserClassificationFilters';
  /** Client id. */
  client_id?: Maybe<Array<Scalars['ID']>>;
  /** User classification type. */
  user_type?: Maybe<Array<UserClassificationType>>;
  /** User name. */
  user_name?: Maybe<Array<Scalars['String']>>;
  /** User email address. */
  user_email?: Maybe<Array<Scalars['String']>>;
};

/** Input for limiting user classification search results. */
export type UserClassificationFiltersInput = {
  /** Client id. */
  client_id?: Maybe<Array<Scalars['ID']>>;
  /** User classification type. */
  user_type?: Maybe<Array<UserClassificationType>>;
  /** User name. */
  user_name?: Maybe<Array<Scalars['String']>>;
  /** User email address. */
  user_email?: Maybe<Array<Scalars['String']>>;
};

/** Represents the options for user classification filters. */
export type UserClassificationFiltersOptions = {
  __typename?: 'UserClassificationFiltersOptions';
  /** Client list. */
  client?: Maybe<Array<Tenant>>;
  /** User classification type list. */
  user_type?: Maybe<Array<UserClassificationType>>;
  /** User name list. */
  user_name?: Maybe<Array<Scalars['String']>>;
  /** User email address list. */
  user_email?: Maybe<Array<Scalars['String']>>;
};

/** Represents the options for user classification filters. */
export type UserClassificationFiltersOptionsUser_NameArgs = {
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<UserClassificationFiltersInput>;
};

/** Represents the options for user classification filters. */
export type UserClassificationFiltersOptionsUser_EmailArgs = {
  email?: Maybe<Scalars['String']>;
  filters?: Maybe<UserClassificationFiltersInput>;
};

/**
 * Input for fetching UserClassification.
 * See UserClassification type for details.
 */
export type UserClassificationInput = {
  /** Token used to get result's next page. */
  continuation_token?: Maybe<Scalars['String']>;
  /** Filters to limit the search results. */
  filters?: Maybe<UserClassificationFiltersInput>;
};

/** Represents possible user classification types. */
export enum UserClassificationType {
  /** Azara system admin or product support user. */
  Internal = 'INTERNAL',
  /** JLL employee client user (i.e., client user with a jll.com email address). */
  ClientCorporateUser = 'CLIENT_CORPORATE_USER',
  /** Standard client user (i.e., client user without a jll.com email address). */
  ClientUser = 'CLIENT_USER',
}

/**
 * Input for adding / updating user's preferred communication.
 * See UserCommunications for details.
 */
export type UserCommunicationsInput = {
  home?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  work?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

/** Represents user's saved view. */
export type UserCustomReport = {
  __typename?: 'UserCustomReport';
  /** Updated user. */
  user: User;
  /** Saved view. */
  saved_report: Report;
};

/** Represents user selections in alert inbox. */
export type UserInsightsInboxSettings = {
  __typename?: 'UserInsightsInboxSettings';
  /** Unique Id for user / tenant combination. */
  id: Scalars['ID'];
  /** List of inbox types along with recently selected alert. */
  insights_inbox_alerts: Array<InsightsInboxAlerts>;
  /** Selected inbox filters. */
  insights_selected_filters: InsightsSelectedFilters;
  /** Selected sort order. */
  insights_selected_sort_order: InsightsInboxSelectedSortOrder;
};

/** Represents possible lifecycle actions that can be done on user. */
export type UserLifecycleOptions = {
  __typename?: 'UserLifecycleOptions';
  /** Indicates if user can be activated. */
  user_can_be_activated?: Maybe<Scalars['Boolean']>;
  /** Indicates if user's password can be reset. */
  user_password_can_be_reset?: Maybe<Scalars['Boolean']>;
  /** Indicates if user account can be unlocked. */
  user_can_be_unlocked?: Maybe<Scalars['Boolean']>;
  /** Indicates if user account can be unsuspended. */
  user_can_be_unsuspended?: Maybe<Scalars['Boolean']>;
};

/** Represents possible user management types. */
export enum UserManagementType {
  Asa = 'ASA',
  Managed = 'MANAGED',
  Federated = 'FEDERATED',
  Unsupported = 'UNSUPPORTED',
}

/** Represents onboarding related variables */
export type UserOnboarding = {
  __typename?: 'UserOnboarding';
  /** Status of the onboarding */
  status: UserOnboardingStatus;
};

export type UserOnboardingInput = {
  /** User onboarding status. */
  status: UserOnboardingStatus;
};

/** Possible values for user onboarding status */
export enum UserOnboardingStatus {
  InProgress = 'in_progress',
  Skipped = 'skipped',
  Completed = 'completed',
}

/** Represents persona configuration. */
export type UserPersona = {
  __typename?: 'UserPersona';
  /** Profile id. */
  id: Scalars['ID'];
  /** Persona name. */
  name: Scalars['String'];
  /** Type of user profile. */
  profile_type: UserProfileType;
  /** Persona description. */
  description?: Maybe<Scalars['String']>;
  /** Tenant id. */
  tenant_id?: Maybe<Scalars['String']>;
};

/** Input for creating persona. */
export type UserPersonaInput = {
  /** Persona name. */
  name: Scalars['String'];
  /** Tenant id. */
  tenant_id: Scalars['ID'];
  /** Persona description. */
  description?: Maybe<Scalars['String']>;
};

/** User profile type. Either main profile or persona (used for demo clients). */
export enum UserProfileType {
  Main = 'main',
  Persona = 'persona',
}

/** Represents status of user provisioning. */
export enum UserProvisioningStatus {
  Queued = 'queued',
  Done = 'done',
  Error = 'error',
}

/**
 * Represents user settings.
 * These are global settings. Tenant-specific settings will be moved to a separate place.
 */
export type UserSettings = {
  __typename?: 'UserSettings';
  /** Current tenant. */
  default_tenant?: Maybe<Tenant>;
  /** Preferred contact. */
  preferred_contact?: Maybe<Scalars['String']>;
  /** User responsibilities for all user tenants. */
  responsibilities?: Maybe<Array<UserTenantKeyword>>;
  /** User work roles for all user tenants. */
  work_roles?: Maybe<Array<UserTenantWorkRole>>;
  /** User hidden reports for all user tenants. */
  hidden_reports?: Maybe<Array<Report>>;
  /** User custom categories for all reports. */
  custom_categories?: Maybe<Array<UserCategory>>;
  /** User universal filters for all user tenants. */
  universal_filters?: Maybe<Array<UserTenantUniversalFilters>>;
  /** User alert settings for all user tenants. */
  alerts?: Maybe<Array<UserAlerts>>;
};

/**
 * Input for updating / creating user global settings.
 * See UserSettings for details.
 */
export type UserSettingsInput = {
  default_tenant?: Maybe<Scalars['ID']>;
  preferred_contact?: Maybe<Scalars['String']>;
  work_roles?: Maybe<Array<UserTenantWorkRoleInput>>;
  responsibilities?: Maybe<Array<UserTenantKeywordInput>>;
  hidden_reports?: Maybe<Array<Scalars['ID']>>;
  universal_filters?: Maybe<Array<UserTenantUniversalFiltersInput>>;
  alerts?: Maybe<Array<UserAlertsInput>>;
};

/** Possible values for user site role. */
export enum UserSiteRole {
  Creator = 'Creator',
  Explorer = 'Explorer',
  ExplorerCanPublish = 'ExplorerCanPublish',
  ServerAdministrator = 'ServerAdministrator',
  SiteAdministratorExplorer = 'SiteAdministratorExplorer',
  SiteAdministratorCreator = 'SiteAdministratorCreator',
  Unlicensed = 'Unlicensed',
  ReadOnly = 'ReadOnly',
  Viewer = 'Viewer',
}

/** Possible values for user status */
export enum UserStatus {
  Pending = 'PENDING',
  Expired = 'EXPIRED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  Suspend = 'SUSPEND',
  Recovery = 'RECOVERY',
}

/** Represents user responsibilities for a given tenant. */
export type UserTenantKeyword = {
  __typename?: 'UserTenantKeyword';
  /** Tenant id. */
  tenant_id: Scalars['ID'];
  /** List of keywords. */
  keywords: Array<Keyword>;
};

/** Input for updating / creating responsibilities for a given tenant. */
export type UserTenantKeywordInput = {
  tenant_id: Scalars['ID'];
  keywords: Array<Scalars['ID']>;
};

/**
 * Represents user tenant settings not related to user access to tenant.
 * This is where all tenant-specific settings should be placed.
 */
export type UserTenantRegularSettings = {
  __typename?: 'UserTenantRegularSettings';
  /** Default client data definition view. */
  default_data_application?: Maybe<Application>;
  /** User default profile of persona. */
  default_profile?: Maybe<Scalars['String']>;
  /** Settings id */
  id?: Maybe<Scalars['ID']>;
  /** Settings for alert rule builder. */
  user_alert_rule_builder_settings?: Maybe<Array<UserAlertSettingData>>;
  /** Settings related to user selection in alerts inbox. */
  user_insights_inbox_settings: UserInsightsInboxSettings;
  /** Onboarding related variables. */
  user_onboarding?: Maybe<UserOnboarding>;
  /** User reports order. */
  user_reports_order?: Maybe<Array<Scalars['String']>>;
  /** Tenant for which this settings apply. */
  user_tenant: Tenant;
};

/** Input for updating user default profile for a tenant. */
export type UserTenantRegularSettingsDefaultProfileInput = {
  default_profile?: Maybe<Scalars['String']>;
};

/**
 * Input for updating user tenant regular settings.
 * See UserTenantRegularSettings for details.
 */
export type UserTenantRegularSettingsInput = {
  user_alert_rule_builder_settings?: Maybe<Array<UserAlertSettingDataInput>>;
  default_data_application?: Maybe<Scalars['String']>;
  user_reports_order?: Maybe<Array<Scalars['String']>>;
  default_profile?: Maybe<Scalars['String']>;
  user_onboarding?: Maybe<UserOnboardingInput>;
};

/**
 * Represents user tenant settings related to access to tenants.
 * This is where all tenant-specific settings should be placed.
 */
export type UserTenantSettings = {
  __typename?: 'UserTenantSettings';
  /** Settings Id. */
  id?: Maybe<Scalars['ID']>;
  /** User tenant role. */
  user_role?: Maybe<Role>;
  /** Tenant for which this settings apply. */
  user_tenant: Tenant;
  /** When user was added to tenant. */
  user_added_at: Scalars['String'];
  /** By whom user was added to tenant. */
  user_added_by: Scalars['String'];
  /** If user is enabled for this tenant. */
  user_enabled: Scalars['Boolean'];
  /** When user tenant access (role, enabled) was updated. */
  user_updated_at?: Maybe<Scalars['String']>;
  /** By whom user tenant access was updated. */
  user_updated_by?: Maybe<Scalars['String']>;
  /** Status of provisioning. */
  user_provisioning_status?: Maybe<UserProvisioningStatus>;
  /** Message of error which occurred during user provisioning. */
  user_provisioning_error?: Maybe<Scalars['String']>;
  /** Provisioned tableau site role. */
  user_provisioned_site_role?: Maybe<UserSiteRole>;
  /** Last user login time. */
  user_last_login?: Maybe<Scalars['String']>;
  /** Last user logout time. */
  user_last_logout?: Maybe<Scalars['String']>;
  /** When user was provisioned. */
  user_provisioned_at?: Maybe<Scalars['String']>;
};

/** Represents user universal filters values for a given tenant. */
export type UserTenantUniversalFilters = {
  __typename?: 'UserTenantUniversalFilters';
  /** Tenant id. */
  tenant_id: Scalars['ID'];
  /** Tenant ovc id. */
  tenant_ovc_id?: Maybe<Scalars['String']>;
  /** Filters values. */
  filters: UserUniversalFilters;
};

/**
 * Input for creating / updating universal filters for a given tenant.
 * See UserTenantUniversalFilters type for details.
 */
export type UserTenantUniversalFiltersInput = {
  tenant_id: Scalars['ID'];
  tenant_ovc_id: Scalars['String'];
  filters: UserUniversalFiltersInput;
};

/** Represents user work role for a given tenant. */
export type UserTenantWorkRole = {
  __typename?: 'UserTenantWorkRole';
  /** Tenant id. */
  tenant_id: Scalars['ID'];
  /** User work role and its subfields. */
  work_role: UserWorkRole;
};

/** Input for updating / creating work area for a given tenant. */
export type UserTenantWorkRoleInput = {
  tenant_id: Scalars['ID'];
  work_role: UserWorkRoleInput;
};

/** Represents single filter value. */
export type UserUniversalFilter = {
  __typename?: 'UserUniversalFilter';
  /** Filter id. */
  id: Scalars['ID'];
  /** Filter ancestor values. */
  filter_ancestors?: Maybe<Array<Scalars['String']>>;
  /** Filter value. */
  filter_value: Scalars['String'];
};

/**
 * Input for creating / updating single value for a given filter.
 * See UserUniversalFilter type for details.
 */
export type UserUniversalFilterInput = {
  id: Scalars['String'];
  filter_ancestors?: Maybe<Array<Scalars['String']>>;
  filter_value: Scalars['String'];
};

/** Represents user universal filters values with meta info. */
export type UserUniversalFilterWithMeta = {
  __typename?: 'UserUniversalFilterWithMeta';
  /** Name of the filter. */
  name: Scalars['String'];
  /** List of filter values. */
  filters: Array<UserUniversalFilter>;
};

/**
 * Input for creating / updating universal filters values with meta info.
 * See UserUniversalFilterWithMeta type for details.
 */
export type UserUniversalFilterWithMetaInput = {
  name: Scalars['String'];
  filters: Array<UserUniversalFilterInput>;
};

/** Represents universal filters values. */
export type UserUniversalFilters = {
  __typename?: 'UserUniversalFilters';
  /** Values for countries. */
  countries?: Maybe<UserUniversalFilterWithMeta>;
  /** Values for states. */
  states?: Maybe<UserUniversalFilterWithMeta>;
  /** Values for cities. */
  cities?: Maybe<UserUniversalFilterWithMeta>;
  /** Values for properties. */
  properties?: Maybe<UserUniversalFilterWithMeta>;
  /** Values for unit_of_measurement. */
  unit_of_measurement?: Maybe<AreaUnit>;
  /** Values for currency. */
  currency?: Maybe<CurrencyUnit>;
};

/**
 * Input for creating / updating universal filters values.
 * See UserUniversalFilters type for details.
 */
export type UserUniversalFiltersInput = {
  countries: UserUniversalFilterWithMetaInput;
  states: UserUniversalFilterWithMetaInput;
  cities: UserUniversalFilterWithMetaInput;
  properties: UserUniversalFilterWithMetaInput;
  unit_of_measurement?: Maybe<AreaUnit>;
  currency?: Maybe<CurrencyUnit>;
};

/**
 * Represents user's work role.
 * See also WorkArea type.
 */
export type UserWorkRole = {
  __typename?: 'UserWorkRole';
  /** Work area. */
  work_area: Scalars['String'];
  /** Work specialization. */
  specialization: Scalars['String'];
  /** Work role. */
  role: Scalars['String'];
};

/** Input for updating / creating user's work area. */
export type UserWorkRoleInput = {
  work_area: Scalars['String'];
  specialization: Scalars['String'];
  role: Scalars['String'];
};

/** Represents report view schema */
export type ViewSchema = {
  __typename?: 'ViewSchema';
  /** View name */
  name: Scalars['String'];
  /** Columns schemas */
  columns: Array<ColumnSchema>;
};

/** Specifies the alert thread to add a user comment. */
export type WhereAddInsightsAlertUserCommentInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Comment text. */
  comment: Scalars['String'];
  /** Alert comment creation date specified as Unix timestamp. */
  commented_at: Scalars['String'];
};

/** Input for specifying category id. */
export type WhereCategoryInput = {
  id: Scalars['ID'];
};

/** Input for creating covid-19 work order demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateCovid19WorkOrderDemoInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: Covid19WorkOrderInsightsAlertData;
};

/** Input for creating demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateDemoInsightsAlertInput = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: InsightsAlertData;
};

/** Input for creating facilities experience demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateFacilitiesExperienceDemoInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: FacilitiesExperienceInsightsAlertData;
};

/** Input for creating lease event demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateLeaseEventDemoInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: LeaseEventInsightsAlertData;
};

/** Input for creating market benchmark demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateMarketBenchmarkDemoInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: MarketBenchmarkInsightsAlertData;
};

/** Input for creating occupancy efficiency demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateOccupancyEfficiencyInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: OccupancyEfficiencyInsightsAlertData;
};

/** Input for creating same day work order completion demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateSameDayWorkOrderCompletionInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: SameDayWorkOrderCompletionInsightsAlertData;
};

/** Input for creating sublease risk demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateSubleaseRiskInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: SubleaseRiskInsightsAlertData;
};

/** Input for creating work order volume demo alert. This is used to create mock alerts for demo purposes. */
export type WhereCreateWorkOrderVolumeDemoInsightsAlertInput = {
  /** Alert generation type. */
  alert_generation_type: InsightsAlertGenerationType;
  /** Alert priority. */
  alert_priority: InsightsAlertPriority;
  /** Alert creation date specified as Unix timestamp. */
  created_at?: Maybe<Scalars['String']>;
  /** Alert data. */
  alert_data: WorkOrderVolumeInsightsAlertData;
};

export type WhereDataLoadsFileUploadSasUrl = {
  tenant_id: Scalars['String'];
  application: Scalars['String'];
  application_sub_code?: Maybe<Scalars['String']>;
};

export type WhereDataLoadsResetContainersInput = {
  tenant_ovc_id: Scalars['String'];
  reset_key: Scalars['Boolean'];
  container_name: Scalars['String'];
  start_time?: Maybe<Scalars['String']>;
  expiry_time?: Maybe<Scalars['String']>;
};

/** Input for deactivating user from tenant. */
export type WhereDeactivateUserFromTenantInput = {
  id: Scalars['ID'];
};

/** Input for deleting report */
export type WhereDeleteReportInput = {
  /** Tenant id */
  tenant_id: Scalars['ID'];
  /** Report id */
  report_id: Scalars['ID'];
};

/** Input for specifying user to delete. */
export type WhereDeleteTenantUserInput = {
  id: Scalars['ID'];
};

/** Represents required arguments to get download template sas url */
export type WhereDownloadTemplateSasUrlInput = {
  tenant_id: Scalars['String'];
  application: Scalars['String'];
};

/** Input for generating Tableau Server VizPortal external report URL using Trusted Authentication tickets. */
export type WhereGenerateTrustedExternalReportUrlInput = {
  /** Tenant ID. */
  tenant_id: Scalars['ID'];
  /** Report ID. */
  report_id: Scalars['ID'];
  /** Report URL. */
  report_url: Scalars['String'];
};

/** Input for generating Tableau Server VizPortal report URL using Trusted Authentication tickets. */
export type WhereGenerateTrustedReportUrlInput = {
  /** Tenant ID. */
  tenant_id: Scalars['ID'];
  /** Report ID. */
  report_id: Scalars['ID'];
  /** Report URL. */
  report_url: Scalars['String'];
};

/** Input for specifying id of region / country. */
export type WhereGeoInput = {
  id: Scalars['ID'];
};

/** Input for fetching alert thread history records. */
export type WhereGetInsightsAlertHistoryInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Continuation token for fetching more results. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Input for fetching alert thread ROI data. */
export type WhereGetInsightsAlertRoiDataInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
};

/** Input for fetching insights alert summary for a given combination of user and tenant. */
export type WhereGetInsightsAlertSummaryInput = {
  /** Id of tenant for which alerts were generated. */
  tenant_id: Scalars['ID'];
};

/** Input for fetching alert thread history records. */
export type WhereGetInsightsAlertThreadHistoryInput = {
  /** Continuation token for fetching more results. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Input for fetching a single alert thread. */
export type WhereGetInsightsAlertThreadInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of alert thread. */
  thread_id: Scalars['ID'];
};

/** Input for fetching alert thread user history records. */
export type WhereGetInsightsAlertThreadUserHistoryInput = {
  /** Continuation token for fetching more results. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Input for fetching alert thread user history records. */
export type WhereGetInsightsAlertUserHistoryInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Continuation token for fetching more results. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Input for fetching alert feed for a given combination of user, tenant and alert filters. */
export type WhereGetInsightsAlertsInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Importance of alert as set by user. By default is set to `unassigned`. End-user might change it to other values. */
  importance?: Maybe<InsightsAlertImportance>;
  /** Corresponds to alert type, e.g. `Work Order Volume`. */
  alert_type?: Maybe<InsightsAlertType>;
  /** Severity of alert. */
  severity?: Maybe<InsightsAlertSeverity>;
  /** Id of user for which alert was assigned. */
  assigned_user_id?: Maybe<Scalars['ID']>;
  /** Tab category of alert. */
  alert_tab?: Maybe<InsightsAlertTab>;
  /** Status of alert. Alert starts as `unread` and its status is modified by end-user actions. */
  status?: Maybe<InsightsAlertStatus>;
  /** User status of alert. */
  user_status?: Maybe<InsightsAlertUserStatus>;
  /** Sort order for the alert feed. */
  order_by?: Maybe<InsightsAlertOrderBy>;
  /** Continuation token for fetching more results. */
  continuation_token?: Maybe<Scalars['ID']>;
};

/** Input for getting user. */
export type WhereGetUserInput = {
  /** User id. */
  id?: Maybe<Scalars['ID']>;
  /** User email. */
  email?: Maybe<Scalars['String']>;
};

/** Input specifying id of keyword. */
export type WhereKeywordInput = {
  id: Scalars['ID'];
};

/** Input for specifying report id to publish / deactivate / preview. */
export type WhereMoveReportInput = {
  /** Id of report. */
  id: Scalars['ID'];
};

/** Input for reactivating user to tenant. */
export type WhereReactivateUserToTenantInput = {
  id: Scalars['ID'];
};

export type WhereReleaseInput = {
  id: Scalars['ID'];
};

/** Specifies the alert thread to remove its assigned user. */
export type WhereRemoveInsightsAlertAssignedUserInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Alert user assignment removal date specified as Unix timestamp. */
  unassigned_at: Scalars['String'];
};

/** Input for mapping identifier. */
export type WhereReportMappingInput = {
  id: Scalars['ID'];
};

/** Specifies the alert thread to update its assigned user. */
export type WhereSetInsightsAlertAssignedUserInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Id of user assigned to the alert. */
  assigned_user_id: Scalars['ID'];
  /** Alert user assignment date specified as Unix timestamp. */
  assigned_at: Scalars['String'];
};

/** Specifies the alert thread to update its ROI opportunity data. */
export type WhereSetInsightsAlertRoiDataInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Alert creation date specified as Unix timestamp. */
  created_at: Scalars['String'];
  /** ROI opportunity type. */
  opportunity_type?: Maybe<InsightsAlertRoiOpportunity>;
  /** ROI opportunity unit of measurement. */
  unit?: Maybe<InsightsAlertRoiUnit>;
  /** ROI opportunity frequency. */
  frequency?: Maybe<InsightsAlertRoiFrequency>;
  /** ROI opportunity value. */
  value?: Maybe<Scalars['Float']>;
  /** ROI comment text. */
  comment?: Maybe<Scalars['String']>;
};

/** Specifies the alert thread to update its severity. */
export type WhereSetInsightsAlertSeverityInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Alert severity. */
  severity: InsightsAlertSeverity;
  /** Alert creation date specified as Unix timestamp. */
  created_at: Scalars['String'];
};

/** Specifies the alert thread to update its status. */
export type WhereSetInsightsAlertStatusInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Alert status. */
  status: InsightsAlertStatusUpdate;
  /** Alert creation date specified as Unix timestamp. */
  created_at: Scalars['String'];
  /** Comment text. */
  comment?: Maybe<Scalars['String']>;
};

/** Specifies the alert thread to update its user importance. */
export type WhereSetInsightsAlertUserImportanceInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
  /** Importance of alert as set by user. */
  importance: InsightsAlertImportance;
};

/** Specifies the alert thread to mark as read. */
export type WhereSetInsightsAlertUserReadInput = {
  /** Id of tenant for which alert was generated. */
  tenant_id: Scalars['ID'];
  /** Id of user with access to the alert. */
  user_id: Scalars['ID'];
  /** Unique id of alert thread across the system. */
  thread_id: Scalars['ID'];
};

/** Input for specifying tenant ovc id. */
export type WhereTenantOvcIdInput = {
  tenant_ovc_id: Scalars['String'];
};

/** Input for specifying tenant id and role id. */
export type WhereTenantRoleInput = {
  tenant_id: Scalars['ID'];
  role_id?: Maybe<Scalars['ID']>;
};

/** Input for generating ThoughtSpot Trusted Authentication ticket. */
export type WhereThoughtSpotTrustedTicketInput = {
  /** User ID. */
  user_id: Scalars['ID'];
  /** Tenant ID. */
  tenant_id: Scalars['ID'];
};

/** Input for specifying which application should be updated / created. */
export type WhereUpdateApplicationInput = {
  id: Scalars['ID'];
};

/** Input for specifying report id. */
export type WhereUpdateReportInput = {
  /** Report Id. */
  report_id: Scalars['ID'];
  /** Tenant Id. */
  tenant_id: Scalars['ID'];
};

/** Input for specifying user to update. */
export type WhereUpdateUserInput = {
  /** User id. */
  id: Scalars['ID'];
};

/** Input for specifying work area id. */
export type WhereUpdateWorkAreaInput = {
  id: Scalars['ID'];
};

/** Input for setting user tenant settings. */
export type WhereUserTenantInput = {
  /** User id. */
  user_id: Scalars['ID'];
  /** Tenant id. */
  tenant_id: Scalars['ID'];
};

/** Input for validating external report embed URL. */
export type WhereValidateExternalReportEmbedUrlInput = {
  /** Tenant ID. */
  id: Scalars['ID'];
  /** Report URL. */
  embed_url: Scalars['String'];
};

/** Represents work area. */
export type WorkArea = {
  __typename?: 'WorkArea';
  /** Unique id. */
  id: Scalars['ID'];
  /** Name of work area. */
  name: Scalars['String'];
  /** List of specializations within work area. */
  specializations: Array<WorkSpecialization>;
  /** List of keywords. */
  keywords: Array<Scalars['String']>;
};

/** Represents settings for Work Order Volume alerts categories. */
export type WorkOrderVolumeAlertCategory = {
  __typename?: 'WorkOrderVolumeAlertCategory';
  internal: Array<Scalars['String']>;
  external: Array<Scalars['String']>;
};

/** Represents data saved by user related to excluded categories for Work Order Volume alert. */
export type WorkOrderVolumeAlertSettingData = {
  __typename?: 'WorkOrderVolumeAlertSettingData';
  /** Unique ID for user / tenant combination. */
  id: Scalars['ID'];
  /** Type of Tenant Alert Settings. */
  alert_type: TenantAlertSettingsType;
  /** Categories excluded for Alert Settings. */
  categories_excluded: WorkOrderVolumeAlertCategory;
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
};

/** Represents settings for Work Order Volume alerts. */
export type WorkOrderVolumeAlertSettings = {
  __typename?: 'WorkOrderVolumeAlertSettings';
  /** Alert type. */
  id: TenantAlertSettingsType;
  /** Unique id across all tenants. Combination of tenant id and alert type. */
  alert_tenant_id: Scalars['ID'];
  /** Indicates if this type of alert is enabled. */
  alert_enabled: Scalars['Boolean'];
  /** Refrence value for alerts above median. */
  wov_above_median: Scalars['Int'];
  /** Refrence value for internal alerts of medium priority. */
  internal_medium: Scalars['Float'];
  /** Refrence value for internal alerts of high priority. */
  internal_high: Scalars['Float'];
  /** Refrence value for external alerts of medium priority. */
  external_medium: Scalars['Float'];
  /** Refrence value for external alerts of high priority. */
  external_high: Scalars['Float'];
};

/**
 * Input for updating / creating settings for Work Order Volume alerts.
 * See WorkOrderVolumeAlertSettings type for details.
 */
export type WorkOrderVolumeAlertSettingsInput = {
  alert_enabled: Scalars['Boolean'];
  wov_above_median: Scalars['Int'];
  internal_medium: Scalars['Float'];
  internal_high: Scalars['Float'];
  external_medium: Scalars['Float'];
  external_high: Scalars['Float'];
};

/** Data for work order volume alerts. */
export type WorkOrderVolumeInsightsAlertData = {
  /** Alert type. */
  alert_type: InsightsAlertType;
  /** Reason. */
  reason?: Maybe<Scalars['String']>;
  /** Internal. */
  internal?: Maybe<Scalars['String']>;
  /** Current value internal. */
  current_value_internal?: Maybe<Scalars['String']>;
  /** Benchmark external. */
  benchmark_external?: Maybe<Scalars['String']>;
  /** Current value external. */
  current_value_external?: Maybe<Scalars['String']>;
  /** Property name. */
  property_name?: Maybe<Scalars['String']>;
  /** City name. */
  city_name?: Maybe<Scalars['String']>;
  /** State name. */
  state_name?: Maybe<Scalars['String']>;
  /** External medium. */
  external_medium?: Maybe<Scalars['String']>;
  /** Country name. */
  country_name?: Maybe<Scalars['String']>;
  /** Internal threshold percentage. */
  internal_threshold_percentage?: Maybe<Scalars['String']>;
  /** Peer group internal. */
  peer_group_internal?: Maybe<Scalars['String']>;
  /** Source system id. */
  source_system_id?: Maybe<Scalars['String']>;
  /** Work order created at specified as Unix timestamp. */
  work_order_created_at?: Maybe<Scalars['String']>;
  /** Peer group external. */
  peer_group_external?: Maybe<Scalars['String']>;
  /** External high. */
  external_high?: Maybe<Scalars['String']>;
  /** Alert scope. */
  alert_scope?: Maybe<Scalars['String']>;
  /** Property id. */
  property_id?: Maybe<Scalars['String']>;
  /** External. */
  external?: Maybe<Scalars['String']>;
  /** WOV internal priority. */
  wov_internal_priority?: Maybe<Scalars['String']>;
  /** WOV external priority. */
  wov_external_priority?: Maybe<Scalars['String']>;
  /** Internal medium. */
  internal_medium?: Maybe<Scalars['String']>;
  /** Work order id. */
  work_order_id?: Maybe<Scalars['String']>;
  /** External threshold percentage. */
  external_threshold_percentage?: Maybe<Scalars['String']>;
  /** Benchmark internal. */
  benchmark_internal?: Maybe<Scalars['String']>;
  /** WOV above median. */
  wov_above_median?: Maybe<Scalars['String']>;
  /** Internal high. */
  internal_high?: Maybe<Scalars['String']>;
};

/** Represents work role. */
export type WorkRole = {
  __typename?: 'WorkRole';
  /** Name of work role. */
  name: Scalars['String'];
};

/**
 * Input for creating work role.
 * See WorkRole type for details.
 */
export type WorkRoleInput = {
  name: Scalars['String'];
};

/**
 * Input for updating work role.
 * See WorkRole type for details.
 */
export type WorkRoleUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

/** Represents work specialization. */
export type WorkSpecialization = {
  __typename?: 'WorkSpecialization';
  /** Name of work specialization. */
  name: Scalars['String'];
  /** List of work roles within specialization. */
  roles: Array<WorkRole>;
};

/**
 * Input for creating work specialization.
 * See WorkSpecialization type for details.
 */
export type WorkSpecializationInput = {
  name: Scalars['String'];
  role: Array<WorkRoleInput>;
};

/**
 * Input for updating work specialization.
 * See WorkSpecialization type for details.
 */
export type WorkSpecializationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Array<WorkRoleUpdateInput>>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdGroup: ResolverTypeWrapper<AdGroup>;
  String: ResolverTypeWrapper<Scalars['String']>;
  AddDemoTenantInput: AddDemoTenantInput;
  AddTenantDataShareForCustomReports: AddTenantDataShareForCustomReports;
  Address: ResolverTypeWrapper<Address>;
  AlertTemplateData:
    | ResolversTypes['InsightsAlertBaseWorkOrderTemplateData']
    | ResolversTypes['InsightsAlertLeaseEventTemplateData']
    | ResolversTypes['InsightsAlertMarketBenchmarkTemplateData']
    | ResolversTypes['InsightsAlertOccupancyEfficiencyTemplateData']
    | ResolversTypes['InsightsAlertSameDayWorkOrderCompletionTemplateData']
    | ResolversTypes['InsightsAlertSubleaseRiskTemplateData']
    | ResolversTypes['InsightsAlertWorkOrderVolumeTemplateData'];
  ApiVersion: ResolverTypeWrapper<ApiVersion>;
  Application: ResolverTypeWrapper<Application>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ApplicationSchema: ResolverTypeWrapper<ApplicationSchema>;
  ApplicationSubcode: ResolverTypeWrapper<ApplicationSubcode>;
  ApplicationSubcodeInput: ApplicationSubcodeInput;
  AreaUnit: AreaUnit;
  BaselineReportControl: ResolverTypeWrapper<BaselineReportControl>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  BaselineReportInteractionConfiguration: ResolverTypeWrapper<BaselineReportInteractionConfiguration>;
  BaselineReportInteractionSection: ResolverTypeWrapper<BaselineReportInteractionSection>;
  BlobStorageUploadSasUrlInput: BlobStorageUploadSasUrlInput;
  Category: ResolverTypeWrapper<Category>;
  ColumnSchema: ResolverTypeWrapper<ColumnSchema>;
  Communications: ResolverTypeWrapper<Communications>;
  Continent: ResolverTypeWrapper<Continent>;
  Country: ResolverTypeWrapper<Country>;
  Covid19WorkOrderInsightsAlertData: Covid19WorkOrderInsightsAlertData;
  CovidWorkOrderAlertCategory: ResolverTypeWrapper<CovidWorkOrderAlertCategory>;
  CovidWorkOrderAlertSettingData: ResolverTypeWrapper<CovidWorkOrderAlertSettingData>;
  CovidWorkOrderAlertSettings: ResolverTypeWrapper<CovidWorkOrderAlertSettings>;
  CovidWorkOrderAlertSettingsInput: CovidWorkOrderAlertSettingsInput;
  CreateAddressInput: CreateAddressInput;
  CreateApplicationInput: CreateApplicationInput;
  CreateCategoryInput: CreateCategoryInput;
  CreateCountryInput: CreateCountryInput;
  CreateRegionInput: CreateRegionInput;
  CreateReportInput: CreateReportInput;
  CreateReportSavedViewInput: CreateReportSavedViewInput;
  CreateTenantUserInput: CreateTenantUserInput;
  CreateUserPersonaInput: CreateUserPersonaInput;
  CreateWorkAreaInput: CreateWorkAreaInput;
  CurrencyUnit: CurrencyUnit;
  CustomReportStatus: CustomReportStatus;
  DataLoadsContainers: ResolverTypeWrapper<DataLoadsContainers>;
  DataLoadsContainersInput: DataLoadsContainersInput;
  DataLoadsErrorInfo: ResolverTypeWrapper<DataLoadsErrorInfo>;
  DataLoadsFileUploadSasUrl: ResolverTypeWrapper<DataLoadsFileUploadSasUrl>;
  DataLoadsJob: ResolverTypeWrapper<DataLoadsJob>;
  DataLoadsJobLinks: ResolverTypeWrapper<DataLoadsJobLinks>;
  DataLoadsJobs: ResolverTypeWrapper<DataLoadsJobs>;
  DataLoadsJobsFiltersInput: DataLoadsJobsFiltersInput;
  DataLoadsJobsStatus: DataLoadsJobsStatus;
  DataLoadsRecordInfo: ResolverTypeWrapper<DataLoadsRecordInfo>;
  DataLoadsResetContainers: ResolverTypeWrapper<DataLoadsResetContainers>;
  DataThreshold: ResolverTypeWrapper<DataThreshold>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  DataThresholdInput: DataThresholdInput;
  DeleteTenantDataShareForCustomReports: DeleteTenantDataShareForCustomReports;
  EntityType: EntityType;
  ErrorLogInput: ErrorLogInput;
  EventType: EventType;
  ExternalReportType: ExternalReportType;
  FacilitiesExperienceAlertCategory: ResolverTypeWrapper<FacilitiesExperienceAlertCategory>;
  FacilitiesExperienceAlertSettingData: ResolverTypeWrapper<FacilitiesExperienceAlertSettingData>;
  FacilitiesExperienceAlertSettings: ResolverTypeWrapper<FacilitiesExperienceAlertSettings>;
  FacilitiesExperienceAlertSettingsInput: FacilitiesExperienceAlertSettingsInput;
  FacilitiesExperienceInsightsAlertData: FacilitiesExperienceInsightsAlertData;
  FlexibleMappingOptionSampleValues: ResolverTypeWrapper<FlexibleMappingOptionSampleValues>;
  GlobalData: ResolverTypeWrapper<GlobalData>;
  GlobalView: ResolverTypeWrapper<GlobalView>;
  GroupMapping: ResolverTypeWrapper<GroupMapping>;
  HiddenReportInput: HiddenReportInput;
  InsightsAlert: ResolverTypeWrapper<
    Omit<InsightsAlert, 'template_data'> & {
      template_data: ResolversTypes['InsightsAlertTemplateData'];
    }
  >;
  InsightsAlertBaseWorkOrderTemplateData: ResolverTypeWrapper<InsightsAlertBaseWorkOrderTemplateData>;
  InsightsAlertData: InsightsAlertData;
  InsightsAlertGenerationType: InsightsAlertGenerationType;
  InsightsAlertHistory: ResolverTypeWrapper<InsightsAlertHistory>;
  InsightsAlertHistoryRecord: ResolverTypeWrapper<InsightsAlertHistoryRecord>;
  InsightsAlertImportance: InsightsAlertImportance;
  InsightsAlertImportanceFilter: InsightsAlertImportanceFilter;
  InsightsAlertInbox: InsightsAlertInbox;
  InsightsAlertLeaseEventTemplateData: ResolverTypeWrapper<InsightsAlertLeaseEventTemplateData>;
  InsightsAlertMarketBenchmarkTemplateData: ResolverTypeWrapper<InsightsAlertMarketBenchmarkTemplateData>;
  InsightsAlertMarketBenchmarkTemplateDataPredictionRecord: ResolverTypeWrapper<InsightsAlertMarketBenchmarkTemplateDataPredictionRecord>;
  InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint: ResolverTypeWrapper<InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint>;
  InsightsAlertOccupancyEfficiencyTemplateData: ResolverTypeWrapper<InsightsAlertOccupancyEfficiencyTemplateData>;
  InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord: ResolverTypeWrapper<InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord>;
  InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord: ResolverTypeWrapper<InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord>;
  InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord: ResolverTypeWrapper<InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord>;
  InsightsAlertOrderBy: InsightsAlertOrderBy;
  InsightsAlertPriority: InsightsAlertPriority;
  InsightsAlertRoiData: ResolverTypeWrapper<InsightsAlertRoiData>;
  InsightsAlertRoiFrequency: InsightsAlertRoiFrequency;
  InsightsAlertRoiOpportunity: InsightsAlertRoiOpportunity;
  InsightsAlertRoiUnit: InsightsAlertRoiUnit;
  InsightsAlertSameDayWorkOrderCompletionTemplateData: ResolverTypeWrapper<InsightsAlertSameDayWorkOrderCompletionTemplateData>;
  InsightsAlertScope: InsightsAlertScope;
  InsightsAlertSeverity: InsightsAlertSeverity;
  InsightsAlertSeverityFilter: InsightsAlertSeverityFilter;
  InsightsAlertSortOrder: InsightsAlertSortOrder;
  InsightsAlertStatus: InsightsAlertStatus;
  InsightsAlertStatusUpdate: InsightsAlertStatusUpdate;
  InsightsAlertSubleaseRiskTemplateData: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateData>;
  InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord>;
  InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord>;
  InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord>;
  InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord>;
  InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord>;
  InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord: ResolverTypeWrapper<InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord>;
  InsightsAlertSummary: ResolverTypeWrapper<InsightsAlertSummary>;
  InsightsAlertSummaryCountsByStatus: ResolverTypeWrapper<InsightsAlertSummaryCountsByStatus>;
  InsightsAlertTab: InsightsAlertTab;
  InsightsAlertTemplateData:
    | ResolversTypes['InsightsAlertBaseWorkOrderTemplateData']
    | ResolversTypes['InsightsAlertLeaseEventTemplateData']
    | ResolversTypes['InsightsAlertWorkOrderVolumeTemplateData']
    | ResolversTypes['InsightsAlertMarketBenchmarkTemplateData']
    | ResolversTypes['InsightsAlertOccupancyEfficiencyTemplateData']
    | ResolversTypes['InsightsAlertSubleaseRiskTemplateData']
    | ResolversTypes['InsightsAlertSameDayWorkOrderCompletionTemplateData'];
  InsightsAlertThread: ResolverTypeWrapper<InsightsAlertThread>;
  InsightsAlertType: InsightsAlertType;
  InsightsAlertTypeFilter: InsightsAlertTypeFilter;
  InsightsAlertUserHistory: ResolverTypeWrapper<InsightsAlertUserHistory>;
  InsightsAlertUserHistoryActionPerformed: InsightsAlertUserHistoryActionPerformed;
  InsightsAlertUserHistoryRecord: ResolverTypeWrapper<InsightsAlertUserHistoryRecord>;
  InsightsAlertUserInfo: ResolverTypeWrapper<InsightsAlertUserInfo>;
  InsightsAlertUserStatus: InsightsAlertUserStatus;
  InsightsAlertWorkOrderVolumeTemplateData: ResolverTypeWrapper<InsightsAlertWorkOrderVolumeTemplateData>;
  InsightsAlerts: ResolverTypeWrapper<InsightsAlerts>;
  InsightsInboxAlerts: ResolverTypeWrapper<InsightsInboxAlerts>;
  InsightsInboxSelectedSortOrder: ResolverTypeWrapper<InsightsInboxSelectedSortOrder>;
  InsightsSelectedFilters: ResolverTypeWrapper<InsightsSelectedFilters>;
  Keyword: ResolverTypeWrapper<Keyword>;
  KeywordInput: KeywordInput;
  KpiData: ResolverTypeWrapper<KpiData>;
  LeaseEventAlertCategory: ResolverTypeWrapper<LeaseEventAlertCategory>;
  LeaseEventAlertSettingData: ResolverTypeWrapper<LeaseEventAlertSettingData>;
  LeaseEventAlertSettings: ResolverTypeWrapper<LeaseEventAlertSettings>;
  LeaseEventAlertSettingsInput: LeaseEventAlertSettingsInput;
  LeaseEventInsightsAlertData: LeaseEventInsightsAlertData;
  Log: ResolverTypeWrapper<Log>;
  Mapped: ResolverTypeWrapper<Mapped>;
  MappingType: MappingType;
  MarketBenchmarkAlertCategory: ResolverTypeWrapper<MarketBenchmarkAlertCategory>;
  MarketBenchmarkAlertSettingData: ResolverTypeWrapper<MarketBenchmarkAlertSettingData>;
  MarketBenchmarkAlertSettings: ResolverTypeWrapper<MarketBenchmarkAlertSettings>;
  MarketBenchmarkAlertSettingsInput: MarketBenchmarkAlertSettingsInput;
  MarketBenchmarkInsightsAlertData: MarketBenchmarkInsightsAlertData;
  MarketBenchmarkInsightsAlertDataPredictionRecord: MarketBenchmarkInsightsAlertDataPredictionRecord;
  MarketBenchmarkInsightsAlertDataSupportingDataPoint: MarketBenchmarkInsightsAlertDataSupportingDataPoint;
  MoveCustomReport: ResolverTypeWrapper<MoveCustomReport>;
  Mutation: ResolverTypeWrapper<{}>;
  OccupancyEfficiencyAlertCategory: ResolverTypeWrapper<OccupancyEfficiencyAlertCategory>;
  OccupancyEfficiencyAlertSettingData: ResolverTypeWrapper<OccupancyEfficiencyAlertSettingData>;
  OccupancyEfficiencyAlertSettings: ResolverTypeWrapper<OccupancyEfficiencyAlertSettings>;
  OccupancyEfficiencyAlertSettingsInput: OccupancyEfficiencyAlertSettingsInput;
  OccupancyEfficiencyInsightsAlertData: OccupancyEfficiencyInsightsAlertData;
  OccupancyEfficiencyTemplateDataHeadcountDensityTriggerInfoRecord: OccupancyEfficiencyTemplateDataHeadcountDensityTriggerInfoRecord;
  OccupancyEfficiencyTemplateDataOccupancyRateTriggerInfoRecord: OccupancyEfficiencyTemplateDataOccupancyRateTriggerInfoRecord;
  OccupancyEfficiencyTemplateDataVacancyRateTriggerInfoRecord: OccupancyEfficiencyTemplateDataVacancyRateTriggerInfoRecord;
  PostMappingDataStandardInput: PostMappingDataStandardInput;
  PostMappingDataStandardMappingOperation: PostMappingDataStandardMappingOperation;
  PostMappingDataStandardResponse: ResolverTypeWrapper<PostMappingDataStandardResponse>;
  ProductSettingsReportInput: ProductSettingsReportInput;
  PromoteReleaseInput: PromoteReleaseInput;
  PromotionAction: PromotionAction;
  PropertyData: ResolverTypeWrapper<PropertyData>;
  PropertyDataFilter: PropertyDataFilter;
  PropertyDataInput: PropertyDataInput;
  PropertyDataRecord: ResolverTypeWrapper<PropertyDataRecord>;
  Query: ResolverTypeWrapper<{}>;
  Region: ResolverTypeWrapper<Region>;
  ReleaseStatus: ReleaseStatus;
  ReleasesRecord: ResolverTypeWrapper<ReleasesRecord>;
  Report: ResolverTypeWrapper<Report>;
  ReportAnchorTarget: ReportAnchorTarget;
  ReportConfig: ResolverTypeWrapper<ReportConfig>;
  ReportControl: ResolverTypeWrapper<ReportControl>;
  ReportControlStaticListItem: ResolverTypeWrapper<ReportControlStaticListItem>;
  ReportControlType: ReportControlType;
  ReportInteractionConfiguration: ResolverTypeWrapper<ReportInteractionConfiguration>;
  ReportInteractionSection: ResolverTypeWrapper<ReportInteractionSection>;
  ReportLogType: ReportLogType;
  ReportMapping: ResolverTypeWrapper<ReportMapping>;
  ReportMetadata: ResolverTypeWrapper<ReportMetadata>;
  ReportMetadataMapping: ResolverTypeWrapper<ReportMetadataMapping>;
  ReportMetadataSourceData: ResolverTypeWrapper<ReportMetadataSourceData>;
  ReportModule: ResolverTypeWrapper<ReportModule>;
  ReportModuleCategory: ResolverTypeWrapper<ReportModuleCategory>;
  ReportModuleCategoryType: ReportModuleCategoryType;
  ReportModuleType: ReportModuleType;
  ReportPerformanceContextInput: ReportPerformanceContextInput;
  ReportPerformanceLogInput: ReportPerformanceLogInput;
  ReportPlatform: ReportPlatform;
  ReportSectionType: ReportSectionType;
  ReportType: ReportType;
  ReportWhereInput: ReportWhereInput;
  Role: ResolverTypeWrapper<Role>;
  RoleDashboard: ResolverTypeWrapper<RoleDashboard>;
  RoleDashboardInput: RoleDashboardInput;
  RoleDashboards: ResolverTypeWrapper<RoleDashboards>;
  RoleDashboardsInput: RoleDashboardsInput;
  RoleInput: RoleInput;
  RoleInsightsAlert: ResolverTypeWrapper<RoleInsightsAlert>;
  RoleInsightsAlertInput: RoleInsightsAlertInput;
  RoleInsightsAlerts: ResolverTypeWrapper<RoleInsightsAlerts>;
  RoleInsightsAlertsInput: RoleInsightsAlertsInput;
  RoleOtherFeature: ResolverTypeWrapper<RoleOtherFeature>;
  RoleOtherFeatureInput: RoleOtherFeatureInput;
  RoleOtherSubFeature: ResolverTypeWrapper<RoleOtherSubFeature>;
  RoleOtherSubFeatureInput: RoleOtherSubFeatureInput;
  RoleStatus: RoleStatus;
  RoleType: RoleType;
  RoleUniversalFilters: ResolverTypeWrapper<RoleUniversalFilters>;
  RoleUniversalFiltersInput: RoleUniversalFiltersInput;
  SameDayWorkOrderCompletionAlertCategory: ResolverTypeWrapper<SameDayWorkOrderCompletionAlertCategory>;
  SameDayWorkOrderCompletionAlertSettingData: ResolverTypeWrapper<SameDayWorkOrderCompletionAlertSettingData>;
  SameDayWorkOrderCompletionAlertSettings: ResolverTypeWrapper<SameDayWorkOrderCompletionAlertSettings>;
  SameDayWorkOrderCompletionAlertSettingsInput: SameDayWorkOrderCompletionAlertSettingsInput;
  SameDayWorkOrderCompletionInsightsAlertData: SameDayWorkOrderCompletionInsightsAlertData;
  SearchedUserResult: ResolverTypeWrapper<SearchedUserResult>;
  SegmentDataInput: SegmentDataInput;
  SegmentOptions: ResolverTypeWrapper<SegmentOptions>;
  SubleaseRiskAlertCategory: ResolverTypeWrapper<SubleaseRiskAlertCategory>;
  SubleaseRiskAlertSettingData: ResolverTypeWrapper<SubleaseRiskAlertSettingData>;
  SubleaseRiskAlertSettings: ResolverTypeWrapper<SubleaseRiskAlertSettings>;
  SubleaseRiskAlertSettingsInput: SubleaseRiskAlertSettingsInput;
  SubleaseRiskInsightsAlertData: SubleaseRiskInsightsAlertData;
  SubleaseRiskTemplateDataContractionOptionsRecord: SubleaseRiskTemplateDataContractionOptionsRecord;
  SubleaseRiskTemplateDataMainlyParkingLeasesRecord: SubleaseRiskTemplateDataMainlyParkingLeasesRecord;
  SubleaseRiskTemplateDataMonthToMonthTenuresRecord: SubleaseRiskTemplateDataMonthToMonthTenuresRecord;
  SubleaseRiskTemplateDataNaturalExpiryRecord: SubleaseRiskTemplateDataNaturalExpiryRecord;
  SubleaseRiskTemplateDataSubleaseWithExpiryRecord: SubleaseRiskTemplateDataSubleaseWithExpiryRecord;
  SubleaseRiskTemplateDataTerminationOptionsRecord: SubleaseRiskTemplateDataTerminationOptionsRecord;
  Tenant: ResolverTypeWrapper<
    Omit<Tenant, 'tenant_alert_settings' | 'tenant_alert_rule_builder'> & {
      tenant_alert_settings?: Maybe<
        Array<ResolversTypes['TenantAlertSettings']>
      >;
      tenant_alert_rule_builder: ResolversTypes['TenantAlertSettingData'];
    }
  >;
  TenantAlertCategoriesInput: TenantAlertCategoriesInput;
  TenantAlertSettingData:
    | ResolversTypes['TenantWorkOrderVolumeAlertSettingData']
    | ResolversTypes['TenantFacilitiesExperienceAlertSettingData']
    | ResolversTypes['TenantLeaseEventAlertSettingData']
    | ResolversTypes['TenantCovidWorkOrderAlertSettingData']
    | ResolversTypes['TenantMarketBenchmarkAlertSettingData']
    | ResolversTypes['TenantOccupancyEfficiencyAlertSettingData']
    | ResolversTypes['TenantSubleaseRiskAlertSettingData']
    | ResolversTypes['TenantSameDayWorkOrderCompletionAlertSettingData'];
  TenantAlertSettings:
    | ResolversTypes['WorkOrderVolumeAlertSettings']
    | ResolversTypes['FacilitiesExperienceAlertSettings']
    | ResolversTypes['LeaseEventAlertSettings']
    | ResolversTypes['CovidWorkOrderAlertSettings']
    | ResolversTypes['MarketBenchmarkAlertSettings']
    | ResolversTypes['OccupancyEfficiencyAlertSettings']
    | ResolversTypes['SubleaseRiskAlertSettings']
    | ResolversTypes['SameDayWorkOrderCompletionAlertSettings'];
  TenantAlertSettingsInput: TenantAlertSettingsInput;
  TenantAlertSettingsType: TenantAlertSettingsType;
  TenantApplication: ResolverTypeWrapper<TenantApplication>;
  TenantApplicationTenantIdInput: TenantApplicationTenantIdInput;
  TenantConfigurationData: ResolverTypeWrapper<
    Omit<TenantConfigurationData, 'values' | 'options' | 'sample_values'> & {
      values?: Maybe<ResolversTypes['TenantConfigurationDataValues']>;
      options?: Maybe<ResolversTypes['TenantConfigurationDataOptions']>;
      sample_values?: Maybe<
        ResolversTypes['TenantConfigurationDataSampleValues']
      >;
    }
  >;
  TenantConfigurationDataInput: TenantConfigurationDataInput;
  TenantConfigurationDataOptions:
    | ResolversTypes['TenantFinanceDataOptions']
    | ResolversTypes['TenantTransactionDataOptions']
    | ResolversTypes['TenantLeaseDataOptions']
    | ResolversTypes['TenantSpaceDataOptions'];
  TenantConfigurationDataSampleValues:
    | ResolversTypes['TenantSpaceDataSampleValues']
    | ResolversTypes['TenantFinanceDataSampleValues']
    | ResolversTypes['TenantLeaseDataSampleValues'];
  TenantConfigurationDataType: TenantConfigurationDataType;
  TenantConfigurationDataValues:
    | ResolversTypes['TenantDefaultsDataValues']
    | ResolversTypes['TenantWorkOrdersDataValues']
    | ResolversTypes['TenantFinanceDataValues']
    | ResolversTypes['TenantTransactionDataValues']
    | ResolversTypes['TenantLeaseDataValues']
    | ResolversTypes['TenantSpaceDataValues'];
  TenantCovidWorkOrderAlertSettingData: ResolverTypeWrapper<TenantCovidWorkOrderAlertSettingData>;
  TenantCreateInput: TenantCreateInput;
  TenantCustomReportsSettings: ResolverTypeWrapper<TenantCustomReportsSettings>;
  TenantCustomReportsSetup: ResolverTypeWrapper<TenantCustomReportsSetup>;
  TenantDataStandard: ResolverTypeWrapper<TenantDataStandard>;
  TenantDefaultsDataInput: TenantDefaultsDataInput;
  TenantDefaultsDataValues: ResolverTypeWrapper<TenantDefaultsDataValues>;
  TenantFacilitiesExperienceAlertSettingData: ResolverTypeWrapper<TenantFacilitiesExperienceAlertSettingData>;
  TenantFeatures: ResolverTypeWrapper<TenantFeatures>;
  TenantFeaturesInput: TenantFeaturesInput;
  TenantFinanceDataInput: TenantFinanceDataInput;
  TenantFinanceDataOptions: ResolverTypeWrapper<TenantFinanceDataOptions>;
  TenantFinanceDataSampleValues: ResolverTypeWrapper<TenantFinanceDataSampleValues>;
  TenantFinanceDataValues: ResolverTypeWrapper<TenantFinanceDataValues>;
  TenantGeoUniversalFilters: ResolverTypeWrapper<TenantGeoUniversalFilters>;
  TenantKpiData: ResolverTypeWrapper<TenantKpiData>;
  TenantKpiSettings: ResolverTypeWrapper<TenantKpiSettings>;
  TenantKpiSettingsInput: TenantKpiSettingsInput;
  TenantKpiSettingsType: TenantKpiSettingsType;
  TenantLeaseDataInput: TenantLeaseDataInput;
  TenantLeaseDataOptions: ResolverTypeWrapper<TenantLeaseDataOptions>;
  TenantLeaseDataSampleValues: ResolverTypeWrapper<TenantLeaseDataSampleValues>;
  TenantLeaseDataValues: ResolverTypeWrapper<TenantLeaseDataValues>;
  TenantLeaseEventAlertSettingData: ResolverTypeWrapper<TenantLeaseEventAlertSettingData>;
  TenantMarketBenchmarkAlertSettingData: ResolverTypeWrapper<TenantMarketBenchmarkAlertSettingData>;
  TenantOccupancyEfficiencyAlertSettingData: ResolverTypeWrapper<TenantOccupancyEfficiencyAlertSettingData>;
  TenantQaType: TenantQaType;
  TenantReportConfigurationInput: TenantReportConfigurationInput;
  TenantReportInput: TenantReportInput;
  TenantReportsConfiguration: ResolverTypeWrapper<TenantReportsConfiguration>;
  TenantReportsConfigurationInput: TenantReportsConfigurationInput;
  TenantReportsFiltersInput: TenantReportsFiltersInput;
  TenantRole: ResolverTypeWrapper<TenantRole>;
  TenantRoleType: TenantRoleType;
  TenantSameDayWorkOrderCompletionAlertSettingData: ResolverTypeWrapper<TenantSameDayWorkOrderCompletionAlertSettingData>;
  TenantSelfServeAnalyticsSettings: ResolverTypeWrapper<TenantSelfServeAnalyticsSettings>;
  TenantSnowflakeUrl: ResolverTypeWrapper<TenantSnowflakeUrl>;
  TenantSpaceDataInput: TenantSpaceDataInput;
  TenantSpaceDataOptions: ResolverTypeWrapper<TenantSpaceDataOptions>;
  TenantSpaceDataSampleValues: ResolverTypeWrapper<TenantSpaceDataSampleValues>;
  TenantSpaceDataValues: ResolverTypeWrapper<TenantSpaceDataValues>;
  TenantStatus: TenantStatus;
  TenantSubleaseRiskAlertSettingData: ResolverTypeWrapper<TenantSubleaseRiskAlertSettingData>;
  TenantTransactionDataInput: TenantTransactionDataInput;
  TenantTransactionDataOptions: ResolverTypeWrapper<TenantTransactionDataOptions>;
  TenantTransactionDataValues: ResolverTypeWrapper<TenantTransactionDataValues>;
  TenantType: TenantType;
  TenantUniversalFilterGeoValue: ResolverTypeWrapper<TenantUniversalFilterGeoValue>;
  TenantUniversalFilterNodeInput: TenantUniversalFilterNodeInput;
  TenantUpdateInput: TenantUpdateInput;
  TenantUserIntegration: TenantUserIntegration;
  TenantWhereInput: TenantWhereInput;
  TenantWorkOrderCompletionDate: TenantWorkOrderCompletionDate;
  TenantWorkOrderVolumeAlertSettingData: ResolverTypeWrapper<TenantWorkOrderVolumeAlertSettingData>;
  TenantWorkOrdersDataInput: TenantWorkOrdersDataInput;
  TenantWorkOrdersDataValues: ResolverTypeWrapper<TenantWorkOrdersDataValues>;
  TenantsWhereUpdateInput: TenantsWhereUpdateInput;
  TestStatus: TestStatus;
  TrustedReportUrl: ResolverTypeWrapper<TrustedReportUrl>;
  UniversalFilterGeoNode: UniversalFilterGeoNode;
  Unmapped: ResolverTypeWrapper<Unmapped>;
  UnmappedRecordKey: ResolverTypeWrapper<UnmappedRecordKey>;
  UpdateAddressInput: UpdateAddressInput;
  UpdateApplicationInput: UpdateApplicationInput;
  UpdateCategoryInput: UpdateCategoryInput;
  UpdateCountryInput: UpdateCountryInput;
  UpdateRegionInput: UpdateRegionInput;
  UpdateReleaseInput: UpdateReleaseInput;
  UpdateReportInput: UpdateReportInput;
  UpdateReportsInput: UpdateReportsInput;
  UpdateTenantDataShareForCustomReports: UpdateTenantDataShareForCustomReports;
  UpdateTenantReportsInput: UpdateTenantReportsInput;
  UpdateTenantUserInput: UpdateTenantUserInput;
  UpdateUserExtendedInput: UpdateUserExtendedInput;
  UpdateUserInput: UpdateUserInput;
  UpdateWorkAreaInput: UpdateWorkAreaInput;
  UpsertKeywordDataInput: UpsertKeywordDataInput;
  UpsertReportMappingDataInput: UpsertReportMappingDataInput;
  UpsertTenantSelfServeAnalyticsSettings: UpsertTenantSelfServeAnalyticsSettings;
  User: ResolverTypeWrapper<User>;
  UserAddressInput: UserAddressInput;
  UserAdminSettings: ResolverTypeWrapper<UserAdminSettings>;
  UserAgreement: ResolverTypeWrapper<UserAgreement>;
  UserAgreementInput: UserAgreementInput;
  UserAlertSettingData:
    | ResolversTypes['WorkOrderVolumeAlertSettingData']
    | ResolversTypes['FacilitiesExperienceAlertSettingData']
    | ResolversTypes['LeaseEventAlertSettingData']
    | ResolversTypes['CovidWorkOrderAlertSettingData']
    | ResolversTypes['MarketBenchmarkAlertSettingData']
    | ResolversTypes['OccupancyEfficiencyAlertSettingData']
    | ResolversTypes['SubleaseRiskAlertSettingData']
    | ResolversTypes['SameDayWorkOrderCompletionAlertSettingData'];
  UserAlertSettingDataInput: UserAlertSettingDataInput;
  UserAlerts: ResolverTypeWrapper<UserAlerts>;
  UserAlertsInput: UserAlertsInput;
  UserCatReportInput: UserCatReportInput;
  UserCategory: ResolverTypeWrapper<UserCategory>;
  UserClassification: ResolverTypeWrapper<UserClassification>;
  UserClassificationFilters: ResolverTypeWrapper<UserClassificationFilters>;
  UserClassificationFiltersInput: UserClassificationFiltersInput;
  UserClassificationFiltersOptions: ResolverTypeWrapper<UserClassificationFiltersOptions>;
  UserClassificationInput: UserClassificationInput;
  UserClassificationType: UserClassificationType;
  UserCommunicationsInput: UserCommunicationsInput;
  UserCustomReport: ResolverTypeWrapper<UserCustomReport>;
  UserInsightsInboxSettings: ResolverTypeWrapper<UserInsightsInboxSettings>;
  UserLifecycleOptions: ResolverTypeWrapper<UserLifecycleOptions>;
  UserManagementType: UserManagementType;
  UserOnboarding: ResolverTypeWrapper<UserOnboarding>;
  UserOnboardingInput: UserOnboardingInput;
  UserOnboardingStatus: UserOnboardingStatus;
  UserPersona: ResolverTypeWrapper<UserPersona>;
  UserPersonaInput: UserPersonaInput;
  UserProfileType: UserProfileType;
  UserProvisioningStatus: UserProvisioningStatus;
  UserSettings: ResolverTypeWrapper<UserSettings>;
  UserSettingsInput: UserSettingsInput;
  UserSiteRole: UserSiteRole;
  UserStatus: UserStatus;
  UserTenantKeyword: ResolverTypeWrapper<UserTenantKeyword>;
  UserTenantKeywordInput: UserTenantKeywordInput;
  UserTenantRegularSettings: ResolverTypeWrapper<
    Omit<UserTenantRegularSettings, 'user_alert_rule_builder_settings'> & {
      user_alert_rule_builder_settings?: Maybe<
        Array<ResolversTypes['UserAlertSettingData']>
      >;
    }
  >;
  UserTenantRegularSettingsDefaultProfileInput: UserTenantRegularSettingsDefaultProfileInput;
  UserTenantRegularSettingsInput: UserTenantRegularSettingsInput;
  UserTenantSettings: ResolverTypeWrapper<UserTenantSettings>;
  UserTenantUniversalFilters: ResolverTypeWrapper<UserTenantUniversalFilters>;
  UserTenantUniversalFiltersInput: UserTenantUniversalFiltersInput;
  UserTenantWorkRole: ResolverTypeWrapper<UserTenantWorkRole>;
  UserTenantWorkRoleInput: UserTenantWorkRoleInput;
  UserUniversalFilter: ResolverTypeWrapper<UserUniversalFilter>;
  UserUniversalFilterInput: UserUniversalFilterInput;
  UserUniversalFilterWithMeta: ResolverTypeWrapper<UserUniversalFilterWithMeta>;
  UserUniversalFilterWithMetaInput: UserUniversalFilterWithMetaInput;
  UserUniversalFilters: ResolverTypeWrapper<UserUniversalFilters>;
  UserUniversalFiltersInput: UserUniversalFiltersInput;
  UserWorkRole: ResolverTypeWrapper<UserWorkRole>;
  UserWorkRoleInput: UserWorkRoleInput;
  ViewSchema: ResolverTypeWrapper<ViewSchema>;
  WhereAddInsightsAlertUserCommentInput: WhereAddInsightsAlertUserCommentInput;
  WhereCategoryInput: WhereCategoryInput;
  WhereCreateCovid19WorkOrderDemoInsightsAlertInput: WhereCreateCovid19WorkOrderDemoInsightsAlertInput;
  WhereCreateDemoInsightsAlertInput: WhereCreateDemoInsightsAlertInput;
  WhereCreateFacilitiesExperienceDemoInsightsAlertInput: WhereCreateFacilitiesExperienceDemoInsightsAlertInput;
  WhereCreateLeaseEventDemoInsightsAlertInput: WhereCreateLeaseEventDemoInsightsAlertInput;
  WhereCreateMarketBenchmarkDemoInsightsAlertInput: WhereCreateMarketBenchmarkDemoInsightsAlertInput;
  WhereCreateOccupancyEfficiencyInsightsAlertInput: WhereCreateOccupancyEfficiencyInsightsAlertInput;
  WhereCreateSameDayWorkOrderCompletionInsightsAlertInput: WhereCreateSameDayWorkOrderCompletionInsightsAlertInput;
  WhereCreateSubleaseRiskInsightsAlertInput: WhereCreateSubleaseRiskInsightsAlertInput;
  WhereCreateWorkOrderVolumeDemoInsightsAlertInput: WhereCreateWorkOrderVolumeDemoInsightsAlertInput;
  WhereDataLoadsFileUploadSasUrl: WhereDataLoadsFileUploadSasUrl;
  WhereDataLoadsResetContainersInput: WhereDataLoadsResetContainersInput;
  WhereDeactivateUserFromTenantInput: WhereDeactivateUserFromTenantInput;
  WhereDeleteReportInput: WhereDeleteReportInput;
  WhereDeleteTenantUserInput: WhereDeleteTenantUserInput;
  WhereDownloadTemplateSasUrlInput: WhereDownloadTemplateSasUrlInput;
  WhereGenerateTrustedExternalReportUrlInput: WhereGenerateTrustedExternalReportUrlInput;
  WhereGenerateTrustedReportUrlInput: WhereGenerateTrustedReportUrlInput;
  WhereGeoInput: WhereGeoInput;
  WhereGetInsightsAlertHistoryInput: WhereGetInsightsAlertHistoryInput;
  WhereGetInsightsAlertRoiDataInput: WhereGetInsightsAlertRoiDataInput;
  WhereGetInsightsAlertSummaryInput: WhereGetInsightsAlertSummaryInput;
  WhereGetInsightsAlertThreadHistoryInput: WhereGetInsightsAlertThreadHistoryInput;
  WhereGetInsightsAlertThreadInput: WhereGetInsightsAlertThreadInput;
  WhereGetInsightsAlertThreadUserHistoryInput: WhereGetInsightsAlertThreadUserHistoryInput;
  WhereGetInsightsAlertUserHistoryInput: WhereGetInsightsAlertUserHistoryInput;
  WhereGetInsightsAlertsInput: WhereGetInsightsAlertsInput;
  WhereGetUserInput: WhereGetUserInput;
  WhereKeywordInput: WhereKeywordInput;
  WhereMoveReportInput: WhereMoveReportInput;
  WhereReactivateUserToTenantInput: WhereReactivateUserToTenantInput;
  WhereReleaseInput: WhereReleaseInput;
  WhereRemoveInsightsAlertAssignedUserInput: WhereRemoveInsightsAlertAssignedUserInput;
  WhereReportMappingInput: WhereReportMappingInput;
  WhereSetInsightsAlertAssignedUserInput: WhereSetInsightsAlertAssignedUserInput;
  WhereSetInsightsAlertRoiDataInput: WhereSetInsightsAlertRoiDataInput;
  WhereSetInsightsAlertSeverityInput: WhereSetInsightsAlertSeverityInput;
  WhereSetInsightsAlertStatusInput: WhereSetInsightsAlertStatusInput;
  WhereSetInsightsAlertUserImportanceInput: WhereSetInsightsAlertUserImportanceInput;
  WhereSetInsightsAlertUserReadInput: WhereSetInsightsAlertUserReadInput;
  WhereTenantOvcIdInput: WhereTenantOvcIdInput;
  WhereTenantRoleInput: WhereTenantRoleInput;
  WhereThoughtSpotTrustedTicketInput: WhereThoughtSpotTrustedTicketInput;
  WhereUpdateApplicationInput: WhereUpdateApplicationInput;
  WhereUpdateReportInput: WhereUpdateReportInput;
  WhereUpdateUserInput: WhereUpdateUserInput;
  WhereUpdateWorkAreaInput: WhereUpdateWorkAreaInput;
  WhereUserTenantInput: WhereUserTenantInput;
  WhereValidateExternalReportEmbedUrlInput: WhereValidateExternalReportEmbedUrlInput;
  WorkArea: ResolverTypeWrapper<WorkArea>;
  WorkOrderVolumeAlertCategory: ResolverTypeWrapper<WorkOrderVolumeAlertCategory>;
  WorkOrderVolumeAlertSettingData: ResolverTypeWrapper<WorkOrderVolumeAlertSettingData>;
  WorkOrderVolumeAlertSettings: ResolverTypeWrapper<WorkOrderVolumeAlertSettings>;
  WorkOrderVolumeAlertSettingsInput: WorkOrderVolumeAlertSettingsInput;
  WorkOrderVolumeInsightsAlertData: WorkOrderVolumeInsightsAlertData;
  WorkRole: ResolverTypeWrapper<WorkRole>;
  WorkRoleInput: WorkRoleInput;
  WorkRoleUpdateInput: WorkRoleUpdateInput;
  WorkSpecialization: ResolverTypeWrapper<WorkSpecialization>;
  WorkSpecializationInput: WorkSpecializationInput;
  WorkSpecializationUpdateInput: WorkSpecializationUpdateInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AdGroup: AdGroup;
  String: Scalars['String'];
  AddDemoTenantInput: AddDemoTenantInput;
  AddTenantDataShareForCustomReports: AddTenantDataShareForCustomReports;
  Address: Address;
  AlertTemplateData:
    | ResolversParentTypes['InsightsAlertBaseWorkOrderTemplateData']
    | ResolversParentTypes['InsightsAlertLeaseEventTemplateData']
    | ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateData']
    | ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateData']
    | ResolversParentTypes['InsightsAlertSameDayWorkOrderCompletionTemplateData']
    | ResolversParentTypes['InsightsAlertSubleaseRiskTemplateData']
    | ResolversParentTypes['InsightsAlertWorkOrderVolumeTemplateData'];
  ApiVersion: ApiVersion;
  Application: Application;
  ID: Scalars['ID'];
  Boolean: Scalars['Boolean'];
  ApplicationSchema: ApplicationSchema;
  ApplicationSubcode: ApplicationSubcode;
  ApplicationSubcodeInput: ApplicationSubcodeInput;
  BaselineReportControl: BaselineReportControl;
  Int: Scalars['Int'];
  BaselineReportInteractionConfiguration: BaselineReportInteractionConfiguration;
  BaselineReportInteractionSection: BaselineReportInteractionSection;
  BlobStorageUploadSasUrlInput: BlobStorageUploadSasUrlInput;
  Category: Category;
  ColumnSchema: ColumnSchema;
  Communications: Communications;
  Continent: Continent;
  Country: Country;
  Covid19WorkOrderInsightsAlertData: Covid19WorkOrderInsightsAlertData;
  CovidWorkOrderAlertCategory: CovidWorkOrderAlertCategory;
  CovidWorkOrderAlertSettingData: CovidWorkOrderAlertSettingData;
  CovidWorkOrderAlertSettings: CovidWorkOrderAlertSettings;
  CovidWorkOrderAlertSettingsInput: CovidWorkOrderAlertSettingsInput;
  CreateAddressInput: CreateAddressInput;
  CreateApplicationInput: CreateApplicationInput;
  CreateCategoryInput: CreateCategoryInput;
  CreateCountryInput: CreateCountryInput;
  CreateRegionInput: CreateRegionInput;
  CreateReportInput: CreateReportInput;
  CreateReportSavedViewInput: CreateReportSavedViewInput;
  CreateTenantUserInput: CreateTenantUserInput;
  CreateUserPersonaInput: CreateUserPersonaInput;
  CreateWorkAreaInput: CreateWorkAreaInput;
  DataLoadsContainers: DataLoadsContainers;
  DataLoadsContainersInput: DataLoadsContainersInput;
  DataLoadsErrorInfo: DataLoadsErrorInfo;
  DataLoadsFileUploadSasUrl: DataLoadsFileUploadSasUrl;
  DataLoadsJob: DataLoadsJob;
  DataLoadsJobLinks: DataLoadsJobLinks;
  DataLoadsJobs: DataLoadsJobs;
  DataLoadsJobsFiltersInput: DataLoadsJobsFiltersInput;
  DataLoadsRecordInfo: DataLoadsRecordInfo;
  DataLoadsResetContainers: DataLoadsResetContainers;
  DataThreshold: DataThreshold;
  Float: Scalars['Float'];
  DataThresholdInput: DataThresholdInput;
  DeleteTenantDataShareForCustomReports: DeleteTenantDataShareForCustomReports;
  ErrorLogInput: ErrorLogInput;
  FacilitiesExperienceAlertCategory: FacilitiesExperienceAlertCategory;
  FacilitiesExperienceAlertSettingData: FacilitiesExperienceAlertSettingData;
  FacilitiesExperienceAlertSettings: FacilitiesExperienceAlertSettings;
  FacilitiesExperienceAlertSettingsInput: FacilitiesExperienceAlertSettingsInput;
  FacilitiesExperienceInsightsAlertData: FacilitiesExperienceInsightsAlertData;
  FlexibleMappingOptionSampleValues: FlexibleMappingOptionSampleValues;
  GlobalData: GlobalData;
  GlobalView: GlobalView;
  GroupMapping: GroupMapping;
  HiddenReportInput: HiddenReportInput;
  InsightsAlert: Omit<InsightsAlert, 'template_data'> & {
    template_data: ResolversParentTypes['InsightsAlertTemplateData'];
  };
  InsightsAlertBaseWorkOrderTemplateData: InsightsAlertBaseWorkOrderTemplateData;
  InsightsAlertData: InsightsAlertData;
  InsightsAlertHistory: InsightsAlertHistory;
  InsightsAlertHistoryRecord: InsightsAlertHistoryRecord;
  InsightsAlertLeaseEventTemplateData: InsightsAlertLeaseEventTemplateData;
  InsightsAlertMarketBenchmarkTemplateData: InsightsAlertMarketBenchmarkTemplateData;
  InsightsAlertMarketBenchmarkTemplateDataPredictionRecord: InsightsAlertMarketBenchmarkTemplateDataPredictionRecord;
  InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint: InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint;
  InsightsAlertOccupancyEfficiencyTemplateData: InsightsAlertOccupancyEfficiencyTemplateData;
  InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord: InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord;
  InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord: InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord;
  InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord: InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord;
  InsightsAlertRoiData: InsightsAlertRoiData;
  InsightsAlertSameDayWorkOrderCompletionTemplateData: InsightsAlertSameDayWorkOrderCompletionTemplateData;
  InsightsAlertSubleaseRiskTemplateData: InsightsAlertSubleaseRiskTemplateData;
  InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord: InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord;
  InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord: InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord;
  InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord: InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord;
  InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord: InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord;
  InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord: InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord;
  InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord: InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord;
  InsightsAlertSummary: InsightsAlertSummary;
  InsightsAlertSummaryCountsByStatus: InsightsAlertSummaryCountsByStatus;
  InsightsAlertTemplateData:
    | ResolversParentTypes['InsightsAlertBaseWorkOrderTemplateData']
    | ResolversParentTypes['InsightsAlertLeaseEventTemplateData']
    | ResolversParentTypes['InsightsAlertWorkOrderVolumeTemplateData']
    | ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateData']
    | ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateData']
    | ResolversParentTypes['InsightsAlertSubleaseRiskTemplateData']
    | ResolversParentTypes['InsightsAlertSameDayWorkOrderCompletionTemplateData'];
  InsightsAlertThread: InsightsAlertThread;
  InsightsAlertUserHistory: InsightsAlertUserHistory;
  InsightsAlertUserHistoryRecord: InsightsAlertUserHistoryRecord;
  InsightsAlertUserInfo: InsightsAlertUserInfo;
  InsightsAlertWorkOrderVolumeTemplateData: InsightsAlertWorkOrderVolumeTemplateData;
  InsightsAlerts: InsightsAlerts;
  InsightsInboxAlerts: InsightsInboxAlerts;
  InsightsInboxSelectedSortOrder: InsightsInboxSelectedSortOrder;
  InsightsSelectedFilters: InsightsSelectedFilters;
  Keyword: Keyword;
  KeywordInput: KeywordInput;
  KpiData: KpiData;
  LeaseEventAlertCategory: LeaseEventAlertCategory;
  LeaseEventAlertSettingData: LeaseEventAlertSettingData;
  LeaseEventAlertSettings: LeaseEventAlertSettings;
  LeaseEventAlertSettingsInput: LeaseEventAlertSettingsInput;
  LeaseEventInsightsAlertData: LeaseEventInsightsAlertData;
  Log: Log;
  Mapped: Mapped;
  MarketBenchmarkAlertCategory: MarketBenchmarkAlertCategory;
  MarketBenchmarkAlertSettingData: MarketBenchmarkAlertSettingData;
  MarketBenchmarkAlertSettings: MarketBenchmarkAlertSettings;
  MarketBenchmarkAlertSettingsInput: MarketBenchmarkAlertSettingsInput;
  MarketBenchmarkInsightsAlertData: MarketBenchmarkInsightsAlertData;
  MarketBenchmarkInsightsAlertDataPredictionRecord: MarketBenchmarkInsightsAlertDataPredictionRecord;
  MarketBenchmarkInsightsAlertDataSupportingDataPoint: MarketBenchmarkInsightsAlertDataSupportingDataPoint;
  MoveCustomReport: MoveCustomReport;
  Mutation: {};
  OccupancyEfficiencyAlertCategory: OccupancyEfficiencyAlertCategory;
  OccupancyEfficiencyAlertSettingData: OccupancyEfficiencyAlertSettingData;
  OccupancyEfficiencyAlertSettings: OccupancyEfficiencyAlertSettings;
  OccupancyEfficiencyAlertSettingsInput: OccupancyEfficiencyAlertSettingsInput;
  OccupancyEfficiencyInsightsAlertData: OccupancyEfficiencyInsightsAlertData;
  OccupancyEfficiencyTemplateDataHeadcountDensityTriggerInfoRecord: OccupancyEfficiencyTemplateDataHeadcountDensityTriggerInfoRecord;
  OccupancyEfficiencyTemplateDataOccupancyRateTriggerInfoRecord: OccupancyEfficiencyTemplateDataOccupancyRateTriggerInfoRecord;
  OccupancyEfficiencyTemplateDataVacancyRateTriggerInfoRecord: OccupancyEfficiencyTemplateDataVacancyRateTriggerInfoRecord;
  PostMappingDataStandardInput: PostMappingDataStandardInput;
  PostMappingDataStandardResponse: PostMappingDataStandardResponse;
  ProductSettingsReportInput: ProductSettingsReportInput;
  PromoteReleaseInput: PromoteReleaseInput;
  PropertyData: PropertyData;
  PropertyDataInput: PropertyDataInput;
  PropertyDataRecord: PropertyDataRecord;
  Query: {};
  Region: Region;
  ReleasesRecord: ReleasesRecord;
  Report: Report;
  ReportConfig: ReportConfig;
  ReportControl: ReportControl;
  ReportControlStaticListItem: ReportControlStaticListItem;
  ReportInteractionConfiguration: ReportInteractionConfiguration;
  ReportInteractionSection: ReportInteractionSection;
  ReportMapping: ReportMapping;
  ReportMetadata: ReportMetadata;
  ReportMetadataMapping: ReportMetadataMapping;
  ReportMetadataSourceData: ReportMetadataSourceData;
  ReportModule: ReportModule;
  ReportModuleCategory: ReportModuleCategory;
  ReportPerformanceContextInput: ReportPerformanceContextInput;
  ReportPerformanceLogInput: ReportPerformanceLogInput;
  ReportWhereInput: ReportWhereInput;
  Role: Role;
  RoleDashboard: RoleDashboard;
  RoleDashboardInput: RoleDashboardInput;
  RoleDashboards: RoleDashboards;
  RoleDashboardsInput: RoleDashboardsInput;
  RoleInput: RoleInput;
  RoleInsightsAlert: RoleInsightsAlert;
  RoleInsightsAlertInput: RoleInsightsAlertInput;
  RoleInsightsAlerts: RoleInsightsAlerts;
  RoleInsightsAlertsInput: RoleInsightsAlertsInput;
  RoleOtherFeature: RoleOtherFeature;
  RoleOtherFeatureInput: RoleOtherFeatureInput;
  RoleOtherSubFeature: RoleOtherSubFeature;
  RoleOtherSubFeatureInput: RoleOtherSubFeatureInput;
  RoleUniversalFilters: RoleUniversalFilters;
  RoleUniversalFiltersInput: RoleUniversalFiltersInput;
  SameDayWorkOrderCompletionAlertCategory: SameDayWorkOrderCompletionAlertCategory;
  SameDayWorkOrderCompletionAlertSettingData: SameDayWorkOrderCompletionAlertSettingData;
  SameDayWorkOrderCompletionAlertSettings: SameDayWorkOrderCompletionAlertSettings;
  SameDayWorkOrderCompletionAlertSettingsInput: SameDayWorkOrderCompletionAlertSettingsInput;
  SameDayWorkOrderCompletionInsightsAlertData: SameDayWorkOrderCompletionInsightsAlertData;
  SearchedUserResult: SearchedUserResult;
  SegmentDataInput: SegmentDataInput;
  SegmentOptions: SegmentOptions;
  SubleaseRiskAlertCategory: SubleaseRiskAlertCategory;
  SubleaseRiskAlertSettingData: SubleaseRiskAlertSettingData;
  SubleaseRiskAlertSettings: SubleaseRiskAlertSettings;
  SubleaseRiskAlertSettingsInput: SubleaseRiskAlertSettingsInput;
  SubleaseRiskInsightsAlertData: SubleaseRiskInsightsAlertData;
  SubleaseRiskTemplateDataContractionOptionsRecord: SubleaseRiskTemplateDataContractionOptionsRecord;
  SubleaseRiskTemplateDataMainlyParkingLeasesRecord: SubleaseRiskTemplateDataMainlyParkingLeasesRecord;
  SubleaseRiskTemplateDataMonthToMonthTenuresRecord: SubleaseRiskTemplateDataMonthToMonthTenuresRecord;
  SubleaseRiskTemplateDataNaturalExpiryRecord: SubleaseRiskTemplateDataNaturalExpiryRecord;
  SubleaseRiskTemplateDataSubleaseWithExpiryRecord: SubleaseRiskTemplateDataSubleaseWithExpiryRecord;
  SubleaseRiskTemplateDataTerminationOptionsRecord: SubleaseRiskTemplateDataTerminationOptionsRecord;
  Tenant: Omit<
    Tenant,
    'tenant_alert_settings' | 'tenant_alert_rule_builder'
  > & {
    tenant_alert_settings?: Maybe<
      Array<ResolversParentTypes['TenantAlertSettings']>
    >;
    tenant_alert_rule_builder: ResolversParentTypes['TenantAlertSettingData'];
  };
  TenantAlertCategoriesInput: TenantAlertCategoriesInput;
  TenantAlertSettingData:
    | ResolversParentTypes['TenantWorkOrderVolumeAlertSettingData']
    | ResolversParentTypes['TenantFacilitiesExperienceAlertSettingData']
    | ResolversParentTypes['TenantLeaseEventAlertSettingData']
    | ResolversParentTypes['TenantCovidWorkOrderAlertSettingData']
    | ResolversParentTypes['TenantMarketBenchmarkAlertSettingData']
    | ResolversParentTypes['TenantOccupancyEfficiencyAlertSettingData']
    | ResolversParentTypes['TenantSubleaseRiskAlertSettingData']
    | ResolversParentTypes['TenantSameDayWorkOrderCompletionAlertSettingData'];
  TenantAlertSettings:
    | ResolversParentTypes['WorkOrderVolumeAlertSettings']
    | ResolversParentTypes['FacilitiesExperienceAlertSettings']
    | ResolversParentTypes['LeaseEventAlertSettings']
    | ResolversParentTypes['CovidWorkOrderAlertSettings']
    | ResolversParentTypes['MarketBenchmarkAlertSettings']
    | ResolversParentTypes['OccupancyEfficiencyAlertSettings']
    | ResolversParentTypes['SubleaseRiskAlertSettings']
    | ResolversParentTypes['SameDayWorkOrderCompletionAlertSettings'];
  TenantAlertSettingsInput: TenantAlertSettingsInput;
  TenantApplication: TenantApplication;
  TenantApplicationTenantIdInput: TenantApplicationTenantIdInput;
  TenantConfigurationData: Omit<
    TenantConfigurationData,
    'values' | 'options' | 'sample_values'
  > & {
    values?: Maybe<ResolversParentTypes['TenantConfigurationDataValues']>;
    options?: Maybe<ResolversParentTypes['TenantConfigurationDataOptions']>;
    sample_values?: Maybe<
      ResolversParentTypes['TenantConfigurationDataSampleValues']
    >;
  };
  TenantConfigurationDataInput: TenantConfigurationDataInput;
  TenantConfigurationDataOptions:
    | ResolversParentTypes['TenantFinanceDataOptions']
    | ResolversParentTypes['TenantTransactionDataOptions']
    | ResolversParentTypes['TenantLeaseDataOptions']
    | ResolversParentTypes['TenantSpaceDataOptions'];
  TenantConfigurationDataSampleValues:
    | ResolversParentTypes['TenantSpaceDataSampleValues']
    | ResolversParentTypes['TenantFinanceDataSampleValues']
    | ResolversParentTypes['TenantLeaseDataSampleValues'];
  TenantConfigurationDataValues:
    | ResolversParentTypes['TenantDefaultsDataValues']
    | ResolversParentTypes['TenantWorkOrdersDataValues']
    | ResolversParentTypes['TenantFinanceDataValues']
    | ResolversParentTypes['TenantTransactionDataValues']
    | ResolversParentTypes['TenantLeaseDataValues']
    | ResolversParentTypes['TenantSpaceDataValues'];
  TenantCovidWorkOrderAlertSettingData: TenantCovidWorkOrderAlertSettingData;
  TenantCreateInput: TenantCreateInput;
  TenantCustomReportsSettings: TenantCustomReportsSettings;
  TenantCustomReportsSetup: TenantCustomReportsSetup;
  TenantDataStandard: TenantDataStandard;
  TenantDefaultsDataInput: TenantDefaultsDataInput;
  TenantDefaultsDataValues: TenantDefaultsDataValues;
  TenantFacilitiesExperienceAlertSettingData: TenantFacilitiesExperienceAlertSettingData;
  TenantFeatures: TenantFeatures;
  TenantFeaturesInput: TenantFeaturesInput;
  TenantFinanceDataInput: TenantFinanceDataInput;
  TenantFinanceDataOptions: TenantFinanceDataOptions;
  TenantFinanceDataSampleValues: TenantFinanceDataSampleValues;
  TenantFinanceDataValues: TenantFinanceDataValues;
  TenantGeoUniversalFilters: TenantGeoUniversalFilters;
  TenantKpiData: TenantKpiData;
  TenantKpiSettings: TenantKpiSettings;
  TenantKpiSettingsInput: TenantKpiSettingsInput;
  TenantLeaseDataInput: TenantLeaseDataInput;
  TenantLeaseDataOptions: TenantLeaseDataOptions;
  TenantLeaseDataSampleValues: TenantLeaseDataSampleValues;
  TenantLeaseDataValues: TenantLeaseDataValues;
  TenantLeaseEventAlertSettingData: TenantLeaseEventAlertSettingData;
  TenantMarketBenchmarkAlertSettingData: TenantMarketBenchmarkAlertSettingData;
  TenantOccupancyEfficiencyAlertSettingData: TenantOccupancyEfficiencyAlertSettingData;
  TenantReportConfigurationInput: TenantReportConfigurationInput;
  TenantReportInput: TenantReportInput;
  TenantReportsConfiguration: TenantReportsConfiguration;
  TenantReportsConfigurationInput: TenantReportsConfigurationInput;
  TenantReportsFiltersInput: TenantReportsFiltersInput;
  TenantRole: TenantRole;
  TenantSameDayWorkOrderCompletionAlertSettingData: TenantSameDayWorkOrderCompletionAlertSettingData;
  TenantSelfServeAnalyticsSettings: TenantSelfServeAnalyticsSettings;
  TenantSnowflakeUrl: TenantSnowflakeUrl;
  TenantSpaceDataInput: TenantSpaceDataInput;
  TenantSpaceDataOptions: TenantSpaceDataOptions;
  TenantSpaceDataSampleValues: TenantSpaceDataSampleValues;
  TenantSpaceDataValues: TenantSpaceDataValues;
  TenantSubleaseRiskAlertSettingData: TenantSubleaseRiskAlertSettingData;
  TenantTransactionDataInput: TenantTransactionDataInput;
  TenantTransactionDataOptions: TenantTransactionDataOptions;
  TenantTransactionDataValues: TenantTransactionDataValues;
  TenantUniversalFilterGeoValue: TenantUniversalFilterGeoValue;
  TenantUniversalFilterNodeInput: TenantUniversalFilterNodeInput;
  TenantUpdateInput: TenantUpdateInput;
  TenantWhereInput: TenantWhereInput;
  TenantWorkOrderVolumeAlertSettingData: TenantWorkOrderVolumeAlertSettingData;
  TenantWorkOrdersDataInput: TenantWorkOrdersDataInput;
  TenantWorkOrdersDataValues: TenantWorkOrdersDataValues;
  TenantsWhereUpdateInput: TenantsWhereUpdateInput;
  TrustedReportUrl: TrustedReportUrl;
  Unmapped: Unmapped;
  UnmappedRecordKey: UnmappedRecordKey;
  UpdateAddressInput: UpdateAddressInput;
  UpdateApplicationInput: UpdateApplicationInput;
  UpdateCategoryInput: UpdateCategoryInput;
  UpdateCountryInput: UpdateCountryInput;
  UpdateRegionInput: UpdateRegionInput;
  UpdateReleaseInput: UpdateReleaseInput;
  UpdateReportInput: UpdateReportInput;
  UpdateReportsInput: UpdateReportsInput;
  UpdateTenantDataShareForCustomReports: UpdateTenantDataShareForCustomReports;
  UpdateTenantReportsInput: UpdateTenantReportsInput;
  UpdateTenantUserInput: UpdateTenantUserInput;
  UpdateUserExtendedInput: UpdateUserExtendedInput;
  UpdateUserInput: UpdateUserInput;
  UpdateWorkAreaInput: UpdateWorkAreaInput;
  UpsertKeywordDataInput: UpsertKeywordDataInput;
  UpsertReportMappingDataInput: UpsertReportMappingDataInput;
  UpsertTenantSelfServeAnalyticsSettings: UpsertTenantSelfServeAnalyticsSettings;
  User: User;
  UserAddressInput: UserAddressInput;
  UserAdminSettings: UserAdminSettings;
  UserAgreement: UserAgreement;
  UserAgreementInput: UserAgreementInput;
  UserAlertSettingData:
    | ResolversParentTypes['WorkOrderVolumeAlertSettingData']
    | ResolversParentTypes['FacilitiesExperienceAlertSettingData']
    | ResolversParentTypes['LeaseEventAlertSettingData']
    | ResolversParentTypes['CovidWorkOrderAlertSettingData']
    | ResolversParentTypes['MarketBenchmarkAlertSettingData']
    | ResolversParentTypes['OccupancyEfficiencyAlertSettingData']
    | ResolversParentTypes['SubleaseRiskAlertSettingData']
    | ResolversParentTypes['SameDayWorkOrderCompletionAlertSettingData'];
  UserAlertSettingDataInput: UserAlertSettingDataInput;
  UserAlerts: UserAlerts;
  UserAlertsInput: UserAlertsInput;
  UserCatReportInput: UserCatReportInput;
  UserCategory: UserCategory;
  UserClassification: UserClassification;
  UserClassificationFilters: UserClassificationFilters;
  UserClassificationFiltersInput: UserClassificationFiltersInput;
  UserClassificationFiltersOptions: UserClassificationFiltersOptions;
  UserClassificationInput: UserClassificationInput;
  UserCommunicationsInput: UserCommunicationsInput;
  UserCustomReport: UserCustomReport;
  UserInsightsInboxSettings: UserInsightsInboxSettings;
  UserLifecycleOptions: UserLifecycleOptions;
  UserOnboarding: UserOnboarding;
  UserOnboardingInput: UserOnboardingInput;
  UserPersona: UserPersona;
  UserPersonaInput: UserPersonaInput;
  UserSettings: UserSettings;
  UserSettingsInput: UserSettingsInput;
  UserTenantKeyword: UserTenantKeyword;
  UserTenantKeywordInput: UserTenantKeywordInput;
  UserTenantRegularSettings: Omit<
    UserTenantRegularSettings,
    'user_alert_rule_builder_settings'
  > & {
    user_alert_rule_builder_settings?: Maybe<
      Array<ResolversParentTypes['UserAlertSettingData']>
    >;
  };
  UserTenantRegularSettingsDefaultProfileInput: UserTenantRegularSettingsDefaultProfileInput;
  UserTenantRegularSettingsInput: UserTenantRegularSettingsInput;
  UserTenantSettings: UserTenantSettings;
  UserTenantUniversalFilters: UserTenantUniversalFilters;
  UserTenantUniversalFiltersInput: UserTenantUniversalFiltersInput;
  UserTenantWorkRole: UserTenantWorkRole;
  UserTenantWorkRoleInput: UserTenantWorkRoleInput;
  UserUniversalFilter: UserUniversalFilter;
  UserUniversalFilterInput: UserUniversalFilterInput;
  UserUniversalFilterWithMeta: UserUniversalFilterWithMeta;
  UserUniversalFilterWithMetaInput: UserUniversalFilterWithMetaInput;
  UserUniversalFilters: UserUniversalFilters;
  UserUniversalFiltersInput: UserUniversalFiltersInput;
  UserWorkRole: UserWorkRole;
  UserWorkRoleInput: UserWorkRoleInput;
  ViewSchema: ViewSchema;
  WhereAddInsightsAlertUserCommentInput: WhereAddInsightsAlertUserCommentInput;
  WhereCategoryInput: WhereCategoryInput;
  WhereCreateCovid19WorkOrderDemoInsightsAlertInput: WhereCreateCovid19WorkOrderDemoInsightsAlertInput;
  WhereCreateDemoInsightsAlertInput: WhereCreateDemoInsightsAlertInput;
  WhereCreateFacilitiesExperienceDemoInsightsAlertInput: WhereCreateFacilitiesExperienceDemoInsightsAlertInput;
  WhereCreateLeaseEventDemoInsightsAlertInput: WhereCreateLeaseEventDemoInsightsAlertInput;
  WhereCreateMarketBenchmarkDemoInsightsAlertInput: WhereCreateMarketBenchmarkDemoInsightsAlertInput;
  WhereCreateOccupancyEfficiencyInsightsAlertInput: WhereCreateOccupancyEfficiencyInsightsAlertInput;
  WhereCreateSameDayWorkOrderCompletionInsightsAlertInput: WhereCreateSameDayWorkOrderCompletionInsightsAlertInput;
  WhereCreateSubleaseRiskInsightsAlertInput: WhereCreateSubleaseRiskInsightsAlertInput;
  WhereCreateWorkOrderVolumeDemoInsightsAlertInput: WhereCreateWorkOrderVolumeDemoInsightsAlertInput;
  WhereDataLoadsFileUploadSasUrl: WhereDataLoadsFileUploadSasUrl;
  WhereDataLoadsResetContainersInput: WhereDataLoadsResetContainersInput;
  WhereDeactivateUserFromTenantInput: WhereDeactivateUserFromTenantInput;
  WhereDeleteReportInput: WhereDeleteReportInput;
  WhereDeleteTenantUserInput: WhereDeleteTenantUserInput;
  WhereDownloadTemplateSasUrlInput: WhereDownloadTemplateSasUrlInput;
  WhereGenerateTrustedExternalReportUrlInput: WhereGenerateTrustedExternalReportUrlInput;
  WhereGenerateTrustedReportUrlInput: WhereGenerateTrustedReportUrlInput;
  WhereGeoInput: WhereGeoInput;
  WhereGetInsightsAlertHistoryInput: WhereGetInsightsAlertHistoryInput;
  WhereGetInsightsAlertRoiDataInput: WhereGetInsightsAlertRoiDataInput;
  WhereGetInsightsAlertSummaryInput: WhereGetInsightsAlertSummaryInput;
  WhereGetInsightsAlertThreadHistoryInput: WhereGetInsightsAlertThreadHistoryInput;
  WhereGetInsightsAlertThreadInput: WhereGetInsightsAlertThreadInput;
  WhereGetInsightsAlertThreadUserHistoryInput: WhereGetInsightsAlertThreadUserHistoryInput;
  WhereGetInsightsAlertUserHistoryInput: WhereGetInsightsAlertUserHistoryInput;
  WhereGetInsightsAlertsInput: WhereGetInsightsAlertsInput;
  WhereGetUserInput: WhereGetUserInput;
  WhereKeywordInput: WhereKeywordInput;
  WhereMoveReportInput: WhereMoveReportInput;
  WhereReactivateUserToTenantInput: WhereReactivateUserToTenantInput;
  WhereReleaseInput: WhereReleaseInput;
  WhereRemoveInsightsAlertAssignedUserInput: WhereRemoveInsightsAlertAssignedUserInput;
  WhereReportMappingInput: WhereReportMappingInput;
  WhereSetInsightsAlertAssignedUserInput: WhereSetInsightsAlertAssignedUserInput;
  WhereSetInsightsAlertRoiDataInput: WhereSetInsightsAlertRoiDataInput;
  WhereSetInsightsAlertSeverityInput: WhereSetInsightsAlertSeverityInput;
  WhereSetInsightsAlertStatusInput: WhereSetInsightsAlertStatusInput;
  WhereSetInsightsAlertUserImportanceInput: WhereSetInsightsAlertUserImportanceInput;
  WhereSetInsightsAlertUserReadInput: WhereSetInsightsAlertUserReadInput;
  WhereTenantOvcIdInput: WhereTenantOvcIdInput;
  WhereTenantRoleInput: WhereTenantRoleInput;
  WhereThoughtSpotTrustedTicketInput: WhereThoughtSpotTrustedTicketInput;
  WhereUpdateApplicationInput: WhereUpdateApplicationInput;
  WhereUpdateReportInput: WhereUpdateReportInput;
  WhereUpdateUserInput: WhereUpdateUserInput;
  WhereUpdateWorkAreaInput: WhereUpdateWorkAreaInput;
  WhereUserTenantInput: WhereUserTenantInput;
  WhereValidateExternalReportEmbedUrlInput: WhereValidateExternalReportEmbedUrlInput;
  WorkArea: WorkArea;
  WorkOrderVolumeAlertCategory: WorkOrderVolumeAlertCategory;
  WorkOrderVolumeAlertSettingData: WorkOrderVolumeAlertSettingData;
  WorkOrderVolumeAlertSettings: WorkOrderVolumeAlertSettings;
  WorkOrderVolumeAlertSettingsInput: WorkOrderVolumeAlertSettingsInput;
  WorkOrderVolumeInsightsAlertData: WorkOrderVolumeInsightsAlertData;
  WorkRole: WorkRole;
  WorkRoleInput: WorkRoleInput;
  WorkRoleUpdateInput: WorkRoleUpdateInput;
  WorkSpecialization: WorkSpecialization;
  WorkSpecializationInput: WorkSpecializationInput;
  WorkSpecializationUpdateInput: WorkSpecializationUpdateInput;
};

export type ClientDirectiveArgs = {};

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = ClientSideResolverContext,
  Args = ClientDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ConnectionDirectiveArgs = {
  key: Scalars['String'];
  filter: Array<Maybe<Scalars['String']>>;
};

export type ConnectionDirectiveResolver<
  Result,
  Parent,
  ContextType = ClientSideResolverContext,
  Args = ConnectionDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AdGroupResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['AdGroup'] = ResolversParentTypes['AdGroup'],
> = {
  group_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address'],
> = {
  street1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['AlertTemplateData'] = ResolversParentTypes['AlertTemplateData'],
> = {
  __resolveType: TypeResolveFn<
    | 'InsightsAlertBaseWorkOrderTemplateData'
    | 'InsightsAlertLeaseEventTemplateData'
    | 'InsightsAlertMarketBenchmarkTemplateData'
    | 'InsightsAlertOccupancyEfficiencyTemplateData'
    | 'InsightsAlertSameDayWorkOrderCompletionTemplateData'
    | 'InsightsAlertSubleaseRiskTemplateData'
    | 'InsightsAlertWorkOrderVolumeTemplateData',
    ParentType,
    ContextType
  >;
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
};

export type ApiVersionResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ApiVersion'] = ResolversParentTypes['ApiVersion'],
> = {
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  build_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_qa_type?: Resolver<
    Maybe<ResolversTypes['TenantQaType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Application'] = ResolversParentTypes['Application'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  app_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  app_cat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  app_active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  app_default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  app_description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  app_schema?: Resolver<
    Maybe<ResolversTypes['ApplicationSchema']>,
    ParentType,
    ContextType,
    RequireFields<ApplicationApp_SchemaArgs, 'client_id'>
  >;
  app_data_upload?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  app_subcodes?: Resolver<
    Maybe<Array<ResolversTypes['ApplicationSubcode']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationSchemaResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ApplicationSchema'] = ResolversParentTypes['ApplicationSchema'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<
    Array<ResolversTypes['ViewSchema']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationSubcodeResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ApplicationSubcode'] = ResolversParentTypes['ApplicationSubcode'],
> = {
  app_subcode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  app_subcode_description?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaselineReportControlResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['BaselineReportControl'] = ResolversParentTypes['BaselineReportControl'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  filter_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  section_type?: Resolver<
    Maybe<ResolversTypes['ReportSectionType']>,
    ParentType,
    ContextType
  >;
  control_type?: Resolver<
    ResolversTypes['ReportControlType'],
    ParentType,
    ContextType
  >;
  static_data?: Resolver<
    Array<ResolversTypes['ReportControlStaticListItem']>,
    ParentType,
    ContextType
  >;
  cascade_update?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sort_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaselineReportInteractionConfigurationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['BaselineReportInteractionConfiguration'] = ResolversParentTypes['BaselineReportInteractionConfiguration'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sections?: Resolver<
    Array<ResolversTypes['BaselineReportInteractionSection']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaselineReportInteractionSectionResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['BaselineReportInteractionSection'] = ResolversParentTypes['BaselineReportInteractionSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expanded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  section_type?: Resolver<
    ResolversTypes['ReportSectionType'],
    ParentType,
    ContextType
  >;
  controls?: Resolver<
    Array<ResolversTypes['BaselineReportControl']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  category_description?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  category_reports?: Resolver<
    Array<Maybe<ResolversTypes['Report']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColumnSchemaResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ColumnSchema'] = ResolversParentTypes['ColumnSchema'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  null?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  default?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primary_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unique_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  check?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expression?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunicationsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Communications'] = ResolversParentTypes['Communications'],
> = {
  home?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  work?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  other?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContinentResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Continent'] = ResolversParentTypes['Continent'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  country_iso_code?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  country_desc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country_continent?: Resolver<
    Maybe<ResolversTypes['Continent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidWorkOrderAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['CovidWorkOrderAlertCategory'] = ResolversParentTypes['CovidWorkOrderAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidWorkOrderAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['CovidWorkOrderAlertSettingData'] = ResolversParentTypes['CovidWorkOrderAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['CovidWorkOrderAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidWorkOrderAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['CovidWorkOrderAlertSettings'] = ResolversParentTypes['CovidWorkOrderAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  time_to_expected_completion_date_medium?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  time_to_expected_completion_date_high?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  time_from_created_to_picked_up_date?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  time_from_picked_up_to_due_date?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsContainersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsContainers'] = ResolversParentTypes['DataLoadsContainers'],
> = {
  input?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  output?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsErrorInfoResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsErrorInfo'] = ResolversParentTypes['DataLoadsErrorInfo'],
> = {
  error_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsFileUploadSasUrlResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsFileUploadSasUrl'] = ResolversParentTypes['DataLoadsFileUploadSasUrl'],
> = {
  sas_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  folder_path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsJobResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsJob'] = ResolversParentTypes['DataLoadsJob'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType>;
  application?: Resolver<
    Maybe<ResolversTypes['Application']>,
    ParentType,
    ContextType
  >;
  application_sub_code?: Resolver<
    Maybe<ResolversTypes['ApplicationSubcode']>,
    ParentType,
    ContextType
  >;
  submitted_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  submitted_by_user?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['DataLoadsJobsStatus']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  processing_started_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  processing_ended_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  file_source_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  record_info?: Resolver<
    Maybe<ResolversTypes['DataLoadsRecordInfo']>,
    ParentType,
    ContextType
  >;
  error_info?: Resolver<
    Maybe<ResolversTypes['DataLoadsErrorInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsJobLinksResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsJobLinks'] = ResolversParentTypes['DataLoadsJobLinks'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source_download_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  output_download_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsJobsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsJobs'] = ResolversParentTypes['DataLoadsJobs'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobs?: Resolver<
    Array<ResolversTypes['DataLoadsJob']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsRecordInfoResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsRecordInfo'] = ResolversParentTypes['DataLoadsRecordInfo'],
> = {
  total_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  success_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  failed_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataLoadsResetContainersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataLoadsResetContainers'] = ResolversParentTypes['DataLoadsResetContainers'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sasUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataThresholdResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['DataThreshold'] = ResolversParentTypes['DataThreshold'],
> = {
  threshold?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilitiesExperienceAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['FacilitiesExperienceAlertCategory'] = ResolversParentTypes['FacilitiesExperienceAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilitiesExperienceAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['FacilitiesExperienceAlertSettingData'] = ResolversParentTypes['FacilitiesExperienceAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['FacilitiesExperienceAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilitiesExperienceAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['FacilitiesExperienceAlertSettings'] = ResolversParentTypes['FacilitiesExperienceAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  time_to_expected_completion_date_medium?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  time_to_expected_completion_date_high?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  time_from_created_to_picked_up_date?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  time_from_picked_up_to_due_date?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlexibleMappingOptionSampleValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['FlexibleMappingOptionSampleValues'] = ResolversParentTypes['FlexibleMappingOptionSampleValues'],
> = {
  option?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sample_data?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlobalDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['GlobalData'] = ResolversParentTypes['GlobalData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mismatch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  match?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlobalViewResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['GlobalView'] = ResolversParentTypes['GlobalView'],
> = {
  group_mapping?: Resolver<
    Maybe<ResolversTypes['GroupMapping']>,
    ParentType,
    ContextType
  >;
  global_data?: Resolver<
    Array<ResolversTypes['GlobalData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupMappingResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['GroupMapping'] = ResolversParentTypes['GroupMapping'],
> = {
  property?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  lease?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  transactions?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  work_order?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlert'] = ResolversParentTypes['InsightsAlert'],
> = {
  _client_?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  alert_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  alert_status?: Resolver<
    ResolversTypes['InsightsAlertStatus'],
    ParentType,
    ContextType
  >;
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  assigned_user_id?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_roi_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  severity?: Resolver<
    ResolversTypes['InsightsAlertSeverity'],
    ParentType,
    ContextType
  >;
  template_data?: Resolver<
    ResolversTypes['InsightsAlertTemplateData'],
    ParentType,
    ContextType
  >;
  thread_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user_importance?: Resolver<
    ResolversTypes['InsightsAlertImportance'],
    ParentType,
    ContextType
  >;
  user_info?: Resolver<
    Array<ResolversTypes['InsightsAlertUserInfo']>,
    ParentType,
    ContextType
  >;
  user_status?: Resolver<
    ResolversTypes['InsightsAlertUserStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertBaseWorkOrderTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertBaseWorkOrderTemplateData'] = ResolversParentTypes['InsightsAlertBaseWorkOrderTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  due_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_action_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  speciality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  work_order_number?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  work_order_priority?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  work_order_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertHistoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertHistory'] = ResolversParentTypes['InsightsAlertHistory'],
> = {
  history?: Resolver<
    Array<ResolversTypes['InsightsAlertHistoryRecord']>,
    ParentType,
    ContextType
  >;
  continuation_token?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertHistoryRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertHistoryRecord'] = ResolversParentTypes['InsightsAlertHistoryRecord'],
> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertLeaseEventTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertLeaseEventTemplateData'] = ResolversParentTypes['InsightsAlertLeaseEventTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  annual_cost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  desk_count?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  event_created_date?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  event_notice_date?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  event_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  head_count?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_cost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_size?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertMarketBenchmarkTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateData'] = ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  event_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  event_created_on?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_size?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  base_rent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  prediction_record?: Resolver<
    Maybe<
      ResolversTypes['InsightsAlertMarketBenchmarkTemplateDataPredictionRecord']
    >,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertMarketBenchmarkTemplateDataPredictionRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateDataPredictionRecord'] = ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateDataPredictionRecord'],
> = {
  rent_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  space_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  low_range?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  high_range?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  supporting_data_count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  supporting_data_points?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertMarketBenchmarkTemplateDataSupportingDataPointResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint'] = ResolversParentTypes['InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint'],
> = {
  ground_truth?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prediction?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  mdm_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  space_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  space_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  costar_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  date_on_market?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rent_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  geohash_12?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lease_transaction_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertOccupancyEfficiencyTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateData'] = ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  vacancy_rate_trigger_info?: Resolver<
    Maybe<
      ResolversTypes['InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord']
    >,
    ParentType,
    ContextType
  >;
  occupancy_rate_trigger_info?: Resolver<
    Maybe<
      ResolversTypes['InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord']
    >,
    ParentType,
    ContextType
  >;
  headcount_density_trigger_info?: Resolver<
    Maybe<
      ResolversTypes['InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord']
    >,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord'] = ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord'],
> = {
  jll_region?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  headcount_density?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  client_headcount_density?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord'] = ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord'],
> = {
  occupancy_rate_percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  client_threshold?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  threshold_level?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord'] = ResolversParentTypes['InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord'],
> = {
  vacancy_rate_percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  client_threshold?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  threshold_level?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertRoiDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertRoiData'] = ResolversParentTypes['InsightsAlertRoiData'],
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<
    Maybe<ResolversTypes['InsightsAlertRoiFrequency']>,
    ParentType,
    ContextType
  >;
  opportunity_type?: Resolver<
    Maybe<ResolversTypes['InsightsAlertRoiOpportunity']>,
    ParentType,
    ContextType
  >;
  unit?: Resolver<
    Maybe<ResolversTypes['InsightsAlertRoiUnit']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSameDayWorkOrderCompletionTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSameDayWorkOrderCompletionTemplateData'] = ResolversParentTypes['InsightsAlertSameDayWorkOrderCompletionTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  property_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  total_work_orders?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  completed_work_orders_percentage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  not_completed_work_orders_percentage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  completed_work_orders_not_on_time_percentage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  threshold_for_alert_generation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateData'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  total_liquidity_options?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  total_cost_obligations?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subleases?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  income_at_risk?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  notice_period?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lease_liquidity_options?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  city_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  state_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  country_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  source_system_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  natural_expiry?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  mainly_parking_leases?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  contraction_options?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  sublease_with_expiry?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  month_to_month_tenures?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  termination_options?: Resolver<
    Maybe<
      Array<
        Maybe<
          ResolversTypes['InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord']
        >
      >
    >,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord'],
> = {
  tenure_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_notice_on?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  event_notes?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  annualized_cost_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord'],
> = {
  tenure_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  annualized_cost_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord'],
> = {
  tenure_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  annualized_cost_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord'],
> = {
  tenure_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_ended_on?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  annualized_cost_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord'],
> = {
  tenure_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_ended_on?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  annualized_cost_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord'] = ResolversParentTypes['InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord'],
> = {
  tenure_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenure_key?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_notice_on?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  event_notes?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  annualized_cost_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSummaryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSummary'] = ResolversParentTypes['InsightsAlertSummary'],
> = {
  client_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  counts_by_status?: Resolver<
    ResolversTypes['InsightsAlertSummaryCountsByStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertSummaryCountsByStatusResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertSummaryCountsByStatus'] = ResolversParentTypes['InsightsAlertSummaryCountsByStatus'],
> = {
  all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unread?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertTemplateData'] = ResolversParentTypes['InsightsAlertTemplateData'],
> = {
  __resolveType: TypeResolveFn<
    | 'InsightsAlertBaseWorkOrderTemplateData'
    | 'InsightsAlertLeaseEventTemplateData'
    | 'InsightsAlertWorkOrderVolumeTemplateData'
    | 'InsightsAlertMarketBenchmarkTemplateData'
    | 'InsightsAlertOccupancyEfficiencyTemplateData'
    | 'InsightsAlertSubleaseRiskTemplateData'
    | 'InsightsAlertSameDayWorkOrderCompletionTemplateData',
    ParentType,
    ContextType
  >;
};

export type InsightsAlertThreadResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertThread'] = ResolversParentTypes['InsightsAlertThread'],
> = {
  client_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  thread_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert?: Resolver<
    Maybe<ResolversTypes['InsightsAlert']>,
    ParentType,
    ContextType
  >;
  alert_history?: Resolver<
    Maybe<ResolversTypes['InsightsAlertHistory']>,
    ParentType,
    ContextType,
    RequireFields<InsightsAlertThreadAlert_HistoryArgs, never>
  >;
  alert_user_history?: Resolver<
    Maybe<ResolversTypes['InsightsAlertUserHistory']>,
    ParentType,
    ContextType,
    RequireFields<InsightsAlertThreadAlert_User_HistoryArgs, never>
  >;
  alert_roi_data?: Resolver<
    Maybe<ResolversTypes['InsightsAlertRoiData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertUserHistoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertUserHistory'] = ResolversParentTypes['InsightsAlertUserHistory'],
> = {
  user_history?: Resolver<
    Array<ResolversTypes['InsightsAlertUserHistoryRecord']>,
    ParentType,
    ContextType
  >;
  continuation_token?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertUserHistoryRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertUserHistoryRecord'] = ResolversParentTypes['InsightsAlertUserHistoryRecord'],
> = {
  user_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commented_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  action_performed?: Resolver<
    ResolversTypes['InsightsAlertUserHistoryActionPerformed'],
    ParentType,
    ContextType
  >;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertUserInfoResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertUserInfo'] = ResolversParentTypes['InsightsAlertUserInfo'],
> = {
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertWorkOrderVolumeTemplateDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlertWorkOrderVolumeTemplateData'] = ResolversParentTypes['InsightsAlertWorkOrderVolumeTemplateData'],
> = {
  alert_type?: Resolver<
    ResolversTypes['InsightsAlertType'],
    ParentType,
    ContextType
  >;
  alert_scope?: Resolver<
    Maybe<ResolversTypes['InsightsAlertScope']>,
    ParentType,
    ContextType
  >;
  benchmark_external?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  benchmark_internal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  client_external_value?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  client_internal_value?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  peer_group_external?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  peer_group_internal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  speciality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  trade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  work_order_created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsAlertsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsAlerts'] = ResolversParentTypes['InsightsAlerts'],
> = {
  client_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  continuation_token?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  has_next_page?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alerts?: Resolver<
    Array<ResolversTypes['InsightsAlert']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsInboxAlertsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsInboxAlerts'] = ResolversParentTypes['InsightsInboxAlerts'],
> = {
  insights_alert_selected?: Resolver<
    Maybe<ResolversTypes['InsightsAlert']>,
    ParentType,
    ContextType
  >;
  insights_inbox_type?: Resolver<
    ResolversTypes['InsightsAlertInbox'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsInboxSelectedSortOrderResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsInboxSelectedSortOrder'] = ResolversParentTypes['InsightsInboxSelectedSortOrder'],
> = {
  created_date?: Resolver<
    ResolversTypes['InsightsAlertSortOrder'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsSelectedFiltersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['InsightsSelectedFilters'] = ResolversParentTypes['InsightsSelectedFilters'],
> = {
  insights_alert_assigned_to?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  insights_alert_severity?: Resolver<
    ResolversTypes['InsightsAlertSeverityFilter'],
    ParentType,
    ContextType
  >;
  insights_alert_type_filter?: Resolver<
    ResolversTypes['InsightsAlertTypeFilter'],
    ParentType,
    ContextType
  >;
  insights_importance_filter?: Resolver<
    ResolversTypes['InsightsAlertImportanceFilter'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeywordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Keyword'] = ResolversParentTypes['Keyword'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  keyword_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KpiDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['KpiData'] = ResolversParentTypes['KpiData'],
> = {
  size_current?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  size_change?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  size_last_yr?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  properties_current?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  properties_change?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  properties_last_yr?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  actual_costs_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  actual_costs?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  head_count?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  desk_count?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  occupancy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost_per_person?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<
    Maybe<ResolversTypes['CurrencyUnit']>,
    ParentType,
    ContextType
  >;
  unit_of_measurement?: Resolver<
    Maybe<ResolversTypes['AreaUnit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaseEventAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['LeaseEventAlertCategory'] = ResolversParentTypes['LeaseEventAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaseEventAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['LeaseEventAlertSettingData'] = ResolversParentTypes['LeaseEventAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['LeaseEventAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaseEventAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['LeaseEventAlertSettings'] = ResolversParentTypes['LeaseEventAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notice_period_time_medium?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  notice_period_time_high?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  preferred_unit_of_measurement?: Resolver<
    ResolversTypes['AreaUnit'],
    ParentType,
    ContextType
  >;
  preferred_unit_of_currency?: Resolver<
    ResolversTypes['CurrencyUnit'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Log'] = ResolversParentTypes['Log'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  entity_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entity_event?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entity_type?: Resolver<ResolversTypes['EntityType'], ParentType, ContextType>;
  event_type?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  entity_old_value?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  entity_new_value?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  changed_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  changed_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MappedResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Mapped'] = ResolversParentTypes['Mapped'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  target_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketBenchmarkAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['MarketBenchmarkAlertCategory'] = ResolversParentTypes['MarketBenchmarkAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketBenchmarkAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['MarketBenchmarkAlertSettingData'] = ResolversParentTypes['MarketBenchmarkAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['MarketBenchmarkAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketBenchmarkAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['MarketBenchmarkAlertSettings'] = ResolversParentTypes['MarketBenchmarkAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notice_period?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  current_cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveCustomReportResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['MoveCustomReport'] = ResolversParentTypes['MoveCustomReport'],
> = {
  updated_report?: Resolver<ResolversTypes['Report'], ParentType, ContextType>;
  deactivated_reports?: Resolver<
    Array<ResolversTypes['Report']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  addApplication?: Resolver<
    ResolversTypes['Application'],
    ParentType,
    ContextType,
    RequireFields<MutationAddApplicationArgs, 'data'>
  >;
  addCategory?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<MutationAddCategoryArgs, 'data'>
  >;
  addCountry?: Resolver<
    ResolversTypes['Country'],
    ParentType,
    ContextType,
    RequireFields<MutationAddCountryArgs, 'data'>
  >;
  addInsightsAlertUserComment?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationAddInsightsAlertUserCommentArgs, 'where'>
  >;
  addRegion?: Resolver<
    ResolversTypes['Region'],
    ParentType,
    ContextType,
    RequireFields<MutationAddRegionArgs, 'data'>
  >;
  addSavedViewToUser?: Resolver<
    ResolversTypes['UserCustomReport'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSavedViewToUserArgs, 'data' | 'where'>
  >;
  addTenant?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTenantArgs, 'data'>
  >;
  addTenantAlertSettings?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTenantAlertSettingsArgs, 'data' | 'where'>
  >;
  addTenantConfigurationData?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTenantConfigurationDataArgs, 'data' | 'where'>
  >;
  addTenantDataShareForCustomReports?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAddTenantDataShareForCustomReportsArgs,
      'where' | 'data'
    >
  >;
  addTenantReport?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTenantReportArgs, 'where' | 'data'>
  >;
  addTenantUsers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddTenantUsersArgs, 'data' | 'where'>
  >;
  addUserCategoryReport?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUserCategoryReportArgs, 'data' | 'where'>
  >;
  addUserFavoriteReport?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUserFavoriteReportArgs, 'reportId' | 'where'>
  >;
  addUserHiddenReport?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUserHiddenReportArgs, 'reportId' | 'where'>
  >;
  addUserPersona?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUserPersonaArgs, 'data'>
  >;
  addWorkArea?: Resolver<
    ResolversTypes['WorkArea'],
    ParentType,
    ContextType,
    RequireFields<MutationAddWorkAreaArgs, 'data'>
  >;
  createDemoTenantCovid19WorkOrderInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantCovid19WorkOrderInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantFacilitiesExperienceInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantFacilitiesExperienceInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateDemoTenantInsightsAlertArgs, 'where' | 'data'>
  >;
  createDemoTenantLeaseEventInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantLeaseEventInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantMarketBenchmarkInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantMarketBenchmarkInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantOccupancyEfficiencyInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantOccupancyEfficiencyInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantSameDayWorkOrderCompletionInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantSameDayWorkOrderCompletionInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantSubleaseRiskInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantSubleaseRiskInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  createDemoTenantWorkOrderVolumeInsightsAlert?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateDemoTenantWorkOrderVolumeInsightsAlertArgs,
      'where' | 'data'
    >
  >;
  deactivateCustomReport?: Resolver<
    ResolversTypes['MoveCustomReport'],
    ParentType,
    ContextType,
    RequireFields<MutationDeactivateCustomReportArgs, 'where'>
  >;
  deactivateUserFromTenant?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationDeactivateUserFromTenantArgs, 'tenant_id' | 'where'>
  >;
  deleteApplication?: Resolver<
    ResolversTypes['Application'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationArgs, 'where'>
  >;
  deleteCategory?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCategoryArgs, 'where'>
  >;
  deleteCountry?: Resolver<
    ResolversTypes['Country'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCountryArgs, 'where'>
  >;
  deleteKeyword?: Resolver<
    ResolversTypes['Keyword'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteKeywordArgs, 'where'>
  >;
  deleteRegion?: Resolver<
    ResolversTypes['Region'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRegionArgs, 'where'>
  >;
  deleteReportMapping?: Resolver<
    ResolversTypes['ReportMapping'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteReportMappingArgs, 'where'>
  >;
  deleteSavedViewFromUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSavedViewFromUserArgs, 'reportId' | 'where'>
  >;
  deleteTenant?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantArgs, 'where'>
  >;
  deleteTenantDataShareForCustomReports?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteTenantDataShareForCustomReportsArgs,
      'where' | 'data'
    >
  >;
  deleteTenantFull?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantFullArgs, 'where'>
  >;
  deleteTenantReport?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantReportArgs, 'where'>
  >;
  deleteTenantRole?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantRoleArgs, 'where'>
  >;
  deleteUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserArgs, 'where'>
  >;
  deleteUserFromTenant?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserFromTenantArgs, 'tenant_id' | 'where'>
  >;
  deleteWorkArea?: Resolver<
    ResolversTypes['WorkArea'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkAreaArgs, 'where'>
  >;
  generateTrustedExternalReportUrl?: Resolver<
    ResolversTypes['TrustedReportUrl'],
    ParentType,
    ContextType,
    RequireFields<MutationGenerateTrustedExternalReportUrlArgs, 'where'>
  >;
  generateTrustedReportUrl?: Resolver<
    ResolversTypes['TrustedReportUrl'],
    ParentType,
    ContextType,
    RequireFields<MutationGenerateTrustedReportUrlArgs, 'where'>
  >;
  getDownloadTemplateSasUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationGetDownloadTemplateSasUrlArgs, 'where'>
  >;
  logError?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationLogErrorArgs, 'data'>
  >;
  logPerformance?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationLogPerformanceArgs, 'data'>
  >;
  logUserActivity?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  loginUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginUserArgs, 'user_id'>
  >;
  logoutUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationLogoutUserArgs, 'user_id' | 'tenant_id'>
  >;
  postMappingDataStandard?: Resolver<
    Array<ResolversTypes['PostMappingDataStandardResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationPostMappingDataStandardArgs, 'where' | 'data'>
  >;
  previewCustomReport?: Resolver<
    ResolversTypes['Report'],
    ParentType,
    ContextType,
    RequireFields<MutationPreviewCustomReportArgs, 'where'>
  >;
  promoteRelease?: Resolver<
    ResolversTypes['ReleasesRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationPromoteReleaseArgs, 'where' | 'data'>
  >;
  provisionTenant?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationProvisionTenantArgs, 'where'>
  >;
  publishCustomReport?: Resolver<
    ResolversTypes['MoveCustomReport'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishCustomReportArgs, 'where'>
  >;
  reactivateTenantUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationReactivateTenantUserArgs, 'tenant_id' | 'user_id'>
  >;
  reactivateUserToTenant?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationReactivateUserToTenantArgs, 'tenant_id' | 'where'>
  >;
  refreshTenantUsers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationRefreshTenantUsersArgs, 'where'>
  >;
  removeDemoTenantInsightsAlerts?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveDemoTenantInsightsAlertsArgs, 'where'>
  >;
  removeInsightsAlertAssignedUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveInsightsAlertAssignedUserArgs, 'where'>
  >;
  removeUserFavoriteReport?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserFavoriteReportArgs, 'reportId' | 'where'>
  >;
  removeUserHiddenReport?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserHiddenReportArgs, 'reportId' | 'where'>
  >;
  replaceUserFavoriteReports?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationReplaceUserFavoriteReportsArgs, 'reportIds' | 'where'>
  >;
  resetContainerAccessSasUrl?: Resolver<
    ResolversTypes['DataLoadsResetContainers'],
    ParentType,
    ContextType,
    RequireFields<MutationResetContainerAccessSasUrlArgs, 'where'>
  >;
  resetDemoTenantInsightsAlerts?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationResetDemoTenantInsightsAlertsArgs, 'where'>
  >;
  resetDemoUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationResetDemoUserArgs, 'where'>
  >;
  resetTenantUserPassword?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationResetTenantUserPasswordArgs, 'tenant_id' | 'user_id'>
  >;
  setInsightsAlertAssignedUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetInsightsAlertAssignedUserArgs, 'where'>
  >;
  setInsightsAlertRoiData?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetInsightsAlertRoiDataArgs, 'where'>
  >;
  setInsightsAlertSeverity?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetInsightsAlertSeverityArgs, 'where'>
  >;
  setInsightsAlertStatus?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetInsightsAlertStatusArgs, 'where'>
  >;
  setInsightsAlertUserImportance?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetInsightsAlertUserImportanceArgs, 'where'>
  >;
  setInsightsAlertUserRead?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetInsightsAlertUserReadArgs, 'where'>
  >;
  setInsightsInboxAlertSeverity?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxAlertSeverityArgs,
      'alertSeverityFilter' | 'tenantId' | 'userId'
    >
  >;
  setInsightsInboxAlertSortOrder?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxAlertSortOrderArgs,
      'createdSortOrder' | 'tenantId' | 'userId'
    >
  >;
  setInsightsInboxAlertTypeFilter?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxAlertTypeFilterArgs,
      'alertTypeFilter' | 'tenantId' | 'userId'
    >
  >;
  setInsightsInboxAlertUserAssignedTo?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxAlertUserAssignedToArgs,
      'tenantId' | 'userAssigned' | 'userId'
    >
  >;
  setInsightsInboxImportanceFilter?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxImportanceFilterArgs,
      'importanceFilter' | 'tenantId' | 'userId'
    >
  >;
  setInsightsInboxRecentAlert?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxRecentAlertArgs,
      'inboxType' | 'tenantId' | 'threadId' | 'userId'
    >
  >;
  setInsightsInboxRemoveRecentAlert?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetInsightsInboxRemoveRecentAlertArgs,
      'inboxType' | 'tenantId' | 'userId'
    >
  >;
  syncTenantUnpublishedReports?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationSyncTenantUnpublishedReportsArgs, 'where'>
  >;
  syncTenants?: Resolver<
    Array<ResolversTypes['Tenant']>,
    ParentType,
    ContextType,
    RequireFields<MutationSyncTenantsArgs, 'where'>
  >;
  syncUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationSyncUserArgs, 'where'>
  >;
  syncUserProvisioning?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSyncUserProvisioningArgs, 'tenantId' | 'where'>
  >;
  syncUserTenantKpiData?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<
      MutationSyncUserTenantKpiDataArgs,
      'tenantId' | 'tenantOvcId' | 'where'
    >
  >;
  unlockTenantUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUnlockTenantUserArgs, 'tenant_id' | 'user_id'>
  >;
  unsuspendTenantUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUnsuspendTenantUserArgs, 'tenant_id' | 'user_id'>
  >;
  updateApplication?: Resolver<
    ResolversTypes['Application'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationArgs, 'data' | 'where'>
  >;
  updateCategory?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCategoryArgs, 'data' | 'where'>
  >;
  updateCountry?: Resolver<
    ResolversTypes['Country'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCountryArgs, 'data' | 'where'>
  >;
  updateProductSettingsReport?: Resolver<
    ResolversTypes['Report'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductSettingsReportArgs, 'where' | 'data'>
  >;
  updateRegion?: Resolver<
    ResolversTypes['Region'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRegionArgs, 'data' | 'where'>
  >;
  updateRelease?: Resolver<
    ResolversTypes['ReleasesRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateReleaseArgs, 'where' | 'data'>
  >;
  updateReport?: Resolver<
    ResolversTypes['Report'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateReportArgs, 'data' | 'where'>
  >;
  updateTenant?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantArgs, 'data' | 'where'>
  >;
  updateTenantDataShareForCustomReports?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTenantDataShareForCustomReportsArgs,
      'where' | 'data'
    >
  >;
  updateTenantKpiSettings?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantKpiSettingsArgs, 'where' | 'data'>
  >;
  updateTenantReports?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantReportsArgs, 'where' | 'data'>
  >;
  updateTenantUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantUserArgs, 'data' | 'where'>
  >;
  updateUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserArgs, 'data' | 'where'>
  >;
  updateUserAgreement?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserAgreementArgs, 'where' | 'data'>
  >;
  updateUserExtended?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserExtendedArgs, 'where' | 'data'>
  >;
  updateUserTenantDefaultProfile?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserTenantDefaultProfileArgs, 'where' | 'data'>
  >;
  updateUserTenantRegularSettings?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserTenantRegularSettingsArgs, 'where' | 'data'>
  >;
  updateUserUniversalFilters?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserUniversalFiltersArgs, 'data' | 'where'>
  >;
  updateWorkArea?: Resolver<
    ResolversTypes['WorkArea'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkAreaArgs, 'data' | 'where'>
  >;
  uploadFileSasUrl?: Resolver<
    ResolversTypes['DataLoadsFileUploadSasUrl'],
    ParentType,
    ContextType,
    RequireFields<MutationUploadFileSasUrlArgs, 'where'>
  >;
  upsertKeyword?: Resolver<
    ResolversTypes['Keyword'],
    ParentType,
    ContextType,
    RequireFields<MutationUpsertKeywordArgs, 'data'>
  >;
  upsertReportMapping?: Resolver<
    ResolversTypes['ReportMapping'],
    ParentType,
    ContextType,
    RequireFields<MutationUpsertReportMappingArgs, 'data'>
  >;
  upsertTenantRole?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<MutationUpsertTenantRoleArgs, 'where' | 'data'>
  >;
  upsertTenantSelfServeAnalyticsSettings?: Resolver<
    ResolversTypes['Tenant'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpsertTenantSelfServeAnalyticsSettingsArgs,
      'where' | 'data'
    >
  >;
  upsertUserWorkRole?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpsertUserWorkRoleArgs, 'where' | 'data'>
  >;
  validateExternalReportEmbedUrl?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationValidateExternalReportEmbedUrlArgs, 'where'>
  >;
};

export type OccupancyEfficiencyAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['OccupancyEfficiencyAlertCategory'] = ResolversParentTypes['OccupancyEfficiencyAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OccupancyEfficiencyAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['OccupancyEfficiencyAlertSettingData'] = ResolversParentTypes['OccupancyEfficiencyAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['OccupancyEfficiencyAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OccupancyEfficiencyAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['OccupancyEfficiencyAlertSettings'] = ResolversParentTypes['OccupancyEfficiencyAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  headcount_density_emea?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  headcount_density_am?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  headcount_density_apac?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  headcount_density_other_or_none?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  occupancy_rate_high?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  occupancy_rate_low?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostMappingDataStandardResponseResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['PostMappingDataStandardResponse'] = ResolversParentTypes['PostMappingDataStandardResponse'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  target_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['PropertyData'] = ResolversParentTypes['PropertyData'],
> = {
  next_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tenant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  records?: Resolver<
    Maybe<Array<ResolversTypes['PropertyDataRecord']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyDataRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['PropertyDataRecord'] = ResolversParentTypes['PropertyDataRecord'],
> = {
  document_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  property_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  property_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  address_line_1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  address_line_2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  address_line_3?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  address_line_4?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postal_code?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_iso_code_2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  country_iso_code_3?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  country_iso_numeric?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_sub_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lease_owned?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  property_area?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unit_of_measure?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  service_end_date?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  service_start_date?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  site_group?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  industry_sector?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  business_unit?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_3?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_4?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_5?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_6?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_7?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region_hierarchy_8?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  getApiVersion?: Resolver<
    ResolversTypes['ApiVersion'],
    ParentType,
    ContextType
  >;
  getApplications?: Resolver<
    Array<ResolversTypes['Application']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetApplicationsArgs, never>
  >;
  getCategories?: Resolver<
    Array<Maybe<ResolversTypes['Category']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCategoriesArgs, never>
  >;
  getCountries?: Resolver<
    Array<Maybe<ResolversTypes['Country']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCountriesArgs, never>
  >;
  getKeywords?: Resolver<
    Array<Maybe<ResolversTypes['Keyword']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetKeywordsArgs, never>
  >;
  getWorkAreas?: Resolver<
    Array<Maybe<ResolversTypes['WorkArea']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetWorkAreasArgs, never>
  >;
  getRegions?: Resolver<
    Array<Maybe<ResolversTypes['Region']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRegionsArgs, never>
  >;
  getReports?: Resolver<
    Array<Maybe<ResolversTypes['Report']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetReportsArgs, never>
  >;
  getRoles?: Resolver<
    Array<Maybe<ResolversTypes['Role']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRolesArgs, never>
  >;
  getTenants?: Resolver<
    Array<Maybe<ResolversTypes['Tenant']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetTenantsArgs, never>
  >;
  getUsers?: Resolver<
    Array<Maybe<ResolversTypes['User']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUsersArgs, never>
  >;
  searchUsers?: Resolver<
    Array<ResolversTypes['SearchedUserResult']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchUsersArgs, never>
  >;
  getSystemRoleUsers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >;
  getUser?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUserArgs, 'where'>
  >;
  getReportMappings?: Resolver<
    Array<ResolversTypes['ReportMapping']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetReportMappingsArgs, never>
  >;
  getBlobStorageUploadSasUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryGetBlobStorageUploadSasUrlArgs, 'where' | 'data'>
  >;
  getWorkAreaKeywords?: Resolver<
    Array<ResolversTypes['Keyword']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetWorkAreaKeywordsArgs, 'tenant_id'>
  >;
  getUserClassification?: Resolver<
    ResolversTypes['UserClassification'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserClassificationArgs, 'where'>
  >;
  getTenantReportsConfiguration?: Resolver<
    ResolversTypes['TenantReportsConfiguration'],
    ParentType,
    ContextType,
    RequireFields<QueryGetTenantReportsConfigurationArgs, 'where'>
  >;
  getQaTenants?: Resolver<
    Array<ResolversTypes['Tenant']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetQaTenantsArgs, 'tenant_qa_type'>
  >;
  getTenantReportConfiguration?: Resolver<
    ResolversTypes['ReportConfig'],
    ParentType,
    ContextType,
    RequireFields<QueryGetTenantReportConfigurationArgs, 'where'>
  >;
  getReleases?: Resolver<
    Array<ResolversTypes['ReleasesRecord']>,
    ParentType,
    ContextType
  >;
  getThoughtSpotTrustedTicket?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryGetThoughtSpotTrustedTicketArgs, 'where'>
  >;
};

export type RegionResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  region_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleasesRecordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReleasesRecord'] = ResolversParentTypes['ReleasesRecord'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  client_type?: Resolver<
    ResolversTypes['TenantQaType'],
    ParentType,
    ContextType
  >;
  release_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ReleaseStatus'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_update?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  last_update_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  release_date?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  test_status?: Resolver<
    Maybe<ResolversTypes['TestStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  report_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  report_original_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_cat?: Resolver<
    Array<ResolversTypes['Category']>,
    ParentType,
    ContextType
  >;
  report_tableau_url?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  report_tableau_full_report_url?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  report_tableau_thumbnail_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_external_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_external_anchor_target?: Resolver<
    Maybe<ResolversTypes['ReportAnchorTarget']>,
    ParentType,
    ContextType
  >;
  report_external_thumbnail_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_full_keywords?: Resolver<
    Array<ResolversTypes['Keyword']>,
    ParentType,
    ContextType
  >;
  report_active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  report_description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_mapping?: Resolver<
    Maybe<ResolversTypes['ReportMapping']>,
    ParentType,
    ContextType
  >;
  report_wb?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_parent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_tenant?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_added_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_status?: Resolver<
    Maybe<ResolversTypes['CustomReportStatus']>,
    ParentType,
    ContextType
  >;
  custom_report_created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_previewed_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_previewed_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_published_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_published_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_deactivated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_deactivated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_version?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custom_report_can_be_published?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  custom_report_can_be_previewed?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  custom_report_can_be_deactivated?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  custom_report_published?: Resolver<
    Maybe<ResolversTypes['Report']>,
    ParentType,
    ContextType
  >;
  report_metadata?: Resolver<
    Maybe<ResolversTypes['ReportMetadata']>,
    ParentType,
    ContextType
  >;
  report_config?: Resolver<
    Maybe<ResolversTypes['ReportConfig']>,
    ParentType,
    ContextType
  >;
  report_external_type?: Resolver<
    Maybe<ResolversTypes['ExternalReportType']>,
    ParentType,
    ContextType
  >;
  report_external_thoughtspot_host?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_external_thoughtspot_pinboard_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_external_thoughtspot_viz_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_data_update_date?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_hidden?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  report_custom_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_custom_categories?: Resolver<
    Maybe<Array<ResolversTypes['Category']>>,
    ParentType,
    ContextType
  >;
  report_disabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportConfigResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportConfig'] = ResolversParentTypes['ReportConfig'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  report_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  report_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  report_type?: Resolver<ResolversTypes['ReportType'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  interaction_config?: Resolver<
    ResolversTypes['BaselineReportInteractionConfiguration'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportControlResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportControl'] = ResolversParentTypes['ReportControl'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  section_type?: Resolver<
    Maybe<ResolversTypes['ReportSectionType']>,
    ParentType,
    ContextType
  >;
  control_type?: Resolver<
    ResolversTypes['ReportControlType'],
    ParentType,
    ContextType
  >;
  static_data?: Resolver<
    Array<ResolversTypes['ReportControlStaticListItem']>,
    ParentType,
    ContextType
  >;
  cascade_update?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sort_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportControlStaticListItemResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportControlStaticListItem'] = ResolversParentTypes['ReportControlStaticListItem'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportInteractionConfigurationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportInteractionConfiguration'] = ResolversParentTypes['ReportInteractionConfiguration'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sections?: Resolver<
    Array<ResolversTypes['ReportInteractionSection']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportInteractionSectionResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportInteractionSection'] = ResolversParentTypes['ReportInteractionSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expanded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  section_type?: Resolver<
    ResolversTypes['ReportSectionType'],
    ParentType,
    ContextType
  >;
  controls?: Resolver<
    Array<ResolversTypes['ReportControl']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportMappingResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportMapping'] = ResolversParentTypes['ReportMapping'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  report_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_keywords?: Resolver<
    Maybe<Array<ResolversTypes['Keyword']>>,
    ParentType,
    ContextType
  >;
  report_description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  report_type?: Resolver<
    Maybe<ResolversTypes['ReportType']>,
    ParentType,
    ContextType
  >;
  report_mapping_exists?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportMetadataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportMetadata'] = ResolversParentTypes['ReportMetadata'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  module?: Resolver<ResolversTypes['ReportModule'], ParentType, ContextType>;
  module_category?: Resolver<
    ResolversTypes['ReportModuleCategory'],
    ParentType,
    ContextType
  >;
  platform?: Resolver<
    ResolversTypes['ReportPlatform'],
    ParentType,
    ContextType
  >;
  report_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  base_report_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  image_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  image_blob?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sort_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  interaction_config?: Resolver<
    ResolversTypes['ReportInteractionConfiguration'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  source_data?: Resolver<
    ResolversTypes['ReportMetadataSourceData'],
    ParentType,
    ContextType
  >;
  report_metadata_mapping?: Resolver<
    ResolversTypes['ReportMetadataMapping'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportMetadataMappingResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportMetadataMapping'] = ResolversParentTypes['ReportMetadataMapping'],
> = {
  worksheet?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dashboard?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subscription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportMetadataSourceDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportMetadataSourceData'] = ResolversParentTypes['ReportMetadataSourceData'],
> = {
  source_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  client_report_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  client_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  client_code?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  client_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  last_data_refresh?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportModuleResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportModule'] = ResolversParentTypes['ReportModule'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  module_type?: Resolver<
    ResolversTypes['ReportModuleType'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportModuleCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ReportModuleCategory'] = ResolversParentTypes['ReportModuleCategory'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  module_category_type?: Resolver<
    ResolversTypes['ReportModuleCategoryType'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role_type?: Resolver<ResolversTypes['RoleType'], ParentType, ContextType>;
  role_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role_description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role_features?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  role_insights_alerts?: Resolver<
    Maybe<ResolversTypes['RoleInsightsAlerts']>,
    ParentType,
    ContextType
  >;
  role_users_list?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  role_universal_filters?: Resolver<
    Maybe<ResolversTypes['RoleUniversalFilters']>,
    ParentType,
    ContextType
  >;
  role_dashboards?: Resolver<
    Maybe<ResolversTypes['RoleDashboards']>,
    ParentType,
    ContextType
  >;
  role_other_features_all?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  role_other_features?: Resolver<
    Maybe<Array<ResolversTypes['RoleOtherFeature']>>,
    ParentType,
    ContextType
  >;
  role_added_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role_added_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role_updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role_updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role_status?: Resolver<
    Maybe<ResolversTypes['RoleStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleDashboardResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleDashboard'] = ResolversParentTypes['RoleDashboard'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  report_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleDashboardsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleDashboards'] = ResolversParentTypes['RoleDashboards'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  all?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  all_future?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dashboards?: Resolver<
    Array<ResolversTypes['RoleDashboard']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleInsightsAlertResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleInsightsAlert'] = ResolversParentTypes['RoleInsightsAlert'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alert_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleInsightsAlertsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleInsightsAlerts'] = ResolversParentTypes['RoleInsightsAlerts'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  all?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  all_future?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alerts?: Resolver<
    Array<ResolversTypes['RoleInsightsAlert']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleOtherFeatureResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleOtherFeature'] = ResolversParentTypes['RoleOtherFeature'],
> = {
  feature_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sub_features?: Resolver<
    Array<ResolversTypes['RoleOtherSubFeature']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleOtherSubFeatureResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleOtherSubFeature'] = ResolversParentTypes['RoleOtherSubFeature'],
> = {
  sub_feature_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  full_access?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleUniversalFiltersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['RoleUniversalFilters'] = ResolversParentTypes['RoleUniversalFilters'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  filters?: Resolver<
    ResolversTypes['UserUniversalFilters'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SameDayWorkOrderCompletionAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SameDayWorkOrderCompletionAlertCategory'] = ResolversParentTypes['SameDayWorkOrderCompletionAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SameDayWorkOrderCompletionAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SameDayWorkOrderCompletionAlertSettingData'] = ResolversParentTypes['SameDayWorkOrderCompletionAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['SameDayWorkOrderCompletionAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SameDayWorkOrderCompletionAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SameDayWorkOrderCompletionAlertSettings'] = ResolversParentTypes['SameDayWorkOrderCompletionAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  threshold_for_alert_generation?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchedUserResultResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SearchedUserResult'] = ResolversParentTypes['SearchedUserResult'],
> = {
  user_email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_first_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_last_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_management_type?: Resolver<
    Maybe<ResolversTypes['UserManagementType']>,
    ParentType,
    ContextType
  >;
  user_tenants?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SegmentOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SegmentOptions'] = ResolversParentTypes['SegmentOptions'],
> = {
  mapping_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  setting_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  setting_segment_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  order_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  setting_segment_name?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubleaseRiskAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SubleaseRiskAlertCategory'] = ResolversParentTypes['SubleaseRiskAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubleaseRiskAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SubleaseRiskAlertSettingData'] = ResolversParentTypes['SubleaseRiskAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['SubleaseRiskAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubleaseRiskAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['SubleaseRiskAlertSettings'] = ResolversParentTypes['SubleaseRiskAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lease_liquidity_options?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  notice_period?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Tenant'] = ResolversParentTypes['Tenant'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tenant_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_description?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  tenant_ovc_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenant_activated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenant_synchronized_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenant_applications?: Resolver<
    Array<ResolversTypes['TenantApplication']>,
    ParentType,
    ContextType
  >;
  tenant_features?: Resolver<
    ResolversTypes['TenantFeatures'],
    ParentType,
    ContextType
  >;
  tenant_reports?: Resolver<
    Array<ResolversTypes['Report']>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_ReportsArgs, never>
  >;
  tenant_users?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >;
  tenant_status?: Resolver<
    ResolversTypes['TenantStatus'],
    ParentType,
    ContextType
  >;
  tenant_alert_settings?: Resolver<
    Maybe<Array<ResolversTypes['TenantAlertSettings']>>,
    ParentType,
    ContextType
  >;
  tenant_custom_reports_settings?: Resolver<
    Maybe<ResolversTypes['TenantCustomReportsSettings']>,
    ParentType,
    ContextType
  >;
  tenant_alert_rule_builder?: Resolver<
    ResolversTypes['TenantAlertSettingData'],
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Alert_Rule_BuilderArgs, 'alert_type'>
  >;
  tenant_geo_universal_filters?: Resolver<
    ResolversTypes['TenantGeoUniversalFilters'],
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Geo_Universal_FiltersArgs, never>
  >;
  tenant_activity_logs?: Resolver<
    Array<ResolversTypes['Log']>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Activity_LogsArgs, 'entity_type' | 'event_type'>
  >;
  tenant_enabled_alert_types?: Resolver<
    Maybe<Array<ResolversTypes['TenantAlertSettingsType']>>,
    ParentType,
    ContextType
  >;
  tenant_configuration_data?: Resolver<
    Maybe<Array<ResolversTypes['TenantConfigurationData']>>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Configuration_DataArgs, never>
  >;
  tenant_ad_groups?: Resolver<
    Array<ResolversTypes['AdGroup']>,
    ParentType,
    ContextType
  >;
  tenant_ad_users?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >;
  tenant_data_standards?: Resolver<
    ResolversTypes['TenantDataStandard'],
    ParentType,
    ContextType
  >;
  tenant_external_reports?: Resolver<
    Array<Maybe<ResolversTypes['Report']>>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_External_ReportsArgs, never>
  >;
  tenant_data_loads_jobs?: Resolver<
    Maybe<ResolversTypes['DataLoadsJobs']>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Data_Loads_JobsArgs, 'filters'>
  >;
  tenant_data_loads_job_links?: Resolver<
    ResolversTypes['DataLoadsJobLinks'],
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Data_Loads_Job_LinksArgs, 'job_id'>
  >;
  tenant_data_loads_containers?: Resolver<
    ResolversTypes['DataLoadsContainers'],
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Data_Loads_ContainersArgs, never>
  >;
  tenant_client_defined_roles?: Resolver<
    Maybe<Array<ResolversTypes['Role']>>,
    ParentType,
    ContextType
  >;
  tenant_user_integration?: Resolver<
    ResolversTypes['TenantUserIntegration'],
    ParentType,
    ContextType
  >;
  tenant_type?: Resolver<ResolversTypes['TenantType'], ParentType, ContextType>;
  tenant_property_data?: Resolver<
    Maybe<ResolversTypes['PropertyData']>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Property_DataArgs, never>
  >;
  tenant_property_data_filter_values?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<TenantTenant_Property_Data_Filter_ValuesArgs, 'filter'>
  >;
  tenant_kpi_settings?: Resolver<
    Maybe<Array<ResolversTypes['TenantKpiSettings']>>,
    ParentType,
    ContextType
  >;
  tenant_qa_type?: Resolver<
    ResolversTypes['TenantQaType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantAlertSettingData'] = ResolversParentTypes['TenantAlertSettingData'],
> = {
  __resolveType: TypeResolveFn<
    | 'TenantWorkOrderVolumeAlertSettingData'
    | 'TenantFacilitiesExperienceAlertSettingData'
    | 'TenantLeaseEventAlertSettingData'
    | 'TenantCovidWorkOrderAlertSettingData'
    | 'TenantMarketBenchmarkAlertSettingData'
    | 'TenantOccupancyEfficiencyAlertSettingData'
    | 'TenantSubleaseRiskAlertSettingData'
    | 'TenantSameDayWorkOrderCompletionAlertSettingData',
    ParentType,
    ContextType
  >;
};

export type TenantAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantAlertSettings'] = ResolversParentTypes['TenantAlertSettings'],
> = {
  __resolveType: TypeResolveFn<
    | 'WorkOrderVolumeAlertSettings'
    | 'FacilitiesExperienceAlertSettings'
    | 'LeaseEventAlertSettings'
    | 'CovidWorkOrderAlertSettings'
    | 'MarketBenchmarkAlertSettings'
    | 'OccupancyEfficiencyAlertSettings'
    | 'SubleaseRiskAlertSettings'
    | 'SameDayWorkOrderCompletionAlertSettings',
    ParentType,
    ContextType
  >;
};

export type TenantApplicationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantApplication'] = ResolversParentTypes['TenantApplication'],
> = {
  app?: Resolver<ResolversTypes['Application'], ParentType, ContextType>;
  app_provisioned?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  app_tenant_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantConfigurationDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantConfigurationData'] = ResolversParentTypes['TenantConfigurationData'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  app_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  values?: Resolver<
    Maybe<ResolversTypes['TenantConfigurationDataValues']>,
    ParentType,
    ContextType
  >;
  options?: Resolver<
    Maybe<ResolversTypes['TenantConfigurationDataOptions']>,
    ParentType,
    ContextType
  >;
  sample_values?: Resolver<
    Maybe<ResolversTypes['TenantConfigurationDataSampleValues']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantConfigurationDataOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantConfigurationDataOptions'] = ResolversParentTypes['TenantConfigurationDataOptions'],
> = {
  __resolveType: TypeResolveFn<
    | 'TenantFinanceDataOptions'
    | 'TenantTransactionDataOptions'
    | 'TenantLeaseDataOptions'
    | 'TenantSpaceDataOptions',
    ParentType,
    ContextType
  >;
};

export type TenantConfigurationDataSampleValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantConfigurationDataSampleValues'] = ResolversParentTypes['TenantConfigurationDataSampleValues'],
> = {
  __resolveType: TypeResolveFn<
    | 'TenantSpaceDataSampleValues'
    | 'TenantFinanceDataSampleValues'
    | 'TenantLeaseDataSampleValues',
    ParentType,
    ContextType
  >;
};

export type TenantConfigurationDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantConfigurationDataValues'] = ResolversParentTypes['TenantConfigurationDataValues'],
> = {
  __resolveType: TypeResolveFn<
    | 'TenantDefaultsDataValues'
    | 'TenantWorkOrdersDataValues'
    | 'TenantFinanceDataValues'
    | 'TenantTransactionDataValues'
    | 'TenantLeaseDataValues'
    | 'TenantSpaceDataValues',
    ParentType,
    ContextType
  >;
};

export type TenantCovidWorkOrderAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantCovidWorkOrderAlertSettingData'] = ResolversParentTypes['TenantCovidWorkOrderAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['CovidWorkOrderAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantCustomReportsSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantCustomReportsSettings'] = ResolversParentTypes['TenantCustomReportsSettings'],
> = {
  tenant_custom_reports?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Report']>>>,
    ParentType,
    ContextType,
    RequireFields<TenantCustomReportsSettingsTenant_Custom_ReportsArgs, never>
  >;
  tenant_custom_reports_setup?: Resolver<
    Maybe<ResolversTypes['TenantCustomReportsSetup']>,
    ParentType,
    ContextType
  >;
  tenant_self_serve_analytics_settings?: Resolver<
    Maybe<ResolversTypes['TenantSelfServeAnalyticsSettings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantCustomReportsSetupResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantCustomReportsSetup'] = ResolversParentTypes['TenantCustomReportsSetup'],
> = {
  tenant_snowflake_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenant_snowflake_urls?: Resolver<
    Array<ResolversTypes['TenantSnowflakeUrl']>,
    ParentType,
    ContextType
  >;
  tenant_snowflake_locator?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  tenant_snowflake_database?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  tenant_tableau_project_custom_site_url?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  tenant_snowflake_share?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  tenant_snowflake_setup_status?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  tenant_snowflake_setup_error?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenant_snowflake_share_consumers?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantDataStandardResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantDataStandard'] = ResolversParentTypes['TenantDataStandard'],
> = {
  global_views?: Resolver<
    ResolversTypes['GlobalView'],
    ParentType,
    ContextType,
    RequireFields<
      TenantDataStandardGlobal_ViewsArgs,
      'tenant_id' | 'mapping_type'
    >
  >;
  unmapped?: Resolver<
    Array<ResolversTypes['Unmapped']>,
    ParentType,
    ContextType,
    RequireFields<TenantDataStandardUnmappedArgs, 'tenant_id' | 'category'>
  >;
  mapped?: Resolver<
    Array<ResolversTypes['Mapped']>,
    ParentType,
    ContextType,
    RequireFields<TenantDataStandardMappedArgs, 'tenant_id' | 'category'>
  >;
  target_possible_values?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<TenantDataStandardTarget_Possible_ValuesArgs, 'category'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantDefaultsDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantDefaultsDataValues'] = ResolversParentTypes['TenantDefaultsDataValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  default_unit_of_measurement?: Resolver<
    ResolversTypes['AreaUnit'],
    ParentType,
    ContextType
  >;
  default_unit_of_currency?: Resolver<
    ResolversTypes['CurrencyUnit'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantFacilitiesExperienceAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantFacilitiesExperienceAlertSettingData'] = ResolversParentTypes['TenantFacilitiesExperienceAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['FacilitiesExperienceAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantFeaturesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantFeatures'] = ResolversParentTypes['TenantFeatures'],
> = {
  alerts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  demo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  portscape?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantFinanceDataOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantFinanceDataOptions'] = ResolversParentTypes['TenantFinanceDataOptions'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  actuals_ledger_type_code?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  budget_forecast_ledger_type_code?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gl_accounts_for_exclusion?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  chart_of_account?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cost_category?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantFinanceDataSampleValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantFinanceDataSampleValues'] = ResolversParentTypes['TenantFinanceDataSampleValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  cost_category?: Resolver<
    Maybe<Array<ResolversTypes['FlexibleMappingOptionSampleValues']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantFinanceDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantFinanceDataValues'] = ResolversParentTypes['TenantFinanceDataValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  actuals_ledger_type_code?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  budget_forecast_ledger_type_code?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  gl_accounts_for_exclusion?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  budget_accuracy_variance_ratings?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  chart_of_account?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  flexible_mappings?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantGeoUniversalFiltersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantGeoUniversalFilters'] = ResolversParentTypes['TenantGeoUniversalFilters'],
> = {
  countries?: Resolver<
    Array<ResolversTypes['TenantUniversalFilterGeoValue']>,
    ParentType,
    ContextType
  >;
  states?: Resolver<
    Array<ResolversTypes['TenantUniversalFilterGeoValue']>,
    ParentType,
    ContextType
  >;
  cities?: Resolver<
    Array<ResolversTypes['TenantUniversalFilterGeoValue']>,
    ParentType,
    ContextType
  >;
  properties?: Resolver<
    Array<ResolversTypes['TenantUniversalFilterGeoValue']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantKpiDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantKpiData'] = ResolversParentTypes['TenantKpiData'],
> = {
  tenant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_ovc_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  request_sync?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  triggered_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kpi_data?: Resolver<
    Maybe<ResolversTypes['KpiData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantKpiSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantKpiSettings'] = ResolversParentTypes['TenantKpiSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantKpiSettingsType'],
    ParentType,
    ContextType
  >;
  kpi_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  app_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantLeaseDataOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantLeaseDataOptions'] = ResolversParentTypes['TenantLeaseDataOptions'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  reporting_business_unit?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantLeaseDataSampleValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantLeaseDataSampleValues'] = ResolversParentTypes['TenantLeaseDataSampleValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  reporting_business_unit?: Resolver<
    Maybe<Array<ResolversTypes['FlexibleMappingOptionSampleValues']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantLeaseDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantLeaseDataValues'] = ResolversParentTypes['TenantLeaseDataValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  flexible_mappings?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantLeaseEventAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantLeaseEventAlertSettingData'] = ResolversParentTypes['TenantLeaseEventAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['LeaseEventAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMarketBenchmarkAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantMarketBenchmarkAlertSettingData'] = ResolversParentTypes['TenantMarketBenchmarkAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['MarketBenchmarkAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantOccupancyEfficiencyAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantOccupancyEfficiencyAlertSettingData'] = ResolversParentTypes['TenantOccupancyEfficiencyAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['OccupancyEfficiencyAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantReportsConfigurationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantReportsConfiguration'] = ResolversParentTypes['TenantReportsConfiguration'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tenant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_ovc_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant_reports_config?: Resolver<
    Array<Maybe<ResolversTypes['ReportConfig']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantRoleResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantRole'] = ResolversParentTypes['TenantRole'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role_type?: Resolver<
    ResolversTypes['TenantRoleType'],
    ParentType,
    ContextType
  >;
  role_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSameDayWorkOrderCompletionAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSameDayWorkOrderCompletionAlertSettingData'] = ResolversParentTypes['TenantSameDayWorkOrderCompletionAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['SameDayWorkOrderCompletionAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSelfServeAnalyticsSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSelfServeAnalyticsSettings'] = ResolversParentTypes['TenantSelfServeAnalyticsSettings'],
> = {
  self_serve_analytics_enabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  self_serve_analytics_url?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  self_serve_analytics_updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSnowflakeUrlResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSnowflakeUrl'] = ResolversParentTypes['TenantSnowflakeUrl'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tenant_snowflake_url?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSpaceDataOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSpaceDataOptions'] = ResolversParentTypes['TenantSpaceDataOptions'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  business_unit?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  space_type?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSpaceDataSampleValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSpaceDataSampleValues'] = ResolversParentTypes['TenantSpaceDataSampleValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  business_unit?: Resolver<
    Maybe<Array<ResolversTypes['FlexibleMappingOptionSampleValues']>>,
    ParentType,
    ContextType
  >;
  space_type?: Resolver<
    Maybe<Array<ResolversTypes['FlexibleMappingOptionSampleValues']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSpaceDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSpaceDataValues'] = ResolversParentTypes['TenantSpaceDataValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  standard_occupancy_thresholds?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  standard_vacancy_thresholds?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  flexible_mappings?: Resolver<
    Array<ResolversTypes['SegmentOptions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantSubleaseRiskAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantSubleaseRiskAlertSettingData'] = ResolversParentTypes['TenantSubleaseRiskAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['SubleaseRiskAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantTransactionDataOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantTransactionDataOptions'] = ResolversParentTypes['TenantTransactionDataOptions'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  milestone_identification?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantTransactionDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantTransactionDataValues'] = ResolversParentTypes['TenantTransactionDataValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  milestone_identification?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUniversalFilterGeoValueResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantUniversalFilterGeoValue'] = ResolversParentTypes['TenantUniversalFilterGeoValue'],
> = {
  node?: Resolver<
    ResolversTypes['UniversalFilterGeoNode'],
    ParentType,
    ContextType
  >;
  ancestors?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantWorkOrderVolumeAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantWorkOrderVolumeAlertSettingData'] = ResolversParentTypes['TenantWorkOrderVolumeAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    ResolversTypes['WorkOrderVolumeAlertCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantWorkOrdersDataValuesResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TenantWorkOrdersDataValues'] = ResolversParentTypes['TenantWorkOrdersDataValues'],
> = {
  id?: Resolver<
    ResolversTypes['TenantConfigurationDataType'],
    ParentType,
    ContextType
  >;
  work_order_completion_date?: Resolver<
    ResolversTypes['TenantWorkOrderCompletionDate'],
    ParentType,
    ContextType
  >;
  on_time_completion_ratings?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  pm_on_time_completion_ratings?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  emergency_on_time_completion_ratings?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  customer_satisfaction_ratings?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  invoices_submitted_less_30_days_ratings?: Resolver<
    Array<ResolversTypes['DataThreshold']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrustedReportUrlResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['TrustedReportUrl'] = ResolversParentTypes['TrustedReportUrl'],
> = {
  report_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_valid_ticket?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnmappedResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['Unmapped'] = ResolversParentTypes['Unmapped'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  context_name?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  record_keys?: Resolver<
    Array<ResolversTypes['UnmappedRecordKey']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnmappedRecordKeyResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UnmappedRecordKey'] = ResolversParentTypes['UnmappedRecordKey'],
> = {
  context_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  record_keys?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user_email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_settings?: Resolver<
    ResolversTypes['UserSettings'],
    ParentType,
    ContextType
  >;
  user_full_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_picture_url?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  user_fav_reports?: Resolver<
    Array<Maybe<ResolversTypes['Report']>>,
    ParentType,
    ContextType
  >;
  user_saved_reports?: Resolver<
    Array<Maybe<ResolversTypes['Report']>>,
    ParentType,
    ContextType
  >;
  user_address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  user_comm?: Resolver<
    ResolversTypes['Communications'],
    ParentType,
    ContextType
  >;
  user_kpi_data?: Resolver<
    Array<ResolversTypes['TenantKpiData']>,
    ParentType,
    ContextType
  >;
  user_tutored?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user_admin_settings?: Resolver<
    ResolversTypes['UserAdminSettings'],
    ParentType,
    ContextType
  >;
  user_tenant_settings?: Resolver<
    Array<ResolversTypes['UserTenantSettings']>,
    ParentType,
    ContextType,
    RequireFields<UserUser_Tenant_SettingsArgs, never>
  >;
  user_tenant_regular_settings?: Resolver<
    Array<ResolversTypes['UserTenantRegularSettings']>,
    ParentType,
    ContextType,
    RequireFields<UserUser_Tenant_Regular_SettingsArgs, never>
  >;
  user_personas?: Resolver<
    Array<ResolversTypes['UserPersona']>,
    ParentType,
    ContextType,
    RequireFields<UserUser_PersonasArgs, never>
  >;
  user_profile_type?: Resolver<
    ResolversTypes['UserProfileType'],
    ParentType,
    ContextType
  >;
  user_insights_alert_summary?: Resolver<
    ResolversTypes['InsightsAlertSummary'],
    ParentType,
    ContextType,
    RequireFields<UserUser_Insights_Alert_SummaryArgs, 'where'>
  >;
  user_insights_alerts?: Resolver<
    ResolversTypes['InsightsAlerts'],
    ParentType,
    ContextType,
    RequireFields<UserUser_Insights_AlertsArgs, 'where'>
  >;
  user_insights_alert?: Resolver<
    Maybe<ResolversTypes['InsightsAlertThread']>,
    ParentType,
    ContextType,
    RequireFields<UserUser_Insights_AlertArgs, 'where'>
  >;
  user_ad_groups?: Resolver<
    Array<ResolversTypes['AdGroup']>,
    ParentType,
    ContextType,
    RequireFields<UserUser_Ad_GroupsArgs, 'tenant_id'>
  >;
  user_lifecycle_options?: Resolver<
    Maybe<ResolversTypes['UserLifecycleOptions']>,
    ParentType,
    ContextType
  >;
  user_management_type?: Resolver<
    ResolversTypes['UserManagementType'],
    ParentType,
    ContextType
  >;
  user_status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  user_agreement?: Resolver<
    ResolversTypes['UserAgreement'],
    ParentType,
    ContextType
  >;
  user_type?: Resolver<
    ResolversTypes['UserClassificationType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAdminSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserAdminSettings'] = ResolversParentTypes['UserAdminSettings'],
> = {
  user_updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_created_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_system_roles?: Resolver<
    Array<ResolversTypes['Role']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAgreementResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserAgreement'] = ResolversParentTypes['UserAgreement'],
> = {
  terms_accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  terms_accepted_at?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserAlertSettingData'] = ResolversParentTypes['UserAlertSettingData'],
> = {
  __resolveType: TypeResolveFn<
    | 'WorkOrderVolumeAlertSettingData'
    | 'FacilitiesExperienceAlertSettingData'
    | 'LeaseEventAlertSettingData'
    | 'CovidWorkOrderAlertSettingData'
    | 'MarketBenchmarkAlertSettingData'
    | 'OccupancyEfficiencyAlertSettingData'
    | 'SubleaseRiskAlertSettingData'
    | 'SameDayWorkOrderCompletionAlertSettingData',
    ParentType,
    ContextType
  >;
};

export type UserAlertsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserAlerts'] = ResolversParentTypes['UserAlerts'],
> = {
  tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contact_method_email?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  contact_method_phone?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  contact_frequency?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserCategory'] = ResolversParentTypes['UserCategory'],
> = {
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType>;
  category_descriptions?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserClassificationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserClassification'] = ResolversParentTypes['UserClassification'],
> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  total_users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internal_users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  client_users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  client_corporate_users?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  continuation_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  filters_options?: Resolver<
    Maybe<ResolversTypes['UserClassificationFiltersOptions']>,
    ParentType,
    ContextType
  >;
  filters?: Resolver<
    Maybe<ResolversTypes['UserClassificationFilters']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserClassificationFiltersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserClassificationFilters'] = ResolversParentTypes['UserClassificationFilters'],
> = {
  client_id?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >;
  user_type?: Resolver<
    Maybe<Array<ResolversTypes['UserClassificationType']>>,
    ParentType,
    ContextType
  >;
  user_name?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  user_email?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserClassificationFiltersOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserClassificationFiltersOptions'] = ResolversParentTypes['UserClassificationFiltersOptions'],
> = {
  client?: Resolver<
    Maybe<Array<ResolversTypes['Tenant']>>,
    ParentType,
    ContextType
  >;
  user_type?: Resolver<
    Maybe<Array<ResolversTypes['UserClassificationType']>>,
    ParentType,
    ContextType
  >;
  user_name?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType,
    RequireFields<UserClassificationFiltersOptionsUser_NameArgs, never>
  >;
  user_email?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType,
    RequireFields<UserClassificationFiltersOptionsUser_EmailArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCustomReportResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserCustomReport'] = ResolversParentTypes['UserCustomReport'],
> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  saved_report?: Resolver<ResolversTypes['Report'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInsightsInboxSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserInsightsInboxSettings'] = ResolversParentTypes['UserInsightsInboxSettings'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  insights_inbox_alerts?: Resolver<
    Array<ResolversTypes['InsightsInboxAlerts']>,
    ParentType,
    ContextType
  >;
  insights_selected_filters?: Resolver<
    ResolversTypes['InsightsSelectedFilters'],
    ParentType,
    ContextType
  >;
  insights_selected_sort_order?: Resolver<
    ResolversTypes['InsightsInboxSelectedSortOrder'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLifecycleOptionsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserLifecycleOptions'] = ResolversParentTypes['UserLifecycleOptions'],
> = {
  user_can_be_activated?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  user_password_can_be_reset?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  user_can_be_unlocked?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  user_can_be_unsuspended?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOnboardingResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserOnboarding'] = ResolversParentTypes['UserOnboarding'],
> = {
  status?: Resolver<
    ResolversTypes['UserOnboardingStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPersonaResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserPersona'] = ResolversParentTypes['UserPersona'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile_type?: Resolver<
    ResolversTypes['UserProfileType'],
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tenant_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserSettings'] = ResolversParentTypes['UserSettings'],
> = {
  default_tenant?: Resolver<
    Maybe<ResolversTypes['Tenant']>,
    ParentType,
    ContextType
  >;
  preferred_contact?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  responsibilities?: Resolver<
    Maybe<Array<ResolversTypes['UserTenantKeyword']>>,
    ParentType,
    ContextType
  >;
  work_roles?: Resolver<
    Maybe<Array<ResolversTypes['UserTenantWorkRole']>>,
    ParentType,
    ContextType
  >;
  hidden_reports?: Resolver<
    Maybe<Array<ResolversTypes['Report']>>,
    ParentType,
    ContextType
  >;
  custom_categories?: Resolver<
    Maybe<Array<ResolversTypes['UserCategory']>>,
    ParentType,
    ContextType
  >;
  universal_filters?: Resolver<
    Maybe<Array<ResolversTypes['UserTenantUniversalFilters']>>,
    ParentType,
    ContextType
  >;
  alerts?: Resolver<
    Maybe<Array<ResolversTypes['UserAlerts']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTenantKeywordResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserTenantKeyword'] = ResolversParentTypes['UserTenantKeyword'],
> = {
  tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  keywords?: Resolver<
    Array<ResolversTypes['Keyword']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTenantRegularSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserTenantRegularSettings'] = ResolversParentTypes['UserTenantRegularSettings'],
> = {
  default_data_application?: Resolver<
    Maybe<ResolversTypes['Application']>,
    ParentType,
    ContextType
  >;
  default_profile?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user_alert_rule_builder_settings?: Resolver<
    Maybe<Array<ResolversTypes['UserAlertSettingData']>>,
    ParentType,
    ContextType
  >;
  user_insights_inbox_settings?: Resolver<
    ResolversTypes['UserInsightsInboxSettings'],
    ParentType,
    ContextType
  >;
  user_onboarding?: Resolver<
    Maybe<ResolversTypes['UserOnboarding']>,
    ParentType,
    ContextType
  >;
  user_reports_order?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  user_tenant?: Resolver<ResolversTypes['Tenant'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTenantSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserTenantSettings'] = ResolversParentTypes['UserTenantSettings'],
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user_role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  user_tenant?: Resolver<ResolversTypes['Tenant'], ParentType, ContextType>;
  user_added_at?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_added_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user_updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_updated_by?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_provisioning_status?: Resolver<
    Maybe<ResolversTypes['UserProvisioningStatus']>,
    ParentType,
    ContextType
  >;
  user_provisioning_error?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_provisioned_site_role?: Resolver<
    Maybe<ResolversTypes['UserSiteRole']>,
    ParentType,
    ContextType
  >;
  user_last_login?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_last_logout?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user_provisioned_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTenantUniversalFiltersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserTenantUniversalFilters'] = ResolversParentTypes['UserTenantUniversalFilters'],
> = {
  tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tenant_ovc_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  filters?: Resolver<
    ResolversTypes['UserUniversalFilters'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTenantWorkRoleResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserTenantWorkRole'] = ResolversParentTypes['UserTenantWorkRole'],
> = {
  tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  work_role?: Resolver<ResolversTypes['UserWorkRole'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserUniversalFilterResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserUniversalFilter'] = ResolversParentTypes['UserUniversalFilter'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  filter_ancestors?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  filter_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserUniversalFilterWithMetaResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserUniversalFilterWithMeta'] = ResolversParentTypes['UserUniversalFilterWithMeta'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filters?: Resolver<
    Array<ResolversTypes['UserUniversalFilter']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserUniversalFiltersResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserUniversalFilters'] = ResolversParentTypes['UserUniversalFilters'],
> = {
  countries?: Resolver<
    Maybe<ResolversTypes['UserUniversalFilterWithMeta']>,
    ParentType,
    ContextType
  >;
  states?: Resolver<
    Maybe<ResolversTypes['UserUniversalFilterWithMeta']>,
    ParentType,
    ContextType
  >;
  cities?: Resolver<
    Maybe<ResolversTypes['UserUniversalFilterWithMeta']>,
    ParentType,
    ContextType
  >;
  properties?: Resolver<
    Maybe<ResolversTypes['UserUniversalFilterWithMeta']>,
    ParentType,
    ContextType
  >;
  unit_of_measurement?: Resolver<
    Maybe<ResolversTypes['AreaUnit']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<
    Maybe<ResolversTypes['CurrencyUnit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserWorkRoleResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['UserWorkRole'] = ResolversParentTypes['UserWorkRole'],
> = {
  work_area?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  specialization?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewSchemaResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['ViewSchema'] = ResolversParentTypes['ViewSchema'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  columns?: Resolver<
    Array<ResolversTypes['ColumnSchema']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkAreaResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['WorkArea'] = ResolversParentTypes['WorkArea'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  specializations?: Resolver<
    Array<ResolversTypes['WorkSpecialization']>,
    ParentType,
    ContextType
  >;
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderVolumeAlertCategoryResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['WorkOrderVolumeAlertCategory'] = ResolversParentTypes['WorkOrderVolumeAlertCategory'],
> = {
  internal?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  external?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderVolumeAlertSettingDataResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['WorkOrderVolumeAlertSettingData'] = ResolversParentTypes['WorkOrderVolumeAlertSettingData'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_type?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  categories_excluded?: Resolver<
    ResolversTypes['WorkOrderVolumeAlertCategory'],
    ParentType,
    ContextType
  >;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderVolumeAlertSettingsResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['WorkOrderVolumeAlertSettings'] = ResolversParentTypes['WorkOrderVolumeAlertSettings'],
> = {
  id?: Resolver<
    ResolversTypes['TenantAlertSettingsType'],
    ParentType,
    ContextType
  >;
  alert_tenant_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  alert_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  wov_above_median?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internal_medium?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  internal_high?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  external_medium?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  external_high?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkRoleResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['WorkRole'] = ResolversParentTypes['WorkRole'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkSpecializationResolvers<
  ContextType = ClientSideResolverContext,
  ParentType extends ResolversParentTypes['WorkSpecialization'] = ResolversParentTypes['WorkSpecialization'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['WorkRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = ClientSideResolverContext> = {
  AdGroup?: AdGroupResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AlertTemplateData?: AlertTemplateDataResolvers<ContextType>;
  ApiVersion?: ApiVersionResolvers<ContextType>;
  Application?: ApplicationResolvers<ContextType>;
  ApplicationSchema?: ApplicationSchemaResolvers<ContextType>;
  ApplicationSubcode?: ApplicationSubcodeResolvers<ContextType>;
  BaselineReportControl?: BaselineReportControlResolvers<ContextType>;
  BaselineReportInteractionConfiguration?: BaselineReportInteractionConfigurationResolvers<ContextType>;
  BaselineReportInteractionSection?: BaselineReportInteractionSectionResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  ColumnSchema?: ColumnSchemaResolvers<ContextType>;
  Communications?: CommunicationsResolvers<ContextType>;
  Continent?: ContinentResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CovidWorkOrderAlertCategory?: CovidWorkOrderAlertCategoryResolvers<ContextType>;
  CovidWorkOrderAlertSettingData?: CovidWorkOrderAlertSettingDataResolvers<ContextType>;
  CovidWorkOrderAlertSettings?: CovidWorkOrderAlertSettingsResolvers<ContextType>;
  DataLoadsContainers?: DataLoadsContainersResolvers<ContextType>;
  DataLoadsErrorInfo?: DataLoadsErrorInfoResolvers<ContextType>;
  DataLoadsFileUploadSasUrl?: DataLoadsFileUploadSasUrlResolvers<ContextType>;
  DataLoadsJob?: DataLoadsJobResolvers<ContextType>;
  DataLoadsJobLinks?: DataLoadsJobLinksResolvers<ContextType>;
  DataLoadsJobs?: DataLoadsJobsResolvers<ContextType>;
  DataLoadsRecordInfo?: DataLoadsRecordInfoResolvers<ContextType>;
  DataLoadsResetContainers?: DataLoadsResetContainersResolvers<ContextType>;
  DataThreshold?: DataThresholdResolvers<ContextType>;
  FacilitiesExperienceAlertCategory?: FacilitiesExperienceAlertCategoryResolvers<ContextType>;
  FacilitiesExperienceAlertSettingData?: FacilitiesExperienceAlertSettingDataResolvers<ContextType>;
  FacilitiesExperienceAlertSettings?: FacilitiesExperienceAlertSettingsResolvers<ContextType>;
  FlexibleMappingOptionSampleValues?: FlexibleMappingOptionSampleValuesResolvers<ContextType>;
  GlobalData?: GlobalDataResolvers<ContextType>;
  GlobalView?: GlobalViewResolvers<ContextType>;
  GroupMapping?: GroupMappingResolvers<ContextType>;
  InsightsAlert?: InsightsAlertResolvers<ContextType>;
  InsightsAlertBaseWorkOrderTemplateData?: InsightsAlertBaseWorkOrderTemplateDataResolvers<ContextType>;
  InsightsAlertHistory?: InsightsAlertHistoryResolvers<ContextType>;
  InsightsAlertHistoryRecord?: InsightsAlertHistoryRecordResolvers<ContextType>;
  InsightsAlertLeaseEventTemplateData?: InsightsAlertLeaseEventTemplateDataResolvers<ContextType>;
  InsightsAlertMarketBenchmarkTemplateData?: InsightsAlertMarketBenchmarkTemplateDataResolvers<ContextType>;
  InsightsAlertMarketBenchmarkTemplateDataPredictionRecord?: InsightsAlertMarketBenchmarkTemplateDataPredictionRecordResolvers<ContextType>;
  InsightsAlertMarketBenchmarkTemplateDataSupportingDataPoint?: InsightsAlertMarketBenchmarkTemplateDataSupportingDataPointResolvers<ContextType>;
  InsightsAlertOccupancyEfficiencyTemplateData?: InsightsAlertOccupancyEfficiencyTemplateDataResolvers<ContextType>;
  InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecord?: InsightsAlertOccupancyEfficiencyTemplateDataHeadcountDensityRateRecordResolvers<ContextType>;
  InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecord?: InsightsAlertOccupancyEfficiencyTemplateDataOccupancyRateRecordResolvers<ContextType>;
  InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecord?: InsightsAlertOccupancyEfficiencyTemplateDataVacancyRateRecordResolvers<ContextType>;
  InsightsAlertRoiData?: InsightsAlertRoiDataResolvers<ContextType>;
  InsightsAlertSameDayWorkOrderCompletionTemplateData?: InsightsAlertSameDayWorkOrderCompletionTemplateDataResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateData?: InsightsAlertSubleaseRiskTemplateDataResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecord?: InsightsAlertSubleaseRiskTemplateDataContractionOptionsRecordResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecord?: InsightsAlertSubleaseRiskTemplateDataMainlyParkingLeasesRecordResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecord?: InsightsAlertSubleaseRiskTemplateDataMonthToMonthTenuresRecordResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecord?: InsightsAlertSubleaseRiskTemplateDataNaturalExpiryRecordResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecord?: InsightsAlertSubleaseRiskTemplateDataSubleaseWithExpiryRecordResolvers<ContextType>;
  InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecord?: InsightsAlertSubleaseRiskTemplateDataTerminationOptionsRecordResolvers<ContextType>;
  InsightsAlertSummary?: InsightsAlertSummaryResolvers<ContextType>;
  InsightsAlertSummaryCountsByStatus?: InsightsAlertSummaryCountsByStatusResolvers<ContextType>;
  InsightsAlertTemplateData?: InsightsAlertTemplateDataResolvers<ContextType>;
  InsightsAlertThread?: InsightsAlertThreadResolvers<ContextType>;
  InsightsAlertUserHistory?: InsightsAlertUserHistoryResolvers<ContextType>;
  InsightsAlertUserHistoryRecord?: InsightsAlertUserHistoryRecordResolvers<ContextType>;
  InsightsAlertUserInfo?: InsightsAlertUserInfoResolvers<ContextType>;
  InsightsAlertWorkOrderVolumeTemplateData?: InsightsAlertWorkOrderVolumeTemplateDataResolvers<ContextType>;
  InsightsAlerts?: InsightsAlertsResolvers<ContextType>;
  InsightsInboxAlerts?: InsightsInboxAlertsResolvers<ContextType>;
  InsightsInboxSelectedSortOrder?: InsightsInboxSelectedSortOrderResolvers<ContextType>;
  InsightsSelectedFilters?: InsightsSelectedFiltersResolvers<ContextType>;
  Keyword?: KeywordResolvers<ContextType>;
  KpiData?: KpiDataResolvers<ContextType>;
  LeaseEventAlertCategory?: LeaseEventAlertCategoryResolvers<ContextType>;
  LeaseEventAlertSettingData?: LeaseEventAlertSettingDataResolvers<ContextType>;
  LeaseEventAlertSettings?: LeaseEventAlertSettingsResolvers<ContextType>;
  Log?: LogResolvers<ContextType>;
  Mapped?: MappedResolvers<ContextType>;
  MarketBenchmarkAlertCategory?: MarketBenchmarkAlertCategoryResolvers<ContextType>;
  MarketBenchmarkAlertSettingData?: MarketBenchmarkAlertSettingDataResolvers<ContextType>;
  MarketBenchmarkAlertSettings?: MarketBenchmarkAlertSettingsResolvers<ContextType>;
  MoveCustomReport?: MoveCustomReportResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OccupancyEfficiencyAlertCategory?: OccupancyEfficiencyAlertCategoryResolvers<ContextType>;
  OccupancyEfficiencyAlertSettingData?: OccupancyEfficiencyAlertSettingDataResolvers<ContextType>;
  OccupancyEfficiencyAlertSettings?: OccupancyEfficiencyAlertSettingsResolvers<ContextType>;
  PostMappingDataStandardResponse?: PostMappingDataStandardResponseResolvers<ContextType>;
  PropertyData?: PropertyDataResolvers<ContextType>;
  PropertyDataRecord?: PropertyDataRecordResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  ReleasesRecord?: ReleasesRecordResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  ReportConfig?: ReportConfigResolvers<ContextType>;
  ReportControl?: ReportControlResolvers<ContextType>;
  ReportControlStaticListItem?: ReportControlStaticListItemResolvers<ContextType>;
  ReportInteractionConfiguration?: ReportInteractionConfigurationResolvers<ContextType>;
  ReportInteractionSection?: ReportInteractionSectionResolvers<ContextType>;
  ReportMapping?: ReportMappingResolvers<ContextType>;
  ReportMetadata?: ReportMetadataResolvers<ContextType>;
  ReportMetadataMapping?: ReportMetadataMappingResolvers<ContextType>;
  ReportMetadataSourceData?: ReportMetadataSourceDataResolvers<ContextType>;
  ReportModule?: ReportModuleResolvers<ContextType>;
  ReportModuleCategory?: ReportModuleCategoryResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  RoleDashboard?: RoleDashboardResolvers<ContextType>;
  RoleDashboards?: RoleDashboardsResolvers<ContextType>;
  RoleInsightsAlert?: RoleInsightsAlertResolvers<ContextType>;
  RoleInsightsAlerts?: RoleInsightsAlertsResolvers<ContextType>;
  RoleOtherFeature?: RoleOtherFeatureResolvers<ContextType>;
  RoleOtherSubFeature?: RoleOtherSubFeatureResolvers<ContextType>;
  RoleUniversalFilters?: RoleUniversalFiltersResolvers<ContextType>;
  SameDayWorkOrderCompletionAlertCategory?: SameDayWorkOrderCompletionAlertCategoryResolvers<ContextType>;
  SameDayWorkOrderCompletionAlertSettingData?: SameDayWorkOrderCompletionAlertSettingDataResolvers<ContextType>;
  SameDayWorkOrderCompletionAlertSettings?: SameDayWorkOrderCompletionAlertSettingsResolvers<ContextType>;
  SearchedUserResult?: SearchedUserResultResolvers<ContextType>;
  SegmentOptions?: SegmentOptionsResolvers<ContextType>;
  SubleaseRiskAlertCategory?: SubleaseRiskAlertCategoryResolvers<ContextType>;
  SubleaseRiskAlertSettingData?: SubleaseRiskAlertSettingDataResolvers<ContextType>;
  SubleaseRiskAlertSettings?: SubleaseRiskAlertSettingsResolvers<ContextType>;
  Tenant?: TenantResolvers<ContextType>;
  TenantAlertSettingData?: TenantAlertSettingDataResolvers<ContextType>;
  TenantAlertSettings?: TenantAlertSettingsResolvers<ContextType>;
  TenantApplication?: TenantApplicationResolvers<ContextType>;
  TenantConfigurationData?: TenantConfigurationDataResolvers<ContextType>;
  TenantConfigurationDataOptions?: TenantConfigurationDataOptionsResolvers<ContextType>;
  TenantConfigurationDataSampleValues?: TenantConfigurationDataSampleValuesResolvers<ContextType>;
  TenantConfigurationDataValues?: TenantConfigurationDataValuesResolvers<ContextType>;
  TenantCovidWorkOrderAlertSettingData?: TenantCovidWorkOrderAlertSettingDataResolvers<ContextType>;
  TenantCustomReportsSettings?: TenantCustomReportsSettingsResolvers<ContextType>;
  TenantCustomReportsSetup?: TenantCustomReportsSetupResolvers<ContextType>;
  TenantDataStandard?: TenantDataStandardResolvers<ContextType>;
  TenantDefaultsDataValues?: TenantDefaultsDataValuesResolvers<ContextType>;
  TenantFacilitiesExperienceAlertSettingData?: TenantFacilitiesExperienceAlertSettingDataResolvers<ContextType>;
  TenantFeatures?: TenantFeaturesResolvers<ContextType>;
  TenantFinanceDataOptions?: TenantFinanceDataOptionsResolvers<ContextType>;
  TenantFinanceDataSampleValues?: TenantFinanceDataSampleValuesResolvers<ContextType>;
  TenantFinanceDataValues?: TenantFinanceDataValuesResolvers<ContextType>;
  TenantGeoUniversalFilters?: TenantGeoUniversalFiltersResolvers<ContextType>;
  TenantKpiData?: TenantKpiDataResolvers<ContextType>;
  TenantKpiSettings?: TenantKpiSettingsResolvers<ContextType>;
  TenantLeaseDataOptions?: TenantLeaseDataOptionsResolvers<ContextType>;
  TenantLeaseDataSampleValues?: TenantLeaseDataSampleValuesResolvers<ContextType>;
  TenantLeaseDataValues?: TenantLeaseDataValuesResolvers<ContextType>;
  TenantLeaseEventAlertSettingData?: TenantLeaseEventAlertSettingDataResolvers<ContextType>;
  TenantMarketBenchmarkAlertSettingData?: TenantMarketBenchmarkAlertSettingDataResolvers<ContextType>;
  TenantOccupancyEfficiencyAlertSettingData?: TenantOccupancyEfficiencyAlertSettingDataResolvers<ContextType>;
  TenantReportsConfiguration?: TenantReportsConfigurationResolvers<ContextType>;
  TenantRole?: TenantRoleResolvers<ContextType>;
  TenantSameDayWorkOrderCompletionAlertSettingData?: TenantSameDayWorkOrderCompletionAlertSettingDataResolvers<ContextType>;
  TenantSelfServeAnalyticsSettings?: TenantSelfServeAnalyticsSettingsResolvers<ContextType>;
  TenantSnowflakeUrl?: TenantSnowflakeUrlResolvers<ContextType>;
  TenantSpaceDataOptions?: TenantSpaceDataOptionsResolvers<ContextType>;
  TenantSpaceDataSampleValues?: TenantSpaceDataSampleValuesResolvers<ContextType>;
  TenantSpaceDataValues?: TenantSpaceDataValuesResolvers<ContextType>;
  TenantSubleaseRiskAlertSettingData?: TenantSubleaseRiskAlertSettingDataResolvers<ContextType>;
  TenantTransactionDataOptions?: TenantTransactionDataOptionsResolvers<ContextType>;
  TenantTransactionDataValues?: TenantTransactionDataValuesResolvers<ContextType>;
  TenantUniversalFilterGeoValue?: TenantUniversalFilterGeoValueResolvers<ContextType>;
  TenantWorkOrderVolumeAlertSettingData?: TenantWorkOrderVolumeAlertSettingDataResolvers<ContextType>;
  TenantWorkOrdersDataValues?: TenantWorkOrdersDataValuesResolvers<ContextType>;
  TrustedReportUrl?: TrustedReportUrlResolvers<ContextType>;
  Unmapped?: UnmappedResolvers<ContextType>;
  UnmappedRecordKey?: UnmappedRecordKeyResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAdminSettings?: UserAdminSettingsResolvers<ContextType>;
  UserAgreement?: UserAgreementResolvers<ContextType>;
  UserAlertSettingData?: UserAlertSettingDataResolvers<ContextType>;
  UserAlerts?: UserAlertsResolvers<ContextType>;
  UserCategory?: UserCategoryResolvers<ContextType>;
  UserClassification?: UserClassificationResolvers<ContextType>;
  UserClassificationFilters?: UserClassificationFiltersResolvers<ContextType>;
  UserClassificationFiltersOptions?: UserClassificationFiltersOptionsResolvers<ContextType>;
  UserCustomReport?: UserCustomReportResolvers<ContextType>;
  UserInsightsInboxSettings?: UserInsightsInboxSettingsResolvers<ContextType>;
  UserLifecycleOptions?: UserLifecycleOptionsResolvers<ContextType>;
  UserOnboarding?: UserOnboardingResolvers<ContextType>;
  UserPersona?: UserPersonaResolvers<ContextType>;
  UserSettings?: UserSettingsResolvers<ContextType>;
  UserTenantKeyword?: UserTenantKeywordResolvers<ContextType>;
  UserTenantRegularSettings?: UserTenantRegularSettingsResolvers<ContextType>;
  UserTenantSettings?: UserTenantSettingsResolvers<ContextType>;
  UserTenantUniversalFilters?: UserTenantUniversalFiltersResolvers<ContextType>;
  UserTenantWorkRole?: UserTenantWorkRoleResolvers<ContextType>;
  UserUniversalFilter?: UserUniversalFilterResolvers<ContextType>;
  UserUniversalFilterWithMeta?: UserUniversalFilterWithMetaResolvers<ContextType>;
  UserUniversalFilters?: UserUniversalFiltersResolvers<ContextType>;
  UserWorkRole?: UserWorkRoleResolvers<ContextType>;
  ViewSchema?: ViewSchemaResolvers<ContextType>;
  WorkArea?: WorkAreaResolvers<ContextType>;
  WorkOrderVolumeAlertCategory?: WorkOrderVolumeAlertCategoryResolvers<ContextType>;
  WorkOrderVolumeAlertSettingData?: WorkOrderVolumeAlertSettingDataResolvers<ContextType>;
  WorkOrderVolumeAlertSettings?: WorkOrderVolumeAlertSettingsResolvers<ContextType>;
  WorkRole?: WorkRoleResolvers<ContextType>;
  WorkSpecialization?: WorkSpecializationResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = ClientSideResolverContext> =
  Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = ClientSideResolverContext> = {
  client?: ClientDirectiveResolver<any, any, ContextType>;
  connection?: ConnectionDirectiveResolver<any, any, ContextType>;
};

/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = ClientSideResolverContext> =
  DirectiveResolvers<ContextType>;
