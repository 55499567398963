import { format } from 'date-fns';
import { utils } from '@jll-labs/azara-ui-components';
import { useGetDownloadTemplateSasUrl } from './useGetDownloadTemplateSasUrl';

export const useDownloadTemplate = (tenantId: string) => {
  const { getDownloadTemplateSasUrl } = useGetDownloadTemplateSasUrl(tenantId);

  return {
    downloadTemplate: async (application: string) => {
      const template = await getDownloadTemplateSasUrl(application);
      const sasUrl = template.data?.getDownloadTemplateSasUrl;
      if (sasUrl) {
        const file = await utils.downloadFile(sasUrl);
        if (file) {
          utils.saveFile(
            file,
            `Azara_SDM_${application}_Upload_Template_${format(
              new Date(),
              'yyyyMMddHHmmss',
            )}.zip`,
          );
        }
      }
    },
  };
};
