import React from 'react';
import { Typography, Grid, CircularProgress } from '@mui/material';
import { SubHeader } from '@components/typography';
import { useGetUserCurrentUniversalFilters } from '@apollo-red/hooks';

const AlertProperties: React.FC = () => {
  const { universalFilters } = useGetUserCurrentUniversalFilters();

  if (!universalFilters) {
    return <CircularProgress style={{ margin: '0 auto' }} size={24} />;
  }

  const { unit_of_measurement: _u, currency: _c, ...rest } = universalFilters;

  return (
    <Grid>
      <SubHeader size={14} marginBottom={2}>
        {
          'Note: Please setup / update universal filters for inclusion / exclusion of properties'
        }
      </SubHeader>
      {Object.entries(rest).map(([key, filter]) => {
        const listName = filter.filters.map((value, index) => {
          const name = value.filter_value;
          return (
            <span key={filter.name + index}>
              {index > 0 ? ', ' + name : name}
            </span>
          );
        });
        return (
          <div key={key}>
            <Typography>
              <SubHeader bold={true} component="span" transform="capitalize">
                {key + ': '}
              </SubHeader>
              {filter.filters[0] ? listName : 'All'}
            </Typography>
          </div>
        );
      })}
    </Grid>
  );
};

export default AlertProperties;
