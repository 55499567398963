import * as React from 'react';
import { useLoginUser } from '@apollo-red/hooks';

interface UseProvisionUserArgs {
  userId?: string;
  tenantOvcId?: string;
}

export const useProvisionUser = ({
  userId,
  tenantOvcId,
}: UseProvisionUserArgs) => {
  const [isProvisioningUser, setUserProvisioning] = React.useState(false);
  const { loginUser } = useLoginUser();

  React.useEffect(() => {
    if (!userId) {
      return;
    }

    const login = async () => {
      setUserProvisioning(true);

      try {
        await loginUser({ userId, tenantOvcId });
      } catch (e) {
        throw new Error('Could not login user');
      }

      setUserProvisioning(false);
    };

    login();
  }, [loginUser, tenantOvcId, userId]);

  return isProvisioningUser;
};
