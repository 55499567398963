export const ALERT_MAX_CREATED = new Date(9999, 11, 31).getTime();

export const ALERT_POLLING_INTERVAL_SECONDS = parseInt(
  process.env.ALERT_POLLING_INTERVAL_SECONDS ?? '15',
  10,
);

export const ALERTS_FETCH_PAGE_SIZE = parseInt(
  process.env.ALERTS_FETCH_PAGE_SIZE ?? '50',
  10,
);
