import * as React from 'react';
import Grid from '@mui/material/Grid';
import { utils, DragHandle } from '@jll-labs/azara-ui-components';
import type { DndConfig } from '@jll-labs/azara-ui-components/types/utils';
import { useToggleFavorite, ReportExtended } from '@apollo-red/hooks';
import Tile from '../tile';
import { DashboardsPopupTypes } from './DashboardsPopup';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { Waypoint } from 'react-waypoint';

const { useChildDnd, useToggleValue } = utils;

interface Props extends DndConfig<ReportExtended> {
  report: ReportExtended;
  categoryId: string;
  handleDialogOpen: (type: DashboardsPopupTypes, reportId: string) => void;
  draggable?: boolean;
  dashboardRoutes: DashboardRouteProps;
}

const TileGridItem: React.FC<Props> = ({
  report,
  categoryId,
  handleDialogOpen,
  onDragBegin,
  onItemDropEnd,
  updateElementsOrder,
  draggable,
  dashboardRoutes,
}) => {
  const { toggleFavorite } = useToggleFavorite();
  const [isTileVisible, toggleIsTileVisible] = useToggleValue(false);
  const { drag, drop, isDragging, isOver } = useChildDnd({
    element: report,
    dndType: 'Tile',
    onDragBegin,
    onDropEnd: onItemDropEnd,
    updateElementsOrder,
  });

  return (
    <Waypoint
      key={report.id}
      onEnter={() => {
        if (!isTileVisible) {
          toggleIsTileVisible();
        }
      }}
    >
      <Grid item={true} key={report.id} xs={12} sm={6} lg={4} xl={3}>
        <Tile
          categoryId={categoryId}
          toggleFavorite={() => toggleFavorite(report)}
          handleDialogOpen={handleDialogOpen}
          report={report}
          ref={drop}
          dragHandle={
            draggable && (
              <DragHandle fontSize="large" ref={drag} viewBox="-7 0 24 24" />
            )
          }
          isDragging={isDragging}
          isOver={isOver}
          dashboardRoutes={dashboardRoutes}
          skipImage={!isTileVisible}
        />
      </Grid>
    </Waypoint>
  );
};

export default TileGridItem;
