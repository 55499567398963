import { User } from '@apollo-red/hooks';

export enum MenuItems {
  Dashboards = 'dashboards',
  AzaraDashboards = 'azara_dashboards',
  PATDashboards = 'pat_dashboards',
  InsightsAlerts = 'insights_alerts',
  Settings = 'my_account',
  ProductSettings = 'product_settings',
  ClientSettings = 'client_settings',
  DataManagement = 'data_management',
  JLLPortscape = 'jll_portscape',
  SelfServeAnalytics = 'self_serve_analytics',
}

export enum MenuAlertsSubItems {
  All = 'all',
  Read = 'read',
  Unread = 'unread',
  SavedForLater = 'saved_for_later',
  DataQualityReview = 'data_quality_review',
  Completed = 'completed',
  AssignToMe = 'assign_to_me',
}

export enum MenuSettingsSubItems {
  AccountSettings = 'account_settings',
  DashboardSettings = 'dashboard_settings',
  AlertSettings = 'alert_settings',
}

export enum MenuClientSettingsSubItems {
  UserManagement = 'user_management',
  RoleManagement = 'role_management',
  CustomReports = 'custom_reports',
  ConfigDataSetup = 'config_data_setup',
  KeywordManagement = 'keyword_management',
  PatReportsConfig = 'pat_reports_config',
  DashboardSettings = 'dashboard_settings',
}

export enum MenuDataManagementSubItems {
  DataLoads = 'data_loads',
  DataStandards = 'data_standards',
  DataViews = 'data_views',
  PropertyData = 'property_data',
}

export enum MenuProductSettingsSubItems {
  Clients = 'clients',
  Applications = 'applications',
  Roles = 'roles',
  UserClassification = 'user_classification',
  KeywordManagement = 'keyword_management',
  EnvironmentSetup = 'environment_setup',
}

export type RoleMenuItems = Exclude<
  MenuItems,
  MenuItems.PATDashboards | MenuItems.AzaraDashboards
>;
const {
  AzaraDashboards: _____,
  PATDashboards: ______,
  ...roleMenuItems
} = MenuItems;
export const RoleMenuItems = roleMenuItems;

export type RoleOtherMenuItems = Exclude<
  MenuItems,
  | MenuItems.InsightsAlerts
  | MenuItems.Dashboards
  | MenuItems.AzaraDashboards
  | MenuItems.PATDashboards
>;
const {
  InsightsAlerts: _,
  Dashboards: __,
  AzaraDashboards: ___,
  PATDashboards: ____,
  ...otherRoleMenuItems
} = MenuItems;
export const RoleOtherMenuItems = otherRoleMenuItems;

export const RoleOtherSubMenuItems = {
  ...MenuSettingsSubItems,
  ...MenuClientSettingsSubItems,
  ...MenuDataManagementSubItems,
  ...MenuProductSettingsSubItems,
};

export type RoleOtherSubMenuItems =
  | MenuSettingsSubItems
  | MenuDataManagementSubItems
  | MenuProductSettingsSubItems
  | MenuClientSettingsSubItems;

export enum MenuItemsLabels {
  Dashboards = 'Dashboards',
  AzaraDashboards = 'Core Dashboards',
  PATDashboards = 'Legacy Dashboards',
  Settings = 'My Account',
  ProductSettings = 'Product Settings',
  ClientSettings = 'Client Settings',
  DataManagement = 'Data Management',
  InsightsAlerts = 'Alerts',
  JLLPortscape = 'JLL Portscape',
  SelfServeAnalytics = 'Self Serve Analytics',
}

export interface MenuSubItem {
  id: string;
  label: string;
  link: string;
  isTestFeature?: boolean;
  restrict?: (user: User, tenantId: string) => boolean;
}

export interface NavItemsProps {
  dashboards: MenuSubItem[];
  PATDashboards: MenuSubItem[];
}

export interface MenuItemsInfo {
  unassignedUsers: number;
  isSidebarExpanded: boolean;
  insightsAllAlertsCount: number;
  insightsUnreadAlertsCount: number;
}

export interface MenuItem {
  id: MenuItems;
  label: MenuItemsLabels;
  short: JSX.Element;
  expanded: JSX.Element;
  expandable?: boolean;
  link: string;
  path: string;
  navItems: (props?: NavItemsProps) => MenuSubItem[];
  restrict?: (user: User, tenantId: string) => boolean;
}
