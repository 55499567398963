import * as React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Button,
  PopoverButton,
  TextField,
} from '@jll-labs/azara-ui-components';
import { InsightsAlert, InsightsAlertStatusUpdate } from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { alertStatusTranslationKeys } from '../utils';

interface Props {
  activeAlert: InsightsAlert;
  type: InsightsAlertStatusUpdate;
  handleStatusChange: (
    threadId: string,
    status: InsightsAlertStatusUpdate,
    comment: string,
  ) => void;
  getButton?: ({ handleBtnClick }) => React.ReactNode;
  onClose?: () => void;
  startIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
}

const OptionalCommentButton: React.FC<Props> = ({
  activeAlert,
  type,
  handleStatusChange,
  getButton,
  onClose,
  startIcon,
  variant = 'contained',
}) => {
  const [commentValue, setCommentValue] = React.useState('');
  const { t } = useTranslation();
  const text = t(
    type !== InsightsAlertStatusUpdate.Closed
      ? alertStatusTranslationKeys[type]
      : 'close',
  );
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  const handleCommentField = event => {
    setCommentValue(event.target.value);
  };

  const handleSendComment = handleClose => async () => {
    trackInsightsAlertEvent('alertCloseAlert', {
      alertThreadId: activeAlert.thread_id ?? '',
      alertType: activeAlert?.alert_type ?? '',
    });

    handleStatusChange(activeAlert.thread_id, type, commentValue);
    handleClose();
  };

  return (
    <PopoverButton
      onClose={onClose}
      getButton={({ handleBtnClick }) =>
        getButton ? (
          getButton({ handleBtnClick })
        ) : (
          <Button
            variant={variant}
            color="primary"
            onClick={handleBtnClick}
            startIcon={startIcon}
          >
            {text}
          </Button>
        )
      }
    >
      {({ handleClose }) => (
        <Box p={2} width="20vw">
          <Box mb={1}>
            <TextField
              value={commentValue}
              onChange={handleCommentField}
              label={'Optional Comment'}
            />
          </Box>
          <Button
            variant="outlined"
            color="primary"
            fullWidth={true}
            onClick={handleSendComment(handleClose)}
          >
            {'OK'}
          </Button>
        </Box>
      )}
    </PopoverButton>
  );
};

export default OptionalCommentButton;
