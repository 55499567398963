import * as React from 'react';
import { UserAvatarIcon, Tooltip } from '@jll-labs/azara-ui-components';
import { Box } from '@mui/material';
import { useAlertTableRowStyles } from '../alertTableRows/useAlertTableRowStyles';

interface Props {
  userName: string;
}

const AlertAssignedUser: React.FC<Props> = ({ userName }) => {
  const styles = useAlertTableRowStyles();

  if (!userName) {
    return null;
  }

  return (
    <Box sx={styles.iconContainer}>
      <Tooltip title={userName}>
        <UserAvatarIcon active={true} key="assignedUser" fontSize="small" />
      </Tooltip>
    </Box>
  );
};

export default AlertAssignedUser;
