import * as React from 'react';
import {
  HistoryList,
  HistoryListItem,
  utils,
} from '@jll-labs/azara-ui-components';
import { InsightsAlertHistoryRecord } from '@apollo-red/hooks';

const { getFormattedDate } = utils;

const getAnchorItem = (createdAt: string, reason: string) => (
  <div style={{ width: '28vw' }}>
    <HistoryListItem
      dateRange={getFormattedDate(
        new Date(parseInt(createdAt, 10)),
        undefined,
        'MMM dd',
      )}
      header={
        <div
          dangerouslySetInnerHTML={{
            __html: reason,
          }}
        ></div>
      }
    />
  </div>
);

const getListItems = (listItems: InsightsAlertHistoryRecord[]) => {
  return listItems.map((item, index) => {
    const { created_at, reason } = item;
    return (
      <div key={index} style={{ width: '28vw' }}>
        <HistoryListItem
          dateRange={getFormattedDate(
            new Date(parseInt(created_at, 10)),
            undefined,
            'MMM dd',
          )}
          header={
            <div
              dangerouslySetInnerHTML={{
                __html: reason,
              }}
            ></div>
          }
        />
      </div>
    );
  });
};

interface Props {
  count: number;
  history?: InsightsAlertHistoryRecord[];
}

const AlertHistory: React.FC<Props> = ({ history, count }) => {
  if (history && history?.length > 0) {
    const [anchorItem, ...otherItems] = history;

    const anchorListItem = getAnchorItem(
      anchorItem.created_at,
      anchorItem.reason,
    );

    const otherListItems = otherItems && getListItems(otherItems);
    return (
      <div>
        <HistoryList
          anchorItem={anchorListItem}
          listItems={otherListItems}
          count={count > 1 ? count - 1 : undefined}
        />
      </div>
    );
  }

  return null;
};

export default AlertHistory;
