import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_ALERT_STATUS } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import {
  InsightsAlertStatusUpdate,
  InsightsAlertUserHistory,
  InsightsAlertUserHistoryRecord,
  InsightsAlertUserHistoryActionPerformed,
} from '../insightsAlerts.types';

const getUserStatusToAlertStatus = (userStatus: InsightsAlertStatusUpdate) => {
  if (userStatus === InsightsAlertStatusUpdate.Closed) {
    return 'closed';
  } else if (userStatus === InsightsAlertStatusUpdate.DataQualityReview) {
    return 'data_quality_review';
  }
  return 'active';
};

const alertStatusToActionPerformed = (status: InsightsAlertStatusUpdate) => {
  switch (status) {
    case InsightsAlertStatusUpdate.Closed: {
      return InsightsAlertUserHistoryActionPerformed.Closed;
    }
    case InsightsAlertStatusUpdate.DataQualityReview: {
      return InsightsAlertUserHistoryActionPerformed.DataQualityReview;
    }
    case InsightsAlertStatusUpdate.SaveForLater: {
      return InsightsAlertUserHistoryActionPerformed.DismissSaveForLater;
    }
    case InsightsAlertStatusUpdate.UnableToAction: {
      return InsightsAlertUserHistoryActionPerformed.DismissUnableToAction;
    }
    default: {
      throw new Error();
    }
  }
};

export const useSetInsightsAlertStatus = (onDeselectAlert: () => void) => {
  const { userId, tenantId, userName, tenantOvcId } =
    useGetInsightsInboxAlertState();
  const [setInsightsAlertStatus] = useAppMutation<
    SchemaTypes.SetInsightsAlertStatusMutation,
    SchemaTypes.SetInsightsAlertStatusMutationVariables
  >(SET_INSIGHTS_ALERT_STATUS);

  return {
    setInsightsAlertStatus: (
      threadId: string,
      status: InsightsAlertStatusUpdate,
      comment?: string,
    ) => {
      const timeStamp = `${new Date().getTime()}`;
      const commentText = comment || undefined;

      setInsightsAlertStatus({
        variables: {
          where: {
            tenant_id: tenantId,
            created_at: timeStamp,
            status,
            thread_id: threadId,
            user_id: userId,
            comment: commentText,
          },
        },
        optimisticResponse: {
          setInsightsAlertStatus: true,
        },
        update(cache) {
          const cacheId = cache.identify({
            __typename: 'InsightsAlert',
            thread_id: threadId,
          });

          cache.modify({
            id: cacheId,
            fields: {
              user_status() {
                return status;
              },
              alert_status() {
                return getUserStatusToAlertStatus(status);
              },
            },
            optimistic: true,
          });

          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlertThread',
              thread_id: threadId,
            }),
            fields: {
              alert_user_history(existing: InsightsAlertUserHistory) {
                const userHistory: InsightsAlertUserHistoryRecord = {
                  commented_at: timeStamp,
                  action_performed: alertStatusToActionPerformed(status),
                  user_name: userName,
                  comment: commentText ?? '',
                };

                return {
                  ...existing,
                  user_history: [userHistory, ...existing.user_history],
                };
              },
            },
            optimistic: true,
          });

          if (status === InsightsAlertStatusUpdate.UnableToAction) {
            cache.modify({
              id: cache.identify({
                __typename: 'InsightsAlerts',
                client_id: tenantOvcId,
                user_id: userId,
              }),
              fields: {
                alerts(existing, { readField }) {
                  const newAlerts = existing?.filter(oldAlert => {
                    return readField('thread_id', oldAlert) !== threadId;
                  });
                  return [...newAlerts];
                },
              },
            });
            cache.gc();
          }
        },
      });

      if (status === InsightsAlertStatusUpdate.UnableToAction) {
        onDeselectAlert();
      }
    },
  };
};
