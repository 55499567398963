import * as React from 'react';
import TilesGrid from './TilesGrid';
import TilesTable from './TilesTable';
import NoDashboards from './NoDashboards';
import {
  ReportExtended,
  useGetUserInfo,
  useDeleteSavedViewFromUser,
  useAddHidden,
  useGetUserHiddenReportsIds,
  useGetUserDefaultTenantId,
  useUpdateUserTenantRegularSettings,
} from '@apollo-red/hooks';
import DashboardsPopup, { DashboardsPopupTypes } from './DashboardsPopup';
import { DashboardRouteProps, Display } from '@pages/dashboards/types';

interface Props {
  reports: ReportExtended[];
  categoryId: string;
  handleDeleteTile: () => () => void;
  displayMode: Display;
  deleteMode: boolean;
  draggable?: boolean;
  dashboardRoutes: DashboardRouteProps;
}

const TilesContent: React.FC<Props> = ({
  reports,
  categoryId,
  handleDeleteTile,
  deleteMode,
  displayMode,
  draggable,
  dashboardRoutes,
}) => {
  const { user } = useGetUserInfo();
  const { addHidden } = useAddHidden();
  const { deleteSavedViewFromUser } = useDeleteSavedViewFromUser();
  const { hiddenReportsIds } = useGetUserHiddenReportsIds();

  const tenantId = useGetUserDefaultTenantId();

  const [type, setType] = React.useState<DashboardsPopupTypes>(
    DashboardsPopupTypes.Closed,
  );
  const [reportId, setReportId] = React.useState('');

  const { updateUserTenantRegularSettings } =
    useUpdateUserTenantRegularSettings();

  const updateReports = React.useCallback(
    userReportOrder => {
      updateUserTenantRegularSettings(tenantId, {
        user_reports_order: userReportOrder,
      });
    },
    [updateUserTenantRegularSettings, tenantId],
  );

  const handleConfirm = () => {
    switch (type) {
      case DashboardsPopupTypes.Hide:
        addHidden(reportId, user.id, hiddenReportsIds);
        setType(DashboardsPopupTypes.Closed);
        break;
      case DashboardsPopupTypes.DeleteSavedView:
        deleteSavedViewFromUser(reportId, user);
        setType(DashboardsPopupTypes.Closed);
        break;
    }
  };

  const handleClose = () => {
    setType(DashboardsPopupTypes.Closed);
  };

  const handleDialogOpen = (type: DashboardsPopupTypes, reportId: string) => {
    setType(type);
    setReportId(reportId);
  };

  if (reports.length === 0) {
    return <NoDashboards />;
  }

  const reportsContent =
    displayMode === Display.grid ? (
      <TilesGrid
        categoryId={categoryId}
        reports={reports}
        handleDialogOpen={handleDialogOpen}
        onDropEnd={updateReports}
        draggable={draggable}
        dashboardRoutes={dashboardRoutes}
      />
    ) : (
      <TilesTable
        categoryId={categoryId}
        deleteMode={deleteMode}
        reports={reports}
        deleteTile={handleDeleteTile}
        handleDialogOpen={handleDialogOpen}
        onDropEnd={updateReports}
        draggable={draggable}
        dashboardRoutes={dashboardRoutes}
      />
    );

  return (
    <div>
      <DashboardsPopup
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        type={type}
      />
      {reportsContent}
    </div>
  );
};

export default TilesContent;
