/* eslint-disable @typescript-eslint/no-unused-vars */
import * as SchemaTypes from '@apollo-red/schema/generated/operations';

export import TenantQaType = SchemaTypes.TenantQaType;
export import TestStatus = SchemaTypes.TestStatus;
export import ReleaseStatus = SchemaTypes.ReleaseStatus;
export import PromotionAction = SchemaTypes.PromotionAction;

type ReleasesRecord = SchemaTypes.ReleasesRecord;
type UpdateReleaseInput = SchemaTypes.UpdateReleaseInput;
type PromoteReleaseInput = SchemaTypes.PromoteReleaseInput;

export { ReleasesRecord, UpdateReleaseInput, PromoteReleaseInput };
