import { useTranslation } from 'react-i18next';
import { utils } from '@jll-labs/azara-ui-components';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_TENANT_DATA_LOADS_JOB_LINKS } from '@apollo-red/queries';
import { useAppMessage } from '@components/messages';
import { useAppLazyQueryCb } from '../../queries';
import { useLogError } from '../../logger';

export const useDownloadTenantJobFile = (tenantId: string) => {
  const { t } = useTranslation();
  const { logError } = useLogError();
  const { setAppMessage } = useAppMessage();
  const getJobLinks = useAppLazyQueryCb<
    SchemaTypes.GetTenantDataLoadsJobLinksQuery,
    SchemaTypes.GetTenantDataLoadsJobLinksQueryVariables
  >(
    GET_TENANT_DATA_LOADS_JOB_LINKS,
    { fetchPolicy: 'no-cache' },
    { globalErr: true },
  );

  return {
    downloadTenantJobFile: async (jobId: string) => {
      const { data } = await getJobLinks({
        tenant_id: tenantId,
        job_id: jobId,
      });

      const url =
        data?.getTenants?.[0]?.tenant_data_loads_job_links?.output_download_url;

      if (url) {
        try {
          const file = await utils.downloadFile(url);
          if (file) {
            utils.saveFile(file, `${jobId}_IO.zip`);
          }
        } catch (e) {
          setAppMessage({
            text: t('systemError'),
            description: e?.message ?? t('errorOccurredMsg'),
            type: 'error',
          });
          logError(e);
        }
      }
    },
  };
};
