import * as React from 'react';
import Grid from '@mui/material/Grid';
import { LaptopScreenshot } from '@assets';
import { Theme, SxProps, useTheme, Box } from '@mui/material';
import Title from './Title';
import BulletList from './BulletList';

const useStyles = (): Record<'root' | 'screenshot', SxProps<Theme>> => {
  const theme = useTheme();

  return {
    root: {
      padding: theme.spacing(12, 6),
      backgroundColor: theme.palette.secondary.light,
    },

    screenshot: {
      width: '80%',
      margin: theme.spacing(3, 0),
    },
  };
};

const points = ['Integrating data', 'Managing data', 'Mastering data'];

const Possibilities: React.FC = () => {
  const styles = useStyles();

  return (
    <Grid container={true} sx={styles.root}>
      <Grid item={true} lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Grid container={true} alignContent="center" justifyContent="center">
          <Box component="img" src={LaptopScreenshot} sx={styles.screenshot} />
        </Grid>
      </Grid>
      <Grid item={true} lg={6}>
        <div>
          <Title title="Explore the possibilities of" />
          <BulletList
            description={
              <div>
                <div>
                  {
                    'Azara leverages JLL institutional knowledge and data science expertise to'
                  }
                </div>
                <div>{'deliver actionable insights.'}</div>
              </div>
            }
            points={points}
            linesText={'from any source!'}
            lines={true}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Possibilities;
