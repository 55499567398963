import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { MessageIcon, MessagePopper } from '@jll-labs/azara-ui-components';
import { Report } from '@apollo-red/hooks';
import { AppTheme } from '@theme';

const useStyles = makeStyles((theme: AppTheme) => ({
  reportName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  tooltipCircled: {
    cursor: 'pointer',
    marginLeft: '5px',
  },
  containerNameAndIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface FormValues {
  [key: string]: {
    id: string;
    keyword_name: string;
  }[];
}

interface Props {
  report: Report;
  iconOnly?: boolean;
}

const ReportName: React.FC<Props> = ({ iconOnly, report }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleReportTooltipOpen = evt => {
    setAnchorEl(evt.currentTarget);
    setOpen(true);
  };

  const handleReportTooltipClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.reportName}>
      <div className={classes.containerNameAndIcon}>
        {!iconOnly && <span>{report.report_name}</span>}
        <MessageIcon
          name="information"
          className={classes.tooltipCircled}
          onClick={handleReportTooltipOpen}
          fontSize="small"
        />
      </div>
      <MessagePopper
        message={{
          text: report.report_name,
          description: report.report_description || 'No description provided.',
          position: 'right',
        }}
        isOpen={open}
        anchorEl={anchorEl}
        handleClose={handleReportTooltipClose}
      />
    </div>
  );
};

export default ReportName;
