import * as Yup from 'yup';
import {
  AreaUnit,
  CurrencyUnit,
  UserUniversalFilter,
  UserUniversalFilters,
  createDefaultFilters,
} from '@apollo-red/hooks';
import { GeoFiltersOption } from './useGeoUniversalFiltersForm';

export interface FormValues {
  countries: GeoFiltersOption[];
  states: GeoFiltersOption[];
  cities: GeoFiltersOption[];
  properties: GeoFiltersOption[];
  unit_of_measurement: AreaUnit;
  currency: CurrencyUnit;
}

export const universalFiltersSchema = Yup.object().shape({
  unit_of_measurement: Yup.mixed()
    .oneOf(Object.values(AreaUnit), 'Value is not allowed')
    .required('Required'),
  currency: Yup.mixed()
    .oneOf(Object.values(CurrencyUnit), 'Value is not allowed')
    .required('Required'),
});

export const parseInitValue = (
  filters: UserUniversalFilter[],
): GeoFiltersOption[] => {
  return filters.map(({ id, filter_value, filter_ancestors }) => ({
    value: id,
    ancestors: filter_ancestors ?? [],
    label: filter_value,
    group: filter_ancestors ? filter_ancestors.join(', ') : '',
  }));
};

const parseValue = (name: string, values: GeoFiltersOption[]) => {
  return {
    name,
    filters:
      values.map(({ value, label }) => ({ filter_value: label, id: value })) ??
      [],
  };
};

export const useUniversalFiltersForm = (
  universalFilters?: UserUniversalFilters,
) => {
  const parseSubmit = (values: FormValues) => {
    return {
      countries: parseValue('countries', values.countries),
      states: parseValue('states', values.states),
      cities: parseValue('cities', values.cities),
      properties: parseValue('properties', values.properties),
      unit_of_measurement: values.unit_of_measurement,
      currency: values.currency,
    };
  };

  const initialValues: FormValues = {
    countries: parseInitValue(
      createDefaultFilters(universalFilters?.countries).filters,
    ),
    states: parseInitValue(
      createDefaultFilters(universalFilters?.states).filters,
    ),
    cities: parseInitValue(
      createDefaultFilters(universalFilters?.cities).filters,
    ),
    properties: parseInitValue(
      createDefaultFilters(universalFilters?.properties).filters,
    ),
    unit_of_measurement: universalFilters?.unit_of_measurement || AreaUnit.Sqm,
    currency: universalFilters?.currency || CurrencyUnit.Usd,
  };

  return { initialValues, parseSubmit };
};
