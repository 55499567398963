import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FavoriteIcon } from '@jll-labs/azara-ui-components';
import { ReportExtended, useToggleFavorite } from '@apollo-red/hooks';
import { Box, Button, Grid } from '@mui/material';

interface Props {
  report: ReportExtended;
}

const ExternalReportToolbar: React.FC<Props> = ({ report }) => {
  const { t } = useTranslation();
  const { toggleFavorite } = useToggleFavorite();

  const handleFavoritesClick = () => {
    toggleFavorite(report);
  };

  return (
    <Grid container={true} alignItems="center">
      <Box component="span" mr={2} mb={2} mt={2} display="inline-block">
        <Button variant="text" color="primary" onClick={handleFavoritesClick}>
          <FavoriteIcon fav={report.isFav} />
          <Box component="span" mx={2} my={0}>
            {t('favorites')}
          </Box>
        </Button>
      </Box>
    </Grid>
  );
};

export default ExternalReportToolbar;
