import * as React from 'react';
import { fallbackUserData } from './fallbackUserData';
import { User } from './types';

export const UserInfoContext = React.createContext<{
  user: User;
  loading: boolean;
  nextUserLoading: boolean;
  switchUser: (id: string) => void;
  onboarding: {
    remindOnboarding: boolean;
    setRemindOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
    skipOnboarding: {
      [key: string]: boolean;
    };
    handleSetSkipOnboarding: (userId: string, skipOnboarding: boolean) => void;
  };
  productAnalytics: {
    isReady: boolean;
    setIsReady: (isReady: boolean) => void;
  };
}>({
  loading: false,
  nextUserLoading: false,
  user: fallbackUserData,
  switchUser: () => {},
  onboarding: {
    remindOnboarding: true,
    setRemindOnboarding: () => {},
    skipOnboarding: {},
    handleSetSkipOnboarding: () => {},
  },
  productAnalytics: {
    isReady: false,
    setIsReady: (_: boolean) => {},
  },
});
