import * as React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';
import { useGetUserInfo, User } from '@apollo-red/hooks';
import { TriangleBackground, HandsOnBoarding } from '@assets';
import { useRoutes } from '@routes';
import { AppTheme } from '@theme';
import StepperContainer from './StepperContainer';
import { StepWrapperProps } from './StepWrapper';

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    minHeight: '100vh',
  },
  bottomBar: {
    height: '10vh',
    display: 'flex',
  },
  topBgImage: {
    backgroundImage: `url(${TriangleBackground})`,
    backgroundSize: 'cover',
    height: '30vh',
  },
  hands: {
    position: 'absolute',
    zIndex: 1,
    width: '10vw',
    left: `calc(50% - 5vw)`,
    top: `calc(30vh - 5vw)`,
  },
  header: {
    textAlign: 'center',
  },
  name: {
    fontSize: theme.typography.h3.fontSize,
    margin: theme.spacing(10, 0, 5, 0),
  },
  follow: {
    fontSize: theme.typography.h5.fontSize,
  },
  btnsContainer: {
    margin: '2em 0',
  },
  btn: {
    margin: '1em',
  },
}));

export const getUserFirstName = (user: User) => {
  const index = user.user_full_name.indexOf(' ');
  return user.user_full_name.slice(0, index);
};

const Welcome: React.FC<StepWrapperProps> = props => {
  const history = useHistory();
  const routes = useRoutes();
  const classes = useStyles();
  const { user } = useGetUserInfo();
  const name = getUserFirstName(user);
  return (
    <Grid
      className={classes.root}
      container={true}
      direction="column"
      justifyContent="space-between"
    >
      <div className={classes.topBgImage}>
        <img className={classes.hands} src={HandsOnBoarding} />
      </div>
      <div className={classes.header}>
        <Typography className={classes.name}>{`Hi, ${name}!`}</Typography>
        <Typography
          color="textSecondary"
          variant="h3"
          className={classes.follow}
        >
          {'To complete your profile, follow this step-by-step process.'}
        </Typography>
        <div>
          <Typography variant="body1" color="textSecondary">
            {
              'This helps us understand your needs and match the system accordingly so you can get the most out of Azara.'
            }
          </Typography>
        </div>
        <Grid
          className={classes.btnsContainer}
          container={true}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            className={classes.btn}
            onClick={() => {
              history.push(routes.onBoarding.roles.getLink());
            }}
            color="primary"
            variant="contained"
          >
            {'Get Started'}
          </Button>
        </Grid>
      </div>
      <div className={classes.bottomBar}>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={12}>
            <StepperContainer
              handleStepClick={props.handleStepClick}
              activeStep={props.activeStep}
              completed={props.completed}
              lastCompletedStep={props.lastCompletedStep}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Welcome;
