import { TypedTypePolicies } from '@apollo-red/schema/generated/apollo-helpers';

export const reportTypePolicy: TypedTypePolicies = {
  Report: {
    fields: {
      report_full_keywords: {
        merge: false,
      },
    },
  },
};
