import {
  RoleType,
  UserTenantSettings,
  UserAdminSettings,
  UserProvisioningStatus,
} from '@apollo-red/hooks';

export const hasUserSystemRole = (
  adminSettings: UserAdminSettings,
  roleType: RoleType,
) => {
  return adminSettings.user_system_roles.some(
    role => role.role_type === roleType,
  );
};

export const isUserSystemAdmin = (adminSettings: UserAdminSettings) => {
  return hasUserSystemRole(adminSettings, RoleType.SystemAdmin);
};

export const getUserPrimaryTenantRole = (
  tenantsSettings: UserTenantSettings[],
  tenantId: string,
) => {
  const tenantSettings = tenantsSettings.find(
    t => t.user_tenant.id === tenantId,
  );
  return tenantSettings?.user_provisioning_status ===
    UserProvisioningStatus.Done
    ? tenantSettings?.user_role?.role_name ?? ''
    : '';
};

export const getUserPrimaryRole = (tenantSettings: UserTenantSettings) => {
  return tenantSettings.user_provisioning_status === UserProvisioningStatus.Done
    ? tenantSettings.user_role?.role_name
    : undefined;
};
