import { useCallback } from 'react';

import {
  GET_ALL_ROLES,
  GET_TENANT_CLIENT_DEFINED_ROLES,
} from '@apollo-red/queries';
import {
  RoleMenuItems,
  RoleOtherSubMenuItems,
} from '@components/pageContainer/SideBar/Navigation/navHelpers.types';
import { UserProvisioningStatus } from '@apollo-red/hooks';
import { useAppMutation, useAppQuery } from '../queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { filterItems, WithoutTypename } from '@utils/various';

/* eslint-disable-next-line */
export import RoleType = SchemaTypes.RoleType;

/* eslint-disable-next-line */
export import TenantRoleType = SchemaTypes.TenantRoleType;
/* eslint-disable-next-line */
export import TenantRoleStatus = SchemaTypes.RoleStatus;

import {
  UPSERT_TENANT_ROLE,
  DELETE_TENANT_ROLE,
} from '@apollo-red/mutations/Role';
import { useGetUserCurrentTenantSettings, useGetUserInfo } from '../user';

export type Role = WithoutTypename<SchemaTypes.Role>;
export type RoleDashboards = WithoutTypename<SchemaTypes.RoleDashboards>;
export type RoleDashboard = WithoutTypename<SchemaTypes.RoleDashboard>;
export type RoleInsightsAlert = WithoutTypename<SchemaTypes.RoleInsightsAlert>;
export type RoleInsightsAlerts =
  WithoutTypename<SchemaTypes.RoleInsightsAlerts>;
export type RoleOtherSubFeature =
  WithoutTypename<SchemaTypes.RoleOtherSubFeature>;
export type RoleOtherFeature = WithoutTypename<SchemaTypes.RoleOtherFeature>;

export const useGetAllRoles = () => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetRolesQuery,
    SchemaTypes.GetRolesQueryVariables
  >(GET_ALL_ROLES);

  return {
    loading,
    roles: filterItems(data?.getRoles ?? []),
  };
};

export const useGetTenantClientDefinedRoles = (tenantId: string) => {
  const { loading, data, startPolling, stopPolling } = useAppQuery<
    SchemaTypes.GetTenantClientDefinedRolesQuery,
    SchemaTypes.GetTenantClientDefinedRolesQueryVariables
  >(GET_TENANT_CLIENT_DEFINED_ROLES, { variables: { tenant_id: tenantId } });
  return {
    loading,
    roles: filterItems(data?.getTenants[0]?.tenant_client_defined_roles ?? []),
    startPolling,
    stopPolling,
  };
};

export const useUpsertTenantRole = () => {
  const [upsertRole] = useAppMutation<
    SchemaTypes.UpsertTenantRoleMutation,
    SchemaTypes.UpsertTenantRoleMutationVariables
  >(UPSERT_TENANT_ROLE);

  return {
    upsertTenantRole: ({
      data,
      roleId,
      tenantId,
    }: {
      tenantId: string;
      data: SchemaTypes.RoleInput;
      roleId?: string;
    }) => {
      return upsertRole({
        variables: {
          data,
          where: { tenant_id: tenantId, role_id: roleId },
        },
      });
    },
  };
};

export const useDeleteTenantRole = () => {
  const [deleteRole, { loading }] = useAppMutation<
    SchemaTypes.DeleteTenantRoleMutation,
    SchemaTypes.DeleteTenantRoleMutationVariables
  >(DELETE_TENANT_ROLE);

  return {
    deleteTenantRole: ({
      tenantId,
      roleId,
    }: {
      tenantId: string;
      roleId?: string;
    }) => {
      return deleteRole({
        variables: {
          where: {
            tenant_id: tenantId,
            role_id: roleId,
          },
        },
      });
    },
    loading,
  };
};

export const useGetUserSystemRoles = () => {
  const {
    user: {
      user_admin_settings: { user_system_roles },
    },
  } = useGetUserInfo();

  return user_system_roles;
};

export const useGetUserCurrentTenantRole = () => {
  const { userTenantSettings } = useGetUserCurrentTenantSettings();

  return userTenantSettings?.user_provisioning_status ===
    UserProvisioningStatus.Done
    ? userTenantSettings?.user_role
    : undefined;
};

export const useCheckFeature = () => {
  const userSystemRoles = useGetUserSystemRoles();
  const userTenantRole = useGetUserCurrentTenantRole();

  return {
    checkFeature: useCallback(
      (feature: RoleMenuItems, subFeature?: RoleOtherSubMenuItems) => {
        if (subFeature) {
          return [...userSystemRoles, userTenantRole].some(userRole => {
            const roleOtherFeature = userRole?.role_other_features?.find(
              rof => rof.feature_id === feature,
            );
            return (
              !!userRole?.role_other_features_all ||
              (!!roleOtherFeature?.enabled &&
                !!roleOtherFeature?.sub_features.find(
                  sf => sf.sub_feature_id === subFeature,
                )?.enabled)
            );
          });
        }
        return [...userSystemRoles, userTenantRole].some(
          userRole =>
            !!userRole?.[`role_${feature}`]?.enabled ||
            !!userRole?.role_other_features_all ||
            !!userRole?.role_other_features?.find(
              rof => rof.feature_id === feature,
            )?.enabled,
        );
      },
      [userSystemRoles, userTenantRole],
    ),
  };
};

export const useCheckFeatureFullAccess = (
  feature: RoleMenuItems,
  subFeature: RoleOtherSubMenuItems,
) => {
  const userSystemRoles = useGetUserSystemRoles();
  const userTenantRole = useGetUserCurrentTenantRole();

  return {
    isFullAccess: [...userSystemRoles, userTenantRole].some(userRole => {
      return (
        !!userRole?.role_other_features_all ||
        !!userRole?.role_other_features
          ?.find(rof => rof.feature_id === feature)
          ?.sub_features.find(sf => sf.sub_feature_id === subFeature)
          ?.full_access
      );
    }),
  };
};
