import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  LOG_ERROR,
  LOG_PERFORMANCE,
  LOG_USER_ACTIVITY,
} from '@apollo-red/mutations';
import { useAppMutation } from '../queries';

export type LogPerformanceContextPayload =
  SchemaTypes.ReportPerformanceContextInput;

/* eslint-disable-next-line */
export import LogReportType = SchemaTypes.ReportLogType;

export const useLogError = () => {
  const [logError] = useAppMutation<
    SchemaTypes.LogErrorMutation,
    SchemaTypes.LogErrorMutationVariables
  >(LOG_ERROR, undefined, {
    globalErr: false,
  });

  return React.useMemo(
    () => ({
      logError: (payload: Error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(payload);
        }
        return logError({
          variables: {
            data: {
              message: payload.message ?? '',
              name: payload.name ?? 'Unknown',
              stack: payload.stack,
            },
          },
        });
      },
    }),
    [logError],
  );
};

export const useLogPerformance = () => {
  const [logPerformance] = useAppMutation<
    SchemaTypes.LogPerformanceMutation,
    SchemaTypes.LogPerformanceMutationVariables
  >(LOG_PERFORMANCE);

  return {
    logPerformance: (payload: SchemaTypes.ReportPerformanceLogInput) => {
      return logPerformance({
        variables: {
          data: {
            duration: payload.duration,
            context: {
              tenant_id: payload.context.tenant_id,
              report_type: payload.context.report_type,
              report_id: payload.context.report_id,
              original_report_id: payload.context.original_report_id,
            },
          },
        },
      });
    },
  };
};

export const useLogUserActivity = (authenticated?: boolean) => {
  const [logUserActivity] = useAppMutation<
    SchemaTypes.LogUserActivityMutation,
    SchemaTypes.LogUserActivityMutationVariables
  >(LOG_USER_ACTIVITY, undefined, { globalErr: false });

  React.useEffect(() => {
    let timeout;

    if (authenticated) {
      const trackActivity = async () => {
        await logUserActivity();
        timeout = setTimeout(trackActivity, 5 * 60 * 1000);
      };

      trackActivity();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [authenticated, logUserActivity]);
};
