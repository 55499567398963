import { gql } from '@apollo/client';
import { ReportMappingBase } from '@apollo-red/fragments';

export const GET_ALL_REPORT_MAPPINGS = gql`
  query getAllReportMappings {
    getReportMappings {
      ...ReportMappingBase
    }
  }
  ${ReportMappingBase.fragment}
`;

export const GET_REPORT_MAPPINGS = gql`
  query getReportMappings($report_mappings: [ID!]!) {
    getReportMappings(report_mappings: $report_mappings) {
      ...ReportMappingBase
    }
  }
  ${ReportMappingBase.fragment}
`;
