import * as React from 'react';
import { RetryThumbnailError, TimeoutThumbnailError } from './error';
import ThumbnailImageWrapper from './ThumbnailImageWrapper';
import { useDashboardThumbnailImageTimeout } from './useDashboardThumbnailImageTimeout';

const TRANSPARENT_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

interface DashboardThumbnailImageProps {
  className?: string;
  imageUrl?: string;
  isError?: boolean;
  isLoading?: boolean;
  isRetrying?: boolean;
  loaderComponent?: JSX.Element;
  onError?: React.ReactEventHandler<HTMLImageElement>;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  onRetry?: () => void;
  timeoutEnabled?: boolean;
}

const DashboardThumbnailImage: React.FC<DashboardThumbnailImageProps> = ({
  className,
  imageUrl,
  isError = false,
  isLoading = false,
  isRetrying = false,
  loaderComponent = null,
  onError,
  onLoad,
  onRetry,
  timeoutEnabled = false,
}) => {
  const { timedOut, resetTimeout, setImageLoaded } =
    useDashboardThumbnailImageTimeout({
      timeoutEnabled,
    });
  const handleOnload = React.useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setImageLoaded();
      onLoad?.(event);
    },
    [onLoad, setImageLoaded],
  );
  const handleRetry = React.useCallback(() => {
    resetTimeout();
    onRetry?.();
  }, [onRetry, resetTimeout]);

  if (timedOut) {
    return <TimeoutThumbnailError handleRetry={handleRetry} />;
  }

  if (isError) {
    return <RetryThumbnailError handleRetry={handleRetry} />;
  }

  return (
    <ThumbnailImageWrapper>
      {isLoading && !timedOut && loaderComponent}
      <img
        onLoad={handleOnload}
        onError={onError}
        className={className}
        src={isRetrying ? TRANSPARENT_PIXEL : imageUrl}
      />
    </ThumbnailImageWrapper>
  );
};

export default DashboardThumbnailImage;
