import * as React from 'react';

interface UseDashboardThumbnailImageTimeoutProps {
  timeoutEnabled?: boolean;
}

export const useDashboardThumbnailImageTimeout = ({
  timeoutEnabled = false,
}: UseDashboardThumbnailImageTimeoutProps) => {
  const [loaded, setLoaded] = React.useState(false);
  const [timedOut, setTimedOut] = React.useState(false);
  const duration = parseInt(
    process.env.TABLEAU_THUMBNAIL_TIMEOUT ?? '30000',
    10,
  );

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    const checkThumbnailImageLoaded = () => {
      if (loaded) {
        return;
      }

      if (!timeout) {
        timeout = setTimeout(checkThumbnailImageLoaded, duration);
      } else {
        clearTimeout(timeout);
        setTimedOut(true);
      }
    };

    if (timeoutEnabled && !timeout) {
      checkThumbnailImageLoaded();
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [duration, loaded, timedOut, timeoutEnabled]);

  return {
    timedOut: timeoutEnabled ? timedOut : false,
    resetTimeout: () => setTimedOut(false),
    setImageLoaded: () => setLoaded(true),
  };
};
