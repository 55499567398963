import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  GET_ALL_REPORT_MAPPINGS,
  GET_REPORT_MAPPINGS,
} from '@apollo-red/queries';
import {
  ADD_REPORT_MAPPING,
  UPDATE_REPORT_MAPPING,
  DELETE_REPORT_MAPPING,
} from '@apollo-red/mutations';
import { useAppQuery, useAppMutation } from '../queries';

export type ReportMapping = SchemaTypes.ReportMappingBaseFragment;

export const useGetAllReportMappings = () => {
  const { data, loading } = useAppQuery<SchemaTypes.GetReportMappingsQuery>(
    GET_ALL_REPORT_MAPPINGS,
  );

  return { loading, reportMappings: data?.getReportMappings };
};

export const useGetReportMappings = (reportMappings: string[]) => {
  const { data, loading } = useAppQuery<SchemaTypes.GetReportMappingsQuery>(
    GET_REPORT_MAPPINGS,
    {
      variables: {
        report_mappings: reportMappings,
      },
    },
  );

  return { loading, reportMappings: data?.getReportMappings };
};

export const useAddReportMapping = () => {
  const [addReportMapping] = useAppMutation<
    SchemaTypes.AddReportMappingMutation,
    SchemaTypes.AddReportMappingMutationVariables
  >(ADD_REPORT_MAPPING);

  return {
    addReportMapping: (payload: SchemaTypes.UpsertReportMappingDataInput) => {
      const data = {
        variables: {
          data: payload,
        },
      };
      return addReportMapping(data);
    },
  };
};

export const useUpdateReportMapping = () => {
  const [updateReportMapping] = useAppMutation<
    SchemaTypes.UpdateReportMappingMutation,
    SchemaTypes.UpdateReportMappingMutationVariables
  >(UPDATE_REPORT_MAPPING);

  return {
    updateReportMapping: (
      id: string,
      payload: SchemaTypes.UpsertReportMappingDataInput,
      keywords: SchemaTypes.Keyword[],
      reportMappingExists,
    ) => {
      return updateReportMapping({
        variables: {
          data: payload,
          where: {
            id,
          },
        },
        optimisticResponse: {
          upsertReportMapping: {
            id,
            ...payload,
            report_mapping_exists: reportMappingExists,
            report_keywords: [...keywords],
            __typename: 'ReportMapping',
          },
        },
      });
    },
  };
};

export const useUpdateReportMappingDescription = () => {
  const [updateReportMapping] = useAppMutation<
    SchemaTypes.UpdateReportMappingMutation,
    SchemaTypes.UpdateReportMappingMutationVariables
  >(UPDATE_REPORT_MAPPING);

  return {
    updateReportMappingDescription: (
      id: string,
      payload: SchemaTypes.UpsertReportMappingDataInput,
    ) => {
      return updateReportMapping({
        variables: {
          data: payload,
          where: {
            id,
          },
        },
      });
    },
  };
};

export const useDeleteReportMapping = () => {
  const [deleteReportMapping] = useAppMutation<
    SchemaTypes.DeleteReportMappingMutation,
    SchemaTypes.DeleteReportMappingMutationVariables
  >(DELETE_REPORT_MAPPING);

  return {
    deleteReportMapping: (id: string) => {
      const data = {
        variables: {
          where: {
            id,
          },
        },
      };
      return deleteReportMapping(data);
    },
  };
};
