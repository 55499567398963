import * as React from 'react';
import { getBaseUrl, main } from '@routes';
import { isNaN } from 'lodash';
import { useParamsCustom } from '@utils/router';

const QaLegacyRedirect: React.FC = () => {
  const { tenantOvcId } = useParamsCustom();

  const currentPathname = location.pathname;
  const newPathname = currentPathname.split('/').slice(1).join('/');

  if ((tenantOvcId && !isNaN(parseInt(tenantOvcId, 10))) || !tenantOvcId) {
    document.location.href =
      document.location.origin + `${getBaseUrl()}${newPathname}`;
  } else {
    document.location.href = document.location.origin + main.notFound.getLink();
  }

  return null;
};

export default QaLegacyRedirect;
