import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GENERATE_TRUSTED_EXTERNAL_REPORT_URL } from '@apollo-red/mutations';
import { useAppMutation } from '../queries';

export const useGenerateTrustedExternalReportUrl = () => {
  const [generateTrustedExternalReportUrl] = useAppMutation<
    SchemaTypes.GenerateTrustedExternalReportUrlMutation,
    SchemaTypes.GenerateTrustedExternalReportUrlMutationVariables
  >(GENERATE_TRUSTED_EXTERNAL_REPORT_URL);

  return {
    generateTrustedExternalReportUrl: (
      payload: SchemaTypes.WhereGenerateTrustedExternalReportUrlInput,
    ) => {
      return generateTrustedExternalReportUrl({
        variables: {
          where: {
            tenant_id: payload.tenant_id,
            report_id: payload.report_id,
            report_url: payload.report_url,
          },
        },
      });
    },
  };
};
