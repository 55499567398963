import { FallbackThumbnail } from '@assets';
import {
  BusinessUnitProfile_T,
  CostofVacancy_T,
  CostsbyCategory_T,
  CostvMarket_T,
  CountryProfile_T,
  Cowork_T,
  CoworkInventory_T,
  DensityvsVacancyComparison_T,
  GeographicSynergiesandClusters_T,
  HistoricalTimeSeriesbyDimensionandMetric_T,
  HistoricalTimeSeriesDashboard_T,
  LeaseAnalysis_T,
  LeaseCostBurnDown_T,
  LeaseEvent_T,
  LeaseExpirationsbyRemainingMonths_T,
  LeaseRentableAreabyExpireYear_T,
  LeaseTimeline_T,
  MapView_T,
  MarketFavorability_T,
  MarketRatetoUtilizationOpportunities_T,
  MarktoMarketOpportunitiesRisks_T,
  OccupancyBreakdown_T,
  OccupancyByDimension_T,
  OccupancyByProperty_T,
  OccupancyList_T,
  OccupancyMarket_T,
  OccupancySavingsOpportunities_T,
  OccupancyScatterplot_T,
  OpportunitieswithPropertyTargets_T,
  OwnedPropertyOverview_T,
  PortfolioByDimension_Occupancy_T,
  PortfolioByDimension_Property_T,
  PortfolioOverview_Occupancy_T,
  PortfolioOverview_Property_T,
  PortfolioOverview_PropertyWOcc_T,
  PropertyClockOverview_T,
  PropertyComparisonOpportunityScatterplots_T,
  PropertyCostsbyYearPivot_T,
  PropertyCostTimeline_T,
  PropertyList_T,
  PropertyScatterplot_T,
  SubleaseAnalysis_T,
} from '@assets/pat';

export const staticImageForImageUrl = (imageUrl?: string) => {
  const [name, _] = (imageUrl ?? '').split('.png');

  switch (name) {
    case 'BusinessUnitProfile_T':
      return BusinessUnitProfile_T;
    case 'CostofVacancy_T':
      return CostofVacancy_T;
    case 'CostsbyCategory_T':
      return CostsbyCategory_T;
    case 'CostvMarket_T':
      return CostvMarket_T;
    case 'CountryProfile_T':
      return CountryProfile_T;
    case 'Cowork_T':
      return Cowork_T;
    case 'CoworkInventory_T':
      return CoworkInventory_T;
    case 'DensityvsVacancyComparison_T':
      return DensityvsVacancyComparison_T;
    case 'GeographicSynergiesandClusters_T':
      return GeographicSynergiesandClusters_T;
    case 'HistoricalTimeSeriesbyDimensionandMetric_T':
      return HistoricalTimeSeriesbyDimensionandMetric_T;
    case 'HistoricalTimeSeriesDashboard_T':
      return HistoricalTimeSeriesDashboard_T;
    case 'LeaseAnalysis_T':
      return LeaseAnalysis_T;
    case 'LeaseCostBurnDown_T':
      return LeaseCostBurnDown_T;
    case 'LeaseEvent_T':
      return LeaseEvent_T;
    case 'LeaseExpirationsbyRemainingMonths_T':
      return LeaseExpirationsbyRemainingMonths_T;
    case 'LeaseRentableAreabyExpireYear_T':
      return LeaseRentableAreabyExpireYear_T;
    case 'LeaseTimeline_T':
      return LeaseTimeline_T;
    case 'MapView_T':
      return MapView_T;
    case 'MarketFavorability_T':
      return MarketFavorability_T;
    case 'MarketRatetoUtilizationOpportunities_T':
      return MarketRatetoUtilizationOpportunities_T;
    case 'MarktoMarketOpportunitiesRisks_T':
      return MarktoMarketOpportunitiesRisks_T;
    case 'OccupancyBreakdown_T':
      return OccupancyBreakdown_T;
    case 'OccupancyByDimension_T':
      return OccupancyByDimension_T;
    case 'OccupancyByProperty_T':
      return OccupancyByProperty_T;
    case 'OccupancyList_T':
      return OccupancyList_T;
    case 'OccupancyMarket_T':
      return OccupancyMarket_T;
    case 'OccupancySavingsOpportunities_T':
      return OccupancySavingsOpportunities_T;
    case 'OccupancyScatterplot_T':
      return OccupancyScatterplot_T;
    case 'OpportunitieswithPropertyTargets_T':
      return OpportunitieswithPropertyTargets_T;
    case 'OwnedPropertyOverview_T':
      return OwnedPropertyOverview_T;
    case 'PortfolioByDimension_Occupancy_T':
      return PortfolioByDimension_Occupancy_T;
    case 'PortfolioByDimension_Property_T':
      return PortfolioByDimension_Property_T;
    case 'PortfolioOverview_Occupancy_T':
      return PortfolioOverview_Occupancy_T;
    case 'PortfolioOverview_Property_T':
      return PortfolioOverview_Property_T;
    case 'PortfolioOverview_PropertyWOcc_T':
      return PortfolioOverview_PropertyWOcc_T;
    case 'PropertyClockOverview_T':
      return PropertyClockOverview_T;
    case 'PropertyComparisonOpportunityScatterplots_T':
      return PropertyComparisonOpportunityScatterplots_T;
    case 'PropertyCostsbyYearPivot_T':
      return PropertyCostsbyYearPivot_T;
    case 'PropertyCostTimeline_T':
      return PropertyCostTimeline_T;
    case 'PropertyList_T':
      return PropertyList_T;
    case 'PropertyScatterplot_T':
      return PropertyScatterplot_T;
    case 'SubleaseAnalysis_T':
      return SubleaseAnalysis_T;
    default:
      return FallbackThumbnail;
  }
};
