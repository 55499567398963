import * as React from 'react';
import { Grid, Typography, Box, Theme, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Checkbox, TextField } from '@jll-labs/azara-ui-components';
import { SubHeader } from '@components/typography';

const useStyles = (): Record<
  'item' | 'checkboxLabel' | 'noFound',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    item: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    checkboxLabel: {
      flex: 1,
    },
    noFound: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
  };
};

interface ItemProps {
  itemName: string;
  testPrefix: string;
  isChecked: boolean;
  handleChange: (value: string) => void;
}

const Item: React.FC<ItemProps> = ({
  itemName,
  testPrefix,
  isChecked,
  handleChange,
}) => {
  const styles = useStyles();

  return (
    <Grid
      container
      sx={styles.item}
      data-testid={`alert-categories-option-${testPrefix}-${itemName}`}
      onClick={() => handleChange(itemName)}
    >
      <Grid item={true}>
        <Checkbox
          onChange={() => handleChange(itemName)}
          checked={!!isChecked}
          disableHover={true}
          label={itemName}
        />
      </Grid>
      <Grid sx={styles.checkboxLabel} container alignItems="center">
        {itemName}
      </Grid>
    </Grid>
  );
};

interface SearchableListProps {
  name: string;
  selected: string[];
  options: string[];
  handleChange: (list: string[]) => void;
}

const SearchableList: React.FC<SearchableListProps> = ({
  name,
  selected,
  options,
  handleChange,
}) => {
  const styles = useStyles();
  const [filterOptions, setFilterOptions] = React.useState<string[]>(options);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const showAllOption = options.length === filterOptions.length;
  const allChecked = options.length === selected.length;

  const handleSearch = e => {
    const value = e.target.value;
    setSearchValue(value);
    const filterOptionsValues = options.filter(option =>
      option.toLowerCase().includes(value.toLowerCase()),
    );
    setFilterOptions(filterOptionsValues);
  };

  const handleResetSearch = () => {
    setSearchValue('');
    setFilterOptions(options);
  };

  const handleSelect = () => {
    if (allChecked) {
      handleChange([]);
    } else {
      handleChange(options);
    }
  };

  const handleChangeCheck = value => {
    const hasValue = selected.find(s => s === value);
    const newValues = hasValue
      ? selected.filter(val => val !== value)
      : selected.concat([value]);
    handleChange(newValues);
  };

  return (
    <Grid>
      <SubHeader>{name}</SubHeader>
      <Grid>
        <Box my={1} width="75%">
          <TextField
            onChange={handleSearch}
            value={searchValue}
            label="Search"
            endIcon="Search"
          />
        </Box>
      </Grid>
      {showAllOption && (
        <Item
          itemName="All"
          testPrefix="all"
          isChecked={allChecked}
          handleChange={handleSelect}
        />
      )}
      {filterOptions.map((itemName, index) => {
        const isChecked = Boolean(selected.find(s => s === itemName));
        const testPrefix = isChecked ? 'item-selected' : 'item';
        return (
          <div key={itemName + index}>
            <Item
              itemName={itemName}
              testPrefix={testPrefix}
              isChecked={isChecked}
              handleChange={handleChangeCheck}
            />
          </div>
        );
      })}
      {!filterOptions.length && (
        <Box sx={styles.noFound}>
          <Typography>{`No ${name} found.`}</Typography>
          <Typography
            sx={styles.item}
            color="primary"
            onClick={handleResetSearch}
          >
            {'Clear search value.'}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default SearchableList;
