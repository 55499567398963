import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  AutocompleteOption,
  TextField,
  Autocomplete,
} from '@jll-labs/azara-ui-components';
import {
  useGetAllCountries,
  useGetUserInfo,
  useUpdateUserCommPhone,
  useUpdateUserCountryAndRegion,
  Country,
} from '@apollo-red/hooks';
import { usePhoneNumberWithValidation } from '@utils/contact';
import StepWrapper, { StepWrapperProps } from './StepWrapper';

const noOp = () => {};

const Contact: React.FC<StepWrapperProps> = props => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { user } = useGetUserInfo();
  const [phone, setPhone, isPhoneValid] = usePhoneNumberWithValidation(
    user.user_comm.work ?? '',
  );
  const { updateUserPhone } = useUpdateUserCommPhone();
  const { countries, loading: countriesLoading } = useGetAllCountries();
  const { updateCountryAndRegion } = useUpdateUserCountryAndRegion();
  const [country, setCountry] = React.useState<Country | undefined>(undefined);

  const handlePhoneInput = event => {
    setPhone(event.currentTarget.value);
  };

  const findCountryById = React.useCallback(
    (id?: string) => {
      if (!id || countriesLoading) {
        return;
      }

      return (countries ?? []).find(country => country.id === id);
    },
    [countries, countriesLoading],
  );

  const handleCountryChange = (_, opt: AutocompleteOption | null) => {
    if (!opt) {
      return;
    }

    const foundCountry = findCountryById(opt.value);

    if (foundCountry) {
      setCountry(foundCountry);
    }
  };

  const countryOptions = countries.map(country => ({
    label: country.country_desc,
    value: country.country_iso_code,
  }));

  const selectedCountry = countryOptions.find(opt => opt.value === country?.id);

  React.useEffect(() => {
    const foundCountry = findCountryById(user.user_address.country?.id);

    if (foundCountry && !selectedCountry) {
      setCountry(foundCountry);
    }
  }, [findCountryById, selectedCountry, user.user_address.country?.id]);

  const handleSaveData = () => {
    if (!country) {
      return updateUserPhone(phone);
    }

    return Promise.all([
      updateUserPhone(phone),
      updateCountryAndRegion(country),
    ]);
  };

  const validateData = () => {
    return phone ? isPhoneValid : true;
  };

  const handleSubmitData = async () => {
    setIsSubmitting(true);

    try {
      await handleSaveData();
      setIsSubmitting(false);
      props.handleNextClick?.(props.activeStep);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <StepWrapper
      icon="Contacts"
      title="Complete your contact information"
      description={
        <span>
          Enter your contact details. <br /> <br /> These details can be updated
          in <b>Account Settings</b> page.
        </span>
      }
      stepWrapperProps={{ ...props, handleNextClick: handleSubmitData }}
      isValid={validateData()}
      isSubmitting={isSubmitting}
      rightSideHeader="Your Contact Information"
    >
      <Box my={2}>
        <TextField
          value={user.user_email}
          label={t('email')}
          disabled={true}
          onChange={noOp}
        />
      </Box>
      <Box my={2}>
        <TextField
          value={phone}
          label={t('phone')}
          onChange={handlePhoneInput}
        />
      </Box>
      <Box my={2}>
        <Autocomplete
          label={t('country')}
          value={selectedCountry || null}
          options={countryOptions}
          onChange={handleCountryChange}
        />
      </Box>
    </StepWrapper>
  );
};

export default React.memo(Contact);
