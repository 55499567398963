import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import { useGetReportById } from '@apollo-red/hooks/reports';
import { useMarksSelection } from '@utils/tableau';
import { appTheme } from '@theme';
import { FullReportContext } from '../../FullReportContextProvider';
import {
  ButtonGroup,
  useEnabledToolbarButtons,
  useToolbar,
} from '../../toolbar';
import { useFullReportWithFilters } from '../FullReportWithFiltersProvider';
import { WithFiltersToolbarProps } from './WithFiltersToolbar';

type UseWithFiltersToolbarProps = Required<WithFiltersToolbarProps>;

export const useWithFiltersToolbar = ({
  enabledSettings,
  isSidebarExpanded,
  report,
  resetPinnedValues,
}: UseWithFiltersToolbarProps) => {
  const { dashboardTrackEventContext } = React.useContext(FullReportContext);
  const {
    appliedMarksSelectionCount,
    getAppliedControlsCount,
    hasInteractionConfig,
    isCoreDashboard,
    showFiltersPanel,
    toggleFiltersPanel,
    viz,
  } = useFullReportWithFilters();
  const { clearMarksSelection } = useMarksSelection(viz);
  const maxWidth =
    appTheme.breakpoints.values.lg * (isSidebarExpanded ? 1.2 : 1.05);
  const showMenu = useMediaQuery(`(max-width: ${maxWidth}px)`);
  const minFiltersWidth =
    appTheme.breakpoints.values.sm * (isSidebarExpanded ? 1.6 : 1.18);
  const showFilters = useMediaQuery(`(min-width: ${minFiltersWidth}px)`);
  const { report_name, report_original_name, report_parent } = report;
  const { report: parentReport } = useGetReportById(report_parent ?? '');
  const documentationTopic = report.isSavedView
    ? parentReport.report_original_name ?? parentReport.report_name
    : report_original_name ?? report_name;

  const resetFilters = React.useCallback(async () => {
    if (
      viz &&
      getAppliedControlsCount() === 0 &&
      appliedMarksSelectionCount > 0
    ) {
      await clearMarksSelection();
      // FIXME: Revert should not work identically to Clear Filters.
    }

    await resetPinnedValues();
  }, [
    appliedMarksSelectionCount,
    clearMarksSelection,
    getAppliedControlsCount,
    resetPinnedValues,
    viz,
  ]);

  const { toolbarButtons } = useToolbar(
    report,
    dashboardTrackEventContext,
    viz,
    resetFilters,
    true,
  );
  const enabledButtons = useEnabledToolbarButtons(
    toolbarButtons,
    enabledSettings,
  );
  const downloadButtonGroup = enabledButtons.filter(
    tb => tb.buttonGroup === ButtonGroup.DOWNLOAD,
  );
  const toolbarButtonsWithoutButtonGroups = enabledButtons.filter(
    tb => !tb.buttonGroup,
  );
  const enableClearButton =
    getAppliedControlsCount() > 0 || appliedMarksSelectionCount;

  return {
    dashboardTrackEventContext,
    documentationTopic,
    downloadButtonGroup,
    enableClearButton,
    getAppliedControlsCount,
    hasInteractionConfig,
    isCoreDashboard,
    resetFilters,
    showFiltersPanel,
    showMenu,
    showFilters,
    toggleFiltersPanel,
    toolbarButtonsWithoutButtonGroups,
    viz,
  };
};
