import { useMemo } from 'react';
import { getEncodedLink } from '@utils/router';
import { UseClientSettingsRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useCustomReportsTenantAdminRoutes = ({
  customReports: { getLink, path },
}: UseClientSettingsRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      accountSettings: getCommonRoute(link, 'account-settings', path),
      azaraHostedReports: getCommonRoute(link, 'azara-hosted-reports', path),
      externalReports: getCommonRoute(link, 'external-reports', path),
      previewReport: {
        path: `${path}/azara-hosted-reports/preview/:reportId`,
        getLink: (reportId: string) =>
          getEncodedLink([link, 'azara-hosted-reports', 'preview', reportId]),
      },
    };
  }, [getLink, path]);
