import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { Icon } from '@jll-labs/azara-ui-components';
import { InsightsAlertImportanceFilter } from '@apollo-red/hooks';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from '../utils';

export const importanceOptionsConfig = [
  { value: InsightsAlertImportanceFilter.All, label: 'all', icon: false },
  {
    value: InsightsAlertImportanceFilter.Unassigned,
    label: 'unassigned',
    icon: true,
  },
  { value: InsightsAlertImportanceFilter.High, label: 'high', icon: true },
  { value: InsightsAlertImportanceFilter.Medium, label: 'medium', icon: true },
  { value: InsightsAlertImportanceFilter.Low, label: 'low', icon: true },
];

interface Props {
  label: string;
  value: InsightsAlertImportanceFilter;
  icon: boolean;
}

export const AlertImportancePickListOption: React.FC<Props> = ({
  label,
  value,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container={true} alignItems="center">
      {icon && (
        <Icon
          name="StarRounded"
          style={{
            color: ColorPicker[value],
          }}
        />
      )}
      <Box mx={1}>{t(label)}</Box>
    </Grid>
  );
};
