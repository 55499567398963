import { gql } from '@apollo/client';
import { ReportMappingBase } from '@apollo-red/fragments';

export const ADD_REPORT_MAPPING = gql`
  mutation addReportMapping(
    $where: WhereReportMappingInput
    $data: UpsertReportMappingDataInput!
  ) {
    upsertReportMapping(where: $where, data: $data) {
      ...ReportMappingBase
    }
  }
  ${ReportMappingBase.fragment}
`;

export const UPDATE_REPORT_MAPPING = gql`
  mutation updateReportMapping(
    $where: WhereReportMappingInput
    $data: UpsertReportMappingDataInput!
  ) {
    upsertReportMapping(where: $where, data: $data) {
      ...ReportMappingBase
    }
  }
  ${ReportMappingBase.fragment}
`;

export const DELETE_REPORT_MAPPING = gql`
  mutation deleteReportMapping($where: WhereReportMappingInput!) {
    deleteReportMapping(where: $where) {
      ...ReportMappingBase
    }
  }
  ${ReportMappingBase.fragment}
`;
