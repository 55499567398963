import * as React from 'react';
import { transformReportUrl } from '@utils/report';
import { useTableauUrlWithTrustedAuthExternalReport } from '@utils/tableau';
import TableauViewBase from './TableauViewBase';
import { TableauViewWithTrustedAuthProps } from './TableauViewWithTrustedAuth';

export const TableauViewWithTrustedAuthExternalReport: React.FC<TableauViewWithTrustedAuthProps> =
  ({ ...baseProps }) => {
    const url = transformReportUrl(baseProps?.url);
    const { tenantId, reportId, originalReportId } = baseProps;
    const viewUrl = useTableauUrlWithTrustedAuthExternalReport({
      tenantId,
      reportId: originalReportId ?? reportId,
      url,
    });

    if (!viewUrl) {
      return null;
    }

    return <TableauViewBase {...baseProps} url={viewUrl} />;
  };

export default TableauViewWithTrustedAuthExternalReport;
