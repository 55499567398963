import * as React from 'react';
import { useGetUserKpisWithSync } from '@apollo-red/hooks';
import KeyMetricBox from './KeyMetricBox';
import { useKeyMetricBoxes } from './helpers';
import { Box, Grid, Hidden, useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      height: '10.5em',
      marginTop: theme.spacing(2),
    },
  };
};
interface Props {
  noData?: React.ReactNode;
}

const KeyMetrics: React.FC<Props> = ({ noData }) => {
  const styles = useStyles();
  const { loading, error, tenantKpiData } = useGetUserKpisWithSync();
  const keyMetricBoxes = useKeyMetricBoxes(tenantKpiData);

  return (
    <Hidden lgDown={true}>
      <Box sx={styles.root}>
        {!error && (
          <Grid
            container={true}
            data-testid="key-metrics"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            {!loading &&
              tenantKpiData &&
              keyMetricBoxes.map(keyMetricBox => (
                <KeyMetricBox
                  key={keyMetricBox.title}
                  keyMetricBox={keyMetricBox}
                  loading={false}
                  noData={noData}
                  isCompact={keyMetricBoxes.length > 3}
                />
              ))}
          </Grid>
        )}
      </Box>
    </Hidden>
  );
};

export default KeyMetrics;
