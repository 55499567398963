import { gql } from '@apollo/client';
import { TrustedReportUrlBase } from '@apollo-red/fragments';

export const GENERATE_TRUSTED_REPORT_URL = gql`
  mutation generateTrustedReportUrl(
    $where: WhereGenerateTrustedReportUrlInput!
  ) {
    generateTrustedReportUrl(where: $where) {
      ...TrustedReportUrlBase
    }
  }
  ${TrustedReportUrlBase.fragment}
`;

export const GENERATE_TRUSTED_EXTERNAL_REPORT_URL = gql`
  mutation generateTrustedExternalReportUrl(
    $where: WhereGenerateTrustedExternalReportUrlInput!
  ) {
    generateTrustedExternalReportUrl(where: $where) {
      ...TrustedReportUrlBase
    }
  }
  ${TrustedReportUrlBase.fragment}
`;
