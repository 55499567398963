import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoutes } from '@routes';
import { Documentation } from '@pages/documentation';
import { RoleRoutes } from '@components/routes';
import { IdleTimer } from './IdleTimer';

const DocumentationRouter: React.FC<{}> = () => {
  const routes = useRoutes();
  const roleRoutes: RoleRoutes = [
    {
      path: routes.documentation.subtopic.path,
      component: Documentation,
    },
    {
      path: routes.documentation.topic.path,
      component: Documentation,
    },
    {
      path: routes.documentation.category.path,
      component: Documentation,
    },
    {
      path: routes.documentation.home.path,
      component: Documentation,
    },
  ];

  return (
    <Switch>
      {roleRoutes.map(route => (
        <Route key={route.path} {...route} />
      ))}
      <Route render={() => <Redirect to={routes.documentation.home.path} />} />
    </Switch>
  );
};

const DocumentationRouterWithContext: React.FC<{}> = ({ children }) => (
  <IdleTimer>
    <DocumentationRouter>{children}</DocumentationRouter>
  </IdleTimer>
);

export default DocumentationRouterWithContext;
