import * as React from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import { ReportControlType } from '@apollo-red/hooks';
import { NullOption, RangeFilterOptions } from '@components/tableau/types';
import { localDateAsUTCDateIgnoringTimeZone } from '@utils/date';
import { FilterExtended, PinFilter, RangeOption } from '../../types';
import { DateRange, SliderRange } from '../../controls';
import { useFullReportWithFilters } from '../../FullReportWithFiltersProvider';
import {
  getInitialFilterState,
  getUpdatedFilters,
  getPinnedFilters,
  updatePinnedFilters,
} from '../../utils';
import OptionBox from '../OptionBox';

interface Props {
  filter: FilterExtended;
  loading: boolean;
  applyRangeFilter: (name: string, rangeOptions: RangeFilterOptions) => void;
}

const QuantitativeFilter: React.FC<Props> = ({
  filter,
  loading,
  applyRangeFilter,
}) => {
  const {
    addAppliedControls,
    pinnedData,
    isCoreDashboard,
    updatePinnedData,
    id,
  } = useFullReportWithFilters();
  const pinnedFilters = getPinnedFilters(pinnedData, isCoreDashboard, id);
  const initialFilter = isCoreDashboard
    ? pinnedFilters?.find(f => f.filter_id === filter.filter_id)
    : pinnedFilters?.find(f => f.name === filter.name);
  const [filterState, setFilterState] = React.useState<PinFilter>(
    getInitialFilterState(filter, initialFilter),
  );

  const updatedPinnedData = React.useCallback(() => {
    const updatedFilters = getUpdatedFilters(filterState, pinnedFilters);
    const updatedReportFilterData = updatePinnedFilters(
      pinnedData,
      updatedFilters,
      isCoreDashboard,
      id,
    );

    updatePinnedData(updatedReportFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterState.isPinned || initialFilter?.isPinned) {
      updatedPinnedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const applyFilterValues = (filterState: PinFilter) => {
    addAppliedControls([filterState.name]);
    applyRangeFilter(filter.name, {
      max: filterState?.max || 1,
      min: filterState?.min || 1,
      nullOption: NullOption.ALL_VALUES,
    });
  };

  const handlePinUnpin = () => {
    const nextState: PinFilter = {
      ...filterState,
      isPinned: !filterState.isPinned,
    };
    setFilterState(nextState);
  };

  const updateRangeFilter = (options: RangeOption) => {
    const { max, min } = options;

    const nextState: PinFilter = {
      ...filterState,
      min:
        filter.control_type === ReportControlType.DateRange
          ? startOfDay(localDateAsUTCDateIgnoringTimeZone(min as Date))
          : min,
      max:
        filter.control_type === ReportControlType.DateRange
          ? endOfDay(localDateAsUTCDateIgnoringTimeZone(max as Date))
          : max,
    };

    setFilterState(nextState);
    applyFilterValues(nextState);
  };

  const rangeOption: RangeOption = {
    max: filter?.domainMax?.value,
    min: filter?.domainMin?.value,
  };

  const filterOptionComponent = () => {
    if (filter.control_type === ReportControlType.DateRange) {
      return (
        <DateRange
          updateRange={updateRangeFilter}
          loading={loading}
          rangeOption={rangeOption}
          initialValues={{ max: filterState?.max, min: filterState?.min }}
        />
      );
    } else if (filter.control_type === ReportControlType.SliderRange) {
      return (
        <SliderRange
          updateRange={updateRangeFilter}
          loading={loading}
          rangeOption={rangeOption}
          initialValues={{ max: filterState?.max, min: filterState?.min }}
        />
      );
    }
  };

  return (
    <OptionBox
      title={filter.label}
      name={filter.label}
      pinned={filterState.isPinned}
      handlePinUnpin={handlePinUnpin}
      loading={loading}
      filtersApplied={
        (filterState?.selectedValues &&
          filterState?.selectedValues?.length > 0) ||
        Boolean(filterState.max) ||
        Boolean(filterState.min)
      }
    >
      {filterOptionComponent()}
    </OptionBox>
  );
};

export default QuantitativeFilter;
