import * as React from 'react';
import { useTableauUrlWithTrustedAuth } from '@utils/tableau';
import TableauViewBase, { TableauViewBaseProps } from './TableauViewBase';

export interface TableauViewWithTrustedAuthProps extends TableauViewBaseProps {
  tenantId: string;
  reportId: string;
  originalReportId?: string;
}

export const TableauViewWithTrustedAuth: React.FC<TableauViewWithTrustedAuthProps> =
  ({ tenantId, reportId, originalReportId, ...baseProps }) => {
    const viewUrl = useTableauUrlWithTrustedAuth({
      tenantId,
      reportId: originalReportId ?? reportId,
      url: baseProps.url,
    });

    if (!viewUrl) {
      return null;
    }

    return <TableauViewBase {...baseProps} url={viewUrl} />;
  };

export default TableauViewWithTrustedAuth;
