import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  useGetInsightsInboxAlertSortOrder,
  InsightsAlertSortOrder,
} from '@apollo-red/hooks';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_ALERT_SORT_ORDERS } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';

export const useSetInsightsInboxAlertSortOrders = (
  onResetInbox: () => void,
  onDeselectAlert: () => void,
) => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const { sortOrders } = useGetInsightsInboxAlertSortOrder();
  const selectedSortOrders =
    sortOrders?.created_date ?? InsightsAlertSortOrder.Desc;
  const [setInboxAlertSortOrders] = useAppMutation<
    SchemaTypes.SetInsightsInboxAlertSortOrderMutation,
    SchemaTypes.SetInsightsInboxAlertSortOrderMutationVariables
  >(SET_INSIGHTS_INBOX_ALERT_SORT_ORDERS);

  return {
    selectedSortOrders,
    setInboxAlertSortOrders: () => {
      const createdSortOrder =
        selectedSortOrders === InsightsAlertSortOrder.Asc
          ? InsightsAlertSortOrder.Desc
          : InsightsAlertSortOrder.Asc;

      onResetInbox();
      setInboxAlertSortOrders({
        variables: {
          userId: user.id,
          tenantId,
          createdSortOrder,
        },
      });
      onDeselectAlert();
    },
  };
};
