import { InsightsAlertInbox } from '../insightsAlerts.types';
import { useGetInsightsInboxSettings } from './useGetInsightsInboxSettings';

export const useGetInsightsInboxRecentlySelectedAlert = (
  inboxType: InsightsAlertInbox,
) => {
  const { loading, inboxSettings } = useGetInsightsInboxSettings();
  const recentlySelectedAlert = inboxSettings?.insights_inbox_alerts.find(
    settings => settings.insights_inbox_type === inboxType,
  )?.insights_alert_selected;

  return {
    recentlySelectedAlert,
    loading,
  };
};
