import * as React from 'react';
import ErrorScreen, { MainIcon } from './ErrorScreen';
import { useTranslation } from 'react-i18next';

const NetworkError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      statusMsg={t('networkErrorStatusMsg')}
      secondaryMsg={t('networkErrorSecondaryMsg')}
      detailsMsg={t('errorPersistsMsg')}
      icon={MainIcon.Warning}
    />
  );
};

export default NetworkError;
