import { gql } from '@apollo/client';
import {
  TenantExtended,
  TenantBase,
  TenantUserBase,
  TenantRuleBuilderSettingsBase,
  TenantGeoUniversalFiltersBase,
  LogBase,
  TenantAdUserBase,
  DataLoadsJobLinksBase,
  PropertyDataBase,
  DataLoadsJobsBase,
} from '@apollo-red/fragments';

/* eslint-disable graphql/template-strings */
export const GET_TENANTS = gql`
  query getTenantFull(
    $tenant_ids: [ID!]
    $report_filters: TenantReportsFiltersInput
  ) {
    getTenants(tenant_ids: $tenant_ids) {
      ...TenantExtended
    }
  }
  ${TenantExtended.fragment}
`;

/* eslint-enable graphql/template-strings */

export const GET_TENANT_USERS = gql`
  query getTenantUsers($tenant_id: ID!) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_users {
        ...TenantUserBase
      }
    }
  }
  ${TenantUserBase.fragment}
`;

export const GET_TENANT_AD_USERS = gql`
  query getTenantAdUsers($tenant_id: ID!) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_ad_users {
        ...TenantAdUserBase
      }
    }
  }
  ${TenantAdUserBase.fragment}
`;

export const GET_TENANT_DATA_STANDARDS_GLOBAL_VIEWS = gql`
  query getTenantDataStandardsGlobalViews(
    $mapping_type: MappingType!
    $tenant_id: ID!
  ) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_standards {
        global_views(tenant_id: $tenant_id, mapping_type: $mapping_type) {
          group_mapping {
            property
            lease
            transactions
            work_order
          }
          global_data {
            id
            category
            total
            mismatch
            match
          }
        }
      }
    }
  }
`;

export const GET_TENANT_DATA_STANDARDS_MAPPED = gql`
  query getTenantDataStandardsMapped($tenant_id: ID!, $category: String!) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_standards {
        mapped(tenant_id: $tenant_id, category: $category) {
          id
          source_value
          target_value
        }
      }
    }
  }
`;

export const GET_TENANT_DATA_STANDARDS_UNMAPPED = gql`
  query getTenantDataStandardsUnmapped($tenant_id: ID!, $category: String!) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_standards {
        unmapped(tenant_id: $tenant_id, category: $category) {
          id
          source_value
          context_name
          record_keys {
            context_name
            record_keys
          }
        }
      }
    }
  }
`;

export const GET_TENANT_DATA_STANDARDS_TARGET_VALUES = gql`
  query getTenantDataStandardsTargetValues(
    $tenant_id: ID!
    $category: String!
  ) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_standards {
        target_possible_values(category: $category)
      }
    }
  }
`;

export const GET_TENANTS_LIST = gql`
  query getTenants($tenant_ids: [ID!]) {
    getTenants(tenant_ids: $tenant_ids) {
      ...TenantBase
    }
  }
  ${TenantBase.fragment}
`;

export const GET_TENANTS_UNIVERSAL_FILTERS = gql`
  query getTenantsUniversalFilters(
    $tenant_ids: [ID!]
    $nodes: TenantUniversalFilterNodeInput
  ) {
    getTenants(tenant_ids: $tenant_ids) {
      id
      tenant_geo_universal_filters(nodes: $nodes) {
        ...TenantGeoUniversalFiltersBase
      }
    }
  }
  ${TenantGeoUniversalFiltersBase.fragment}
`;

export const GET_TENANTS_RULE_BUILDER_SETTINGS = gql`
  query getTenantsRuleBuilderSettings(
    $tenant_ids: [ID!]
    $alert_type: TenantAlertSettingsType!
  ) {
    getTenants(tenant_ids: $tenant_ids) {
      id
      tenant_alert_rule_builder(alert_type: $alert_type) {
        ...TenantRuleBuilderSettingsBase
      }
    }
  }
  ${TenantRuleBuilderSettingsBase.fragment}
`;

export const GET_TENANT_TRACKER_DATA = gql`
  query getTenantTrackerData(
    $entity_type: EntityType!
    $event_type: EventType!
    $tenant_id: ID!
    $entity_id: String
  ) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_activity_logs(
        entity_type: $entity_type
        event_type: $event_type
        entity_id: $entity_id
      ) {
        ...LogBase
      }
    }
  }
  ${LogBase.fragment}
`;

export const GET_TENANT_DATA_LOADS_JOBS = gql`
  query getTenantDataLoadsJobs(
    $tenant_id: ID!
    $filters: DataLoadsJobsFiltersInput!
    $cursor: ID
  ) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_loads_jobs(filters: $filters, cursor: $cursor) {
        ...DataLoadsJobsBase
      }
    }
  }
  ${DataLoadsJobsBase.fragment}
`;

export const GET_TENANT_DATA_LOADS_JOB_LINKS = gql`
  query getTenantDataLoadsJobLinks($tenant_id: ID!, $job_id: ID!) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_loads_job_links(job_id: $job_id) {
        ...DataLoadsJobLinksBase
      }
    }
  }
  ${DataLoadsJobLinksBase.fragment}
`;

export const GET_TENANT_DATA_LOADS_CONTAINERS = gql`
  query getTenantDataLoadsContainers(
    $tenant_id: ID!
    $data: DataLoadsContainersInput
  ) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_data_loads_containers(data: $data) {
        input
        output
        error
      }
    }
  }
`;

export const GET_TENANT_PROPERTY_DATA = gql`
  query getTenantPropertyData($tenant_id: ID!, $filter: PropertyDataInput) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_property_data(filter: $filter) {
        ...PropertyDataBase
      }
    }
  }
  ${PropertyDataBase.fragment}
`;

export const GET_TENANT_PROPERTY_DATA_FILTER_VALUES = gql`
  query getTenantPropertyDataFilterValues(
    $tenant_id: ID!
    $filter: PropertyDataFilter!
  ) {
    getTenants(tenant_ids: [$tenant_id]) {
      id
      tenant_property_data_filter_values(filter: $filter)
    }
  }
`;
