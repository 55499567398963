import * as React from 'react';
import { Box } from '@mui/material';
import { Header } from '@components/typography';

interface Props {
  title: string;
}

const SettingsSection: React.FC<Props> = ({ children, title }) => {
  return (
    <Box my={3}>
      <Box my={2}>
        <Header secondary={true}>{title}</Header>
      </Box>
      <div>{children}</div>
    </Box>
  );
};

export default SettingsSection;
