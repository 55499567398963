import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { Tenant } from '@apollo-red/hooks';

export const getUpdatedUserTenantSettings = (
  tenant: Tenant,
  userId: string,
  userTenantSettings: SchemaTypes.UserBaseFragment['user_tenant_regular_settings'],
  update: SchemaTypes.UserTenantRegularSettingsInput,
) => {
  return userTenantSettings.map(item => {
    if (item.user_tenant.id !== tenant.id) {
      return item;
    }

    return {
      ...item,
      user_alerts_inbox_settings: item.user_insights_inbox_settings,
      user_alert_rule_builder_settings: update.user_alert_rule_builder_settings
        ? update.user_alert_rule_builder_settings.map(s => ({
            ...s,
            id: `${userId}_${tenant.id}_${s.alert_type}`,
          }))
        : item.user_alert_rule_builder_settings,
      default_data_application: update.default_data_application
        ? tenant?.tenant_applications.find(
            ({ app }) => app.id === update.default_data_application,
          )?.app
        : item.default_data_application,
      user_reports_order: update.user_reports_order ?? item.user_reports_order,
    };
  });
};
