import { useGetUserInfo } from '@apollo-red/hooks';
import * as React from 'react';
import { useHistory } from 'react-router';

const ClientLegacyRedirect: React.FC = () => {
  const { user } = useGetUserInfo();
  const {
    push,
    location: { pathname },
  } = useHistory();

  const [path, setPath] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (!path) {
      setPath(pathname);
    }
  }, [path, pathname]);

  React.useEffect(() => {
    if (user.user_settings.default_tenant?.tenant_ovc_id && path) {
      const newPath = path.split('/').slice(2).join('/');
      push(`/${user.user_settings.default_tenant.tenant_ovc_id}/${newPath}`);
    }
  }, [path, push, user?.user_settings.default_tenant?.tenant_ovc_id]);

  return null;
};

export default ClientLegacyRedirect;
