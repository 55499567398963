import * as React from 'react';
import { useIsUserActiveForCurrentTenant } from '@apollo-red/hooks';
import NoData from '@components/noData';

const ActiveUserOnly: React.FC<{}> = ({ children }) => {
  const isUserActive = useIsUserActiveForCurrentTenant();

  if (!isUserActive) {
    return <NoData message="userInactive" />;
  }

  return <>{children}</>;
};

export default ActiveUserOnly;
