import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { RESET_CONTAINERS_ACCESS_SAS_URL } from '@apollo-red/mutations';
import { useAppMutation } from '../../queries';

export const useResetDataLoadsContainers = (tenantOvcId: string) => {
  const [resetContainer, { loading }] = useAppMutation<
    SchemaTypes.ResetContainerAccessSasUrlMutation,
    SchemaTypes.ResetContainerAccessSasUrlMutationVariables
  >(RESET_CONTAINERS_ACCESS_SAS_URL, {
    fetchPolicy: 'no-cache',
  });

  return {
    resetDataLoadsContainers: (containerName: string) =>
      resetContainer({
        variables: {
          where: {
            container_name: containerName,
            reset_key: true,
            tenant_ovc_id: tenantOvcId,
          },
        },
      }),
    loading,
  };
};
