import * as React from 'react';
import { useTableauUrlWithTrustedAuth } from '@utils/tableau';
import { useGetUserDefaultTenantId } from '@apollo-red/hooks';
import OpenInNewTab from './OpenInNewTab';

interface Props {
  reportId: string;
  url: string;
  handleOpen: (val: boolean) => void;
}

const OpenInNewTabWithTrustedAuth: React.FC<Props> = ({
  handleOpen,
  reportId,
  url,
}) => {
  const tenantId = useGetUserDefaultTenantId();
  const viewUrl = useTableauUrlWithTrustedAuth({
    tenantId,
    reportId,
    url: url,
  });

  return <OpenInNewTab handleOpen={handleOpen} url={viewUrl} />;
};

export default OpenInNewTabWithTrustedAuth;
