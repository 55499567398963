import * as React from 'react';
import { Link } from 'react-router-dom';
import { DashboardRouteProps } from '@pages/dashboards/types';

interface DashboardLinkProps {
  id: string;
  command: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  routes: DashboardRouteProps;
}

export const DashboardCommandLink: React.FC<DashboardLinkProps> =
  React.forwardRef<HTMLElement, DashboardLinkProps>(
    ({ id, command, style, children, disabled, routes }, _ref) => {
      if (disabled) {
        return <div>{children}</div>;
      }
      return (
        <Link
          style={style}
          to={routes?.dashboard.getLink('all', String(id), command)}
        >
          {children}
        </Link>
      );
    },
  );
