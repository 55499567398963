import {
  FilterValueMapping,
  ParameterValueMapping,
  PinFilter,
  PinParameter,
  ReportFilterData,
  ReportType,
  ReportControlWithoutTypename,
  BaselineReportControlWithoutTypename,
} from '../types';

export const getInitialPinnedParameters = (
  pinnedParameters: PinParameter[],
  controls: ReportControlWithoutTypename[],
) => {
  return pinnedParameters?.reduce((acc, pinnedParameter) => {
    if (
      pinnedParameter?.selectedOption?.value &&
      controls?.some(control => control.name === pinnedParameter.name)
    ) {
      return {
        ...acc,
        [pinnedParameter.name]: pinnedParameter?.selectedOption?.value,
      };
    }

    return acc;
  }, {} as ParameterValueMapping);
};

export const getInitialPinnedParametersForBaselineReport = (
  pinnedParameters: PinParameter[],
  controls: BaselineReportControlWithoutTypename[],
) => {
  return pinnedParameters?.reduce((acc, pinnedParameter) => {
    if (
      pinnedParameter?.selectedOption?.value &&
      controls?.some(control => control.filter_id === pinnedParameter.filter_id)
    ) {
      return {
        ...acc,
        [pinnedParameter.name]: pinnedParameter?.selectedOption?.value,
      };
    }

    return acc;
  }, {} as ParameterValueMapping);
};

export const getInitialPinnedFilters = (
  pinnedFilters: PinFilter[],
  controls: ReportControlWithoutTypename[],
) => {
  return pinnedFilters?.reduce((acc, pinnedFilter) => {
    if (
      pinnedFilter?.selectedValues &&
      !pinnedFilter.isExcluded &&
      pinnedFilter?.selectedValues?.length > 0 &&
      controls?.some(control => control.name === pinnedFilter.name)
    ) {
      return {
        ...acc,
        [pinnedFilter.name]: pinnedFilter?.selectedValues
          ?.map(selectedValue => selectedValue.value)
          .join(','),
      };
    }

    return acc;
  }, {} as FilterValueMapping);
};

export const getInitialPinnedFiltersForBaselineReport = (
  pinnedFilters: PinFilter[],
  controls: BaselineReportControlWithoutTypename[],
) => {
  return pinnedFilters?.reduce((acc, pinnedFilter) => {
    if (
      pinnedFilter?.selectedValues &&
      !pinnedFilter.isExcluded &&
      pinnedFilter?.selectedValues?.length > 0 &&
      controls?.some(control => control.filter_id === pinnedFilter.filter_id)
    ) {
      return {
        ...acc,
        [pinnedFilter.name]: pinnedFilter?.selectedValues
          ?.map(selectedValue => selectedValue.value)
          .join(','),
      };
    }

    return acc;
  }, {} as FilterValueMapping);
};

export const getInitialPinnedFilterNames = (
  data: ReportFilterData,
  isCoreDashboard: boolean,
  id: string,
) => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;
  return data[id]?.[reportType]?.Filter?.map(filter => filter.name);
};

export const getInitialPinnedParameterNames = (
  data: ReportFilterData,
  isCoreDashboard: boolean,
  id: string,
) => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;
  return data[id]?.[reportType]?.Parameter?.map(parameter => parameter.name);
};

export const calculatePinnedValuesLength = (pinnedValues: {
  [key: string]: string;
}) => {
  let pinnedQueryString = '';

  for (const key in pinnedValues) {
    pinnedQueryString += `${key}=${pinnedValues[key]}&`;
  }

  return pinnedQueryString.length ?? 0;
};

export const getApplicablePinnedFilters = (
  pinnedFilters: PinFilter[],
  controls: ReportControlWithoutTypename[],
) => {
  return pinnedFilters?.filter(pinnedFilter =>
    controls.some(control => control.name === pinnedFilter.name),
  );
};
