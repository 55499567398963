import { useGetInsightsInboxAlertState } from '../inbox';
import { useGetInsightsAlertHistory } from './useGetInsightsAlertHistory';
import { useGetInsightsAlertRoiData } from './useGetInsightsAlertRoiData';

export const useGetInsightsAlertBody = (threadId: string) => {
  const { userId, tenantId } = useGetInsightsInboxAlertState();
  const { loading: alertHistoryLoading, history: alertHistory } =
    useGetInsightsAlertHistory(userId, tenantId, threadId);

  const { loading: roiLoading, roiData } = useGetInsightsAlertRoiData(
    userId,
    tenantId,
    threadId,
  );

  return {
    loading: alertHistoryLoading || roiLoading,
    alertHistory,
    roiData,
  };
};
