import * as React from 'react';
import { SuspenseLoader } from './SuspenseLoader';

interface SuspenseProps {
  fallback?: React.SuspenseProps['fallback'];
}

const Suspense: React.FC<SuspenseProps> = ({ children, fallback }) => {
  return (
    <React.Suspense fallback={fallback ?? <SuspenseLoader />}>
      {children}
    </React.Suspense>
  );
};

export default Suspense;
