import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import * as React from 'react';
import { SYNC_USER_KPI } from '@apollo-red/mutations';
import { useGetUserInfo } from '../user';
import { getUserDefaultTenantId } from '../settings';
import { useAppMutation } from '../../queries';

export type UserKpi = SchemaTypes.UserKpiBaseFragment;

export type UserKpiData = SchemaTypes.UserKpiDataBaseFragment;

export type TenantKpiDataBase = SchemaTypes.UserKpiBaseFragment;

export interface TenantKpiData extends TenantKpiDataBase {
  not_found?: boolean;
}

const defaultTenantKpiData = {
  tenant_id: '',
  tenant_ovc_id: '',
  updated: '0',
  request_sync: false,
  kpi_data: {},
};

export const useGetUserKpis = () => {
  const { loading, user } = useGetUserInfo();
  const defaultTenantId = getUserDefaultTenantId(user);

  const found = (user.user_kpi_data || []).find(
    kpi => kpi.tenant_id === defaultTenantId,
  );

  return {
    loading,
    tenantKpiData: found
      ? { ...found, not_found: false }
      : { ...defaultTenantKpiData, not_found: !loading },
  };
};

export const useGetUserKpisWithSync = () => {
  const [syncUserKpi] = useAppMutation(SYNC_USER_KPI);
  const { user } = useGetUserInfo();
  const { loading: userDataLoading, tenantKpiData } = useGetUserKpis();
  const [loading, setLoading] = React.useState(false);
  const [tried, setTried] = React.useState(false);
  const [error, setError] = React.useState(false);

  const shouldRequestSync =
    !tried && (tenantKpiData.not_found || tenantKpiData.request_sync);

  React.useEffect(() => {
    if (shouldRequestSync && user.user_settings.default_tenant) {
      setLoading(true);
      syncUserKpi({
        variables: {
          tenantId: user.user_settings.default_tenant.id,
          tenantOvcId: user.user_settings.default_tenant.tenant_ovc_id,
          where: {
            id: user.id,
          },
        },
      })
        .then(() => {
          setLoading(false);
          setTried(true);
        })
        .catch(_ => {
          setLoading(false);
          setTried(true);
          setError(true);
        });
    }
  }, [shouldRequestSync, syncUserKpi, user]);

  return {
    loading: userDataLoading || loading,
    error,
    tenantKpiData,
  };
};
