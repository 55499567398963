import { gql } from '@apollo/client';

export const LOG_ERROR = gql`
  mutation logError($data: ErrorLogInput!) {
    logError(data: $data)
  }
`;

export const LOG_PERFORMANCE = gql`
  mutation logPerformance($data: ReportPerformanceLogInput!) {
    logPerformance(data: $data)
  }
`;

export const LOG_USER_ACTIVITY = gql`
  mutation logUserActivity {
    logUserActivity
  }
`;
