import * as React from 'react';
import { Grid, CircularProgress, useTheme, Box } from '@mui/material';
import { MessagePopper, Scroll } from '@jll-labs/azara-ui-components';
import {
  TenantAlertSettingsType,
  useGetTenantAlertCategories,
  useGetUserDefaultTenantId,
  UserAlertRule,
} from '@apollo-red/hooks';
import { SubHeader } from '@components/typography';
import {
  getCategoriesExcluded,
  showExternalAlerts,
  getTableObject,
} from '../AlertSettings.utils';
import { TableObject } from '../AlertSettings.constants';

interface Props {
  selectedRule: UserAlertRule;
  type: 'internal' | 'external';
}

const useStyles = () => {
  const theme = useTheme();
  return {
    active: {
      color: theme.palette.action.active,
      cursor: 'pointer',
    },
    unselected: {
      marginTop: theme.spacing(2),
    },
    popper: {
      '& [data-element="arrow"]': {
        marginLeft: 20,
      },
    },
  };
};

const SelectedCategoriesCell: React.FC<Props> = ({ selectedRule, type }) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const tenantId = useGetUserDefaultTenantId();
  const { tenantAlertCategories, loading } = useGetTenantAlertCategories(
    tenantId,
    selectedRule.alert_type,
  );
  const categoriesShown = getCategoriesExcluded(
    tenantAlertCategories,
    selectedRule.categories_excluded,
    selectedRule.alert_type,
    true,
  );
  const categories = categoriesShown[type] || [];
  const object: TableObject = getTableObject(categories);

  if (!showExternalAlerts(selectedRule.alert_type) && type === 'external') {
    return <div>{'--'}</div>;
  }

  if (
    selectedRule.alert_type === TenantAlertSettingsType.OccupancyEfficiency ||
    selectedRule.alert_type === TenantAlertSettingsType.SubleaseRisk ||
    selectedRule.alert_type ===
      TenantAlertSettingsType.SameDayWorkOrderCompletion
  ) {
    return <div>{'--'}</div>;
  }

  if (loading) {
    return (
      <Grid container={true}>
        <CircularProgress size={24} color="primary" />
      </Grid>
    );
  }

  if (!object || !object.name) {
    return <div>{'--'}</div>;
  }

  const handlePopperOpen = evt => {
    setAnchorEl(evt.currentTarget);
    setOpen(true);
  };

  const handlePopperClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {object.name}
      {Boolean(object.length) && (
        <>
          {`, `}
          <Box component="span" onClick={handlePopperOpen} sx={styles.active}>
            {' +' + object.length}
          </Box>
          <MessagePopper
            classes={{ popper: styles.popper }}
            message={{ position: 'bottom-start' }}
            isOpen={open}
            anchorEl={anchorEl}
            handleClose={handlePopperClose}
          >
            <Scroll maxHeight={200}>
              <div>
                <SubHeader bold={true}>{'Selected:'}</SubHeader>
                {object.selected}
              </div>
              {object.unselected && (
                <Box sx={styles.unselected}>
                  <SubHeader bold={true}>{'Unselected:'}</SubHeader>
                  {object.unselected}
                </Box>
              )}
            </Scroll>
          </MessagePopper>
        </>
      )}
    </div>
  );
};

export default SelectedCategoriesCell;
