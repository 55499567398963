import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import {
  MenuSelect,
  UserAvatarIcon,
  SelectOption,
} from '@jll-labs/azara-ui-components';
import { AppTheme } from '@theme';
import { InsightsAlertType } from '@apollo-red/hooks';

const useStyles = makeStyles((theme: AppTheme) => ({
  root: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
  userName: {
    marginLeft: theme.spacing(1),
  },
  zeroMinWidthMenuSelect: {
    minWidth: 0,
  },
}));

interface Props {
  handleUserChange: (
    userId: any,
    alertType?: InsightsAlertType,
    threadId?: string,
  ) => void;
  userId?: string;
  userOptions: SelectOption[];
  activeLabel?: boolean;
  fontSize?: 'small' | 'inherit' | 'medium' | 'large';
  label?: React.ReactNode;
}

const AlertSelectUser: React.FC<Props> = ({
  handleUserChange,
  userId,
  userOptions,
  activeLabel = true,
  fontSize,
  label,
}) => {
  const classes = useStyles();

  const handleChange = (opt: SelectOption) => {
    handleUserChange(opt);
  };

  return (
    <div className={classes.root}>
      <MenuSelect
        options={userOptions}
        onChange={handleChange}
        value={userId ?? null}
        label={
          <Grid
            container={true}
            alignItems="center"
            justifyContent="space-between"
          >
            {label && <div>{label}</div>}
            <UserAvatarIcon
              active={Boolean(userId) && activeLabel}
              style={{ marginRight: '-12px' }}
              fontSize={fontSize}
            />
          </Grid>
        }
        size="sm"
        buttonClassName={classes.zeroMinWidthMenuSelect}
        maxHeight={400}
      />
    </div>
  );
};

export default AlertSelectUser;
