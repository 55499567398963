import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { LOGIN_USER } from '@apollo-red/mutations';
import { GET_USER_DATA } from '@apollo-red/queries';
import { useAppMutation } from '../../queries';

interface LoginUserArgs {
  userId: string;
  tenantOvcId?: string;
}

export const useLoginUser = () => {
  const [loginUser, { loading }] = useAppMutation<
    SchemaTypes.LoginUserMutation,
    SchemaTypes.LoginUserMutationVariables
  >(LOGIN_USER, {
    onError: () => {
      return;
    },
  });

  return {
    loading,
    loginUser: React.useCallback(
      async ({ userId, tenantOvcId }: LoginUserArgs) => {
        const { data } = await loginUser({
          variables: {
            user_id: userId,
            tenant_ovc_id: tenantOvcId,
          },
          update: (cache, { data }) => {
            if (data?.loginUser) {
              cache.writeQuery<
                SchemaTypes.GetUserQuery,
                SchemaTypes.GetUserQueryVariables
              >({
                query: GET_USER_DATA,
                variables: { where: { id: userId } },
                data: {
                  getUser: { ...data.loginUser },
                },
              });
            }
          },
        });

        return data?.loginUser;
      },
      [loginUser],
    ),
  };
};
