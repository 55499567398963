import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_API_VERSION } from '@apollo-red/queries';
import { useAppQuery } from '../queries';

export type ApiVersion = SchemaTypes.ApiVersionBaseFragment;

export const useApiVersion = (pollInterval?: number) => {
  const { loading, data } = useAppQuery<SchemaTypes.GetApiVersionQuery>(
    GET_API_VERSION,
    {
      pollInterval,
    },
  );

  return {
    loading,
    hasData: !!data?.getApiVersion,
    apiVersion: data?.getApiVersion?.version ?? '',
    apiBuildUtc: data?.getApiVersion?.build_date ?? '',
    tenantQaType: data?.getApiVersion?.tenant_qa_type,
  };
};
