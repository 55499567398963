import {
  BaselineReportControlWithoutTypename,
  BaselineInteractionSection,
  ReportControlWithoutTypename,
  ReportFiltersInteractionSection,
} from '../../types';

export const flattenControlsInSections = <
  T extends ReportFiltersInteractionSection,
  U extends T extends BaselineInteractionSection
    ? BaselineReportControlWithoutTypename
    : ReportControlWithoutTypename,
>(
  sections?: T[],
): U[] => {
  if (!sections || sections.length === 0) {
    return [];
  }

  return sections.reduce((acc, { controls }) => {
    return [...acc, ...controls] as U[];
  }, [] as U[]);
};
