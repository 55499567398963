import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { UPDATE_USER_TENANT_UNIVERSAL_FILTERS } from '@apollo-red/mutations';
import { useGetUserInfo } from '../user';
import { useAppMutation } from '../../queries';

export type UserUniversalFilter = SchemaTypes.UserUniversalFilterBaseFragment;
export type UserUniversalFilters = SchemaTypes.UserUniversalFilters;

export const createDefaultFilters = (
  filter?: SchemaTypes.UserUniversalFilterWithMeta | null,
) => {
  return {
    name: filter?.name ?? '',
    filters: filter?.filters ?? [],
  };
};

export const useGetUserUniversalFiltersForTenant = (tenantId: string) => {
  const { loading, user } = useGetUserInfo();

  const found = user.user_settings.universal_filters
    ? user.user_settings.universal_filters.find(
        filter => filter.tenant_id === tenantId,
      )
    : undefined;

  return {
    loading,
    universalFilters: React.useMemo(
      () => ({
        countries: createDefaultFilters(found?.filters.countries),
        states: createDefaultFilters(found?.filters.states),
        cities: createDefaultFilters(found?.filters.cities),
        properties: createDefaultFilters(found?.filters.properties),
        unit_of_measurement:
          found?.filters.unit_of_measurement ?? SchemaTypes.AreaUnit.Sqft,
        currency: found?.filters.currency ?? SchemaTypes.CurrencyUnit.Usd,
      }),
      [
        found?.filters.cities,
        found?.filters.countries,
        found?.filters.currency,
        found?.filters.properties,
        found?.filters.states,
        found?.filters.unit_of_measurement,
      ],
    ),
  };
};

export const useGetUserCurrentUniversalFilters = () => {
  const { user } = useGetUserInfo();
  const { user_settings, user_tenant_settings } = user;
  const { default_tenant } = user_settings;
  const { id } = default_tenant ?? {};

  const found = id
    ? user_tenant_settings.find(t => t.user_tenant.id === id)
    : undefined;

  const { loading, universalFilters } = useGetUserUniversalFiltersForTenant(
    found ? found.user_tenant.id : '',
  );

  return {
    loading,
    universalFilters,
  };
};

export const useUpdateUserUniversalFilters = () => {
  const { user } = useGetUserInfo();

  const [updateUserUniversalFilters] = useAppMutation<
    SchemaTypes.UpdateUserUniversalFiltersMutation,
    SchemaTypes.UpdateUserUniversalFiltersMutationVariables
  >(UPDATE_USER_TENANT_UNIVERSAL_FILTERS);

  return {
    updateUserUniversalFilters: (
      tenantId: string,
      tenantOvcId: string,
      values: SchemaTypes.UserUniversalFiltersInput,
    ) => {
      return updateUserUniversalFilters({
        variables: {
          data: {
            tenant_id: tenantId,
            tenant_ovc_id: tenantOvcId,
            filters: values,
          },
          where: {
            id: user.id,
          },
        },
      });
    },
  };
};
