import * as React from 'react';
import {
  useGetUserDefaultTenant,
  useGetUserInfo,
  UserProvisioningStatus,
} from '@apollo-red/hooks';
import { useWorkRolesForm } from '@components/workRoles';
import '@components/pendo/types';
import { usePendoEnvironmentPrefix } from './usePendoEnvironmentPrefix';
import { usePendoScreenInfo } from './usePendoScreenInfo';

/**
 * See Pendo Developer documentation:
 * - https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo
 * - https://developers.pendo.io/docs/?bash#initialization-and-identification
 */

export const usePendo = () => {
  const environmentPrefix = usePendoEnvironmentPrefix();
  const { screenInfo } = usePendoScreenInfo();
  const { user, productAnalytics } = useGetUserInfo();
  const { userWorkRole: workRole } = useWorkRolesForm();
  const { defaultTenant } = useGetUserDefaultTenant();
  const defaultTenantId = defaultTenant?.id ?? '';
  const tenantName = defaultTenant?.tenant_name ?? '';
  const tenantOvcId = defaultTenant?.tenant_ovc_id ?? '';
  const tenantType = defaultTenant?.tenant_type ?? '';
  const accountIdPrefix = tenantOvcId ? environmentPrefix : '';
  const userId = user?.id;
  const userEmail = user?.user_email;
  const userName = user?.user_full_name;
  const userClassification = user?.user_type ?? '';
  const userRegion = user.user_address.region?.region_name;
  const userCountry = user.user_address.country?.country_desc;
  const tenantSettings = user.user_tenant_settings.find(
    t => t.user_tenant.id === defaultTenantId,
  );
  const userRole =
    tenantSettings?.user_provisioning_status === UserProvisioningStatus.Done
      ? tenantSettings?.user_role?.role_name
      : undefined;
  const userWorkArea = workRole?.work_area;
  const userWorkSpecialization = workRole?.specialization;
  const userWorkRole = workRole?.role;
  const { isReady, setIsReady } = productAnalytics;

  React.useEffect(() => {
    if (!window.pendo) {
      return;
    }

    if (isReady) {
      return;
    }

    if (!userId && !tenantOvcId) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: userId,
        email: userEmail,
        full_name: userName,
        role: userRole ?? false,
        region: userRegion ?? false,
        country: userCountry ?? false,
        workArea: userWorkArea ?? false,
        workSpecialization: userWorkSpecialization ?? false,
        workRole: userWorkRole ?? false,
        userClassification,
        ...screenInfo,
      },
      account: {
        id: `${accountIdPrefix}${tenantOvcId}`,
        name: tenantName,
        type: tenantType,
      },
      events: {
        ready: () => setIsReady(true),
      },
    });
  }, [
    accountIdPrefix,
    isReady,
    screenInfo,
    setIsReady,
    tenantName,
    tenantOvcId,
    tenantType,
    userClassification,
    userCountry,
    userEmail,
    userId,
    userName,
    userRegion,
    userRole,
    userWorkArea,
    userWorkRole,
    userWorkSpecialization,
  ]);

  React.useEffect(() => {
    if (!window.pendo) {
      return;
    }

    if (!isReady) {
      return;
    }

    pendo.identify({
      visitor: {
        id: userId,
        email: userEmail,
        full_name: userName,
        role: userRole ?? false,
        region: userRegion ?? false,
        country: userCountry ?? false,
        workArea: userWorkArea ?? false,
        workSpecialization: userWorkSpecialization ?? false,
        workRole: userWorkRole ?? false,
        userClassification,
        ...screenInfo,
      },
      account: {
        id: `${accountIdPrefix}${tenantOvcId}`,
        name: tenantName,
        type: tenantType,
      },
    });
  }, [
    accountIdPrefix,
    isReady,
    screenInfo,
    tenantName,
    tenantOvcId,
    tenantType,
    userClassification,
    userCountry,
    userEmail,
    userId,
    userName,
    userRegion,
    userRole,
    userWorkArea,
    userWorkRole,
    userWorkSpecialization,
  ]);
};
