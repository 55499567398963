import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { AppTheme } from '@theme';

const useStyles = makeStyles((theme: AppTheme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 99,
    marginBottom: theme.spacing(0.5),
  },
  title: {
    width: '70%',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  closeButton: {
    cursor: 'pointer',
  },
}));

interface Props {
  title: string;
}

const SectionHeader: React.FC<Props> = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
    </div>
  );
};

export default SectionHeader;
