import * as React from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteOption,
  utils,
} from '@jll-labs/azara-ui-components';
import { ReportControlStaticListItem } from '@apollo-red/hooks';
import { isFilterSelectionEqual } from '../utils';

const { useToggleValue } = utils;

export type Option = Pick<ReportControlStaticListItem, 'label' | 'value'>;

interface Props {
  options: Option[];
  loading: boolean;
  label?: string;
  values?: ReportControlStaticListItem[];
  updateValues: (selectedValues: ReportControlStaticListItem[]) => void;
  onClear: () => void;
}

const MultiSelect: React.FC<Props> = ({
  options,
  loading,
  label,
  values,
  updateValues,
  onClear,
}) => {
  const [selectedValues, setSelectedValues] = React.useState<
    AutocompleteOption[]
  >([]);
  const [changeReason, setChangeReason] =
    React.useState<AutocompleteChangeReason>();
  const [loadingIndicator, toggleLoadingIndicator] = useToggleValue(false);

  React.useEffect(() => {
    if (!loading && loadingIndicator) {
      toggleLoadingIndicator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadingIndicator]);

  React.useEffect(() => {
    setSelectedValues(
      values?.map(({ label, value, selected }) => ({
        label,
        value,
        selected: !!selected,
      })) ?? [],
    );
  }, [values]);

  const handleChange = (_, options, reason: AutocompleteChangeReason) => {
    setSelectedValues(options);
    setChangeReason(reason);
  };

  const handleClose = () => {
    if (selectedValues.length === 0) {
      if (changeReason === 'removeOption') {
        onClear();
      }
    }

    if (!isFilterSelectionEqual(values, selectedValues)) {
      updateValues(selectedValues);
    }
  };

  const handleClear = (_, __, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear') {
      onClear();
    }
  };

  return (
    <Autocomplete
      label={label ?? ''}
      multiple={true}
      options={options}
      onChange={handleChange}
      onBlur={handleClose}
      value={selectedValues}
      loading={loadingIndicator}
      disabled={loading}
      inputLabelWidth="md"
      inputMaxWidth="md"
      coloredOutline={!!selectedValues?.length}
      size="sm"
      disableTextareaPadding={true}
      onInputChange={handleClear}
    />
  );
};

export default MultiSelect;
