import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import * as Resolvers from '@apollo-red/schema/generated/resolvers';
import { UserInsightsInboxSettingsBase } from '@apollo-red/fragments';

const UserTenantRegularSettings: Resolvers.UserTenantRegularSettingsResolvers =
  {
    // @ts-ignore
    user_insights_inbox_settings: (parent, _, ctx) => {
      const id = `UserInsightsInboxSettings:${parent.id}`;

      const inboxSettings =
        ctx.cache.readFragment<SchemaTypes.UserInsightsInboxSettingsBaseFragment>(
          {
            fragmentName: 'UserInsightsInboxSettingsBase',
            fragment: UserInsightsInboxSettingsBase.fragment,
            id,
          },
        );

      if (inboxSettings) {
        return inboxSettings;
      }

      return {
        id: parent.id,
        insights_inbox_alerts: Object.keys(Resolvers.InsightsAlertInbox).map(
          key => {
            return {
              insights_inbox_type: Resolvers.InsightsAlertInbox[
                key
              ] as Resolvers.InsightsAlertInbox,
              insights_alert_selected: null,
              alerts: [],
              __typename: 'InsightsInboxAlerts',
            };
          },
        ),
        insights_selected_filters: {
          insights_alert_type_filter: Resolvers.InsightsAlertTypeFilter.All,
          insights_importance_filter:
            Resolvers.InsightsAlertImportanceFilter.All,
          insights_alert_severity: Resolvers.InsightsAlertSeverityFilter.All,
          insights_alert_assigned_to: 'all',
          __typename: 'InsightsSelectedFilters',
        },
        insights_selected_sort_order: {
          created_date: Resolvers.InsightsAlertSortOrder.Desc,
          __typename: 'InsightsInboxSelectedSortOrder',
        },
        __typename: 'UserInsightsInboxSettings',
      };
    },
  };

export default UserTenantRegularSettings;
