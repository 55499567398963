import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  ADD_TENANT_REPORT,
  DELETE_TENANT_REPORT,
  UPDATE_REPORT,
} from '@apollo-red/mutations';
import {
  GET_REPORTS,
  GET_REPORT_BY_ID,
  GET_REPORT_DATA_UPDATE_BY_ID,
} from '@apollo-red/queries';
import { useAppQuery, useAppMutation } from '../queries';
import { filterItems } from '@utils/various';

export type Report = SchemaTypes.ReportBaseFragment;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import ReportControlType = SchemaTypes.ReportControlType;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import ReportSectionType = SchemaTypes.ReportSectionType;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import ReportAnchorTarget = SchemaTypes.ReportAnchorTarget;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import ExternalReportType = SchemaTypes.ExternalReportType;

export const fallbackReport: Report = {
  id: '',
  report_cat: [],
  report_full_keywords: [],
  report_name: '',
  report_tableau_full_report_url: '',
  report_external_anchor_target: ReportAnchorTarget.NewTab,
  report_tableau_url: '',
  report_active: false,
};

export const useGetReportById = (reportId: string) => {
  const { loading, data } = useAppQuery<SchemaTypes.GetReportQuery>(
    GET_REPORT_BY_ID,
    {
      variables: {
        report_ids: [reportId],
      },
    },
  );
  return {
    loading,
    report:
      data && data.getReports && data.getReports[0]
        ? data.getReports[0]
        : fallbackReport,
  };
};

export const useGetReportDataUpdateById = (
  reportId: string,
  skip?: boolean,
) => {
  const { loading, data } =
    useAppQuery<SchemaTypes.GetReportDataUpdateDateQuery>(
      GET_REPORT_DATA_UPDATE_BY_ID,
      {
        variables: {
          report_ids: [reportId],
        },
        fetchPolicy: 'network-only',
        skip,
      },
    );
  return {
    loading,
    report:
      data && data.getReports && data.getReports[0]
        ? data.getReports[0]
        : {
            id: fallbackReport.id,
            report_data_update_date: '',
          },
  };
};

export const useGetAllReports = () => {
  const { loading, data } =
    useAppQuery<SchemaTypes.GetReportQuery>(GET_REPORTS);
  return {
    loading,
    allReports: filterItems(data?.getReports ?? []),
  };
};

export const useAddTenantReport = () => {
  const [addReport] = useAppMutation<
    SchemaTypes.AddTenantReportMutation,
    SchemaTypes.AddTenantReportMutationVariables
  >(ADD_TENANT_REPORT);

  return {
    addTenantReport: (
      tenantId: string,
      addReportFields: SchemaTypes.CreateReportInput,
    ) => {
      return addReport({
        variables: {
          data: addReportFields,
          where: {
            id: tenantId,
          },
        },
      });
    },
  };
};

export const useDeleteTenantReport = () => {
  const [deleteReport] = useAppMutation<
    SchemaTypes.DeleteTenantReportMutation,
    SchemaTypes.DeleteTenantReportMutationVariables
  >(DELETE_TENANT_REPORT);

  return {
    deleteTenantReport: (tenantId: string, reportId: string) => {
      return deleteReport({
        variables: {
          where: {
            report_id: reportId,
            tenant_id: tenantId,
          },
        },
      });
    },
  };
};

export const useUpdateReport = () => {
  const [updateReport] = useAppMutation<
    SchemaTypes.UpdateTenantReportMutation,
    SchemaTypes.UpdateTenantReportMutationVariables
  >(UPDATE_REPORT);

  return {
    updateReport: (
      reportId: string,
      tenantId: string,
      reportData: SchemaTypes.UpdateReportInput,
    ) => {
      return updateReport({
        variables: {
          data: reportData,
          where: {
            tenant_id: tenantId,
            report_id: reportId,
          },
        },
      });
    },
  };
};
