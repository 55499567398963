import * as React from 'react';
import { useHistory } from 'react-router';
import { useRoutes } from '@routes';
import {
  useGetUserInfo,
  useIsUserActiveForCurrentTenant,
  useGetCurrentTenantRole,
  UserOnboardingStatus,
  useGetUserCurrentTenantRegularSettings,
  useUpdateUserTenantRegularSettings,
} from '@apollo-red/hooks';
import { Box, useTheme } from '@mui/material';
import { useFeatureFlag } from '@utils/launchDarkly';
import OnBoardingRoutes from './OnBoardingRoutes';

const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper,
    },
  };
};

export interface ValidateJumpStepper {
  label: string;
  value: boolean;
  disabled: boolean;
  path: string;
}

const STARTING_PAGE = 0;
const STEPS = 6;
const LAST_PAGE = STEPS - 1;

const OnBoarding: React.FC<{}> = ({ children }) => {
  const routes = useRoutes();
  const history = useHistory();
  const { user } = useGetUserInfo();
  const styles = useStyles();
  const [activeStep, setActiveStep] = React.useState(STARTING_PAGE);
  const isUserActive = useIsUserActiveForCurrentTenant();
  const { hasRoleAssigned } = useGetCurrentTenantRole();
  const requireOnboarding = useFeatureFlag('userOnboardingRequireOnboarding');
  const defaultTenantName = user.user_settings.default_tenant
    ? user.user_settings.default_tenant.tenant_name
    : '';

  const initialCompletionStatus: ValidateJumpStepper[] = [
    {
      label: 'Welcome',
      value: true,
      disabled: false,
      path: routes.onBoarding.welcome.getLink(),
    },
    {
      label: 'Organizational Role',
      value: false,
      disabled: false,
      path: routes.onBoarding.roles.getLink(),
    },
    {
      label: 'Scope by Location',
      value: false,
      disabled: false,
      path: routes.onBoarding.scope.getLink(),
    },
    {
      label: 'Favorite Dashboards',
      value: false,
      disabled: false,
      path: routes.onBoarding.favorites.getLink(),
    },
    {
      label: 'Contact Information',
      value: false,
      disabled: false,
      path: routes.onBoarding.contact.getLink(),
    },
    {
      label: 'Summary',
      value: false,
      disabled: false,
      path: routes.onBoarding.summary.getLink(),
    },
  ];

  const [completed, setCompleted] = React.useState<ValidateJumpStepper[]>(
    initialCompletionStatus,
  );
  const { userTenantRegularSettings } =
    useGetUserCurrentTenantRegularSettings();
  const { updateUserTenantRegularSettings } =
    useUpdateUserTenantRegularSettings();

  const handleDoneClick = async () => {
    if (user.user_settings.default_tenant) {
      await updateUserTenantRegularSettings(
        user.user_settings.default_tenant.id,
        {
          user_onboarding: {
            status: UserOnboardingStatus.Completed,
          },
        },
      );
    }

    history.push(routes.dashboards.favorites.getLink());
  };

  const handleNextClick = (step: number) => {
    const isLastStep = step === LAST_PAGE;

    if (isLastStep) {
      handleDoneClick();
    } else {
      setActiveStep(step + 1);
      setCompleted(completed =>
        completed.map((item, i) => ({ ...item, value: i <= step + 1 })),
      );
      history.push(initialCompletionStatus[step + 1].path);
    }
  };

  const handleBackClick = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepClick = (step: number) => () => {
    setActiveStep(step);

    const path = initialCompletionStatus[step].path;
    history.push(path);
  };

  const getLastCompletedStepIndex = () => {
    const copy = [...completed];
    const index = copy.reverse().findIndex(item => item.value);

    return copy.length - index - 1;
  };

  React.useEffect(() => {
    setCompleted(completed =>
      completed.map((item, index) =>
        index === activeStep ? { ...item, value: true } : item,
      ),
    );
  }, [activeStep]);

  const onboardingProps = {
    defaultTenantName,
    handleNextClick,
    handleBackClick,
    handleStepClick,
    activeStep,
    isLastPage: activeStep === LAST_PAGE,
    isFirstPage: activeStep === STARTING_PAGE,
    steps: STEPS,
    setActiveStep,
    completed,
    lastCompletedStep: getLastCompletedStepIndex(),
  };

  if (
    !requireOnboarding ||
    userTenantRegularSettings?.user_onboarding?.status ===
      UserOnboardingStatus.Completed ||
    !user.user_settings.default_tenant ||
    !isUserActive ||
    !hasRoleAssigned
  ) {
    return <>{children}</>;
  }

  return (
    <Box sx={styles.root}>
      <OnBoardingRoutes {...onboardingProps} />
    </Box>
  );
};

export default OnBoarding;
