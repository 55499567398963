import { TypedTypePolicies } from '@apollo-red/schema/generated/apollo-helpers';
import { InsightsAlertUserHistory } from '@apollo-red/hooks';

export const insightsAlertTypePolicy: TypedTypePolicies = {
  InsightsAlertSummary: {
    keyFields: ['client_id', 'user_id'],
    fields: {
      counts_by_status: {
        merge(existing, incoming) {
          if (!incoming) return existing;
          if (!existing) return incoming;

          return { ...existing, ...(incoming ?? {}) };
        },
      },
    },
  },
  InsightsAlert: {
    keyFields: ['thread_id'],
    merge: true,
  },
  InsightsAlerts: {
    keyFields: ['client_id', 'user_id'],
    fields: {
      continuation_token: {
        merge: true,
      },
      has_next_page: {
        merge: true,
      },
      alerts: {
        merge(existing, incoming, { readField }) {
          if (!incoming) return existing;
          if (!existing) return incoming;

          if (incoming.length > 0) {
            const oldAlerts = [...existing];
            const newAlerts = incoming.filter(newAlert => {
              const found = oldAlerts.some(
                oldAlert =>
                  readField('thread_id', oldAlert) ===
                  readField('thread_id', newAlert),
              );
              if (found) {
                return false;
              }
              return true;
            });

            return [...oldAlerts, ...newAlerts];
          } else {
            return [];
          }
        },
      },
    },
  },
  InsightsAlertThread: {
    keyFields: ['thread_id'],
    fields: {
      alert_user_history: {
        keyArgs: false,
        merge(
          existing: InsightsAlertUserHistory,
          incoming: InsightsAlertUserHistory,
        ) {
          if (!incoming) return existing;
          if (!existing) return incoming;

          const { user_history: incomingItems } = incoming;
          const oldItems = [...existing.user_history];

          if (incomingItems.length > 0) {
            const newItems = incomingItems.filter(item => {
              const found = oldItems.some(
                oldAlert => oldAlert.commented_at === item.commented_at,
              );
              if (found) {
                return false;
              }
              return true;
            });

            return { ...incoming, user_history: [...oldItems, ...newItems] };
          } else {
            return existing;
          }
        },
      },
    },
  },
  WorkOrderVolumeAlertSettingData: {
    fields: {
      categories_excluded: {
        merge: true,
      },
    },
  },
  FacilitiesExperienceAlertSettingData: {
    fields: {
      categories_excluded: {
        merge: true,
      },
    },
  },
  LeaseEventAlertSettingData: {
    fields: {
      categories_excluded: {
        merge: true,
      },
    },
  },
  CovidWorkOrderAlertSettingData: {
    fields: {
      categories_excluded: {
        merge: true,
      },
    },
  },
  MarketBenchmarkAlertSettingData: {
    fields: {
      categories_excluded: {
        merge: true,
      },
    },
  },
};
