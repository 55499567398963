import { gql } from '@apollo/client';

export const LogBase = {
  fragment: gql`
    fragment LogBase on Log {
      id
      entity_event
      entity_id
      entity_type
      changed_at
      changed_by
      error
      entity_old_value
      entity_new_value
    }
  `,
};
