import * as React from 'react';
import {
  InsightsAlertScope,
  InsightsAlertWorkOrderVolumeTemplateData,
  InsightsAlertInbox,
} from '@apollo-red/hooks';
import { getInternalLink } from './utils';
import OpenAlertReport from './OpenAlertReport';
import { useAlertTextStyles } from './useAlertTextStyles';
import { useRoutes } from '@routes';

interface WorkOrderVolumeProps {
  threadId: string;
  template: InsightsAlertWorkOrderVolumeTemplateData;
  boldText?: boolean;
  inboxType?: InsightsAlertInbox;
}

const WorkOrderVolume: React.FC<WorkOrderVolumeProps> = ({
  template,
  boldText,
  threadId,
  inboxType,
}) => {
  const { property_name, trade, speciality } = template;
  const classes = useAlertTextStyles({ boldText });
  const routes = useRoutes();

  return (
    <OpenAlertReport>
      <div className={classes.fontWeight}>
        <b>{trade} </b> volume at your <b>{property_name} property </b> is
        <b> substantially above the {trade} volume</b> at similar properties
        managed by JLL and <b>substantially above the {speciality} volume </b>{' '}
        at similar properties in your portfolio.
        <br />
        <br />
        {inboxType && (
          <>
            See comparison to all JLL clients’ portfolio{' '}
            <a
              href={getInternalLink(
                threadId,
                inboxType,
                InsightsAlertScope.External,
                routes,
              )}
              className={classes.anchorText}
            >
              here
            </a>
            <br />
            See comparison to your portfolio{' '}
            <a
              href={getInternalLink(
                threadId,
                inboxType,
                InsightsAlertScope.Internal,
                routes,
              )}
              className={classes.anchorText}
            >
              here
            </a>
          </>
        )}
        <br />
      </div>
    </OpenAlertReport>
  );
};

export default WorkOrderVolume;
