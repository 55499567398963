import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLogout } from '@components/okta';

export const IdleTimer: React.FC = ({ children }) => {
  const { logout } = useLogout();

  const handleOnIdle = async () => {
    logout();
  };

  useIdleTimer({
    timeout:
      1000 * 60 * parseInt(process.env.IDLE_TIMEOUT_MINUTES ?? '210', 10),
    debounce: 500,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
    events: ['mousedown'],
  });

  return <>{children}</>;
};
