import { utils } from '@jll-labs/azara-ui-components';
import { RangeFilterOptions, Viz } from '@components/tableau/types';
import { getApplicableWorksheets } from './getApplicableWorksheets';
import { useGetWorksheets } from './useGetWorksheets';

const { useToggleValue } = utils;

export const useApplyRangeFilterAsync = (viz?: Viz) => {
  const [loading, toggleLoading] = useToggleValue(false);
  const { worksheets } = useGetWorksheets(viz);

  const applyRangeFilterAsync = async (name, range: RangeFilterOptions) => {
    if (!viz) {
      return;
    }

    try {
      toggleLoading();

      const applicableWorksheets = await getApplicableWorksheets(
        worksheets,
        name,
      );
      const applyFiltersPromises = applicableWorksheets?.map(ws =>
        ws.applyRangeFilterAsync(name, range),
      );

      if (applyFiltersPromises) {
        await Promise.all(applyFiltersPromises);
      }
    } finally {
      toggleLoading();
    }
  };

  return { loading, applyRangeFilterAsync };
};
