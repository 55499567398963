import { gql } from '@apollo/client';
import { ReportBase, CustomReportBase } from './Report';
import { ApplicationBase } from './Application';
import { RoleBase } from './Role';

export const UserRoleBase = {
  fragment: gql`
    fragment UserRoleBase on Role {
      id
      role_type
      role_name
    }
  `,
};

export const UserSystemAdminBase = {
  fragment: gql`
    fragment UserSystemAdminBase on User {
      id
      user_email
      user_full_name
      user_admin_settings {
        user_system_roles {
          ...RoleBase
        }
        user_created_at
        user_created_by
        user_updated_at
        user_updated_by
      }
    }
    ${RoleBase.fragment}
  `,
};

export const UserPersonaBase = {
  fragment: gql`
    fragment UserPersonaBase on UserPersona {
      id
      name
      profile_type
      description
      tenant_id
    }
  `,
};

export const UserWorkRoleBase = {
  fragment: gql`
    fragment UserWorkRoleBase on UserWorkRole {
      work_area
      specialization
      role
    }
  `,
};

export const UserCategoryBase = {
  fragment: gql`
    fragment UserCategoryBase on UserCategory {
      category_descriptions
      report {
        id
      }
    }
  `,
};

export const UserKpiDataBase = {
  fragment: gql`
    fragment UserKpiDataBase on KpiData {
      size_current
      size_change
      size_last_yr
      properties_current
      properties_change
      properties_last_yr
      actual_costs_usd
      actual_costs
      head_count
      desk_count
      occupancy
      cost_per_person
      currency
      unit_of_measurement
    }
  `,
};

export const UserKpiBase = {
  fragment: gql`
    fragment UserKpiBase on TenantKpiData {
      tenant_id
      tenant_ovc_id
      updated
      request_sync
      kpi_data {
        ...UserKpiDataBase
      }
    }
    ${UserKpiDataBase.fragment}
  `,
};

export const UserTenantBase = {
  fragment: gql`
    fragment UserTenantBase on Tenant {
      id
      tenant_name
      tenant_ovc_id
    }
  `,
};

export const UserAlertSettingsBase = {
  workOrderVolumeFragment: gql`
    fragment WorkOrderVolumeAlertSettingDataBase on WorkOrderVolumeAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
        external
      }
      alert_enabled
    }
  `,
  facilitiesExperienceFragment: gql`
    fragment FacilitiesExperienceAlertSettingDataBase on FacilitiesExperienceAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
  leaseEventFragment: gql`
    fragment LeaseEventAlertSettingDataBase on LeaseEventAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
  covidWorkOrderFragment: gql`
    fragment CovidWorkOrderAlertSettingDataBase on CovidWorkOrderAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
  marketBenchmarkFragment: gql`
    fragment MarketBenchmarkAlertSettingDataBase on MarketBenchmarkAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
  occupancyEfficiencyFragment: gql`
    fragment OccupancyEfficiencyAlertSettingDataBase on OccupancyEfficiencyAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
  subleaseRiskFragment: gql`
    fragment SubleaseRiskAlertSettingDataBase on SubleaseRiskAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
  sameDayWorkOrderCompletionFragment: gql`
    fragment SameDayWorkOrderCompletionAlertSettingDataBase on SameDayWorkOrderCompletionAlertSettingData {
      id
      alert_type
      categories_excluded {
        internal
      }
      alert_enabled
    }
  `,
};

export const UserAlertSettingDataBase = {
  fragment: gql`
    fragment UserAlertSettingDataBase on UserAlertSettingData {
      ...WorkOrderVolumeAlertSettingDataBase
      ...FacilitiesExperienceAlertSettingDataBase
      ...LeaseEventAlertSettingDataBase
      ...CovidWorkOrderAlertSettingDataBase
      ...MarketBenchmarkAlertSettingDataBase
      ...OccupancyEfficiencyAlertSettingDataBase
      ...SubleaseRiskAlertSettingDataBase
      ...SameDayWorkOrderCompletionAlertSettingDataBase
    }
    ${UserAlertSettingsBase.workOrderVolumeFragment}
    ${UserAlertSettingsBase.facilitiesExperienceFragment}
    ${UserAlertSettingsBase.leaseEventFragment}
    ${UserAlertSettingsBase.covidWorkOrderFragment}
    ${UserAlertSettingsBase.marketBenchmarkFragment}
    ${UserAlertSettingsBase.occupancyEfficiencyFragment}
    ${UserAlertSettingsBase.subleaseRiskFragment}
    ${UserAlertSettingsBase.sameDayWorkOrderCompletionFragment}
  `,
};

export const UserTenantExtended = {
  fragment: gql`
    fragment UserTenantExtended on Tenant {
      ...UserTenantBase
      tenant_type
      tenant_features {
        alerts
        demo
        portscape
      }
      tenant_reports {
        ...ReportBase
      }
      tenant_external_reports {
        ...ReportBase
      }
      tenant_custom_reports_settings {
        tenant_custom_reports(status: published) {
          ...CustomReportBase
        }
      }
    }
    ${UserTenantBase.fragment}
    ${ReportBase.fragment}
    ${CustomReportBase.fragment}
  `,
};

export const UserTenantSettingsBase = {
  fragment: gql`
    fragment UserTenantSettingsBase on UserTenantSettings {
      id
      user_added_at
      user_added_by
      user_updated_at
      user_updated_by
      user_enabled
      user_tenant {
        ...UserTenantBase
      }
      user_role {
        ...RoleBase
      }
      user_provisioned_site_role
      user_provisioning_status
      user_provisioning_error
    }
    ${UserTenantBase.fragment}
    ${RoleBase.fragment}
  `,
};

/**
 * Client side Insights Inbox type
 */
export const UserInsightsInboxSettingsBase = {
  fragment: gql`
    fragment UserInsightsInboxSettingsBase on UserInsightsInboxSettings {
      id
      insights_inbox_alerts {
        insights_inbox_type
        insights_alert_selected {
          thread_id
        }
      }
      insights_selected_filters {
        insights_alert_type_filter
        insights_importance_filter
        insights_alert_severity
        insights_alert_assigned_to
      }
      insights_selected_sort_order {
        created_date
      }
    }
  `,
};

export const UserOnboardingBase = {
  fragment: gql`
    fragment UserOnboardingBase on UserOnboarding {
      status
    }
  `,
};

export const UserTenantRegularSettingsBase = {
  fragment: gql`
    fragment UserTenantRegularSettingsBase on UserTenantRegularSettings {
      id
      user_tenant {
        id
      }
      user_alert_rule_builder_settings {
        ...UserAlertSettingDataBase
      }
      default_data_application {
        ...ApplicationBase
      }
      user_insights_inbox_settings @client {
        ...UserInsightsInboxSettingsBase
      }
      user_reports_order
      default_profile
      user_onboarding {
        ...UserOnboardingBase
      }
    }
    ${ApplicationBase.fragment}
    ${UserAlertSettingDataBase.fragment}
    ${UserOnboardingBase.fragment}
    ${UserInsightsInboxSettingsBase.fragment}
  `,
};

export const UserAdminSettingsBase = {
  fragment: gql`
    fragment UserAdminSettingsBase on UserAdminSettings {
      user_system_roles {
        ...RoleBase
      }
    }
    ${RoleBase.fragment}
  `,
};

export const UserUniversalFilterBase = {
  fragment: gql`
    fragment UserUniversalFilterBase on UserUniversalFilter {
      id
      filter_ancestors
      filter_value
    }
  `,
};

export const UserUniversalFiltersBase = {
  fragment: gql`
    fragment UserUniversalFiltersBase on UserTenantUniversalFilters {
      tenant_id
      filters {
        countries {
          name
          filters {
            ...UserUniversalFilterBase
          }
        }
        states {
          name
          filters {
            ...UserUniversalFilterBase
          }
        }
        cities {
          name
          filters {
            ...UserUniversalFilterBase
          }
        }
        properties {
          name
          filters {
            ...UserUniversalFilterBase
          }
        }
        unit_of_measurement
        currency
      }
    }
    ${UserUniversalFilterBase.fragment}
  `,
};

export const UserAgreementBase = {
  fragment: gql`
    fragment UserAgreementBase on UserAgreement {
      terms_accepted
      terms_accepted_at
    }
  `,
};

export const UserBase = {
  fragment: gql`
    fragment UserBase on User {
      id
      user_email
      user_picture_url
      user_status
      user_management_type
      user_type
      user_admin_settings {
        ...UserAdminSettingsBase
      }
      user_comm {
        work
      }
      user_settings {
        custom_categories {
          ...UserCategoryBase
        }
        hidden_reports {
          id
          report_name
        }
        default_tenant {
          ...UserTenantExtended
        }
        work_roles {
          tenant_id
          work_role {
            ...UserWorkRoleBase
          }
        }
        alerts {
          tenant_id
          contact_method_email
          contact_method_phone
          contact_frequency
        }
        responsibilities {
          tenant_id
          keywords {
            id
            keyword_name
          }
        }
        preferred_contact
        universal_filters {
          ...UserUniversalFiltersBase
        }
      }
      user_tutored
      user_full_name
      user_address {
        country {
          id
          country_desc
        }
        region {
          id
          region_name
        }
      }
      user_saved_reports {
        ...ReportBase
      }
      user_fav_reports {
        id
      }
      user_tenant_settings {
        ...UserTenantSettingsBase
      }
      user_tenant_regular_settings {
        ...UserTenantRegularSettingsBase
      }
      user_kpi_data {
        ...UserKpiBase
      }
      user_agreement {
        ...UserAgreementBase
      }
    }
    ${ReportBase.fragment}
    ${UserTenantBase.fragment}
    ${UserTenantExtended.fragment}
    ${UserKpiBase.fragment}
    ${UserUniversalFiltersBase.fragment}
    ${UserCategoryBase.fragment}
    ${UserWorkRoleBase.fragment}
    ${UserAdminSettingsBase.fragment}
    ${UserTenantSettingsBase.fragment}
    ${UserTenantRegularSettingsBase.fragment}
    ${UserAgreementBase.fragment}
  `,
};

export const SearchUserBase = {
  fragment: gql`
    fragment SearchUserBase on SearchedUserResult {
      user_email
      user_first_name
      user_last_name
      user_management_type
      user_tenants
    }
  `,
};

export const UserClassificationUserBase = {
  fragment: gql`
    fragment UserClassificationUserBase on User {
      id
      user_full_name
      user_email
      user_status
      user_type
      user_tenant_settings {
        id
        user_last_login
        user_added_at
        user_enabled
        user_tenant {
          id
          tenant_name
        }
        user_role {
          role_name
        }
      }
    }
  `,
};

export const UserClassificationUsersBase = {
  fragment: gql`
    fragment UserClassificationUsersBase on UserClassification {
      continuation_token
      users {
        id
        user_full_name
        user_email
        user_type
        user_tenant_settings {
          id
          user_last_login
          user_added_at
          user_tenant {
            id
            tenant_name
          }
          user_role {
            role_name
          }
        }
        user_tenant_regular_settings {
          user_tenant {
            id
          }
          user_onboarding {
            status
          }
        }
      }
    }
  `,
};

export const UserClassificationNumbersBase = {
  fragment: gql`
    fragment UserClassificationNumbersBase on UserClassification {
      total_users
      client_corporate_users
      internal_users
      client_users
    }
  `,
};

export const UserClassificationFiltersOptions = {
  fragment: gql`
    fragment UserClassificationFiltersOptionsBase on UserClassification {
      filters_options {
        client {
          id
          tenant_name
        }
        user_type
      }
    }
  `,
};
