import * as React from 'react';
import {
  Popover,
  Typography,
  FormControlLabel,
  CircularProgress,
  Button,
  Grid,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton,
  Icon,
  RadioGroup,
  Radio,
} from '@jll-labs/azara-ui-components';
import {
  useDeleteUserPersona,
  useGetUserPersonas,
  useGetUserCurrentPersona,
  useGetUserInfo,
  UserPersona,
  UserProfileType,
  useResetDemoUser,
  useUpdateUserDefaultProfile,
} from '@apollo-red/hooks';
import { AddPersona } from './AddPersona';
import { useTranslation } from 'react-i18next';

interface Props {
  personaAnchorEl: HTMLElement | null;
  setPersonasAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  handleParentClose: () => void;
}

const useStyles = makeStyles(() => ({
  personaItem: {
    margin: 0,
    padding: '10px 0px',
    alignItems: 'flex-start',
  },
  popover: {
    maxWidth: '400px',
    padding: '20px',
    margin: '0 0.25em',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  radioButton: {
    marginTop: -9,
  },
  resetButton: {
    marginRight: 5,
  },
}));

const Label: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <>
    <Typography variant="body1">{title}</Typography>
    <Typography variant="caption">{description}</Typography>
  </>
);

const AddRemove: React.FC<{
  disableDelete: boolean;
  handleDialogOpen: () => void;
  handleRemoveUserPersona: () => void;
}> = ({ handleDialogOpen, handleRemoveUserPersona, disableDelete }) => {
  const classes = useStyles();
  return (
    <div className={classes.actionButtons}>
      <IconButton size="small" onClick={handleDialogOpen} label="Add">
        <Icon name="Add" />
      </IconButton>
      <IconButton
        disabled={disableDelete}
        size="small"
        onClick={handleRemoveUserPersona}
        label="Delete"
      >
        <Icon name="Delete" />
      </IconButton>
    </div>
  );
};

const UserPersonas: React.FC<Props> = ({
  setPersonasAnchorEl,
  handleParentClose,
  personaAnchorEl,
}) => {
  const classes = useStyles();
  const { personas } = useGetUserPersonas();
  const {
    switchUser,
    nextUserLoading,
    user,
    onboarding: { handleSetSkipOnboarding },
  } = useGetUserInfo();
  const { persona } = useGetUserCurrentPersona();
  const [selectedPersona, setSelectedPersona] = React.useState<
    UserPersona | undefined
  >(persona);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { deleteUserPersona } = useDeleteUserPersona();
  const { resetDemoUser } = useResetDemoUser();
  const { updateDefaultProfile } = useUpdateUserDefaultProfile();
  const { t } = useTranslation();

  const handleClose = () => {
    setPersonasAnchorEl(null);
    handleParentClose();
  };

  const handleSwitch = async () => {
    if (selectedPersona) {
      setIsLoading(true);
      await updateDefaultProfile(selectedPersona.id);
      switchUser(selectedPersona.id);
    }
  };

  const handleReset = async () => {
    if (selectedPersona && user.user_settings.default_tenant) {
      setIsLoading(true);
      handleSetSkipOnboarding(selectedPersona.id, false);
      try {
        await resetDemoUser(
          selectedPersona.id,
          user.user_settings.default_tenant.id,
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRemoveUserPersona = (userId?: string) => async () => {
    if (userId) {
      setIsLoading(true);
      try {
        await deleteUserPersona(userId);
      } finally {
        setSelectedPersona(undefined);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (userId: string) => async () => {
    setSelectedPersona(currentPersona => {
      if (currentPersona?.id === userId) {
        return undefined;
      }
      return personas.find(p => p.id === userId);
    });
  };

  return (
    <>
      <AddPersona isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
      <Popover
        open={Boolean(personaAnchorEl)}
        onClose={handleClose}
        anchorEl={personaAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        {persona?.profile_type === UserProfileType.Main && (
          <AddRemove
            disableDelete={
              !selectedPersona ||
              selectedPersona.profile_type === UserProfileType.Main ||
              persona?.id === selectedPersona.id
            }
            handleDialogOpen={() => setIsDialogOpen(true)}
            handleRemoveUserPersona={handleRemoveUserPersona(
              selectedPersona?.id,
            )}
          />
        )}
        <Box width="18em">
          <RadioGroup direction="column">
            {personas.length === 0 && (
              <Box textAlign="center" m="3em">
                {t('pleaseAddPersona')}
              </Box>
            )}
            {personas.map(persona => (
              <FormControlLabel
                key={persona.id}
                classes={{ root: classes.personaItem }}
                onChange={handleChange(persona.id)}
                disabled={isLoading}
                control={
                  <Radio
                    checked={selectedPersona?.id === persona.id}
                    name={persona.name}
                    className={classes.radioButton}
                    label={persona.name}
                  />
                }
                label={
                  <Label
                    title={persona.name}
                    description={persona.description ?? ''}
                  />
                }
              />
            ))}
          </RadioGroup>
          <Grid container={true} justifyContent="flex-end">
            {persona?.profile_type === UserProfileType.Main && (
              <Button
                className={classes.resetButton}
                disabled={
                  isLoading ||
                  !selectedPersona ||
                  selectedPersona?.profile_type === UserProfileType.Main
                }
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleReset}
              >
                {t('resetPersona')}
              </Button>
            )}
            <Button
              disabled={
                isLoading ||
                nextUserLoading ||
                !selectedPersona ||
                selectedPersona.id === persona?.id
              }
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleSwitch}
            >
              {t('switchPersona')}
            </Button>
          </Grid>
          {(isLoading || nextUserLoading) && (
            <CircularProgress size={24} className={classes.loader} />
          )}
        </Box>
      </Popover>
    </>
  );
};

export default UserPersonas;
