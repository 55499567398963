import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';

interface ThumbnailErrorStylesProps {
  forFullDashboard?: boolean;
  isSidebarExpanded?: boolean;
}

export const useThumbnailErrorStyles = makeStyles((theme: AppTheme) => ({
  error: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    minWidth: '80px',
    minHeight: '80px',
    width: (props: ThumbnailErrorStylesProps) =>
      props.isSidebarExpanded ? '70%' : '100%',
    height: (props: ThumbnailErrorStylesProps) =>
      props.forFullDashboard ? '70%' : '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginLeft: (props: ThumbnailErrorStylesProps) =>
        props.forFullDashboard && props.isSidebarExpanded ? '40%' : 'auto',
    },
  },
  wrapper: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 0.5),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 1.5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 2.5),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1, 3.5),
    },
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: theme.typography.body2.fontSize,
  },
  message: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    fontSize: theme.typography.body2.fontSize,
    '& strong': {
      color: theme.palette.action.active,
    },
  },
  click: {
    cursor: 'pointer',
  },
  icon: {
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
}));
