import * as React from 'react';
import { Box } from '@mui/material';
import { ReportExtended } from '@apollo-red/hooks';
import { Viz } from '@components/tableau/types';
import { useFeatureFlag } from '@utils/launchDarkly';
import { TableauLicenseValidator } from '@components/tableau';
import { Toolbar } from './toolbar';
import FullReportContextProvider from './FullReportContextProvider';
import FullReportViz from './FullReportViz';
import FullReportWithFilters from './withFilters';

interface Props {
  report: ReportExtended;
  setVizToDashboard: (viz?: Viz) => void;
}

const FullReport: React.FC<Props> = ({ report, setVizToDashboard }) => {
  const [viz, setViz] = React.useState<Viz | undefined>(undefined);
  const isDashboardsCoreFiltersEnabled = useFeatureFlag(
    'dashboardsCoreFilters',
  );

  React.useEffect(() => {
    if (viz) {
      setVizToDashboard(viz);
    }
  }, [setVizToDashboard, viz]);

  if (isDashboardsCoreFiltersEnabled) {
    return <FullReportWithFilters report={report} isCoreDashboard={true} />;
  }

  return (
    <Box marginTop={3}>
      <FullReportContextProvider report={report}>
        <TableauLicenseValidator>
          <Toolbar viz={viz} report={report} />
          <FullReportViz
            reportId={report.id}
            reportUrl={report.report_tableau_full_report_url}
            reportName={report.report_name}
            reportCategory={report.primaryCategory}
            setViz={setViz}
            applyUniversalFilters={{
              basic: !report.isSavedView,
              geographic: !report.isSavedView,
            }}
            originalReportId={report.report_parent || undefined}
          />
        </TableauLicenseValidator>
      </FullReportContextProvider>
    </Box>
  );
};

export default FullReport;
