import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppLazyQueryCb } from '../queries';
import { GET_BLOB_STORAGE_UPLOAD_SAS_URL } from '@apollo-red/queries';

export const useGetBlobStorageUploadSasUrl = () => {
  const getBlobStorageUploadSasUrl = useAppLazyQueryCb<
    SchemaTypes.GetBlobStorageUploadSasUrlQuery,
    SchemaTypes.GetBlobStorageUploadSasUrlQueryVariables
  >(
    GET_BLOB_STORAGE_UPLOAD_SAS_URL,
    {
      fetchPolicy: 'no-cache',
    },
    { globalErr: true },
  );

  return {
    getBlobStorageUploadSasUrl: async (
      containerName: string,
      userId: string,
    ) => {
      const { data } = await getBlobStorageUploadSasUrl({
        data: { container_name: containerName },
        where: { id: userId },
      });

      return data.getBlobStorageUploadSasUrl;
    },
  };
};
