import * as React from 'react';
import { ReportExtended } from '@apollo-red/hooks';
import FullReportViz from '../FullReportViz';
import FullReportWithSections from './FullReportWithSections';
import { useFullReportWithFilters } from './FullReportWithFiltersProvider';
import { WithFiltersToolbar } from './toolbar';
import { ToolbarSettings } from './types';
import { getPurgedPinnedValues } from './utils';

interface Props {
  report: ReportExtended;
}

const FullReportWithFiltersWrapper: React.FC<Props> = ({ report }) => {
  const {
    handleViz,
    showFiltersPanel,
    resetPinnedValues,
    contentHeight,
    contentWidth,
    isCoreDashboard,
    isSidebarExpanded,
    pinnedData,
    updatePinnedData,
    id,
  } = useFullReportWithFilters();

  const reset = () => {
    const updatedReportFilterData = getPurgedPinnedValues(
      pinnedData,
      isCoreDashboard,
      id,
    );

    resetPinnedValues();
    updatePinnedData(updatedReportFilterData);
  };

  const coreDashboardSettings = { help: true, load: true, save: true };

  const enabledSettings: ToolbarSettings = {
    share: true,
    favorites: true,
    revert: true,
    csv: true,
    image: true,
    pdf: true,
    ...(isCoreDashboard && { ...coreDashboardSettings }),
  };

  return (
    <div style={{ width: contentWidth, height: contentHeight }}>
      <WithFiltersToolbar
        report={report}
        resetPinnedValues={reset}
        enabledSettings={enabledSettings}
        isSidebarExpanded={isSidebarExpanded}
      />
      <FullReportWithSections report={report}>
        <FullReportViz
          reportId={report.id}
          reportUrl={report.report_tableau_full_report_url}
          reportName={report.report_metadata?.report_name ?? report.report_name}
          reportCategory={
            report.report_metadata?.module_category.module_category_type ??
            report.primaryCategory
          }
          setViz={handleViz}
          applyUniversalFilters={{
            basic: !report.isSavedView,
            geographic: !report.isSavedView,
          }}
          originalReportId={report.report_parent || undefined}
          showFiltersPanel={showFiltersPanel}
          withFilters={true}
          contentHeight={contentHeight}
          contentWidth={contentWidth}
        />
      </FullReportWithSections>
    </div>
  );
};

export default FullReportWithFiltersWrapper;
