import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MessageDialog,
  DialogContentText,
} from '@jll-labs/azara-ui-components';
import { useTheme } from '@mui/material';

export enum DashboardsPopupTypes {
  Hide = 'hide',
  DeleteSavedView = 'deleteSavedView',
  Closed = 'closed',
}

interface DialogParameters {
  title: string;
  message: string;
  warningMessage?: string;
  button: string;
}

type DialogParametersConfiguration = Record<
  DashboardsPopupTypes,
  DialogParameters
>;

const dialogParameters: DialogParametersConfiguration = {
  hide: {
    title: 'hideDashboardTitle',
    message: 'hideDashboard',
    warningMessage: 'hideDashboardWarning',
    button: 'hide',
  },
  deleteSavedView: {
    title: 'deleteSavedViewDashboardTitle',
    message: 'deleteSavedViewDashboard',
    warningMessage: 'deleteSavedViewDashboardWarning',
    button: 'delete',
  },
  closed: {
    title: '',
    message: '',
    button: '',
  },
};

interface StyleProps {
  type: DashboardsPopupTypes;
}

const useStyles = ({ type }: StyleProps) => {
  const theme = useTheme();

  return {
    confirmButton: {
      backgroundColor:
        type === DashboardsPopupTypes.DeleteSavedView
          ? theme.palette.error.light
          : theme.palette.primary.main,
    },
  };
};

interface Props {
  handleConfirm: () => void;
  handleClose: () => void;
  type: DashboardsPopupTypes;
}

const DashboardsPopup: React.FC<Props> = ({
  handleClose,
  handleConfirm,
  type,
}) => {
  const { t } = useTranslation();
  const styles = useStyles({ type } as StyleProps);
  const parameters = dialogParameters[type];
  const { title, button, message, warningMessage } = parameters;

  return (
    <MessageDialog
      open={type !== 'closed'}
      title={t(title)}
      handleConfirmClick={handleConfirm}
      handleClose={handleClose}
      size="small"
      confirmButtonTranslation={button}
      confirmButtonSx={styles.confirmButton}
    >
      <DialogContentText>
        <Trans i18nKey={message}>{t(message)}</Trans>
      </DialogContentText>
      {warningMessage ? (
        <DialogContentText>{t(warningMessage)}</DialogContentText>
      ) : undefined}
    </MessageDialog>
  );
};

export default DashboardsPopup;
