import * as React from 'react';
import {
  FormSubmit,
  MessagePopper,
  Form,
  FormFileUpload,
  FormButtons,
  FormRow,
  FormSection,
} from '@jll-labs/azara-ui-components';
import { useUploadUserPicture } from '@apollo-red/hooks';
import { validationSchema } from './validationSchema';

interface Props {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  photoFile?: File;
}

const PictureEdit: React.FC<Props> = ({ anchorEl, isOpen, setIsOpen }) => {
  const { uploadUserPicture } = useUploadUserPicture();

  const handleFormSubmit: FormSubmit<FormValues> = async ({ photoFile }) => {
    if (photoFile) {
      await uploadUserPicture(photoFile);
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <MessagePopper
      message={{ position: 'bottom-start' }}
      isOpen={isOpen}
      anchorEl={anchorEl}
      handleClose={handleCancel}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{
          photoFile: undefined,
        }}
        validationSchema={validationSchema}
      >
        <FormSection title="Upload picture">
          <FormRow>
            <FormFileUpload name="photoFile" required={true} />
          </FormRow>
        </FormSection>
        <FormButtons onCancel={handleCancel} />
      </Form>
    </MessagePopper>
  );
};

export default PictureEdit;
