import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { AppTheme } from '@theme';
import { DataQuality } from '@utils/tableau';
import DashboardTag from '../dashboardTag';

interface Props {
  title: React.ReactNode;
  dataQuality?: DataQuality;
  dataQualityExplanation?: string;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
  },
}));

const DashboardTitle: React.FC<Props> = ({
  title,
  dataQuality,
  dataQualityExplanation,
}) => {
  const classes = useStyles();

  if (!dataQuality) {
    return <div>{title}</div>;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <div>
        <DashboardTag
          dataQuality={dataQuality}
          tooltipLabel={dataQualityExplanation}
        />
      </div>
    </div>
  );
};

export default DashboardTitle;
