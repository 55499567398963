import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@mui/material';
import { useAlertTableRowStyles } from './useAlertTableRowStyles';

interface AlertNoDataTableRowProps {
  colSpan?: number;
}

const AlertNoDataTableRow: React.FC<AlertNoDataTableRowProps> = ({
  colSpan = 5,
}) => {
  const styles = useAlertTableRowStyles();
  const { t } = useTranslation();
  const message = t('noDataAvailable');

  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        size="small"
        align="center"
        sx={styles.noDataTableCell}
      >
        {message}
      </TableCell>
    </TableRow>
  );
};

export default AlertNoDataTableRow;
