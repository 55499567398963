import { isEmpty, isEqual, xorWith } from 'lodash';
import { ReportControlStaticListItem } from '@apollo-red/hooks';

type FilterSelection = ReportControlStaticListItem;

const getFilterSelectionValue = (filterSelection: FilterSelection) => ({
  label: filterSelection.label ?? '',
  value: filterSelection.value ?? '',
  selected: filterSelection.selected ?? false,
});

export const isFilterSelectionEqual = (
  a?: FilterSelection[],
  b?: FilterSelection[],
) => {
  const s1 = a?.map(getFilterSelectionValue) ?? [];
  const s2 = b?.map(getFilterSelectionValue) ?? [];

  return isEmpty(xorWith(s1, s2, isEqual));
};
