import { useGetInsightsAlertThreadQuery } from './useGetInsightsAlertThreadQuery';
import { useGetInsightsInboxAlertState } from '../inbox';

export const useGetInsightsAlertThread = (threadId?: string) => {
  const { tenantId, userId } = useGetInsightsInboxAlertState();
  const { loading, data } = useGetInsightsAlertThreadQuery({
    userId,
    tenantId,
    threadId: threadId ?? '',
  });

  const { alert } = data?.getUser?.user_insights_alert ?? {};

  return {
    loading,
    alert: alert ?? undefined,
  };
};
