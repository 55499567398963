import { useMemo } from 'react';
import { UseClientRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useSettingsRoutes = ({
  settings: { getLink },
}: UseClientRoutes) => {
  return useMemo(() => {
    const link = getLink();

    return {
      accountSettings: getCommonRoute(link, 'account-settings'),
      profile: getCommonRoute(link, 'profile'),
      dashboards: getCommonRoute(link, 'dashboards'),
      categories: getCommonRoute(link, 'categories'),
      reportSettings: getCommonRoute(link, 'dashboard-settings'),
      notifications: getCommonRoute(link, 'notifications'),
      alertSettings: getCommonRoute(link, 'alert-settings'),
    };
  }, [getLink]);
};
