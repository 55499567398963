export const getPercentageSteps = (step = 10) => {
  const steps = 100 / step;

  return [
    0,
    ...Array(steps)
      .fill('')
      .map((_, index) => (index + 1) * step),
  ];
};
