import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { main, useRoutes } from '@routes';

const useLogin = () => {
  const history = useHistory();
  const routes = useRoutes();
  const { oktaAuth } = useOktaAuth();

  const login = async () => {
    if (await oktaAuth.isAuthenticated()) {
      return history.push(main.client.getLink('login'));
    }

    oktaAuth.signInWithRedirect({
      originalUri: routes.dashboards.all.getLink('login'),
    });
  };

  return { login };
};

export default useLogin;
