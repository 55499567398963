import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_PRODUCT_SETTINGS_REPORT } from '@apollo-red/mutations';

interface UpdateProductSettingsReportArgs {
  reportId: string;
  disabled?: boolean;
}

export const useUpdateProductSettingsReport = () => {
  const [updateTenantReport] = useAppMutation<
    SchemaTypes.UpdateProductSettingsReportMutation,
    SchemaTypes.UpdateProductSettingsReportMutationVariables
  >(UPDATE_PRODUCT_SETTINGS_REPORT);

  return {
    updateReport: ({ reportId, disabled }: UpdateProductSettingsReportArgs) =>
      updateTenantReport({
        variables: {
          where: {
            report_id: reportId,
          },
          data: {
            disabled,
          },
        },
      }),
  };
};
