import * as React from 'react';
import FooterInfo from './FooterInfo';
import TopBar from './TopBar';
import Possibilities from './Possibilities';
import Hero from './Hero';
import Support from './Support';

const Home = () => {
  return (
    <div>
      <TopBar />
      <Hero />
      <Possibilities />
      <FooterInfo />
      <Support />
    </div>
  );
};

export default Home;
