import * as React from 'react';
import { CircularProgress } from '@mui/material';

interface ThumbnailLoaderProps {
  className?: string;
}

const ThumbnailLoader: React.FC<ThumbnailLoaderProps> = ({ className }) => (
  <div className={className}>
    <CircularProgress color="primary" />
  </div>
);

export default React.memo(ThumbnailLoader);
