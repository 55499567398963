import { gql } from '@apollo/client';

export const TrustedReportUrlBase = {
  fragment: gql`
    fragment TrustedReportUrlBase on TrustedReportUrl {
      report_url
      is_valid_ticket
    }
  `,
};
