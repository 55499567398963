import * as React from 'react';
import { staticImageForImageUrl } from './staticImageForImageUrl';
import FallbackThumbnailImage from './FallbackThumbnailImage';
import ThumbnailImageWrapper from './ThumbnailImageWrapper';

interface LegacyDashboardStaticThumbnailImageProps {
  className?: string;
  imageUrl?: string;
  isError?: boolean;
  isLoading?: boolean;
  loaderComponent?: JSX.Element;
  reportId: string;
  onError?: React.ReactEventHandler<HTMLImageElement>;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
}

const LegacyDashboardStaticThumbnailImage: React.FC<LegacyDashboardStaticThumbnailImageProps> =
  ({
    className,
    imageUrl,
    isError,
    isLoading,
    loaderComponent,
    reportId,
    onError,
    onLoad,
  }) => {
    const staticImage = staticImageForImageUrl(imageUrl);

    if (isError) {
      return <FallbackThumbnailImage reportId={reportId} />;
    }

    return (
      <ThumbnailImageWrapper>
        {isLoading && loaderComponent}
        <img
          onLoad={onLoad}
          onError={onError}
          className={className}
          src={staticImage}
        />
      </ThumbnailImageWrapper>
    );
  };

export default LegacyDashboardStaticThumbnailImage;
