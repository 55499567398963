import {
  InsightsAlert,
  InsightsAlertScope,
  ReportExtended,
  InsightsAlertType,
  Report,
} from '@apollo-red/hooks';
import { ApplyUniversalFiltersProps } from '@components/tableau/TableauView';
import { InitFiltersProp } from '@components/tableau/TableauViewBase';
import { getFilterValues } from './getFilterValues';
import { getReportCacheId } from './getReportCacheId';

interface Filters {
  alertReportId?: string;
  initialFilters?: InitFiltersProp;
  applyUniversalFilters?: ApplyUniversalFiltersProps;
}

export type AlertReport = ReportExtended & Filters;

interface InitReportPayload {
  report?: Report;
  alert: InsightsAlert;
  alertType?: InsightsAlertType | null;
  alertScope?: InsightsAlertScope;
  alertName?: string;
}

export const initializeAlertReport = ({
  report,
  alert,
  alertType,
  alertScope,
  alertName,
}: InitReportPayload): AlertReport => {
  const name = report?.report_name ?? alertName ?? '';
  const alertReportId = report?.id ?? '';
  const id = getReportCacheId(
    alertReportId,
    alert.thread_id,
    alertType,
    alertScope,
  );
  const url = report?.report_tableau_full_report_url ?? '';
  const filters = getFilterValues(alert, alertType, alertScope);

  return {
    id,
    alertReportId,
    report_active: true,
    report_cat: [],
    report_name: name,
    report_full_keywords: [],
    report_tableau_url: '',
    report_tableau_full_report_url: url,
    isFav: false,
    isCustomReport: false,
    isHidden: false,
    isSavedView: false,
    isExternalReport: false,
    isAlertReport: false,
    isAuthReport: false,
    isKpiReport: false,
    isPatReport: false,
    primaryCategory: '',
    originalCategory: '',
    ...filters,
  };
};
