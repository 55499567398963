import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useRemoveInsightsAlertAssignedUser } from '@apollo-red/hooks';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_ALERT_ASSIGNED_USER } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import {
  InsightsAlert,
  InsightsAlertUserHistory,
  InsightsAlertUserHistoryRecord,
  InsightsAlertUserHistoryActionPerformed,
} from '../insightsAlerts.types';

export const useSetInsightsAlertAssignedUser = (
  selectedAlert?: InsightsAlert,
) => {
  const { userId, tenantId, userName } = useGetInsightsInboxAlertState();
  const { removeInsightsAlertAssignedUser } =
    useRemoveInsightsAlertAssignedUser();
  const [setInsightsAlertAssignedUser] = useAppMutation<
    SchemaTypes.SetInsightsAlertAssignedUserMutation,
    SchemaTypes.SetInsightsAlertAssignedUserMutationVariables
  >(SET_INSIGHTS_ALERT_ASSIGNED_USER);

  return {
    setInsightsAlertAssignedUser: (
      threadId: string,
      assignedUserId: string,
    ) => {
      const timeStamp = `${new Date().getTime()}`;
      const assignedUserName =
        selectedAlert?.user_info.find(user => user.user_id === assignedUserId)
          ?.user_name ?? '';

      if (assignedUserId === 'Unassigned') {
        return removeInsightsAlertAssignedUser(threadId);
      }

      setInsightsAlertAssignedUser({
        variables: {
          where: {
            tenant_id: tenantId,
            assigned_at: timeStamp,
            assigned_user_id: assignedUserId,
            thread_id: threadId,
            user_id: userId,
          },
        },
        optimisticResponse: { setInsightsAlertAssignedUser: true },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlert',
              thread_id: threadId,
            }),
            fields: {
              assigned_user_id() {
                return assignedUserId;
              },
            },
            optimistic: true,
          });

          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlertThread',
              thread_id: threadId,
            }),
            fields: {
              alert_user_history(existing: InsightsAlertUserHistory) {
                const userHistory: InsightsAlertUserHistoryRecord = {
                  commented_at: timeStamp,
                  action_performed:
                    InsightsAlertUserHistoryActionPerformed.AssignedUser,
                  user_name: userName,
                  comment: assignedUserName,
                };

                return {
                  ...existing,
                  user_history: [userHistory, ...existing.user_history],
                };
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
