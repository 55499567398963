import * as React from 'react';
import { AppBar, Box, Grid, Theme, Toolbar, useTheme } from '@mui/material';
import { Icon, utils } from '@jll-labs/azara-ui-components';
import type { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { AppTheme } from '@theme';
import { SystemStyleObject } from '@mui/system';

const { createUpBreakpoints } = utils;

export const createTopBarBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    height: theme.topBar.height[breakpoint],
  };
};

export const createLogoContainerBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    width: theme.sidebar.width[breakpoint].expanded,
  };
};

export const createNavigationBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  if (breakpoint === 'sm' || breakpoint === 'xs') {
    return {
      display: 'none',
    };
  } else {
    return {
      display: 'inherit',
      height: theme.topBar.height[breakpoint],
    };
  }
};

const useStyles = (): Record<
  'topBar' | 'content' | 'logo' | 'spacer' | 'toolbar' | 'logoWrapper',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    topBar: {
      zIndex: theme.zIndex.appBar,
      height: '64px',
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createTopBarBreakpoints,
      ),
    },
    content: {
      height: '100%',
    },
    logo: {
      width: '6em',
      paddingLeft: '1em',
    },
    spacer: {
      flexGrow: 1,
    },
    toolbar: {
      flex: 1,
      flexGrow: 1,
      margin: theme.spacing(0, 3, 0, 7),
    },
    logoWrapper: {
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createLogoContainerBreakpoints,
      ),
    },
  };
};

interface Props {
  toolbarLeftSideItems?: React.ReactNode;
  toolbarRightSideItems?: React.ReactNode;
  position?: 'static' | 'fixed';
}

const BrandedTopBar: React.FC<Props> = ({
  toolbarLeftSideItems,
  toolbarRightSideItems,
  children,
  position = 'static',
}) => {
  const styles = useStyles();

  return (
    <AppBar
      position={position}
      color="secondary"
      elevation={3}
      sx={styles.topBar}
    >
      <Box component="div" sx={styles.content}>
        <Grid container={true} alignItems="center" sx={styles.content}>
          <Box component="div" sx={styles.logoWrapper}>
            <Icon name="AzaraLogo" sx={styles.logo} />
          </Box>
          <Toolbar sx={styles.toolbar} disableGutters={true}>
            {toolbarLeftSideItems}
            <Box component="div" sx={styles.spacer} />
            {toolbarRightSideItems}
          </Toolbar>
          {children}
        </Grid>
      </Box>
    </AppBar>
  );
};

export default BrandedTopBar;
