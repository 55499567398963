import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import { IconButton, Icon, utils } from '@jll-labs/azara-ui-components';
import type { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { useGetUserCurrentPersona } from '@apollo-red/hooks';
import { SystemStyleObject } from '@mui/system';
import { AvatarSize } from '@components/avatar';
import { TenantFeatureToggle } from '@components/featureToggle';
import { AppTheme } from '@theme';
import AvatarButton from '../TopBar/AvatarButton';

const { createUpBreakpoints } = utils;

export const createCollapseButtonExpandedBreakpoints: CreateBreakpointFn<AppTheme> =
  (theme, breakpoint) => {
    return {
      left: `calc(${theme.sidebar.width[breakpoint].expanded} - 15px)`,
    };
  };

export const createCollapseButtonCollapsedBreakpoints: CreateBreakpointFn<AppTheme> =
  (theme, breakpoint) => {
    return {
      left: `calc(${theme.sidebar.width[breakpoint].closed} - 15px)`,
    };
  };

const useStyles = (): Record<
  | 'root'
  | 'persona'
  | 'collapseButtonContainer'
  | 'collapseButtonExpanded'
  | 'collapseButtonClosed'
  | 'boxLightShadow',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    root: {
      padding: '0.5em',
      position: 'relative',
      zIndex: 100,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
      display: 'flex',
      alignItems: 'center',
    },
    persona: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    collapseButtonContainer: {
      position: 'absolute',
      zIndex: 10000,
      top: `calc(50% - 15px)`,
    },
    collapseButtonExpanded: {
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createCollapseButtonExpandedBreakpoints,
      ),
    },
    collapseButtonClosed: {
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createCollapseButtonCollapsedBreakpoints,
      ),
    },
    boxLightShadow: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[4],
      '&:hover': {
        backgroundColor: theme.palette.secondary.main + ' !important',
      },
    },
  };
};

interface Props {
  email: string;
  fullName: string;
  isExpanded: boolean;
  toggleSidebar: () => void;
  ref: React.Ref<HTMLDivElement>;
}

const UserProfile: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const styles = useStyles();
    const { persona } = useGetUserCurrentPersona();
    const { t } = useTranslation();

    return (
      <div ref={ref}>
        <Box sx={styles.root}>
          <AvatarButton fullName={props.fullName} size={AvatarSize.md} />
          {props.isExpanded && persona && (
            <TenantFeatureToggle hasFeatures={['demo']}>
              <Box sx={styles.persona}>
                <Typography color="textPrimary" variant="subtitle2">
                  {t('currentPersona')}
                </Typography>
                <Typography color="textPrimary" sx={styles.persona}>
                  {persona.name}
                </Typography>
              </Box>
            </TenantFeatureToggle>
          )}
          <Box
            sx={[
              styles.collapseButtonContainer,
              props.isExpanded
                ? styles.collapseButtonExpanded
                : styles.collapseButtonClosed,
            ]}
          >
            <IconButton
              sx={styles.boxLightShadow}
              size="small"
              onClick={props.toggleSidebar}
              dataTestId="topbar-buttonCollapse"
              label="Sidebar Collapse"
            >
              <Icon name={props.isExpanded ? 'ArrowBack' : 'ArrowForward'} />
            </IconButton>
          </Box>
        </Box>
      </div>
    );
  },
);

export default UserProfile;
