import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  GET_SYSTEM_ROLE_USERS,
  GET_USER_CLASSIFICATION_FILTERS_OPTIONS,
  GET_USER_CLASSIFICATION_FILTERS_OPTIONS_USER_EMAIL,
  GET_USER_CLASSIFICATION_FILTERS_OPTIONS_USER_NAME,
  GET_USER_CLASSIFICATION_NUMBERS,
  GET_USER_CLASSIFICATION_USER,
  GET_USER_CLASSIFICATION_USERS,
} from '@apollo-red/queries';
import { useAppLazyQueryCb, useAppQuery } from '../queries';
import { NetworkStatus } from '@apollo/client';
import { useState } from 'react';

export type SystemAdminUser = SchemaTypes.UserSystemAdminBaseFragment;
export type UserClassificationTenantSettings =
  SchemaTypes.UserClassificationUsersBaseFragment['users'][0]['user_tenant_settings'];
export type UserClassificationUser =
  SchemaTypes.UserClassificationUserBaseFragment;
export type UserClassificationFiltersInput =
  SchemaTypes.UserClassificationFiltersInput;

export const useGetSystemAdminUsers = () => {
  const { loading, data } = useAppQuery<SchemaTypes.GetSystemRoleUsersQuery>(
    GET_SYSTEM_ROLE_USERS,
  );

  return {
    loading,
    users: data?.getSystemRoleUsers ?? [],
  };
};

export const useGetUserClassificationUsers = (
  filters?: SchemaTypes.UserClassificationFiltersInput,
) => {
  const { loading, data, fetchMore, networkStatus, variables } = useAppQuery<
    SchemaTypes.GetUserClassificationUsersQuery,
    SchemaTypes.GetUserClassificationUsersQueryVariables
  >(GET_USER_CLASSIFICATION_USERS, {
    variables: {
      where: { filters },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const { users, continuation_token } = data?.getUserClassification ?? {};
  const filteredUsers = users?.filter(user => {
    if (filters?.user_type) {
      return filters.user_type.some(userType => userType === user.user_type);
    }
    return true;
  });

  const loadMore = () => {
    if (continuation_token) {
      fetchMore({
        variables: {
          where: {
            filters: variables?.where.filters,
            continuation_token: continuation_token,
          },
        },
      });
    }
  };

  return {
    users: filteredUsers,
    loading,
    loadMore,
    hasMore: !!continuation_token,
    hasUsers: (users?.length ?? 0) > 0,
    isLoadingMore: networkStatus === NetworkStatus.fetchMore,
  };
};

export const useGetUserClassificationUser = (userEmail: string) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetUserClassificationUserQuery,
    SchemaTypes.GetUserClassificationUserQueryVariables
  >(GET_USER_CLASSIFICATION_USER, {
    variables: {
      where: {
        email: userEmail,
      },
    },
  });

  return {
    user: data?.getUser ?? undefined,
    loading,
  };
};

export const useGetUserClassificationNumbers = (
  filters?: SchemaTypes.UserClassificationFiltersInput,
) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetUserClassificationNumbersQuery,
    SchemaTypes.GetUserClassificationNumbersQueryVariables
  >(GET_USER_CLASSIFICATION_NUMBERS, {
    variables: {
      where: { filters },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { client_users, client_corporate_users, internal_users, total_users } =
    data?.getUserClassification ?? {};

  return {
    clientUsers: client_users,
    clientCorporateUsers: client_corporate_users,
    internalUsers: internal_users,
    totalUsers: total_users,
    loading,
  };
};

export const useGetUserClassificationFiltersOptions = (
  filters?: SchemaTypes.UserClassificationFiltersInput,
) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetUserClassificationFiltersOptionsQuery,
    SchemaTypes.GetUserClassificationFiltersOptionsQueryVariables
  >(GET_USER_CLASSIFICATION_FILTERS_OPTIONS, {
    variables: {
      where: { filters },
    },
  });

  const { filters_options } = data?.getUserClassification ?? {};

  return { loading, filtersOptions: filters_options };
};

export const useGetUserClassificationFiltersOptionsUserName = (
  filters?: SchemaTypes.UserClassificationFiltersInput,
) => {
  const getUserName = useAppLazyQueryCb<
    SchemaTypes.GetUserClassificationFiltersOptionsUserNameQuery,
    SchemaTypes.GetUserClassificationFiltersOptionsUserNameQueryVariables
  >(GET_USER_CLASSIFICATION_FILTERS_OPTIONS_USER_NAME);

  const [loading, setLoading] = useState(false);

  return {
    loading,
    getUserNameOptions: async (userName: string) => {
      if (userName.length < 3) {
        return undefined;
      }
      setLoading(true);
      const res = await getUserName({
        where: { filters },
        name: userName,
        filters,
      });
      setLoading(false);
      return res.data.getUserClassification.filters_options?.user_name;
    },
  };
};

export const useGetUserClassificationFiltersOptionsUserEmail = (
  filters?: SchemaTypes.UserClassificationFiltersInput,
) => {
  const getUserEmail = useAppLazyQueryCb<
    SchemaTypes.GetUserClassificationFiltersOptionsUserEmailQuery,
    SchemaTypes.GetUserClassificationFiltersOptionsUserEmailQueryVariables
  >(GET_USER_CLASSIFICATION_FILTERS_OPTIONS_USER_EMAIL);

  const [loading, setLoading] = useState(false);

  return {
    loading,
    getUserEmailOptions: async (userEmail: string) => {
      if (userEmail.length < 3) {
        return undefined;
      }
      setLoading(true);
      const res = await getUserEmail({
        where: { filters },
        email: userEmail,
        filters,
      });
      setLoading(false);
      return res.data.getUserClassification.filters_options?.user_email;
    },
  };
};
