import { gql } from '@apollo/client';
import { WorkAreaBase, KeywordBase } from '@apollo-red/fragments';

export const GET_WORK_AREAS = gql`
  query getWorkAreas {
    getWorkAreas {
      ...WorkAreaBase
    }
  }
  ${WorkAreaBase.fragment}
`;

export const GET_WORK_AREA_KEYWORDS = gql`
  query getWorkAreaKeywords($tenantId: ID!) {
    getWorkAreaKeywords(tenant_id: $tenantId) {
      ...KeywordBase
    }
  }
  ${KeywordBase.fragment}
`;
