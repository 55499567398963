import * as React from 'react';
import { TextField } from '@jll-labs/azara-ui-components';
import { InsightsAlert } from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';

interface Props {
  handleAddUserComment: (comment: string) => void;
  activeAlert: InsightsAlert;
}

const AlertCommentBox: React.FC<Props> = ({
  handleAddUserComment,
  activeAlert,
}) => {
  const [comment, setComment] = React.useState('');
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  const handleChange = e => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    trackInsightsAlertEvent('alertAddComment', {
      alertThreadId: activeAlert?.thread_id ?? '',
      alertType: activeAlert?.alert_type ?? '',
    });

    handleAddUserComment(comment);
    setComment('');
  };

  return (
    <div>
      <TextField
        variant="filled"
        value={comment}
        onChange={handleChange}
        endIcon="Send"
        onEndIconClick={handleSubmit}
        label="Comment"
      />
    </div>
  );
};

export default AlertCommentBox;
