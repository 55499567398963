import { Theme, responsiveFontSizes } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { theme } from '@jll-labs/azara-ui-components';

const { defaultTheme } = theme;

interface SidebarWidth {
  expanded: string;
  closed: string;
  zIndex?: number;
}

export interface AppOwnTheme {
  sidebar: {
    width: {
      xs: SidebarWidth;
      sm: SidebarWidth;
      md: SidebarWidth;
      lg: SidebarWidth;
      xl: SidebarWidth;
    };
  };
  topBar: {
    height: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  };
  alerts: {
    mailbox: {
      width: {
        lg: string;
        xl: string;
      };
    };
    alertContent: {
      width: {
        lg: string;
        xl: string;
      };
    };
  };
}

export type AppTheme = Theme & AppOwnTheme;

const appOwnTheme: AppOwnTheme = {
  sidebar: {
    width: {
      xs: {
        expanded: '40vw',
        closed: '14vw',
        zIndex: 100,
      },
      sm: {
        expanded: '40vw',
        closed: '12.5vw',
        zIndex: 100,
      },
      md: {
        expanded: '25vw',
        closed: '12vw',
      },
      lg: {
        expanded: '19vw',
        closed: '8vw',
      },
      xl: {
        expanded: '13vw',
        closed: '5.5vw',
      },
    },
  },
  topBar: {
    height: {
      xs: '64px',
      sm: '64px',
      md: '64px',
      lg: '64px',
      xl: '64px',
    },
  },
  alerts: {
    mailbox: {
      width: {
        lg: '45%',
        xl: '30%',
      },
    },
    alertContent: {
      width: {
        lg: '55%',
        xl: '70%',
      },
    },
  },
};

export const appTheme = {
  ...appOwnTheme,
  ...responsiveFontSizes(defaultTheme),
};

export const useAppTheme = () => useTheme<AppTheme>();
