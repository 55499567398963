import * as React from 'react';
import { useGetUserCategory } from '@apollo-red/hooks';
import { useParamsCustom } from '@utils/router';
import { BaseCategoryDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';

const useSaveHandler = () => {
  return {
    saveHandler: () => {},
  };
};

interface MatchParams {
  categoryId: string;
}

const CategoryDashboards: React.FC = () => {
  const routes = useRoutes();
  const { categoryId } = useParamsCustom<MatchParams>();
  const { category } = useGetUserCategory(categoryId);

  return (
    <BaseCategoryDashboards
      reports={category.category_reports}
      categoryId={categoryId}
      useSaveHandler={useSaveHandler}
      category={category}
      showKeyMetrics={true}
      drag={true}
      dashboardRoutes={routes.dashboards}
    />
  );
};

export default CategoryDashboards;
