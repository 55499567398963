interface DocumentationApiConfig extends RequestInit {
  headers: HeadersInit;
}

export const config: DocumentationApiConfig = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    Authorization: 'Bearer',
    'Content-Type': 'application/json',
    Origin: window.location.origin,
  },
  method: 'POST',
  mode: 'cors',
};
