import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { MenuSelect, Icon, SelectOption } from '@jll-labs/azara-ui-components';
import {
  InsightsAlertSeverity,
  InsightsAlert,
  InsightsAlertTypeFilter,
} from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { severityConfig } from '../utils';

const useStyles = makeStyles(() => ({
  zeroMinWidthMenuSelect: {
    minWidth: 0,
  },
}));

interface Props {
  severity: InsightsAlertSeverity | 'all';
  handleSeverityChange: (selectedOption: any) => void;
  severityOptions: SelectOption[];
  changeLabel?: boolean;
  fontSize?: 'small' | 'inherit' | 'medium' | 'large';
  activeAlert?: InsightsAlert;
  isFilter?: boolean;
}

const AlertSelectSeverity: React.FC<Props> = ({
  severity,
  handleSeverityChange,
  severityOptions,
  changeLabel = true,
  fontSize,
  activeAlert,
  isFilter,
}) => {
  const classes = useStyles();
  const [severityState, setSeverityState] = React.useState(severity);
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  React.useEffect(() => {
    setSeverityState(severity);
  }, [severity]);

  const handleChange = (option: SelectOption) => {
    if (!isFilter) {
      trackInsightsAlertEvent('alertAssignSeverity', {
        alertThreadId: activeAlert?.thread_id ?? '',
        alertType: activeAlert?.alert_type ?? InsightsAlertTypeFilter.All,
      });
    }

    setSeverityState(option.value as InsightsAlertSeverity);
    handleSeverityChange(option);
  };

  const severityIcon =
    severityConfig.find(sc => sc.value === severityState)?.icon || 'Flag';

  return (
    <MenuSelect
      options={severityOptions}
      onChange={handleChange}
      value={severityState || InsightsAlertSeverity.None}
      label={
        <Icon
          name={changeLabel ? severityIcon : 'Flag'}
          style={{ marginRight: '-12px' }}
          fontSize={fontSize}
        />
      }
      size="sm"
      buttonClassName={classes.zeroMinWidthMenuSelect}
    />
  );
};

export default AlertSelectSeverity;
