import { useMemo } from 'react';
import { getEncodedLink } from '@utils/router';
import { InsightsAlertsInboxTypeParam, UseClientRoutes } from '../../types';
import { getCommonBaseRoute } from '../../utils';

export const useInsightsAlertsRoutes = ({
  insightsAlerts: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      home: getCommonBaseRoute(link),
      inbox: {
        path: `${link}/:inboxType`,
        getLink: (inboxType: InsightsAlertsInboxTypeParam) =>
          getEncodedLink([link, inboxType]),
      },
      dashboard: {
        path: `${link}/report/:inboxType/:alertId/:alertScope?`,
        getLink: (
          alertId: string,
          inboxType: InsightsAlertsInboxTypeParam,
          alertScope?: 'internal' | 'external',
        ) => {
          if (alertScope) {
            return `${getEncodedLink([
              link,
              'report',
              inboxType,
              alertId,
              alertScope,
            ])}`;
          } else {
            return `${getEncodedLink([link, 'report', inboxType, alertId])}`;
          }
        },
      },
    };
  }, [getLink]);
