import {
  DataAvailabilitySheetFields,
  DataQuality,
  DataObject,
  DATA_DELIMITER,
  DATA_QUALITY,
  DATA_QUALITY_EXPLANATION,
  HAS_DATA,
} from './types';

export const parseDataAvailabilitySheetValues = (
  dataObject: DataObject[] = [],
): DataAvailabilitySheetFields => {
  return dataObject.reduce(
    (data: DataAvailabilitySheetFields, fieldValue) => {
      const rawValue = fieldValue?.value?.split(DATA_DELIMITER);
      const [field, value] = (rawValue ?? []).map(value => value.trim());

      if (field === HAS_DATA && value === 'TRUE') {
        return { ...data, hasData: true };
      }

      if (field === DATA_QUALITY && value !== 'N/A') {
        return {
          ...data,
          quality:
            value === 'Superior Data'
              ? DataQuality.Superior
              : DataQuality.Deficient,
        };
      }

      if (field === DATA_QUALITY_EXPLANATION && value !== 'N/A') {
        return { ...data, qualityExplanation: value };
      }

      return data;
    },
    {
      hasData: false,
      quality: undefined,
      qualityExplanation: undefined,
    },
  );
};
