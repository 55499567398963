import * as React from 'react';
import { CircularProgress, styled } from '@mui/material';

const StyledDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const SuspenseLoader: React.FC<{}> = () => {
  return (
    <StyledDiv>
      <CircularProgress color="primary" />
    </StyledDiv>
  );
};
