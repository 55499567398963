import * as React from 'react';
import { SelectOption } from '@jll-labs/azara-ui-components';
import { User } from '@apollo-red/hooks';

export const useClientSelectWithPageReload = (user: User) => {
  const [loadingTenant, setLoadingTenant] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (!loadingTenant) {
      return;
    }

    const nextTenant = user.user_tenant_settings.find(
      ({ user_tenant }) => user_tenant.id === loadingTenant,
    );

    window.location.pathname = nextTenant?.user_tenant?.tenant_ovc_id ?? 'home';

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTenant]);

  const handleClick = async (opt: SelectOption) => {
    const tenantId = opt.value as string;

    setLoadingTenant(tenantId);
  };

  return {
    handleClick,
    loadingTenant,
  };
};
