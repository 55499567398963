import { makeStyles } from '@mui/styles';
import { AppTheme } from '@theme';

interface StyleProps {
  showPinHelpMessage: boolean;
}

export const useInteractionSectionsStyles = makeStyles((theme: AppTheme) => ({
  container: {
    height: '100%',
  },
  sections: ({ showPinHelpMessage }: StyleProps) => ({
    height: showPinHelpMessage ? '94%' : '100%',
    overflowX: 'hidden',
  }),
  pinHelpMessage: ({ showPinHelpMessage }: StyleProps) => ({
    height: showPinHelpMessage ? '6%' : '0%',
    opacity: showPinHelpMessage ? '1' : '0',
    transition: 'opacity 0.25s ease-in',
  }),
  pinHelpMessageText: {
    fontSize: theme.typography.caption.fontSize,
    opacity: '0.6',
    padding: theme.spacing(1, 1, 1.5, 1),
    textAlign: 'center',
  },
}));
