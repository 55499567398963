import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Paper } from '@jll-labs/azara-ui-components';
import {
  useCheckFeatureFullAccess,
  useGetUserCurrentTenantReports,
} from '@apollo-red/hooks';
import { ContentContainer } from '@components/pageContainer';
import SettingsPageWrapper from '../pageWrapper';
import SettingsSection from '../SettingsSection';
import UniversalFilters from './UniversalFilters';
import ListOfReports from './ListOfReports';
import {
  RoleMenuItems,
  RoleOtherSubMenuItems,
} from '@components/pageContainer/SideBar/Navigation/navHelpers.types';

const ReportSettings: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { fullListOfReports } = useGetUserCurrentTenantReports();
  const { isFullAccess } = useCheckFeatureFullAccess(
    RoleMenuItems.Settings,
    RoleOtherSubMenuItems.DashboardSettings,
  );

  return (
    <SettingsPageWrapper breadcrumbsParts={[{ label: t('reportSettings') }]}>
      <ContentContainer title={t('reportSettings')}>
        <Paper>
          <Box px={5} py={2}>
            <SettingsSection title={t('universalFilters')}>
              <UniversalFilters disabled={!isFullAccess} />
            </SettingsSection>
            <SettingsSection title={t('listOfReports')}>
              <ListOfReports
                reports={fullListOfReports}
                disabled={!isFullAccess}
              />
            </SettingsSection>
          </Box>
        </Paper>
      </ContentContainer>
    </SettingsPageWrapper>
  );
};

export default ReportSettings;
