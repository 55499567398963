import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardsNotFound } from '@components/tiles';
import { DashboardProps } from '../types';
import BaseDashboards from './BaseDashboards';

const BaseFavoriteDashboards: React.FC<DashboardProps> = ({
  useSaveHandler,
  reports,
  showKeyMetrics,
  drag,
  showAddReports,
  dashboardRoutes,
  isPATDashboard,
}) => {
  const { t } = useTranslation();

  return (
    <BaseDashboards
      reports={reports}
      title={t('favorites')}
      breadcrumbsParts={[{ label: t('favorites') }]}
      breadcrumbsTitle={t('dashboards')}
      dashboardRoutes={dashboardRoutes}
      useSaveHandler={useSaveHandler}
      isPATDashboard={isPATDashboard}
      categoryId="favorites"
      showAddReports={showAddReports}
      showKeyMetrics={showKeyMetrics}
      drag={drag}
      autoOverflow={true}
      dashboardNotFound={
        <DashboardsNotFound
          useSaveHandler={useSaveHandler}
          isPATMenu={isPATDashboard}
        />
      }
    />
  );
};

export default BaseFavoriteDashboards;
