import * as React from 'react';
import { Loader } from '@jll-labs/azara-ui-components';
import {
  ReportInteractionSection,
  useReportMetadataControls,
} from '@apollo-red/hooks';
import { FlattenedFilter } from '@components/tableau/types';
import { useApplyFilterAsync, useApplyRangeFilterAsync } from '../../hooks';
import { FilterExtended } from '../../types';
import SectionHeader from './../SectionHeader';
import { useFullReportWithFilters } from '../../FullReportWithFiltersProvider';
import Filters from './Filters';

interface Props {
  section: ReportInteractionSection;
  tableauFilters?: FlattenedFilter[];
  filtersLoading?: boolean;
}

const FiltersPanel: React.FC<Props> = ({
  section,
  tableauFilters,
  filtersLoading,
}) => {
  const { viz } = useFullReportWithFilters();
  const controls = useReportMetadataControls(section);
  const { loading: filterChangeLoading, applyFilterAsync } =
    useApplyFilterAsync(viz);
  const { loading: rangeFilterChangeLoading, applyRangeFilterAsync } =
    useApplyRangeFilterAsync(viz);

  const getFilters = () => {
    const mergedFilters: FilterExtended[] = [];

    controls.map(control => {
      const tableauFilter = tableauFilters?.find(
        tf => tf.name === control.name,
      );

      const { staticData, ...restTableauFilter } = tableauFilter ?? {};

      if (tableauFilter) {
        const mergedFilter = {
          ...restTableauFilter,
          ...control,
          static_data:
            (control.static_data.length > 0
              ? control.static_data
              : staticData?.map(
                  ({ formattedValue, isPreselectedUniversalFilterValue }) => ({
                    label: formattedValue,
                    value: formattedValue,
                    selected: true,
                    isPreselectedUniversalFilterValue,
                  }),
                )) ?? [],
        } as FilterExtended;
        mergedFilters.push(mergedFilter);
      }
    });

    return mergedFilters;
  };

  const filters = getFilters();

  return filters?.length ? (
    <div>
      <SectionHeader title={section.label} />
      {filtersLoading ? (
        <Loader />
      ) : (
        <div>
          <Filters
            filters={filters}
            applyFilter={applyFilterAsync}
            loading={filterChangeLoading || rangeFilterChangeLoading}
            applyRangeFilter={applyRangeFilterAsync}
          />
        </div>
      )}
    </div>
  ) : null;
};

export default FiltersPanel;
