import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    textAlign: 'center',
  },
  margin: (props: { margin?: string }) => ({
    margin: props.margin || '20vh 0',
  }),
});

interface Props {
  message?: string;
  margin?: string;
  noMargin?: boolean;
}

const NoData: React.FC<Props> = ({
  message = '',
  margin = '',
  noMargin,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ margin });

  return (
    <Grid
      className={cx(classes.root, !noMargin && classes.margin)}
      container={true}
    >
      {children || <div>{t(message)}</div>}
    </Grid>
  );
};

export default NoData;
