import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_TENANT_DATA_LOADS_CONTAINERS } from '@apollo-red/queries';
import { stripProp } from '@utils/various';
import { useAppQuery } from '../../queries';

export const useGetDataLoadsContainers = (tenantId: string) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantDataLoadsContainersQuery,
    SchemaTypes.GetTenantDataLoadsContainersQueryVariables
  >(GET_TENANT_DATA_LOADS_CONTAINERS, {
    variables: {
      tenant_id: tenantId,
    },
    fetchPolicy: 'no-cache',
  });

  const dataLoadsContainers =
    data?.getTenants?.[0]?.tenant_data_loads_containers;

  return {
    loading,
    dataLoadsContainers: dataLoadsContainers
      ? stripProp(dataLoadsContainers, '__typename')
      : dataLoadsContainers,
  };
};
