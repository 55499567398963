import { UPDATE_RELEASE } from '@apollo-red/mutations';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '../queries';
import { UpdateReleaseInput } from './types';

export const useUpdateRelease = () => {
  const [updateRelease] = useAppMutation<
    SchemaTypes.UpdateReleaseMutation,
    SchemaTypes.UpdateReleaseMutationVariables
  >(UPDATE_RELEASE);

  return {
    updateRelease: (id: string, args: UpdateReleaseInput) =>
      updateRelease({ variables: { data: { ...args }, where: { id } } }),
  };
};
