import * as React from 'react';
import { FullReportContext } from '@components/fullReport';
import {
  HandleFirstInteractive,
  HandleVizDispose,
  Viz,
} from '@components/tableau/types';
import { usePendoTrackDashboardEvent } from '@utils/pendo';

export const useTableauViewEventTracking = () => {
  const { dashboardTrackEventContext } = React.useContext(FullReportContext);
  const [initTimestamp] = React.useState(() => new Date().getTime());
  const [duration, setDuration] = React.useState(-1);
  const [viewLoaded, setViewLoaded] = React.useState(false);
  const [viewTracked, setViewTracked] = React.useState(false);
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();

  React.useEffect(() => {
    if (duration === -1 || viewLoaded) {
      return;
    }

    setViewLoaded(true);
  }, [duration, viewLoaded]);

  React.useEffect(() => {
    if (
      duration === -1 ||
      !viewLoaded ||
      viewTracked ||
      !dashboardTrackEventContext.dashboardId
    ) {
      return;
    }

    return () => {
      const vizViewedTimestamp = new Date().getTime();
      const viewDuration = vizViewedTimestamp - (initTimestamp + duration);

      if (viewDuration < 1000) {
        return;
      }

      setViewTracked(true);

      trackDashboardEvent('dashboardView', {
        ...dashboardTrackEventContext,
        dashboardViewTime: Math.round(viewDuration / 1000),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    duration,
    viewLoaded,
    viewTracked,
    initTimestamp,
    dashboardTrackEventContext,
  ]);

  const trackFirstInteractive = React.useCallback<HandleFirstInteractive>(
    (_: Viz) => {
      const vizLoadedTimestamp = new Date().getTime();
      const loadTime = vizLoadedTimestamp - initTimestamp;

      if (dashboardTrackEventContext.dashboardId) {
        trackDashboardEvent('dashboardLoad', {
          ...dashboardTrackEventContext,
          dashboardLoadTime: Math.round(loadTime / 1000),
        });
      }

      setDuration(loadTime);
    },
    [dashboardTrackEventContext, initTimestamp, trackDashboardEvent],
  );

  const trackVizDispose = React.useCallback<HandleVizDispose>(
    (_: Viz) => {
      if (
        dashboardTrackEventContext.dashboardId &&
        viewLoaded &&
        !viewTracked
      ) {
        const vizDisposedTimestamp = new Date().getTime();
        const viewDuration = vizDisposedTimestamp - initTimestamp;

        trackDashboardEvent('dashboardView', {
          ...dashboardTrackEventContext,
          dashboardViewTime: Math.round(viewDuration / 1000),
        });
      }
    },
    [
      dashboardTrackEventContext,
      initTimestamp,
      trackDashboardEvent,
      viewLoaded,
      viewTracked,
    ],
  );

  return {
    dashboardTrackEventContext,
    initTimestamp,
    duration,
    trackFirstInteractive,
    trackVizDispose,
    trackDashboardEvent,
  };
};
