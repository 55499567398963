import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { ADD_USER_CATEGORY_REPORT } from '@apollo-red/mutations';
import {
  buildCategoriesList,
  buildPrimaryCategoriesList,
  buildPATCategoriesList,
} from './helpers';
import { useGetUserCurrentTenantReports } from '../reports';
import { User } from '../user';
import { Report } from '../../reports';
import { useAppMutation } from '../../queries';

export type UserCategory = SchemaTypes.UserCategoryBaseFragment;

export const useAddUserCategoryReport = () => {
  const [addUserCategoryReport] =
    useAppMutation<SchemaTypes.AddUserCategoryReportMutation>(
      ADD_USER_CATEGORY_REPORT,
    );

  return {
    addUserCategoryReport: (
      reportId: string,
      categoryDesc: string,
      user: User,
    ) => {
      const categories = user.user_settings.custom_categories || [];
      const hasReport = categories.find(
        c => c.report && c.report.id === reportId,
      );
      const mapped = categories
        .filter(c => !!c.report)
        .map(c => {
          const report = c.report as Report;
          return {
            report: {
              id: report.id,
              __typename: 'Report' as 'Report',
            },
            category_descriptions: [categoryDesc],
            __typename: 'UserCategory' as 'UserCategory',
          };
        });

      const updatedCustomCategories = hasReport
        ? mapped.map(c => {
            if (c.report.id === reportId) {
              return {
                ...c,
                category_descriptions: [categoryDesc],
              };
            }
            return c;
          })
        : mapped.concat({
            report: {
              id: reportId,
              __typename: 'Report' as 'Report',
            },
            category_descriptions: [categoryDesc],
            __typename: 'UserCategory' as 'UserCategory',
          });

      return addUserCategoryReport({
        variables: {
          data: {
            category_description: categoryDesc,
            report_id: reportId,
          },
          where: {
            id: user.id,
          },
        },
        optimisticResponse: {
          addUserCategoryReport: {
            id: user.id,
            user_settings: {
              custom_categories: updatedCustomCategories,
              __typename: 'UserSettings',
            },
            __typename: 'User',
          },
        },
      });
    },
  };
};

export const useGetUserReportCategories = () => {
  const { loading, allReports } = useGetUserCurrentTenantReports();

  return {
    loading,
    categories: buildCategoriesList(allReports).filter(c => !!c.id),
    primaryCategories: buildPrimaryCategoriesList(allReports),
  };
};

export const useGetUserCategory = (categoryId: string) => {
  const { loading, categories } = useGetUserReportCategories();

  const category = categories.find(c => c.id === categoryId) || {
    id: '',
    category_description: '',
    category_reports: [],
  };

  return {
    loading,
    category,
  };
};

export const useGetUserPATReportCategories = () => {
  const { loading, patReports } = useGetUserCurrentTenantReports();

  if (!patReports) {
    return {
      loading,
      categories: [],
      primaryCategories: [],
    };
  }

  const categories = buildPATCategoriesList(patReports)
    .filter(c => !!c.id)
    .filter(c => {
      if (c.id === 'favorites' || c.id === 'all') {
        return true;
      } else {
        return c.category_reports.length > 0;
      }
    });

  return {
    loading,
    categories,
    primaryCategories: buildPrimaryCategoriesList(patReports),
  };
};

export const useGetUserPATCategory = (categoryId: string) => {
  const { loading, categories } = useGetUserPATReportCategories();

  const category = categories.find(c => c.id === categoryId) || {
    id: '',
    category_description: '',
    category_reports: [],
  };

  return {
    loading,
    category,
  };
};
