import { gql } from '@apollo/client';

export const ColumnSchemaBase = {
  fragment: gql`
    fragment ColumnSchemaBase on ColumnSchema {
      name
      type
      kind
      null
      default
      primary_key
      unique_key
      check
      expression
      comment
    }
  `,
};

export const ViewSchemaBase = {
  fragment: gql`
    fragment ViewSchemaBase on ViewSchema {
      name
      columns {
        ...ColumnSchemaBase
      }
    }
    ${ColumnSchemaBase.fragment}
  `,
};

export const ApplicationSchemaBase = {
  fragment: gql`
    fragment ApplicationSchemaBase on ApplicationSchema {
      name
      views {
        ...ViewSchemaBase
      }
    }
    ${ViewSchemaBase.fragment}
  `,
};

export const ApplicationSubcodeBase = {
  fragment: gql`
    fragment ApplicationSubcodeBase on ApplicationSubcode {
      app_subcode
      app_subcode_description
    }
  `,
};

export const ApplicationBase = {
  fragment: gql`
    fragment ApplicationBase on Application {
      id
      app_name
      app_active
      app_cat
      app_default
      app_description
      app_data_upload
      app_subcodes {
        ...ApplicationSubcodeBase
      }
    }
    ${ApplicationSubcodeBase.fragment}
  `,
};

export const ApplicationExtended = {
  fragment: gql`
    fragment ApplicationExtended on Application {
      ...ApplicationBase
      app_schema(client_id: $tenant_id) {
        ...ApplicationSchemaBase
      }
    }

    ${ApplicationBase.fragment}
    ${ApplicationSchemaBase.fragment}
  `,
};
