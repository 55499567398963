import { SelectOption } from '@jll-labs/azara-ui-components';
import * as Yup from 'yup';
import {
  InsightsAlertRoiOpportunity,
  InsightsAlertRoiUnit,
  InsightsAlertRoiFrequency,
} from '@apollo-red/hooks';
import { FormValues } from './Roi.types';

export const opportunityTypeMapping: Record<
  InsightsAlertRoiOpportunity,
  string
> = {
  cost_reduction: 'Cost Reduction',
  cycle_time: 'Cycle Time',
  human_experience: 'Human Experience',
  labour_hour: 'Labor Hours',
  property_size: 'Property Size',
};

export const unitMapping: Record<InsightsAlertRoiUnit, string> = {
  aud: 'AUD',
  eur: 'EUR',
  grb: 'GBP',
  usd: 'USD',
  hours: 'Hours',
  days: 'Days',
  sqft: 'Square Feet',
  sqm: 'Square Meters',
};

export const frequencyMapping: Record<InsightsAlertRoiFrequency, string> = {
  one_time: 'One Time',
  recurring_monthly: 'Recurring – Monthly',
  recurring_quarterly: 'Recurring – Quarterly',
  recurring_annually: 'Recurring – Annually',
};

export const validationSchema = Yup.object<FormValues>({
  value: Yup.number().typeError('Value must be a number'),
});

export const LABELS: Record<keyof FormValues, string> = {
  frequency: 'Frequency',
  opportunityType: 'Opportunity Type',
  unit: 'Unit',
  value: 'Value',
  comment: 'Comment',
};

export const FIELDS: Record<keyof FormValues, keyof FormValues> = {
  comment: 'comment',
  opportunityType: 'opportunityType',
  frequency: 'frequency',
  unit: 'unit',
  value: 'value',
};

export const initialValues = (values?: FormValues): FormValues => ({
  comment: '',
  frequency: undefined,
  opportunityType: undefined,
  unit: undefined,
  value: 0,
  ...values,
});

export const opportunityTypeOptions: SelectOption[] = Object.entries(
  opportunityTypeMapping,
)
  .map(([key, value]) => ({ label: value, value: key }))
  .sort((item1, item2) => item1.label.localeCompare(item2.label));

export const unitOptions: SelectOption[] = Object.entries(unitMapping).map(
  ([key, value]) => ({ label: value, value: key }),
);

export const frequencyOptions: SelectOption[] = Object.entries(
  frequencyMapping,
).map(([key, value]) => ({ label: value, value: key }));
