import { useGetUserInfo } from '../../user';
import { useGetUserDefaultTenantId } from './useGetUserDefaultTenantId';

export const useGetUserCurrentTenantSettings = () => {
  const tenantId = useGetUserDefaultTenantId();
  const {
    user: { user_tenant_settings },
    loading,
  } = useGetUserInfo();

  return {
    loading,
    userTenantSettings: user_tenant_settings.find(
      uts => uts.user_tenant.id === tenantId,
    ),
  };
};
