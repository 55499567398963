import * as React from 'react';
import {
  useReplaceFavorites,
  Report,
  useGetUserFavPATReports,
} from '@apollo-red/hooks';
import { BaseFavoriteDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';

const useSaveHandler = (reports: Report[]) => {
  const { replaceFavReports } = useReplaceFavorites();
  return {
    saveHandler: () => replaceFavReports(reports.map(({ id }) => id)),
  };
};

const FavoriteDashboards: React.FC = () => {
  const routes = useRoutes();
  const { favReports } = useGetUserFavPATReports();

  if (!favReports) {
    return null;
  }

  return (
    <BaseFavoriteDashboards
      reports={favReports}
      useSaveHandler={useSaveHandler}
      showAddReports={true}
      showKeyMetrics={false}
      drag={false}
      dashboardRoutes={routes.PATDashboards}
      isPATDashboard
    />
  );
};

export default FavoriteDashboards;
