import * as React from 'react';
import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';
import { IconName, Icon, utils } from '@jll-labs/azara-ui-components';
import { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { SystemStyleObject } from '@mui/system';
import { TriangleBackground } from '@assets';

const { createUpBreakpoints } = utils;

const middleIconContainerSize = {
  xs: '32vw',
  sm: '24vw',
  md: '16vw',
  lg: '10vw',
};

const descPadding = {
  xs: '35vw',
  md: '20vw',
  lg: '12vw',
};

const createIconContainerBreakpoints: CreateBreakpointFn<Theme> = (
  _,
  breakpoint,
) => {
  return {
    top: `calc(40% - ${middleIconContainerSize[breakpoint]} / 2)`,
    left: `calc(50% - ${middleIconContainerSize[breakpoint]} / 2)`,
    width: middleIconContainerSize[breakpoint],
    height: middleIconContainerSize[breakpoint],
  };
};

const createDescriptionBreakpoints: CreateBreakpointFn<Theme> = (
  _,
  breakpoint,
) => {
  return {
    paddingTop: `calc(${descPadding[breakpoint]} / 2)`,
  };
};

const useStyles = (): Record<
  | 'container'
  | 'upperBg'
  | 'description'
  | 'middleIcon'
  | 'middleIconImg'
  | 'statusMsg'
  | 'secondaryMsg'
  | 'detailsMsg',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();

  return {
    container: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: theme.palette.common.white,
    },
    upperBg: {
      backgroundImage: `url(${TriangleBackground})`,
      backgroundSize: 'cover',
      height: '40vh',
    },
    description: {
      textAlign: 'center',
      ...createUpBreakpoints(
        ['xs', 'md', 'lg'],
        theme,
        createDescriptionBreakpoints,
      ),
    },
    middleIcon: {
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      display: 'flex',
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg'],
        theme,
        createIconContainerBreakpoints,
      ),
    },
    middleIconImg: {
      fontSize: '75px',
      color: theme.palette.action.active,
    },
    statusMsg: {
      margin: '1vw',
    },
    secondaryMsg: {
      color: theme.palette.primary.main,
    },
    detailsMsg: {
      width: '50%',
    },
  };
};

export enum MainIcon {
  Build,
  Warning,
}

const iconAsset: { [key in MainIcon]: IconName } = {
  [MainIcon.Build]: 'Build',
  [MainIcon.Warning]: 'Warning',
};

interface Props {
  statusMsg: string;
  secondaryMsg: string;
  detailsMsg: string;
  icon: MainIcon;
}

const ErrorScreen: React.FC<Props> = ({
  icon,
  statusMsg,
  secondaryMsg,
  detailsMsg,
  children,
}) => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.upperBg} />
      <Grid
        sx={styles.description}
        container={true}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Typography variant="h4" sx={styles.statusMsg}>
          {statusMsg}
        </Typography>
        <Typography variant="body1" sx={styles.secondaryMsg}>
          {secondaryMsg}
        </Typography>
        <Typography variant="body1" sx={styles.detailsMsg}>
          {detailsMsg}
        </Typography>
      </Grid>
      <Box alignItems="center" justifyContent="center" sx={styles.middleIcon}>
        <Icon sx={styles.middleIconImg} name={iconAsset[icon]} />
      </Box>
      {children}
    </Box>
  );
};

export default ErrorScreen;
