import * as React from 'react';
import { ReportExtended } from '@apollo-red/hooks';

export enum FullReportEventTrackingType {
  Auth = 'Auth',
  Core = 'Core',
  CoreAlert = 'Core - Alert',
  CoreKpi = 'Core - KPI',
  Legacy = 'Legacy',
  SavedView = 'Saved View',
  CustomAzaraHosted = 'Custom - Azara Hosted',
  CustomExternal = 'Custom - External',
  Other = 'Other',
}

export const getFullReportEventTrackingType = (
  report: ReportExtended,
): FullReportEventTrackingType => {
  const {
    report_tableau_full_report_url,
    isSavedView,
    isCustomReport,
    isExternalReport,
    isAlertReport,
    isAuthReport,
    isKpiReport,
    isPatReport,
  } = report;
  let type = FullReportEventTrackingType.Core;

  if (isSavedView) {
    type = FullReportEventTrackingType.SavedView;
  } else if (isCustomReport) {
    type = FullReportEventTrackingType.CustomAzaraHosted;
  } else if (isExternalReport) {
    type = FullReportEventTrackingType.CustomExternal;
  } else if (isAlertReport) {
    type = FullReportEventTrackingType.CoreAlert;
  } else if (isAuthReport) {
    type = FullReportEventTrackingType.Auth;
  } else if (isKpiReport) {
    type = FullReportEventTrackingType.CoreKpi;
  } else if (isPatReport) {
    type = FullReportEventTrackingType.Legacy;
  } else if (!report_tableau_full_report_url) {
    type = FullReportEventTrackingType.Other;
  }

  return type;
};

interface FullReportEventTrackingMetadata {
  id: string;
  parentId: string;
  name: string;
  type: FullReportEventTrackingType;
  category: string;
  customCategory: string;
  url: string;
  isFavorite: boolean;
}

export interface DashboardTrackEventContext {
  dashboardId: string;
  dashboardParentId: string;
  dashboardName: string;
  dashboardType: string;
  dashboardOriginalCategory: string;
  dashboardCategory: string;
  dashboardUrl: string;
  dashboardIsFavorite: boolean;
}

interface FullReportContextValue {
  dashboardTrackEventContext: DashboardTrackEventContext;
  setReport: (report?: ReportExtended) => void;
  report?: ReportExtended;
  eventTrackingMetadata?: FullReportEventTrackingMetadata;
}

export const FullReportContext = React.createContext<FullReportContextValue>({
  dashboardTrackEventContext: {
    dashboardId: '',
    dashboardParentId: '',
    dashboardName: '',
    dashboardType: '',
    dashboardOriginalCategory: '',
    dashboardCategory: '',
    dashboardUrl: '',
    dashboardIsFavorite: false,
  },
  setReport: (_?: ReportExtended) => {},
});

interface Props {
  report?: ReportExtended;
}

const FullReportContextProvider: React.FC<Props> = ({
  report: fullReport,
  children,
}) => {
  const [report, setReport] = React.useState<ReportExtended | undefined>(
    fullReport,
  );
  const [eventTrackingMetadata, setEventTrackingMetadata] =
    React.useState<FullReportEventTrackingMetadata>();

  const dashboardTrackEventContext: DashboardTrackEventContext =
    React.useMemo(() => {
      const {
        id,
        parentId,
        name,
        type,
        category,
        customCategory,
        url,
        isFavorite,
      } = eventTrackingMetadata ?? {};

      return {
        dashboardId: id ?? '',
        dashboardParentId: parentId ?? '',
        dashboardName: name ?? '',
        dashboardType: type ?? '',
        dashboardOriginalCategory: category ?? '',
        dashboardCategory: customCategory ?? '',
        dashboardUrl: url ?? '',
        dashboardIsFavorite: isFavorite ?? false,
      };
    }, [eventTrackingMetadata]);

  React.useEffect(() => {
    if (!report) {
      return;
    }

    const {
      id,
      report_parent: parentId,
      report_name: name,
      report_tableau_full_report_url,
      report_external_url,
      primaryCategory: customCategory,
      originalCategory: category,
      isFav: isFavorite,
    } = report;

    const type = getFullReportEventTrackingType(report);
    let url = report_tableau_full_report_url;

    if (type === FullReportEventTrackingType.CustomExternal) {
      url = report_external_url ?? '';
    }

    setEventTrackingMetadata({
      id,
      parentId: parentId ?? '',
      name,
      type,
      category,
      customCategory,
      url,
      isFavorite,
    });
  }, [report, setEventTrackingMetadata]);

  return (
    <FullReportContext.Provider
      value={{
        dashboardTrackEventContext,
        setReport,
        report,
        eventTrackingMetadata,
      }}
    >
      {children}
    </FullReportContext.Provider>
  );
};

export default FullReportContextProvider;
