import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';

export const useGetInsightsInboxSettings = () => {
  const tenantId = useGetUserDefaultTenantId();
  const { user, loading } = useGetUserInfo();

  const tenantSettings = user.user_tenant_regular_settings.find(
    settings => settings.user_tenant.id === tenantId,
  );

  return {
    inboxSettings: tenantSettings?.user_insights_inbox_settings,
    loading,
  };
};
