import produce from 'immer';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import * as Resolvers from '@apollo-red/schema/generated/resolvers';
import { ApolloClient } from '@apollo/client';
import { UserInsightsInboxSettingsBase } from '@apollo-red/fragments';

export const updateInsightsInboxSettings = (
  updateFn: (
    inboxSettings: SchemaTypes.UserInsightsInboxSettingsBaseFragment,
  ) => SchemaTypes.UserInsightsInboxSettingsBaseFragment,
  userId: string,
  tenantId: string,
  cache: ApolloClient<any>['cache'],
) => {
  const id = `UserInsightsInboxSettings:${userId}_${tenantId}`;

  const inboxSettings =
    cache.readFragment<SchemaTypes.UserInsightsInboxSettingsBaseFragment>({
      fragmentName: 'UserInsightsInboxSettingsBase',
      fragment: UserInsightsInboxSettingsBase.fragment,
      id,
    });

  if (inboxSettings) {
    cache.writeFragment<SchemaTypes.UserInsightsInboxSettingsBaseFragment>({
      fragmentName: 'UserInsightsInboxSettingsBase',
      fragment: UserInsightsInboxSettingsBase.fragment,
      id,
      data: updateFn(inboxSettings),
    });
  }

  return null;
};

const Mutation: Resolvers.MutationResolvers = {
  setInsightsInboxImportanceFilter: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings => ({
        ...inboxSettings,
        insights_selected_filters: {
          ...inboxSettings.insights_selected_filters,
          insights_importance_filter: variables.importanceFilter,
        },
      }),
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
  setInsightsInboxRecentAlert: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings => {
        const updatedAlerts = inboxSettings.insights_inbox_alerts.map(
          settings => {
            if (settings.insights_inbox_type === variables.inboxType) {
              return {
                ...settings,
                insights_alert_selected: {
                  thread_id: variables.threadId,
                  __typename: 'InsightsAlert' as 'InsightsAlert',
                },
              };
            }
            return settings;
          },
        );

        return {
          ...inboxSettings,
          insights_inbox_alerts: updatedAlerts,
        };
      },
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
  setInsightsInboxRemoveRecentAlert: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings =>
        produce(inboxSettings, draft => {
          const inboxAlertSettings = draft.insights_inbox_alerts.find(
            settings => settings.insights_inbox_type === variables.inboxType,
          );

          if (inboxAlertSettings) {
            inboxAlertSettings.insights_alert_selected = null;
          }
        }),
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
  setInsightsInboxAlertTypeFilter: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings => ({
        ...inboxSettings,
        insights_selected_filters: {
          ...inboxSettings.insights_selected_filters,
          insights_alert_type_filter: variables.alertTypeFilter,
        },
      }),
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
  setInsightsInboxAlertSortOrder: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings => ({
        ...inboxSettings,
        insights_selected_sort_order: {
          created_date: variables.createdSortOrder,
          __typename: 'InsightsInboxSelectedSortOrder',
        },
      }),
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
  setInsightsInboxAlertSeverity: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings => ({
        ...inboxSettings,
        insights_selected_filters: {
          ...inboxSettings.insights_selected_filters,
          insights_alert_severity: variables.alertSeverityFilter,
        },
      }),
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
  setInsightsInboxAlertUserAssignedTo: (_root, variables, ctx) => {
    return updateInsightsInboxSettings(
      inboxSettings => ({
        ...inboxSettings,
        insights_selected_filters: {
          ...inboxSettings.insights_selected_filters,
          insights_alert_assigned_to: variables.userAssigned,
        },
      }),
      variables.userId,
      variables.tenantId,
      ctx.cache,
    );
  },
};

export default Mutation;
