import * as React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormProps } from '@jll-labs/azara-ui-components';
import SearchableList from '@components/SearchableList';
import { FormValues } from '../AlertSettings.constants';
import { showExternalAlerts } from '../AlertSettings.utils';

const useStyles = makeStyles(() => ({
  groups: {
    marginBottom: '1em',
    maxHeight: 150,
    overflowY: 'auto',
  },
}));

export interface SingleSelectOption {
  value: string;
  label: string;
  tenantId: string;
}

interface AlertCategoriesProps {
  loading?: boolean;
  tenantAlertCategories: {
    internal: string[];
    external?: string[];
  };
}

const AlertCategories: React.FC<AlertCategoriesProps & FormProps<FormValues>> =
  ({
    loading,
    tenantAlertCategories,
    values,
    setFieldValue,
    setFieldTouched,
  }) => {
    const classes = useStyles();
    const showExternal = showExternalAlerts(values.alert_type);

    const handleChange = (group: string) => (listSelected: string[]) => {
      setFieldValue(group, listSelected);
      setFieldTouched(group);
    };

    if (loading) {
      return (
        <Grid container justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      );
    }

    return (
      <Grid container data-testid="alert-categories">
        <Grid className={classes.groups} container spacing={2}>
          <Grid item xs={showExternal ? 6 : 12}>
            <SearchableList
              name="Internal"
              selected={values.internal}
              options={tenantAlertCategories.internal}
              handleChange={handleChange('internal')}
            />
          </Grid>
          {showExternal && (
            <Grid item xs={6}>
              <SearchableList
                name="External"
                selected={values.external}
                options={tenantAlertCategories.external || []}
                handleChange={handleChange('external')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

export default AlertCategories;
