import { ReleaseBase } from '@apollo-red/fragments';
import { gql } from '@apollo/client';

export const UPDATE_RELEASE = gql`
  mutation updateRelease(
    $where: WhereReleaseInput!
    $data: UpdateReleaseInput!
  ) {
    updateRelease(where: $where, data: $data) {
      ...ReleaseBase
    }
  }
  ${ReleaseBase.fragment}
`;

export const PROMOTE_RELEASE = gql`
  mutation promoteRelease(
    $where: WhereReleaseInput!
    $data: PromoteReleaseInput!
  ) {
    promoteRelease(where: $where, data: $data) {
      ...ReleaseBase
    }
  }
  ${ReleaseBase.fragment}
`;
