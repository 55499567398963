import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { SYNC_USER, SYNC_USER_PROVISIONING } from '@apollo-red/mutations';
import { useAppMutation } from '../../queries';

export const useSyncUser = () => {
  const [syncUser] = useAppMutation<
    SchemaTypes.SyncUserMutation,
    SchemaTypes.SyncUserMutationVariables
  >(SYNC_USER);
  return {
    syncUser: React.useCallback(
      (id: string) => {
        return syncUser({
          variables: {
            where: { id },
          },
        });
      },
      [syncUser],
    ),
  };
};

export const useSyncUserProvisioning = () => {
  const [syncUserProvisioning] = useAppMutation<
    SchemaTypes.SyncUserProvisioningMutation,
    SchemaTypes.SyncUserProvisioningMutationVariables
  >(SYNC_USER_PROVISIONING, undefined, { globalErr: false });
  return {
    syncUserProvisioning: React.useCallback(
      (tenantId: string, userId: string) => {
        return syncUserProvisioning({
          variables: {
            tenantId,
            where: { id: userId },
          },
        });
      },
      [syncUserProvisioning],
    ),
  };
};
