import * as React from 'react';

const PHONE_REGEXP = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/g;

export const usePhoneNumberWithValidation = (
  initValue: string,
): [string, React.Dispatch<React.SetStateAction<string>>, boolean] => {
  const [phone, setPhone] = React.useState(initValue);
  const parsedPhone =
    phone.length === 0 || phone.indexOf('+') === 0 ? phone : `+${phone}`;
  const matchPattern = parsedPhone.match(PHONE_REGEXP);
  const isValid = !!(matchPattern && matchPattern.length > 0);
  return [parsedPhone, setPhone, isValid];
};
