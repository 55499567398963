import * as React from 'react';
import { useTableauUrlWithTrustedAuthExternalReport } from '@utils/tableau';
import { useGetUserDefaultTenantId } from '@apollo-red/hooks';
import OpenInNewTab from './OpenInNewTab';

interface Props {
  reportId: string;
  url: string;
  handleOpen: (val: boolean) => void;
}

const OpenInNewTabWithTrustedAuthExternalReport: React.FC<Props> = ({
  handleOpen,
  reportId,
  url,
}) => {
  const tenantId = useGetUserDefaultTenantId();
  const viewUrl = useTableauUrlWithTrustedAuthExternalReport({
    tenantId,
    reportId,
    url,
  });

  return <OpenInNewTab handleOpen={handleOpen} url={viewUrl} />;
};

export default OpenInNewTabWithTrustedAuthExternalReport;
