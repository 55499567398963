import * as React from 'react';
import { Modal, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Icon } from '@jll-labs/azara-ui-components';
import { useRect } from '@utils/htmlEvents';
import { FullReportContextProvider } from '@components/fullReport';
import {
  TableauLoader,
  TableauViewCached,
  TableauLoaderStyle,
} from '@components/tableau';
import { TableauTimeout, TableauLicenseValidator } from '@components/tableau';
import { useTableau, useTableauDataAvailability } from '@utils/tableau';
import {
  LogReportType,
  Report,
  useGetUserSpecialReportById,
} from '@apollo-red/hooks';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    float: 'left',
    left: '50%',
    top: '50%',
    backgroundColor: theme.palette.background.paper,
    transform: 'translate(-50%, -50%)',
    boxShadow: theme.shadows[5],
    outline: 'none',
    right: 0,
    marginRight: theme.spacing(4),
    overflow: 'hidden',
    width: '70vw',
    height: '43vw',
    padding: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      width: '65vw',
      height: '42vw',
    },
  },
  gridContainer: {
    height: '100%',
  },
  contentContainer: {
    flex: 1,
    height: '100%',
  },
}));

interface Props {
  title: string;
  open: boolean;
  handleClose: () => void;
  report: Report;
  noData?: React.ReactNode;
}

const KeyMetricModal: React.FC<Props> = ({
  open,
  title,
  handleClose,
  report,
  noData,
}) => {
  const classes = useStyles();
  const modalRef = React.useRef<HTMLDivElement>(null);
  const rect = useRect(modalRef);
  const {
    setRefElementCb,
    refElement,
    refElementOffset,
    refElementTransform,
    handleLoadComplete,
    handleFirstVizSizeKnown,
    vizLoading,
    viz,
  } = useTableau({ transform: true });
  const { isDataLoading, isDataAvailable } = useTableauDataAvailability(viz);
  const { report: kpiReportExtended } = useGetUserSpecialReportById(report.id);
  const cacheId = `${report.id}_${title}`;

  return (
    <Modal open={open} onClose={handleClose} disableScrollLock={true}>
      <div ref={modalRef} className={classes.paper}>
        <Grid
          container={true}
          justifyContent="flex-end"
          className={classes.gridContainer}
        >
          <div className={classes.contentContainer}>
            <FullReportContextProvider report={kpiReportExtended}>
              <TableauLicenseValidator>
                <div ref={setRefElementCb} style={{ height: '100%' }}>
                  {refElement && (
                    <TableauTimeout viz={viz} id={cacheId} height="100%">
                      <TableauLoader
                        loading={vizLoading}
                        loader={TableauLoaderStyle.Circular}
                        loaderStyles={{ height: '100%' }}
                      >
                        {noData && !isDataLoading && !isDataAvailable ? (
                          noData
                        ) : (
                          <TableauViewCached
                            cacheId={cacheId}
                            url={report.report_tableau_full_report_url}
                            containerStyle={{
                              margin: '25px',
                              top: rect.top,
                              left: rect.left,
                              transformOrigin: 'left top',
                              transform: refElementTransform,
                            }}
                            container="bodyContainer"
                            offset={refElementOffset}
                            applyUniversalFilters={{
                              basic: true,
                              geographic: true,
                            }}
                            handleLoadComplete={handleLoadComplete}
                            handleFirstInteractive={handleLoadComplete}
                            handleFirstVizSizeKnown={handleFirstVizSizeKnown}
                            reportLogContext={{
                              reportType: LogReportType.KpiDetailedReport,
                              reportId: report.id,
                              reportName: report.report_name,
                              reportCategory:
                                report.report_cat?.[0]?.category_description ||
                                'KPI',
                            }}
                          />
                        )}
                      </TableauLoader>
                    </TableauTimeout>
                  )}
                </div>
              </TableauLicenseValidator>
            </FullReportContextProvider>
          </div>
          <div>
            <IconButton onClick={handleClose} label="Clear" size="large">
              <Icon name="Clear" />
            </IconButton>
          </div>
        </Grid>
      </div>
    </Modal>
  );
};

export default KeyMetricModal;
