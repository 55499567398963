import * as React from 'react';
import { DiscreteSlider as Slider } from '@jll-labs/azara-ui-components';
import { getPercentageSteps } from '../utils';

interface Props {
  initialValue: number;
  loading: boolean;
  updateValue: (value: number) => void;
  percentageStep?: number;
  step?: number;
}

const getMarks = (
  percentageStep: number,
): Array<{ value: number; label?: React.ReactNode }> => {
  const steps = getPercentageSteps(percentageStep);
  return steps.map(step => ({
    label: step,
    value: step,
  }));
};

const PercentStep: React.FC<Props> = ({
  initialValue,
  loading,
  updateValue,
  percentageStep = 20,
  step = 5,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (value: number) => {
    setValue(value);
    updateValue(value);
  };

  return (
    <div style={{ marginTop: '18px' }}>
      <Slider
        min={0}
        max={100}
        marks={getMarks(percentageStep)}
        step={step}
        disabled={loading}
        value={value}
        setValues={handleChange}
      />
    </div>
  );
};

export default PercentStep;
