import { useAppPerformance } from '@components/appPerformance';

const REFRESH_PARAM_NAME = ':refresh';
const REFRESH_PARAM_VALUE = 'yes';

export interface UseTableauUrlWithRefreshProps {
  url?: string | null;
  filters?: string | null;
  asImage?: boolean;
  forceRefreshReports?: boolean;
}

export const getTableauUrlWithRefresh = ({
  url,
  filters,
  asImage,
  forceRefreshReports,
}: UseTableauUrlWithRefreshProps) => {
  if (!url) {
    return '';
  }

  const urlParts = url.split('?');
  const viewFilters = `${filters ?? ''}`;
  const paramSeparator = asImage || forceRefreshReports ? '&' : '';
  const params = forceRefreshReports
    ? `${REFRESH_PARAM_NAME}=${REFRESH_PARAM_VALUE}${paramSeparator}${viewFilters}`
    : viewFilters;

  urlParts.splice(1, 0, '?');

  if (asImage) {
    urlParts.splice(1, 0, '.png');
  }

  urlParts.push(params);

  const newUrl = urlParts.join('');
  const lastCharacter = newUrl.slice(-1);

  if (lastCharacter === '?' || lastCharacter === '&') {
    return newUrl.slice(0, newUrl.length - 1);
  }

  return newUrl;
};

export const useTableauUrlWithRefresh = ({
  url,
  filters,
  asImage,
  forceRefreshReports,
}: UseTableauUrlWithRefreshProps) => {
  const appPerformance = useAppPerformance();
  const { refreshReports } = appPerformance ?? {
    refreshReports: false,
  };

  return getTableauUrlWithRefresh({
    url,
    filters,
    asImage,
    forceRefreshReports: forceRefreshReports || refreshReports,
  });
};
