import {
  ParameterDataType,
  ParameterValueType,
} from '@components/tableau/types';

export const parseNumberValue = (
  value: ParameterValueType,
  dataType: ParameterDataType,
) => {
  if (dataType === ParameterDataType.INTEGER) {
    return parseInt(value.toString(), 10);
  } else if (dataType === ParameterDataType.FLOAT) {
    return parseFloat(value.toString());
  } else {
    return 0;
  }
};
