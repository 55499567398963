import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentContainer } from '@components/pageContainer';
import AccountForm from './AccountForm';
import SettingsPageWrapper from '../pageWrapper';
import AccountWrapper from './AccountWrapper';
import UserAvatar from './UserAvatar';
import { useCheckFeatureFullAccess } from '@apollo-red/hooks';
import {
  RoleMenuItems,
  RoleOtherSubMenuItems,
} from '@components/pageContainer/SideBar/Navigation/navHelpers.types';

const Account: React.FC = () => {
  const { t } = useTranslation();
  const { isFullAccess } = useCheckFeatureFullAccess(
    RoleMenuItems.Settings,
    RoleOtherSubMenuItems.AccountSettings,
  );

  return (
    <SettingsPageWrapper breadcrumbsParts={[{ label: t('accountSettings') }]}>
      <ContentContainer title={t('accountSettings')}>
        <AccountWrapper
          leftColumn={<UserAvatar disabled={!isFullAccess} />}
          rightColumn={<AccountForm disabled={!isFullAccess} />}
        />
      </ContentContainer>
    </SettingsPageWrapper>
  );
};

export default Account;
