import { gql } from '@apollo/client';
import { KeywordBase } from '@apollo-red/fragments';

export const ReportMappingBase = {
  fragment: gql`
    fragment ReportMappingBase on ReportMapping {
      id
      report_name
      report_keywords {
        ...KeywordBase
      }
      report_description
      report_type
      report_mapping_exists
    }
    ${KeywordBase.fragment}
  `,
};
