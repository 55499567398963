import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoutes } from '@routes';
import { ActiveUserOnly } from '@components/featureToggle';
import Dashboard from './Dashboard';
import FavoriteDashboards from './FavoriteDashboards';
import AllDashboards from './AllDashboards';
import CategoryDashboards from './CategoryDashboards';
import SavedDashboards from './SavedDashboards';
import SearchDashboards from './SearchDashboards';
import ExternalReport from './ExternalReport';

const Dashboards: React.FC = () => {
  const { dashboards } = useRoutes();

  return (
    <ActiveUserOnly>
      <Switch>
        <Route exact={true} path={dashboards.favorites.path}>
          <FavoriteDashboards />
        </Route>
        <Route exact={true} path={dashboards.all.path}>
          <AllDashboards />
        </Route>
        <Route exact={true} path={dashboards.saved?.path}>
          <SavedDashboards />
        </Route>
        <Route exact={true} path={dashboards.search?.path}>
          <SearchDashboards />
        </Route>
        <Route exact={true} path={dashboards.category.path}>
          <CategoryDashboards />
        </Route>
        <Route exact={true} path={dashboards.external?.path}>
          <ExternalReport />
        </Route>
        <Route exact={true} path={dashboards.dashboard.path}>
          <Dashboard />
        </Route>
        <Route exact={true} path={dashboards.home.path}>
          <Redirect to={dashboards.favorites.path} />
        </Route>
      </Switch>
    </ActiveUserOnly>
  );
};

export default Dashboards;
