import { useMemo } from 'react';
import { getEncodedLink } from '@utils/router';
import { UseClientRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useDataManagementRoutes = ({
  dataManagement: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      dataLoads: getCommonRoute(link, 'data-loads'),
      dataViews: getCommonRoute(link, 'data-views'),
      dataView: {
        path: `${link}/data-views/:application/:view`,
        getLink: (application: string, view: string) =>
          getEncodedLink([link, 'data-views', application, view]),
      },
      dataStandards: getCommonRoute(link, 'data-standards'),
      propertyData: getCommonRoute(link, 'property-data'),
    };
  }, [getLink]);
