import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { Report } from './reports';

export type ReportMetadata = NonNullable<
  SchemaTypes.ReportMetadata | null | undefined
>;
export type ReportMetadataMapping = SchemaTypes.ReportMetadataMapping;
export type ReportInteractionConfiguration =
  SchemaTypes.ReportInteractionConfiguration;
export type ReportInteractionSection = SchemaTypes.ReportInteractionSection;
export type BaselineReportInteractionSection =
  SchemaTypes.BaselineReportInteractionSection;
export type ReportControl = SchemaTypes.ReportControl;
export type BaselineReportControl = SchemaTypes.BaselineReportControl;
export type ReportControlStaticListItem =
  SchemaTypes.ReportControlStaticListItem;
export type ReportMetadataSourceData = SchemaTypes.ReportMetadataSourceData;
export type ReportModule = SchemaTypes.ReportModule;
export type ReportModuleCategory = SchemaTypes.ReportModuleCategory;

const fallbackReportModule: ReportModule = {
  enabled: true,
  module_type: SchemaTypes.ReportModuleType.Other,
};

const fallbackReportModuleCategory: ReportModuleCategory = {
  enabled: true,
  module_category_type: SchemaTypes.ReportModuleCategoryType.Other,
};

const fallbackInteractionSections: ReportInteractionSection[] = [];

const fallbackReportInteractionConfiguration: ReportInteractionConfiguration = {
  id: '',
  enabled: false,
  sections: fallbackInteractionSections,
};

const fallbackSourceData: ReportMetadataSourceData = {};

const fallbackReportMetadataMapping: ReportMetadataMapping = {};

const fallbackReportMetadata: ReportMetadata = {
  id: '',
  enabled: true,
  module: fallbackReportModule,
  module_category: fallbackReportModuleCategory,
  platform: SchemaTypes.ReportPlatform.Tableau,
  report_name: 'Report',
  url: '',
  sort_index: 0,
  interaction_config: fallbackReportInteractionConfiguration,
  source_data: fallbackSourceData,
  report_metadata_mapping: fallbackReportMetadataMapping,
};

export const useReportMetadata = (report: Report): ReportMetadata => {
  if (report.report_metadata) {
    return report.report_metadata;
  }

  return fallbackReportMetadata;
};

export const useReportMetadataInteractionConfiguration = (
  report: Report,
): ReportInteractionConfiguration => {
  const reportMetadata = useReportMetadata(report);

  const config: ReportInteractionConfiguration =
    reportMetadata.interaction_config ?? fallbackReportInteractionConfiguration;

  return config;
};

export const useReportMetadataSections = (
  report: Report,
): ReportInteractionSection[] => {
  const config = useReportMetadataInteractionConfiguration(report);

  if (!config.sections) {
    return fallbackInteractionSections;
  }

  const sections = [...config.sections].sort(
    (a, b) => a.sort_index - b.sort_index,
  );

  return sections;
};

export const useReportMetadataControls = (
  section: ReportInteractionSection,
): ReportControl[] | BaselineReportControl[] => {
  if (!section.controls) {
    return [];
  }

  const controls = [...section.controls].sort(
    (a, b) => a.sort_index - b.sort_index,
  );

  return controls;
};

export const useReportMetadataStaticData = (
  control: ReportControl,
): ReportControlStaticListItem[] => {
  if (!control.static_data) {
    return [];
  }

  return control.static_data;
};
