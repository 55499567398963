import {
  BaselineReportControlWithoutTypename,
  BaselineInteractionSection,
  ReportControlWithoutTypename,
  ReportFiltersInteractionSection,
} from '../../types';
import { flattenControlsInSections } from './flattenControlsInSections';
import { overrideControlSortOrderForFilterSections } from './overrideControlSortOrderForFilterSections';

export const getFilterControls = <
  T extends ReportFiltersInteractionSection,
  U extends T extends BaselineInteractionSection
    ? BaselineReportControlWithoutTypename
    : ReportControlWithoutTypename,
>(
  sections?: T[],
): U[] => {
  if (!sections || sections.length === 0) {
    return [];
  }

  const sortedSections = overrideControlSortOrderForFilterSections(sections);

  return flattenControlsInSections<T, U>(sortedSections).sort(
    (a, b) => a.sort_index - b.sort_index,
  );
};
