import {
  InsightsAlert,
  InsightsAlertBaseMarketBenchmarkTemplateData,
} from '@apollo-red/hooks';

/**
 * Prepares value for 'p_tenure' filter.
 *
 * @param alert alert document
 */
export const getTenureKeyFilterValues = (alert: InsightsAlert) => {
  const templateData =
    alert.template_data as InsightsAlertBaseMarketBenchmarkTemplateData;
  return templateData.tenure_key ? [templateData.tenure_key] : [];
};
