import { utils } from '@jll-labs/azara-ui-components';
import { parseFilter } from '@utils/tableau';
import {
  ConcreteFilter,
  FilterUpdateType,
  FlattenedFilter,
  Viz,
} from '@components/tableau/types';
import { getApplicableWorksheets } from './getApplicableWorksheets';
import { useGetWorksheets } from './useGetWorksheets';

const { useToggleValue } = utils;

export const parseAndFlattenFilters = (
  filters: ConcreteFilter[],
): FlattenedFilter[] => {
  return filters.map(parseFilter);
};

export const useApplyHierarchicalFilterAsync = (viz?: Viz) => {
  const [loading, toggleLoading] = useToggleValue(false);
  const { worksheets } = useGetWorksheets(viz);

  const applyHierarchicalFilterAsync = async (
    name,
    values: {},
    filterType: FilterUpdateType,
  ) => {
    if (!viz) {
      return;
    }

    try {
      toggleLoading();

      const applicableWorksheets = await getApplicableWorksheets(
        worksheets,
        name,
      );
      const applyFiltersPromises = applicableWorksheets?.map(ws =>
        ws.applyHierarchicalFilterAsync(name, values, filterType),
      );

      if (applyFiltersPromises) {
        await Promise.all(applyFiltersPromises);
      }
    } finally {
      toggleLoading();
    }
  };

  return { loading, applyHierarchicalFilterAsync };
};
