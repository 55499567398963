import { gql } from '@apollo/client';
import {
  TenantCustomReportsSettingsBase,
  TenantUserBase,
  TenantExtended,
  TenantAlertSettingsBase,
  TenantBase,
  PostMappingDataStandardResponseBase,
  TenantConfigurationDataBase,
  ReportBase,
} from '@apollo-red/fragments';

export const ADD_TENANT = gql`
  mutation addTenant($data: TenantCreateInput!) {
    addTenant(data: $data) {
      id
    }
  }
`;

export const DELETE_TENANT = gql`
  mutation deleteTenant($where: WhereTenantOvcIdInput!) {
    deleteTenant(where: $where) {
      id
      tenant_status
    }
  }
`;

/* eslint-disable graphql/template-strings */

export const SYNC_TENANT_UNPUBLISHED_REPORTS = gql`
  mutation syncTenantUnpublishedReports(
    $where: TenantWhereInput!
    $report_filters: TenantReportsFiltersInput
  ) {
    syncTenantUnpublishedReports(where: $where) {
      id
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${TenantCustomReportsSettingsBase.fragment}
`;
/* eslint-disable graphql/template-strings */

export const ADD_TENANT_ALERT_SETTINGS = gql`
  mutation addTenantAlertSettings(
    $data: TenantAlertSettingsInput!
    $where: TenantWhereInput!
  ) {
    addTenantAlertSettings(data: $data, where: $where) {
      id
      tenant_alert_settings {
        ...WorkOrderVolumeAlertSettingsBase
        ...FacilitiesExperienceAlertSettingsBase
        ...LeaseEventAlertSettingsBase
        ...SubleaseRiskAlertSettingsBase
        ...SameDayWorkOrderCompletionAlertSettingsBase
      }
    }
  }
  ${TenantAlertSettingsBase.workOrderVolumeFragment}
  ${TenantAlertSettingsBase.facilitiesExperienceFragment}
  ${TenantAlertSettingsBase.leaseEventFragment}
  ${TenantAlertSettingsBase.subleaseRiskFragment}
  ${TenantAlertSettingsBase.sameDayWorkOrderCompletionFragment}
`;

export const ADD_TENANT_CONFIGURATION_DATA = gql`
  mutation addTenantConfigurationData(
    $data: TenantConfigurationDataInput!
    $where: TenantWhereInput!
  ) {
    addTenantConfigurationData(data: $data, where: $where) {
      id
      tenant_configuration_data {
        ...TenantConfigurationDataBase
      }
    }
  }
  ${TenantConfigurationDataBase.fragment}
`;

/* eslint-disable graphql/template-strings */
export const UPDATE_TENANT = gql`
  mutation updateTenant(
    $data: TenantUpdateInput!
    $where: TenantWhereInput!
    $report_filters: TenantReportsFiltersInput
  ) {
    updateTenant(data: $data, where: $where) {
      ...TenantExtended
    }
  }
  ${TenantExtended.fragment}
`;
/* eslint-enable graphql/template-strings */

export const UPDATE_TENANT_REPORTS = gql`
  mutation updateTenantReports(
    $where: TenantWhereInput!
    $data: UpdateTenantReportsInput!
    $report_filters: TenantReportsFiltersInput
  ) {
    updateTenantReports(where: $where, data: $data) {
      id
      tenant_reports(filters: $report_filters) {
        ...ReportBase
      }
      tenant_external_reports(filters: $report_filters) {
        ...ReportBase
      }
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${ReportBase.fragment}
  ${TenantCustomReportsSettingsBase.fragment}
`;

export const UPDATE_PRODUCT_SETTINGS_REPORT = gql`
  mutation updateProductSettingsReport(
    $where: ReportWhereInput!
    $data: ProductSettingsReportInput!
  ) {
    updateProductSettingsReport(where: $where, data: $data) {
      ...ReportBase
    }
  }
  ${ReportBase.fragment}
`;

/* eslint-disable graphql/template-strings */
export const UPDATE_TENANT_CUSTOM_REPORTS_SETTINGS = gql`
  mutation updateTenantCustomReports(
    $data: TenantUpdateInput!
    $where: TenantWhereInput!
    $report_filters: TenantReportsFiltersInput
  ) {
    updateTenant(data: $data, where: $where) {
      id
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${TenantCustomReportsSettingsBase.fragment}
`;
/* eslint-disable graphql/template-strings */

export const SYNC_TENANTS = gql`
  mutation syncTenants($where: TenantsWhereUpdateInput!) {
    syncTenants(where: $where) {
      id
    }
  }
`;

export const UPDATE_TENANT_USER = gql`
  mutation updateTenantUser($data: UpdateTenantUserInput!, $tenant_id: ID!) {
    updateTenantUser(data: $data, where: { id: $tenant_id }) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const ADD_TENANT_USERS = gql`
  mutation addTenantUsers($data: [CreateTenantUserInput!]!, $tenant_id: ID!) {
    addTenantUsers(data: $data, where: { id: $tenant_id }) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const PROVISION_TENANT = gql`
  mutation provisionTenant($where: TenantWhereInput!) {
    provisionTenant(where: $where) {
      ...TenantBase
    }
  }
  ${TenantBase.fragment}
`;

export const DELETE_USER_FROM_TENANT = gql`
  mutation deleteUserFromTenant(
    $where: WhereDeleteTenantUserInput!
    $tenant_id: ID!
  ) {
    deleteUserFromTenant(tenant_id: $tenant_id, where: $where) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const POST_MAPPING_DATA_STANDARD = gql`
  mutation postMappingDataStandard(
    $where: WhereTenantOvcIdInput!
    $data: [PostMappingDataStandardInput!]!
  ) {
    postMappingDataStandard(where: $where, data: $data) {
      ...PostMappingDataStandardResponseBase
    }
  }
  ${PostMappingDataStandardResponseBase.fragment}
`;

export const REACTIVATE_TENANT_USER = gql`
  mutation reactivateTenantUser($tenant_id: ID!, $userId: ID!) {
    reactivateTenantUser(tenant_id: $tenant_id, user_id: $userId) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const RESET_TENANT_USER_PASSWORD = gql`
  mutation resetTenantUserPassword($tenant_id: ID!, $userId: ID!) {
    resetTenantUserPassword(tenant_id: $tenant_id, user_id: $userId) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const UNLOCK_TENANT_USER = gql`
  mutation unlockTenantUser($tenant_id: ID!, $userId: ID!) {
    unlockTenantUser(tenant_id: $tenant_id, user_id: $userId) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const UNSUSPEND_TENANT_USER = gql`
  mutation unsuspendTenantUser($tenant_id: ID!, $userId: ID!) {
    unsuspendTenantUser(tenant_id: $tenant_id, user_id: $userId) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const REFRESH_TENANT_USERS = gql`
  mutation refreshTenantUsers($tenant_id: ID!) {
    refreshTenantUsers(where: { id: $tenant_id }) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const UPDATE_TENANT_KPI_SETTINGS = gql`
  mutation updateTenantKpiSettings(
    $where: TenantWhereInput!
    $data: [TenantKpiSettingsInput!]!
  ) {
    updateTenantKpiSettings(where: $where, data: $data) {
      ...TenantBase
    }
  }
  ${TenantBase.fragment}
`;

export const DEACTIVATE_USER_FROM_TENANT = gql`
  mutation deactivateUserFromTenant(
    $where: WhereDeactivateUserFromTenantInput!
    $tenant_id: ID!
  ) {
    deactivateUserFromTenant(tenant_id: $tenant_id, where: $where) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

export const REACTIVATE_USER_TO_TENANT = gql`
  mutation reactivateUserToTenant(
    $where: WhereReactivateUserToTenantInput!
    $tenant_id: ID!
  ) {
    reactivateUserToTenant(tenant_id: $tenant_id, where: $where) {
      ...TenantUserBase
    }
  }
  ${TenantUserBase.fragment}
`;

/* eslint-disable graphql/template-strings */
export const UPSERT_TENANT_SELF_SERVE_ANALYTICS_SETTINGS = gql`
  mutation upsertTenantSelfServeAnalyticsSettings(
    $where: TenantWhereInput!
    $data: UpsertTenantSelfServeAnalyticsSettings!
    $report_filters: TenantReportsFiltersInput
  ) {
    upsertTenantSelfServeAnalyticsSettings(where: $where, data: $data) {
      id
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${TenantCustomReportsSettingsBase.fragment}
`;
/* eslint-disable graphql/template-strings */

/* eslint-disable graphql/template-strings */
export const ADD_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS = gql`
  mutation addTenantDataShareForCustomReports(
    $where: TenantWhereInput!
    $data: AddTenantDataShareForCustomReports!
    $report_filters: TenantReportsFiltersInput
  ) {
    addTenantDataShareForCustomReports(where: $where, data: $data) {
      id
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${TenantCustomReportsSettingsBase.fragment}
`;
/* eslint-disable graphql/template-strings */

/* eslint-disable graphql/template-strings */
export const UPDATE_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS = gql`
  mutation updateTenantDataShareForCustomReports(
    $where: TenantWhereInput!
    $data: UpdateTenantDataShareForCustomReports!
    $report_filters: TenantReportsFiltersInput
  ) {
    updateTenantDataShareForCustomReports(where: $where, data: $data) {
      id
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${TenantCustomReportsSettingsBase.fragment}
`;
/* eslint-disable graphql/template-strings */

/* eslint-disable graphql/template-strings */
export const DELETE_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS = gql`
  mutation deleteTenantDataShareForCustomReports(
    $where: TenantWhereInput!
    $data: DeleteTenantDataShareForCustomReports!
    $report_filters: TenantReportsFiltersInput
  ) {
    deleteTenantDataShareForCustomReports(where: $where, data: $data) {
      id
      tenant_custom_reports_settings {
        ...TenantCustomReportsSettingsBase
      }
    }
  }
  ${TenantCustomReportsSettingsBase.fragment}
`;
/* eslint-disable graphql/template-strings */
