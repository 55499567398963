import { RoleBase } from '@apollo-red/fragments';
import { gql } from '@apollo/client';

export const UPSERT_TENANT_ROLE = gql`
  mutation upsertTenantRole($data: RoleInput!, $where: WhereTenantRoleInput!) {
    upsertTenantRole(data: $data, where: $where) {
      id
      tenant_client_defined_roles {
        ...RoleBase
      }
    }
  }
  ${RoleBase.fragment}
`;

export const DELETE_TENANT_ROLE = gql`
  mutation deleteTenantRole($where: WhereTenantRoleInput!) {
    deleteTenantRole(where: $where) {
      id
      tenant_client_defined_roles {
        ...RoleBase
      }
    }
  }
  ${RoleBase.fragment}
`;
