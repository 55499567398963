import * as React from 'react';
import { ReportExtended } from '@apollo-red/hooks';
import { Viz } from '@components/tableau/types';
import { Toolbar } from '../fullReport';

interface ExternalFullReportToolbarProps {
  report: ReportExtended;
  viz?: Viz;
}

const ExternalFullReportToolbar: React.FC<ExternalFullReportToolbarProps> = ({
  report,
  viz,
}) => (
  <Toolbar
    viz={viz}
    report={report}
    enabledSettings={{
      help: false,
      save: false,
      load: false,
      csv: true,
      image: false,
      pdf: true,
      share: true,
      favorites: true,
      revert: true,
    }}
  />
);

export default ExternalFullReportToolbar;
