import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppMessage, changesSavedMsg } from '@components/messages';
import RoleForm, { useWorkRolesForm, FormValues } from '@components/workRoles';
import PreferredContactMethod from './PreferredContactMethod';
import AccessInfo from './AccessInfo';
import PersonalDetails from './PersonalDetails';
import SettingsSection from '../SettingsSection';
import { Form, FormButtons } from '@jll-labs/azara-ui-components';

interface Props {
  disabled?: boolean;
}

const AccountForm: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    initialValues,
    workAreas,
    loadingWorkAreas,
    onSubmit: onWorkRolesSubmit,
  } = useWorkRolesForm();
  const { setAppMessage } = useAppMessage();

  const onSubmit = async (values: FormValues) => {
    await onWorkRolesSubmit(values);
    setAppMessage(changesSavedMsg());
  };

  return (
    <div>
      <SettingsSection title={t('info')}>
        <PersonalDetails disabled={disabled} />
      </SettingsSection>
      <SettingsSection title={t('workArea')}>
        <Form
          key={String(loadingWorkAreas)}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {props => {
            return (
              <>
                <RoleForm
                  {...props}
                  workAreas={workAreas}
                  loadingWorkAreas={loadingWorkAreas}
                  disabled={disabled}
                />
                <FormButtons disabled={disabled} />
              </>
            );
          }}
        </Form>
      </SettingsSection>
      <SettingsSection title={t('preferredContactMethod')}>
        <PreferredContactMethod disabled={disabled} />
      </SettingsSection>
      <SettingsSection title={t('accessInfo')}>
        <AccessInfo />
      </SettingsSection>
    </div>
  );
};

export default AccountForm;
