import { useGetThoughtSpotTrustedTicket } from '@apollo-red/hooks';
import * as React from 'react';

export const useGetThoughtSpotAuthToken = (userId?: string) => {
  const { getThoughtSpotTrustedTicket } = useGetThoughtSpotTrustedTicket();
  const [error, setError] = React.useState(false);
  const [authToken, setAuthToken] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!userId || authToken) {
      return;
    }

    const getAuthToken = async () => {
      try {
        const token = await getThoughtSpotTrustedTicket();

        setAuthToken(token);
        setError(false);
      } catch (error) {
        setError(true);
      }
    };

    getAuthToken();
  }, [authToken, getThoughtSpotTrustedTicket, userId]);

  return {
    authToken,
    error,
  };
};
