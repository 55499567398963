import * as React from 'react';
import { SignoutOptions } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import {
  useGetUserInfo,
  useGetUserDefaultTenantId,
  useLogoutUser,
} from '@apollo-red/hooks';
import { useDocumentationLogout } from '@pages/documentation';
import { usePendoTrackEvent } from '@utils/pendo';
import { useSessionStorage } from '@utils/webAPI';
import useAuth from './useAuth';

const useLogout = () => {
  const { user } = useGetUserInfo();
  const { userEmail } = useAuth();
  const tenantId = useGetUserDefaultTenantId();
  const { oktaAuth } = useOktaAuth();
  const { logout: docs } = useDocumentationLogout();
  const { logoutUser, loading } = useLogoutUser();
  const { trackEvent } = usePendoTrackEvent();
  const [_, __, clear] = useSessionStorage('logout', {});
  const userId = user.id ?? '';
  const email = userEmail ?? '';

  const logout = React.useCallback(
    async (signOutOptions?: SignoutOptions) => {
      await docs();
      await logoutUser({ userId, tenantId });
      clear();

      trackEvent('userLogout', {
        userEmail: email || 'N/A',
        clientId: tenantId,
      });

      oktaAuth.signOut(signOutOptions);
    },
    [clear, docs, email, logoutUser, oktaAuth, tenantId, trackEvent, userId],
  );

  return {
    logout,
    loggingOut: loading,
  };
};

export default useLogout;
