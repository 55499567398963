import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { SEARCH_USERS } from '@apollo-red/queries';
import { useAppLazyQuery } from '../../queries';

export const useSearchUsers = () => {
  const [searchUsers, { loading, data, error }] = useAppLazyQuery<
    SchemaTypes.SearchUsersQuery,
    SchemaTypes.SearchUsersQueryVariables
  >(SEARCH_USERS, { fetchPolicy: 'no-cache' });

  return {
    loading,
    error,
    users: data?.searchUsers,
    searchUsers: React.useCallback(
      async (phrase: string) => {
        return searchUsers({
          variables: {
            phrase,
          },
        });
      },
      [searchUsers],
    ),
  };
};
