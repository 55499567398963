import { Worksheet } from '@components/tableau/types';

export const getApplicableWorksheets = async (
  worksheets: Worksheet[],
  filterName: string,
) => {
  if (!worksheets) {
    return [];
  }

  const worksheetFiltersNamesPromises = worksheets.map(ws =>
    ws.getFiltersAsync().then(filters => filters.map(f => f.getFieldName())),
  );

  const worksheetFiltersNames = await Promise.all(
    worksheetFiltersNamesPromises,
  );

  const applicableWorksheets = worksheets.filter((_, i) => {
    const filterNames = worksheetFiltersNames[i];

    return filterNames.includes(filterName);
  });

  return applicableWorksheets;
};
