import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  GET_TENANTS_UNIVERSAL_FILTERS,
  GET_TENANTS_RULE_BUILDER_SETTINGS,
} from '@apollo-red/queries';
import { useAppQuery } from '../queries';
import { useGetUserDefaultTenantId } from '../user';

export type TenantGeoUniversalFilters =
  SchemaTypes.TenantGeoUniversalFiltersBaseFragment;

export type TenantUniversalFilterGeoValue =
  SchemaTypes.TenantUniversalFilterGeoValueBaseFragment;

const fallbackTenantFilters = {
  countries: [],
  states: [],
  cities: [],
  properties: [],
};

const parseTenantUniversalFiltersData = (
  data?: SchemaTypes.GetTenantsUniversalFiltersQuery,
) => {
  const filters = data?.getTenants?.[0]?.tenant_geo_universal_filters;
  return filters || fallbackTenantFilters;
};

export const useGetTenantUniversalFilters = (
  tenantId: string,
  nodes?: SchemaTypes.TenantUniversalFilterNodeInput,
  skip?: boolean,
) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetTenantsUniversalFiltersQuery,
    SchemaTypes.GetTenantsUniversalFiltersQueryVariables
  >(GET_TENANTS_UNIVERSAL_FILTERS, {
    variables: { tenant_ids: [tenantId], nodes },
    skip,
  });

  return {
    loading,
    ...parseTenantUniversalFiltersData(data),
  };
};

export const useGetCurrentTenantUniversalFilters = (
  nodes?: SchemaTypes.TenantUniversalFilterNodeInput,
  skip?: boolean,
) => {
  const defaultTenantId = useGetUserDefaultTenantId();
  return useGetTenantUniversalFilters(defaultTenantId, nodes, skip);
};

const parseTenantAlertCategories = (
  data?: SchemaTypes.GetTenantsRuleBuilderSettingsQuery,
) => {
  const ruleBuilder = data?.getTenants?.[0]?.tenant_alert_rule_builder;
  const categories = ruleBuilder?.categories;
  const initCategories = {
    internal: [],
    external: [],
  };
  if (categories) {
    const { __typename, ...alertCategories } = categories;
    return {
      ...initCategories,
      ...alertCategories,
    };
  }
  return initCategories;
};

export const useGetTenantAlertCategories = (
  tenantId: string,
  alertType: SchemaTypes.TenantAlertSettingsType,
) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetTenantsRuleBuilderSettingsQuery,
    SchemaTypes.GetTenantsRuleBuilderSettingsQueryVariables
  >(GET_TENANTS_RULE_BUILDER_SETTINGS, {
    variables: {
      alert_type: alertType,
      tenant_ids: [tenantId],
    },
  });

  return {
    loading,
    tenantAlertCategories: parseTenantAlertCategories(data),
  };
};
