import * as React from 'react';
import { useFeatureFlag, BooleanFeatureFlagKey } from '@utils/launchDarkly';

interface Props {
  flag: BooleanFeatureFlagKey;
}

const FeatureFlagToggle: React.FC<Props> = ({ flag, children }) => {
  const flagValue = useFeatureFlag(flag);

  return flagValue ? <>{children}</> : null;
};

export default FeatureFlagToggle;
