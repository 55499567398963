import * as React from 'react';
import { Form, FormSubmit } from '@jll-labs/azara-ui-components';
import {
  useGetUserDefaultTenant,
  useGetUserUniversalFiltersForTenant,
  useUpdateUserUniversalFilters,
} from '@apollo-red/hooks';
import UniversalFilters, {
  FormValues,
  useUniversalFiltersForm,
  useUniversalFiltersTrackLocationEvent,
} from '@components/universalFilters';
import { TableauCacheContext } from '@components/tableau/TableauCacheContextProvider';
import StepWrapper, { StepWrapperProps } from './StepWrapper';

const Scope: React.FC<StepWrapperProps> = props => {
  const { clearCache } = React.useContext(TableauCacheContext);
  const { defaultTenant } = useGetUserDefaultTenant();
  const tenantId = defaultTenant?.id ?? '';
  const tenantOvcId = defaultTenant?.tenant_ovc_id ?? '';
  const { updateUserUniversalFilters } = useUpdateUserUniversalFilters();
  const { universalFilters } = useGetUserUniversalFiltersForTenant(
    defaultTenant?.id ?? '',
  );
  const { initialValues, parseSubmit } =
    useUniversalFiltersForm(universalFilters);
  const { trackLocationEvent } = useUniversalFiltersTrackLocationEvent();

  const onSubmit: FormSubmit<FormValues> = async values => {
    trackLocationEvent(initialValues, values);

    await updateUserUniversalFilters(
      tenantId,
      tenantOvcId,
      parseSubmit(values),
    );

    clearCache();
    props.handleNextClick?.(props.activeStep);
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {formProps => {
        return (
          <StepWrapper
            icon="Assignment"
            title="How is your Client scope defined?"
            description={
              <span>
                Select all of your portfolio responsibilities by location and
                the default units. Azara will show you content only for selected
                locations. <br /> <br />
                These selections can be updated in <b>
                  Dashboard Settings
                </b>{' '}
                page.
              </span>
            }
            isValid={formProps.isValid}
            isSubmitting={formProps.isSubmitting}
            stepWrapperProps={{ ...props, handleNextClick: undefined }}
            rightSideHeader="Your Responsibilities Scope by Location"
          >
            <UniversalFilters
              components={{
                countries: true,
                states: true,
                cities: true,
                properties: false,
                unitOfMeasurement: true,
                currency: true,
              }}
              inline={false}
              {...formProps}
            />
          </StepWrapper>
        );
      }}
    </Form>
  );
};

export default React.memo(Scope);
