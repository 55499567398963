export const AZARA_DATA = 'AZARA_DATA';

export const VIZ_PADDING = 8;
export const VIZ_CONTENT_WITHOUT_FLOATING_PANEL = 98;
export const VIZ_CONTENT_WITH_FLOATING_PANEL = 75;
export const FLOATING_CONTENT_FULL_WIDTH = 22;
export const FLOATING_CONTENT_NO_WIDTH = 0;
export const FLOATING_CONTENT_FULL_WIDTH_WITH_SIDEBAR = 23.5;

export const PINNED_QUERY_VALUES_MAX_LENGTH = 1500;
