import * as React from 'react';
import { useEmbedThoughtSpotPinboard } from './useEmbedThoughtSpotPinboard';
interface ThoughtSpotPinboardProps {
  thoughtSpotHost?: string;
  pinboardId?: string;
  visualizationId?: string;
  handleOnLoad?: () => void;
}

const ThoughtSpotPinboard: React.FC<ThoughtSpotPinboardProps> = ({
  thoughtSpotHost,
  pinboardId,
  visualizationId,
  handleOnLoad,
}) => {
  const divEl = React.useRef<HTMLDivElement>(null);
  const { authError, hasData, isAuthExpire, isLoading } =
    useEmbedThoughtSpotPinboard({
      targetRef: divEl,
      thoughtSpotHost,
      pinboardId,
      visualizationId,
      handleOnLoad,
    });

  return (
    <>
      {isLoading && <div></div>}
      {!isLoading && !hasData && <div>No Data Available</div>}
      {!isLoading && isAuthExpire && <div>Auth Expired</div>}
      {!isLoading && authError && <div>Auth Error</div>}
      <div ref={divEl} />
    </>
  );
};

export default ThoughtSpotPinboard;
