import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRoutes } from '@routes';
import { ActiveUserOnly, TenantFeatureToggle } from '@components/featureToggle';
import Landing from './Landing';
import PortscapeWrapper from './PortscapeWrapper';

const Portscape: React.FC = () => {
  const { jllPortscape } = useRoutes();

  return (
    <ActiveUserOnly>
      <PortscapeWrapper>
        <TenantFeatureToggle hasFeatures={['portscape']}>
          <Switch>
            <Route exact={true} path={jllPortscape.home.path}>
              <Landing />
            </Route>
          </Switch>
        </TenantFeatureToggle>
      </PortscapeWrapper>
    </ActiveUserOnly>
  );
};

export default Portscape;
