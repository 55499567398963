import { chain, differenceWith, sortBy } from 'lodash';
import { TenantAlertSettingsType } from '@apollo-red/hooks/tenants';
import { filterItems } from '@utils/various';
import {
  filterNonSpecialReports,
  filterSpecialReports,
  hasReportCategory,
  isNonPATReport,
  isPATReport,
  Report,
  CustomReportStatus,
  LOGIN_DASHBOARD_CATEGORY,
  getKpiDetailsReports,
  KpiDetailsReports,
} from '../../reports';
import { UserCategory } from '../categories';
import { User } from '../user';
import { UserTenantExtended } from '../tenants';
import { InsightsAlertScope } from '../insightsAlert';
import { ReportExtended } from './reports';
import { ReportBaseFragment } from '@apollo-red/schema/generated/operations';

const SENSOR_ANALYSIS = 'SensorAnalysis';
const SENSOR_ANALYSIS_TEXT = 'Sensor Analysis';

export interface AlertScopeReports {
  [InsightsAlertScope.External]?: Report;
  [InsightsAlertScope.Internal]?: Report;
}

export interface AlertReports {
  allAlertReports: ReportExtended[];
  [TenantAlertSettingsType.WorkorderVolume]?: AlertScopeReports;
  [TenantAlertSettingsType.LeaseEvent]?: AlertScopeReports;
  [TenantAlertSettingsType.Covid_19WorkOrder]?: AlertScopeReports;
  [TenantAlertSettingsType.MarketBenchmark]?: AlertScopeReports;
  [TenantAlertSettingsType.FacilitiesExperience]?: AlertScopeReports;
  [TenantAlertSettingsType.OccupancyEfficiency]?: AlertScopeReports;
  [TenantAlertSettingsType.SubleaseRisk]?: AlertScopeReports;
}

export const getUserCustomCategory = (
  report: Report,
  category?: UserCategory,
) => {
  const userCategoryName = category?.category_descriptions?.[0];

  if (userCategoryName) {
    return userCategoryName;
  }

  const categoryDescription =
    report?.report_cat?.[0]?.category_description ?? '';
  return categoryDescription === SENSOR_ANALYSIS
    ? SENSOR_ANALYSIS_TEXT
    : categoryDescription;
};

export const getAuthReport = (allReports: Report[]) => {
  const loginDashboard = allReports.find(report =>
    hasReportCategory(report, LOGIN_DASHBOARD_CATEGORY),
  );
  return loginDashboard || allReports[0];
};

export const getAlertReports = (allReports: Report[]): AlertReports => {
  // This should be changed once more meaningful tags are provided for the workbook.
  const externalWovAlertReport = allReports.find(r =>
    r.report_tableau_url.includes('WOVAlertExternal'),
  );
  const internalWovAlertReport = allReports.find(r =>
    r.report_tableau_url.includes('WOVAlertInternal'),
  );
  const leaseEventAlertReport = allReports.find(r =>
    r.report_tableau_url.includes('LeaseEvents'),
  );
  const leaseCostBenchmarkReport = allReports.find(r =>
    r.report_tableau_url.includes('LeaseCostBenchmark'),
  );
  const occupancyDetailsReport = allReports.find(r =>
    r.report_tableau_url.includes('OccupancyDetails'),
  );
  const subleaseRiskReport = allReports.find(r =>
    r.report_tableau_url.includes('LeaseEvents'),
  );

  const alertReports = [
    externalWovAlertReport,
    internalWovAlertReport,
    leaseEventAlertReport,
    leaseCostBenchmarkReport,
    occupancyDetailsReport,
    subleaseRiskReport,
  ]
    .filter(r => !!r)
    .map(r => r as ReportBaseFragment);

  const allAlertReports = reportsExtended(alertReports, [], [], [], [], [], []);

  return {
    allAlertReports,
    [TenantAlertSettingsType.WorkorderVolume]: {
      [InsightsAlertScope.External]: externalWovAlertReport,
      [InsightsAlertScope.Internal]: internalWovAlertReport,
    },
    [TenantAlertSettingsType.LeaseEvent]: {
      [InsightsAlertScope.Internal]: leaseEventAlertReport,
    },
    [TenantAlertSettingsType.MarketBenchmark]: {
      [InsightsAlertScope.Internal]: leaseCostBenchmarkReport,
    },
    [TenantAlertSettingsType.OccupancyEfficiency]: {
      [InsightsAlertScope.Internal]: occupancyDetailsReport,
    },
    [TenantAlertSettingsType.SubleaseRisk]: {
      [InsightsAlertScope.Internal]: subleaseRiskReport,
    },
  };
};

export const reportsExtended = (
  allReports: Report[],
  favDashboardsIds: string[],
  savedDashboardsIds: string[],
  hiddenDashboardsIds: string[],
  customReportsIds: string[],
  externalReportsIds: string[],
  reportsOrder: string[],
  customCategories?: UserCategory[],
): ReportExtended[] => {
  const orderedReports = chain(allReports)
    .intersectionWith(reportsOrder, (report, order) => report.id === order)
    .sortBy(report => reportsOrder.indexOf(report.id))
    .value();
  const otherReports = differenceWith(
    allReports,
    reportsOrder,
    (report, order) => report.id === order,
  );

  return [...orderedReports, ...otherReports].map(report => {
    const { __typename, ...rest } = report;
    const { id, report_cat, report_name, report_original_name, report_parent } =
      rest;
    const customCategory = (customCategories || []).find(
      cat => cat.report && cat.report.id === id,
    );

    return {
      ...rest,
      report_original_name: report_original_name ?? report_name,
      primaryCategory: getUserCustomCategory(report, customCategory),
      originalCategory: report_cat?.[0]?.category_description ?? '',
      isSavedView: savedDashboardsIds.includes(id),
      isCustomReport:
        customReportsIds.includes(id) ||
        customReportsIds.includes(report_parent || ''),
      isFav: favDashboardsIds.includes(id),
      isHidden: hiddenDashboardsIds.includes(id),
      isAlertReport: false,
      isAuthReport: false,
      isKpiReport: false,
      isPatReport: false,
      isExternalReport: externalReportsIds.includes(id),
    };
  });
};

interface UserReportList {
  allReports: ReportExtended[];
  allSpecialReports: ReportExtended[];
  alertReports: AlertReports;
  authReport?: Report;
  kpiDetailReports?: KpiDetailsReports;
  patReports?: ReportExtended[];
}

const classifyReportExtended = (
  report: ReportExtended,
  alertReports: Report[],
  authReport?: Report,
  kpiDetailReports?: Report[],
  patReports?: ReportExtended[],
): ReportExtended => {
  const { id } = report;

  if (authReport && id === authReport.id) {
    return {
      ...report,
      isAuthReport: true,
    };
  }

  if (alertReports.map(r => r.id).includes(id)) {
    return {
      ...report,
      isAlertReport: true,
    };
  }

  if (kpiDetailReports?.map(r => r.id).includes(id)) {
    return {
      ...report,
      isKpiReport: true,
    };
  }

  if (patReports?.map(r => r.id).includes(id)) {
    return {
      ...report,
      isPatReport: true,
    };
  }

  return report;
};

const withExtendedReportClassification = (
  userReportList: UserReportList,
): UserReportList => {
  const {
    allReports,
    allSpecialReports,
    alertReports,
    authReport,
    kpiDetailReports,
    patReports,
  } = userReportList;

  return {
    allReports,
    allSpecialReports: allSpecialReports.map(r =>
      classifyReportExtended(
        r,
        alertReports.allAlertReports,
        authReport,
        kpiDetailReports?.allKpiDetailsReports,
        patReports,
      ),
    ),
    alertReports: {
      ...alertReports,
      allAlertReports: alertReports.allAlertReports.map(r => ({
        ...r,
        isAlertReport: true,
      })),
    },
    authReport,
    kpiDetailReports,
    patReports: patReports?.map(r => ({
      ...r,
      isPatReport: true,
    })),
  };
};

export const buildUserReportList = (
  user: User,
  userTenant?: UserTenantExtended | null,
): UserReportList => {
  if (!userTenant) {
    return {
      allReports: [],
      allSpecialReports: [],
      alertReports: {
        allAlertReports: [],
        [TenantAlertSettingsType.WorkorderVolume]: {
          [InsightsAlertScope.External]: undefined,
          [InsightsAlertScope.Internal]: undefined,
        },
        [TenantAlertSettingsType.LeaseEvent]: {
          [InsightsAlertScope.Internal]: undefined,
        },
        [TenantAlertSettingsType.MarketBenchmark]: {
          [InsightsAlertScope.Internal]: undefined,
        },
        [TenantAlertSettingsType.OccupancyEfficiency]: {
          [InsightsAlertScope.Internal]: undefined,
        },
        [TenantAlertSettingsType.SubleaseRisk]: {
          [InsightsAlertScope.Internal]: undefined,
        },
      },
      authReport: undefined,
    };
  }

  const userTenantReports = filterItems(userTenant.tenant_reports || []).filter(
    report => isNonPATReport(report),
  );

  const userTenantCustomReports = filterItems(
    userTenant.tenant_custom_reports_settings?.tenant_custom_reports ?? [],
  );

  const publishedCustomReports = userTenantCustomReports.filter(
    r => r.custom_report_status === CustomReportStatus.Published,
  );

  const userPATReports = filterItems(userTenant.tenant_reports || [])
    .filter(report => isPATReport(report))
    .filter(report => report.report_active);

  const userExternalReports = filterItems(userTenant.tenant_external_reports);

  const allTenantReports = filterSpecialReports([
    ...userTenantReports,
    ...publishedCustomReports,
    ...userExternalReports,
    ...filterItems(user.user_saved_reports).filter(
      r => r.report_tenant === userTenant.id,
    ),
  ]).filter(report => report.report_active);

  const allSpecialTenantReports = filterNonSpecialReports([
    ...userTenantReports,
    ...publishedCustomReports,
    ...userExternalReports,
    ...filterItems(user.user_saved_reports).filter(
      r => r.report_tenant === userTenant.id,
    ),
  ]).filter(report => report.report_active);

  return withExtendedReportClassification({
    allReports: reportsExtended(
      allTenantReports,
      filterItems(user.user_fav_reports).map(r => r.id),
      filterItems(user.user_saved_reports).map(r => r.id),
      filterItems(user.user_settings.hidden_reports || []).map(r => r.id),
      publishedCustomReports.map(r => r.id),
      userExternalReports.map(r => r.id),
      user.user_tenant_regular_settings?.find(
        item => item.user_tenant.id === userTenant.id,
      )?.user_reports_order ?? [],
      filterItems(user.user_settings.custom_categories || []),
    ),
    allSpecialReports: reportsExtended(
      allSpecialTenantReports,
      [],
      [],
      [],
      [],
      [],
      [],
    ),
    kpiDetailReports: getKpiDetailsReports(userTenantReports),
    authReport: getAuthReport(userTenantReports),
    alertReports: getAlertReports(userTenantReports),
    patReports: reportsExtended(
      userPATReports,
      filterItems(user.user_fav_reports).map(r => r.id),
      [],
      [],
      [],
      [],
      [],
    ),
  });
};

export const getUserTenantFavReports = (
  user: User,
  tenant?: UserTenantExtended | null,
) => {
  const { allReports } = buildUserReportList(user, tenant);
  return allReports.filter(report => report.isFav);
};

export const sortReportsBasedOnSortIndex = (reports: ReportExtended[]) => {
  return sortBy(reports, [
    function (report) {
      return report?.report_metadata?.sort_index;
    },
  ]);
};
