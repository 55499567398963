import * as React from 'react';
import {
  QaEnvironmentIdentifier,
  TenantQaType,
  useGetEnvironmentType,
} from '@apollo-red/hooks';

type QaEnvironment = Exclude<QaEnvironmentIdentifier, ''>;

type PendoEnvironmentPrefix = `${QaEnvironment}_` | '';

const environmentPrefixes: Record<TenantQaType, PendoEnvironmentPrefix> = {
  [TenantQaType.Demo]: 'demo_',
  [TenantQaType.Nonwd]: 'app_',
  [TenantQaType.Wdlive]: '',
  [TenantQaType.Wdqa]: 'qa_',
};

export const usePendoEnvironmentPrefix = () => {
  const { environmentType } = useGetEnvironmentType();

  return React.useMemo(() => {
    const qaEnv = environmentType ?? TenantQaType.Wdlive;

    return environmentPrefixes[qaEnv];
  }, [environmentType]);
};
