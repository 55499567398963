import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@jll-labs/azara-ui-components';
import {
  isTableauHostedReport,
  isExternalTableauHostedReport,
} from '@utils/tableau';
import { useFeatureFlag } from '@utils/launchDarkly';
import { useTheme } from '@mui/material';
import { getReportUrl, shouldOpenReportInNewTab } from '@utils/report';
import { ReportExtended } from '@apollo-red/hooks';
import { DashboardRouteProps } from '@pages/dashboards/types';
import OpenInNewTabWithTrustedAuth from './OpenInNewTabWithTrustedAuth';
import OpenInNewTabWithTrustedAuthExternalReport from './OpenInNewTabWithTrustedAuthExternalReport';

interface StyleProps {
  isTableRow?: boolean;
}

const useStyles = ({ isTableRow }: StyleProps) => {
  const theme = useTheme();
  return {
    link: {
      textDecoration: 'none',
    },
    button: {
      minWidth: 0,
      color: isTableRow
        ? theme.palette.primary.main
        : theme.palette.text.secondary,
      textTransform: isTableRow ? 'none' : 'uppercase',
    },
  };
};

interface Props {
  report: ReportExtended;
  dashboardRoutes: DashboardRouteProps;
  categoryId: string;
  label: string;
  disabled?: boolean;
  isTableRow?: boolean;
}

const ExternalButtonLink: React.FunctionComponent<Props> = ({
  report,
  dashboardRoutes,
  categoryId,
  label,
  disabled,
  isTableRow = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const styles = useStyles({ isTableRow });
  const enableEmbeddingExternalReport = useFeatureFlag(
    'clientSettingsCustomReportsExternalReportsEmbed',
  );
  const externalUrl = report.report_external_url ?? '';
  const shouldGetTrustedTicket =
    isTableauHostedReport(externalUrl) ||
    isExternalTableauHostedReport(externalUrl);
  const url = getReportUrl(
    report,
    dashboardRoutes,
    categoryId,
    enableEmbeddingExternalReport,
  );
  const shouldOpenInNewTab = shouldOpenReportInNewTab(
    report,
    enableEmbeddingExternalReport,
  );

  const handleOpen = (val: boolean) => {
    setOpen(val);
  };

  const handleOpenClick = () => {
    setOpen(true);
  };

  if (disabled) {
    return <Button disabled={disabled}>{label}</Button>;
  }

  if (!shouldOpenInNewTab) {
    return (
      <Link to={url} style={styles.link}>
        <Button color="primary" sx={styles.button} variant="text">
          {label}
        </Button>
      </Link>
    );
  }

  if (!shouldGetTrustedTicket) {
    return (
      <Link to={{ pathname: url }} style={styles.link} target="_blank">
        <Button color="primary" sx={styles.button} variant="text">
          {label}
        </Button>
      </Link>
    );
  }

  const OpenInNewTab = isExternalTableauHostedReport(url)
    ? OpenInNewTabWithTrustedAuthExternalReport
    : OpenInNewTabWithTrustedAuth;

  return (
    <>
      {open && (
        <OpenInNewTab handleOpen={handleOpen} reportId={report.id} url={url} />
      )}
      <Button
        color="primary"
        sx={styles.button}
        variant="text"
        onClick={handleOpenClick}
      >
        {label}
      </Button>
    </>
  );
};

export default ExternalButtonLink;
