import { useParams } from 'react-router';

/**
 * Wrapper around `react-router`'s `useParams`. Use it to decode url params created by `getEncodedLink`.
 */
export const useParamsCustom = <Params>() => {
  const all = useParams<Params>();
  return Object.entries(all).reduce(
    (prev, [key, val]) => ({
      ...prev,
      [key]: val ? decodeURIComponent(val as string) : val,
    }),
    all,
  );
};

/**
 * Encodes and concatenantes url params except for the first one.
 * This is to ensure that all parameters get encoded correctly, e.g. those from user input ('/').
 *
 * Example:
 * Natively, in `react-router`, a parameter such as 'Facilities / Properties' would be recognized as two separate params: 'Facilities ' and ' Properties'.
 * Therefore, '/' has to be encoded.
 *
 * @param args array of url parameters
 */
export const getEncodedLink = (args: string[]) =>
  args.reduce((prev, curr) => prev.concat(...['/', encodeURIComponent(curr)]));
