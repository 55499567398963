import * as React from 'react';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { useTheme } from '@mui/material';

interface Props {
  count: number;
}

const useStyles = () => {
  const theme = useTheme();

  return {
    badge: {
      [`& .${badgeClasses['badge']}`]: {
        right: theme.spacing(-1.25),
        top: theme.spacing(1),
        color: theme.palette.action.active,
        width: theme.spacing(3),
        display: 'inline-block',
      },
    },
    emptyBadge: {
      [`& .${badgeClasses['badge']}`]: {
        right: theme.spacing(-1),
        top: theme.spacing(1),
        color: theme.palette.action.active,
        width: theme.spacing(1),
        display: 'inline-block',
      },
    },
  };
};

const AlertBadge: React.FC<Props> = ({ count, children }) => {
  const styles = useStyles();

  return (
    <Badge
      badgeContent={count > 1 ? `+${count}` : ''}
      sx={count > 1 ? styles.badge : styles.emptyBadge}
      max={500}
    >
      {children}
    </Badge>
  );
};

export default AlertBadge;
