import { useAppQuery } from '@apollo-red/hooks/queries';
import {
  GET_TENANT_PROPERTY_DATA,
  GET_TENANT_PROPERTY_DATA_FILTER_VALUES,
} from '@apollo-red/queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { NetworkStatus } from '@apollo/client';
import { WithoutTypename } from '@utils/various';

export type PropertyDataRecord =
  WithoutTypename<SchemaTypes.PropertyDataRecord>;
export type PropertyData = WithoutTypename<SchemaTypes.PropertyData>;
export import PropertyDataFilter = SchemaTypes.PropertyDataFilter;

const useGetPropertyDataFilterValues = (
  tenantId: string,
  filter: PropertyDataFilter,
) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantPropertyDataFilterValuesQuery,
    SchemaTypes.GetTenantPropertyDataFilterValuesQueryVariables
  >(GET_TENANT_PROPERTY_DATA_FILTER_VALUES, {
    variables: { filter: filter, tenant_id: tenantId },
  });

  return {
    filterValues: data?.getTenants[0]?.tenant_property_data_filter_values ?? [],
    loading,
  };
};

export const useGetPropertyDataFiltersValues = (tenantId: string) => {
  const { filterValues: cityFilterValues, loading: cityFilterValuesLoading } =
    useGetPropertyDataFilterValues(tenantId, PropertyDataFilter.City);
  const {
    filterValues: countryFilterValues,
    loading: countryFilterValuesLoading,
  } = useGetPropertyDataFilterValues(tenantId, PropertyDataFilter.Country);
  const {
    filterValues: propertyIdFilterValues,
    loading: propertyIdFilterValuesLoading,
  } = useGetPropertyDataFilterValues(tenantId, PropertyDataFilter.PropertyId);
  const {
    filterValues: propertyNameFilterValues,
    loading: propertyNameFilterValuesLoading,
  } = useGetPropertyDataFilterValues(tenantId, PropertyDataFilter.PropertyName);
  const {
    filterValues: propertyTypeFilterValues,
    loading: propertyTypeFilterValuesLoading,
  } = useGetPropertyDataFilterValues(tenantId, PropertyDataFilter.PropertyType);

  return {
    cityFilterValues,
    cityFilterValuesLoading,
    countryFilterValues,
    countryFilterValuesLoading,
    propertyIdFilterValues,
    propertyIdFilterValuesLoading,
    propertyNameFilterValues,
    propertyNameFilterValuesLoading,
    propertyTypeFilterValues,
    propertyTypeFilterValuesLoading,
  };
};
interface PropertyDataArgs {
  tenantId: string;
  pageNumber?: number;
  city?: string[];
  country?: string[];
  propertyName?: string[];
  propertyType?: string[];
  propertyId?: string[];
}

export type PropertyDataFilterArgs = Omit<
  PropertyDataArgs,
  'tenantId' | 'pageNumber'
>;

export const useGetPropertyData = ({
  tenantId,
  country,
  city,
  pageNumber,
  propertyId,
  propertyName,
  propertyType,
}: PropertyDataArgs) => {
  const { loading, data, fetchMore, networkStatus, variables } = useAppQuery<
    SchemaTypes.GetTenantPropertyDataQuery,
    SchemaTypes.GetTenantPropertyDataQueryVariables
  >(GET_TENANT_PROPERTY_DATA, {
    variables: {
      tenant_id: tenantId,
      filter: {
        city,
        country,
        page_number: pageNumber,
        property_id: propertyId,
        property_name: propertyName,
        property_type: propertyType,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const propertyData = data?.getTenants[0]?.tenant_property_data;

  const hasNextPage =
    propertyData?.next_page !== null &&
    propertyData?.next_page !== undefined &&
    propertyData.next_page > -1;
  const nextPageNumber =
    propertyData?.next_page !== undefined &&
    propertyData.next_page !== null &&
    hasNextPage
      ? propertyData.next_page
      : undefined;

  const loadMore = () => {
    if (nextPageNumber) {
      fetchMore({
        variables: {
          ...variables,
          filter: {
            ...variables?.filter,
            page_number: nextPageNumber,
          },
        },
      });
    }
  };

  return {
    loading,
    propertyData,
    loadMore,
    hasNextPage,
    hasPropertyData: (propertyData?.records?.length ?? 0) > 0,
    isLoadingMore: networkStatus === NetworkStatus.fetchMore,
  };
};
