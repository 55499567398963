import * as React from 'react';
import {
  useGetUserCurrentTenantReports,
  sortReportsBasedOnSortIndex,
} from '@apollo-red/hooks';
import { BaseDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';
import { useTranslation } from 'react-i18next';

const useSaveHandler = () => {
  return {
    saveHandler: () => {},
  };
};

const AllDashboards: React.FC = () => {
  const routes = useRoutes();
  const { patReports } = useGetUserCurrentTenantReports();
  const { t } = useTranslation();

  if (!patReports) {
    return null;
  }

  const sortedReports = sortReportsBasedOnSortIndex(patReports);

  return (
    <BaseDashboards
      isPATDashboard
      title={t('allPATDashboards')}
      reports={sortedReports}
      useSaveHandler={useSaveHandler}
      showKeyMetrics={false}
      drag={false}
      dashboardRoutes={routes.PATDashboards}
      categoryId="all"
      breadcrumbsParts={[{ label: t('allPATDashboards') }]}
      breadcrumbsTitle={t('allPATDashboards')}
      autoOverflow={true}
    />
  );
};

export default AllDashboards;
