import * as React from 'react';
import { useGetUserSavedReports } from '@apollo-red/hooks';
import { BaseSavedDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';

const useSaveHandler = () => {
  return {
    saveHandler: () => {},
  };
};

const SavedDashboards: React.FC = () => {
  const routes = useRoutes();
  const { savedReports } = useGetUserSavedReports();

  return (
    <BaseSavedDashboards
      reports={savedReports}
      useSaveHandler={useSaveHandler}
      drag={true}
      dashboardRoutes={routes.dashboards}
    />
  );
};

export default SavedDashboards;
