import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_PHONE } from '@apollo-red/mutations';
import { useGetUserInfo } from '../../user';

export const useUpdateUserCommPhone = () => {
  const { user } = useGetUserInfo();
  const [updateUserPhone] = useAppMutation(UPDATE_USER_PHONE);

  return {
    updateUserPhone: phone => {
      const data = {
        variables: {
          data: {
            user_comm: {
              work: phone,
            },
          },
          where: {
            id: user.id,
          },
        },
      };

      return updateUserPhone(data);
    },
  };
};
