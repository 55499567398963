import { usePendoTrackEvent } from '@utils/pendo';
import { PendoTrackEventType, PendoTrackEventMetadata } from './types';

export const usePendoTrackLocationFilters = () => {
  const { trackEvent } = usePendoTrackEvent();
  const trackLocationFiltersEvent = <
    T extends Extract<
      PendoTrackEventType,
      | 'locationFiltersSelected'
      | 'locationFiltersUnselected'
      | 'locationFiltersEdited'
    >,
  >(
    trackEventType: T,
    trackEventMetadata: PendoTrackEventMetadata<T>,
  ) => {
    trackEvent(trackEventType, trackEventMetadata);
  };

  return { trackLocationFiltersEvent };
};
