import * as React from 'react';
import {
  ReportControlStaticListItem,
  ReportControlType,
} from '@apollo-red/hooks';
import {
  ParameterAllowableValuesType,
  ParameterValueType,
} from '@components/tableau/types';
import { ParameterExtended, PinParameter } from '../../types';
import { PercentStep, SingleSelect } from '../../controls';
import { useFullReportWithFilters } from '../../FullReportWithFiltersProvider';
import {
  getPinnedParameters,
  updatePinnedParameters,
  getInitialParameterState,
  getUpdatedParameters,
  parseNumberValue,
} from '../../utils';
import OptionBox from '../OptionBox';

interface Props {
  parameter: ParameterExtended;
  loading: boolean;
  changeParameter: (name: string, value: ParameterValueType) => void;
}

const Parameter: React.FC<Props> = ({
  parameter,
  loading,
  changeParameter,
}) => {
  const {
    isCoreDashboard,
    pinnedData,
    updatePinnedData,
    addAppliedControls,
    id,
  } = useFullReportWithFilters();
  const pinnedParameters = getPinnedParameters(pinnedData, isCoreDashboard, id);
  const initialParameter = isCoreDashboard
    ? pinnedParameters?.find(f => f.filter_id === parameter.filter_id)
    : pinnedParameters?.find(f => f.name === parameter.name);
  const [parameterState, setParameterState] = React.useState<PinParameter>(
    getInitialParameterState(parameter, initialParameter),
  );
  const isRangeValue =
    parameter.allowableType === ParameterAllowableValuesType.RANGE;

  const updatedPinnedData = React.useCallback(() => {
    const updatedParameters = getUpdatedParameters(
      parameterState,
      pinnedParameters,
    );
    const updatedReportFilterData = updatePinnedParameters(
      pinnedData,
      updatedParameters,
      isCoreDashboard,
      id,
    );

    updatePinnedData(updatedReportFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterState]);

  React.useEffect(() => {
    if (parameterState.isPinned || initialParameter?.isPinned) {
      updatedPinnedData();
    }
  }, [initialParameter?.isPinned, parameterState, updatedPinnedData]);

  const handlePinUnpin = () => {
    const defaultValue = parameter.selectedValue.formattedValue;
    const nextState: PinParameter = {
      ...parameterState,
      isPinned: !parameterState.isPinned,
      selectedOption: !isRangeValue
        ? parameterState.selectedOption
          ? parameterState.selectedOption
          : {
              label: defaultValue,
              value: defaultValue,
            }
        : null,
      selectedValue: isRangeValue
        ? parameterState.selectedValue
          ? parameterState.selectedValue
          : parameter.selectedValue.value
        : '',
    };

    setParameterState(nextState);
  };

  const updateParameter = (values: ReportControlStaticListItem[]) => {
    const [selectedOption] = values;
    const nextState: PinParameter = {
      ...parameterState,
      selectedOption,
    };

    setParameterState(nextState);
    addAppliedControls([parameter.name]);
    changeParameter(parameter.name, selectedOption?.value || '');
  };

  const updatePercentageValue = (value: number) => {
    const parsedValue = (value / 100).toString();
    const nextState: PinParameter = {
      ...parameterState,
      selectedValue: parsedValue,
    };

    setParameterState(nextState);
    addAppliedControls([parameter.name]);
    changeParameter(parameter.name, parsedValue);
  };

  const options = parameter.options.map(op => ({
    label: op.formattedValue,
    value: op.formattedValue,
  }));

  const parameterControlComponent = () => {
    if (
      parameter.allowableType === ParameterAllowableValuesType.LIST ||
      parameter.allowableType === ParameterAllowableValuesType.ALL
    ) {
      const initialValues = parameterState.selectedOption || {
        label: parameter.selectedValue.formattedValue,
        value: parameter.selectedValue.formattedValue,
      };

      if (parameter.control_type === ReportControlType.SingleSelectList) {
        return (
          <SingleSelect
            label={parameter.label}
            loading={loading}
            updateValues={updateParameter}
            options={options}
            initialValue={initialValues}
          />
        );
      } else if (
        parameter.control_type === ReportControlType.SingleSelectListDef
      ) {
        const selected = parameterState.selectedOption;
        const listOptions = options.map((option, index) => ({
          ...option,
          selected: selected ? selected.value === option.value : index === 0,
        }));

        return (
          <SingleSelect
            label={parameter.label}
            loading={loading}
            updateValues={updateParameter}
            options={listOptions}
            initialValue={initialValues}
          />
        );
      }
    } else if (isRangeValue) {
      if (parameter.control_type === ReportControlType.PercentStep) {
        const initialValue = parameterState.selectedValue;
        const parseInitialValue =
          parseNumberValue(initialValue, parameter.dataType) * 100;
        const stepSize = parameter.stepSize ? parameter.stepSize * 100 : 5;

        return (
          <PercentStep
            loading={loading}
            updateValue={updatePercentageValue}
            initialValue={parseInitialValue}
            step={stepSize}
          />
        );
      }
    }
  };

  return (
    <OptionBox
      name={parameter.label}
      pinned={parameterState.isPinned}
      handlePinUnpin={handlePinUnpin}
      filtersApplied={!!parameter.selectedValue}
      title={isRangeValue ? parameter.label : ''}
    >
      {parameterControlComponent()}
    </OptionBox>
  );
};

export default Parameter;
