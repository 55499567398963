import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppLazyQuery } from '@apollo-red/hooks/queries';
import { GET_TENANTS_CONFIGURATION_DATA_SAMPLE_VALUES } from '@apollo-red/queries';

export const useGetTenantConfigurationDataSampleValues = (
  tenantId: string,
  applications?: SchemaTypes.TenantConfigurationDataType[],
) => {
  const [getSampleValues, { data, loading }] = useAppLazyQuery<
    SchemaTypes.GetTenantsConfigurationDataSampleValuesQuery,
    SchemaTypes.GetTenantsConfigurationDataSampleValuesQueryVariables
  >(GET_TENANTS_CONFIGURATION_DATA_SAMPLE_VALUES);

  return {
    loading,
    configurationDataSampleValues:
      data?.getTenants?.[0]?.tenant_configuration_data,
    getConfigurationDataSampleValues: () => {
      getSampleValues({
        variables: {
          applications,
          tenant_ids: [tenantId],
        },
      });
    },
  };
};
