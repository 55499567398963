import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_IMPORTANCE_FILTER } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';
import { InsightsAlertImportanceFilter } from '../insightsAlerts.types';

export const useSetInsightsInboxImportanceFilter = () => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const [setInboxImportanceFilter] = useAppMutation<
    SchemaTypes.SetInsightsInboxImportanceFilterMutation,
    SchemaTypes.SetInsightsInboxImportanceFilterMutationVariables
  >(SET_INSIGHTS_INBOX_IMPORTANCE_FILTER);

  return {
    setInboxImportanceFilter: (filter: InsightsAlertImportanceFilter) => {
      setInboxImportanceFilter({
        variables: {
          importanceFilter: filter,
          userId: user.id,
          tenantId,
        },
      });
    },
  };
};
