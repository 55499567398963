import * as React from 'react';
import { IconButton, Icon } from '@jll-labs/azara-ui-components';
import {
  useGetUserCurrentTenantRegularSettings,
  useGetUserInfo,
  useGetUserSystemRoles,
  UserOnboardingStatus,
  RoleType,
} from '@apollo-red/hooks';
import Breadcrumbs from '@components/breadcrumbs';
import BrandedTopBar from '@components/brandedTopBar';
import { Box, Grid, Hidden, useTheme } from '@mui/material';
import {
  DocumentationLink,
  useBreadcrumbHelpPortalLinkInfo,
} from '@components/link';
import Searchbar from './Searchbar';
import ClientSelect from '../ClientSelect';
import EnvironmentSwitch from '../EnvironmentSwitch';
import OnboardingStatus from './OnboardingStatus';

const useStyles = () => {
  const theme = useTheme();
  return {
    searchbar: {
      position: 'absolute',
      backgroundColor: theme.palette.secondary.main,
      height: '100%',
      width: '100%',
      bottom: '100%',
      transition: 'bottom 0.3s',
    },
    searchbarExpanded: {
      bottom: '0%',
    },
    searchItemsContainer: {
      height: '100%',
    },
    searchbarBox: {
      width: '30%',
    },
  };
};

interface Props {
  toggleSearchbar: () => void;
  isSearchbarExpanded: boolean;
  breadcrumbs?: any[];
  searchedPhrase?: string;
}

const TopBar: React.FC<Props> = ({
  breadcrumbs,
  isSearchbarExpanded,
  toggleSearchbar,
  searchedPhrase,
}) => {
  const styles = useStyles();
  const { userTenantRegularSettings } =
    useGetUserCurrentTenantRegularSettings();
  const {
    onboarding: { remindOnboarding },
  } = useGetUserInfo();
  const { pageTitle, routeParts, topic, type } =
    useBreadcrumbHelpPortalLinkInfo(breadcrumbs);

  const userSystemRoles = useGetUserSystemRoles();
  const isProductSupport = userSystemRoles.some(
    ({ role_type }) =>
      role_type === RoleType.SystemAdmin ||
      role_type === RoleType.ProductSupport,
  );

  const toolbarLeftSideItems = (
    <>
      <Box
        sx={[styles.searchbar, isSearchbarExpanded && styles.searchbarExpanded]}
      >
        <Grid
          container={true}
          justifyContent="space-between"
          alignItems="center"
          sx={styles.searchItemsContainer}
        >
          <Box sx={styles.searchbarBox}>
            {isSearchbarExpanded && <Searchbar searched={searchedPhrase} />}
          </Box>
          <IconButton onClick={toggleSearchbar} label="Cancel search">
            <Icon name="Clear" />
          </IconButton>
        </Grid>
      </Box>
      {breadcrumbs && !isSearchbarExpanded && (
        <Breadcrumbs pageTitle={pageTitle} routeParts={...routeParts} />
      )}
    </>
  );

  const toolbarRightSideItems = !isSearchbarExpanded && (
    <>
      {userTenantRegularSettings?.user_onboarding?.status ===
        UserOnboardingStatus.Skipped &&
        remindOnboarding && <OnboardingStatus />}
      <Hidden xsDown={true}>
        <DocumentationLink topic={topic} type={type}>
          <IconButton label="Help" data-testid={'topbar-buttonHelp'}>
            <Icon name="HelpOutline" />
          </IconButton>
        </DocumentationLink>
      </Hidden>
      <Hidden smDown={true}>
        <IconButton
          onClick={toggleSearchbar}
          dataTestId="topbar-buttonSearch"
          label="Search"
        >
          <Icon name="Search" />
        </IconButton>
      </Hidden>
      {isProductSupport && (
        <Box mx={1}>
          <EnvironmentSwitch />
        </Box>
      )}
      <Box mx={1}>
        <ClientSelect />
      </Box>
    </>
  );

  return (
    <BrandedTopBar
      position="fixed"
      toolbarLeftSideItems={toolbarLeftSideItems}
      toolbarRightSideItems={toolbarRightSideItems}
    />
  );
};

export default TopBar;
