import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  label: string;
  icon: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  menuItemWrapper: {
    display: 'flex',
    padding: '5px',
  },
  labelWrapper: {
    paddingLeft: '10px',
  },
}));

const ToolbarMenuOption: React.FC<Props> = ({ icon, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.menuItemWrapper} data-testid={label}>
      {icon}
      <div className={classes.labelWrapper}>{label}</div>
    </div>
  );
};

export default ToolbarMenuOption;
