import { InsightsAlertImportance } from '@apollo-red/schema/generated/operations';
import { InsightsAlertImportanceFilter } from '../insightsAlerts.types';

export const alertImportanceFilterToInsightsAlertImportance: {
  [key in InsightsAlertImportanceFilter]: InsightsAlertImportance | undefined;
} = {
  [InsightsAlertImportanceFilter.All]: undefined,
  [InsightsAlertImportanceFilter.Unassigned]:
    InsightsAlertImportance.Unassigned,
  [InsightsAlertImportanceFilter.High]: InsightsAlertImportance.High,
  [InsightsAlertImportanceFilter.Medium]: InsightsAlertImportance.Medium,
  [InsightsAlertImportanceFilter.Low]: InsightsAlertImportance.Low,
};
