import * as React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { SelectOption, UserAvatarIcon } from '@jll-labs/azara-ui-components';
import {
  InsightsAlertUserInfo,
  useGetUserDefaultTenantId,
  useGetAllNonInternalUsers,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import AlertSelectUser from './AlertSelectUser';

interface Props {
  userInfo: InsightsAlertUserInfo[];
  handleAssignUser: (userId: string) => void;
  assignedUserId?: string;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  root: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
  userName: {
    marginLeft: theme.spacing(1),
  },
}));

const AlertHeaderRightSideContent: React.FC<Props> = ({
  assignedUserId,
  userInfo,
  handleAssignUser,
}) => {
  const [localUserId, setLocalUserId] = React.useState(assignedUserId);
  const { t } = useTranslation();
  const classes = useStyles();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();
  const defaultTenantId = useGetUserDefaultTenantId();
  const { users } = useGetAllNonInternalUsers(defaultTenantId, true);
  const nonInternalUsers = userInfo?.filter(user => {
    return users?.find(u => user?.user_id === u.id);
  });

  React.useEffect(() => {
    setLocalUserId(assignedUserId);
  }, [assignedUserId]);

  const userInfoOptions: SelectOption[] = [...nonInternalUsers]
    ?.sort((u1, u2) => u1.user_name.localeCompare(u2.user_name))
    .map(user => {
      return {
        label: (
          <Grid container={true} alignItems="center">
            <UserAvatarIcon active={true} />
            <div className={classes.userName}>{user?.user_name}</div>
          </Grid>
        ),
        value: user.user_id,
      };
    });

  const userOptions: SelectOption[] = [
    {
      label: (
        <Grid container={true} alignItems="center">
          <UserAvatarIcon />
          <div className={classes.userName}>Unassign</div>
        </Grid>
      ),
      value: 'Unassigned',
    },
    ...userInfoOptions,
  ];

  const assignedUserName = userInfo?.find(
    user => user.user_id === localUserId,
  )?.user_name;

  const handleAssignUserChange = (
    option: any,
    alertType: InsightsAlertType,
    threadId: string,
  ) => {
    trackInsightsAlertEvent('alertAssignToUser', {
      alertThreadId: threadId ?? '',
      alertType: alertType ?? '',
    });

    setLocalUserId(option.value);
    handleAssignUser(option);
  };

  return (
    <Grid container={true} alignItems="center" justifyContent="space-between">
      <AlertSelectUser
        userId={localUserId}
        handleUserChange={handleAssignUserChange}
        userOptions={userOptions}
        activeLabel={localUserId !== 'Unassigned'}
        label={<div>{localUserId ? assignedUserName : t('assignUser')}</div>}
      />
    </Grid>
  );
};

export default AlertHeaderRightSideContent;
