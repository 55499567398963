import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Tooltip } from '@mui/material';
import { Icon, paperStyle } from '@jll-labs/azara-ui-components';
import { ReportExtended } from '@apollo-red/hooks';
import { ThumbnailImage } from '@components/thumbnail';
import { DashboardsPopupTypes } from '@components/tiles/DashboardsPopup';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { TileStyleProps, useSpacingStyles, useTileStyles } from './styles';
import TileActionMenu from './TileActionMenu';
import TileFooter from './TileFooter';

interface Props {
  categoryId: string;
  dashboardRoutes: DashboardRouteProps;
  dragHandle?: React.ReactNode;
  handleDialogOpen: (type: DashboardsPopupTypes, reportId: string) => void;
  isDragging?: boolean;
  isOver?: boolean;
  ref?: any;
  report: ReportExtended;
  showVisibilityButton?: boolean;
  skipImage?: boolean;
  toggleFavorite: () => void;
}

const Tile: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      categoryId,
      dashboardRoutes,
      dragHandle,
      handleDialogOpen,
      isDragging,
      isOver,
      report,
      showVisibilityButton = false,
      skipImage,
      toggleFavorite,
    },
    ref,
  ) => {
    const styles = useTileStyles({ isOver, isDragging } as TileStyleProps);
    const spacing = useSpacingStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleHide = () => {
      handleDialogOpen(DashboardsPopupTypes.Hide, report.id);
    };
    const handleDeleteSavedView = () => {
      handleDialogOpen(DashboardsPopupTypes.DeleteSavedView, report.id);
    };

    return (
      <Card elevation={paperStyle.elevation} sx={styles.card} ref={ref}>
        <CardContent sx={styles.cardContent}>
          <Box sx={styles.titleSection}>
            <div>
              <Box sx={styles.title}>
                {report.report_name}
                {report.isSavedView && (
                  <Tooltip title={t('savedView') ?? ''}>
                    <Icon name="Save" sx={styles.savedIcon} />
                  </Tooltip>
                )}
              </Box>
              <div>{report.primaryCategory || '\u00A0'}</div>
            </div>
            {!!dragHandle && <div> {dragHandle}</div>}
          </Box>
          <Box sx={spacing.mySm}>
            <ThumbnailImage report={report} skipImage={skipImage} />
          </Box>
        </CardContent>
        <TileFooter
          categoryId={categoryId}
          dashboardRoutes={dashboardRoutes}
          onDeleteSavedView={handleDeleteSavedView}
          onHideDashboard={handleHide}
          onShowActionsMenu={handleClick}
          report={report}
          showVisibilityButton={showVisibilityButton}
          toggleFavorite={toggleFavorite}
        />
        <TileActionMenu
          anchorEl={anchorEl}
          dashboardRoutes={dashboardRoutes}
          reportId={report.id}
          onClose={() => setAnchorEl(null)}
        />
        {isDragging && <Box sx={styles.dndForeground} />}
        {(isDragging || isOver) && <Box sx={styles.dndBackground} />}
      </Card>
    );
  },
);

export default Tile;
