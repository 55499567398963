import * as React from 'react';
import { useGetUserCurrentTenantReports } from '@apollo-red/hooks';
import { BaseSearchDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';

const useSaveHandler = () => {
  return {
    saveHandler: () => {},
  };
};

const SearchDashboards: React.FC = () => {
  const routes = useRoutes();
  const { allReports } = useGetUserCurrentTenantReports();

  return (
    <BaseSearchDashboards
      reports={allReports}
      useSaveHandler={useSaveHandler}
      drag={true}
      dashboardRoutes={routes.dashboards}
    />
  );
};

export default SearchDashboards;
