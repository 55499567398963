import { gql } from '@apollo/client';
import { CustomReport, ReportLastUpdate } from '@apollo-red/fragments';
import { ReportConfiguration } from '@apollo-red/fragments';

export const GET_REPORTS = gql`
  query getReports {
    getReports {
      ...CustomReport
    }
  }
  ${CustomReport.fragment}
`;

export const GET_REPORT_BY_ID = gql`
  query getReport($report_ids: [ID!]) {
    getReports(report_ids: $report_ids) {
      ...CustomReport
    }
  }
  ${CustomReport.fragment}
`;

export const GET_REPORT_DATA_UPDATE_BY_ID = gql`
  query getReportDataUpdateDate($report_ids: [ID!]) {
    getReports(report_ids: $report_ids) {
      ...ReportLastUpdate
    }
  }
  ${ReportLastUpdate.fragment}
`;

export const GET_TENANT_REPORT_CONFIGURATION = gql`
  query getTenantReportConfiguration($where: TenantReportConfigurationInput!) {
    getTenantReportConfiguration(where: $where) {
      ...ReportConfiguration
    }
  }
  ${ReportConfiguration.fragment}
`;
