import produce from 'immer';
import { PinFilter } from '../../types';

export const getUpdatedFilters = (
  filter: PinFilter,
  existingFilters: PinFilter[],
) => {
  const existing = existingFilters ?? [];
  const { isExcluded, isPinned, selectedValues, max, min, name } = filter;

  if (!isPinned) {
    return existing.filter(f => f.name !== name);
  }

  return produce(existingFilters, draft => {
    const currentFilter = draft.find(f => f.name === name);

    if (currentFilter) {
      currentFilter.selectedValues = selectedValues;
      currentFilter.isExcluded = isExcluded;
      currentFilter.isPinned = isPinned;
      currentFilter.max = max;
      currentFilter.min = min;
    } else {
      draft.push({ ...filter, isPinned: true });
    }
  });
};
