import { gql } from '@apollo/client';
import { KeywordBase } from '@apollo-red/fragments';

export const ADD_KEYWORD = gql`
  mutation addKeyword($data: UpsertKeywordDataInput!) {
    upsertKeyword(data: $data) {
      ...KeywordBase
    }
  }
  ${KeywordBase.fragment}
`;

export const UPDATE_KEYWORD = gql`
  mutation updateKeyword(
    $where: WhereKeywordInput!
    $data: UpsertKeywordDataInput!
  ) {
    upsertKeyword(data: $data, where: $where) {
      ...KeywordBase
    }
  }
  ${KeywordBase.fragment}
`;

export const DELETE_KEYWORD = gql`
  mutation deleteKeyword($where: WhereKeywordInput!) {
    deleteKeyword(where: $where) {
      ...KeywordBase
    }
  }
  ${KeywordBase.fragment}
`;
