import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useInteractionSectionsStyles } from './useInteractionSectionsStyles';

interface PinnedFiltersHelpMessageProps {
  show?: boolean;
}

const PinnedFiltersHelpMessage: React.FC<PinnedFiltersHelpMessageProps> = ({
  show = false,
}) => {
  const { t } = useTranslation();
  const classes = useInteractionSectionsStyles({ showPinHelpMessage: show });

  return (
    <Grid item className={classes.pinHelpMessage}>
      {!show ? null : (
        <Typography className={classes.pinHelpMessageText}>
          {t('clearPinnedFiltersHelpMessage')}
        </Typography>
      )}
    </Grid>
  );
};

export default React.memo(PinnedFiltersHelpMessage);
