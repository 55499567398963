import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_ALL_COUNTRIES } from '@apollo-red/queries';
import { filterItems } from '@utils/various';
import { useAppQuery } from '../queries';

export type Country = SchemaTypes.CountryBaseFragment;

export const useGetAllCountries = () => {
  const { loading, data } =
    useAppQuery<SchemaTypes.GetCountriesQuery>(GET_ALL_COUNTRIES);

  return {
    loading,
    countries: filterItems(data?.getCountries ?? []),
  };
};
