import { gql } from '@apollo/client';

export const GET_BLOB_STORAGE_UPLOAD_SAS_URL = gql`
  query getBlobStorageUploadSasUrl(
    $where: WhereUpdateUserInput!
    $data: BlobStorageUploadSasUrlInput!
  ) {
    getBlobStorageUploadSasUrl(where: $where, data: $data)
  }
`;
