import * as React from 'react';
import {
  useGetUserPATCategory,
  useGetUserPATReportById,
} from '@apollo-red/hooks';
import { useTranslation } from 'react-i18next';
import PATFullReport from '@components/fullReport/withFilters';
import { PageContainerContext } from '@components/pageContainer';
import { useRoutes } from '@routes';
import { useParamsCustom } from '@utils/router';
import { BaseDashboardsPageWrapper } from '../baseDashboards';
import DashboardTitle from '@components/dashboardTitle';

interface MatchParams {
  categoryId: string;
  dashboardId: string;
}

const specialCategoryIds = {
  all: 'allPATDashboards',
  favorites: 'favorites',
};

const Dashboard: React.FC = () => {
  const routes = useRoutes();
  const { dashboardId, categoryId } = useParamsCustom<MatchParams>();
  const { closeSidebar } = React.useContext(PageContainerContext);
  const { report } = useGetUserPATReportById(dashboardId);
  const { category } = useGetUserPATCategory(categoryId);
  const { t } = useTranslation();

  const categoryName = specialCategoryIds[categoryId]
    ? t(specialCategoryIds[categoryId])
    : category.category_description;

  React.useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  if (!report) {
    return null;
  }

  return (
    <BaseDashboardsPageWrapper
      key={report.id}
      isPATDashboard
      breadcrumbsTitle={t('legacyDashboards')}
      breadcrumbsParts={[
        {
          label: categoryName,
          link: routes.PATDashboards.category.getLink(categoryId),
        },
        { label: report.report_name },
      ]}
      showKeyMetrics={false}
      showToolbar={false}
      title={<DashboardTitle title={report.report_name} />}
      noRightSidePadding={true}
    >
      {() => <PATFullReport report={report} />}
    </BaseDashboardsPageWrapper>
  );
};

export default Dashboard;
