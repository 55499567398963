import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem, { listItemClasses } from '@mui/material/ListItem';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme, Hidden, useTheme, Box } from '@mui/material';
import { Icon } from '@jll-labs/azara-ui-components';
import { SystemStyleObject } from '@mui/system';

const useStyles = (): Record<
  | 'root'
  | 'lineContainer'
  | 'curve'
  | 'adjustDown'
  | 'downRightCurve'
  | 'adjustUp'
  | 'flatLine'
  | 'listItemRoot'
  | 'listItemIconRoot'
  | 'listItemIcon'
  | 'listItemText'
  | 'description',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    root: {
      color: theme.palette.text.secondary,
    },
    lineContainer: {
      position: 'relative',
    },
    curve: {
      border: 'solid',
      borderWidth: '2px',
      borderRadius: `0px 50%`,
      height: 100,
      borderColor: `${theme.palette.action.active} transparent transparent transparent`,
      width: 100,
    },
    adjustDown: {
      position: 'relative',
      top: 55,
    },
    downRightCurve: {
      transform: 'scale(1, -1)',
    },
    adjustUp: {
      position: 'relative',
      bottom: 50,
    },
    flatLine: {
      backgroundColor: theme.palette.action.active,
      height: 2,
      width: 90,
    },
    listItemRoot: {
      [`&.${listItemClasses['root']}`]: {
        paddingLeft: 0,
      },
    },
    listItemIconRoot: {
      [`&.${listItemIconClasses['root']}`]: {
        minWidth: 0,
        marginRight: '1em',
      },
    },
    listItemIcon: {
      fontSize: '26px',
    },
    listItemText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    description: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  };
};

interface Props {
  description: React.ReactNode;
  points: string[];
  linesText?: string;
  lines?: boolean;
}

const BulletList: React.FC<Props> = ({
  description,
  points,
  lines,
  linesText,
}) => {
  const styles = useStyles();

  return (
    <Box component="div" sx={styles.root}>
      <Typography sx={styles.description} variant="h5">
        {description}
      </Typography>
      <Grid container={true} alignItems="center">
        <List>
          {points.map(point => (
            <ListItem key={point} sx={styles.listItemRoot}>
              <ListItemIcon sx={styles.listItemIconRoot}>
                <Icon
                  sx={styles.listItemIcon}
                  name="CheckCircle"
                  color="action"
                />
              </ListItemIcon>
              <ListItemText
                primary={point}
                primaryTypographyProps={{
                  sx: styles.listItemText,
                }}
              />
            </ListItem>
          ))}
        </List>
        {lines && (
          <Hidden only={['xs']}>
            <Box component="div" sx={styles.lineContainer}>
              <Box
                component="div"
                sx={{ ...styles.curve, ...styles.adjustDown }}
              />
              <Box component="div" sx={styles.flatLine} />
              <Box
                component="div"
                sx={{
                  ...styles.curve,

                  ...styles.downRightCurve,
                  ...styles.adjustUp,
                }}
              />
            </Box>
            <div>
              <Typography sx={styles.listItemText}>{linesText}</Typography>
            </div>
          </Hidden>
        )}
      </Grid>
    </Box>
  );
};

export default BulletList;
