import { useGetInsightsAlertRoiDataQuery } from './useGetInsightsAlertRoiDataQuery';

export const useGetInsightsAlertRoiData = (
  userId: string,
  tenantId: string,
  threadId: string,
) => {
  const { loading, data } = useGetInsightsAlertRoiDataQuery({
    userId,
    tenantId,
    threadId,
  });

  const { alert_roi_data, thread_id } =
    data?.getUser?.user_insights_alert ?? {};

  const { frequency, opportunity_type, unit, value, comment } =
    alert_roi_data || {};

  const roiData = {
    frequency: frequency || undefined,
    opportunityType: opportunity_type || undefined,
    unit: unit || undefined,
    value: value || 0,
    comment: comment || '',
  };

  return { thread_id, loading, roiData };
};
