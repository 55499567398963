import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, TextFieldOnChange } from '@jll-labs/azara-ui-components';
import {
  useGetEnvironmentType,
  TenantQaType,
  tenantQaTypeToQaEnvironmentIdentifier,
} from '@apollo-red/hooks';
import makeStyles from '@mui/styles/makeStyles';
import { useSelectOptions } from './EnvironmentSwitch.constants';

const useStyles = makeStyles(() => ({
  root: {
    width: 140,
  },
}));

const EnvironmentSwitch: React.FC = () => {
  const { t } = useTranslation();
  const { environmentType } = useGetEnvironmentType();
  const classes = useStyles();
  const selectOptions = useSelectOptions();

  const onChange: TextFieldOnChange = ({ target: { value } }) => {
    const envId = tenantQaTypeToQaEnvironmentIdentifier[value as TenantQaType];
    const env = envId ?? '';
    const urlPattern =
      process.env.CLIENT_QA_ENV_URL_PATTERN ?? document.location.origin;
    const newOrigin = urlPattern
      .replace('{CLIENT_API_ENV}', env)
      .replace('-.', '.');
    const currentPathname = document.location.pathname;
    const newUrl = `${newOrigin}${currentPathname}`
      .replace('://-', '://')
      .replace('://.', '://');

    document.location.href = newUrl;
  };

  return (
    <div className={classes.root}>
      <Select
        label={t('environment')}
        options={selectOptions}
        onChange={onChange}
        value={environmentType}
        size="xs"
        coloredOutline={true}
      />
    </div>
  );
};

export default React.memo(EnvironmentSwitch);
