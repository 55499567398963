import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorLogger } from '@components/error';
import ErrorScreen, { MainIcon } from './ErrorScreen';

interface Props {
  error: Error;
}

const LoginError: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  const defaultError = t('logInErrorDetailsMsg');
  const errorMessage = `${defaultError} ${error.message}`;

  return (
    <>
      <ErrorScreen
        statusMsg={t('logInErrorStatusMsg')}
        secondaryMsg={t('logInErrorSecondaryMsg')}
        detailsMsg={errorMessage}
        icon={MainIcon.Warning}
      />
      <ErrorLogger error={error} />
    </>
  );
};

export default LoginError;
