import { ReportModuleCategoryType } from '@apollo-red/schema/generated/operations';
import { ReportExtended, Category } from '../reports';

const initFavoriteCategory = (): Category => ({
  id: 'favorites',
  category_description: 'Favorites',
  category_reports: [],
});

const initSavedCategory = (): Category => ({
  id: 'saved',
  category_description: 'My Saved Views',
  category_reports: [],
});

const initCustomReportsCategory = (): Category => ({
  id: 'custom-reports',
  category_description: 'Custom Reports',
  category_reports: [],
});

const initExternalReportsCategory = (): Category => ({
  id: 'external-reports',
  category_description: 'External Reports',
  category_reports: [],
});

const initAllCategory = (): Category => ({
  id: 'all',
  category_description: 'All',
  category_reports: [],
});

const initAllPATCategories = (): Category[] => {
  const categories = [
    ReportModuleCategoryType.Cost,
    ReportModuleCategoryType.Lease,
    ReportModuleCategoryType.Market,
    ReportModuleCategoryType.Occupancy,
    ReportModuleCategoryType.Portfolio,
    ReportModuleCategoryType.SensorAnalysis,
    ReportModuleCategoryType.Trending,
  ];

  return categories.map(value => {
    return {
      id: value,
      category_description: value,
      category_reports: [],
    };
  });
};

export const buildPrimaryCategoriesList = (reports: ReportExtended[]) => {
  return reports.reduce((acc: Category[], report) => {
    const foundCategory =
      report.primaryCategory &&
      acc.find(c => c.category_description === report.primaryCategory);

    if (foundCategory) {
      foundCategory.category_reports.push(report);
      return acc;
    }

    return acc.concat({
      id: report.primaryCategory,
      category_description: report.primaryCategory,
      category_reports: [report],
    });
  }, []);
};

const lengthOfInitialCategories = (
  hasExternalReports: boolean,
  hasCustomReports: boolean,
) => {
  if (hasCustomReports && hasExternalReports) {
    return 4;
  }
  if (hasExternalReports || hasCustomReports) {
    return 3;
  }
  return 2;
};

export const buildCategoriesList = (reports: ReportExtended[]) => {
  const customReports = reports.filter(r => r.isCustomReport);
  const externalReports = reports.filter(r => r.isExternalReport);
  const hasCustomReports = customReports.length > 0;
  const hasExternalReports = externalReports.length > 0;
  const categorized = reports.reduce(
    (acc, report) => {
      if (report.isFav) {
        acc[0].category_reports.push(report);
      }

      if (report.isSavedView) {
        acc[1].category_reports.push(report);
      }

      if (hasCustomReports && report.isCustomReport) {
        acc[2].category_reports.push(report);
      }

      if (hasExternalReports && report.isExternalReport) {
        acc[hasCustomReports ? 3 : 2].category_reports.push(report);
      }

      const foundCategory =
        report.primaryCategory &&
        acc.find(c => c.category_description === report.primaryCategory);

      if (foundCategory) {
        foundCategory.category_reports.push(report);
        return acc;
      }

      return acc.concat({
        id: report.primaryCategory,
        category_description: report.primaryCategory,
        category_reports: [report],
      });
    },
    [
      initFavoriteCategory(),
      initSavedCategory(),
      ...(hasCustomReports ? [initCustomReportsCategory()] : []),
      ...(hasExternalReports ? [initExternalReportsCategory()] : []),
    ],
  );

  const sortedPrimaryCategories = categorized
    .slice(lengthOfInitialCategories(hasExternalReports, hasCustomReports))
    .sort((c1, c2) =>
      c1.category_description.localeCompare(c2.category_description),
    );

  return categorized
    .slice(0, lengthOfInitialCategories(hasExternalReports, hasCustomReports))
    .concat(sortedPrimaryCategories, {
      ...initAllCategory(),
      category_reports: reports,
    });
};

export const buildPATCategoriesList = (reports: ReportExtended[]) => {
  const categorized = reports.reduce(
    (acc, report) => {
      if (report.isFav) {
        acc[0].category_reports.push(report);
      }

      const foundCategory =
        report.primaryCategory &&
        acc.find(c => c.category_description === report.primaryCategory);

      if (foundCategory) {
        foundCategory.category_reports.push(report);
        return acc;
      }

      return acc.concat({
        id: report.primaryCategory,
        category_description: report.primaryCategory,
        category_reports: [report],
      });
    },
    [initFavoriteCategory(), ...initAllPATCategories()],
  );

  const sortedPrimaryCategories = categorized
    .slice(1)
    .sort((c1, c2) =>
      c1.category_description.localeCompare(c2.category_description),
    );

  return categorized.slice(0, 1).concat(sortedPrimaryCategories, {
    ...initAllCategory(),
    category_reports: reports,
  });
};
