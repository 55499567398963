import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { SelectOption } from '@jll-labs/azara-ui-components';
import DownloadFileTypeOption from './DownloadFileTypeOption';
import ToolbarButtonGroup from './ToolbarButtonGroup';
import { ToolbarButton } from './types';

interface Props {
  disabled?: boolean;
  buttonGroup: ToolbarButton[];
}

const ToolbarDownloadItems: React.FC<Props> = ({
  disabled = false,
  buttonGroup,
}) => {
  const { t } = useTranslation();

  if (!buttonGroup || buttonGroup.length === 0) {
    return null;
  }

  const options: SelectOption[] = buttonGroup.map(({ name, onClick }) => {
    return {
      label: (
        <DownloadFileTypeOption
          label={t(name)}
          icon={name === 'pdf' ? 'PDF' : 'Image'}
        />
      ),
      value: name,
      onClick,
    };
  });

  return (
    <Box component="span" mr={2} mb={2} display="inline-block">
      <ToolbarButtonGroup
        icon="Download"
        id="download"
        name={t('download')}
        disabled={disabled}
        size="md"
        iconButton={true}
        options={options}
        tooltip={t('download')}
      />
    </Box>
  );
};

export default ToolbarDownloadItems;
