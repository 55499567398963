import * as React from 'react';
import { useGetTrustedReportUrl } from '@apollo-red/hooks';
import { isTestEnv } from '@components/featureToggle';
import { useIsMountedRef } from '@utils/various';

export interface TableauUrlWithTrustedAuthProps {
  tenantId: string;
  reportId: string;
  url: string;
  disableTrustedAuth?: boolean;
  skip?: boolean;
  retryId?: string;
  retry?: boolean;
}

export const useTableauUrlWithTrustedAuth = ({
  tenantId,
  reportId,
  url,
  disableTrustedAuth,
  skip,
  retryId,
  retry,
}: TableauUrlWithTrustedAuthProps) => {
  const [isTrustedAuthViewUrlGenerated, setIsTrustedAuthViewUrlGenerated] =
    React.useState<boolean>(false);
  const [viewUrl, setViewUrl] = React.useState<string>(url);
  const { getTrustedReportUrl } = useGetTrustedReportUrl();
  const trustedAuthDisabled =
    disableTrustedAuth ||
    (isTestEnv() && process.env.TABLEAU_TRUSTED_AUTH === 'false');
  const isMountedRef = useIsMountedRef();

  React.useEffect(() => {
    if (isTrustedAuthViewUrlGenerated && retry && retryId) {
      setIsTrustedAuthViewUrlGenerated(false);
    }
  }, [isTrustedAuthViewUrlGenerated, retryId, retry]);

  React.useEffect(() => {
    const getTrustedAuthViewUrl = async url => {
      const trustedReportInfo = await getTrustedReportUrl({
        tenantId,
        reportId,
        reportUrl: url,
      });

      const { reportUrl } = trustedReportInfo ?? {};

      if (isMountedRef.current) {
        if (reportUrl) {
          setViewUrl(reportUrl);
          setIsTrustedAuthViewUrlGenerated(true);
        }
      }
    };

    if (trustedAuthDisabled || !viewUrl) {
      setIsTrustedAuthViewUrlGenerated(true);
    } else {
      if (
        !isTrustedAuthViewUrlGenerated &&
        viewUrl.indexOf('/trusted/') === -1 &&
        !skip
      ) {
        getTrustedAuthViewUrl(url);
      } else if (
        isTrustedAuthViewUrlGenerated &&
        viewUrl.indexOf('/trusted/') !== -1 &&
        retry &&
        !skip
      ) {
        getTrustedAuthViewUrl(url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trustedAuthDisabled,
    tenantId,
    reportId,
    isTrustedAuthViewUrlGenerated,
    viewUrl,
    skip,
    retry,
    retryId,
  ]);

  if (!isTrustedAuthViewUrlGenerated) {
    return;
  }

  return viewUrl;
};
