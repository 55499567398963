import * as React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import {
  useCheckFeature,
  useGetUserCurrentTenantRole,
  useGetUserSystemRoles,
  useHasUserAssignedTenants,
} from '@apollo-red/hooks';
import { main, useRoutes } from '@routes';
import {
  RoleMenuItems,
  RoleOtherSubMenuItems,
} from '@components/pageContainer/SideBar/Navigation/navHelpers.types';
import NoData from '@components/noData';

export interface RoleRoute extends RouteProps {
  path: string;
  feature?: RoleMenuItems;
  subFeature?: RoleOtherSubMenuItems;
  disabled?: boolean;
}

export type RoleRoutes = RoleRoute[];

interface Props {
  roleRoutes: RoleRoutes;
  beforeDynamicPathRedirect?: string;
  afterDynamicPathRedirect?: string;
  checkTenantAssigned?: boolean;
  checkRoleAssigned?: boolean;
}

const Routes: React.FC<Props> = ({
  roleRoutes,
  beforeDynamicPathRedirect,
  afterDynamicPathRedirect,
  checkTenantAssigned = true,
  checkRoleAssigned = true,
}) => {
  const routes = useRoutes();
  const { checkFeature } = useCheckFeature();
  const [allowedRoutes, setAllowedRoutes] =
    React.useState<RoleRoutes>(roleRoutes);
  const [redirectTo, setRedirectTo] = React.useState('');
  const { hasTenantAssigned } = useHasUserAssignedTenants();
  const userSystemRoles = useGetUserSystemRoles();
  const userTenantRole = useGetUserCurrentTenantRole();

  React.useEffect(() => {
    const enabledRoutes = roleRoutes.filter(({ disabled }) => !disabled);
    const featureRoutes = enabledRoutes.filter(({ feature, subFeature }) => {
      return feature && checkFeature(feature, subFeature);
    });

    const noRoleAssignRoute: RoleRoute = {
      path: routes.client.noRoleAssigned.path,
      render: props => <NoData {...props} message="noRoleAssigned" />,
    };

    const noTenantAssignedRoute: RoleRoute = {
      path: routes.client.noTenantsAssigned.path,
      render: props => <NoData {...props} message="noClientsAssigned" />,
    };

    setAllowedRoutes([
      ...featureRoutes,
      ...(checkRoleAssigned ? [noRoleAssignRoute] : []),
      ...(checkTenantAssigned ? [noTenantAssignedRoute] : []),
    ]);
  }, [
    checkFeature,
    checkRoleAssigned,
    checkTenantAssigned,
    roleRoutes,
    routes.client.noRoleAssigned.path,
    routes.client.noTenantsAssigned.path,
  ]);

  React.useEffect(() => {
    setRedirectTo(
      checkTenantAssigned && !hasTenantAssigned
        ? routes.client.noTenantsAssigned.getLink()
        : checkRoleAssigned && ![...userSystemRoles, userTenantRole].length
        ? routes.client.noRoleAssigned.getLink()
        : beforeDynamicPathRedirect ??
          allowedRoutes[0]?.path ??
          afterDynamicPathRedirect ??
          main.notFound.getLink(),
    );
  }, [
    afterDynamicPathRedirect,
    allowedRoutes,
    beforeDynamicPathRedirect,
    checkRoleAssigned,
    checkTenantAssigned,
    hasTenantAssigned,
    routes.client.noRoleAssigned,
    routes.client.noTenantsAssigned,
    userSystemRoles,
    userTenantRole,
  ]);

  return (
    <Switch>
      {allowedRoutes.map(({ feature: _, subFeature: __, ...rest }) => (
        <Route key={rest.path} {...rest} />
      ))}
      {redirectTo && <Route render={() => <Redirect to={redirectTo} />} />}
    </Switch>
  );
};

export default Routes;
