import * as React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

interface Props {
  secondary?: boolean;
  className?: string;
}

const Header: React.FC<Props> = ({ children, secondary, className }) => {
  const classes = useStyles();

  return (
    <Typography
      variant={secondary ? 'h6' : 'h4'}
      className={cx(classes.root, className)}
    >
      {children}
    </Typography>
  );
};

export default Header;
