import { useFeatureFlags } from './useFeatureFlags';
import { FeatureFlagKey, FeatureFlagType } from './types';

export const useFeatureFlag = <T extends FeatureFlagKey>(
  flag: T,
): FeatureFlagType<T> => {
  const flags = useFeatureFlags();

  return flags[flag] ?? false;
};
