import { useGetInsightsAlertSummaryQuery } from './useGetInsightsAlertSummaryQuery';

export const useGetInsightsAlertSummary = (
  userId: string,
  tenantId: string,
  hasFeatureEnabled: boolean,
) => {
  const { loading, data } = useGetInsightsAlertSummaryQuery({
    userId,
    tenantId,
    skip: !hasFeatureEnabled,
  });

  const { counts_by_status, client_id, user_id } =
    data?.getUser?.user_insights_alert_summary ?? {};
  const allAlertsCount = counts_by_status?.all ?? 0;
  const unreadAlertsCount = counts_by_status?.unread ?? 0;

  return {
    loading,
    client_id,
    user_id,
    allAlertsCount,
    unreadAlertsCount,
  };
};
