import { PinFilter, FilterExtended } from '../../types';

const initialState: PinFilter = {
  filter_id: '',
  name: '',
  selectedValues: [],
  isPinned: false,
  isExcluded: false,
};

export const getInitialFilterState = (
  filter: FilterExtended,
  initialFilter?: PinFilter,
): PinFilter => {
  if (initialFilter) {
    return initialFilter;
  }

  return {
    ...initialState,
    name: filter.name,
    filter_id: filter.filter_id ?? '',
    selectedValues: filter.static_data.filter(({ selected }) => selected),
    staticData: filter.static_data,
    isUniversalFilter: filter.isUniversalFilter,
  };
};
