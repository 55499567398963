import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_USER_TENANT_INSIGHTS_ALERT_THREAD_USER_HISTORY } from '@apollo-red/queries';
import { useAppQuery } from '../../../queries';

interface GetInsightsAlertUserHistory {
  userId: string;
  tenantId: string;
  threadId: string;
  continuationToken?: string;
  skip?: boolean;
}

export const useGetInsightsAlertUserHistoryQuery = ({
  userId,
  tenantId,
  threadId,
  continuationToken,
  skip,
}: GetInsightsAlertUserHistory) => {
  return useAppQuery<
    SchemaTypes.GetInsightsAlertThreadUserHistoryQuery,
    SchemaTypes.GetInsightsAlertThreadUserHistoryQueryVariables
  >(GET_USER_TENANT_INSIGHTS_ALERT_THREAD_USER_HISTORY, {
    variables: {
      where: { id: userId },
      whereInsightsAlert: {
        tenant_id: tenantId,
        thread_id: threadId,
      },
      whereInsightsAlertUserHistory: {
        continuation_token: continuationToken,
      },
    },
    skip: !userId || !tenantId || skip,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
};
