import * as React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useGetUserInfo,
  getTenantSetting,
  useGetUserDefaultTenantId,
  useGetUserFavReports,
  UserUniversalFilter,
} from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import StepWrapper, { StepWrapperProps } from './StepWrapper';

const useStyles = makeStyles((theme: AppTheme) => ({
  formContainer: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  gridItems: {
    padding: theme.spacing(2, 0),
    textAlign: 'left',
    wordWrap: 'break-word',
  },
  categoryText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const parseUniversalFilter = (
  filters?: UserUniversalFilter[] | null,
  prependComma?: boolean,
) => {
  if (!filters || filters.length === 0) {
    return '';
  }

  const values = filters.map(f => f.filter_value).join(', ');
  return prependComma ? `, ${values}` : values;
};

const Summary: React.FC<StepWrapperProps> = props => {
  const classes = useStyles();
  const { user } = useGetUserInfo();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const defaultTenantId = useGetUserDefaultTenantId();
  const { favReports } = useGetUserFavReports();

  const userTenantUniversalFilters = getTenantSetting(
    user.user_settings.universal_filters,
    defaultTenantId,
  );

  const userTenantWorkRole = getTenantSetting(
    user.user_settings.work_roles,
    user.user_settings.default_tenant && user.user_settings.default_tenant.id,
  );

  const summaryArray = [
    {
      label: 'Organizational Role - Work Area',
      value: [userTenantWorkRole ? userTenantWorkRole.work_role.work_area : ''],
    },
    {
      label: 'Organizational Role - Specialization',
      value: [
        userTenantWorkRole ? userTenantWorkRole.work_role.specialization : '',
      ],
    },
    {
      label: 'Organizational Role - Role',
      value: [userTenantWorkRole ? userTenantWorkRole.work_role.role : ''],
    },
    {
      label: 'Scope by Location',
      value: [
        userTenantUniversalFilters
          ? `${parseUniversalFilter(
              userTenantUniversalFilters.filters.countries?.filters,
            )}${parseUniversalFilter(
              userTenantUniversalFilters.filters.states?.filters,
              true,
            )}${parseUniversalFilter(
              userTenantUniversalFilters.filters.cities?.filters,
              true,
            )}`
          : '',
      ],
    },
    {
      label: 'Favorite Dashboards',
      value: favReports.map(report => report.report_name),
    },
    {
      label: 'Contact Information',
      value: [
        user.user_email,
        user.user_comm.work,
        user.user_address.region ? user.user_address.region.region_name : '',
        user.user_address.country ? user.user_address.country.country_desc : '',
      ],
    },
  ];

  const handleSubmitData = () => {
    setIsSubmitting(true);
    props.handleNextClick?.(props.activeStep);
  };

  return (
    <StepWrapper
      icon="FormatListBulleted"
      title="Review your selections made during the onboarding process"
      description={
        <span>
          Click on page numbers at the bottom to update these settings. <br />
          <br /> You can also review and update your selections on{' '}
          <b>My Account</b> page.
        </span>
      }
      isValid={true}
      isSubmitting={isSubmitting}
      stepWrapperProps={{ ...props, handleNextClick: handleSubmitData }}
      rightSideHeader="Review Your Selections"
    >
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          {summaryArray.map(section => (
            <Grid key={section.label} container item xs={12} spacing={8}>
              <Grid item xs={4}>
                <div className={classes.gridItems}>
                  <span className={classes.categoryText}>{section.label}</span>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className={classes.gridItems}>
                  {(section.value as string[]).map((item, index) =>
                    section.value.length - 1 === index || item === ''
                      ? item
                      : `${item}, `,
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </StepWrapper>
  );
};

export default React.memo(Summary);
