import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_ALERT_SEVERITY } from '@apollo-red/mutations';
import { useGetInsightsInboxAlertState } from '../inbox';
import {
  InsightsAlertSeverity,
  InsightsAlertUserHistory,
  InsightsAlertUserHistoryRecord,
  InsightsAlertUserHistoryActionPerformed,
} from '../insightsAlerts.types';

export const useSetInsightsAlertSeverity = () => {
  const [setInsightsAlertSeverity] = useAppMutation<
    SchemaTypes.SetInsightsAlertSeverityMutation,
    SchemaTypes.SetInsightsAlertSeverityMutationVariables
  >(SET_INSIGHTS_ALERT_SEVERITY);
  const { userId, tenantId, userName } = useGetInsightsInboxAlertState();

  return {
    setInsightsAlertSeverity: (
      threadId: string,
      severity: InsightsAlertSeverity,
    ) => {
      const timeStamp = `${new Date().getTime()}`;
      setInsightsAlertSeverity({
        variables: {
          where: {
            created_at: timeStamp,
            severity,
            tenant_id: tenantId,
            thread_id: threadId,
            user_id: userId,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setInsightsAlertSeverity: true,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlert',
              thread_id: threadId,
            }),
            fields: {
              severity() {
                return severity;
              },
            },
            optimistic: true,
          });

          cache.modify({
            id: cache.identify({
              __typename: 'InsightsAlertThread',
              thread_id: threadId,
            }),
            fields: {
              alert_user_history(existing: InsightsAlertUserHistory) {
                const userHistory: InsightsAlertUserHistoryRecord = {
                  commented_at: timeStamp,
                  action_performed:
                    InsightsAlertUserHistoryActionPerformed.SeverityUpdated,
                  user_name: userName,
                  comment: severity,
                };

                return {
                  ...existing,
                  user_history: [userHistory, ...existing.user_history],
                };
              },
            },
            optimistic: true,
          });
        },
      });
    },
  };
};
