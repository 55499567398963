import { gql } from '@apollo/client';
import { ApplicationBase, ApplicationSchemaBase } from '@apollo-red/fragments';

export const GET_ALL_APPLICATIONS = gql`
  query getApplications {
    getApplications {
      ...ApplicationBase
    }
  }
  ${ApplicationBase.fragment}
`;

export const GET_APPLICATIONS_SCHEMA = gql`
  query getApplicationsSchema($app_ids: [ID!]!, $tenant_id: String!) {
    getApplications(application_ids: $app_ids) {
      ...ApplicationBase
      app_schema(client_id: $tenant_id) {
        ...ApplicationSchemaBase
      }
    }
  }

  ${ApplicationBase.fragment}
  ${ApplicationSchemaBase.fragment}
`;
