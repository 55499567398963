import * as React from 'react';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import {
  Autocomplete,
  AutocompleteOption,
} from '@jll-labs/azara-ui-components';
import {
  useGetUserCurrentTenantReports,
  ReportExtended,
} from '@apollo-red/hooks';
import { useRoutes } from '@routes';

const prepareData = (reports: ReportExtended[]) => {
  return reports.reduce((keywords, report) => {
    return keywords.concat(
      report.report_full_keywords
        .filter(
          ({ keyword_name }) =>
            !keywords.some(({ value }) => value === keyword_name),
        )
        .map(keyword => ({
          value: keyword.keyword_name,
          label: keyword.keyword_name,
        })),
    );
  }, [] as AutocompleteOption[]);
};

interface Props {
  searched?: string;
}

const SearchBar: React.FC<Props> = ({ searched }) => {
  const history = useHistory();
  const routes = useRoutes();
  const { allReports } = useGetUserCurrentTenantReports();

  const handleChange = (_, option: AutocompleteOption) => {
    history.push(routes.dashboards.search?.getLink(option.value) || '');
  };

  const options = prepareData(allReports);
  const value = options.find(opt => opt.value === searched);

  return (
    <Box py={1.5}>
      <Autocomplete
        onChange={handleChange}
        options={options}
        value={value || null}
        placeholder="Search"
        hideBorders={true}
        disableLeftPadding={true}
        autoFocus={true}
        disableArrowIcon={true}
        disableClearable={true}
      />
    </Box>
  );
};

export default SearchBar;
