import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { isInternalUser } from './isInternalUser';

const useAuth = () => {
  const [userEmail, setUserEmail] = React.useState<string | undefined>();
  const { oktaAuth, authState } = useOktaAuth();

  React.useEffect(() => {
    const setUser = async () => {
      try {
        if (authState?.isAuthenticated) {
          const { email, preferred_username: upn } = await oktaAuth.getUser();
          const nextEmail = isInternalUser(upn ?? '') ? upn : email;

          setUserEmail(nextEmail);
        }
      } catch (e) {
        throw new Error('Authentication failed');
      }
    };

    setUser();
  }, [authState, oktaAuth]);

  return React.useMemo(
    () => ({
      oktaAuth,
      authState,
      userEmail,
    }),
    [authState, oktaAuth, userEmail],
  );
};

export default useAuth;
