import * as React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  useGetUserInfo,
  useGetUserDefaultTenantId,
  UserSiteRole,
} from '@apollo-red/hooks';

interface Props {
  height?: string;
}

const TableauLicenseValidator: React.FC<Props> = ({ children, height }) => {
  const { t } = useTranslation();
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();
  const siteRole = user?.user_tenant_settings?.find(
    settings => settings.user_tenant?.id === defaultTenantId,
  )?.user_provisioned_site_role;

  const showDashboards = siteRole === UserSiteRole.Unlicensed;

  if (showDashboards) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={height || '50%'}
      >
        {t('dashboardsLicenseErrorMessage')}
      </Box>
    );
  }

  return <> {children} </>;
};

export default TableauLicenseValidator;
