import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  InsightsAlert,
  InsightsAlertUserStatus,
  useGetInsightsAlertBody,
  InsightsAlertStatusUpdate,
  InsightsAlertSeverity,
  InsightsAlertStatus,
  ROIData,
  InsightsAlertInbox,
} from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import {
  AlertUserHistory,
  AlertStatusButtons,
  AlertBody,
  AlertCommentBox,
  AlertHeader,
  AlertHeaderRightSideContent,
  AlertRecommendations,
  AlertSelectSeverity,
  AlertHistory,
  useGetSeverityOptions,
} from './alertBodyComponents';
import {
  alertTypeToTitleTranslation,
  alertStatusTranslationKeys,
} from './utils';

const useStyles = makeStyles((theme: AppTheme) => ({
  statusBox: {
    padding: '1em 0',
  },
  statusLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  circularProgress: {
    flexGrow: 1,
    visibility: 'visible',
    height: '100%',
  },
  circularProgressHidden: {
    flexGrow: 1,
    visibility: 'hidden',
    height: '0',
  },
  severityAndHistoryList: {
    margin: theme.spacing(2, 0),
  },
}));

interface Props {
  activeAlert: InsightsAlert;
  inboxType: InsightsAlertInbox;
  handleSeverityChange: (
    threadId: string,
    severity: InsightsAlertSeverity,
  ) => void;
  handleAssignUserChange: (threadId: string, userAssigned: string) => void;
  handleAlertRead: (alert: InsightsAlert) => void;
  handleStatusChange: (
    threadId: string,
    status: InsightsAlertStatusUpdate,
    comment?: string,
  ) => void;
  handleROIDataChange: (threadId: string, roiData: ROIData) => void;
  handleAddUserComment: (threadId: string, comment: string) => void;
}

const hasFixedStatus = (alert: InsightsAlert) => {
  return [
    InsightsAlertStatus.Closed,
    InsightsAlertStatus.DataQualityReview,
  ].includes(alert.alert_status);
};

const AlertDetails: React.FC<Props> = ({
  activeAlert,
  handleStatusChange,
  handleSeverityChange,
  handleAssignUserChange,
  handleAlertRead,
  handleROIDataChange,
  handleAddUserComment,
  inboxType,
}) => {
  const {
    alert_count,
    alert_type,
    assigned_user_id,
    severity,
    thread_id,
    user_info,
    user_status,
  } = activeAlert ?? {};
  const classes = useStyles();
  const { t } = useTranslation();
  const { severityOptions } = useGetSeverityOptions();
  const { loading, alertHistory, roiData } = useGetInsightsAlertBody(thread_id);
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  React.useEffect(() => {
    if (
      loading ||
      !activeAlert ||
      user_status !== InsightsAlertUserStatus.Unread
    ) {
      return;
    }

    handleAlertRead(activeAlert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, thread_id, user_status]);

  if (!loading && !activeAlert) {
    return null;
  }

  const renderContent = (alert: InsightsAlert) => {
    if (hasFixedStatus(alert)) {
      return (
        <div>
          <div className={classes.statusBox}>
            <div>
              <span>{'Status: '}</span>
              <span className={classes.statusLabel}>
                {t(alertStatusTranslationKeys[alert.alert_status])}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <AlertStatusButtons
        activeAlert={alert}
        handleStatusChange={handleStatusChange}
        handleROIDataChange={handleROIDataChange}
        roiData={roiData}
      />
    );
  };

  return (
    <>
      <Box
        className={
          loading ? classes.circularProgress : classes.circularProgressHidden
        }
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <CircularProgress disableShrink color="primary" />
      </Box>

      {!loading && (
        <div>
          <AlertHeader
            title={t(alertTypeToTitleTranslation[alert_type])}
            rightSideContent={
              <AlertHeaderRightSideContent
                userInfo={user_info}
                assignedUserId={assigned_user_id || ''}
                handleAssignUser={(option: any) => {
                  trackInsightsAlertEvent('alertAssignToUser', {
                    alertThreadId: thread_id ?? '',
                    alertType: alert_type ?? '',
                  });

                  handleAssignUserChange(thread_id, option.value);
                }}
              />
            }
          />
          <Grid
            container={true}
            alignItems="flex-start"
            className={classes.severityAndHistoryList}
          >
            <div style={{ minWidth: '10%' }}>
              <AlertSelectSeverity
                severity={severity}
                activeAlert={activeAlert}
                handleSeverityChange={selectedOption =>
                  handleSeverityChange(
                    activeAlert.thread_id,
                    selectedOption.value,
                  )
                }
                severityOptions={severityOptions}
              />
            </div>
            <div style={{ minWidth: '85%' }}>
              <AlertHistory history={alertHistory} count={alert_count} />
            </div>
          </Grid>
          <AlertBody alert={activeAlert} inboxType={inboxType} />
          <AlertRecommendations alert={activeAlert} />
          {renderContent(activeAlert)}
          <AlertCommentBox
            activeAlert={activeAlert}
            handleAddUserComment={(comment: string) => {
              handleAddUserComment(thread_id, comment);
            }}
          />
          <AlertUserHistory threadId={thread_id} />
        </div>
      )}
    </>
  );
};

export default AlertDetails;
