import * as React from 'react';
import { ExternalReportType, ReportExtended } from '@apollo-red/hooks';
import {
  ExternalFullReport,
  ExternalStandardReport,
  ExternalThoughtSpotReport,
} from '@components/externalReport';
import { isTableauHostedReport } from '@utils/tableau';
import { BaseDashboardsPageWrapper } from '../baseDashboards';

type ExternalReportRenderProp = React.ComponentProps<
  typeof BaseDashboardsPageWrapper
>['children'];

export const getExternalReportRenderProp = (
  report: ReportExtended,
): ExternalReportRenderProp => {
  const { report_external_url, report_external_type } = report;
  const isTableauReport = isTableauHostedReport(report_external_url ?? '');

  return () => (
    <div>
      {isTableauReport &&
        report_external_type === ExternalReportType.Tableau && (
          <ExternalFullReport report={report} />
        )}
      {report_external_type === ExternalReportType.ThoughtSpot && (
        <ExternalThoughtSpotReport report={report} />
      )}
      {report_external_type === ExternalReportType.Standard && (
        <ExternalStandardReport report={report} />
      )}
    </div>
  );
};
