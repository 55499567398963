import * as React from 'react';
import { getAlertDate } from '../utils';
import { useAlertTableRowStyles } from '../alertTableRows/useAlertTableRowStyles';
import { Box } from '@mui/material';

interface Props {
  createdAt: string;
}

const AlertDateCell: React.FC<Props> = ({ createdAt }) => {
  const styles = useAlertTableRowStyles();

  return (
    <Box sx={styles.dateContainer}>
      {getAlertDate(new Date(Number(createdAt)))}
    </Box>
  );
};

export default AlertDateCell;
