import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Icon, IconName } from '@jll-labs/azara-ui-components';

type DownloadFileTypeIconName = Extract<IconName, 'Image' | 'CSV' | 'PDF'>;

interface Props {
  label: string;
  icon: DownloadFileTypeIconName;
}

const useStyles = makeStyles(() => ({
  optionWrapper: {
    display: 'flex',
  },
  icon: {
    marginRight: '10px',
  },
}));

const DownloadFileTypeOption: React.FC<Props> = ({ icon, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.optionWrapper} data-testid={label}>
      <Icon fontSize="small" name={icon} className={classes.icon} />
      {label}
    </div>
  );
};

export default DownloadFileTypeOption;
