import { isToday, format } from 'date-fns';
import { IconName } from '@jll-labs/azara-ui-components';
import {
  InsightsAlertStatusUpdate,
  InsightsAlertInbox,
  InsightsAlertUserStatus,
  InsightsAlertSeverity,
  InsightsAlertImportance,
  InsightsAlertType,
  InsightsAlertUserHistoryActionPerformed,
  InsightsAlertSeverityFilter,
  InsightsAlertTypeFilter,
  TenantAlertSettingsType,
} from '@apollo-red/hooks';
import { InsightsAlertsInboxTypeParam } from '@routes';

export const isAlertUnread = (status: InsightsAlertUserStatus) => {
  return status === InsightsAlertUserStatus.Unread;
};

export const getAlertDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, 'h:mm a');
  } else {
    return format(date, 'MMM d');
  }
};

export const alertInboxTranslationKeys = {
  [InsightsAlertInbox.Unread]: 'unreadAlerts',
  [InsightsAlertInbox.Read]: 'readAlerts',
  [InsightsAlertInbox.SavedForLater]: 'saveForLater',
  [InsightsAlertInbox.All]: 'allAlerts',
  [InsightsAlertInbox.DataQualityReview]: 'dataQualityReview',
  [InsightsAlertInbox.Closed]: 'closed',
  [InsightsAlertInbox.AssignedToMe]: 'assignedToMe',
};

export const alertStatusTranslationKeys = {
  [InsightsAlertStatusUpdate.UnableToAction]: 'unableToAction',
  [InsightsAlertStatusUpdate.Closed]: 'closed',
  [InsightsAlertStatusUpdate.DataQualityReview]: 'dataQualityReview',
  [InsightsAlertStatusUpdate.SaveForLater]: 'saveForLater',
};

export const alertUserStatusTranslationKeys = {
  [InsightsAlertUserStatus.UnableToAction]: 'unableToAction',
  [InsightsAlertUserStatus.Unread]: 'unreadAlerts',
  [InsightsAlertUserStatus.Read]: 'readAlerts',
  [InsightsAlertUserStatus.SaveForLater]: 'saveForLater',
};

export const alertTypeDashboardNameTranslationKeys: Record<
  InsightsAlertTypeFilter,
  string
> = {
  [InsightsAlertTypeFilter.All]: 'all',
  [InsightsAlertTypeFilter.WorkorderVolume]: 'workOrderVolume',
  [InsightsAlertTypeFilter.FacilitiesExperience]: 'facilitiesExperience',
  [InsightsAlertTypeFilter.LeaseEvent]: 'leaseEvents',
  [InsightsAlertTypeFilter.Covid_19WorkOrder]: 'covidWorkOrder',
  [InsightsAlertTypeFilter.MarketBenchmark]: 'marketBenchmark',
  [InsightsAlertTypeFilter.OccupancyEfficiency]: 'occupancyDetails',
  [InsightsAlertTypeFilter.SubleaseRisk]: 'leaseEvents',
  [InsightsAlertTypeFilter.SameDayWorkOrderCompletion]:
    'sameDayWorkOrderCompletion',
};

export const alertTypeTranslationKeys: Record<InsightsAlertTypeFilter, string> =
  {
    [InsightsAlertTypeFilter.All]: 'all',
    [InsightsAlertTypeFilter.WorkorderVolume]: 'workOrderVolume',
    [InsightsAlertTypeFilter.FacilitiesExperience]: 'facilitiesExperience',
    [InsightsAlertTypeFilter.LeaseEvent]: 'leaseEvents',
    [InsightsAlertTypeFilter.Covid_19WorkOrder]: 'covidWorkOrder',
    [InsightsAlertTypeFilter.MarketBenchmark]: 'marketBenchmark',
    [InsightsAlertTypeFilter.OccupancyEfficiency]: 'occupancyEfficiency',
    [InsightsAlertTypeFilter.SubleaseRisk]: 'subleaseRisk',
    [InsightsAlertTypeFilter.SameDayWorkOrderCompletion]:
      'sameDayWorkOrderCompletion',
  };

export const paramToInboxType: Record<
  InsightsAlertsInboxTypeParam,
  InsightsAlertInbox
> = {
  all: InsightsAlertInbox.All,
  unread: InsightsAlertInbox.Unread,
  read: InsightsAlertInbox.Read,
  'saved-for-later': InsightsAlertInbox.SavedForLater,
  'data-quality-review': InsightsAlertInbox.DataQualityReview,
  closed: InsightsAlertInbox.Closed,
  'assigned-to-me': InsightsAlertInbox.AssignedToMe,
};

export const alertInboxToParam: Record<
  InsightsAlertInbox,
  InsightsAlertsInboxTypeParam
> = {
  [InsightsAlertInbox.Unread]: 'unread',
  [InsightsAlertInbox.Read]: 'read',
  [InsightsAlertInbox.SavedForLater]: 'saved-for-later',
  [InsightsAlertInbox.All]: 'all',
  [InsightsAlertInbox.DataQualityReview]: 'data-quality-review',
  [InsightsAlertInbox.Closed]: 'closed',
  [InsightsAlertInbox.AssignedToMe]: 'assigned-to-me',
};

export const ColorPicker: Record<InsightsAlertImportance, string> = {
  [InsightsAlertImportance.High]: '#cf0808',
  [InsightsAlertImportance.Medium]: '#f0ba1a',
  [InsightsAlertImportance.Low]: '#00802b',
  [InsightsAlertImportance.Unassigned]: 'lightgray',
};

export enum AlertSeverity {
  Escalation = 'escalation',
  None = 'none',
  Reminder = 'reminder',
  Urgent = 'urgent',
}

export const alertActionToComment: Record<
  InsightsAlertUserHistoryActionPerformed,
  string
> = {
  [InsightsAlertUserHistoryActionPerformed.AssignedUser]: 'Assigned User',
  [InsightsAlertUserHistoryActionPerformed.CommentAdded]: 'Comment Added',
  [InsightsAlertUserHistoryActionPerformed.Closed]: 'Closed',
  [InsightsAlertUserHistoryActionPerformed.DataQualityReview]:
    'Data Quality Review',
  [InsightsAlertUserHistoryActionPerformed.DismissSaveForLater]:
    'Save For Later',
  [InsightsAlertUserHistoryActionPerformed.DismissUnableToAction]:
    'Unable To Action',
  [InsightsAlertUserHistoryActionPerformed.RoiAdded]: 'ROI Added',
  [InsightsAlertUserHistoryActionPerformed.RoiEdit]: 'ROI Edited',
  [InsightsAlertUserHistoryActionPerformed.SeverityUpdated]: 'Severity Updated',
  [InsightsAlertUserHistoryActionPerformed.SystemClosed]: 'System Closed',
  [InsightsAlertUserHistoryActionPerformed.UnAssignedUser]: 'Unassigned User',
  [InsightsAlertUserHistoryActionPerformed.DismissUnableToAction]:
    'Dismiss Unable To Action',
};

export interface severityConfig {
  value: InsightsAlertSeverity;
  label: string;
  icon: IconName;
}

export const severityConfig: severityConfig[] = [
  { value: InsightsAlertSeverity.None, label: 'Unassign', icon: 'Flag' },
  {
    value: InsightsAlertSeverity.Reminder,
    label: 'Reminder',
    icon: 'Reminder',
  },
  {
    value: InsightsAlertSeverity.Urgent,
    label: 'Urgent',
    icon: 'Urgent',
  },
  {
    value: InsightsAlertSeverity.Escalation,
    label: 'Escalation',
    icon: 'Escalation',
  },
];

export const alertTypeToTitleTranslation: Record<InsightsAlertType, string> = {
  [InsightsAlertType.WorkorderVolume]: 'workOrderVolume',
  [InsightsAlertType.FacilitiesExperience]: 'facilitiesExperience',
  [InsightsAlertType.LeaseEvent]: 'leaseEvent',
  [InsightsAlertType.Covid_19WorkOrder]: 'covidWorkOrder',
  [InsightsAlertType.MarketBenchmark]: 'marketBenchmark',
  [InsightsAlertType.OccupancyEfficiency]: 'occupancyEfficiency',
  [InsightsAlertType.SubleaseRisk]: 'subleaseRisk',
  [InsightsAlertType.SameDayWorkOrderCompletion]: 'sameDayWorkOrderCompletion',
};

export const alertSeverityFilterToInsightsAlertSeverity: Record<
  InsightsAlertSeverityFilter,
  InsightsAlertSeverity | undefined
> = {
  [InsightsAlertSeverityFilter.All]: undefined,
  [InsightsAlertSeverityFilter.Escalation]: InsightsAlertSeverity.Escalation,
  [InsightsAlertSeverityFilter.None]: InsightsAlertSeverity.None,
  [InsightsAlertSeverityFilter.Reminder]: InsightsAlertSeverity.Reminder,
  [InsightsAlertSeverityFilter.Urgent]: InsightsAlertSeverity.Urgent,
};

export const alertTypeFilterToInsightsAlertTitleTranslation: Record<
  InsightsAlertTypeFilter,
  string
> = {
  [InsightsAlertTypeFilter.All]: 'alertType',
  [InsightsAlertTypeFilter.Covid_19WorkOrder]: 'covidWorkOrder',
  [InsightsAlertTypeFilter.FacilitiesExperience]: 'facilitiesExperience',
  [InsightsAlertTypeFilter.LeaseEvent]: 'leaseEvent',
  [InsightsAlertTypeFilter.WorkorderVolume]: 'workOrderVolume',
  [InsightsAlertTypeFilter.MarketBenchmark]: 'marketBenchmark',
  [InsightsAlertTypeFilter.OccupancyEfficiency]: 'occupancyEfficiency',
  [InsightsAlertTypeFilter.SubleaseRisk]: 'subleaseRisk',
  [InsightsAlertTypeFilter.SameDayWorkOrderCompletion]:
    'sameDayWorkOrderCompletion',
};

export const insightsAlertTypeFilterToTenantAlertSettingsType: Record<
  Exclude<InsightsAlertTypeFilter, 'all'>,
  TenantAlertSettingsType
> = {
  [InsightsAlertTypeFilter.WorkorderVolume]:
    TenantAlertSettingsType.WorkorderVolume,
  [InsightsAlertTypeFilter.MarketBenchmark]:
    TenantAlertSettingsType.MarketBenchmark,
  [InsightsAlertTypeFilter.LeaseEvent]: TenantAlertSettingsType.LeaseEvent,
  [InsightsAlertTypeFilter.FacilitiesExperience]:
    TenantAlertSettingsType.FacilitiesExperience,
  [InsightsAlertTypeFilter.Covid_19WorkOrder]:
    TenantAlertSettingsType.Covid_19WorkOrder,
  [InsightsAlertTypeFilter.OccupancyEfficiency]:
    TenantAlertSettingsType.OccupancyEfficiency,
  [InsightsAlertTypeFilter.SubleaseRisk]: TenantAlertSettingsType.SubleaseRisk,
  [InsightsAlertTypeFilter.SameDayWorkOrderCompletion]:
    TenantAlertSettingsType.SameDayWorkOrderCompletion,
};
