import { gql } from '@apollo/client';

export const ReportMetadataBase = {
  fragment: gql`
    fragment ReportMetadataBase on Report {
      report_metadata {
        id
        enabled
        module {
          enabled
          module_type
        }
        module_category {
          enabled
          module_category_type
        }
        platform
        report_name
        description
        url
        reference_url
        image_url
        sort_index
        interaction_config {
          id
          enabled
          sections {
            id
            enabled
            label
            sort_index
            expanded
            section_type
            controls {
              id
              enabled
              label
              name
              section_type
              control_type
              static_data {
                label
                value
                selected
              }
              cascade_update
              sort_index
            }
          }
        }
        source_data {
          source_name
          client_report_id
          client_id
        }
        report_metadata_mapping {
          worksheet
        }
      }
    }
  `,
};

export const ReportBase = {
  fragment: gql`
    fragment ReportBase on Report {
      id
      report_name
      report_original_name
      report_tableau_url
      report_tableau_full_report_url
      report_external_url
      report_external_thumbnail_url
      report_external_anchor_target
      report_external_type
      report_external_thoughtspot_host
      report_external_thoughtspot_pinboard_id
      report_external_thoughtspot_viz_id
      report_active
      report_tenant
      report_parent
      report_tableau_thumbnail_url
      report_description
      report_full_keywords {
        id
        keyword_name
      }
      report_cat {
        id
        category_description
      }
      report_wb
      report_added_by
      report_updated_by
      report_hidden
      report_custom_name
      report_custom_categories {
        id
        category_description
      }
      report_disabled
      ...ReportMetadataBase
    }
    ${ReportMetadataBase.fragment}
  `,
};

export const ReportLastUpdate = {
  fragment: gql`
    fragment ReportLastUpdate on Report {
      id
      report_data_update_date
    }
  `,
};

export const CustomReportBase = {
  fragment: gql`
    fragment CustomReportBase on Report {
      ...ReportBase
      custom_report_status
      custom_report_created_at
      custom_report_previewed_at
      custom_report_previewed_by
      custom_report_published_at
      custom_report_published_by
      custom_report_deactivated_at
      custom_report_deactivated_by
      custom_report_version
      custom_report_published {
        id
        custom_report_version
      }
    }
    ${ReportBase.fragment}
  `,
};

export const CustomReport = {
  fragment: gql`
    fragment CustomReport on Report {
      ...CustomReportBase
      custom_report_can_be_published
      custom_report_can_be_previewed
      custom_report_can_be_deactivated
    }
    ${CustomReportBase.fragment}
  `,
};

export const ReportConfiguration = {
  fragment: gql`
    fragment ReportConfiguration on ReportConfig {
      id
      report_id
      enabled
      report_name
      description
      interaction_config {
        id
        enabled
        sections {
          id
          enabled
          label
          sort_index
          expanded
          section_type
          controls {
            id
            filter_id
            enabled
            label
            name
            section_type
            control_type
            static_data {
              label
              value
              selected
            }
            cascade_update
            sort_index
          }
        }
      }
    }
  `,
};
