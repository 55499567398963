import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Paper, Loader, Icon } from '@jll-labs/azara-ui-components';
import { Viz } from '@components/tableau/types';
import { useMarksSelection } from '@utils/tableau';
import {
  calculateContentOffset,
  getMarksSelectionCount,
} from '../withFilters/utils';
import { ReportFiltersInteractionSection } from '../withFilters/types';
import {
  VIZ_CONTENT_WITHOUT_FLOATING_PANEL,
  VIZ_PADDING,
} from '../withFilters/constants';
import { DeferLoadInteractionSections } from '../withFilters';
import { useFullReportWithFilters } from './FullReportWithFiltersProvider';
import { useFloatingPanelStyles } from './useFloatingPanelStyles';

interface Props {
  sections: ReportFiltersInteractionSection[];
}

const setFrameSize = (viz: Viz, width: number, height: number) => {
  viz.setFrameSize(width, height);
};

const FullReportWithFloatingPanel: React.FC<Props> = ({
  children,
  sections,
}) => {
  const {
    isSidebarExpanded,
    showFiltersPanel,
    viz,
    resetKey,
    areParametersLoading,
    areFiltersLoading,
    contentHeight,
    contentWidth,
    toggleFiltersPanel,
    updateAppliedMarksSelectionCount,
  } = useFullReportWithFilters();
  const isLoading = areParametersLoading || areFiltersLoading;
  const isVizLoading = !viz;
  const classes = useFloatingPanelStyles({
    showFiltersPanel,
    isLoading,
    isVizLoading,
  });
  const { marksCount } = useMarksSelection(viz);
  const { vizContent, floatingContent } = calculateContentOffset(
    { width: contentWidth, height: contentHeight },
    showFiltersPanel,
    isSidebarExpanded,
  );
  const vizContainerWidth = viz
    ? vizContent.width
    : `${VIZ_CONTENT_WITHOUT_FLOATING_PANEL}%`;
  const filterPanelContainerWidth = viz ? floatingContent.width : '0';
  const filterPanelContainerHeight = floatingContent.height - VIZ_PADDING;

  React.useEffect(() => {
    if (viz) {
      setFrameSize(
        viz,
        vizContent.width - VIZ_PADDING,
        vizContent.height - VIZ_PADDING,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viz, showFiltersPanel, contentHeight, contentWidth]);

  React.useEffect(() => {
    const count = getMarksSelectionCount(marksCount);
    updateAppliedMarksSelectionCount(count);
  }, [marksCount, updateAppliedMarksSelectionCount]);

  return (
    <div className={classes.wrapper} key={resetKey}>
      <div style={{ width: vizContainerWidth }} className={classes.vizContent}>
        {children}
      </div>
      <div
        className={classes.floatingPanel}
        id="floatingPanel"
        style={{
          width: filterPanelContainerWidth,
          display: showFiltersPanel ? 'initial' : 'none',
        }}
      >
        {viz && sections.length > 0 && (
          <Paper className={classes.floatingPaper}>
            <Scrollbars
              style={{ height: filterPanelContainerHeight }}
              autoHide
              renderView={props => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    overflow: 'hidden',
                  }}
                />
              )}
            >
              <div className={classes.closeButton}>
                <Icon name="Close" onClick={toggleFiltersPanel} />
              </div>
              {isLoading && (
                <div className={classes.loader}>
                  <Loader />
                </div>
              )}
              <DeferLoadInteractionSections sections={sections} />
            </Scrollbars>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default FullReportWithFloatingPanel;
