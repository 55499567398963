import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SelectOption, MenuSelect, Icon } from '@jll-labs/azara-ui-components';
import {
  useGetUserDefaultTenantId,
  useGetUserInfo,
  UserOnboardingStatus,
  useUpdateUserTenantRegularSettings,
} from '@apollo-red/hooks';
import { AppTheme } from '@theme';
import OnboardingStatusOption from './OnboardingStatusOption';

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.action.selected,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    textTransform: 'none',
  },
  icon: {
    color: theme.palette.action.active,
  },
}));

const OnboardingStatus: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultTenantId = useGetUserDefaultTenantId();
  const {
    user,
    onboarding: { setRemindOnboarding, handleSetSkipOnboarding },
  } = useGetUserInfo();
  const { updateUserTenantRegularSettings } =
    useUpdateUserTenantRegularSettings();

  const handleChange = async (opt: SelectOption) => {
    handleSetSkipOnboarding(user.id, false);
    await updateUserTenantRegularSettings(defaultTenantId, {
      user_onboarding: {
        status: opt.value as UserOnboardingStatus,
      },
    });
    if (opt.value === UserOnboardingStatus.Skipped) {
      setRemindOnboarding(false);
    }
  };

  return (
    <Hidden lgDown={true}>
      <MenuSelect
        onChange={handleChange}
        options={[
          {
            label: (
              <OnboardingStatusOption
                label={t('rerunOnboarding')}
                icon="CheckCircle"
              />
            ),
            value: UserOnboardingStatus.InProgress,
          },
          {
            label: (
              <OnboardingStatusOption
                label={t('remindOnboarding')}
                icon="AccessAlarm"
              />
            ),
            value: UserOnboardingStatus.Skipped,
          },
          {
            label: (
              <OnboardingStatusOption
                label={t('skipOnboarding')}
                icon="Cancel"
              />
            ),
            value: UserOnboardingStatus.Completed,
          },
        ]}
        value={null}
        getButton={({ handleBtnClick }) => (
          <div>
            <Button
              className={classes.root}
              startIcon={<Icon name="Contacts" className={classes.icon} />}
              onClick={handleBtnClick}
            >
              <div>{t('pendingUserOnboarding')}</div>
            </Button>
          </div>
        )}
      />
    </Hidden>
  );
};

export default OnboardingStatus;
