import { TenantAlertSettingsType } from '@apollo-red/schema/generated/operations';
import { InsightsAlertTypeFilter } from '../insightsAlerts.types';

export const tenantAlertSettingsTypeToAlertTypeFilter: {
  [key in TenantAlertSettingsType]: InsightsAlertTypeFilter | undefined;
} = {
  [TenantAlertSettingsType.FacilitiesExperience]:
    InsightsAlertTypeFilter.FacilitiesExperience,
  [TenantAlertSettingsType.WorkorderVolume]:
    InsightsAlertTypeFilter.WorkorderVolume,
  [TenantAlertSettingsType.LeaseEvent]: InsightsAlertTypeFilter.LeaseEvent,
  [TenantAlertSettingsType.Covid_19WorkOrder]:
    InsightsAlertTypeFilter.Covid_19WorkOrder,
  [TenantAlertSettingsType.MarketBenchmark]:
    InsightsAlertTypeFilter.MarketBenchmark,
  [TenantAlertSettingsType.OccupancyEfficiency]:
    InsightsAlertTypeFilter.OccupancyEfficiency,
  [TenantAlertSettingsType.SubleaseRisk]: InsightsAlertTypeFilter.SubleaseRisk,
  // FIXME to be updated at activation
  [TenantAlertSettingsType.SameDayWorkOrderCompletion]: undefined,
};
