import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  useAppMutation,
  useAppLazyQuery,
  useAppQuery,
} from '@apollo-red/hooks/queries';
import { DELETE_USER_PERSONA, ADD_USER_PERSONA } from '@apollo-red/mutations';
import { GET_USER_DATA, GET_USER_PERSONAS } from '@apollo-red/queries';
import { useGetUserDefaultTenantId } from '../settings';
import { useGetUserInfo } from '../user';

export type UserPersona = SchemaTypes.UserPersonaBaseFragment;

/* eslint-disable-next-line */
export import UserProfileType = SchemaTypes.UserProfileType;

export const useGetUserPersonas = () => {
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();
  const { loading, data } = useAppQuery<
    SchemaTypes.GetUserPersonasQuery,
    SchemaTypes.GetUserPersonasQueryVariables
  >(GET_USER_PERSONAS, {
    variables: {
      where: { id: user.id },
      tenant_ids: [defaultTenantId],
    },
  });

  return {
    loading,
    personas: data?.getUser?.user_personas || [],
  };
};

export const useGetUserCurrentPersona = () => {
  const { user } = useGetUserInfo();
  const { personas } = useGetUserPersonas();

  return { persona: personas.find(p => p.id === user.id) };
};

export const useGetUserMainPersona = () => {
  const { personas } = useGetUserPersonas();
  return {
    persona: personas.find(p => p.profile_type === UserProfileType.Main),
  };
};

export const useSwitchUser = () => {
  const [switchUser, { data, loading }] = useAppLazyQuery<
    SchemaTypes.GetUserQuery,
    SchemaTypes.GetUserQueryVariables
  >(GET_USER_DATA);

  return {
    user: data?.getUser,
    loading,
    switchUser: (id: string) => {
      return switchUser({ variables: { where: { id } } });
    },
  };
};

export const useDeleteUserPersona = () => {
  const defaultTenantId = useGetUserDefaultTenantId();
  const [deleteUserPersona] = useAppMutation<
    SchemaTypes.DeleteUserPersonaMutation,
    SchemaTypes.DeleteUserPersonaMutationVariables
  >(DELETE_USER_PERSONA);

  return {
    deleteUserPersona: (id: string) => {
      return deleteUserPersona({
        variables: { where: { id }, tenant_ids: [defaultTenantId] },
        update: (cache, { data }) => {
          if (data?.deleteUser) {
            data.deleteUser.user_personas.forEach(p => {
              cache.writeQuery<
                SchemaTypes.GetUserPersonasQuery,
                SchemaTypes.GetUserPersonasQueryVariables
              >({
                query: GET_USER_PERSONAS,
                variables: {
                  where: { id: p.id },
                  tenant_ids: [defaultTenantId],
                },
                data: {
                  getUser: { ...data.deleteUser, id: p.id },
                },
              });
            });
          }
        },
      });
    },
  };
};

export const useAddUserPersona = () => {
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();
  const [addUserPersona] = useAppMutation<
    SchemaTypes.AddUserPersonaMutation,
    SchemaTypes.AddUserPersonaMutationVariables
  >(ADD_USER_PERSONA);

  return {
    addUserPersona: (name: string, description?: string) => {
      return addUserPersona({
        variables: {
          data: {
            user_email: user.user_email,
            user_persona: { tenant_id: defaultTenantId, name, description },
          },
          tenant_ids: [defaultTenantId],
        },
        update: (cache, { data }) => {
          if (data?.addUserPersona) {
            data.addUserPersona.user_personas.forEach(p => {
              cache.writeQuery<
                SchemaTypes.GetUserPersonasQuery,
                SchemaTypes.GetUserPersonasQueryVariables
              >({
                query: GET_USER_PERSONAS,
                variables: {
                  where: { id: p.id },
                  tenant_ids: [defaultTenantId],
                },
                data: {
                  getUser: { ...data.addUserPersona, id: p.id },
                },
              });
            });
          }
        },
      });
    },
  };
};
