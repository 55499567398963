import { useMemo } from 'react';
import { UseClientSettingsRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useKeywordsTenantAdminRoutes = ({
  keywords: { getLink, path },
}: UseClientSettingsRoutes) =>
  useMemo(
    () => ({
      dashboards: getCommonRoute(getLink(), 'dashboards', path),
    }),
    [getLink, path],
  );
