import * as React from 'react';
import { useLocation } from 'react-router-dom';

export enum HelpPortalLinkType {
  Home = 'Home',
  Dashboard = 'Dashboards',
  Kpi = 'KPIs',
  Alert = 'Alerts',
  MyAccount = 'My Account',
  ClientSettings = 'Client Settings',
  DataManagement = 'Data Loads',
  Faq = 'FAQs',
  Portscape = 'Portscape',
  SelfServeAnalytics = 'Self-Serve Analytics',
}

export const useHelpPortalLinkType = (type?: HelpPortalLinkType) => {
  const location = useLocation();

  const linkType = React.useMemo(() => {
    if (type) {
      return type;
    }

    const { pathname } = location;
    const [_, __, ...parts] = pathname.split('/');
    const testPath = parts.join('/');

    if (testPath.startsWith('dashboards/')) {
      return HelpPortalLinkType.Dashboard;
    } else if (testPath.startsWith('alerts/')) {
      return HelpPortalLinkType.Alert;
    } else if (testPath.startsWith('settings/')) {
      return HelpPortalLinkType.MyAccount;
    } else if (testPath.startsWith('client-settings/')) {
      return HelpPortalLinkType.ClientSettings;
    } else if (testPath.startsWith('data-management/')) {
      return HelpPortalLinkType.DataManagement;
    }

    return HelpPortalLinkType.Home;
  }, [location, type]);

  return linkType;
};
