import { usePendoTrackEvent } from '@utils/pendo';
import { PendoTrackEventType, PendoTrackEventMetadata } from './types';

export const usePendoTrackKpiHiddenEvent = () => {
  const { trackEvent } = usePendoTrackEvent();
  const trackKpiHiddenEvent = <
    T extends Extract<
      PendoTrackEventType,
      'kpiHiddenClient' | 'kpiUnhiddenClient'
    >,
  >(
    trackEventType: T,
    trackEventMetadata: PendoTrackEventMetadata<T>,
  ) => {
    trackEvent(trackEventType, trackEventMetadata);
  };

  return { trackKpiHiddenEvent };
};
