import * as React from 'react';
import { MessageType } from '@jll-labs/azara-ui-components';
import Snackbar from './Snackbar';
import Dialog from './Dialog';

export type SuccessMessageType = 'default' | 'saved' | 'warning';

export interface MessageData {
  type: MessageType;
  text?: string;
  description?: string;
  successType?: SuccessMessageType;
}

export interface MessageContextType {
  setAppMessage: (message: MessageData) => void;
  resetAppMessage: () => void;
}

export const MessageContext = React.createContext<MessageContextType>({
  setAppMessage: () => {},
  resetAppMessage: () => {},
});

export const useAppMessage = () => {
  return React.useContext(MessageContext);
};

export interface MessageComponentProps {
  message: MessageData;
  handleClose: () => void;
}

const MessageComponents: {
  [key in MessageType]: React.FC<MessageComponentProps>;
} = {
  success: Snackbar,
  warning: Dialog,
  error: Dialog,
  systemError: Dialog,
  information: Dialog,
  confirmation: Dialog,
};

export const changesSavedMsg = (): MessageData => ({
  type: 'success',
  successType: 'saved',
});

/**
 * Provides context for displaying messages. Use provided context methods to display success / warning / error messages.
 * More complex cases should be handled in the components.
 */
export const MessageContextProvider: React.FC<{}> = ({ children }) => {
  const [message, setMessage] = React.useState<MessageData | undefined>();

  const setAppMessage = React.useCallback(
    (message: MessageData) => {
      setMessage(message);
    },
    [setMessage],
  );

  const resetAppMessage = React.useCallback(() => {
    setMessage(undefined);
  }, [setMessage]);

  const MessageComponent = React.useMemo(
    () => (message ? MessageComponents[message.type] : null),
    [message],
  );

  const value = React.useMemo(
    () => ({
      setAppMessage,
      resetAppMessage,
    }),
    [resetAppMessage, setAppMessage],
  );

  return (
    <MessageContext.Provider value={value}>
      {children}
      {message && MessageComponent ? (
        <MessageComponent message={message} handleClose={resetAppMessage} />
      ) : null}
    </MessageContext.Provider>
  );
};
