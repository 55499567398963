import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';
import { InsightsAlertInbox } from '@apollo-red/hooks';
import {
  PageContainerContext,
  ContentContainer,
} from '@components/pageContainer';
import { alertInboxTranslationKeys } from './utils';

interface Props {
  breadcrumbsTitle: string;
  breadcrumbsParts: BreadcrumbsRoutePart[];
  inboxType: InsightsAlertInbox;
}

const AlertsContentWrapper: React.FC<Props> = ({
  breadcrumbsTitle,
  breadcrumbsParts,
  inboxType,
  children,
}) => {
  const { t } = useTranslation();
  const data = React.useContext(PageContainerContext);

  React.useEffect(() => {
    data.setBreadcrumbsData([
      breadcrumbsTitle,
      [{ label: t('alerts') }, ...breadcrumbsParts],
    ]);
  }, [breadcrumbsParts, breadcrumbsTitle, data, t]);

  return (
    <ContentContainer
      title={t(alertInboxTranslationKeys[inboxType])}
      autoOverflow={true}
    >
      {children}
    </ContentContainer>
  );
};

export default AlertsContentWrapper;
