import { useGetUserInfo } from '../../user';

export const useGetUserTenantRegularSettings = (tenantId: string) => {
  const { user, loading } = useGetUserInfo();
  return {
    userTenantRegularSettings: user.user_tenant_regular_settings.find(
      trs => trs.user_tenant.id === tenantId,
    ),
    loading,
  };
};
