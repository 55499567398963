import { PinFilter, PinParameter, ReportFiltersControl } from '../../types';

export const isPinnedControlInReport = (
  pinned: (PinFilter | PinParameter)[] = [],
  reportControls: ReportFiltersControl[] = [],
) => {
  if (pinned.length === 0) {
    return false;
  }

  const hasFilterIds = reportControls.every(control => !!control.filter_id);
  const controlIds = reportControls.map(control => control.filter_id);
  const controlNames = reportControls.map(control => control.name);

  return pinned.some(({ filter_id, name }) =>
    hasFilterIds && filter_id
      ? controlIds.includes(filter_id)
      : controlNames.includes(name),
  );
};
