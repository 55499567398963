import * as React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useRoutes } from '@routes';
import { ActiveUserOnly, TenantFeatureToggle } from '@components/featureToggle';
import AlertReport from './alertReport';
import AlertsContainer from './AlertsContainer';

const InsightsAlerts: React.FC = () => {
  const { insightsAlerts } = useRoutes();

  return (
    <ActiveUserOnly>
      <TenantFeatureToggle hasFeatures={['alerts']}>
        <Switch>
          <Route exact={true} path={insightsAlerts.dashboard.path}>
            <AlertReport />
          </Route>
          <Route exact={true} path={insightsAlerts.home.path}>
            <Redirect to={`${insightsAlerts.home.path}/all`} />
          </Route>
          <Route path={insightsAlerts.inbox.path}>
            <AlertsContainer />
          </Route>
        </Switch>
      </TenantFeatureToggle>
    </ActiveUserOnly>
  );
};

export default InsightsAlerts;
