import * as React from 'react';
import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props {
  tenantName: string;
  loading: boolean;
}

const ClientSelectOption: React.FC<Props> = ({ tenantName, loading }) => {
  const classes = useStyles();
  return (
    <div>
      {tenantName}
      {loading && (
        <CircularProgress
          color="primary"
          size={24}
          className={classes.loader}
        />
      )}
    </div>
  );
};

export default ClientSelectOption;
