import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { Icon } from '@jll-labs/azara-ui-components';
import { severityConfig } from '../utils';
import { AppTheme } from '@theme';

const useStyles = makeStyles((theme: AppTheme) => ({
  label: {
    padding: theme.spacing(0, 1),
  },
  icon: {
    minWidth: '20%',
  },
}));

export const useGetSeverityOptions = () => {
  const classes = useStyles();
  const severityOptions = severityConfig.map((severity, index) => {
    return {
      label: (
        <Grid
          container={true}
          alignItems="center"
          key={index}
          justifyContent="space-between"
        >
          <div>
            <Icon name={severity.icon} fontSize="inherit" />
          </div>

          <div className={classes.label}>{severity.label}</div>
        </Grid>
      ),
      value: severity.value,
    };
  });

  return { severityOptions };
};
