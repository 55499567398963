import { useMemo } from 'react';
import { UseDataManagementRoutes } from '../../types';
import { getCommonRoute } from '../../utils';

export const useDataStandardsDataManagementRoutes = ({
  dataStandards: { getLink, path },
}: UseDataManagementRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      clientValueMapping: getCommonRoute(link, 'client-value-mapping', path),
      dataStandardsErrors: getCommonRoute(link, 'data-standards-errors', path),
    };
  }, [getLink, path]);
