import * as React from 'react';
import { ReportSectionType } from '@apollo-red/hooks';
import { ReportFiltersInteractionSection } from '../types';
import { useFullReportWithFilters } from '../FullReportWithFiltersProvider';
import { useGetFilters, useGetParameters } from '../hooks';
import { getReportControls } from '../utils';

interface UseInteractionSectionsProps {
  sections: ReportFiltersInteractionSection[];
}

export const useInteractionSections = ({
  sections,
}: UseInteractionSectionsProps) => {
  const {
    reportId,
    viz,
    handleFiltersLoading,
    handleParametersLoading,
    hasPinnedData,
    addAppliedControls,
  } = useFullReportWithFilters();
  const { filterControls, parameterControls } = React.useMemo(() => {
    return getReportControls(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);
  const { loading: tableauFiltersLoading, tableauFilters } = useGetFilters(
    reportId,
    filterControls,
    viz,
  );
  const { loading: tableauParametersLoading, tableauParameters } =
    useGetParameters(viz);

  const filterSection: ReportFiltersInteractionSection = {
    enabled: true,
    controls: filterControls,
    id: 'filter-section',
    expanded: true,
    label: 'Filters',
    section_type: ReportSectionType.Filter,
    sort_index: 0,
  };
  const parametersSection = sections.filter(
    section => section.section_type === ReportSectionType.Parameter,
  );
  const showPinHelpMessage = hasPinnedData(filterControls, parameterControls);

  React.useEffect(() => {
    handleFiltersLoading(tableauFiltersLoading);
  }, [handleFiltersLoading, tableauFiltersLoading]);

  React.useEffect(() => {
    handleParametersLoading(tableauParametersLoading);
  }, [handleParametersLoading, tableauParametersLoading]);

  React.useEffect(() => {
    const appliedControls = tableauFilters
      .filter(({ staticData }) => staticData?.length)
      .map(({ name }) => name);
    addAppliedControls(appliedControls);
  }, [addAppliedControls, tableauFilters]);

  return {
    filterSection,
    parametersSection,
    showPinHelpMessage,
    tableauFilters,
    tableauFiltersLoading,
    tableauParameters,
    tableauParametersLoading,
  };
};
