import { InsightsAlertInbox, useGetInsightsAlerts } from '@apollo-red/hooks';
import {
  InsightsAlertImportanceFilter,
  InsightsAlertTypeFilter,
  InsightsAlertSeverityFilter,
} from '../insightsAlerts.types';
import {
  alertInboxToInsightsAlertTab,
  alertImportanceFilterToInsightsAlertImportance,
  alertTypeFilterToTenantAlertSettingsType,
  alertSeverityFilterToInsightsAlertSeverity,
  sortOrderToAlertFeedSort,
} from '../utils';
import { useGetInsightsInboxAlertState } from './useGetInsightsInboxAlertState';

export const useGetInboxInsightsAlerts = (inboxType: InsightsAlertInbox) => {
  const alertTab = alertInboxToInsightsAlertTab[inboxType];

  const {
    userId,
    tenantId,
    hasFeatureEnabled,
    importance,
    alertType,
    sortBy,
    alertAssignedTo,
    alertSeverity,
  } = useGetInsightsInboxAlertState(alertTab);

  const {
    alerts,
    loading,
    loadMore,
    networkStatus,
    hasNextPage,
    continuationToken,
  } = useGetInsightsAlerts(
    userId,
    tenantId,
    hasFeatureEnabled,
    alertImportanceFilterToInsightsAlertImportance[
      importance ?? InsightsAlertImportanceFilter.All
    ],
    alertTypeFilterToTenantAlertSettingsType[
      alertType ?? InsightsAlertTypeFilter.All
    ],
    undefined,
    alertTab,
    sortOrderToAlertFeedSort[sortBy],
    alertSeverityFilterToInsightsAlertSeverity[
      alertSeverity ?? InsightsAlertSeverityFilter.All
    ],
    alertAssignedTo !== 'all' ? alertAssignedTo : undefined,
  );

  return {
    alerts,
    hasNextPage,
    loading,
    loadMore,
    networkStatus,
    continuationToken,
  };
};
