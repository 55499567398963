import * as React from 'react';
import { Typography, useTheme, Box } from '@mui/material';
import { Button } from '@jll-labs/azara-ui-components';
import Portscape from '@assets/static/Portscape.png';
import { useGetUserDefaultTenant } from '@apollo-red/hooks';
import { useFeatureFlag } from '@utils/launchDarkly';

const useStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      display: 'flex',
      marginTop: '150px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftPart: {
      width: '58%',
    },
    imageWrapper: {
      padding: theme.spacing(8),
    },
    image: {
      width: '100%',
      boxShadow: theme.shadows[2],
    },
    rightPart: {
      width: '34%',
      padding: theme.spacing(2, 1),
    },
    description: {
      color: theme.palette.text.secondary,
    },
    descriptionPara: {
      paddingTop: theme.spacing(1),
    },
    unorderedList: {
      paddingInlineStart: '20px',
    },
  };
};

const Landing: React.FC<{}> = () => {
  const styles = useStyles();
  const { defaultTenant } = useGetUserDefaultTenant();
  const isPortscapeEnabled =
    useFeatureFlag('productSettingsClientsPortscapeAccess') &&
    !!defaultTenant?.tenant_features?.portscape;
  const portscapeBaseUrl = process.env.PORTSCAPE_URI;

  if (!isPortscapeEnabled || !portscapeBaseUrl) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.leftPart}>
        <Box sx={styles.imageWrapper}>
          <Box
            component="img"
            src={Portscape}
            sx={styles.image}
            alt="Portscape"
          />
        </Box>
      </Box>
      <Box sx={styles.rightPart}>
        <Typography variant="h4">
          Strategic Portfolio Management with Portscape
        </Typography>
        <Box sx={styles.description}>
          <Box sx={styles.descriptionPara}>
            <Typography variant="h5">
              Portscape enables you to turn portfolio insights into actions and
              take a strategic approach towards:
            </Typography>
          </Box>

          <Typography variant="h6">
            <Box component="ul" sx={styles.unorderedList}>
              <li>Portfolio Planning</li>
              <li>Management and execution</li>
              <li>Monitor progress towards targets</li>
            </Box>
          </Typography>
        </Box>
        <Button
          color="primary"
          icon="Launch"
          variant="contained"
          onClick={() => {
            window.open(
              `${portscapeBaseUrl}/${defaultTenant?.tenant_ovc_id}`,
              '_blank',
            );
          }}
        >
          Open Portscape
        </Button>
      </Box>
    </Box>
  );
};

export default Landing;
