export enum ButtonGroup {
  'DOWNLOAD' = 'download',
}

export interface ToolbarButton {
  name: string;
  icon: React.ReactNode;
  onClick: () => void;
  component?: React.ReactNode | boolean;
  buttonGroup?: ButtonGroup;
  exclude?: boolean;
  tooltip?: string;
}
