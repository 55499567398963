import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardProps } from '../types';
import BaseDashboards from './BaseDashboards';

const BaseSavedDashboards: React.FC<DashboardProps> = ({
  reports,
  useSaveHandler,
  drag,
  dashboardRoutes,
}) => {
  const { t } = useTranslation();

  return (
    <BaseDashboards
      reports={reports}
      title={t('mySaved')}
      breadcrumbsParts={[{ label: t('mySaved') }]}
      breadcrumbsTitle={t('dashboards')}
      dashboardRoutes={dashboardRoutes}
      drag={drag}
      autoOverflow={true}
      useSaveHandler={useSaveHandler}
      categoryId="saved"
    />
  );
};

export default BaseSavedDashboards;
