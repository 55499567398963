import { useAppQuery } from '@apollo-red/hooks/queries';
import { GET_QA_TENANTS } from '@apollo-red/queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { TenantQaType } from './types';

export const useGetQaTenants = (tenantQaType: TenantQaType, skip?: boolean) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetQaTenantsQuery,
    SchemaTypes.GetQaTenantsQueryVariables
  >(GET_QA_TENANTS, {
    variables: {
      tenant_qa_type: tenantQaType,
    },
    skip,
  });

  const tenants = data?.getQaTenants ?? [];
  const qaTenants = [...tenants].sort((t, t2) =>
    t.tenant_name.localeCompare(t2.tenant_name),
  );

  return {
    loading,
    qaTenants,
  };
};
