import * as React from 'react';
import { Box } from '@mui/material';
import { Viz } from '@components/tableau/types';
import { FullReportViz, FullReportContextProvider } from '../fullReport';
import { default as ExternalFullReportToolbar } from './ExternalFullReportToolbar';
import { ExternalReportProps } from './types';

const ExternalFullReport: React.FC<ExternalReportProps> = ({ report }) => {
  const [viz, setViz] = React.useState<Viz | undefined>(undefined);
  return (
    <Box marginTop={3}>
      <FullReportContextProvider report={report}>
        <ExternalFullReportToolbar viz={viz} report={report} />
        <FullReportViz
          reportId={report.id}
          reportUrl={report.report_external_url ?? ''}
          reportName={report.report_name}
          reportCategory={report.primaryCategory}
          setViz={setViz}
          applyUniversalFilters={{
            basic: !report.isSavedView,
            geographic: !report.isSavedView,
          }}
          isExternalReport={true}
          showLastUpdateDate={false}
        />
      </FullReportContextProvider>
    </Box>
  );
};

export default ExternalFullReport;
