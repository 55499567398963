import * as React from 'react';
import { InsightsAlertSeverity } from '@apollo-red/hooks';
import { Tooltip, Icon } from '@jll-labs/azara-ui-components';
import { Box } from '@mui/material';
import { severityConfig } from '../utils';
import { useAlertTableRowStyles } from '../alertTableRows/useAlertTableRowStyles';

interface Props {
  severity?: InsightsAlertSeverity;
}

const AlertSeverityFlag: React.FC<Props> = ({ severity }) => {
  const styles = useAlertTableRowStyles();

  if (!severity) {
    return null;
  }

  const alertSeverity = severityConfig.find(s => s.value === severity);

  if (alertSeverity?.value === InsightsAlertSeverity.None) {
    return null;
  }

  return (
    <Box sx={styles.iconContainer}>
      <Tooltip title={alertSeverity?.label || ''}>
        {alertSeverity && <Icon name={alertSeverity?.icon} fontSize="small" />}
      </Tooltip>
    </Box>
  );
};

export default AlertSeverityFlag;
