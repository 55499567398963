import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  ADD_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS,
  UPDATE_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS,
  DELETE_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS,
  SYNC_TENANT_UNPUBLISHED_REPORTS,
  UPDATE_TENANT_CUSTOM_REPORTS_SETTINGS,
} from '@apollo-red/mutations';
import { filterItems } from '@utils/various';
import { useAppMutation } from '../queries';
import { Report, ReportExtended } from '..';
import { useGetTenant, getTenantFromQuery } from './tenants';

export enum TenantCustomReportsSetupStatus {
  NotStarted = 'not-started',
  Done = 'done',
  Error = 'error',
}

export type TenantCustomReportsSettings =
  SchemaTypes.TenantCustomReportsSettingsBaseFragment;

export type TenantCustomReportsSetup =
  SchemaTypes.TenantCustomReportsSetupBaseFragment;

export type TenantSelfServiceAnalyticsSettings =
  SchemaTypes.TenantSelfServeAnalyticsSettingsBaseFragment;

const fallbackCustomReportsSetup: TenantCustomReportsSetup = {
  tenant_snowflake_urls: [
    {
      id: '',
      tenant_snowflake_url: '',
    },
  ],
  tenant_snowflake_locator: '',
  tenant_snowflake_database: '',
  tenant_snowflake_share: '',
  tenant_tableau_project_custom_site_url: '',
  tenant_snowflake_setup_status: TenantCustomReportsSetupStatus.NotStarted,
  tenant_snowflake_share_consumers: [],
};

export const isTenantCustomReportsSetupCompleted = (
  customReportsSettings: TenantCustomReportsSettings,
) => {
  return (
    customReportsSettings.tenant_custom_reports_setup
      ?.tenant_snowflake_setup_status === TenantCustomReportsSetupStatus.Done
  );
};

const getCustomReportSettings = (
  customReportsSettings?: TenantCustomReportsSettings | null,
) => {
  return {
    tenant_custom_reports_setup:
      customReportsSettings?.tenant_custom_reports_setup ??
      fallbackCustomReportsSetup,
    tenant_custom_reports: filterItems(
      customReportsSettings?.tenant_custom_reports ?? [],
    ),
  };
};

export const useGetTenantCustomReportsSettings = (tenantId: string) => {
  const query = useGetTenant(tenantId);

  return {
    loading: query.loading,
    refetchCustomReportsSettings: async () => {
      const refetchedQuery = await query.refetch();
      return getCustomReportSettings(
        getTenantFromQuery(tenantId, refetchedQuery.data)
          ?.tenant_custom_reports_settings,
      );
    },
    customReportsSettings: getCustomReportSettings(
      query.tenant?.tenant_custom_reports_settings,
    ),
  };
};

export const useGetTenantSelfServeAnalyticsSettings = (tenantId: string) => {
  const query = useGetTenant(tenantId);
  const selfServeAnalyticsSettings: TenantSelfServiceAnalyticsSettings = query
    ?.tenant?.tenant_custom_reports_settings
    ?.tenant_self_serve_analytics_settings ?? {
    self_serve_analytics_enabled: false,
    self_serve_analytics_url: '',
  };

  return {
    loading: query.loading,
    selfServeAnalyticsSettings,
  };
};

const customReportExtended = (report?: Report): ReportExtended | undefined => {
  if (!report) {
    return;
  }

  const { __typename, ...rest } = report;
  const { report_name, report_original_name } = rest;

  return {
    ...rest,
    report_original_name: report_original_name ?? report_name,
    primaryCategory: '',
    originalCategory: '',
    isSavedView: false,
    isCustomReport: true,
    isFav: false,
    isHidden: false,
    isAlertReport: false,
    isAuthReport: false,
    isKpiReport: false,
    isPatReport: false,
    isExternalReport: false,
  };
};

export const useGetTenantCustomReportById = (
  tenantId: string,
  reportId: string,
) => {
  const { customReportsSettings, loading } =
    useGetTenantCustomReportsSettings(tenantId);

  const report = customReportsSettings.tenant_custom_reports.find(r => {
    return r.id === reportId;
  });
  const customReport = customReportExtended(report);

  return {
    customReport,
    loading,
  };
};

export const useSyncTenantUnpublishedReports = () => {
  const [syncTenantUnpublishedReports] =
    useAppMutation<SchemaTypes.SyncTenantUnpublishedReportsMutation>(
      SYNC_TENANT_UNPUBLISHED_REPORTS,
    );

  return {
    syncTenantCustomReports: (id: string) => {
      return syncTenantUnpublishedReports({
        variables: {
          where: { id },
        },
      });
    },
  };
};

export const useUpdateTenantCustomReportsSettings = () => {
  const [updateTenantCustomReportsSettings] =
    useAppMutation<SchemaTypes.UpdateTenantCustomReportsMutation>(
      UPDATE_TENANT_CUSTOM_REPORTS_SETTINGS,
    );

  return {
    updateTenantCustomReportsSettings: (
      id: string,
      payload: Partial<TenantCustomReportsSettings>,
    ) => {
      return updateTenantCustomReportsSettings({
        variables: {
          data: {
            tenant_custom_reports_settings: {
              ...payload,
            },
          },
          where: {
            id,
          },
        },
      });
    },
  };
};

export const useAddTenantDataShareForCustomReports = () => {
  const [addTenantDataShareForCustomReports] = useAppMutation<
    SchemaTypes.AddTenantDataShareForCustomReportsMutation,
    SchemaTypes.AddTenantDataShareForCustomReportsMutationVariables
  >(ADD_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS);

  return {
    addTenantDataShareForCustomReports: (id: string, snowflakeUrl: string) => {
      return addTenantDataShareForCustomReports({
        variables: {
          where: {
            id,
          },
          data: { snowflake_url: snowflakeUrl },
        },
      });
    },
  };
};

export const useUpdateTenantDataShareForCustomReports = () => {
  const [updateTenantDataShareForCustomReports] = useAppMutation<
    SchemaTypes.UpdateTenantDataShareForCustomReportsMutation,
    SchemaTypes.UpdateTenantDataShareForCustomReportsMutationVariables
  >(UPDATE_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS);

  return {
    updateTenantDataShareForCustomReports: (
      id: string,
      accountId: string,
      snowflakeUrl: string,
    ) => {
      return updateTenantDataShareForCustomReports({
        variables: {
          where: {
            id,
          },
          data: { snowflake_url: snowflakeUrl, id: accountId },
        },
      });
    },
  };
};

export const useDeleteTenantDataShareForCustomReports = () => {
  const [deleteTenantDataShareForCustomReports] = useAppMutation<
    SchemaTypes.DeleteTenantDataShareForCustomReportsMutation,
    SchemaTypes.DeleteTenantDataShareForCustomReportsMutationVariables
  >(DELETE_TENANT_DATA_SHARE_FOR_CUSTOM_REPORTS);

  return {
    deleteTenantDataShareForCustomReports: (id: string, accountId: string) => {
      return deleteTenantDataShareForCustomReports({
        variables: {
          where: {
            id,
          },
          data: { id: accountId },
        },
      });
    },
  };
};
