import * as React from 'react';
import {
  InsightsAlertScope,
  InsightsAlertBaseMarketBenchmarkTemplateData,
  InsightsAlertInbox,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { useFeatureFlag } from '@utils/launchDarkly';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { formatDate, getInternalLink } from './utils';
import { useAlertTextStyles } from './useAlertTextStyles';
import OpenAlertReport from './OpenAlertReport';
import { useRoutes } from '@routes';

interface MarketBenchmarkAlertProps {
  template: InsightsAlertBaseMarketBenchmarkTemplateData;
  boldText?: boolean;
  inboxType?: InsightsAlertInbox;
  threadId: string;
  alertType: InsightsAlertType;
}

const MarketBenchmarkAlert: React.FC<MarketBenchmarkAlertProps> = ({
  template,
  boldText,
  threadId,
  inboxType,
  alertType,
}) => {
  const {
    event_type,
    property_name,
    event_created_on,
    base_rent,
    prediction_record,
    tenure_name,
    property_type,
    tenure_type,
    tenure_size,
  } = template;
  const classes = useAlertTextStyles({ boldText });
  const isLinkEnabled = useFeatureFlag('insightsAlertsLeaseCostDashboard');
  const routes = useRoutes();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  return (
    <OpenAlertReport>
      <div className={classes.fontWeight}>
        You have a/n <b>{event_type}</b> at your <b>{property_name}</b> property
        on <b>{formatDate(event_created_on)}</b>. Your current cost is{' '}
        <b>${parseInt(base_rent ?? '', 10).toFixed(2)}/SF (annual)</b>. Our
        analysis suggests market rent for similar properties is between{' '}
        <b>${(prediction_record?.low_range ?? 0).toFixed(2)}/SF</b> -{' '}
        <b>${(prediction_record?.high_range ?? 0).toFixed(2)}/SF (annual)</b>.
        <ul>
          <li>Tenure Name: {tenure_name} </li>
          <li>Property Type: {property_type}</li>
          <li>Tenure Type: {tenure_type}</li>
          <li>Tenure Size: {tenure_size}</li>
        </ul>
        {inboxType && isLinkEnabled && (
          <>
            See notification details{' '}
            <a
              onClick={() => {
                trackInsightsAlertEvent('alertShowNotificationDetails', {
                  alertThreadId: threadId ?? '',
                  alertType: alertType ?? '',
                });
              }}
              href={getInternalLink(
                threadId,
                inboxType,
                InsightsAlertScope.Internal,
                routes,
              )}
              className={classes.anchorText}
            >
              here
            </a>
          </>
        )}
      </div>
    </OpenAlertReport>
  );
};

export default MarketBenchmarkAlert;
