import { makeStyles } from '@mui/styles';
import { AppTheme } from '@theme';

interface StyleProps {
  showFiltersPanel?: boolean;
  isLoading: boolean;
  isVizLoading?: boolean;
}

export const useFloatingPanelStyles = makeStyles((theme: AppTheme) => ({
  wrapper: ({ showFiltersPanel, isVizLoading }: StyleProps) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    marginRight: !isVizLoading
      ? showFiltersPanel
        ? 0
        : theme.spacing(6)
      : theme.spacing(6),
    height: '100%',
  }),
  floatingPanel: ({ showFiltersPanel }: StyleProps) => ({
    marginRight: theme.spacing(0.4),
    position: 'relative',
    left: showFiltersPanel ? '0%' : '22%',
  }),
  floatingPaper: {
    borderRadius: 0,
    position: 'relative',
  },
  sections: ({ isLoading }: StyleProps) => ({
    display: isLoading ? 'none' : 'inherit',
  }),
  loader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  vizContent: ({ isVizLoading }: StyleProps) => ({
    marginRight: isVizLoading ? theme.spacing(6) : 0,
  }),
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1.8),
    zIndex: 100,
    right: theme.spacing(3),
    cursor: 'pointer',
  },
}));
