import * as React from 'react';
import { useFeatureFlag } from '@utils/launchDarkly';
import { Dashboard, SheetType, Viz } from '@components/tableau/types';

export const useGetFiltersSheet = (viz?: Viz) => {
  const filtersOnFirstWorksheet = useFeatureFlag(
    'dashboardsFiltersSourceFromFirstWorksheet',
  );

  return React.useMemo(() => {
    if (!viz) {
      return;
    }

    const activeSheet = viz.getWorkbook().getActiveSheet();
    const activeSheetType = activeSheet.getSheetType();

    if (activeSheetType !== SheetType.DASHBOARD) {
      return activeSheet;
    }

    const dashboard = activeSheet as Dashboard;
    const worksheets = dashboard.getWorksheets();

    if (worksheets.length === 0) {
      return activeSheet;
    }

    return filtersOnFirstWorksheet
      ? worksheets.find(w => w.getName() !== 'DataAvailabilitySheet') ??
          worksheets[0]
      : activeSheet;
  }, [filtersOnFirstWorksheet, viz]);
};
