import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AutoSizer } from '@jll-labs/azara-ui-components';
import { AppTheme } from '@theme';
import { ReportExtended } from '@apollo-red/hooks';
import { TableauLicenseValidator } from '@components/tableau';
import FullReportContextProvider from '../FullReportContextProvider';
import FullReportWithFiltersProvider from './FullReportWithFiltersProvider';
import FullReportWithFiltersWrapper from './FullReportWithFiltersWrapper';

interface Props {
  report: ReportExtended;
  isCoreDashboard?: boolean;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    height: `calc(100vh - 250px)`,
    marginTop: theme.spacing(3),
  },
}));

const FullReportWithFilters: React.FC<Props> = ({
  report,
  isCoreDashboard,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <FullReportContextProvider report={report}>
              <TableauLicenseValidator>
                <FullReportWithFiltersProvider
                  width={width}
                  height={height}
                  reportId={report.id}
                  isCoreDashboard={isCoreDashboard}
                >
                  <FullReportWithFiltersWrapper report={report} />
                </FullReportWithFiltersProvider>
              </TableauLicenseValidator>
            </FullReportContextProvider>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default FullReportWithFilters;
