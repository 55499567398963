/**
 * NOTE: Categories correspond to dashboard tags in Tableau Server.
 */

/**
 * Category of dashboards related to KPIs. E.g. Dashboards with detailed data.
 */
export const KPI_BANNER_CATEGORY = 'banner';

/**
 * Category of login dashboard.
 */
export const LOGIN_DASHBOARD_CATEGORY = 'First';

/**
 * Category of alert dashboards.
 */
export const BY_REQUEST_ONLY_CATEGORY = 'By Request Only';

/**
 * Name of workbook which contains dashboards for details of portfolio size and properties.
 */
export const KPI_PORTFOLIO_DETAILS_WORKBOOK = 'PortfolioMetricsDetail';

/**
 * Name of dashboard with details of portfolio size.
 */
export const KPI_PORTFOLIO_DETAILS_AREA = 'AreaSQFdashboard';

/**
 * Name of dashboard with details of portfolio properties.
 */
export const KPI_PORTFOLIO_DETAILS_PROPERTIES = 'Propertydashboard';

/**
 * Name of workbook which contains dashboards for details of portfolio cost.
 */
export const KPI_COST_WORKBOOK = 'CostdashboardDetailed';

/**
 * Default PAT Dashboard Category when provisioned without valid Report Metadata.
 */
export const PAT_TAG = 'PAT';
