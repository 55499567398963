import * as React from 'react';
import { ReportControlStaticListItem } from '@apollo-red/hooks';
import { Option } from '../../controls';
import { useFullReportWithFilters } from '../../FullReportWithFiltersProvider';
import { ApplyFilterProps, FilterExtended, PinFilter } from '../../types';
import {
  getFilterUpdateType,
  getInitialFilterAppliedValues,
  getInitialFilterState,
  getPinnedFilters,
  getUpdatedFilters,
  getSelectedOptionFromStaticData,
  updatePinnedFilters,
} from '../../utils';

export const useCategoricalFilter = (
  filter: FilterExtended,
  applyFilter: ApplyFilterProps,
) => {
  const {
    addAppliedControls,
    id,
    isCoreDashboard,
    pinnedData,
    removeAppliedControls,
    updatePinnedData,
  } = useFullReportWithFilters();
  const pinnedFilters = getPinnedFilters(pinnedData, isCoreDashboard, id);
  const initialFilter = isCoreDashboard
    ? pinnedFilters?.find(f => f.filter_id === filter.filter_id)
    : pinnedFilters?.find(f => f.name === filter.name);
  const [filterState, setFilterState] = React.useState<PinFilter>(
    getInitialFilterState(filter, initialFilter),
  );

  const updatedPinnedData = React.useCallback(() => {
    const updatedFilters = getUpdatedFilters(filterState, pinnedFilters);
    const updatedReportFilterData = updatePinnedFilters(
      pinnedData,
      updatedFilters,
      isCoreDashboard,
      id,
    );

    updatePinnedData(updatedReportFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const applyFilterValues = React.useCallback(
    ({ name, selectedValues, isExcluded }: PinFilter) => {
      const selectedFilterValues = selectedValues?.reduce(
        (acc, selectedValue) => {
          return [...acc, selectedValue.value];
        },
        [],
      );
      const updateType = getFilterUpdateType(selectedFilterValues || []);
      const areFiltersCleared =
        selectedFilterValues && selectedFilterValues?.length === 0;

      if (areFiltersCleared) {
        removeAppliedControls(name);
      } else {
        addAppliedControls([name]);
      }

      applyFilter(
        name,
        selectedFilterValues || [],
        updateType,
        isExcluded || false,
      );
    },
    [addAppliedControls, applyFilter, removeAppliedControls],
  );

  const handlePinUnpin = React.useCallback(() => {
    const nextState: PinFilter = {
      ...filterState,
      isPinned: !filterState.isPinned,
    };
    setFilterState(nextState);
  }, [filterState]);

  const handleIncludeExclude = React.useCallback(
    (isExcluded: boolean) => {
      const nextState: PinFilter = {
        ...filterState,
        isExcluded,
      };

      setFilterState(nextState);

      if (nextState.selectedValues && nextState.selectedValues?.length > 0) {
        applyFilterValues(nextState);
      }
    },
    [applyFilterValues, filterState],
  );

  const updateFilter = React.useCallback(
    (values: ReportControlStaticListItem[]) => {
      const nextState: PinFilter = {
        ...filterState,
        selectedValues: values,
      };

      setFilterState(nextState);
      applyFilterValues(nextState);
    },
    [applyFilterValues, filterState],
  );

  React.useEffect(() => {
    if (filterState.isPinned || initialFilter?.isPinned) {
      updatedPinnedData();
    }
  }, [filterState, initialFilter?.isPinned, updatedPinnedData]);

  React.useEffect(() => {
    if (filterState.isPinned || initialFilter?.isPinned) {
      return;
    }

    if (!filter.isAllSelected) {
      const initialAppliedValues = getInitialFilterAppliedValues(filter);

      if (initialAppliedValues) {
        updateFilter(initialAppliedValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClear = React.useCallback(() => {
    const nextState: PinFilter = {
      ...filterState,
      isPinned: false,
      selectedValues: filter.isUniversalFilter
        ? filterState.staticData?.filter(
            ({ isPreselectedUniversalFilterValue }) =>
              isPreselectedUniversalFilterValue,
          )
        : [],
    };

    setFilterState(nextState);
    applyFilterValues(nextState);
  }, [applyFilterValues, filter.isUniversalFilter, filterState]);

  const filterOptions: Option[] = React.useMemo(() => {
    return (
      filter?.appliedValues?.map(({ formattedValue }) => ({
        label: formattedValue,
        value: formattedValue,
      })) ?? []
    );
  }, [filter?.appliedValues]);
  const selectedOption = React.useMemo(
    () => getSelectedOptionFromStaticData(filter.static_data),
    [filter.static_data],
  );

  return {
    filterOptions,
    filterState,
    handleClear,
    handleIncludeExclude,
    handlePinUnpin,
    selectedOption,
    updateFilter,
  };
};
