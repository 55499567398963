import * as React from 'react';
import { v4 as uuid } from 'uuid';
import {
  ReportExtended,
  LogReportType,
  useLogPerformance,
  useGetUserDefaultTenantId,
  useReportMetadata,
} from '@apollo-red/hooks';
import { usePropsUniversalFilters } from '@components/universalFilters/usePropsUniversalFilters';
import {
  useTableauUrlWithRefresh,
  useTableauUrlWithTrustedAuth,
} from '@utils/tableau';
import { useIsMountedRef } from '@utils/various';

interface UseThumbnailImageProps {
  report: ReportExtended;
  skipImage?: boolean;
}

export const useThumbnailImage = ({
  report,
  skipImage = false,
}: UseThumbnailImageProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [initTimestamp] = React.useState(() => new Date().getTime());
  const [retryId, setRetryId] = React.useState('');
  const [isRetrying, setIsRetrying] = React.useState(false);
  const { logPerformance } = useLogPerformance();
  const tenantId = useGetUserDefaultTenantId();
  const {
    isCustomReport,
    isExternalReport,
    isSavedView,
    report_tableau_thumbnail_url,
    report_external_thumbnail_url: reportExternalThumbnailUrl,
    id: reportId,
  } = report;
  const isMountedRef = useIsMountedRef();
  const universalFilters = usePropsUniversalFilters({
    isBasic: !isSavedView,
    isGeographic: !isSavedView,
  });
  const reportMetadata = useReportMetadata(report);
  const urlWithRefresh = useTableauUrlWithRefresh({
    url: report_tableau_thumbnail_url,
    filters: universalFilters,
    asImage: true,
  });
  const url = useTableauUrlWithTrustedAuth({
    tenantId,
    reportId,
    url: urlWithRefresh,
    disableTrustedAuth: isCustomReport || isExternalReport,
    skip: skipImage,
    retryId,
    retry: isRetrying,
  });
  const shouldUseFallbackImage =
    (isCustomReport && !url) ||
    (isExternalReport && !reportExternalThumbnailUrl);

  React.useEffect(() => {
    if (isMountedRef.current) {
      setIsRetrying(false);
    }
  }, [isMountedRef, url]);

  const handleLoaded = React.useCallback(() => {
    const currentTimestamp = new Date().getTime();
    const duration = currentTimestamp - initTimestamp;

    logPerformance({
      duration,
      context: {
        report_type: LogReportType.Thumbnail,
        report_id: reportId,
        tenant_id: tenantId,
      },
    });

    setIsLoading(false);
    setIsError(false);
  }, [initTimestamp, logPerformance, reportId, tenantId]);

  const handleError = React.useCallback(() => {
    setIsLoading(false);
    setIsError(true);
  }, []);

  const handleRetry = React.useCallback(() => {
    setRetryId(uuid());
    setIsRetrying(true);
    setIsLoading(true);
    setIsError(false);
  }, []);

  return {
    handleError,
    handleLoaded,
    handleRetry,
    isError,
    isLoading,
    isRetrying,
    reportExternalThumbnailUrl,
    reportId,
    shouldUseFallbackImage,
    staticThumbnailUrl: reportMetadata?.image_url,
    thumbnailUrl: url ? url : reportExternalThumbnailUrl ?? undefined,
    url,
    urlWithRefresh,
  };
};
