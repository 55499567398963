import produce from 'immer';
import { ReportSectionType } from '@apollo-red/hooks';
import {
  ReportFilterData,
  ReportType,
  PinParameter,
  PinFilter,
} from '../types';

export const getPinnedParameters = (
  data: ReportFilterData,
  isCoreDashboard: boolean,
  id: string,
): PinParameter[] => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;
  return data?.[id]?.[reportType]?.Parameter ?? [];
};

export const getPinnedFilters = (
  data: ReportFilterData,
  isCoreDashboard: boolean,
  id: string,
): PinFilter[] => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;
  return data?.[id]?.[reportType]?.Filter ?? [];
};

export const getPurgedPinnedValues = (
  data: ReportFilterData,
  isCoreDashboard: boolean,
  id: string,
) => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;
  const updatedData = produce(data, draft => {
    if (draft[id]) {
      draft[id][reportType] = {};
    }
  });

  return updatedData;
};

export const updatePinnedParameters = (
  data: ReportFilterData,
  updatedParameters: PinParameter[],
  isCoreDashboard: boolean,
  id: string,
) => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;

  const updatedData = produce(data, draft => {
    if (draft[id]) {
      const reportInteractionData = {
        ...draft[id]?.[reportType],
        [ReportSectionType.Parameter]: updatedParameters,
      };

      draft[id][reportType] = reportInteractionData;
    } else {
      const reportInteractionData = {
        [reportType]: { [ReportSectionType.Parameter]: updatedParameters },
      };
      draft[id] = reportInteractionData;
    }
  });

  return updatedData;
};

export const updatePinnedFilters = (
  data: ReportFilterData,
  updatedFilters: PinFilter[],
  isCoreDashboard: boolean,
  id: string,
) => {
  const reportType = isCoreDashboard
    ? ReportType.CoreDashboard
    : ReportType.PATDashboard;
  const updatedData = produce(data, draft => {
    if (draft[id]) {
      const reportInteractionData = {
        ...draft[id]?.[reportType],
        [ReportSectionType.Filter]: updatedFilters,
      };

      draft[id][reportType] = reportInteractionData;
    } else {
      const reportInteractionData = {
        [reportType]: { [ReportSectionType.Filter]: updatedFilters },
      };
      draft[id] = reportInteractionData;
    }
  });

  return updatedData;
};
