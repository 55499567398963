import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_TENANT_USERS, GET_TENANT_AD_USERS } from '@apollo-red/queries';
import { MutationUpdaterFn } from '@apollo/client';
import {
  UPDATE_TENANT_USER,
  ADD_TENANT_USERS,
  DELETE_USER_FROM_TENANT,
  REFRESH_TENANT_USERS,
  DEACTIVATE_USER_FROM_TENANT,
  REACTIVATE_USER_TO_TENANT,
} from '@apollo-red/mutations';
import { filterItems } from '@utils/various';
import { useAppMutation, useAppQuery } from '../queries';
import { UserClassificationType } from '../user';

export type TenantUser = SchemaTypes.TenantUserBaseFragment;

export type TenantAdUser = SchemaTypes.TenantAdUserBaseFragment;

export type TenantUserTenantSettings =
  SchemaTypes.TenantUserTenantSettingsBaseFragment;

/* eslint-disable-next-line */
export import UserProvisioningStatus = SchemaTypes.UserProvisioningStatus;

const getUpdatedTenantUser = (
  updatedUser: TenantUser,
  currentUsers: TenantUser[],
): TenantUser[] => {
  const hasUser = currentUsers.find(userWithType => {
    return userWithType.user_email === updatedUser.user_email;
  });
  const userPayload = {
    ...updatedUser,
    __typename: 'User' as 'User',
  };
  if (hasUser) {
    return currentUsers.map(userWithType => {
      if (userWithType.user_email === updatedUser.user_email) {
        return userPayload;
      }
      return userWithType;
    });
  }
  return currentUsers.concat(userPayload);
};

const getUpdatedTenantUsers = (
  updatedUsers: TenantUser[],
  currentUsers: TenantUser[],
): TenantUser[] => {
  return updatedUsers.reduce((acc, user) => {
    return getUpdatedTenantUser(user, acc);
  }, currentUsers);
};

const getTenantFromQuery = (
  tenantId: string,
  getTenantUsersQuery?: SchemaTypes.GetTenantUsersQuery | null,
) =>
  filterItems(getTenantUsersQuery?.getTenants ?? []).find(
    t => t.id === tenantId,
  );

export const filterTenantUsers = (
  deletedUser: TenantUser,
  currentUsers: TenantUser[],
) => {
  if (!deletedUser) {
    return currentUsers;
  }
  return currentUsers.filter(user => user.id !== deletedUser?.id);
};

const updateGetTenantUsersQuery = <T>(
  tenantId: string,
  updatedUserFromDataFn: (
    data: T | null | undefined,
  ) => TenantUser[] | null | undefined,
  isDeleteMutation?: boolean,
): MutationUpdaterFn<T> => {
  return (cache, { data }) => {
    const getTenantUsersQuery = cache.readQuery<
      SchemaTypes.GetTenantUsersQuery,
      SchemaTypes.GetTenantUsersQueryVariables
    >({
      query: GET_TENANT_USERS,
      variables: {
        tenant_id: tenantId,
      },
    });

    const tenant = getTenantFromQuery(tenantId, getTenantUsersQuery);
    const user = updatedUserFromDataFn(data);

    if (tenant && user) {
      cache.writeQuery({
        query: GET_TENANT_USERS,
        variables: {
          tenant_ids: [tenantId],
        },
        data: {
          getTenants: [
            {
              ...tenant,
              tenant_users: isDeleteMutation
                ? filterTenantUsers(user?.[0], tenant.tenant_users)
                : getUpdatedTenantUsers(user, tenant.tenant_users),
            },
          ],
        },
      });
    }
  };
};

export const useGetAllDirectTenantUsers = (tenantId: string) => {
  const { loading, data, stopPolling, startPolling } = useAppQuery<
    SchemaTypes.GetTenantUsersQuery,
    SchemaTypes.GetTenantUsersQueryVariables
  >(GET_TENANT_USERS, {
    variables: {
      tenant_id: tenantId,
    },
  });

  const tenant = getTenantFromQuery(tenantId, data);
  const tenantUsers = tenant?.tenant_users ?? [];

  const filteredTenantUsers = tenantUsers?.filter(tenantUser => {
    const tenantSetting = tenantUser?.user_tenant_settings?.find(
      uts => uts.user_tenant.id === tenantId,
    );

    if (!tenantSetting) {
      return false;
    }

    const { user_added_at, user_added_by } = tenantSetting;

    if (
      tenantUser.user_type === UserClassificationType.Internal &&
      !user_added_at &&
      !user_added_by
    ) {
      return false;
    }

    return true;
  });

  return {
    loading,
    stopPolling,
    startPolling,
    users: [...filteredTenantUsers].sort((u1, u2) =>
      u1.user_email.localeCompare(u2.user_email),
    ),
  };
};

export const useGetAllTenantUsers = (tenantId: string) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetTenantUsersQuery,
    SchemaTypes.GetTenantUsersQueryVariables
  >(GET_TENANT_USERS, {
    variables: {
      tenant_id: tenantId,
    },
  });

  const tenant = getTenantFromQuery(tenantId, data);
  const tenantUsers = tenant?.tenant_users ?? [];

  return {
    loading: loading,
    users: [...tenantUsers].sort((u1, u2) =>
      u1.user_email.localeCompare(u2.user_email),
    ),
  };
};

export const useGetAllNonInternalUsers = (
  tenantId: string,
  onlyActiveUsersWithRoles?: boolean,
) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetTenantUsersQuery,
    SchemaTypes.GetTenantUsersQueryVariables
  >(GET_TENANT_USERS, {
    variables: {
      tenant_id: tenantId,
    },
  });

  const users = React.useMemo(() => {
    if (loading) {
      return [];
    }

    const tenant = getTenantFromQuery(tenantId, data);
    const tenantUsers = tenant?.tenant_users ?? [];

    return tenantUsers
      ?.filter(({ user_type }) => user_type !== UserClassificationType.Internal)
      .filter(
        ({ user_status, user_tenant_settings }) =>
          !onlyActiveUsersWithRoles ||
          (onlyActiveUsersWithRoles &&
            user_status === SchemaTypes.UserStatus.Active &&
            !!user_tenant_settings.find(
              uts => uts.user_tenant.id === tenant?.id,
            )?.user_role?.id),
      )
      .sort((u1, u2) => u1.user_email.localeCompare(u2.user_email));
  }, [data, loading, onlyActiveUsersWithRoles, tenantId]);

  return {
    loading,
    users,
  };
};

export const useGetTenantAdUsers = (tenantId?: string) => {
  const { loading, data } = useAppQuery<
    SchemaTypes.GetTenantAdUsersQuery,
    SchemaTypes.GetTenantAdUsersQueryVariables
  >(GET_TENANT_AD_USERS, {
    variables: {
      tenant_id: tenantId ?? '',
    },
    skip: !tenantId,
  });

  return React.useMemo(() => {
    const tenant = data?.getTenants?.[0];
    const tenantUsers = tenant?.tenant_ad_users ?? [];

    return {
      loading,
      users: [...tenantUsers].sort((u1, u2) =>
        u1.user_email.localeCompare(u2.user_email),
      ),
    };
  }, [data?.getTenants, loading]);
};

export const useUpdateTenantUser = () => {
  const [updateTenantUser] = useAppMutation<
    SchemaTypes.UpdateTenantUserMutation,
    SchemaTypes.UpdateTenantUserMutationVariables
  >(UPDATE_TENANT_USER);

  return {
    updateTenantUser: (
      tenantId: string,
      payload: SchemaTypes.UpdateTenantUserMutationVariables['data'],
    ) => {
      return updateTenantUser({
        variables: {
          tenant_id: tenantId,
          data: payload,
        },
        update: updateGetTenantUsersQuery(tenantId, data =>
          data?.updateTenantUser ? [data?.updateTenantUser] : [],
        ),
      });
    },
  };
};

export const useCreateTenantUsers = () => {
  const [createTenantUsers] = useAppMutation<
    SchemaTypes.AddTenantUsersMutation,
    SchemaTypes.AddTenantUsersMutationVariables
  >(ADD_TENANT_USERS);
  return {
    createTenantUsers: (
      tenantId: string,
      payload: SchemaTypes.AddTenantUsersMutationVariables['data'],
    ) => {
      return createTenantUsers({
        variables: {
          tenant_id: tenantId,
          data: payload,
        },
        update: updateGetTenantUsersQuery(
          tenantId,
          data => data?.addTenantUsers,
        ),
      });
    },
  };
};

export const useDeleteUserFromTenant = () => {
  const [deleteUserFromTenant] = useAppMutation<
    SchemaTypes.DeleteUserFromTenantMutation,
    SchemaTypes.DeleteUserFromTenantMutationVariables
  >(DELETE_USER_FROM_TENANT);
  return {
    deleteUserFromTenant: (tenantId: string, userId: string) => {
      return deleteUserFromTenant({
        variables: {
          where: {
            id: userId,
          },
          tenant_id: tenantId,
        },
        update: updateGetTenantUsersQuery(
          tenantId,
          data =>
            data?.deleteUserFromTenant ? [data?.deleteUserFromTenant] : [],
          true,
        ),
      });
    },
  };
};

export const useRefreshTenantUsers = () => {
  const [refreshTenantUsers] = useAppMutation<
    SchemaTypes.RefreshTenantUsersMutation,
    SchemaTypes.RefreshTenantUsersMutationVariables
  >(REFRESH_TENANT_USERS);
  return {
    refreshTenantUsers: (tenantId: string) => {
      return refreshTenantUsers({
        variables: {
          tenant_id: tenantId,
        },
        update: updateGetTenantUsersQuery(
          tenantId,
          data => data?.refreshTenantUsers,
        ),
      });
    },
  };
};

export const useDeactivateUserFromTenant = () => {
  const [deactivateUserFromTenant] = useAppMutation<
    SchemaTypes.DeactivateUserFromTenantMutation,
    SchemaTypes.DeactivateUserFromTenantMutationVariables
  >(DEACTIVATE_USER_FROM_TENANT);
  return {
    deactivateUserFromTenant: (tenantId: string, userId: string) => {
      return deactivateUserFromTenant({
        variables: {
          where: {
            id: userId,
          },
          tenant_id: tenantId,
        },
        update: updateGetTenantUsersQuery(tenantId, data =>
          data?.deactivateUserFromTenant
            ? [data?.deactivateUserFromTenant]
            : [],
        ),
      });
    },
  };
};

export const useReactivateUserToTenant = () => {
  const [reactivateUserToTenant] = useAppMutation<
    SchemaTypes.ReactivateUserToTenantMutation,
    SchemaTypes.ReactivateUserToTenantMutationVariables
  >(REACTIVATE_USER_TO_TENANT);
  return {
    reactivateUserToTenant: (tenantId: string, userId: string) => {
      return reactivateUserToTenant({
        variables: {
          where: {
            id: userId,
          },
          tenant_id: tenantId,
        },
        update: updateGetTenantUsersQuery(tenantId, data =>
          data?.reactivateUserToTenant ? [data?.reactivateUserToTenant] : [],
        ),
      });
    },
  };
};
