import * as React from 'react';
import { Badge, Box, useTheme, badgeClasses } from '@mui/material';
import { IconButton, Icon } from '@jll-labs/azara-ui-components';
import { useTranslation } from 'react-i18next';
import AppAvatar, { AvatarSize } from '@components/avatar';
import PictureEdit from '@components/pictureEdit';
import { useGetUserInfo, useGetCurrentTenantRole } from '@apollo-red/hooks';

const useStyles = () => {
  const theme = useTheme();
  return {
    userName: {
      textAlign: 'center',
    },
    name: theme.typography.h6,
    badge: {
      [`& .${badgeClasses['badge']}`]: {
        alignContent: 'flex-end',
      },
    },
    badgeButton: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(-2),
    },
  };
};

interface Props {
  disabled?: boolean;
}

const UserAvatar: React.FC<Props> = ({ disabled }) => {
  const styles = useStyles();
  const { user } = useGetUserInfo();
  const { roleName } = useGetCurrentTenantRole();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPhotoEditOpen, setIsPhotoEditOpen] = React.useState(false);

  const handleTooltipOpen = evt => {
    setAnchorEl(evt.currentTarget);
    setIsPhotoEditOpen(current => !current);
  };

  return (
    <div>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={styles.badge}
        badgeContent={
          <IconButton
            sx={styles.badgeButton}
            onClick={handleTooltipOpen}
            dataTestId="camera-icon"
            label="Avatar"
            disabled={disabled}
            size="large"
          >
            <Icon name="CameraAlt" />
          </IconButton>
        }
      >
        <AppAvatar
          fullName={user.user_full_name}
          src={user.user_picture_url?.[0]}
          size={AvatarSize.lg}
        />
      </Badge>
      <PictureEdit
        anchorEl={anchorEl}
        isOpen={isPhotoEditOpen}
        setIsOpen={setIsPhotoEditOpen}
      />
      <Box sx={styles.userName}>
        <Box sx={styles.name}>{user.user_full_name}</Box>
        {roleName && (
          <div>
            <span>{`${t('role')}: `}</span>
            <span>{roleName}</span>
          </div>
        )}
      </Box>
    </div>
  );
};

export default UserAvatar;
