import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useGetTenant } from '@apollo-red/hooks';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_TENANT_REGULAR_SETTINGS } from '@apollo-red/mutations';
import { stripProp } from '@utils/various';
import { useGetUserInfo } from '../../user';
import { getUpdatedUserTenantSettings, mergeOrders } from '../helpers';
import { useGetUserDefaultTenantId } from './useGetUserDefaultTenantId';

export const useUpdateUserTenantRegularSettings = () => {
  const { user } = useGetUserInfo();
  const defaultTenantId = useGetUserDefaultTenantId();
  const { tenant } = useGetTenant(defaultTenantId);
  const [updateSettings] = useAppMutation<
    SchemaTypes.UpdateUserTenantRegularSettingsMutation,
    SchemaTypes.UpdateUserTenantRegularSettingsMutationVariables
  >(UPDATE_USER_TENANT_REGULAR_SETTINGS);

  return {
    updateUserTenantRegularSettings: (
      tenantId: string,
      settings: SchemaTypes.UserTenantRegularSettingsInput,
    ) => {
      if (!tenant) {
        return;
      }

      const order =
        user.user_tenant_regular_settings?.find(
          item => item.user_tenant.id === tenantId,
        )?.user_reports_order ?? [];
      const mergedOrder = mergeOrders(order, settings.user_reports_order);

      return updateSettings({
        variables: {
          where: {
            tenant_id: tenantId,
            user_id: user.id,
          },
          data: stripProp(
            { ...settings, user_reports_order: mergedOrder },
            '__typename',
          ),
        },
        optimisticResponse: {
          updateUserTenantRegularSettings: {
            id: user.id,
            user_tenant_regular_settings: getUpdatedUserTenantSettings(
              tenant,
              user.id,
              user.user_tenant_regular_settings,
              { ...settings, user_reports_order: mergedOrder },
            ),
            __typename: 'User',
          },
        },
      });
    },
  };
};
