import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';
import { PageContainerContext } from '@components/pageContainer';

interface Props {
  breadcrumbsParts?: BreadcrumbsRoutePart[];
}

const SelfServeAnalyticsPageWrapper: React.FC<Props> = ({
  breadcrumbsParts,
  children,
}) => {
  const { t } = useTranslation();
  const pageContainerContext = React.useContext(PageContainerContext);

  React.useEffect(() => {
    pageContainerContext.setBreadcrumbsData([
      t('self_serve_analytics'),
      [
        { label: t('self_serve_analytics') },
        breadcrumbsParts && { ...breadcrumbsParts },
      ],
    ]);
  }, [breadcrumbsParts, pageContainerContext, t]);

  return <>{children}</>;
};

export default SelfServeAnalyticsPageWrapper;
