import * as React from 'react';
import { ToolbarSettings } from '../withFilters/types';
import { ToolbarButton } from './types';

export const useEnabledToolbarButtons = (
  buttons: ToolbarButton[],
  settings: ToolbarSettings,
) => {
  return React.useMemo(
    () => buttons.filter(button => !!settings[button.name]),
    [buttons, settings],
  );
};
