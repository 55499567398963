import * as React from 'react';

export const getElementOffset = (el: HTMLDivElement | null) => {
  if (!el) {
    return {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    };
  }

  return {
    top: el.offsetTop,
    left: el.offsetLeft,
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
};

export const getElementRect = (element: HTMLElement | null) => {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    };
  }

  return element.getBoundingClientRect();
};

interface Options {
  deps?: any[];
}

export const useRect = (
  ref: React.RefObject<HTMLElement> | null,
  options?: Options,
) => {
  const [rect, setRect] = React.useState(
    getElementRect(ref ? ref.current : null),
  );

  const handleResize = React.useCallback(() => {
    if (ref && ref.current) {
      setRect(getElementRect(ref.current));
    }
  }, [ref]);

  let layoutDeps = [ref ? ref.current : undefined];

  if (options && options.deps) {
    layoutDeps = layoutDeps.concat(...options.deps);
  }

  React.useLayoutEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...layoutDeps, handleResize]);

  return rect;
};
