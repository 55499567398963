import * as React from 'react';
import { FatalError } from '@pages/error';
import { ErrorLogger } from './ErrorLogger';

interface ErrorState {
  reactError?: Error;
}

export class ErrorBoundaries extends React.Component<{}, ErrorState> {
  state = { reactError: undefined };

  static getDerivedStateFromError(error: Error): ErrorState {
    return { reactError: error };
  }

  render() {
    if (!this.state.reactError) {
      return this.props.children;
    }

    const error = this.state.reactError as unknown as Error;
    const isUserNotFoundError = error?.name === 'UserNotFoundInTheSystemError';

    return (
      <>
        <FatalError
          errorMessage={
            isUserNotFoundError ? 'accountNotActivated' : 'errorOccurredMsg'
          }
        />
        <ErrorLogger error={this.state.reactError} />
      </>
    );
  }
}
