import { gql } from '@apollo/client';

export const ReleaseBase = {
  fragment: gql`
    fragment ReleaseBase on ReleasesRecord {
      id
      client_type
      comments
      last_update
      last_update_by
      release_date
      release_id
      status
      test_status
    }
  `,
};
