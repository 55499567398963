import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorScreen, { MainIcon } from './ErrorScreen';

interface Props {
  errorMessage?: string;
}

const FatalError: React.FC<Props> = ({ errorMessage = 'errorOccurredMsg' }) => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      statusMsg=""
      secondaryMsg=""
      detailsMsg={t(errorMessage)}
      icon={MainIcon.Warning}
    />
  );
};

export default FatalError;
