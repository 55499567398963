import * as React from 'react';
import { GET_THOUGHTSPOT_TRUSTED_TICKET } from '@apollo-red/queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppLazyQueryCb } from '../queries';
import { useGetUserDefaultTenantId, useGetUserInfo } from '../user';

export const useGetThoughtSpotTrustedTicket = () => {
  const getTrustedTicket = useAppLazyQueryCb<
    SchemaTypes.GetThoughtSpotTrustedTicketQuery,
    SchemaTypes.GetThoughtSpotTrustedTicketQueryVariables
  >(GET_THOUGHTSPOT_TRUSTED_TICKET, {
    fetchPolicy: 'no-cache',
  });
  const {
    user: { id },
  } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();

  return {
    getThoughtSpotTrustedTicket: React.useCallback(async () => {
      const { data } = await getTrustedTicket({
        where: { user_id: id, tenant_id: tenantId },
      });
      return data.getThoughtSpotTrustedTicket;
    }, [getTrustedTicket, id, tenantId]),
  };
};
