import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormDialog,
  FormRow,
  FormSection,
  FormSelect,
  FormSubmit,
  FormTextField,
  SelectOption,
} from '@jll-labs/azara-ui-components';
import {
  FIELDS,
  frequencyMapping,
  frequencyOptions,
  initialValues,
  LABELS,
  opportunityTypeMapping,
  opportunityTypeOptions,
  unitMapping,
  unitOptions,
  validationSchema,
} from './Roi.constants';
import { FormValues } from './Roi.types';
import { Box } from '@mui/material';

interface Props {
  open: boolean;
  edit: boolean;
  toggleOpen: () => void;
  handleSubmit: (values: FormValues) => void;
  values?: FormValues;
}

const RoiDialog: React.FC<Props> = ({
  open,
  edit,
  values,
  toggleOpen,
  handleSubmit,
}) => {
  const [isEdit, setIsEdit] = React.useState(edit);
  const { t } = useTranslation();

  React.useEffect(() => {
    setIsEdit(edit);
  }, [edit]);

  const onSubmit: FormSubmit<FormValues> = async values => {
    if (isEdit) {
      handleSubmit(values);
      setIsEdit(false);
    } else {
      setIsEdit(true);
      toggleOpen();
    }
  };

  const onCancel = () => {
    setIsEdit(false);
    toggleOpen();
  };

  const renderReadOnlyRow = (name: string) => (
    <FormTextField
      name={name}
      readOnly={!isEdit}
      hideBorders={!isEdit}
      valueMapping={{
        ...opportunityTypeMapping,
        ...unitMapping,
        ...frequencyMapping,
      }}
      size="sm"
    />
  );

  const renderFormSelect = (
    name: string,
    label: string,
    options: SelectOption[],
  ) => <FormSelect name={name} label={label} options={options} />;

  return (
    <FormDialog
      open={open}
      title={isEdit ? t('estimateRoi') : t('estimatedRoi')}
      titleVariant="h5"
      size="small"
      validationSchema={validationSchema}
      initialValues={initialValues(values)}
      onClose={toggleOpen}
      onSubmit={onSubmit}
      enableCancel={true}
      onCancel={onCancel}
      keepEnabledIfPristine={!isEdit}
      submitBtnLabel={!isEdit ? 'Edit' : undefined}
    >
      <FormSection
        title={isEdit ? undefined : t('description')}
        titleVariant="h6"
      >
        <FormTextField
          name={FIELDS.comment}
          label={isEdit ? t('comment') : undefined}
          type="textarea"
          hideBorders={!isEdit}
          readOnly={!isEdit}
          disableTextareaPadding={!isEdit}
          textAreaProps={{ minRows: 3, maxRows: 3 }}
        />
      </FormSection>
      <Box maxWidth={400}>
        <FormSection
          title={isEdit ? t('selectParameters') : t('selectedParameters')}
          titleVariant="h6"
        >
          <FormRow
            label={!isEdit ? LABELS.opportunityType : undefined}
            noMarginTopBottom={!isEdit}
          >
            {isEdit
              ? renderFormSelect(
                  FIELDS.opportunityType,
                  LABELS.opportunityType,
                  opportunityTypeOptions,
                )
              : renderReadOnlyRow(FIELDS.opportunityType)}
          </FormRow>
          <FormRow
            label={!isEdit ? LABELS.value : undefined}
            noMarginTopBottom={!isEdit}
          >
            {isEdit ? (
              <FormTextField
                type="number"
                name={FIELDS.value}
                label={LABELS.value}
              />
            ) : (
              renderReadOnlyRow(FIELDS.value)
            )}
          </FormRow>
          <FormRow
            label={!isEdit ? LABELS.unit : undefined}
            noMarginTopBottom={!isEdit}
          >
            {isEdit
              ? renderFormSelect(FIELDS.unit, LABELS.unit, unitOptions)
              : renderReadOnlyRow(FIELDS.unit)}
          </FormRow>
          <FormRow
            label={!isEdit ? LABELS.frequency : undefined}
            noMarginTopBottom={!isEdit}
          >
            {isEdit
              ? renderFormSelect(
                  FIELDS.frequency,
                  LABELS.frequency,
                  frequencyOptions,
                )
              : renderReadOnlyRow(FIELDS.frequency)}
          </FormRow>
        </FormSection>
      </Box>
    </FormDialog>
  );
};

export default RoiDialog;
