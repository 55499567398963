import produce from 'immer';
import { PinParameter } from '../../types';

export const getUpdatedParameters = (
  parameter: PinParameter,
  existingParameters: PinParameter[],
) => {
  const { isPinned, selectedValue, selectedOption, name } = parameter;

  if (!parameter.isPinned) {
    return existingParameters.filter(p => p.name !== name);
  }

  return produce(existingParameters, draft => {
    const currentParameter = draft.find(p => p.name === name);

    if (currentParameter) {
      currentParameter.selectedOption = selectedOption;
      currentParameter.selectedValue = selectedValue;
      currentParameter.isPinned = isPinned;
    } else {
      draft.push({ ...parameter, isPinned: true });
    }
  });
};
