import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import {
  DEACTIVATE_CUSTOM_REPORT,
  PUBLISH_CUSTOM_REPORT,
  PREVIEW_CUSTOM_REPORT,
  VALIDATE_EXTERNAL_REPORT_EMBED_URL,
} from '@apollo-red/mutations';
import { useAppMutation } from '../queries';

export type CustomReport = SchemaTypes.CustomReportFragment;

export type CustomReportBase = SchemaTypes.CustomReportBaseFragment;

/* eslint-disable-next-line */
export import CustomReportStatus = SchemaTypes.CustomReportStatus;

export const usePreviewTenantCustomReport = () => {
  const [previewCustomReport] = useAppMutation<
    SchemaTypes.PreviewCustomReportMutation,
    SchemaTypes.PreviewCustomReportMutationVariables
  >(PREVIEW_CUSTOM_REPORT);

  return {
    previewCustomReport: (reportId: string) => {
      return previewCustomReport({
        variables: {
          where: { id: reportId },
        },
      });
    },
  };
};

export const usePublishTenantCustomReport = () => {
  const [publishTenantCustomReport] = useAppMutation<
    SchemaTypes.PublishTenantCustomReportMutation,
    SchemaTypes.PublishTenantCustomReportMutationVariables
  >(PUBLISH_CUSTOM_REPORT);

  return {
    publishTenantCustomReport: (reportId: string) => {
      return publishTenantCustomReport({
        variables: {
          where: { id: reportId },
        },
      });
    },
  };
};

export const useDeactivateCustomReport = () => {
  const [deactivateTenantCustomReport] = useAppMutation<
    SchemaTypes.DeactivateCustomReportMutation,
    SchemaTypes.DeactivateCustomReportMutationVariables
  >(DEACTIVATE_CUSTOM_REPORT);

  return {
    deactivateCustomReport: (reportId: string) => {
      return deactivateTenantCustomReport({
        variables: {
          where: { id: reportId },
        },
      });
    },
  };
};

export const useValidateExternalReportEmbedUrl = () => {
  const [validateExternalReportEmbedUrl] = useAppMutation<
    SchemaTypes.ValidateExternalReportEmbedUrlMutation,
    SchemaTypes.ValidateExternalReportEmbedUrlMutationVariables
  >(VALIDATE_EXTERNAL_REPORT_EMBED_URL);

  return {
    validateExternalReportEmbedUrl: (tenantId: string, embedUrl: string) => {
      return validateExternalReportEmbedUrl({
        variables: {
          where: {
            id: tenantId,
            embed_url: embedUrl,
          },
        },
      });
    },
  };
};
