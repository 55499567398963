import * as React from 'react';
import { Box } from '@mui/material';
import { Button } from '@jll-labs/azara-ui-components';
import { ToolbarButton } from './types';

interface Props {
  button: ToolbarButton;
  disabled: boolean;
  label: string;
}

const ToolbarItem: React.FC<Props> = ({ button, disabled, label }) => {
  const { component, icon, name, onClick } = button;

  return (
    <Box component="span" key={name} mr={2} mb={2} display="inline-block">
      <Button
        name={name}
        color="primary"
        data-testid={name}
        onClick={onClick}
        disabled={disabled}
        variant="text"
      >
        {icon}
        <Box component="span" mx={2} my={0}>
          {label}
        </Box>
      </Button>
      {component && component}
    </Box>
  );
};

export default ToolbarItem;
