import * as React from 'react';
import Grid from '@mui/material/Grid';
import {
  InsightsAlertImportance,
  InsightsAlertUserStatus,
  InsightsAlertStatus,
  InsightsAlertType,
  InsightsAlertSeverity,
} from '@apollo-red/hooks';
import { isAlertUnread } from '../utils';
import { useAlertTableRowStyles } from '../alertTableRows/useAlertTableRowStyles';
import {
  AlertDateCell,
  AlertImportanceFilterCell,
  AlertTitleCell,
  AlertSubtitleCell,
  AlertAssignedUser,
  AlertSeverityFlag,
} from './index';

interface Props {
  threadId: string;
  userImportance: InsightsAlertImportance;
  userStatus: InsightsAlertUserStatus;
  alertCount: number;
  alertStatus: InsightsAlertStatus;
  alertType: InsightsAlertType;
  severity: InsightsAlertSeverity;
  assignedUserId: string;
  userName: string;
  createdAt: string;
  shortDescription: string;
  handleImportanceChange: (
    threadId: string,
    importance: InsightsAlertImportance,
  ) => void;
}

const InsightsAlertTableCell: React.FC<Props> = ({
  threadId,
  userImportance,
  userStatus,
  alertType,
  alertCount,
  alertStatus,
  severity,
  createdAt,
  handleImportanceChange,
  userName,
  shortDescription,
}) => {
  const isUnread = isAlertUnread(userStatus);
  const rowStyles = useAlertTableRowStyles();

  return (
    <Grid
      style={{ flexGrow: 1 }}
      spacing={0}
      container
      direction="column"
      sx={isUnread ? rowStyles.unReadContainer : rowStyles.readContainer}
    >
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid container item xs={8}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item xs={3}>
                <AlertImportanceFilterCell
                  threadId={threadId}
                  userImportance={userImportance}
                  handleImportanceChange={handleImportanceChange}
                  alertType={alertType}
                />
              </Grid>
              <Grid item xs={9}>
                <AlertTitleCell
                  alertCount={alertCount}
                  alertStatus={alertStatus}
                  alertType={alertType}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={2}></Grid>
              <Grid item xs={3}>
                <AlertSeverityFlag severity={severity} />
              </Grid>
              <Grid item xs={3}>
                <AlertAssignedUser userName={userName} />
              </Grid>
              <Grid item xs={4}>
                <AlertDateCell createdAt={createdAt} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid container item xs={8}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <AlertSubtitleCell shortDescription={shortDescription} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.alertStatus === nextProps.alertStatus &&
    prevProps.assignedUserId === nextProps.assignedUserId &&
    prevProps.handleImportanceChange === nextProps.handleImportanceChange &&
    prevProps.severity === nextProps.severity &&
    prevProps.userImportance === nextProps.userImportance &&
    prevProps.userName === nextProps.userName &&
    prevProps.userStatus === nextProps.userStatus
  );
};

export default React.memo(InsightsAlertTableCell, areEqual);
