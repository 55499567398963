import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import {
  LocalizationProvider,
  AdapterDateFns,
} from '@jll-labs/azara-ui-components';
import { AppPerformanceProvider } from '@components/appPerformance';
import { ErrorBoundaries } from '@components/error';
import { Router } from '@components/okta';
import { ClientHomeRedirect } from '@components/routes';
import { MessageContextProvider } from '@components/messages';
import { LoginError, Maintenance, NetworkError, NotFound } from '@pages/error';
import Home from '@pages/home';
import { main } from '@routes';
import { appTheme } from '@theme';
import '../resource/translations';
import ApolloProvider from './ApolloProvider';
import BrowserRouter from './BrowserRouter';
import ClientRouter from './ClientRouter';
import DocumentationRouter from './DocumentationRouter';
import QaLegacyRedirect from './QaLegacyRedirect';
import { UserActivityTracker } from './UserActivityTracker';

const App: React.FC = () => {
  return (
    <AppPerformanceProvider>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={appTheme}>
            <CssBaseline>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router>
                  <MessageContextProvider>
                    <ApolloProvider>
                      <ErrorBoundaries>
                        <DndProvider
                          backend={TouchBackend}
                          options={{
                            enableMouseEvents: true,
                            enableTouchEvents: false,
                          }}
                        >
                          <UserActivityTracker>
                            <Switch>
                              <Route exact={true} path={main.landing.path}>
                                <Home />
                              </Route>
                              <Route exact={true} path={main.callback.path}>
                                <LoginCallback errorComponent={LoginError} />
                              </Route>
                              <SecureRoute path={main.documentation.path}>
                                <DocumentationRouter />
                              </SecureRoute>
                              <SecureRoute path={main.home.path}>
                                <ClientHomeRedirect />
                              </SecureRoute>
                              <SecureRoute path={main.client.path}>
                                <ClientRouter />
                              </SecureRoute>
                              <Route exact={true} path={main.notFound.path}>
                                <NotFound />
                              </Route>
                              <Route exact={true} path={main.maintenance.path}>
                                <Maintenance />
                              </Route>
                              <Route exact={true} path={main.networkError.path}>
                                <NetworkError />
                              </Route>
                              <Redirect
                                path="/"
                                exact={true}
                                to={main.landing.getLink()}
                              />
                              <Route path="/:tenantOvcId">
                                <QaLegacyRedirect />
                              </Route>
                              <Route
                                render={() => (
                                  <Redirect to={main.notFound.getLink()} />
                                )}
                              />
                            </Switch>
                          </UserActivityTracker>
                        </DndProvider>
                      </ErrorBoundaries>
                    </ApolloProvider>
                  </MessageContextProvider>
                </Router>
              </LocalizationProvider>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </AppPerformanceProvider>
  );
};

export default App;
