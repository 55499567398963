import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormAutocomplete,
  FormSelect,
  useFormContext,
} from '@jll-labs/azara-ui-components';
import { Grid } from '@mui/material';
import {
  CURRENCY_TRANSLATION_KEYS,
  UNIT_OF_MEASUREMENT_TRANSLATION_KEYS_LONG,
  unitsOfMeasurement,
  unitsOfCurrency,
} from '@apollo-red/hooks';
import { FormValues } from './useUniversalFiltersForm';
import { useGeoUniversalFiltersForm } from './useGeoUniversalFiltersForm';

interface Props {
  inline?: boolean;
  components: {
    countries: boolean;
    states: boolean;
    cities: boolean;
    properties: boolean;
    unitOfMeasurement: boolean;
    currency: boolean;
  };
  namePrefix?: string;
  disabled?: boolean;
}

const UniversalFiltersForm: React.FC<Props> = ({
  inline = true,
  components,
  namePrefix,
  disabled,
}) => {
  const gridItem = inline ? 4 : 12;
  const { t } = useTranslation();
  const formProps = useFormContext<FormValues>();

  const {
    handleChange,
    countriesDisabled,
    countriesLoading,
    countryOptions,
    statesDisabled,
    statesLoading,
    stateOptions,
    citiesDisabled,
    citiesLoading,
    cityOptions,
    propertiesDisabled,
    propertiesLoading,
    propertyOptions,
    groupCities,
    groupProperties,
    groupStates,
  } = useGeoUniversalFiltersForm({ formProps, namePrefix });

  return (
    <div>
      <Grid container={true} spacing={2}>
        {components.countries && (
          <Grid item={true} xs={gridItem}>
            <FormAutocomplete
              name={namePrefix ? `${namePrefix}.countries` : 'countries'}
              label="Country"
              multiple={true}
              loading={countriesLoading}
              disabled={countriesDisabled || disabled}
              onChange={handleChange}
              options={countryOptions}
            />
          </Grid>
        )}
        {components.states && (
          <Grid item={true} xs={gridItem}>
            <FormAutocomplete
              name={namePrefix ? `${namePrefix}.states` : 'states'}
              label="State/Province"
              multiple={true}
              loading={statesLoading}
              disabled={statesDisabled || disabled}
              onChange={handleChange}
              options={stateOptions}
              groupOptions={groupStates}
            />
          </Grid>
        )}
        {components.cities && (
          <Grid item={true} xs={gridItem}>
            <FormAutocomplete
              name={namePrefix ? `${namePrefix}.cities` : 'cities'}
              label="City"
              multiple={true}
              loading={citiesLoading}
              disabled={citiesDisabled || disabled}
              onChange={handleChange}
              options={cityOptions}
              groupOptions={groupCities}
            />
          </Grid>
        )}
        {components.properties && (
          <Grid item={true} xs={gridItem}>
            <FormAutocomplete
              name={namePrefix ? `${namePrefix}.properties` : 'properties'}
              label="Property Name"
              multiple={true}
              loading={propertiesLoading}
              disabled={propertiesDisabled || disabled}
              onChange={handleChange}
              options={propertyOptions}
              groupOptions={groupProperties}
            />
          </Grid>
        )}
      </Grid>
      <Grid container={true} spacing={2} my={1}>
        {components.unitOfMeasurement && (
          <Grid item={true} xs={gridItem}>
            <FormSelect
              name={
                namePrefix
                  ? `${namePrefix}.unit_of_measurement`
                  : 'unit_of_measurement'
              }
              options={unitsOfMeasurement.map(unit => ({
                label: t(UNIT_OF_MEASUREMENT_TRANSLATION_KEYS_LONG[unit]),
                value: unit,
              }))}
              label={t('unitOfMeasurement')}
              disabled={formProps.isSubmitting || disabled}
              required={true}
            />
          </Grid>
        )}
        {components.currency && (
          <Grid item={true} xs={gridItem}>
            <FormSelect
              name={namePrefix ? `${namePrefix}.currency` : 'currency'}
              options={unitsOfCurrency.map(unit => ({
                label: t(CURRENCY_TRANSLATION_KEYS[unit]),
                value: unit,
              }))}
              label={t('currency')}
              disabled={formProps.isSubmitting || disabled}
              required={true}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UniversalFiltersForm;
