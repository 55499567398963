import { useMemo } from 'react';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { getEncodedLink } from '@utils/router';
import { UseClientRoutes } from '../../types';
import { getCommonBaseRoute, getCommonRoute } from '../../utils';

export const usePATDashboardsRoutes = ({
  PATDashboards: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      home: getCommonBaseRoute(link),
      category: {
        path: `${link}/:categoryId`,
        getLink: (categoryId: string) => getEncodedLink([link, categoryId]),
      },
      dashboard: {
        path: `${link}/:categoryId/:dashboardId/:command?`,
        getLink: (categoryId: string, dashboardId: string, command?: string) =>
          getEncodedLink([
            link,
            categoryId,
            dashboardId,
            command ? command : '',
          ]),
      },
      favorites: getCommonRoute(link, 'favorites'),
      all: getCommonRoute(link, 'all'),
    } as DashboardRouteProps;
  }, [getLink]);
