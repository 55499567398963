/* eslint-disable no-console */
import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '@jll-labs/azara-ui-components';
import {
  ReportExtended,
  useGetTenantReportConfigurationData,
  useGetUserDefaultTenantId,
} from '@apollo-red/hooks';
import { useFeatureFlag } from '@utils/launchDarkly';
import {
  calculatePinnedValuesLength,
  getFilterControls,
  getInitialPinnedFiltersForBaselineReport,
  getInitialPinnedParametersForBaselineReport,
  getPinnedFilters,
  getPinnedParameters,
} from './utils';
import { PINNED_QUERY_VALUES_MAX_LENGTH } from './constants';
import FullReportWithFloatingPanel from './FullReportWithFloatingPanel';
import { useFullReportWithFilters } from './FullReportWithFiltersProvider';

const useStyles = makeStyles({
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px',
  },
});

interface Props {
  report: ReportExtended;
}

const CoreFullReport: React.FC<Props> = ({ children, report }) => {
  const debugFilters = useFeatureFlag('dashboardsFiltersDebugLogging');
  const { id, report_name, report_parent } = report;
  const reportId = report_parent || id;
  const classes = useStyles();
  const tenantId = useGetUserDefaultTenantId();
  const { loading, reportConfiguration } = useGetTenantReportConfigurationData({
    tenant_id: tenantId,
    report_id: reportId,
  });
  const {
    handleHasInteractionConfig,
    isCoreDashboard,
    pinnedData,
    id: pinnedFilterId,
    addInitialPinnedValues,
    addAppliedControls,
  } = useFullReportWithFilters();
  const { enabled, interaction_config } = reportConfiguration ?? {};
  const hasConfigurableFilters =
    !loading &&
    enabled &&
    interaction_config?.enabled &&
    interaction_config.sections.length > 0;

  React.useEffect(() => {
    const debugLog = debugFilters && interaction_config;
    const log = debugLog ? console.log : () => {};
    const table = debugLog ? console.table ?? console.log : () => {};

    debugLog &&
      console.groupCollapsed(`Dashboard Filter Configuration - ${report_name}`);

    const controls = getFilterControls(interaction_config?.sections ?? []);

    log(`Report Configuration for ID: ${reportId}`, interaction_config);
    log(`${controls.length} Filters configured.`);
    table(controls);

    const pinnedParameters = getPinnedParameters(
      pinnedData,
      isCoreDashboard,
      pinnedFilterId,
    );
    const pinnedFilters = getPinnedFilters(
      pinnedData,
      isCoreDashboard,
      pinnedFilterId,
    );
    const initialPinnedParameters = getInitialPinnedParametersForBaselineReport(
      pinnedParameters,
      controls,
    );
    const initialPinnedFilters = getInitialPinnedFiltersForBaselineReport(
      pinnedFilters,
      controls,
    );
    const pinnedValues = {
      ...initialPinnedParameters,
      ...initialPinnedFilters,
    };

    const initialPinnedValues =
      calculatePinnedValuesLength(pinnedValues) < PINNED_QUERY_VALUES_MAX_LENGTH
        ? pinnedValues
        : {};
    const initialPinnedControlNames = Object.entries(initialPinnedValues)?.map(
      ([key]) => key,
    );

    addInitialPinnedValues(initialPinnedValues);
    addAppliedControls(initialPinnedControlNames);

    log(`${Object.keys(initialPinnedValues).length} initial pinned values.`);
    table(initialPinnedValues);
    log(`${initialPinnedControlNames.length} initial applied controls.`);
    table(initialPinnedControlNames);
    debugLog && console.groupEnd();
  }, [
    debugFilters,
    pinnedFilterId,
    interaction_config,
    isCoreDashboard,
    pinnedData,
    reportId,
    report_name,
    addInitialPinnedValues,
    addAppliedControls,
  ]);

  React.useEffect(() => {
    if (hasConfigurableFilters && !loading) {
      handleHasInteractionConfig(hasConfigurableFilters);
    }
  }, [hasConfigurableFilters, loading, handleHasInteractionConfig]);

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <Loader />
      </div>
    );
  }

  return (
    <FullReportWithFloatingPanel sections={interaction_config?.sections ?? []}>
      {children}
    </FullReportWithFloatingPanel>
  );
};

export default CoreFullReport;
