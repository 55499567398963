import * as React from 'react';
import { MessageDialog } from '@jll-labs/azara-ui-components';
import { MessageComponentProps } from './MessageContext';

/**
 * Wrapper around generic MessageDialog. It handles various types of non-success notifications appearing in RED2.
 */
const Dialog: React.FC<MessageComponentProps> = ({ message, handleClose }) => {
  return (
    <MessageDialog
      open={true}
      title={message.text ?? ''}
      type={message.type}
      handleConfirmClick={handleClose}
      size="small"
    >
      {message.description}
    </MessageDialog>
  );
};

export default Dialog;
