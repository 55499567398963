import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import { Viz } from './types';
import TableauView, { TableauViewProps } from './TableauView';

export interface AppViz {
  id: string;
  props: TableauViewProps;
  visible: boolean;
  mounted: boolean;
  reset: boolean;
  container: string;
  viz?: Viz;
  tableauContainerStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  offset?: {
    top: number;
    left: number;
    width: number;
  };
  resetKey?: string;
}

interface AppVizes {
  [key: string]: AppViz;
}

interface TableauAppCacheContext {
  vizes: AppVizes;
  add: (viz: AppViz) => void;
  getById: (id: string) => AppViz | undefined;
  update: (id: string, partialUpdate: Partial<AppViz>) => void;
  clearCache: () => void;
  clearCacheById: (id: string) => void;
  reset: (id: string) => void;
}

export const TableauCacheContext = React.createContext<TableauAppCacheContext>({
  vizes: {},
  add: () => {},
  getById: () => undefined,
  update: () => undefined,
  clearCache: () => undefined,
  clearCacheById: () => undefined,
  reset: () => {},
});

const getStyle = (appViz: AppViz): React.CSSProperties => {
  const common = {
    position: 'absolute' as any,
    top: -9999,
    left: -9999,
    width: '100%',
  };
  if (appViz.visible && appViz.mounted && appViz.offset) {
    const { top, left, width } = appViz.offset;
    return {
      ...common,
      top,
      left,
      zIndex: 0,
      opacity: 1,
      width,
      transition: 'opacity 0.3s',
      transitionDelay: '0.25s',
      ...appViz.containerStyle,
    };
  }
  return {
    ...common,
    zIndex: -1000,
    opacity: 0,
  };
};

interface Props {
  containers: {
    [key: string]: {
      element: HTMLElement | null;
      hide: boolean;
    };
  };
}

interface VizesPerContainer {
  [key: string]: {
    allHidden: boolean;
    vizes: AppViz[];
  };
}

const TableauCacheContextProvider: React.FC<Props> = ({
  children,
  containers,
}) => {
  const [vizes, setVizes] = React.useState<AppVizes>({});

  const vizesPerContainer: VizesPerContainer = Object.keys(vizes)
    .map(cacheKey => vizes[cacheKey])
    .reduce((acc: VizesPerContainer, viz: AppViz) => {
      if (!acc[viz.container]) {
        return {
          ...acc,
          [viz.container]: {
            allHidden: !viz.visible,
            vizes: [viz],
          },
        };
      }

      return {
        ...acc,
        [viz.container]: {
          allHidden: acc[viz.container].allHidden && !viz.visible,
          vizes: acc[viz.container].vizes.concat(viz),
        },
      };
    }, {});

  const add = (viz: AppViz) => {
    setVizes(currentVizes => ({
      ...currentVizes,
      [viz.id]: viz,
    }));
  };

  const getById = (id: string) => {
    return vizes[id];
  };

  const update = (id: string, partialUpdate: Partial<AppViz>) => {
    setVizes(currentVizes => {
      return {
        ...currentVizes,
        [id]: {
          ...currentVizes[id],
          ...partialUpdate,
        },
      };
    });
  };

  const clearCache = () => {
    Object.keys(vizes)
      .map(cacheKey => vizes[cacheKey])
      .forEach((appViz: AppViz) => {
        if (appViz.viz) {
          appViz.viz.dispose();
        }
      });

    setVizes({});
  };

  const clearCacheById = (id: string) => {
    setVizes(current => {
      const viz = current[id]?.viz;

      if (viz) {
        viz.dispose();
      }

      const copy = { ...current };
      delete copy[id];

      return copy;
    });
  };

  const reset = (id: string) => {
    const existingViz = Object.values(vizes).find(viz => viz.id.includes(id));

    if (!existingViz) {
      return;
    }

    update(id, {
      resetKey: uuid(),
      reset: true,
    });
  };

  Object.keys(vizesPerContainer).forEach(containerKey => {
    if (containers[containerKey]?.hide) {
      if (containers[containerKey]?.element) {
        const container = containers[containerKey].element as HTMLElement;
        container.style.zIndex = vizesPerContainer[containerKey].allHidden
          ? '-9999'
          : '9999';
      }
    }
  });

  return (
    <TableauCacheContext.Provider
      value={{
        vizes,
        add,
        getById,
        update,
        clearCache,
        clearCacheById,
        reset,
      }}
    >
      {Object.keys(vizesPerContainer).map(containerKey => {
        return containers[containerKey]?.element
          ? ReactDOM.createPortal(
              vizesPerContainer[containerKey].vizes.map(viz => (
                <div key={viz?.resetKey || viz.id} style={getStyle(viz)}>
                  <TableauView
                    {...viz.props}
                    tableauContainerStyle={viz.tableauContainerStyle}
                    reset={viz.reset}
                  />
                </div>
              )),
              containers[containerKey]?.element as HTMLElement,
            )
          : null;
      })}
      {children}
    </TableauCacheContext.Provider>
  );
};

export default TableauCacheContextProvider;
