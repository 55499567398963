import { useMemo } from 'react';
import { getCommonRoute, useGetClientLink } from '../utils';

export const useClientRoutes = (clientId?: string) => {
  const getClientLink = useGetClientLink(clientId);

  return useMemo(() => {
    const link = getClientLink();

    return {
      dashboards: {
        path: `${link}/dashboards`,
        getLink: (tenantOvcId?: string) =>
          `${getClientLink(tenantOvcId)}/dashboards`,
      },
      PATDashboards: getCommonRoute(link, 'legacy-dashboards'),
      jllPortscape: getCommonRoute(link, 'portscape'),
      selfServeAnalytics: getCommonRoute(link, 'self-serve-analytics'),
      insightsAlerts: getCommonRoute(link, 'alerts'),
      settings: getCommonRoute(link, 'settings'),
      productSettings: getCommonRoute(link, 'product-settings'),
      clientSettings: getCommonRoute(link, 'client-settings'),
      noRoleAssigned: getCommonRoute(link, 'no-role-assigned'),
      userInactive: getCommonRoute(link, 'user-inactive'),
      dataManagement: getCommonRoute(link, 'data-management'),
      noTenantsAssigned: getCommonRoute(link, 'no-clients-assigned'),
      onBoarding: getCommonRoute(link, 'onboarding'),
    };
  }, [getClientLink]);
};
