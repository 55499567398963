import { utils } from '@jll-labs/azara-ui-components';
import { Viz, ParameterValueType } from '@components/tableau/types';

const { useToggleValue } = utils;

export const useChangeParameterValue = (viz?: Viz) => {
  const [loading, toggleLoading] = useToggleValue(false);

  const changeParameter = async (name, value: ParameterValueType) => {
    if (!viz) {
      return;
    }

    try {
      toggleLoading();
      await viz?.getWorkbook()?.changeParameterValueAsync(name, value);
    } finally {
      toggleLoading();
    }
  };

  return { loading, changeParameter };
};
