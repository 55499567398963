export enum ErrorType {
  Service = 'ServiceError',
  Validation = 'ValidationError',
  ResourceNotFound = 'ResourceNotFoundError',
  Authorization = 'AuthorizationError',
  Internal = 'InternalServerError',
  Maintenance = 'MaintenanceError',
}

export class UserNotFoundInTheSystemError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UserNotFoundInTheSystemError';
  }
}

export class InvalidFilterValue extends Error {
  constructor(filterName: string) {
    super(filterName);
    this.name = 'InvalidFilterValueError';
  }
}
