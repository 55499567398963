import * as React from 'react';
import { InsightsAlertSameDayWorkOrderCompletionTemplateData } from '@apollo-red/hooks';
import OpenAlertReport from './OpenAlertReport';
import { useAlertTextStyles } from './useAlertTextStyles';

interface SameDayWorkOrderCompletionAlertProps {
  template: InsightsAlertSameDayWorkOrderCompletionTemplateData;
  boldText?: boolean;
}

const SameDayWorkOrderCompletionAlert: React.FC<SameDayWorkOrderCompletionAlertProps> =
  ({ template, boldText }) => {
    const {
      property_name,
      completed_work_orders_not_on_time_percentage,
      completed_work_orders_percentage,
      not_completed_work_orders_percentage,
      total_work_orders,
      threshold_for_alert_generation,
    } = template;

    const classes = useAlertTextStyles({ boldText });

    return (
      <OpenAlertReport>
        <div className={classes.fontWeight}>
          {threshold_for_alert_generation ? (
            <>
              Same day completion rate of high priority work orders is less than
              the threshold value of <b>{threshold_for_alert_generation}%</b> at
              your <b>{property_name}</b> property in last 1 week
            </>
          ) : (
            <>
              Same day completion rate of high priority work orders is less than
              the threshold value at your <b>{property_name}</b> property in
              last 1 week
            </>
          )}
          <br />
          <ul>
            <li>
              Total High Priority Work Orders - <b>{total_work_orders}</b>
            </li>
            <li>
              % Completed on time - <b>{completed_work_orders_percentage}</b>
            </li>
            <li>
              % Completed in a day but missed SLA time -{' '}
              <b>{completed_work_orders_not_on_time_percentage}</b>
            </li>
            <li>
              % Not completed in a day -{' '}
              <b>{not_completed_work_orders_percentage}</b>
            </li>
          </ul>
        </div>
      </OpenAlertReport>
    );
  };

export default SameDayWorkOrderCompletionAlert;
