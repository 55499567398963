import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Icon, IconName } from '@jll-labs/azara-ui-components';
import { AppTheme } from '@theme';

interface Props {
  label: string;
  icon: IconName;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  icon: {
    color: theme.palette.action.active,
  },
  option: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  optionLabel: {
    paddingLeft: theme.spacing(1),
  },
}));

const OnboardingStatusOption: React.FC<Props> = ({ icon, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.option}>
      <Icon name={icon} className={classes.icon} />
      <div className={classes.optionLabel}>{label}</div>
    </div>
  );
};

export default OnboardingStatusOption;
