import * as React from 'react';
import { Loader } from '@jll-labs/azara-ui-components';
import {
  ReportInteractionSection,
  useReportMetadataControls,
} from '@apollo-red/hooks';
import { FlattenedParameter } from '@components/tableau/types';
import { useChangeParameterValue } from '../../hooks';
import { ParameterExtended } from '../../types';
import { useFullReportWithFilters } from '../../FullReportWithFiltersProvider';
import SectionHeader from '../SectionHeader';
import Parameters from './Parameters';

interface Props {
  section: ReportInteractionSection;
  tableauParameters?: FlattenedParameter[];
  parametersLoading?: boolean;
}

const VariablesPanel: React.FC<Props> = ({
  section,
  tableauParameters,
  parametersLoading,
}) => {
  const { viz } = useFullReportWithFilters();
  const { loading: parameterChangeLoading, changeParameter } =
    useChangeParameterValue(viz);
  const controls = useReportMetadataControls(section);

  const getParameters = () => {
    const mergedParameters: ParameterExtended[] = [];

    controls.forEach(control => {
      const tableauParameter = tableauParameters?.find(
        tp => tp.name === control.name,
      );

      if (tableauParameter) {
        const mergedParameter = {
          ...tableauParameter,
          ...control,
        };

        mergedParameters.push(mergedParameter);
      }
    });

    return mergedParameters;
  };

  const parameters = getParameters();

  return parameters?.length ? (
    <div>
      <SectionHeader title={section.label} />
      {parametersLoading ? (
        <Loader />
      ) : (
        <Parameters
          parameters={parameters}
          changeParameter={changeParameter}
          loading={parameterChangeLoading}
        />
      )}
    </div>
  ) : null;
};

export default VariablesPanel;
