import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_EXTENDED } from '@apollo-red/mutations';
import { GET_USER_CLASSIFICATION_NUMBERS } from '@apollo-red/queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';

export const useUpdateUserExtended = (
  filters?: SchemaTypes.UserClassificationFiltersInput,
) => {
  const [updateUser] = useAppMutation<
    SchemaTypes.UpdateUserExtendedMutation,
    SchemaTypes.UpdateUserExtendedMutationVariables
  >(UPDATE_USER_EXTENDED, {
    refetchQueries: [
      {
        query: GET_USER_CLASSIFICATION_NUMBERS,
        variables: {
          where: {
            filters: filters,
          },
        },
      },
    ],
  });

  return {
    updateUserExtended: (
      userId: string,
      data: SchemaTypes.UpdateUserExtendedInput,
    ) =>
      updateUser({
        variables: {
          data,
          where: {
            id: userId,
          },
        },
      }),
  };
};
