import { CommonRoute } from '../types';

export const getCommonRoute = (
  baseRoute: string,
  route: string,
  path?: string,
) =>
  ({
    path: `${path ?? baseRoute}/${route}`,
    getLink: () => `${baseRoute}/${route}`,
  } as CommonRoute);
