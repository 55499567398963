import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  toolbarWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
}));

interface ToolbarItemsWrapperProps {
  items: React.ReactNode;
  downloadItems: React.ReactNode;
}

const ToolbarItemsWrapper: React.FC<ToolbarItemsWrapperProps> = ({
  items,
  downloadItems,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toolbarWrapper}>
      {items}
      {downloadItems}
    </div>
  );
};

export default ToolbarItemsWrapper;
