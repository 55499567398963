import * as React from 'react';
import { CustomViewTableau, Viz } from '@components/tableau/types';

export interface CustomView {
  viewName: string;
  viewUrl: string;
}

const mapToCustomView = (
  tableauCustomViewResponse: CustomViewTableau,
): CustomView => {
  return {
    viewName: tableauCustomViewResponse.getName(),
    viewUrl: tableauCustomViewResponse.getUrl(),
  };
};

const useCustomViews = viz => {
  const [customViews, setCustomViews] = React.useState<CustomView[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tableauListeners, setTableauListeners] = React.useState([]);

  const getCustomViews = (viz: Viz) => {
    setLoading(true);

    viz
      .getWorkbook()
      .getCustomViewsAsync()
      .then(data => {
        // slightly delay data loading
        setTimeout(() => {
          const customViews: CustomView[] = data.map(mapToCustomView);

          setLoading(false);
          setCustomViews(customViews);
        }, 1000);
      });
  };

  const attachCustomViewEvents = viz => {
    if (tableauListeners.length === 0) {
      setTableauListeners((listeners: { [index: string]: any }) =>
        listeners.concat(
          {
            customviewsave: viz.addEventListener('customviewsave', () => {
              getCustomViews(viz);
            }),
          },
          {
            customviewdelete: viz.addEventListener('customviewremove', () => {
              getCustomViews(viz);
            }),
          },
          {
            customviewsetdefault: viz.addEventListener(
              'customviewsetdefault',
              () => {
                getCustomViews(viz);
              },
            ),
          },
        ),
      );
    }
  };

  const removeEventListeners = viz => {
    Object.entries(tableauListeners).forEach(
      ([tableauListenerName, tableauListenerValue]) => {
        try {
          viz.removeEventListener(tableauListenerName, tableauListenerValue);
        } catch (error) {
          // Handle tableauSoftwareErrorCode: "unsupportedEventName"
        }
      },
    );

    setTableauListeners([]);
  };

  React.useEffect(() => {
    if (viz) {
      attachCustomViewEvents(viz);

      return () => {
        removeEventListeners(viz);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viz]);

  React.useEffect(() => {
    if (viz) {
      getCustomViews(viz);
    }
  }, [viz]);

  return {
    loading,
    customViews,
  };
};

export default useCustomViews;
