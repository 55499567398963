import { ReportFiltersInteractionSection } from '../../types';

export const overrideControlSortOrderForFilterSections = <
  T extends ReportFiltersInteractionSection,
>(
  sections?: T[],
): T[] => {
  if (!sections || sections.length === 0) {
    return [];
  }

  let sortIndex = 0;

  return sections.map(section => {
    const controls = section.controls.map(control => {
      return {
        ...control,
        sort_index: sortIndex++,
      };
    });

    return { ...section, controls };
  });
};
