import { gql } from '@apollo/client';

export const ADD_APPLICATION = gql`
  mutation addApplication($data: CreateApplicationInput!) {
    addApplication(data: $data) {
      id
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation updateApplication(
    $data: UpdateApplicationInput!
    $where: WhereUpdateApplicationInput!
  ) {
    updateApplication(data: $data, where: $where) {
      id
    }
  }
`;
