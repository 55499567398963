import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, CircularProgress, Box } from '@mui/material';
import { Paper, utils } from '@jll-labs/azara-ui-components';
import { useInsightsAlertsInbox, InsightsAlert } from '@apollo-red/hooks';
import { AppTheme, useAppTheme } from '@theme';
import { PageContainerContext } from '@components/pageContainer';
import { InsightsAlertsInboxTypeParam } from '@routes';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { useParamsCustom } from '@utils/router';
import AlertsContentWrapper from './AlertsContentWrapper';
import RightSideComponent from './RightSideContent';
import LeftAlertComponent from './LeftAlertList';
import { paramToInboxType, alertInboxTranslationKeys } from './utils';
import { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';

const { createUpBreakpoints } = utils;

export const createMailBoxBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    width: theme.alerts.mailbox.width[breakpoint],
  };
};

export const createAlertContentBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    width: theme.alerts.alertContent.width[breakpoint],
  };
};

const useStyles = () => {
  const theme = useAppTheme();
  return {
    root: {
      height: '78vh',
    },
    fullHeight: {
      height: '100%',
    },
    leftSideComponent: {
      padding: '1em',
      minWidth: '400px',
      width: theme.alerts.mailbox.width.lg,
      ...createUpBreakpoints(['xl'], theme, createMailBoxBreakpoints),
    },
    circularProgress: {
      flexGrow: 1,
      visibility: 'visible',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    rightSideComponent: {
      padding: '2em',
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: theme.palette.secondary.dark,
      overflow: 'auto',
      width: theme.alerts.alertContent.width.lg,
      ...createUpBreakpoints(['xl'], theme, createAlertContentBreakpoints),
    },
  };
};

interface MatchParams {
  inboxType: InsightsAlertsInboxTypeParam;
}

const AlertsContainer: React.FC<{}> = () => {
  const [clickedAlert, setClickedAlert] = React.useState('');
  const pageContainerCtx = React.useContext(PageContainerContext);
  const params = useParamsCustom<MatchParams>();
  const inboxType =
    paramToInboxType[params.inboxType as InsightsAlertsInboxTypeParam];
  const styles = useStyles();
  const { t } = useTranslation();
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  const {
    alerts,
    hasNextPage,
    isLoadingMore,
    loading,
    selectedAlert,
    selectedAlertAssignedToFilter,
    selectedAlertImportanceFilter,
    selectedAlertSeverityFilter,
    selectedAlertThreadLoading,
    selectedAlertTypeFilter,
    selectedSortOrders,
    handleAddUserComment,
    handleAlertRead,
    handleAlertSelect,
    handleAssignedUserFilterChange,
    handleAssignUserChange,
    handleImportanceFilterChange,
    handleImportanceChange,
    handleROIDataChange,
    handleSeverityChange,
    handleSeverityFilterChange,
    handleSortByDate,
    handleStatusChange,
    handleTypeFilterChange,
    loadMore,
  } = useInsightsAlertsInbox(inboxType);

  const handleAlertClick = (item: InsightsAlert) => {
    trackInsightsAlertEvent('alertRead', {
      alertThreadId: item.thread_id ?? '',
      alertType: item.alert_type ?? '',
    });

    if (pageContainerCtx.isSidebarExpanded) {
      pageContainerCtx.closeSidebar();
    }

    setClickedAlert(item.thread_id);
    handleAlertSelect(item);
  };

  const handleFilterChange = (
    filterOption: any,
    filterFunction: (arg: any) => void,
  ) => {
    filterFunction(filterOption.value);
  };

  const breadcrumbsParts = React.useMemo(
    () => [
      {
        label: t(alertInboxTranslationKeys[inboxType]),
      },
    ],
    [inboxType, t],
  );

  return (
    <AlertsContentWrapper
      breadcrumbsTitle={t('alerts')}
      breadcrumbsParts={breadcrumbsParts}
      inboxType={inboxType}
    >
      <Paper sx={styles.root}>
        <Grid container={true} wrap="nowrap" sx={styles.fullHeight}>
          <Box sx={styles.leftSideComponent}>
            <LeftAlertComponent
              hasNextPage={!!hasNextPage}
              loadMore={loadMore}
              loading={loading}
              isLoadingMore={isLoadingMore}
              inboxType={inboxType}
              alerts={alerts}
              selectedAlertTypeFilter={selectedAlertTypeFilter}
              selectedAlertImportanceFilter={selectedAlertImportanceFilter}
              selectedSortOrders={selectedSortOrders}
              handleImportanceFilterChange={val => {
                handleFilterChange(val, handleImportanceFilterChange);
              }}
              handleTypeFilterChange={val => {
                handleFilterChange(val, handleTypeFilterChange);
              }}
              handleAssignedUserFilterChange={val => {
                handleFilterChange(val, handleAssignedUserFilterChange);
              }}
              handleSeverityFilterChange={val => {
                handleFilterChange(val, handleSeverityFilterChange);
              }}
              handleImportanceChange={handleImportanceChange}
              handleSortByDate={handleSortByDate}
              handleAlertClick={handleAlertClick}
              activeAlert={selectedAlert}
              selectedAlertAssignedToFilter={selectedAlertAssignedToFilter}
              selectedAlertSeverityFilter={selectedAlertSeverityFilter}
            />
          </Box>

          <Box sx={styles.rightSideComponent}>
            {selectedAlertThreadLoading ? (
              <Box sx={styles.circularProgress}>
                <CircularProgress disableShrink color="primary" />
              </Box>
            ) : (
              selectedAlert &&
              clickedAlert === selectedAlert.thread_id && (
                <RightSideComponent
                  activeAlert={selectedAlert}
                  handleSeverityChange={handleSeverityChange}
                  handleAssignUserChange={handleAssignUserChange}
                  handleStatusChange={handleStatusChange}
                  handleAlertRead={handleAlertRead}
                  handleROIDataChange={handleROIDataChange}
                  inboxType={inboxType}
                  handleAddUserComment={handleAddUserComment}
                />
              )
            )}
          </Box>
        </Grid>
      </Paper>
    </AlertsContentWrapper>
  );
};

export default AlertsContainer;
