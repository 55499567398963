import { unionBy } from 'lodash';
import { TypedTypePolicies } from '@apollo-red/schema/generated/apollo-helpers';

export const tenantTypePolicy: TypedTypePolicies = {
  Tenant: {
    fields: {
      tenant_ad_groups: {
        merge: false,
      },
      tenant_applications: {
        merge: false,
      },
      tenant_client_defined_roles: {
        merge: false,
      },
      tenant_reports: {
        merge: false,
      },
      tenant_users: {
        merge: false,
      },
      tenant_property_data: {
        keyArgs: [
          'filter',
          ['city', 'country', 'property_id', 'property_name', 'property_type'],
        ],
        merge(existing, incoming) {
          let next_page;
          let records;

          if (incoming?.next_page === null) next_page = -1;
          else if (existing?.next_page === -1) next_page = -1;
          else if (existing?.next_page === null) next_page = incoming.next_page;
          else if (existing?.next_page > incoming.next_page)
            next_page = existing.next_page;
          else next_page = incoming.next_page;

          if (!incoming?.records) records = [...existing.records];
          else if (!existing?.records) records = [...incoming.records];
          else
            records = unionBy(
              existing.records,
              incoming.records,
              'property_id',
            );

          return {
            ...(incoming ?? existing),
            next_page,
            records,
          };
        },
      },
      tenant_external_reports: {
        merge: false,
      },
    },
  },
  TenantAlertSettings: {
    // @ts-ignore
    keyFields: object => object.alert_tenant_id,
  },
  TenantCustomReportsSettings: {
    merge: true,
  },
  TenantDataStandard: {
    merge: true,
  },
  TenantConfigurationData: {
    keyFields: ['id', 'tenant_id'],
  },
};
