import { sortBy } from 'lodash';
import {
  FormSelectOption,
  FormProps,
  getFormFieldValue,
} from '@jll-labs/azara-ui-components';
import { TenantUniversalFilterGeoValue } from '@apollo-red/hooks';
import { FormValues } from './useUniversalFiltersForm';
import { useGeoUniversalFiltersValues } from './useGeoUniversalFiltersValues';

const filterChildren = {
  countries: 'states',
  states: 'cities',
  cities: 'properties',
  'role_universal_filters.filters.countries':
    'role_universal_filters.filters.states',
  'role_universal_filters.filters.states':
    'role_universal_filters.filters.cities',
  'role_universal_filters.filters.cities':
    'role_universal_filters.filters.properties',
};

export type GeoFiltersOption = FormSelectOption & {
  group: string;
  ancestors: string[];
};

const parseFilterValues = (filterValues: TenantUniversalFilterGeoValue[]) => {
  return filterValues.reduce((acc, filterValue) => {
    const { values, ancestors } = filterValue;
    const parsedAncestors = ancestors ?? [];
    const parsedAncestorsLabel = ancestors
      ? [...ancestors].reverse().join(', ')
      : '';
    const parsedAncestorsId = ancestors ? ancestors.join('_') : '';
    return acc.concat(
      values.map(v => ({
        label: v,
        value: parsedAncestorsId ? `${parsedAncestorsId}_${v}` : v,
        group: parsedAncestorsLabel,
        ancestors: parsedAncestors,
      })),
    );
  }, [] as GeoFiltersOption[]);
};

const sortFilterValuesBasedOnGroup = (values: GeoFiltersOption[]) => {
  return sortBy(values, function (value: GeoFiltersOption) {
    return value?.group.toLowerCase();
  });
};

const filterUnselected =
  (parentOpts: GeoFiltersOption[]) => (childOpt: GeoFiltersOption) => {
    return parentOpts.some(({ value: parentValue }) => {
      const { label, value } = childOpt;
      return `${parentValue}_${label}` === value;
    });
  };

interface Props {
  formProps: FormProps<FormValues>;
  namePrefix?: string;
}

export const useGeoUniversalFiltersForm = ({
  formProps: { setFieldTouched, setFieldValue, values },
  namePrefix,
}: Props) => {
  const formValues: FormValues = namePrefix
    ? getFormFieldValue(values, namePrefix)
    : values;
  const { countries, states, cities } = formValues;

  const {
    anyLoading,
    tenantCountries,
    tenantStates,
    tenantCities,
    tenantProperties,
    countriesLoading,
    statesLoading,
    citiesLoading,
    propertiesLoading,
    groupCities,
    groupProperties,
    groupStates,
    byPassFilter,
  } = useGeoUniversalFiltersValues(formValues);

  const clearChildren = (
    fieldName: string,
    parentValues: GeoFiltersOption[],
  ) => {
    const child = filterChildren[fieldName];
    const childValues: GeoFiltersOption[] = formValues[child] || [];
    if (child) {
      const filteredValues = byPassFilter
        ? []
        : childValues.filter(filterUnselected(parentValues));
      setFieldValue(child, filteredValues);
      setFieldTouched(child);
      clearChildren(child, filteredValues);
    }
  };

  const handleChange = (fieldName: string, selected: GeoFiltersOption[]) => {
    clearChildren(fieldName, selected);
  };

  const countryOptions = parseFilterValues(tenantCountries);
  const parsedStates = sortFilterValuesBasedOnGroup(
    parseFilterValues(tenantStates),
  );
  const parsedCities = sortFilterValuesBasedOnGroup(
    parseFilterValues(tenantCities),
  );
  const parsedProperties = sortFilterValuesBasedOnGroup(
    parseFilterValues(tenantProperties),
  );

  const stateOptions = byPassFilter
    ? parsedStates
    : parsedStates.filter(filterUnselected(countries));
  const cityOptions = byPassFilter
    ? parsedCities
    : parsedCities.filter(filterUnselected(states));
  const propertyOptions = byPassFilter
    ? parsedProperties
    : parsedProperties.filter(filterUnselected(cities));

  return {
    handleChange,
    countriesLoading,
    countriesDisabled: countriesLoading,
    countryOptions,
    stateOptions,
    statesDisabled: anyLoading || countries.length === 0,
    statesLoading,
    cityOptions,
    citiesDisabled: anyLoading || states.length === 0,
    citiesLoading,
    propertyOptions,
    propertiesDisabled: anyLoading || cities.length === 0,
    propertiesLoading,
    groupCities,
    groupProperties,
    groupStates,
  };
};
