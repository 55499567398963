import * as React from 'react';
import { useThumbnailWrapperStyles } from './styles';

interface ThumbnailImageWrapperProps {
  forFullDashboard?: boolean;
}

const ThumbnailImageWrapper: React.FC<ThumbnailImageWrapperProps> = ({
  children,
  forFullDashboard = false,
}) => {
  const classes = useThumbnailWrapperStyles({ forFullDashboard });

  return <div className={classes.wrapper}>{children}</div>;
};

export default ThumbnailImageWrapper;
