import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Icon, Tooltip } from '@jll-labs/azara-ui-components';
import { DataQuality } from '@utils/tableau';
import { Box, useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      border: 'none',
      fontWeight: 'normal',
      lineHeight: theme.spacing(1),
    },
    tagContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    superiorData: {
      backgroundColor: '#97C495',
      color: '#3C4E3B',
    },
    deficientData: { backgroundColor: '#FBDBC2', color: '#A64E07' },
    tagIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(0.8, 0.1),
    },
    tagLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0.2),
      marginRight: theme.spacing(0.5),
    },
    tagTitle: {
      marginTop: theme.spacing(0.2),
    },
  };
};

interface Props {
  dataQuality: DataQuality;
  tooltipLabel?: string;
}

const DashboardTag: React.FC<Props> = ({ dataQuality, tooltipLabel }) => {
  const { t } = useTranslation();
  const {
    superiorData,
    deficientData,
    wrapper,
    tagContent,
    tagIcon,
    tagLabel,
    tagTitle,
  } = useStyles();

  const isSuperior = dataQuality === DataQuality.Superior;

  const TagIcon = (
    <Icon name={isSuperior ? 'Lock' : 'NoEncryption'} fontSize="small" />
  );

  const Tag = (
    <Chip
      sx={[isSuperior ? superiorData : deficientData, wrapper]}
      label={
        <Box sx={tagContent}>
          <Box sx={tagIcon}>{TagIcon}</Box>
          <Box sx={tagLabel}>
            <Box sx={tagTitle}>
              {isSuperior ? t('superiorData') : t('deficientData')}
            </Box>
          </Box>
        </Box>
      }
      size="small"
      style={{ cursor: tooltipLabel ? 'pointer' : 'inherit' }}
    />
  );

  if (!tooltipLabel) {
    return <div>{Tag}</div>;
  }

  return (
    <Tooltip title={tooltipLabel}>
      <div>{Tag}</div>
    </Tooltip>
  );
};

export default DashboardTag;
