import { InsightsAlertScope, InsightsAlertType } from '@apollo-red/hooks';

/**
 * Creates report's cache id.
 *
 * @param reportId report id
 * @param alertId alert id
 * @param alertType type of the alert
 * @param scope alert scope
 */
export const getReportCacheId = (
  reportId: string,
  alertId: string,
  alertType?: InsightsAlertType | null,
  scope?: InsightsAlertScope,
) => {
  switch (alertType) {
    case InsightsAlertType.LeaseEvent:
      return reportId;
    default:
      return `${alertId}-${scope}`;
  }
};
