import { InsightsAlertImportance } from '@apollo-red/schema/generated/operations';
import { InsightsAlertImportanceFilter } from '../insightsAlerts.types';

export const alertImportanceToAlertImportanceFilter: {
  [key in InsightsAlertImportance]: InsightsAlertImportanceFilter;
} = {
  [InsightsAlertImportance.Unassigned]:
    InsightsAlertImportanceFilter.Unassigned,
  [InsightsAlertImportance.High]: InsightsAlertImportanceFilter.High,
  [InsightsAlertImportance.Medium]: InsightsAlertImportanceFilter.Medium,
  [InsightsAlertImportance.Low]: InsightsAlertImportanceFilter.Low,
};
