import { TenantApplication } from '@apollo-red/hooks';
import { TenantReportInput } from '@apollo-red/schema/generated/operations';
import { Report } from '../reports';

export const isPATApplicationEnabledForTenant = (
  applications: TenantApplication[],
) => {
  return applications?.some(({ app }) => app.app_cat === 'PAT');
};

export const isApplicationEnabled = (
  appCat: string,
  applications: TenantApplication[],
) => {
  return applications.some(
    ({ app, app_provisioned }) =>
      app.app_cat === appCat && app_provisioned && app.app_active,
  );
};

export const updateTenantReportsElements = (
  reports: Report[],
  elements: TenantReportInput[],
) => {
  return reports.map(report => {
    const updatedReport = elements.find(ru => ru.report_id === report.id);

    if (!updatedReport) {
      return report;
    }

    return {
      ...report,
      report_custom_name:
        updatedReport.custom_name ?? report.report_custom_name,
      report_hidden: updatedReport.hidden ?? report.report_hidden,
      report_custom_categories: updatedReport.custom_category
        ? [
            {
              id: updatedReport.custom_category,
              category_description: updatedReport.custom_category,
            },
          ]
        : report.report_custom_categories,
    };
  });
};
