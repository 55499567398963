import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { UPDATE_USER_PREFERRED_CONTACT } from '@apollo-red/mutations';
import { useGetUserInfo } from '../../user';

export const useUpdateUserPreferredContact = () => {
  const { user } = useGetUserInfo();
  const [updateUserPreferredContact] = useAppMutation<
    SchemaTypes.UpdateUserContactMutation,
    SchemaTypes.UpdateUserContactMutationVariables
  >(UPDATE_USER_PREFERRED_CONTACT);

  return {
    updateUserPreferredContact: (preferredContact: string) => {
      return updateUserPreferredContact({
        variables: {
          data: {
            user_settings: {
              preferred_contact: preferredContact,
            },
          },
          where: {
            id: user.id,
          },
        },
        optimisticResponse: {
          updateUser: {
            id: user.id,
            user_settings: {
              preferred_contact: preferredContact,
              __typename: 'UserSettings',
            },
            __typename: 'User',
          },
        },
      });
    },
  };
};
