import * as React from 'react';
import CategoricalFilterOptions from './CategoricalFilterOptions';
import { useCategoricalFilter } from './useCategoricalFilter';
import { FilterExtended, ApplyFilterProps } from '../../types';
import OptionBox from '../OptionBox';

interface Props {
  applyFilter: ApplyFilterProps;
  filter: FilterExtended;
  loading: boolean;
}

const CategoricalFilter: React.FC<Props> = ({
  applyFilter,
  filter,
  loading,
}) => {
  const {
    filterOptions,
    filterState,
    handleClear,
    handleIncludeExclude,
    handlePinUnpin,
    selectedOption,
    updateFilter,
  } = useCategoricalFilter(filter, applyFilter);
  const { isExcluded, isPinned, selectedValues } = filterState;

  return (
    <OptionBox
      name={filter.label}
      pinned={isPinned}
      handlePinUnpin={handlePinUnpin}
      handleIncludeExclude={handleIncludeExclude}
      exclude={isExcluded}
      loading={loading}
      filtersApplied={selectedValues && selectedValues?.length > 0}
    >
      <CategoricalFilterOptions
        filter={filter}
        filterOptions={filterOptions}
        filterState={filterState}
        loading={loading}
        onClear={handleClear}
        selectedOption={selectedOption}
        updateFilter={updateFilter}
      />
    </OptionBox>
  );
};

export default CategoricalFilter;
