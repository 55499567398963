import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_USER_TENANT_INSIGHTS_ALERTS } from '@apollo-red/queries';
import { useAppQuery } from '../../../queries';

interface GetInsightsAlertFeed {
  userId: string;
  tenantId: string;
  assignedUserId?: string;
  importance?: SchemaTypes.InsightsAlertImportance;
  alertType?: SchemaTypes.InsightsAlertType;
  status?: SchemaTypes.InsightsAlertStatus;
  alertTab?: SchemaTypes.InsightsAlertTab;
  orderBy?: SchemaTypes.InsightsAlertOrderBy;
  alertSeverity?: SchemaTypes.InsightsAlertSeverity;
  continuationToken?: string;
  cursor?: string;
  first?: number;
  pollInterval?: number;
  skip?: boolean;
}

export const useGetInsightsAlertsQuery = ({
  userId,
  tenantId,
  importance,
  alertType,
  status,
  alertTab,
  orderBy,
  pollInterval,
  continuationToken,
  skip,
  assignedUserId,
  alertSeverity,
}: GetInsightsAlertFeed) => {
  return useAppQuery<
    SchemaTypes.GetInsightsAlertsQuery,
    SchemaTypes.GetInsightsAlertsQueryVariables
  >(GET_USER_TENANT_INSIGHTS_ALERTS, {
    variables: {
      where: {
        id: userId,
      },
      whereInsightsAlerts: {
        tenant_id: tenantId,
        assigned_user_id: assignedUserId,
        importance: importance,
        alert_type: alertType,
        status: status,
        alert_tab: alertTab,
        order_by: orderBy,
        severity: alertSeverity,
        continuation_token: continuationToken,
      },
    },
    skip: !userId || !tenantId || skip,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });
};
