import { useEffect, useState, useCallback } from 'react';

export function useSessionStorage<T>(
  key: string,
  initialValue?: T,
): [T | undefined, (value: T | undefined) => void, () => void] {
  const readValue = useCallback(() => {
    if (typeof window.sessionStorage === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T | undefined>(readValue);

  const setValue = (value: T) => {
    if (typeof window.sessionStorage === 'undefined') {
      return;
    }

    try {
      if (!value) {
        window.sessionStorage.removeItem(key);
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      }

      setStoredValue(value);
    } catch (error) {
      console.warn(`Error setting sessionStorage key "${key}":`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clear = useCallback(() => {
    if (typeof window.sessionStorage === 'undefined') {
      return;
    }

    window.sessionStorage.clear();
    setStoredValue(undefined);
  }, []);

  return [storedValue, setValue, clear];
}
