import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAddSavedViewToUser,
  useGetUserInfo,
  useToggleFavorite,
  ReportExtended,
} from '@apollo-red/hooks';
import { Icon, FavoriteIcon } from '@jll-labs/azara-ui-components';
import SaveCustomViewDialog from '@components/fullReport/SaveCustomViewDialog';
import LoadCustomViewDialog from '@components/fullReport/LoadCustomViewDialog';
import { Viz } from '@components/tableau/types';
import { useFeatureFlag } from '@utils/launchDarkly';
import {
  usePendoTrackDashboardEvent,
  DashboardToolbarEventMetadata,
} from '@utils/pendo';
import { ButtonGroup, ToolbarButton } from './types';

export const useToolbar = (
  report: ReportExtended,
  dashboardEventProps: DashboardToolbarEventMetadata,
  viz?: Viz,
  handleReset?: () => Promise<void>,
  isPATReport?: boolean,
) => {
  const { user } = useGetUserInfo();
  const { t } = useTranslation();
  const [isSaveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [isLoadDialogOpen, setLoadDialogOpen] = React.useState(false);
  const [exportEnabled, setExportEnabled] = React.useState(false);
  const { addSavedViewToUser } = useAddSavedViewToUser();
  const { toggleFavorite } = useToggleFavorite();
  const dashboardsDownloadDataRefresh = useFeatureFlag(
    'dashboardsDownloadDataRefresh',
  );
  const { trackDashboardEvent } = usePendoTrackDashboardEvent();
  const exportSheetName = 'Export';

  React.useEffect(() => {
    const workbook = viz?.getWorkbook();
    const sheets = workbook?.getPublishedSheetsInfo();
    const exportSheet = sheets?.find(
      sheet => sheet.getName() === exportSheetName,
    );

    setExportEnabled(!!exportSheet);
  }, [viz]);

  const handleOpenSaveDialog = () => {
    trackDashboardEvent('dashboardSaveView', dashboardEventProps);
    setSaveDialogOpen(true);
  };

  const handleOpenLoadDialog = () => {
    trackDashboardEvent('dashboardLoadView', dashboardEventProps);
    setLoadDialogOpen(true);
  };

  const handleSaveCustomView = async (customViewName: string) => {
    if (!viz) {
      return '';
    }

    const createdView = await viz
      .getWorkbook()
      .rememberCustomViewAsync(customViewName);

    const [parsedName] = createdView.getUrl().split('/').reverse();

    const { data } = await addSavedViewToUser(
      user.id,
      report.report_parent || report.id,
      customViewName,
      parsedName,
    );

    return data?.addSavedViewToUser.saved_report.id ?? '';
  };

  const handlePrintToPdf = async () => {
    if (!viz) {
      return;
    }

    trackDashboardEvent('dashboardDownloadPDF', dashboardEventProps);

    if (dashboardsDownloadDataRefresh && !isPATReport) {
      await viz.refreshDataAsync();
    }

    viz.showExportPDFDialog();
  };

  const handlePrintToCSV = async () => {
    if (!viz || !exportEnabled) {
      return;
    }

    trackDashboardEvent('dashboardDownloadCSV', dashboardEventProps);

    if (dashboardsDownloadDataRefresh && !isPATReport) {
      await viz.refreshDataAsync();
    }

    const workbook = viz.getWorkbook();
    const activeSheetName = workbook.getActiveSheet().getName();

    await workbook.activateSheetAsync(exportSheetName);
    viz.showExportCrossTabDialog(exportSheetName);
    await workbook.activateSheetAsync(activeSheetName);
  };

  const handleShareViz = () => {
    if (!viz) {
      return;
    }

    trackDashboardEvent('dashboardShare', dashboardEventProps);

    viz.showShareDialog();
  };

  const handleExportImage = async () => {
    if (!viz) {
      return;
    }

    trackDashboardEvent('dashboardDownloadImage', dashboardEventProps);

    if (dashboardsDownloadDataRefresh && !isPATReport) {
      await viz.refreshDataAsync();
    }

    viz.showExportImageDialog();
  };

  const handleResetFilters = async () => {
    if (!viz) {
      return;
    }

    trackDashboardEvent('dashboardRevert', dashboardEventProps);

    if (handleReset) {
      await handleReset();
    }

    if (isPATReport) {
      await viz.revertAllAsync();
    }
  };

  const handleFavorites = () => {
    if (report?.isFav) {
      trackDashboardEvent('dashboardUnfavorited', dashboardEventProps);
    } else {
      trackDashboardEvent('dashboardFavorited', dashboardEventProps);
    }
    toggleFavorite(report);
  };

  const toolbarButtons: ToolbarButton[] = [
    {
      name: 'save',
      icon: <Icon name="Save" />,
      onClick: handleOpenSaveDialog,
      component: (
        <SaveCustomViewDialog
          key="save"
          isOpen={isSaveDialogOpen}
          setOpen={setSaveDialogOpen}
          handleSaveCustomView={handleSaveCustomView}
        />
      ),
    },
    {
      name: 'load',
      icon: <Icon name="Backup" />,
      onClick: handleOpenLoadDialog,
      component: viz && (
        <LoadCustomViewDialog
          key="load"
          isOpen={isLoadDialogOpen}
          setOpen={setLoadDialogOpen}
          viz={viz}
        />
      ),
    },
    {
      name: 'pdf',
      icon: <Icon name="CloudDownload" />,
      onClick: handlePrintToPdf,
      buttonGroup: ButtonGroup.DOWNLOAD,
    },
    {
      name: 'csv',
      icon: <Icon name="CloudDownload" />,
      onClick: handlePrintToCSV,
      buttonGroup: ButtonGroup.DOWNLOAD,
      exclude: !exportEnabled,
    },
    {
      name: 'share',
      icon: <Icon name="Share" />,
      onClick: handleShareViz,
      tooltip: t('share'),
    },
    {
      name: 'favorites',
      icon: <FavoriteIcon fav={report.isFav} disabled={!viz} />,
      onClick: handleFavorites,
      tooltip: t('favorite'),
    },
    {
      name: 'image',
      icon: <Icon name="Image" />,
      onClick: handleExportImage,
      buttonGroup: ButtonGroup.DOWNLOAD,
    },
    {
      name: 'revert',
      icon: <Icon name="Restore" />,
      onClick: handleResetFilters,
      tooltip: t('revert'),
    },
  ].filter(toolbarButton => !toolbarButton.exclude);

  return {
    toolbarButtons,
  };
};
