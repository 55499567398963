import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Report,
  ReportExtended,
  isPATApplicationEnabledForTenant,
  useGetAllTenantApplications,
  useGetUserDefaultTenantId,
} from '@apollo-red/hooks';
import { useParamsCustom } from '@utils/router';
import { PageContainerContext } from '@components/pageContainer';
import { DashboardProps } from '../types';
import BaseDashboards from './BaseDashboards';

interface MatchParams {
  keywordName: string;
}

interface Props extends DashboardProps {
  useSaveHandler: (reports: Report[]) => { saveHandler: () => void };
}

const BaseSearchDashboards: React.FC<Props> = ({
  reports,
  useSaveHandler,
  drag,
  dashboardRoutes,
}) => {
  const { t } = useTranslation();
  const currentTenantId = useGetUserDefaultTenantId();
  const { applications } = useGetAllTenantApplications(currentTenantId);

  const isPATEnabled = isPATApplicationEnabledForTenant(applications);
  const pageContext = React.useContext(PageContainerContext);
  const { keywordName } = useParamsCustom<MatchParams>();
  const filteredItems: ReportExtended[] = [];

  const filteredReports = () => {
    reports.forEach(report => {
      report.report_full_keywords.map(item => {
        if (item.keyword_name === keywordName) {
          filteredItems.push(report);
        }
      });
    });
    return filteredItems;
  };

  React.useEffect(() => {
    pageContext.setSearchedPhrase(keywordName);
    pageContext.expandSearchbar();
  }, [keywordName, pageContext]);

  return (
    <BaseDashboards
      reports={filteredReports()}
      title={isPATEnabled ? t('allCoreDashboards') : t('allDashboards')}
      breadcrumbsParts={[{ label: t('search') }]}
      breadcrumbsTitle={t('search')}
      dashboardRoutes={dashboardRoutes}
      drag={drag}
      autoOverflow={true}
      useSaveHandler={useSaveHandler}
      categoryId="all"
    />
  );
};

export default BaseSearchDashboards;
