import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_TENANT_REPORT_CONFIGURATION } from '@apollo-red/queries';
import { useAppQuery } from '../queries';

export type ReportConfig = SchemaTypes.ReportConfig;
export type BaselineReportInteractionConfiguration =
  SchemaTypes.BaselineReportInteractionConfiguration;

export const useGetTenantReportConfigurationData = (
  payload: SchemaTypes.TenantReportConfigurationInput,
) => {
  const { data, loading } = useAppQuery<
    SchemaTypes.GetTenantReportConfigurationQuery,
    SchemaTypes.GetTenantReportConfigurationQueryVariables
  >(GET_TENANT_REPORT_CONFIGURATION, {
    variables: {
      where: payload,
    },
  });

  return { loading, reportConfiguration: data?.getTenantReportConfiguration };
};
