import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ThumbnailError, { ThumbnailErrorProps } from './ThumbnailError';

type TimeoutThumbnailErrorProps = Pick<
  ThumbnailErrorProps,
  'forFullDashboard' | 'handleRetry' | 'isSidebarExpanded'
>;

const TimeoutThumbnailError: React.FC<TimeoutThumbnailErrorProps> = ({
  forFullDashboard,
  handleRetry,
  isSidebarExpanded,
}) => {
  const { t } = useTranslation();

  return React.useMemo(
    () => (
      <ThumbnailError
        forFullDashboard={forFullDashboard}
        isSidebarExpanded={isSidebarExpanded}
        icon="IssueTimeOut"
        title={t('tableauTimeout')}
        message={
          <Trans i18nKey={'tableauTimeoutRetry'}>
            {t('tableauTimeoutRetry')}
          </Trans>
        }
        handleRetry={handleRetry}
      />
    ),
    [forFullDashboard, handleRetry, isSidebarExpanded, t],
  );
};

export default TimeoutThumbnailError;
