import { gql } from '@apollo/client';

export const ApiVersionBase = {
  fragment: gql`
    fragment ApiVersionBase on ApiVersion {
      version
      build_date
      tenant_qa_type
    }
  `,
};
