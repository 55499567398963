import * as React from 'react';
import { useGetUserCurrentTenantReports } from '@apollo-red/hooks';
import { BaseAllDashboards } from '../baseDashboards';
import { useRoutes } from '@routes';

const useSaveHandler = () => {
  return {
    saveHandler: () => {},
  };
};

const AllDashboards: React.FC = () => {
  const routes = useRoutes();
  const { allReports } = useGetUserCurrentTenantReports();

  return (
    <BaseAllDashboards
      reports={allReports}
      useSaveHandler={useSaveHandler}
      drag={true}
      showKeyMetrics={true}
      dashboardRoutes={routes.dashboards}
    />
  );
};

export default AllDashboards;
