import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import { Typography, Grid, Box } from '@mui/material';
import { Icon, utils } from '@jll-labs/azara-ui-components';
import { useApiVersion } from '@apollo-red/hooks';
import { main } from '@routes';
import ErrorScreen, { MainIcon } from './ErrorScreen';

const { getFormattedDate, getTimezoneName } = utils;

interface LocationState {
  start: string;
  end: string;
}

const defaultState = { start: '', end: '' };

const Maintenance: React.FC = () => {
  const { t } = useTranslation();
  const apiVersion = useApiVersion(10000);
  const { state = defaultState } = useLocation<LocationState | undefined>();
  const history = useHistory();
  const { start, end } = state;

  const startTime = getFormattedDate(start);
  const endTime = getFormattedDate(end);

  React.useEffect(() => {
    if (apiVersion.hasData) {
      history.push(main.landing.getLink());
    }
  }, [apiVersion.hasData, history]);

  return (
    <ErrorScreen
      statusMsg={t('maintenanceStatusMsg')}
      secondaryMsg={t('maintenanceSecondaryMsg')}
      detailsMsg={t('maintenanceDetailsMsg')}
      icon={MainIcon.Build}
    >
      <Box my="2em">
        <Grid container={true} justifyContent="center" alignItems="center">
          <Box mx="1em">
            <Icon name="AccessAlarm" />
          </Box>
          <Typography align="center" variant="body1" component="div">
            {t('maintenanceStart')} <strong>{startTime}</strong> -{' '}
            {t('maintenanceEnd')} <strong>{endTime}</strong> {getTimezoneName()}
          </Typography>
        </Grid>
      </Box>
    </ErrorScreen>
  );
};

export default Maintenance;
