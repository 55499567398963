import { gql } from '@apollo/client';

export const SET_INSIGHTS_ALERT_SEVERITY = gql`
  mutation setInsightsAlertSeverity(
    $where: WhereSetInsightsAlertSeverityInput!
  ) {
    setInsightsAlertSeverity(where: $where)
  }
`;

export const SET_INSIGHTS_ALERT_STATUS = gql`
  mutation setInsightsAlertStatus($where: WhereSetInsightsAlertStatusInput!) {
    setInsightsAlertStatus(where: $where)
  }
`;

export const SET_INSIGHTS_ALERT_ROI_DATA = gql`
  mutation setInsightsAlertRoiData($where: WhereSetInsightsAlertRoiDataInput!) {
    setInsightsAlertRoiData(where: $where)
  }
`;

export const SET_INSIGHTS_ALERT_ASSIGNED_USER = gql`
  mutation setInsightsAlertAssignedUser(
    $where: WhereSetInsightsAlertAssignedUserInput!
  ) {
    setInsightsAlertAssignedUser(where: $where)
  }
`;

export const SET_INSIGHTS_ALERT_USER_READ = gql`
  mutation setInsightsAlertUserRead(
    $where: WhereSetInsightsAlertUserReadInput!
  ) {
    setInsightsAlertUserRead(where: $where)
  }
`;

export const SET_INSIGHTS_ALERT_USER_IMPORTANCE = gql`
  mutation setInsightsAlertUserImportance(
    $where: WhereSetInsightsAlertUserImportanceInput!
  ) {
    setInsightsAlertUserImportance(where: $where)
  }
`;

export const ADD_INSIGHTS_ALERT_USER_COMMENT = gql`
  mutation addInsightsAlertUserComment(
    $where: WhereAddInsightsAlertUserCommentInput!
  ) {
    addInsightsAlertUserComment(where: $where)
  }
`;

export const REMOVE_INSIGHTS_ALERT_ASSIGNED_USER = gql`
  mutation removeInsightsAlertAssignedUser(
    $where: WhereRemoveInsightsAlertAssignedUserInput!
  ) {
    removeInsightsAlertAssignedUser(where: $where)
  }
`;
