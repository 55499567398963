import * as React from 'react';
import { InsightsAlertType } from '@apollo-red/hooks';

const WorkOrderVolumeRecommendation = () => {
  return (
    <div>
      Investigate the following options and if necessary, take action:
      <ul>
        <li>
          Review the work order history to determine if you need to address any
          recurring issues.
        </li>
        <li>
          Review your preventive maintenance program to determine its
          effectiveness and optimal schedule.
        </li>
        <li>
          Consider switching to an alternative service provider when necessary.
        </li>
      </ul>
    </div>
  );
};

const LeaseEventsRecommendation = () => {
  return <div>Consult your transaction manager to review your options.</div>;
};

const FacilitiesExperienceRecommendation = () => {
  return (
    <div>
      Review work order activity history to make sure all tasks are on track to
      be completed on time or notify requester of expected completion date.
    </div>
  );
};

const Covid19Recommendation = () => {
  return (
    <div>
      Review work order activity history to make sure all tasks are on track to
      be completed on time or notify requester of expected completion date
    </div>
  );
};

const MarketBenchmarkRecommendation = () => {
  return (
    <div>
      Investigate the following options and if necessary, take action:
      <ul>
        <li>
          Review your lease agreement to understand the specific terms of your
          contract.
        </li>
        <li>
          Review market data to understand how your property costs compare to
          others.
        </li>
        <li>
          Work with your transaction manager or local broker to review your
          options.
        </li>
      </ul>
    </div>
  );
};

const OccupancyEfficiencyRecommendation = () => {
  return (
    <div>
      Review Occupancy Details dashboard to understand details about space types
      and uses.
    </div>
  );
};

const SubleaseRiskRecommendation = () => {
  return <div>Review the identified events against portfolio strategy.</div>;
};

const SameDayWorkOrderCompletionRecommendation = () => {
  return (
    <div>
      <ul>
        <li>
          Determine whether the correct priority was selected for the request.
        </li>
        <li>
          Determine whether there are opportunities for process improvement that
          could increase timely response to requests.
        </li>
      </ul>
    </div>
  );
};

export const alertsRecommendation: Record<InsightsAlertType, JSX.Element> = {
  covid_19_work_order: <Covid19Recommendation />,
  facilities_experience: <FacilitiesExperienceRecommendation />,
  lease_event: <LeaseEventsRecommendation />,
  workorder_volume: <WorkOrderVolumeRecommendation />,
  market_benchmark: <MarketBenchmarkRecommendation />,
  occupancy_efficiency: <OccupancyEfficiencyRecommendation />,
  sublease_risk: <SubleaseRiskRecommendation />,
  same_day_work_order_completion: <SameDayWorkOrderCompletionRecommendation />,
};
