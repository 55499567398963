import { gql } from '@apollo/client';

export const KeywordBase = {
  fragment: gql`
    fragment KeywordBase on Keyword {
      id
      keyword_name
    }
  `,
};
