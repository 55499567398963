import { gql } from '@apollo/client';
import { CountryBase } from '@apollo-red/fragments';

export const GET_ALL_COUNTRIES = gql`
  query getCountries {
    getCountries {
      ...CountryBase
    }
  }
  ${CountryBase.fragment}
`;
