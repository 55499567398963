import { paperStyle, utils } from '@jll-labs/azara-ui-components';
import { Theme, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export interface TileStyleProps {
  isOver?: boolean;
  isDragging?: boolean;
}

export const { useSpacingStyles } = utils;

export const useTileStyles = ({
  isDragging,
  isOver,
}: TileStyleProps): Record<string, SystemStyleObject<Theme>> => {
  const theme = useTheme();

  return {
    card: {
      borderRadius: paperStyle.borderRadius,
      position: 'relative',
      overflow: 'unset',
      [theme.breakpoints.down('md')]: {
        minHeight: '202px',
        minWidth: '212px',
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: '196px',
        minWidth: '210px',
      },
    },
    cardContent: {
      paddingBottom: 0,
      color: theme.palette.text.secondary,
    },
    dndForeground: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor:
        isOver && !isDragging
          ? theme.palette.secondary.main
          : theme.palette.action.hover,
      zIndex: 3,
    },
    dndBackground: {
      position: 'absolute',
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      backgroundImage: `
    linear-gradient(45deg, ${
      isOver && !isDragging
        ? theme.palette.action.active
        : theme.palette.text.secondary
    } 25%, transparent 25%),
    linear-gradient(-45deg, ${
      isOver && !isDragging
        ? theme.palette.action.active
        : theme.palette.text.secondary
    } 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${
      isOver && !isDragging
        ? theme.palette.action.active
        : theme.palette.text.secondary
    } 75%),
    linear-gradient(-45deg, transparent 75%, ${
      isOver && !isDragging
        ? theme.palette.action.active
        : theme.palette.text.secondary
    } 75%)`,
      backgroundSize: '20px 20px',
      backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
      zIndex: isDragging ? 2 : -1,
    },
    savedIcon: {
      fontSize: '1rem',
      marginLeft: 5,
    },
    title: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      wordBreak: 'break-word',
    },
    titleSection: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
};

export const useTileFooterStyles = () => {
  const theme = useTheme();

  return {
    actions: {
      borderTop: `1px solid ${theme.palette.secondary.dark}`,
      padding: theme.spacing(0.5, 1),
    },
    actionIcon: {
      margin: theme.spacing(0.5),
    },
    btnLink: {
      color: theme.palette.text.secondary,
    },
    customReportIcon: {
      color: theme.palette.text.secondary,
    },
    flipped: {
      color: theme.palette.text.secondary,
      transform: 'scale(-1, 1)',
    },
  };
};
