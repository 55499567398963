import React from 'react';

export const usePendoScreenInfo = () =>
  React.useMemo(
    () => ({
      screenInfo: {
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        screenAvailHeight: window.screen.availHeight,
        screenAvailWidth: window.screen.availWidth,
        screenColorDepth: window.screen.colorDepth,
        screenPixelDepth: window.screen.pixelDepth,
      },
    }),
    [],
  );
