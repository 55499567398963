import * as React from 'react';
import Preview from 'react-dnd-preview';
import Grid from '@mui/material/Grid';
import { utils, DragHandle } from '@jll-labs/azara-ui-components';
import type { OnDropEnd } from '@jll-labs/azara-ui-components/types/utils';
import { ReportExtended } from '@apollo-red/hooks';
import Tile from '@components/tile/Tile';
import TileGridItem from './TileGridItem';
import { DashboardsPopupTypes } from './DashboardsPopup';
import { DashboardRouteProps } from '@pages/dashboards/types';

const { useParentDnd, useUpBreakpoints, getBreakpointValue, ScrollContext } =
  utils;

interface Props {
  reports: ReportExtended[];
  categoryId: string;
  handleDialogOpen: (type: DashboardsPopupTypes, reportId: string) => void;
  onDropEnd?: OnDropEnd;
  draggable?: boolean;
  dashboardRoutes: DashboardRouteProps;
}

const TilesGrid: React.FC<Props> = ({
  reports,
  categoryId,
  handleDialogOpen,
  onDropEnd,
  draggable,
  dashboardRoutes,
}) => {
  const ref = React.useRef<HTMLDivElement>();
  const { addScrollListener, removeScrollListener } =
    React.useContext(ScrollContext);

  const {
    dragged,
    orderedElements,
    onDragBegin,
    onItemDropEnd,
    updateElementsOrder,
  } = useParentDnd(reports, onDropEnd, {
    addScrollListener: addScrollListener,
    removeScrollListener: removeScrollListener,
  });
  const matches = useUpBreakpoints();

  const previewGenerator = React.useCallback(
    ({ style }) => {
      if (!dragged) {
        return null;
      }

      const column = getBreakpointValue(
        { xs: 12, sm: 6, lg: 4, xl: 3 },
        matches,
      );
      const rowItemsNum = 12 / column;

      return (
        <Grid
          container={true}
          spacing={2}
          style={{
            ...style,
            zIndex: 5,
            width: ref.current?.offsetWidth,
            left:
              -(ref.current?.offsetWidth ?? 0) / rowItemsNum +
              rowItemsNum * 16 +
              16,
          }}
        >
          <Grid item={true} xs={12} sm={6} lg={4} xl={3}>
            <Tile
              categoryId={categoryId}
              toggleFavorite={() => {}}
              handleDialogOpen={() => {}}
              report={dragged.element}
              dragHandle={
                draggable && (
                  <DragHandle fontSize="large" viewBox="-7 0 24 24" />
                )
              }
              dashboardRoutes={dashboardRoutes}
            />
          </Grid>
        </Grid>
      );
    },
    [categoryId, draggable, dragged, matches, dashboardRoutes],
  );

  return (
    <>
      <Preview generator={previewGenerator} />
      <Grid
        container={true}
        spacing={2}
        ref={
          ref.current ? (ref as React.MutableRefObject<HTMLDivElement>) : null
        }
      >
        {orderedElements.map(report => (
          <TileGridItem
            key={report.id}
            report={report}
            categoryId={categoryId}
            handleDialogOpen={handleDialogOpen}
            onDragBegin={onDragBegin}
            onItemDropEnd={onItemDropEnd}
            updateElementsOrder={updateElementsOrder}
            draggable={draggable}
            dashboardRoutes={dashboardRoutes}
          />
        ))}
      </Grid>
    </>
  );
};

export default TilesGrid;
