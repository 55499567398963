import * as React from 'react';
import { TenantQaType } from './types';

export type QaEnvironmentIdentifier = '' | 'app' | 'demo' | 'qa';

export interface UseGetEnvironmentType {
  environmentType: TenantQaType;
  environmentIdentifier: QaEnvironmentIdentifier;
}

export const tenantQaTypeToQaEnvironmentIdentifier: Record<
  TenantQaType,
  QaEnvironmentIdentifier
> = {
  [TenantQaType.Demo]: 'demo',
  [TenantQaType.Nonwd]: 'app',
  [TenantQaType.Wdqa]: 'qa',
  [TenantQaType.Wdlive]: '',
};

export const clientApiEnvToTenantQaType: Record<string, TenantQaType> = {
  demo: TenantQaType.Demo,
  nonwd: TenantQaType.Nonwd,
  wdlive: TenantQaType.Wdlive,
  wdqa: TenantQaType.Wdqa,
};

export const useGetEnvironmentType = (): UseGetEnvironmentType => {
  return React.useMemo(() => {
    const clientApiEnv = process.env.CLIENT_API_ENV || '';
    const environmentType =
      clientApiEnvToTenantQaType[clientApiEnv] ?? TenantQaType.Wdlive;
    const environmentIdentifier =
      tenantQaTypeToQaEnvironmentIdentifier[environmentType];

    return {
      environmentType,
      environmentIdentifier,
    };
  }, []);
};
