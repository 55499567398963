import '@components/pendo/types';

export interface PendoTrackEventCommonMetadata extends pendo.Metadata {
  clientId: string;
  clientName: string;
  userId: string;
  userEmail: string;
}

export interface PendoTrackEventStandardMetadata
  extends PendoTrackEventCommonMetadata {
  userFullName: string;
  alertType: string;
  alertThreadId: string;
  dashboardId: string;
  dashboardName: string;
  dashboardType: string;
  dashboardOriginalCategory: string;
  dashboardCategory: string;
  dashboardUrl: string;
  dashboardLoadTime: number;
  dashboardViewTime: number;
  pageName: string;
  pageViewTime: number;
  kpiName: string;
  initialCountry: string;
  initialState: string;
  initialCity: string;
  country: string;
  state: string;
  city: string;
}

type AlertFilterMetadata = Pick<PendoTrackEventStandardMetadata, 'alertType'>;

type AlertMetadata = Pick<
  PendoTrackEventStandardMetadata,
  'alertType' | 'alertThreadId'
>;

type DashboardLoadMetadata = Pick<
  PendoTrackEventStandardMetadata,
  | 'dashboardId'
  | 'dashboardName'
  | 'dashboardType'
  | 'dashboardOriginalCategory'
  | 'dashboardCategory'
  | 'dashboardUrl'
  | 'dashboardLoadTime'
>;

type DashboardViewMetadata = Pick<
  PendoTrackEventStandardMetadata,
  | 'dashboardId'
  | 'dashboardName'
  | 'dashboardType'
  | 'dashboardOriginalCategory'
  | 'dashboardCategory'
  | 'dashboardUrl'
  | 'dashboardViewTime'
>;

export type DashboardToolbarEventMetadata = Pick<
  PendoTrackEventStandardMetadata,
  | 'dashboardId'
  | 'dashboardName'
  | 'dashboardType'
  | 'dashboardOriginalCategory'
  | 'dashboardCategory'
  | 'dashboardUrl'
>;

type PageViewMetadata = Pick<
  PendoTrackEventStandardMetadata,
  'pageName' | 'pageViewTime'
>;

type UserLogoutMetadata = Pick<
  PendoTrackEventStandardMetadata,
  'userEmail' | 'clientId'
>;

type DashboardHideUnhideMetadata = Pick<
  PendoTrackEventStandardMetadata,
  'dashboardName'
>;

type KpiHideUnhideMetadata = Pick<PendoTrackEventStandardMetadata, 'kpiName'>;

type LocationFiltersMetadata = Pick<
  PendoTrackEventStandardMetadata,
  | 'initialCountry'
  | 'initialState'
  | 'initialCity'
  | 'country'
  | 'state'
  | 'city'
  | 'pageName'
>;

type PendoTrackEventNameMap = {
  alertFilterAlertType: 'Alert Filter Alert Type';
  alertRoi: 'Alert Add/Edit ROI';
  alertRead: 'Alert Read';
  alertShowNotificationDetails: 'Alert Show Notification Details';
  alertDismiss: 'Alert Dismiss';
  alertAssignSeverity: 'Alert Assign Severity';
  alertShowRecommendation: 'Alert Show Recommendation';
  alertAddComment: 'Alert Add Comment';
  alertAssignToUser: 'Alert Assign To User';
  alertSetImportance: 'Alert Set Importance';
  alertCloseAlert: 'Alert Close Alert';
  alertMarkedForDataQualityReview: 'Alert Marked For Data Quality Review';
  dashboardLoad: 'Dashboard Load';
  dashboardView: 'Dashboard View';
  dashboardSaveView: 'Dashboard Save View';
  dashboardLoadView: 'Dashboard Load View';
  dashboardFavorited: 'Dashboard Favorited';
  dashboardUnfavorited: 'Dashboard Unfavorited';
  dashboardShare: 'Dashboard Share';
  dashboardRevert: 'Dashboard Revert';
  dashboardDownloadCSV: 'Dashboard Download CSV';
  dashboardDownloadImage: 'Dashboard Download Image';
  dashboardDownloadPDF: 'Dashboard Download PDF';
  dashboardHelp: 'Dashboard Help';
  dashboardHiddenClient: 'Dashboard Hidden - Client';
  dashboardUnhiddenClient: 'Dashboard Unhidden - Client';
  dashboardHiddenUser: 'Dashboard Hidden - User';
  dashboardUnhiddenUser: 'Dashboard Unhidden - User';
  pageView: 'Page View';
  userLogout: 'User Logout';
  kpiHiddenClient: 'KPI Hidden - Client';
  kpiUnhiddenClient: 'KPI Unhidden - Client';
  locationFiltersSelected: 'Location Filters Selected';
  locationFiltersUnselected: 'Location Filters Unselected';
  locationFiltersEdited: 'Location Filters Edited';
};

type PendoTrackEventTypeMap = {
  alertFilterAlertType: AlertFilterMetadata;
  alertRoi: AlertMetadata;
  alertRead: AlertMetadata;
  alertShowNotificationDetails: AlertMetadata;
  alertDismiss: AlertMetadata;
  alertAssignSeverity: AlertMetadata;
  alertShowRecommendation: AlertMetadata;
  alertAddComment: AlertMetadata;
  alertAssignToUser: AlertMetadata;
  alertSetImportance: AlertMetadata;
  alertCloseAlert: AlertMetadata;
  alertMarkedForDataQualityReview: AlertMetadata;
  dashboardLoad: DashboardLoadMetadata;
  dashboardView: DashboardViewMetadata;
  dashboardSaveView: DashboardToolbarEventMetadata;
  dashboardLoadView: DashboardToolbarEventMetadata;
  dashboardFavorited: DashboardToolbarEventMetadata;
  dashboardUnfavorited: DashboardToolbarEventMetadata;
  dashboardShare: DashboardToolbarEventMetadata;
  dashboardRevert: DashboardToolbarEventMetadata;
  dashboardDownloadCSV: DashboardToolbarEventMetadata;
  dashboardDownloadImage: DashboardToolbarEventMetadata;
  dashboardDownloadPDF: DashboardToolbarEventMetadata;
  dashboardHelp: DashboardToolbarEventMetadata;
  dashboardHiddenClient: DashboardHideUnhideMetadata;
  dashboardUnhiddenClient: DashboardHideUnhideMetadata;
  dashboardHiddenUser: DashboardHideUnhideMetadata;
  dashboardUnhiddenUser: DashboardHideUnhideMetadata;
  pageView: PageViewMetadata;
  userLogout: UserLogoutMetadata;
  kpiHiddenClient: KpiHideUnhideMetadata;
  kpiUnhiddenClient: KpiHideUnhideMetadata;
  locationFiltersSelected: LocationFiltersMetadata;
  locationFiltersUnselected: LocationFiltersMetadata;
  locationFiltersEdited: LocationFiltersMetadata;
};

export type PendoTrackEventType = keyof PendoTrackEventTypeMap;

export type PendoTrackEventMetadata<T extends PendoTrackEventType> =
  PendoTrackEventTypeMap[T];

type PendoTrackEventNameKey = keyof PendoTrackEventNameMap;

export type PendoTrackEventName<T extends PendoTrackEventNameKey> =
  PendoTrackEventNameMap[T];

export const trackEventTypeToName: {
  [key in PendoTrackEventNameKey]: PendoTrackEventName<key>;
} = {
  alertFilterAlertType: 'Alert Filter Alert Type',
  alertRoi: 'Alert Add/Edit ROI',
  alertRead: 'Alert Read',
  alertShowNotificationDetails: 'Alert Show Notification Details',
  alertDismiss: 'Alert Dismiss',
  alertAssignSeverity: 'Alert Assign Severity',
  alertShowRecommendation: 'Alert Show Recommendation',
  alertAddComment: 'Alert Add Comment',
  alertAssignToUser: 'Alert Assign To User',
  alertSetImportance: 'Alert Set Importance',
  alertCloseAlert: 'Alert Close Alert',
  alertMarkedForDataQualityReview: 'Alert Marked For Data Quality Review',
  dashboardLoad: 'Dashboard Load',
  dashboardView: 'Dashboard View',
  dashboardSaveView: 'Dashboard Save View',
  dashboardLoadView: 'Dashboard Load View',
  dashboardFavorited: 'Dashboard Favorited',
  dashboardUnfavorited: 'Dashboard Unfavorited',
  dashboardShare: 'Dashboard Share',
  dashboardRevert: 'Dashboard Revert',
  dashboardDownloadCSV: 'Dashboard Download CSV',
  dashboardDownloadPDF: 'Dashboard Download PDF',
  dashboardDownloadImage: 'Dashboard Download Image',
  dashboardHelp: 'Dashboard Help',
  dashboardHiddenClient: 'Dashboard Hidden - Client',
  dashboardUnhiddenClient: 'Dashboard Unhidden - Client',
  dashboardHiddenUser: 'Dashboard Hidden - User',
  dashboardUnhiddenUser: 'Dashboard Unhidden - User',
  pageView: 'Page View',
  userLogout: 'User Logout',
  kpiHiddenClient: 'KPI Hidden - Client',
  kpiUnhiddenClient: 'KPI Unhidden - Client',
  locationFiltersSelected: 'Location Filters Selected',
  locationFiltersUnselected: 'Location Filters Unselected',
  locationFiltersEdited: 'Location Filters Edited',
};
