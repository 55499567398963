import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppLazyQuery } from '@apollo-red/hooks/queries';
import { GET_TENANTS_CONFIGURATION_DATA } from '@apollo-red/queries';

export const useGetTenantConfigurationData = (
  tenantId: string,
  applications?: SchemaTypes.TenantConfigurationDataType[],
) => {
  const [getData, { data, loading }] = useAppLazyQuery<
    SchemaTypes.GetTenantsConfigurationDataQuery,
    SchemaTypes.GetTenantsConfigurationDataQueryVariables
  >(GET_TENANTS_CONFIGURATION_DATA);

  return {
    loading,
    configurationData: data?.getTenants?.[0]?.tenant_configuration_data,
    getConfigurationData: () => {
      getData({
        variables: { applications, tenant_ids: [tenantId] },
      });
    },
  };
};
