import * as React from 'react';
import { useRoutes } from '@routes';
import { ActiveUserOnly } from '@components/featureToggle';
import Account from './account';
import ReportSettings from './reportSettings';
import AlertSettings from './alertSettings';
import {
  MenuItems,
  MenuSettingsSubItems,
} from '@components/pageContainer/SideBar/Navigation/navHelpers.types';
import Routes, { RoleRoutes } from '@components/routes';

const Settings: React.FC = () => {
  const routes = useRoutes();

  const roleRoutes: RoleRoutes = [
    {
      path: routes.settings.accountSettings.path,
      component: Account,
      feature: MenuItems.Settings,
      subFeature: MenuSettingsSubItems.AccountSettings,
    },
    {
      path: routes.settings.reportSettings.path,
      component: ReportSettings,
      feature: MenuItems.Settings,
      subFeature: MenuSettingsSubItems.DashboardSettings,
    },
    {
      path: routes.settings.alertSettings.path,
      component: AlertSettings,
      feature: MenuItems.Settings,
      subFeature: MenuSettingsSubItems.AlertSettings,
    },
  ];

  return (
    <ActiveUserOnly>
      <Routes roleRoutes={roleRoutes} />
    </ActiveUserOnly>
  );
};

export default Settings;
