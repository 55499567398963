import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_TENANT_TRACKER_DATA } from '@apollo-red/queries';
import { useAppQuery } from '../queries';

export type Log = SchemaTypes.Log;

export import EntityType = SchemaTypes.EntityType;

export import EventType = SchemaTypes.EventType;

export const useGetTenantTrackerData = (
  tenantId: string,
  entityType: EntityType,
  eventType: EventType,
  entityId?: string,
) => {
  const { loading, data, refetch } = useAppQuery<
    SchemaTypes.GetTenantTrackerDataQuery,
    SchemaTypes.GetTenantTrackerDataQueryVariables
  >(GET_TENANT_TRACKER_DATA, {
    variables: {
      event_type: eventType,
      entity_type: entityType,
      entity_id: entityId,
      tenant_id: tenantId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    loading,
    refetch,
    logs: data?.getTenants?.[0]?.tenant_activity_logs ?? [],
  };
};
