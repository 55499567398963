import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { GET_KEYWORDS } from '@apollo-red/queries';
import {
  ADD_KEYWORD,
  UPDATE_KEYWORD,
  DELETE_KEYWORD,
} from '@apollo-red/mutations';
import { filterItems } from '@utils/various';
import { useAppQuery, useAppMutation } from '../queries';
import { MutationUpdaterFn } from '@apollo/client';
import { filterKeywordsByTitleCase } from './utils';

export type Keyword = SchemaTypes.KeywordBaseFragment;

export const useGetKeywords = () => {
  const { loading, data } =
    useAppQuery<SchemaTypes.GetKeywordsQuery>(GET_KEYWORDS);
  const keywords = filterItems(data?.getKeywords ?? []);

  const filteredKeywordsByTitleCase = filterKeywordsByTitleCase(keywords);

  return {
    loading,
    keywords: filteredKeywordsByTitleCase,
  };
};

const updateGetKeywordsQuery = <T>(
  updatedUserFromDataFn: (
    data: T | null | undefined,
  ) => Keyword | null | undefined,
): MutationUpdaterFn<T> => {
  return (cache, { data }) => {
    const cacheKeywords = cache.readQuery<SchemaTypes.GetKeywordsQuery>({
      query: GET_KEYWORDS,
    });

    const keyword = updatedUserFromDataFn(data);
    const keywords = cacheKeywords?.getKeywords;
    if (keyword && keywords) {
      cache.writeQuery({
        query: GET_KEYWORDS,
        data: {
          getKeywords: [keyword, ...keywords],
        },
      });
    }
  };
};

export const useAddKeyword = () => {
  const [addKeyword] = useAppMutation<
    SchemaTypes.AddKeywordMutation,
    SchemaTypes.AddKeywordMutationVariables
  >(ADD_KEYWORD);

  return {
    addKeyword: (payload: SchemaTypes.UpsertKeywordDataInput) => {
      return addKeyword({
        variables: {
          data: payload,
        },
        update: updateGetKeywordsQuery(data => data?.upsertKeyword),
      });
    },
  };
};

export const useUpdateKeyword = () => {
  const [updateKeyword] = useAppMutation<
    SchemaTypes.UpdateKeywordMutation,
    SchemaTypes.UpdateKeywordMutationVariables
  >(UPDATE_KEYWORD);

  return {
    updateKeyword: (
      id: string,
      payload: SchemaTypes.UpsertKeywordDataInput,
    ) => {
      const data = {
        variables: {
          data: payload,
          where: {
            id,
          },
        },
      };
      return updateKeyword(data);
    },
  };
};

export const useDeleteKeyword = () => {
  const [deleteKeyword] = useAppMutation<
    SchemaTypes.DeleteKeywordMutation,
    SchemaTypes.DeleteKeywordMutationVariables
  >(DELETE_KEYWORD);

  return {
    deleteKeyword: (id: string) => {
      const data = {
        variables: {
          where: {
            id,
          },
        },
      };
      return deleteKeyword(data);
    },
  };
};
