import * as React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from '@theme';

interface SubHeaderProps {
  size?: number;
  marginBottom?: number;
  bold?: boolean;
  transform?: 'uppercase' | 'capitalize';
  component?: 'span';
}

const useStyles = makeStyles((theme: AppTheme) => ({
  root: (props: SubHeaderProps) => ({
    textTransform: props.transform || 'none',
    fontSize: props.size ? props.size + 'px' : 'inherit',
    lineHeight: props.size ? props.size + 2 + 'px' : 'inherit',
    fontWeight: props.bold ? 500 : 'inherit',
    marginBottom: props.marginBottom ? theme.spacing(props.marginBottom) : 0,
  }),
}));

const SubHeader: React.FC<SubHeaderProps> = ({
  children,
  component,
  ...rest
}) => {
  const classes = useStyles({ ...rest });

  return (
    <Typography component={component || 'p'} className={classes.root}>
      {children}
    </Typography>
  );
};

export default SubHeader;
