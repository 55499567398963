import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsRoutePart } from '@components/breadcrumbs';
import { PageContainerContext } from '@components/pageContainer';
import { Box } from '@mui/material';

interface Props {
  breadcrumbsParts?: BreadcrumbsRoutePart[];
}

const PortscapeWrapper: React.FC<Props> = ({ breadcrumbsParts, children }) => {
  const { t } = useTranslation();
  const pageContainerContext = React.useContext(PageContainerContext);

  React.useEffect(() => {
    pageContainerContext.setBreadcrumbsData([
      t('jll_portscape'),
      [
        { label: t('jll_portscape') },
        breadcrumbsParts && { ...breadcrumbsParts },
      ],
    ]);
  }, [breadcrumbsParts, pageContainerContext, t]);

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.secondary.light,
        height: '100%',
        position: 'absolute',
      })}
    >
      {children}
    </Box>
  );
};

export default PortscapeWrapper;
