import {
  InsightsAlert,
  InsightsAlertScope,
  InsightsAlertWorkOrderVolumeTemplateData,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { getBenchmarkFilterValues } from './getBenchmarkFilterValues';
import { getPeerGroupFilterValues } from './getPeerGroupFilterValues';
import { getPropertyNameFilterValues } from './getPropertyNameFilterValues';
import { getTenureKeyFilterValues } from './getTenureKeyFilterValues';

const initialFilters = {
  p_property: [],
  p_peer: [],
  p_benchmark: [],
  p_alertdate: [],
  p_wo_created: [],
  p_tenure: [],
};

interface FilterValues {
  initialFilters?: { [key: string]: string[] };
  applyUniversalFilters?: { basic: boolean; geographic: boolean };
}

/**
 * Prepares filter values for alert based on alert type.
 *
 * @param alert alert document
 * @param alertType type of the alert
 * @param scope alert scope
 */
export const getFilterValues = (
  alert: InsightsAlert,
  alertType?: InsightsAlertType | null,
  scope?: InsightsAlertScope,
): FilterValues => {
  const templateData =
    alert.template_data as InsightsAlertWorkOrderVolumeTemplateData;

  switch (alertType) {
    case InsightsAlertType.WorkorderVolume:
      return {
        initialFilters: {
          ...initialFilters,
          p_property: getPropertyNameFilterValues(alert),
          p_peer: getPeerGroupFilterValues(alert, scope),
          p_benchmark: getBenchmarkFilterValues(alert, scope),
          p_alertdate: [alert.created_at],
          p_wo_created: templateData.work_order_created_at
            ? [templateData.work_order_created_at]
            : [],
        },
      };
    case InsightsAlertType.LeaseEvent:
      return {
        initialFilters: undefined,
        applyUniversalFilters: {
          basic: true,
          geographic: true,
        },
      };
    case InsightsAlertType.MarketBenchmark:
      return {
        initialFilters: {
          ...initialFilters,
          p_property: getPropertyNameFilterValues(alert),
          p_tenure: getTenureKeyFilterValues(alert),
        },
      };
    case InsightsAlertType.OccupancyEfficiency:
      return {
        initialFilters: {
          ...initialFilters,
          'Property Name': getPropertyNameFilterValues(alert),
        },
        applyUniversalFilters: {
          basic: true,
          geographic: true,
        },
      };
    case InsightsAlertType.SubleaseRisk:
      return {
        initialFilters: {
          ...initialFilters,
          'Property Name': getPropertyNameFilterValues(alert),
        },
        applyUniversalFilters: {
          basic: true,
          geographic: true,
        },
      };
    default:
      return { initialFilters: undefined };
  }
};
