import { ReportSectionType } from '@apollo-red/hooks';
import {
  BaselineInteractionSection,
  BaselineReportControlWithoutTypename,
  ReportControlWithoutTypename,
  ReportFiltersInteractionSection,
} from '../../types';
import { getFilterControls } from './getFilterControls';
import { flattenControlsInSections } from './flattenControlsInSections';

export const getReportControls = <
  T extends ReportFiltersInteractionSection,
  U extends T extends BaselineInteractionSection
    ? BaselineReportControlWithoutTypename
    : ReportControlWithoutTypename,
>(
  sections?: T[],
): {
  filterControls: U[];
  parameterControls: U[];
} => {
  if (!sections || sections.length === 0) {
    return {
      filterControls: [],
      parameterControls: [],
    };
  }

  const filterSections = sections.filter(
    section => section.section_type === ReportSectionType.Filter,
  );
  const parameterSections = sections.filter(
    section => section.section_type === ReportSectionType.Parameter,
  );

  return {
    filterControls: getFilterControls(filterSections),
    parameterControls: flattenControlsInSections(parameterSections),
  };
};
