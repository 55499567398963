import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Accordion, utils } from '@jll-labs/azara-ui-components';
import { AppTheme } from '@theme';

const { useToggleValue } = utils;

export const useKeywordStyles = makeStyles((theme: AppTheme) => ({
  expansionPanel: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  title: string;
}

const KeywordExpansionPanel: React.FC<Props> = ({ title, children }) => {
  const [expanded, toggleExpanded] = useToggleValue(false);
  const classes = useKeywordStyles();

  return (
    <div className={classes.expansionPanel}>
      <Accordion
        expanded={expanded}
        onExpand={toggleExpanded}
        active={false}
        variant="compact"
        title={title}
      >
        {children}
      </Accordion>
    </div>
  );
};

export default KeywordExpansionPanel;
