import * as React from 'react';
import { PageContainerContext } from '@components/pageContainer';

export const useHandleExternalReportResize = () => {
  const [width, setWidth] = React.useState<number>();
  const ref = React.useRef<HTMLDivElement>(null);
  const { closeSidebar, isSidebarExpanded } =
    React.useContext(PageContainerContext);

  React.useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  React.useEffect(() => {
    setWidth(ref.current?.offsetWidth);
  }, [isSidebarExpanded]);

  return { ref, width };
};
