import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { utils } from '@jll-labs/azara-ui-components';
import { useGetUserInfo, useGetUserDefaultTenantId } from '@apollo-red/hooks';
import { PageContainerContext } from '@components/pageContainer';
import { Viz } from '@components/tableau/types';
import { useSessionStorage } from '@utils/webAPI';
import { useHasPinnedData } from '../withFilters/hooks';
import { ReportFilterData } from '../withFilters/types';
import { AZARA_DATA } from '../withFilters/constants';

const { useToggleValue } = utils;

export interface FullReportWithFiltersContextType {
  id: string;
  reportId: string;
  isCoreDashboard: boolean;
  isSidebarExpanded: boolean;
  hasInteractionConfig: boolean;
  contentWidth: number;
  contentHeight: number;
  showFiltersPanel: boolean;
  handleHasInteractionConfig: (hasConfig: boolean) => void;
  toggleFiltersPanel: () => void;
  resetKey: string;
  resetPinnedValues: () => void;
  appliedControls: string[];
  addAppliedControls: (controlName: string[]) => void;
  removeAppliedControls: (controlName: string) => void;
  getAppliedControlsCount: () => number;
  areFiltersLoading: boolean;
  handleFiltersLoading: (loading: boolean) => void;
  areParametersLoading: boolean;
  handleParametersLoading: (loading: boolean) => void;
  viz?: Viz;
  handleViz?: (viz?: Viz) => void;
  hasPinnedData: ReturnType<typeof useHasPinnedData>['hasPinnedData'];
  pinnedData: ReportFilterData;
  updatePinnedData: (pinnedData: ReportFilterData) => void;
  filtersLoaded: boolean;
  initialPinnedValues: {};
  addInitialPinnedValues: (values: { [key: string]: string }) => void;
  appliedMarksSelectionCount: number;
  updateAppliedMarksSelectionCount: (count: number) => void;
}

export const FullReportWithFiltersContext =
  React.createContext<FullReportWithFiltersContextType>({
    id: '',
    reportId: '',
    contentWidth: 0,
    contentHeight: 0,
    areFiltersLoading: false,
    toggleFiltersPanel: () => {},
    showFiltersPanel: false,
    handleViz: () => {},
    resetKey: '',
    resetPinnedValues: () => {},
    appliedControls: [],
    addAppliedControls: () => {},
    removeAppliedControls: () => {},
    getAppliedControlsCount: () => 1,
    handleFiltersLoading: () => {},
    areParametersLoading: false,
    handleParametersLoading: () => {},
    isCoreDashboard: false,
    isSidebarExpanded: false,
    hasInteractionConfig: false,
    handleHasInteractionConfig: () => {},
    hasPinnedData: () => false,
    pinnedData: {},
    updatePinnedData: () => {},
    filtersLoaded: false,
    initialPinnedValues: {},
    addInitialPinnedValues: () => {},
    appliedMarksSelectionCount: 0,
    updateAppliedMarksSelectionCount: () => {},
  });

export const useFullReportWithFilters = () => {
  return React.useContext(FullReportWithFiltersContext);
};

interface ProviderProps {
  width: number;
  height: number;
  reportId: string;
  isCoreDashboard?: boolean;
}

const FullReportWithFiltersProvider: React.FC<ProviderProps> = ({
  children,
  width,
  height,
  reportId,
  isCoreDashboard = false,
}) => {
  const { isSidebarExpanded } = React.useContext(PageContainerContext);
  const tenantId = useGetUserDefaultTenantId();
  const { user } = useGetUserInfo();
  const id = `${tenantId}-${user.id}`;
  const [reportFilterData = {}, updateReportFilterData] = useSessionStorage(
    AZARA_DATA,
    {},
  );
  const [pinnedData, setPinnedData] =
    React.useState<ReportFilterData>(reportFilterData);
  const [hasInteractionConfig, setHasInteractionConfig] = React.useState(false);
  const [contentWidth, setContentWidth] = React.useState(width);
  const [contentHeight, setContentHeight] = React.useState(height);
  const [resetKey, setReset] = React.useState(() => uuid());
  const [viz, setViz] = React.useState<Viz | undefined>(undefined);
  const [showFiltersPanel, setShowFiltersPanel] = useToggleValue(false);
  const [appliedControls, setAppliedControls] = React.useState<string[]>([]);
  const [areFiltersLoading, setAreFiltersLoading] = React.useState(false);
  const [areParametersLoading, setAreParametersLoading] = React.useState(false);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [initialPinnedValues, setInitialPinnedValues] = React.useState({});
  const [appliedMarksSelectionCount, setAppliedMarksSelectionCount] =
    React.useState(0);
  const { hasPinnedData } = useHasPinnedData(pinnedData[id], isCoreDashboard);

  React.useEffect(() => {
    if (width || height) {
      setContentWidth(width);
      setContentHeight(height);
    }
  }, [width, height]);

  React.useEffect(() => {
    updateReportFilterData(pinnedData);
  }, [pinnedData, updateReportFilterData]);

  const toggleFiltersPanel = React.useCallback(() => {
    if (!filtersLoaded) {
      setFiltersLoaded(true);
    }

    setShowFiltersPanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHasInteractionConfig = React.useCallback((hasConfig: boolean) => {
    setHasInteractionConfig(hasConfig);
  }, []);

  const handleViz = React.useCallback((viz?: Viz) => {
    setViz(viz);
  }, []);

  const addAppliedControls = React.useCallback((controlName: string[]) => {
    setAppliedControls(appliedControls => [
      ...new Set([...appliedControls, ...controlName]),
    ]);
  }, []);

  const removeAppliedControls = React.useCallback((controlName: string) => {
    setAppliedControls(appliedControls =>
      appliedControls?.filter(control => control !== controlName),
    );
  }, []);

  const resetPinnedValues = React.useCallback(() => {
    setAppliedControls([]);
    setReset(uuid());
    setAppliedMarksSelectionCount(0);
  }, []);

  const getAppliedControlsCount = React.useCallback(() => {
    return appliedControls?.length ?? 0;
  }, [appliedControls]);

  const handleFiltersLoading = React.useCallback((loading: boolean) => {
    setAreFiltersLoading(loading);
  }, []);

  const handleParametersLoading = React.useCallback((loading: boolean) => {
    setAreParametersLoading(loading);
  }, []);

  const updatePinnedData = React.useCallback((data: ReportFilterData) => {
    setPinnedData(data);
  }, []);

  const addInitialPinnedValues = React.useCallback(
    (values: { [key: string]: string }) => {
      setInitialPinnedValues(values);
    },
    [],
  );

  const updateAppliedMarksSelectionCount = React.useCallback(
    (count: number) => {
      setAppliedMarksSelectionCount(count);
    },
    [],
  );

  return (
    <FullReportWithFiltersContext.Provider
      value={{
        id,
        reportId,
        contentWidth,
        contentHeight,
        viz,
        toggleFiltersPanel,
        handleViz,
        showFiltersPanel,
        resetKey,
        resetPinnedValues,
        addAppliedControls,
        appliedControls,
        getAppliedControlsCount,
        removeAppliedControls,
        areFiltersLoading,
        handleFiltersLoading,
        handleParametersLoading,
        areParametersLoading,
        isCoreDashboard,
        isSidebarExpanded,
        hasInteractionConfig,
        handleHasInteractionConfig,
        hasPinnedData,
        pinnedData,
        updatePinnedData,
        filtersLoaded,
        initialPinnedValues,
        addInitialPinnedValues,
        appliedMarksSelectionCount,
        updateAppliedMarksSelectionCount,
      }}
    >
      {children}
    </FullReportWithFiltersContext.Provider>
  );
};

export default FullReportWithFiltersProvider;
