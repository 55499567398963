import { utils } from '@jll-labs/azara-ui-components';
import {
  InsightsAlertScope,
  InsightsAlertInbox,
  InsightsAlertHeader,
  InsightsAlertBaseWorkOrderTemplateData,
  InsightsAlertType,
  InsightsAlertLeaseEventTemplateData,
  InsightsAlertWorkOrderVolumeTemplateData,
  InsightsAlertBaseMarketBenchmarkTemplateData,
  InsightsAlertOccupancyEfficiencyTemplateData,
  InsightsAlertSubleaseRiskTemplateData,
  InsightsAlertSameDayWorkOrderCompletionTemplateData,
} from '@apollo-red/hooks';
import { alertInboxToParam } from '../utils';

const { getFormattedDate } = utils;

const scopeToParam = {
  [InsightsAlertScope.External]: 'external',
  [InsightsAlertScope.Internal]: 'internal',
};

/**
 * Generates link to alert report based on alert id, alert status, and provided id of anchor element.
 * @param alert alert document
 */
export const getInternalLink = (
  threadId: string,
  inboxType: InsightsAlertInbox,
  scope: InsightsAlertScope,
  routes: any,
) => {
  return routes.insightsAlerts.dashboard.getLink(
    threadId,
    alertInboxToParam[inboxType],
    scopeToParam[scope],
  );
};

export const formatDate = (timeStamp: any = '') => {
  const date = new Date(parseInt(timeStamp, 10));
  return getFormattedDate(date, undefined, 'dd-MMM-yyyy');
};

export const alertsShortDescription: Record<
  InsightsAlertType,
  (alert: InsightsAlertHeader) => string
> = {
  covid_19_work_order: alert => {
    const templateData =
      alert.template_data as InsightsAlertBaseWorkOrderTemplateData;
    const { work_order_priority, speciality, work_order_number } = templateData;

    return `A/n ${work_order_priority} – ${speciality} work order ${work_order_number}`;
  },
  facilities_experience: alert => {
    const templateData =
      alert.template_data as InsightsAlertBaseWorkOrderTemplateData;
    const { work_order_priority, speciality, work_order_number } = templateData;

    return `A/n ${work_order_priority} – ${speciality} work order ${work_order_number}`;
  },
  lease_event: alert => {
    const templateData =
      alert.template_data as InsightsAlertLeaseEventTemplateData;
    const { event_type, property_name } = templateData;

    return `A/n ${event_type} event at your ${property_name}`;
  },
  workorder_volume: alert => {
    const templateData =
      alert.template_data as InsightsAlertWorkOrderVolumeTemplateData;
    const { alert_scope, speciality, property_name, trade } = templateData;

    if (alert_scope === InsightsAlertScope.Internal) {
      return `${speciality} volume at your ${property_name} property`;
    } else if (alert_scope === InsightsAlertScope.External) {
      return `${trade} volume at your ${property_name} property`;
    } else {
      return `${trade} volume at your ${property_name} property`;
    }
  },
  market_benchmark: alert => {
    const templateData =
      alert.template_data as InsightsAlertBaseMarketBenchmarkTemplateData;
    const { event_type, property_name, event_created_on } = templateData;

    return `You have a/n ${event_type} at your ${property_name} property on ${formatDate(
      event_created_on,
    )}.`;
  },
  occupancy_efficiency: alert => {
    const templateData =
      alert.template_data as InsightsAlertOccupancyEfficiencyTemplateData;
    const { property_name } = templateData;

    return `One or more occupancy metrics at your ${property_name} property are outside of recommended thresholds.`;
  },
  sublease_risk: alert => {
    const templateData =
      alert.template_data as InsightsAlertSubleaseRiskTemplateData;
    const { property_name, notice_period } = templateData;

    return `You have upcoming lease tenure(s) with liquidity options or risks available for action at your ${property_name} property within next ${notice_period} months`;
  },
  same_day_work_order_completion: alert => {
    const templateData =
      alert.template_data as InsightsAlertSameDayWorkOrderCompletionTemplateData;
    const { property_name } = templateData;

    return `Same day completion rate of high priority work orders is less than the threshold value at your ${property_name} property in last 1 week `;
  },
};
