import * as React from 'react';
import cx from 'classnames';
import { Stepper, Step, StepLabel, Tooltip, StepIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ValidateJumpStepper } from './OnBoarding';
import { AppTheme } from '@theme';

const useStyles = makeStyles((theme: AppTheme) => ({
  stepperContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 0',
  },
  stepperRoot: {
    padding: 0,
  },
  stepLabelRoot: {
    width: '18px',
    margin: theme.spacing(0, 0.5),
  },
  stepLabelActive: {
    fill: theme.palette.action.active,
    cursor: 'pointer',
  },
}));

interface Props {
  handleStepClick: (step: number) => () => void;
  activeStep: number;
  completed: ValidateJumpStepper[];
  lastCompletedStep: number;
}

const StepperContainer: React.FC<Props> = ({
  handleStepClick,
  activeStep,
  completed,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.stepperContainer}>
        {
          <Stepper
            classes={{ root: classes.stepperRoot }}
            activeStep={activeStep}
          >
            {completed.map((item, index) => {
              if (!item.disabled) {
                return (
                  <Tooltip key={item.label} title={item.label} placement="top">
                    <Step>
                      <StepLabel
                        icon={
                          <StepIcon
                            icon={''}
                            active={index === activeStep}
                            completed={index <= activeStep - 1}
                            classes={{
                              root: cx(
                                classes.stepLabelRoot,
                                index <= activeStep && classes.stepLabelActive,
                              ),
                            }}
                          />
                        }
                        onClick={handleStepClick(
                          item.value ? index : activeStep,
                        )}
                      >
                        {''}
                      </StepLabel>
                    </Step>
                  </Tooltip>
                );
              }
            })}
          </Stepper>
        }
      </div>
    </div>
  );
};

export default StepperContainer;
