import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from '@jll-labs/azara-ui-components';
import {
  OperationVariables,
  ApolloError,
  FetchPolicy,
  useQuery,
  useMutation,
  QueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  useLazyQuery,
  LazyQueryHookOptions,
  useApolloClient,
  LazyQueryResult,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useAppMessage } from '@components/messages';
import { ErrorType } from '@utils/error';

interface AppOptions {
  /**
   * Indicates if global error handler should be used.
   */
  globalErr?: boolean;
}

const errorMessages: {
  [key in ErrorType]: { text: string; type: MessageType };
} = {
  [ErrorType.Validation]: { text: 'validationError', type: 'error' },
  [ErrorType.Authorization]: { text: 'authorizationError', type: 'error' },
  [ErrorType.Maintenance]: { text: 'maintenanceError', type: 'error' },
  [ErrorType.Internal]: { text: 'systemError', type: 'systemError' },
  [ErrorType.ResourceNotFound]: { text: 'systemError', type: 'systemError' },
  [ErrorType.Service]: { text: 'systemError', type: 'systemError' },
};

/**
 * Sets global error.
 *
 * @param error error from Apollo
 * @param globalErr indicates if global error handler should be used
 */
export const useSetGraphQlError = (
  error?: ApolloError,
  globalErr?: boolean,
) => {
  const { t } = useTranslation();
  const { setAppMessage } = useAppMessage();

  const setMessage = React.useCallback(
    (err?: ApolloError) => {
      if (!err) {
        return;
      }

      const [err1] = err.graphQLErrors || [];

      if (err1 && err1.extensions?.code) {
        const errorMessage = errorMessages[err1.extensions.code as ErrorType];

        setAppMessage({
          text: errorMessage?.text ? t(errorMessage?.text) : t('systemError'),
          description: err1.message ?? 'Internal Server Error',
          type: errorMessage?.type ?? 'system',
        });
      } else {
        if (err.message === 'NetworkError when attempting to fetch resource.') {
          return;
        }

        setAppMessage({
          text: t('systemError'),
          description: 'Internal Server Error',
          type: 'systemError',
        });
      }
    },
    [setAppMessage, t],
  );

  React.useEffect(() => {
    if (globalErr) {
      setMessage(error);
    }
  }, [error, globalErr, setMessage]);

  return { setMessage };
};

/**
 * Wrapper around useQuery.
 * Use it in the application to leverage features such as:
 * - global error handling (false by default)
 *
 * @param query document node (pass-through to Apollo's useQuery)
 * @param options hook options (pass-through to Apollo's useQuery)
 * @param appOptions options
 */
export const useAppQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
  appOptions?: AppOptions,
) => {
  const result = useQuery(query, options);

  useSetGraphQlError(result.error, appOptions?.globalErr);

  return result;
};

/**
 * Wrapper around useLazyQuery.
 * Use it in the application to leverage features such as:
 * - global error handling (false by default)
 *
 * @param query document node (pass-through to Apollo's useLazyQuery)
 * @param options hook options (pass-through to Apollo's useLazyQuery)
 * @param appOptions options
 */
export const useAppLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
  appOptions?: AppOptions,
): [
  (options?: LazyQueryHookOptions<TData, TVariables>) => void,
  LazyQueryResult<TData, TVariables>,
] => {
  const [lazyQueryFn, result] = useLazyQuery(query, options);

  useSetGraphQlError(result.error, appOptions?.globalErr);

  return [lazyQueryFn, result];
};

// A workaround for mutation-like callback for queries
// https://github.com/apollographql/react-apollo/issues/3499#issuecomment-539346982
export const useAppLazyQueryCb = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: { fetchPolicy: FetchPolicy },
  appOptions?: AppOptions,
) => {
  const client = useApolloClient();
  const { setMessage } = useSetGraphQlError();

  const callback = React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
        fetchPolicy: options?.fetchPolicy,
      }),
    [client, options?.fetchPolicy, query],
  );

  const wrappedCallback = React.useCallback(
    async (variables: TVariables) => {
      try {
        const res = await callback(variables);
        return res;
      } catch (e) {
        const globalErr = appOptions?.globalErr ?? true;
        if (globalErr) {
          setMessage(e);
        }
        throw e;
      }
    },
    [appOptions?.globalErr, callback, setMessage],
  );

  return wrappedCallback;
};

/**
 * Wrapper around useMutation.
 * Use it in the application to leverage features such as:
 * - global error handling (true by default)
 *
 * @param query document node (pass-through to Apollo's useMutation)
 * @param options hook options (pass-through to Apollo's useMutation)
 * @param appOptions options
 */
export const useAppMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
  appOptions?: AppOptions,
): MutationTuple<TData, TVariables> => {
  const [mutationFn, result] = useMutation(mutation, options);
  const { setMessage } = useSetGraphQlError();

  const wrappedMutationFn = React.useCallback(
    async (options?: MutationHookOptions<TData, TVariables>) => {
      try {
        const res = await mutationFn(options);
        return res;
      } catch (e) {
        const globalErr = appOptions?.globalErr ?? true;
        if (globalErr) {
          setMessage(e);
        }
        throw e;
      }
    },
    [appOptions?.globalErr, mutationFn, setMessage],
  );

  return [wrappedMutationFn, result];
};
