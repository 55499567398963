import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_ALERT_SEVERITY } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';
import { InsightsAlertSeverityFilter } from '../insightsAlerts.types';

export const useSetInsightsInboxAlertSeverityFilter = () => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const [setInboxAlertSeverityFilter] = useAppMutation<
    SchemaTypes.SetInsightsInboxAlertSeverityMutation,
    SchemaTypes.SetInsightsInboxAlertSeverityMutationVariables
  >(SET_INSIGHTS_INBOX_ALERT_SEVERITY);

  return {
    setInboxAlertSeverityFilter: (filter: InsightsAlertSeverityFilter) => {
      setInboxAlertSeverityFilter({
        variables: {
          alertSeverity: filter,
          userId: user.id,
          tenantId,
        },
      });
    },
  };
};
