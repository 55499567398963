import { ReportModuleType } from '@apollo-red/schema/generated/operations';
import { Report } from './reports';
import {
  KPI_BANNER_CATEGORY,
  KPI_PORTFOLIO_DETAILS_WORKBOOK,
  KPI_COST_WORKBOOK,
  KPI_PORTFOLIO_DETAILS_AREA,
  KPI_PORTFOLIO_DETAILS_PROPERTIES,
  LOGIN_DASHBOARD_CATEGORY,
  BY_REQUEST_ONLY_CATEGORY,
  PAT_TAG,
} from './constants';

/**
 * Checks if report has given category.
 *
 * @param report report to check
 * @param categoryName category name
 */
export const hasReportCategory = (report: Report, categoryName: string) => {
  return !!report.report_cat?.find(
    cat => cat.category_description === categoryName,
  );
};

export interface KpiDetailsReports {
  allKpiDetailsReports: Report[];
  area?: Report;
  properties?: Report;
  cost?: Report;
}

/**
 * Gets reports for KPI details. Reports for `area` and `properties` have to be extracted from a single workbook.
 * Report for `cost` is a separate workbook.
 *
 * @param reports
 */
export const getKpiDetailsReports = (reports: Report[]): KpiDetailsReports => {
  const bannerReports = reports.filter(report =>
    hasReportCategory(report, KPI_BANNER_CATEGORY),
  );
  const portfolio = bannerReports.find(
    report =>
      report.report_tableau_url.indexOf(KPI_PORTFOLIO_DETAILS_WORKBOOK) !== -1,
  );
  const area = portfolio && {
    ...portfolio,
    report_tableau_full_report_url: `${portfolio.report_tableau_url}/${KPI_PORTFOLIO_DETAILS_AREA}`,
  };
  const properties = portfolio && {
    ...portfolio,
    report_tableau_full_report_url: `${portfolio.report_tableau_url}/${KPI_PORTFOLIO_DETAILS_PROPERTIES}`,
  };
  const cost = bannerReports.find(
    report => report.report_tableau_url.indexOf(KPI_COST_WORKBOOK) !== -1,
  );

  const allKpiDetailsReports: Report[] = [portfolio, area, properties, cost]
    .filter(r => !!r)
    .map(r => r as Report);

  return {
    allKpiDetailsReports,
    area,
    properties,
    cost,
  };
};

/**
 * Filters specially treated reports off the main list.
 *
 * These are dashboards with the following categories:
 * - `banner` category (used for dashboards related to KPI boxes).
 * - `First` category (used for Login dashboard)
 * - `By Request Only` category (used for alert reports)
 *
 * @param reports reports list to filter
 */
export const filterSpecialReports = (reports: Report[]) => {
  return reports.filter(isNonSpecialReport);
};

export const filterNonSpecialReports = (reports: Report[]) => {
  return reports.filter(r => !isNonSpecialReport(r));
};

export const isNonSpecialReport = (report: Report) => {
  return (
    !hasReportCategory(report, LOGIN_DASHBOARD_CATEGORY) &&
    !hasReportCategory(report, KPI_BANNER_CATEGORY) &&
    !hasReportCategory(report, BY_REQUEST_ONLY_CATEGORY)
  );
};

export const isNonPATReport = (report: Report): boolean => {
  if (report?.report_cat?.[0]?.category_description === PAT_TAG) {
    // Ignore reports tagged with 'PAT' because these reports
    // represent Views that were not provisioned correctly.
    return false;
  }

  if (!report?.report_metadata) {
    return true;
  }

  const module =
    report?.report_metadata?.module?.module_type ?? ReportModuleType.Other;

  switch (module) {
    case ReportModuleType.PortfolioAnalyticsToolEssentials:
    case ReportModuleType.PortfolioAnalyticsToolPremium:
      return false;
    default:
      return true;
  }
};

export const isPATReport = (
  report: Report,
  includePATCategory?: boolean,
): boolean => {
  if (report?.report_cat?.[0]?.category_description === PAT_TAG) {
    // Ignore reports tagged with 'PAT' because they need to have
    // a valid PAT Category assigned when provisioned.
    return includePATCategory ? true : false;
  }

  if (!report?.report_metadata) {
    return false;
  }

  const module =
    report?.report_metadata?.module?.module_type ?? ReportModuleType.Other;

  switch (module) {
    case ReportModuleType.PortfolioAnalyticsToolEssentials:
    case ReportModuleType.PortfolioAnalyticsToolPremium:
      return true;
    default:
      return false;
  }
};
