import { Keyword } from './keywords';

const TITLE_CASE_REGEX = /^(?:[A-Z0-9][^\s]*\s?)+$/;

const keywordsToFilter = ['Lease Auto-Renewal'];

export const filterKeywordsByTitleCase = (keywords: Keyword[]) => {
  return keywords?.filter(
    keyword =>
      keyword?.keyword_name?.match(TITLE_CASE_REGEX) &&
      !keywordsToFilter?.includes(keyword?.keyword_name),
  );
};
