import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '@jll-labs/azara-ui-components';
import { LogReportType } from '@apollo-red/hooks';
import {
  FullReportContextProvider,
  FullReportViz,
  Toolbar,
} from '@components/fullReport';
import { ContentContainer } from '@components/pageContainer';
import NoData from '@components/noData';
import { TableauLicenseValidator } from '@components/tableau';
import { useAlertReportData } from './utils';

const useStyles = makeStyles({
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

/**
 * Renders report for alert in the following manner:
 * 1. Fetches alert by `:alertId` param.
 * 2. Chooses alert scope - prioritizes scope from `:alertScope` param over the one from alert document.
 * 3. Chooses report based on alert document and scope (report for Internal scope or for External scope). There can also be a custom report (demo alerts).
 * 4. Passes initial filters to the report.
 */
const AlertsReport: React.FC<{}> = () => {
  const classes = useStyles();
  const {
    alertLabel,
    alertReport,
    alertReportExtended,
    loading,
    recentLoading,
    viz,
    setViz,
  } = useAlertReportData();

  if (!loading && !recentLoading && !alertReport) {
    return <NoData message="noDataAvailable" />;
  }

  if (loading || recentLoading) {
    return (
      <div className={classes.loadingIndicator}>
        <Loader />
      </div>
    );
  }

  if (!alertReport || !alertReportExtended) {
    return <ContentContainer compactPadding={true} title={alertLabel} />;
  }

  const {
    id,
    report_tableau_full_report_url,
    report_name,
    primaryCategory,
    applyUniversalFilters,
    initialFilters,
  } = alertReport;

  return (
    <ContentContainer compactPadding={true} title={alertLabel}>
      <FullReportContextProvider report={alertReportExtended}>
        <TableauLicenseValidator height="50vh">
          <Toolbar
            viz={viz}
            report={alertReport}
            enabledSettings={{
              pdf: true,
              help: true,
            }}
          />
          <FullReportViz
            reportId={id}
            reportUrl={report_tableau_full_report_url}
            reportName={report_name}
            reportCategory={primaryCategory || 'Alerts'}
            setViz={setViz}
            applyUniversalFilters={applyUniversalFilters}
            initialFilters={initialFilters}
            reportType={LogReportType.AlertReport}
            originalReportId={alertReportExtended.id}
            showLastUpdateDate={false}
          />
        </TableauLicenseValidator>
      </FullReportContextProvider>
    </ContentContainer>
  );
};

export default AlertsReport;
