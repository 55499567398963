import { GET_WORK_AREAS, GET_WORK_AREA_KEYWORDS } from '@apollo-red/queries';
import { useAppQuery } from '../queries';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { filterItems } from '@utils/various';

export type WorkArea = SchemaTypes.WorkAreaBaseFragment;

export const useGetWorkAreas = () => {
  const { loading, data } =
    useAppQuery<SchemaTypes.GetWorkAreasQuery>(GET_WORK_AREAS);

  return {
    loading,
    workAreas: filterItems(data?.getWorkAreas ?? []),
  };
};

export const useGetWorkAreaKeywords = (tenantId: string) => {
  const { loading, data } = useAppQuery<SchemaTypes.GetWorkAreaKeywordsQuery>(
    GET_WORK_AREA_KEYWORDS,
    {
      variables: {
        tenantId: tenantId,
      },
      fetchPolicy: 'network-only',
    },
  );

  return {
    loading,
    keywords: filterItems(data?.getWorkAreaKeywords ?? []),
  };
};
