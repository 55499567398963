import { gql } from '@apollo/client';
import {
  UserTenantExtended,
  UserBase,
  UserPersonaBase,
  UserSystemAdminBase,
  UserInsightsAlertHistoryBase,
  UserInsightsAlertUserHistoryBase,
  UserInsightsAlertUserROIDataBase,
  UserInsightsAlertsBase,
  UserInsightsAlertBase,
  SearchUserBase,
  UserClassificationUsersBase,
  UserClassificationNumbersBase,
  UserClassificationFiltersOptions,
  UserClassificationUserBase,
} from '@apollo-red/fragments';

export const GET_SYSTEM_ROLE_USERS = gql`
  query getSystemRoleUsers {
    getSystemRoleUsers {
      ...UserSystemAdminBase
    }
  }
  ${UserSystemAdminBase.fragment}
`;

export const GET_USER_TENANT = gql`
  query getUserTenant($where: WhereGetUserInput!, $tenant_ids: [ID!]) {
    getUser(where: $where) {
      id
      user_tenant_settings(tenant_ids: $tenant_ids) {
        user_tenant {
          ...UserTenantExtended
        }
      }
    }
  }
  ${UserTenantExtended.fragment}
`;

export const GET_USER_PERSONAS = gql`
  query getUserPersonas($where: WhereGetUserInput!, $tenant_ids: [ID!]) {
    getUser(where: $where) {
      id
      user_personas(tenant_ids: $tenant_ids) {
        ...UserPersonaBase
      }
    }
  }
  ${UserPersonaBase.fragment}
`;

export const GET_USER_DATA = gql`
  query getUser($where: WhereGetUserInput!) {
    getUser(where: $where) {
      ...UserBase
    }
  }
  ${UserBase.fragment}
`;

export const GET_USER_TENANT_INSIGHTS_ALERT_SUMMARY = gql`
  query getInsightsAlertSummary(
    $where: WhereGetUserInput!
    $whereInsightsAlertsSummary: WhereGetInsightsAlertSummaryInput!
  ) {
    getUser(where: $where) {
      id
      user_insights_alert_summary(where: $whereInsightsAlertsSummary) {
        user_id
        client_id
        counts_by_status {
          all
          unread
        }
      }
    }
  }
`;

export const GET_USER_TENANT_INSIGHTS_ALERTS = gql`
  query getInsightsAlerts(
    $where: WhereGetUserInput!
    $whereInsightsAlerts: WhereGetInsightsAlertsInput!
  ) {
    getUser(where: $where) {
      id
      user_insights_alerts(where: $whereInsightsAlerts) {
        ...UserInsightsAlertsBase
      }
    }
  }
  ${UserInsightsAlertsBase.fragment}
`;

export const GET_USER_TENANT_INSIGHTS_ALERT_THREAD_HISTORY = gql`
  query getInsightsAlertThreadHistory(
    $where: WhereGetUserInput!
    $whereInsightsAlert: WhereGetInsightsAlertThreadInput!
    $whereInsightsAlertHistory: WhereGetInsightsAlertThreadHistoryInput!
  ) {
    getUser(where: $where) {
      id
      user_insights_alert(where: $whereInsightsAlert) {
        thread_id
        alert_history(where: $whereInsightsAlertHistory) {
          ...UserInsightsAlertHistoryBase
        }
      }
    }
  }
  ${UserInsightsAlertHistoryBase.fragment}
`;

export const GET_USER_TENANT_INSIGHTS_ALERT_THREAD_USER_HISTORY = gql`
  query getInsightsAlertThreadUserHistory(
    $where: WhereGetUserInput!
    $whereInsightsAlert: WhereGetInsightsAlertThreadInput!
    $whereInsightsAlertUserHistory: WhereGetInsightsAlertThreadUserHistoryInput!
  ) {
    getUser(where: $where) {
      id
      user_insights_alert(where: $whereInsightsAlert) {
        thread_id
        alert_user_history(where: $whereInsightsAlertUserHistory) {
          ...UserInsightsAlertUserHistoryBase
        }
      }
    }
  }
  ${UserInsightsAlertUserHistoryBase.fragment}
`;

export const GET_USER_TENANT_INSIGHTS_ALERT_THREAD_ROI_DATA = gql`
  query getInsightsAlertThreadRoiData(
    $where: WhereGetUserInput!
    $whereInsightsAlert: WhereGetInsightsAlertThreadInput!
  ) {
    getUser(where: $where) {
      id
      user_insights_alert(where: $whereInsightsAlert) {
        thread_id
        alert_roi_data {
          ...UserInsightsAlertUserROIDataBase
        }
      }
    }
  }
  ${UserInsightsAlertUserROIDataBase.fragment}
`;

export const GET_USER_TENANT_INSIGHTS_ALERT_THREAD = gql`
  query getInsightsAlertThread(
    $where: WhereGetUserInput!
    $whereInsightsAlert: WhereGetInsightsAlertThreadInput!
  ) {
    getUser(where: $where) {
      id
      user_insights_alert(where: $whereInsightsAlert) {
        thread_id
        alert {
          ...UserInsightsAlertBase
        }
      }
    }
  }
  ${UserInsightsAlertBase.fragment}
`;

export const SEARCH_USERS = gql`
  query searchUsers($phrase: String!) {
    searchUsers(phrase: $phrase) {
      ...SearchUserBase
    }
  }
  ${SearchUserBase.fragment}
`;

export const GET_USER_CLASSIFICATION_USERS = gql`
  query getUserClassificationUsers($where: UserClassificationInput!) {
    getUserClassification(where: $where) {
      ...UserClassificationUsersBase
    }
  }
  ${UserClassificationUsersBase.fragment}
`;

export const GET_USER_CLASSIFICATION_USER = gql`
  query getUserClassificationUser($where: WhereGetUserInput!) {
    getUser(where: $where) {
      ...UserClassificationUserBase
    }
  }
  ${UserClassificationUserBase.fragment}
`;

export const GET_USER_CLASSIFICATION_NUMBERS = gql`
  query getUserClassificationNumbers($where: UserClassificationInput!) {
    getUserClassification(where: $where) {
      ...UserClassificationNumbersBase
    }
  }
  ${UserClassificationNumbersBase.fragment}
`;

export const GET_USER_CLASSIFICATION_FILTERS_OPTIONS = gql`
  query getUserClassificationFiltersOptions($where: UserClassificationInput!) {
    getUserClassification(where: $where) {
      ...UserClassificationFiltersOptionsBase
    }
  }
  ${UserClassificationFiltersOptions.fragment}
`;

export const GET_USER_CLASSIFICATION_FILTERS_OPTIONS_USER_NAME = gql`
  query getUserClassificationFiltersOptionsUserName(
    $where: UserClassificationInput!
    $name: String
    $filters: UserClassificationFiltersInput
  ) {
    getUserClassification(where: $where) {
      filters_options {
        user_name(name: $name, filters: $filters)
      }
    }
  }
`;

export const GET_USER_CLASSIFICATION_FILTERS_OPTIONS_USER_EMAIL = gql`
  query getUserClassificationFiltersOptionsUserEmail(
    $where: UserClassificationInput!
    $email: String
    $filters: UserClassificationFiltersInput
  ) {
    getUserClassification(where: $where) {
      filters_options {
        user_email(email: $email, filters: $filters)
      }
    }
  }
`;
