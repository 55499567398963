import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { utils } from '@jll-labs/azara-ui-components';
import { CreateBreakpointFn } from '@jll-labs/azara-ui-components/types/utils';
import { AppTheme } from '@theme';
import { SystemStyleObject } from '@mui/system';
import { Box, Theme, useTheme } from '@mui/material';
import SideBar from './SideBar';
import TopBar from './TopBar';

const { createUpBreakpoints } = utils;

export const createSideBarBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    top: theme.topBar.height[breakpoint],
    height: `calc(100vh - ${theme.topBar.height[breakpoint]})`,
    zIndex: theme.zIndex.appBar - 1,
    width: theme.sidebar.width[breakpoint].closed,
  };
};

export const createSideBarExpandedBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    width: theme.sidebar.width[breakpoint].expanded,
  };
};

export const createContentBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    marginTop: theme.topBar.height[breakpoint],
    marginLeft: theme.sidebar.width[breakpoint].closed,
    height: `calc(100vh - ${theme.topBar.height[breakpoint]})`,
  };
};

export const createContentExpandedBreakpoints: CreateBreakpointFn<AppTheme> = (
  theme,
  breakpoint,
) => {
  return {
    marginLeft: theme.sidebar.width[breakpoint].zIndex
      ? 0
      : theme.sidebar.width[breakpoint].expanded,
  };
};

const useStyles = (): Record<
  'sideBar' | 'sideBarExpanded' | 'content' | 'contentExpanded',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    sideBar: {
      position: 'fixed',
      boxShadow: theme.shadows[4],
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createSideBarBreakpoints,
      ),
    },
    sideBarExpanded: {
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createSideBarExpandedBreakpoints,
      ),
    },
    content: {
      position: 'relative',
      overflow: 'visible',
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createContentBreakpoints,
      ),
    },
    contentExpanded: {
      ...createUpBreakpoints(
        ['xs', 'sm', 'md', 'lg', 'xl'],
        theme,
        createContentExpandedBreakpoints,
      ),
    },
  };
};

export interface AppPageContainerContext {
  toggleSidebar: () => void;
  expandSidebar: () => void;
  closeSidebar: () => void;
  expandSearchbar: () => void;
  setSearchedPhrase: (searchedPhrase?: string) => void;
  setBreadcrumbsData: (data: any) => void;
  contentEl: HTMLDivElement | null;
  isSidebarExpanded: boolean;
  searchedPhrase?: string;
}

export const PageContainerContext =
  React.createContext<AppPageContainerContext>({
    toggleSidebar: () => {},
    expandSidebar: () => {},
    expandSearchbar: () => {},
    setSearchedPhrase: () => {},
    closeSidebar: () => {},
    setBreadcrumbsData: () => {},
    contentEl: null,
    isSidebarExpanded: true,
    searchedPhrase: undefined,
  });

const PageContainer: React.FC<{}> = ({ children }) => {
  const styles = useStyles();
  const [isSidebarExpanded, setSidebarExpanded] = React.useState(true);
  const [searchedPhrase, setSearched] = React.useState<string | undefined>(
    undefined,
  );
  const [isSearchbarExpanded, setSearchbarExpanded] = React.useState(false);
  const [contentEl, setContentEl] = React.useState(null);
  const [breadcrumbs, setBreadcrumbs] = React.useState();

  const refCb = React.useCallback(node => {
    setContentEl(node);
  }, []);

  const toggleSidebar = React.useCallback(() => {
    setSidebarExpanded(isOpen => !isOpen);
  }, []);

  const toggleSearchbar = React.useCallback(() => {
    setSearchbarExpanded(isExpanded => !isExpanded);
  }, []);

  const expandSidebar = React.useCallback(() => {
    setSidebarExpanded(true);
  }, []);

  const expandSearchbar = React.useCallback(() => {
    setSearchbarExpanded(true);
  }, []);

  const closeSidebar = React.useCallback(() => {
    setSidebarExpanded(false);
  }, []);

  const setBreadcrumbsData = React.useCallback(data => {
    setBreadcrumbs(data);
  }, []);

  const setSearchedPhrase = React.useCallback((phrase: string) => {
    setSearched(phrase);
  }, []);

  const values: AppPageContainerContext = React.useMemo(
    () => ({
      toggleSidebar,
      expandSearchbar,
      closeSidebar,
      expandSidebar,
      contentEl,
      isSidebarExpanded,
      setBreadcrumbsData,
      setSearchedPhrase,
    }),
    [
      closeSidebar,
      contentEl,
      expandSearchbar,
      expandSidebar,
      isSidebarExpanded,
      setBreadcrumbsData,
      setSearchedPhrase,
      toggleSidebar,
    ],
  );

  return (
    <PageContainerContext.Provider value={values}>
      <div>
        <TopBar
          breadcrumbs={breadcrumbs}
          searchedPhrase={searchedPhrase}
          toggleSearchbar={toggleSearchbar}
          isSearchbarExpanded={isSearchbarExpanded}
        />
        <Box
          sx={[styles.sideBar, isSidebarExpanded && styles.sideBarExpanded]}
          data-testid={'pagecontainer-sidebar-container'}
        >
          <SideBar
            toggleSidebar={toggleSidebar}
            expandSidebar={expandSidebar}
            isExpanded={isSidebarExpanded}
          />
        </Box>
        <Box
          ref={refCb}
          sx={[styles.content, isSidebarExpanded && styles.contentExpanded]}
          data-testid={'pagecontainer-children'}
        >
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{
              height: '100%',
            }}
            renderView={props => (
              <div
                {...props}
                style={{ ...props.style, overflowX: 'hidden', height: '100%' }}
              />
            )}
          >
            {children}
          </Scrollbars>
        </Box>
      </div>
    </PageContainerContext.Provider>
  );
};

export default PageContainer;
