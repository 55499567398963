import * as React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { subYears, startOfYear, endOfYear, startOfDay } from 'date-fns';
import { DatePicker } from '@jll-labs/azara-ui-components';
import { RangeOption } from '../types';

interface Props {
  rangeOption: RangeOption;
  loading: boolean;
  updateRange: (options: RangeOption) => void;
  initialValues: RangeOption;
}

const useStyles = makeStyles({
  container: {
    width: '40%',
  },
});

const defaultNumberOfYears = 3;
const defaultMinDate = startOfYear(subYears(new Date(), defaultNumberOfYears));
const defaultMaxDate = endOfYear(new Date());

const FilterDateRange: React.FC<Props> = ({
  loading,
  updateRange,
  rangeOption,
  initialValues,
}) => {
  const [fromDate, setFromDate] = React.useState(() => {
    const minDate = initialValues?.min || rangeOption?.min;
    return minDate ? startOfDay(minDate) : startOfDay(defaultMinDate.getTime());
  });
  const [toDate, setToDate] = React.useState(() => {
    const maxDate = initialValues?.max || rangeOption?.max;
    return maxDate ? startOfDay(maxDate) : startOfDay(defaultMaxDate.getTime());
  });

  const classes = useStyles();

  const handleFromDateChange = (value: Date) => {
    setFromDate(value);
    updateRange({
      min: value,
      max: new Date(toDate),
    });
  };

  const handleToDateChange = (value: Date) => {
    setToDate(value);
    updateRange({
      min: new Date(fromDate),
      max: value,
    });
  };

  return (
    <Grid alignItems="center" justifyContent="space-between" container={true}>
      <div className={classes.container}>
        <DatePicker
          value={fromDate}
          onChange={handleFromDateChange}
          size="sm"
          disabled={loading}
          minDate={new Date(rangeOption?.min || defaultMinDate)}
          maxDate={toDate}
          showEndIcon={true}
          label=""
        ></DatePicker>
      </div>
      <span> {'to'}</span>
      <div className={classes.container}>
        <DatePicker
          value={toDate}
          onChange={handleToDateChange}
          size="sm"
          disabled={loading}
          maxDate={new Date(rangeOption?.max || defaultMaxDate)}
          minDate={fromDate}
          showEndIcon={true}
          label=""
        ></DatePicker>
      </div>
    </Grid>
  );
};

export default FilterDateRange;
