import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRoutes } from '@routes';
import { useFeatureFlag } from '@utils/launchDarkly';
import { ActiveUserOnly, TenantFeatureToggle } from '@components/featureToggle';
import SelfServeAnalyticsPageWrapper from './SelfServeAnalyticsPageWrapper';
import ThoughtSpotPinboard from './ThoughtSpotPinboard';

const EmptyComponent = () => {
  return null;
};

const SelfServeAnalytics: React.FC<{}> = () => {
  const { selfServeAnalytics: routes } = useRoutes();
  const enableTestEmbed = useFeatureFlag('selfServeAnalyticsEmbedTest');

  // FIXME: Remove after testing Trusted Authentication in higher environments.
  if (enableTestEmbed) {
    return (
      <ActiveUserOnly>
        <SelfServeAnalyticsPageWrapper>
          <Switch>
            <Route exact={true} path={routes.home.path}>
              <ThoughtSpotPinboard
                thoughtSpotHost="https://ts-qa.cloud.jll.com/"
                pinboardId="f9f54079-7b8d-4dd3-8455-1dbfc70d0ea7"
              />
            </Route>
          </Switch>
        </SelfServeAnalyticsPageWrapper>
      </ActiveUserOnly>
    );
  }

  return (
    <ActiveUserOnly>
      <SelfServeAnalyticsPageWrapper>
        <TenantFeatureToggle hasFeatures={['selfServeAnalytics']}>
          <Switch>
            <Route exact={true} path={routes.home.path}>
              <EmptyComponent />
            </Route>
          </Switch>
        </TenantFeatureToggle>
      </SelfServeAnalyticsPageWrapper>
    </ActiveUserOnly>
  );
};

export default SelfServeAnalytics;
