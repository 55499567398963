import { gql } from '@apollo/client';

export const SegmentOptions = {
  fragment: gql`
    fragment SegmentOptionsBase on SegmentOptions {
      mapping_id
      setting_id
      setting_segment_id
      order_index
      setting_segment_name
    }
  `,
};

export const DataThreshold = {
  fragment: gql`
    fragment DataThresholdBase on DataThreshold {
      threshold
      value
    }
  `,
};

export const TenantConfigurationDataValuesBase = {
  defaultsDataFragment: gql`
    fragment TenantDefaultsDataValuesBase on TenantDefaultsDataValues {
      default_unit_of_measurement
      default_unit_of_currency
    }
  `,
  workOrdersDataFragment: gql`
    fragment TenantWorkOrdersDataValuesBase on TenantWorkOrdersDataValues {
      work_order_completion_date
      on_time_completion_ratings {
        ...DataThresholdBase
      }
      pm_on_time_completion_ratings {
        ...DataThresholdBase
      }
      emergency_on_time_completion_ratings {
        ...DataThresholdBase
      }
      customer_satisfaction_ratings {
        ...DataThresholdBase
      }
      invoices_submitted_less_30_days_ratings {
        ...DataThresholdBase
      }
    }
  `,
  financeDataFragment: gql`
    fragment TenantFinanceDataValuesBase on TenantFinanceDataValues {
      actuals_ledger_type_code
      budget_forecast_ledger_type_code
      gl_accounts_for_exclusion
      chart_of_account
      budget_accuracy_variance_ratings {
        ...DataThresholdBase
      }
      flexible_mappings {
        ...SegmentOptionsBase
      }
    }
    ${DataThreshold.fragment}
    ${SegmentOptions.fragment}
  `,
  transactionDataFragment: gql`
    fragment TenantTransactionDataValuesBase on TenantTransactionDataValues {
      milestone_identification
    }
  `,
  leaseDataFragment: gql`
    fragment TenantLeaseDataValuesBase on TenantLeaseDataValues {
      flexible_mappings {
        ...SegmentOptionsBase
      }
    }
    ${SegmentOptions.fragment}
  `,
  spaceDataFragment: gql`
    fragment TenantSpaceDataValuesBase on TenantSpaceDataValues {
      flexible_mappings {
        ...SegmentOptionsBase
      }
      standard_occupancy_thresholds {
        ...DataThresholdBase
      }
      standard_vacancy_thresholds {
        ...DataThresholdBase
      }
    }
    ${SegmentOptions.fragment}
    ${DataThreshold.fragment}
  `,
};

export const TenantConfigurationDataOptionsBase = {
  financeDataFragment: gql`
    fragment TenantFinanceDataOptionsBase on TenantFinanceDataOptions {
      actuals_ledger_type_code
      budget_forecast_ledger_type_code
      gl_accounts_for_exclusion
      chart_of_account
      cost_category {
        ...SegmentOptionsBase
      }
    }
    ${DataThreshold.fragment}
    ${SegmentOptions.fragment}
  `,
  transactionDataFragment: gql`
    fragment TenantTransactionDataOptionsBase on TenantTransactionDataOptions {
      milestone_identification
    }
  `,
  leaseDataFragment: gql`
    fragment TenantLeaseDataOptionsBase on TenantLeaseDataOptions {
      reporting_business_unit {
        ...SegmentOptionsBase
      }
    }
    ${SegmentOptions.fragment}
  `,
  spaceDataFragment: gql`
    fragment TenantSpaceDataOptionsBase on TenantSpaceDataOptions {
      business_unit {
        ...SegmentOptionsBase
      }
      space_type {
        ...SegmentOptionsBase
      }
    }
    ${SegmentOptions.fragment}
  `,
};

export const TenantConfigurationDataBase = {
  fragment: gql`
    fragment TenantConfigurationDataBase on TenantConfigurationData {
      id
      app_code
      tenant_id
      values {
        ...TenantDefaultsDataValuesBase
        ...TenantWorkOrdersDataValuesBase
        ...TenantFinanceDataValuesBase
        ...TenantTransactionDataValuesBase
        ...TenantLeaseDataValuesBase
        ...TenantSpaceDataValuesBase
      }
      options {
        ...TenantSpaceDataOptionsBase
        ...TenantLeaseDataOptionsBase
        ...TenantTransactionDataOptionsBase
        ...TenantFinanceDataOptionsBase
      }
    }
    ${TenantConfigurationDataValuesBase.defaultsDataFragment}
    ${TenantConfigurationDataValuesBase.financeDataFragment}
    ${TenantConfigurationDataValuesBase.workOrdersDataFragment}
    ${TenantConfigurationDataValuesBase.transactionDataFragment}
    ${TenantConfigurationDataValuesBase.leaseDataFragment}
    ${TenantConfigurationDataValuesBase.spaceDataFragment}
    ${TenantConfigurationDataOptionsBase.financeDataFragment}
    ${TenantConfigurationDataOptionsBase.leaseDataFragment}
    ${TenantConfigurationDataOptionsBase.spaceDataFragment}
    ${TenantConfigurationDataOptionsBase.transactionDataFragment}
  `,
};

export const FlexibleMappingOptionSampleValues = {
  fragement: gql`
    fragment FlexibleMappingOptionSampleValuesBase on FlexibleMappingOptionSampleValues {
      option
      sample_data
    }
  `,
};

export const TenantConfigurationDataSampleValues = {
  spaceDataFragment: gql`
    fragment TenantSpaceDataSampleValuesBase on TenantSpaceDataSampleValues {
      business_unit {
        ...FlexibleMappingOptionSampleValuesBase
      }
      space_type {
        ...FlexibleMappingOptionSampleValuesBase
      }
    }
    ${FlexibleMappingOptionSampleValues.fragement}
  `,
  financeDataFragment: gql`
    fragment TenantFinanceDataSampleValuesBase on TenantFinanceDataSampleValues {
      cost_category {
        ...FlexibleMappingOptionSampleValuesBase
      }
    }
    ${FlexibleMappingOptionSampleValues.fragement}
  `,
  leaseDataFragment: gql`
    fragment TenantLeaseDataSampleValuesBase on TenantLeaseDataSampleValues {
      reporting_business_unit {
        ...FlexibleMappingOptionSampleValuesBase
      }
    }
    ${FlexibleMappingOptionSampleValues.fragement}
  `,
};

export const TenantConfigurationDataSampleValuesBase = {
  fragment: gql`
    fragment TenantConfigurationDataSampleValuesBase on TenantConfigurationData {
      id
      tenant_id
      sample_values {
        ...TenantSpaceDataSampleValuesBase
        ...TenantFinanceDataSampleValuesBase
        ...TenantLeaseDataSampleValuesBase
      }
    }
    ${TenantConfigurationDataSampleValues.financeDataFragment}
    ${TenantConfigurationDataSampleValues.leaseDataFragment}
    ${TenantConfigurationDataSampleValues.spaceDataFragment}
  `,
};
