import * as React from 'react';
import { Box, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { Paper } from '@jll-labs/azara-ui-components';
import KeyMetricModal from './KeyMetricModal';
import { KeyMetricBox } from './helpers';

interface StyleProps {
  isCompact?: boolean;
}

const useStyles = ({ isCompact }: StyleProps) => {
  const theme = useTheme();
  return {
    tile: {
      position: 'relative',
      margin: theme.spacing(1),
      width: '10.5em',
      textAlign: 'center',
    },
    clickable: {
      cursor: 'pointer',
    },
    title: {
      paddingTop: theme.spacing(1),
      textTransform: 'uppercase',
      fontSize: isCompact
        ? theme.typography.body2.fontSize
        : theme.typography.body1.fontSize,
    } as const,
    value: {
      padding: theme.spacing(0.5, 0),
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
    kpiLoader: {
      margin: '0.5em',
    },
    changeBox: {
      textAlign: 'center',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.secondary.dark,
      fontSize: theme.typography.h5.fontSize,
      padding: theme.spacing(0.5, 0),
    } as const,
    boxHighlight: {
      borderBottomWidth: '3px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    boxError: {
      borderBottomWidth: '3px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  };
};

interface ChangeBoxProps {
  change: string;
}

const ChangeBox: React.FC<ChangeBoxProps> = ({ change }) => {
  const styles = useStyles({});
  const positive = parseFloat(change) > 0;
  return (
    <Box
      sx={[styles.changeBox, positive ? styles.boxHighlight : styles.boxError]}
    >
      <span>{change}</span>
    </Box>
  );
};

interface KeyMetricsBoxContentProps {
  title: string;
  loading: boolean;
  value?: string;
  change?: string;
  isCompact?: boolean;
}

const KeyMetricsBoxContent: React.FC<KeyMetricsBoxContentProps> = ({
  value,
  change,
  title,
  loading,
  isCompact,
}) => {
  const styles = useStyles({ isCompact });
  const content = loading ? (
    <CircularProgress sx={styles.kpiLoader} color="primary" size={24} />
  ) : (
    <div>
      <Box sx={styles.value}>{value ? value : 'N/A'}</Box>
      {change ? <ChangeBox change={change} /> : null}
    </div>
  );

  return (
    <div data-testid={`keymetric-box-${title}`}>
      <Box sx={styles.title}>{title}</Box>
      {content}
    </div>
  );
};

interface Props {
  keyMetricBox: KeyMetricBox;
  loading: boolean;
  noData?: React.ReactNode;
  isCompact?: boolean;
}

const KeyMetricBoxCmp: React.FC<Props> = ({
  keyMetricBox,
  loading,
  noData,
  isCompact = false,
}) => {
  const styles = useStyles({});
  const { title, report, data } = keyMetricBox;
  const { change, value, lastYear } = data;
  const [open, setOpen] = React.useState(false);
  const clickable = !!report;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validValue =
    loading || (value !== undefined && parseFloat(value) !== 0);

  const kpiContent = validValue ? (
    <Tooltip title={lastYear === undefined ? '' : `Last year: ${lastYear}`}>
      <Paper
        sx={[styles.tile, clickable && styles.clickable]}
        onClick={clickable ? handleOpen : undefined}
      >
        <KeyMetricsBoxContent
          loading={loading}
          title={title}
          value={value}
          change={change}
          isCompact={isCompact}
        />
      </Paper>
    </Tooltip>
  ) : null;

  return (
    <>
      {kpiContent}
      {report && (
        <KeyMetricModal
          report={report}
          title={title}
          open={open}
          handleClose={handleClose}
          noData={noData}
        />
      )}
    </>
  );
};

export default KeyMetricBoxCmp;
