import { InsightsAlertSortOrder } from '../insightsAlerts.types';
import { useGetInsightsInboxSettings } from './useGetInsightsInboxSettings';

export const useGetInsightsInboxAlertSortOrder = () => {
  const { loading, inboxSettings } = useGetInsightsInboxSettings();

  return {
    sortOrders: inboxSettings?.insights_selected_sort_order ?? {
      created_date: InsightsAlertSortOrder.Desc,
    },
    loading,
  };
};
