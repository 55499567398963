import * as React from 'react';
import { cloneDeep } from 'apollo-utilities';

export const recursivelyStripProperty = (obj: any, propToDelete: string) => {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !null) {
      const newData = recursivelyStripProperty(obj[property], propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
};

export type MaybeItem<T> = T | null;

export const filterItems = <I>(items: MaybeItem<I>[]): I[] => {
  return items.filter(item => !!item) as I[];
};

export const stripProp = <T extends object, K extends string>(
  obj: T,
  prop: K,
): Omit<T, K> => {
  const cl = cloneDeep(obj);
  return recursivelyStripProperty(cl, prop);
};

export const useIsMountedRef = () => {
  const isMountedRef = React.useRef(false);

  React.useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  });

  return isMountedRef;
};

type CallbackFn<T, S> = (arg?: T) => S | void;

export const useIfMountedCallback = <T, S>(
  callback: CallbackFn<T, S>,
): CallbackFn<T, S> => {
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (arg?: any) => {
    if (mounted.current === true) {
      callback(arg);
    }
  };
};

export const useRefCallback = () => {
  const [refElement, setRefElement] = React.useState(null);
  const setRefElementCb = React.useCallback(node => {
    setRefElement(node);
  }, []);

  return {
    refElement,
    setRefElementCb,
  };
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P];
};

export const transformString = ({
  sentence = '',
  wordsJoin = ' ',
  wordsSeparator = ' ',
  wordTransform = (word: string) => word,
}: {
  sentence?: string;
  wordTransform?: (word: string) => string;
  wordsSeparator?: string;
  wordsJoin?: string;
}) =>
  sentence
    .split(wordsSeparator)
    .map(word => wordTransform(word))
    .join(wordsJoin);

export enum UnitOfTime {
  Millisecond,
  Second,
}

export const convertTime = (
  value: number,
  from: UnitOfTime,
  to: UnitOfTime,
): number => {
  switch (from) {
    case UnitOfTime.Millisecond:
      switch (to) {
        case UnitOfTime.Second:
          return Math.round(value / 1000);
      }

      break;
    case UnitOfTime.Second:
      switch (to) {
        case UnitOfTime.Millisecond:
          return value * 1000;
      }

      break;
  }

  return value;
};

export type WithoutTypename<T> = Omit<T, '__typename'>;

export type Await<T> = T extends Promise<infer U> ? U : T;

export const useIntersection = ref => {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  React.useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
};
