import * as React from 'react';
import { ErrorType, UserNotFoundInTheSystemError } from '@utils/error';
import useUserQuery from './useUserQuery';

interface UseGetUserInfoArgs {
  email?: string;
}

export const useGetUserInfoQuery = ({ email }: UseGetUserInfoArgs) => {
  const { loading, data, error } = useUserQuery({ email });

  if (error) {
    if (
      error?.graphQLErrors?.[0]?.extensions?.name === ErrorType.ResourceNotFound
    ) {
      throw new UserNotFoundInTheSystemError();
    } else {
      throw new Error('Could not retrieve user info');
    }
  }

  return React.useMemo(
    () => ({
      loading,
      error,
      userNotFound: !loading && !error && !data?.getUser,
      user: data?.getUser,
    }),
    [data?.getUser, error, loading],
  );
};
