import { useMemo } from 'react';
import { DashboardRouteProps } from '@pages/dashboards/types';
import { getEncodedLink } from '@utils/router';
import { UseClientRoutes } from '../../types';
import { getCommonBaseRoute, getCommonRoute } from '../../utils';

export const useDashboardsRoutes = ({
  dashboards: { getLink },
}: UseClientRoutes) =>
  useMemo(() => {
    const link = getLink();

    return {
      home: getCommonBaseRoute(link),
      category: {
        path: `${link}/:categoryId`,
        getLink: (categoryId: string) => getEncodedLink([link, categoryId]),
      },
      dashboard: {
        path: `${link}/:categoryId/:dashboardId/:command?`,
        getLink: (categoryId: string, dashboardId: string, command?: string) =>
          getEncodedLink([
            link,
            categoryId,
            dashboardId,
            command ? command : '',
          ]),
      },
      favorites: {
        path: `${link}/favorites`,
        getLink: (tenantOvcId?: string) => `${getLink(tenantOvcId)}/favorites`,
      },
      saved: getCommonRoute(link, 'saved'),
      all: {
        path: `${link}/all`,
        getLink: (tenantOvcId?: string) => `${getLink(tenantOvcId)}/all`,
      },
      search: {
        path: `${link}/search/:keywordName`,
        getLink: (keywordName: string) =>
          getEncodedLink([link, 'search', keywordName]),
      },
      external: {
        path: `${link}/:categoryId/report/:externalReportId`,
        getLink: (categoryId: string, externalReportId: string) =>
          getEncodedLink([link, categoryId, externalReportId]),
      },
    } as DashboardRouteProps;
  }, [getLink]);
