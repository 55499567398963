import {
  ConcreteFilter,
  FlattenedFilter,
  FilterType,
} from '@components/tableau/types';

export const parseFilter = (filter: ConcreteFilter): FlattenedFilter => {
  return {
    name: filter.getFieldName(),
    filterType: filter.getFilterType() ?? FilterType.CATEGORICAL,
    appliedValues: filter.getAppliedValues?.(),
    isExcludeMode: filter.getIsExcludeMode?.(),
    domainMax: filter.getDomainMax?.(),
    domainMin: filter.getDomainMin?.(),
    max: filter.getMax?.(),
    min: filter.getMin?.(),
    includeNullValues: filter.getIncludeNullValues?.(),
    period: filter.getPeriod?.(),
    range: filter.getRange?.(),
    rangeN: filter.getRangeN?.(),
    isAllSelected: filter.getIsAllSelected?.(),
  };
};
