import { ReportAnchorTarget, ReportExtended } from '@apollo-red/hooks';

export const shouldOpenReportInNewTab = (
  report: ReportExtended,
  isExternalReportEmbeddingEnabled = false,
) => {
  const { isExternalReport, report_external_anchor_target } = report;

  if (!isExternalReport) {
    return false;
  }

  if (isExternalReport && !isExternalReportEmbeddingEnabled) {
    return true;
  }

  return report_external_anchor_target !== ReportAnchorTarget.Self;
};
