import { useFeatureFlag } from '@utils/launchDarkly';
import { useGenerateTrustedExternalReportUrl } from './useGenerateTrustedExternalReportUrl';

export interface GetTrustedExternalReportUrlArgs {
  tenantId: string;
  reportId: string;
  reportUrl: string;
}

export interface GetTrustedExternalReportUrl {
  reportUrl?: string;
  isValidTicket: boolean;
}

export const useGetTrustedExternalReportUrl = () => {
  const dashboardsTrustedAuthenticationExternalReports = useFeatureFlag(
    'dashboardsTrustedAuthenticationExternalReports',
  );
  const { generateTrustedExternalReportUrl } =
    useGenerateTrustedExternalReportUrl();

  const getTrustedExternalReportUrl = async ({
    tenantId,
    reportId,
    reportUrl,
  }: GetTrustedExternalReportUrlArgs): Promise<GetTrustedExternalReportUrl> => {
    if (!dashboardsTrustedAuthenticationExternalReports) {
      return {
        reportUrl,
        isValidTicket: false,
      };
    }

    const result = await generateTrustedExternalReportUrl({
      tenant_id: tenantId,
      report_id: reportId,
      report_url: reportUrl,
    });

    const { data } = result ?? {};
    const { generateTrustedExternalReportUrl: urlInfo } = data ?? {};

    if (!urlInfo) {
      return {
        isValidTicket: false,
      };
    }

    return {
      reportUrl: urlInfo.report_url,
      isValidTicket: urlInfo.is_valid_ticket,
    };
  };

  return {
    getTrustedExternalReportUrl,
  };
};
