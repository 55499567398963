import * as React from 'react';
import { useDocumentationApiClient } from './useDocumentationApiClient';

export const useDocumentationLogout = () => {
  const { logout } = useDocumentationApiClient();

  return React.useMemo(
    () => ({
      logout,
    }),
    [logout],
  );
};
