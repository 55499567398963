import { InsightsAlertSeverity } from '@apollo-red/schema/generated/operations';
import { InsightsAlertSeverityFilter } from '../insightsAlerts.types';

export const alertInsightsSeverityToAlertSeverityFilter: {
  [key in InsightsAlertSeverity]: InsightsAlertSeverityFilter;
} = {
  [InsightsAlertSeverity.Urgent]: InsightsAlertSeverityFilter.Urgent,
  [InsightsAlertSeverity.None]: InsightsAlertSeverityFilter.None,
  [InsightsAlertSeverity.Escalation]: InsightsAlertSeverityFilter.Escalation,
  [InsightsAlertSeverity.Reminder]: InsightsAlertSeverityFilter.Reminder,
};
