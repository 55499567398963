import * as React from 'react';
import { useDocumentationApiClient } from './useDocumentationApiClient';

export const useDocumentationLogin = () => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const { login, logout } = useDocumentationApiClient();

  React.useEffect(() => {
    const loginToDocs = async () => {
      await logout();
      const successfulLogin = await login();

      setLoggedIn(successfulLogin);
    };

    loginToDocs();
  }, [login, logout]);

  return {
    loggedIn,
  };
};
