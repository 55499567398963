import * as React from 'react';
import { SuspenseLoader } from '@components/asyncLoader';
import { useDocumentation } from './hooks';

const Documentation: React.FC = () => {
  useDocumentation();

  return <SuspenseLoader />;
};

export default Documentation;
