import * as SchemaTypes from '@apollo-red/schema/generated/operations';

/* eslint-disable-next-line */
export import InsightsAlertImportanceFilter = SchemaTypes.InsightsAlertImportanceFilter;

/* eslint-disable-next-line */
export import InsightsAlertTypeFilter = SchemaTypes.InsightsAlertTypeFilter;

/* eslint-disable-next-line */
export import InsightsAlertInbox = SchemaTypes.InsightsAlertInbox;

/* eslint-disable-next-line */
export import InsightsAlertSortOrder = SchemaTypes.InsightsAlertSortOrder;

/* eslint-disable-next-line */
export import InsightsAlertSeverity = SchemaTypes.InsightsAlertSeverity;

/* eslint-disable-next-line */
export import InsightsAlertImportance = SchemaTypes.InsightsAlertImportance;

/* eslint-disable-next-line */
export import InsightsAlertSeverityFilter = SchemaTypes.InsightsAlertSeverityFilter;

/* eslint-disable-next-line */
export import InsightAlertType = SchemaTypes.InsightsAlertType;

export type InsightsAlert = SchemaTypes.InsightsAlert;

export type InsightsAlerts = SchemaTypes.InsightsAlerts;

export type InsightsAlertTemplateData = SchemaTypes.InsightsAlertTemplateData;

export type InsightsAlertBaseWorkOrderTemplateData =
  SchemaTypes.InsightsAlertBaseWorkOrderTemplateData;

export type InsightsAlertLeaseEventTemplateData =
  SchemaTypes.InsightsAlertLeaseEventTemplateData;

export type InsightsAlertWorkOrderVolumeTemplateData =
  SchemaTypes.InsightsAlertWorkOrderVolumeTemplateData;

export type InsightsAlertBaseMarketBenchmarkTemplateData =
  SchemaTypes.InsightsAlertMarketBenchmarkTemplateData;

export type InsightsAlertOccupancyEfficiencyTemplateData =
  SchemaTypes.InsightsAlertOccupancyEfficiencyTemplateData;

export type InsightsAlertSubleaseRiskTemplateData =
  SchemaTypes.InsightsAlertSubleaseRiskTemplateData;

export type InsightsAlertSameDayWorkOrderCompletionTemplateData =
  SchemaTypes.InsightsAlertSameDayWorkOrderCompletionTemplateData;

export type InsightsAlertUserInfo = SchemaTypes.InsightsAlertUserInfo;

export type InsightsAlertHistoryRecord = SchemaTypes.InsightsAlertHistoryRecord;

export type InsightsAlertRoiData = SchemaTypes.InsightsAlertRoiData;

export type InsightsAlertUserHistoryRecord =
  SchemaTypes.InsightsAlertUserHistoryRecord;

export type InsightsAlertRoiOpportunity =
  SchemaTypes.InsightsAlertRoiOpportunity;

export type InsightsAlertRoiUnit = SchemaTypes.InsightsAlertRoiUnit;

export type InsightsAlertRoiFrequency = SchemaTypes.InsightsAlertRoiFrequency;

export type InsightsAlertUserHistory = SchemaTypes.InsightsAlertUserHistory;

export type InsightsAlertHeader =
  SchemaTypes.UserInsightsAlertHeaderBaseFragment;

/* eslint-disable-next-line */
export import InsightsAlertUserHistoryActionPerformed = SchemaTypes.InsightsAlertUserHistoryActionPerformed;

/* eslint-disable-next-line */
export import InsightsAlertUserStatus = SchemaTypes.InsightsAlertUserStatus;

/* eslint-disable-next-line */
export import InsightsAlertType = SchemaTypes.InsightsAlertType;

/* eslint-disable-next-line */
export import InsightsAlertTab = SchemaTypes.InsightsAlertTab;

/* eslint-disable-next-line */
export import InsightsAlertScope = SchemaTypes.InsightsAlertScope;

/* eslint-disable-next-line */
export import InsightsAlertStatusUpdate = SchemaTypes.InsightsAlertStatusUpdate;

/* eslint-disable-next-line */
export import InsightsAlertStatus = SchemaTypes.InsightsAlertStatus;

export interface ROIData {
  frequency?: InsightsAlertRoiFrequency;
  opportunityType?: InsightsAlertRoiOpportunity;
  unit?: InsightsAlertRoiUnit;
  value?: number;
  comment?: string;
}
