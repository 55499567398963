import * as React from 'react';
import {
  ReportFiltersControl,
  ReportInteractionSections,
  ReportType,
} from '../types';
import { isPinnedControlInReport } from '../utils';

export const useHasPinnedData = (
  sections?: ReportInteractionSections,
  isCoreDashboard = true,
) => {
  const hasPinnedData = React.useCallback(
    (
      filterControls: ReportFiltersControl[] = [],
      parameterControls: ReportFiltersControl[] = [],
    ) => {
      if (!sections) {
        return false;
      }

      const reportType = isCoreDashboard
        ? ReportType.CoreDashboard
        : ReportType.PATDashboard;
      const section = sections[reportType];

      if (!section) {
        return false;
      }

      return (
        isPinnedControlInReport(section.Filter, filterControls) ||
        isPinnedControlInReport(section.Parameter, parameterControls)
      );
    },
    [isCoreDashboard, sections],
  );

  return { hasPinnedData };
};
