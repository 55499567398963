import * as React from 'react';
import { InsightsAlertInbox } from '@apollo-red/hooks';
import { useInsightsAlertsInboxData } from './useInsightsAlertsInboxData';

export const useInsightsAlertsInbox = (inboxType: InsightsAlertInbox) => {
  const {
    alerts,
    hasNextPage,
    isLoadingMore,
    loading,
    selectedAlert,
    selectedAlertAssignedToFilter,
    selectedAlertImportanceFilter,
    selectedAlertSeverityFilter,
    selectedAlertThreadLoading,
    selectedAlertTypeFilter,
    selectedSortOrders,
    cleanupInboxAlerts,
    handleAddUserComment,
    handleAlertRead,
    handleAlertSelect,
    handleAssignedUserFilterChange,
    handleAssignUserChange,
    handleImportanceFilterChange,
    handleImportanceChange,
    handleROIDataChange,
    handleSeverityChange,
    handleSeverityFilterChange,
    handleSortByDate,
    handleStatusChange,
    handleTypeFilterChange,
    loadMore,
  } = useInsightsAlertsInboxData(inboxType);

  React.useEffect(() => {
    cleanupInboxAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxType]);

  React.useEffect(() => {
    return () => {
      cleanupInboxAlerts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    alerts,
    hasNextPage,
    isLoadingMore,
    loading,
    selectedAlert,
    selectedAlertAssignedToFilter,
    selectedAlertImportanceFilter,
    selectedAlertSeverityFilter,
    selectedAlertThreadLoading,
    selectedAlertTypeFilter,
    selectedSortOrders,
    handleAddUserComment,
    handleAlertRead,
    handleAlertSelect,
    handleAssignedUserFilterChange,
    handleAssignUserChange,
    handleImportanceFilterChange,
    handleImportanceChange,
    handleROIDataChange,
    handleSeverityChange,
    handleSeverityFilterChange,
    handleSortByDate,
    handleStatusChange,
    handleTypeFilterChange,
    loadMore,
  };
};
