import * as React from 'react';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { LOGOUT_USER } from '@apollo-red/mutations';
import { useAppMutation } from '../../queries';

interface LogoutUserArgs {
  userId: string;
  tenantId: string;
}

export const useLogoutUser = () => {
  const [logoutUser, { loading }] = useAppMutation<
    SchemaTypes.LogoutUserMutation,
    SchemaTypes.LogoutUserMutationVariables
  >(LOGOUT_USER, {
    onError: () => {
      return;
    },
  });

  return {
    loading,
    logoutUser: React.useCallback(
      async ({ userId, tenantId }: LogoutUserArgs) => {
        const { data } = await logoutUser({
          variables: {
            user_id: userId,
            tenant_id: tenantId,
          },
        });

        return data?.logoutUser ?? false;
      },
      [logoutUser],
    ),
  };
};
