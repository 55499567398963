import { gql } from '@apollo/client';

export const InsightsAlertWorkOrderVolumeTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertWorkOrderVolumeTemplateDataBase on InsightsAlertWorkOrderVolumeTemplateData {
      alert_type
      alert_scope
      benchmark_external
      benchmark_internal
      client_external_value
      client_internal_value
      peer_group_external
      peer_group_internal
      property_name
      speciality
      trade
      work_order_created_at
    }
  `,
};

export const InsightsAlertLeaseEventTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertLeaseEventTemplateDataBase on InsightsAlertLeaseEventTemplateData {
      alert_type
      annual_cost
      desk_count
      event_created_date
      event_notice_date
      event_type
      head_count
      property_name
      property_type
      tenure_cost
      tenure_size
      tenure_type
    }
  `,
};

export const InsightsAlertBaseWorkOrderTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertBaseWorkOrderTemplateDataBase on InsightsAlertBaseWorkOrderTemplateData {
      alert_type
      description
      due_at
      last_action_at
      property_name
      speciality
      work_order_number
      work_order_priority
      work_order_url
    }
  `,
};

export const InsightsAlertBaseMarketBenchmarkTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertBaseMarketBenchmarkTemplateDataBase on InsightsAlertMarketBenchmarkTemplateData {
      alert_type
      event_type
      event_created_on
      property_id
      property_name
      tenure_size
      tenure_name
      tenure_type
      tenure_key
      property_type
      base_rent
      prediction_record {
        rent_type
        space_type
        low_range
        high_range
        supporting_data_count
        supporting_data_points {
          ground_truth
          prediction
          mdm_id
          space_id
          property_id
          space_type
          costar_id
          date_on_market
          rent_type
          latitude
          longitude
          geohash_12
          lease_transaction_id
        }
      }
    }
  `,
};

export const InsightsAlertBaseOccupancyEfficiencyTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertBaseOccupnacyEfficiencyTemplateDataBase on InsightsAlertOccupancyEfficiencyTemplateData {
      alert_type
      property_id
      property_name
      vacancy_rate_trigger_info {
        vacancy_rate_percentage
        client_threshold
        threshold_level
      }
      occupancy_rate_trigger_info {
        occupancy_rate_percentage
        client_threshold
        threshold_level
      }
      headcount_density_trigger_info {
        jll_region
        headcount_density
        client_headcount_density
      }
    }
  `,
};

export const InsightsAlertBaseSubleaseRiskTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertBaseSubleaseRiskTemplateDataBase on InsightsAlertSubleaseRiskTemplateData {
      alert_type
      property_id
      property_name
      income_at_risk
      total_liquidity_options
      total_cost_obligations
      subleases
      notice_period
      reason
      lease_liquidity_options
      city_name
      state_name
      country_name
      source_system_id
      sublease_with_expiry {
        tenure_id
        tenure_key
        tenure_ended_on
        annualized_cost_usd
      }
      contraction_options {
        tenure_id
        tenure_key
        event_id
        event_notice_on
        event_notes
        annualized_cost_usd
      }
      month_to_month_tenures {
        tenure_id
        tenure_key
        annualized_cost_usd
      }
      termination_options {
        tenure_id
        tenure_key
        event_id
        event_notice_on
        event_notes
        annualized_cost_usd
      }
      natural_expiry {
        tenure_id
        tenure_key
        tenure_ended_on
        annualized_cost_usd
      }
      mainly_parking_leases {
        tenure_id
        tenure_key
        annualized_cost_usd
      }
    }
  `,
};

export const InsightsAlertBaseSameDayWorkOrderCompletionTemplateDataBase = {
  fragment: gql`
    fragment InsightsAlertBaseSameDayWorkOrderCompletionTemplateDataBase on InsightsAlertSameDayWorkOrderCompletionTemplateData {
      alert_type
      property_id
      property_name
      total_work_orders
      completed_work_orders_percentage
      not_completed_work_orders_percentage
      completed_work_orders_not_on_time_percentage
      threshold_for_alert_generation
    }
  `,
};

export const UserInsightsAlertUserInfoBase = {
  fragment: gql`
    fragment UserInsightsAlertUserInfoBase on InsightsAlertUserInfo {
      user_id
      user_name
    }
  `,
};

export const UserInsightsAlertBase = {
  fragment: gql`
    fragment UserInsightsAlertBase on InsightsAlert {
      thread_id
      severity
      alert_type
      alert_status
      alert_count
      template_data {
        ...InsightsAlertWorkOrderVolumeTemplateDataBase
        ...InsightsAlertLeaseEventTemplateDataBase
        ...InsightsAlertBaseWorkOrderTemplateDataBase
        ...InsightsAlertBaseMarketBenchmarkTemplateDataBase
        ...InsightsAlertBaseOccupnacyEfficiencyTemplateDataBase
        ...InsightsAlertBaseSubleaseRiskTemplateDataBase
        ...InsightsAlertBaseSameDayWorkOrderCompletionTemplateDataBase
      }
      reason
      created_at
      assigned_user_id
      user_importance
      user_status
      is_roi_enabled
      user_info {
        ...UserInsightsAlertUserInfoBase
      }
    }
    ${InsightsAlertWorkOrderVolumeTemplateDataBase.fragment}
    ${InsightsAlertLeaseEventTemplateDataBase.fragment}
    ${InsightsAlertBaseWorkOrderTemplateDataBase.fragment}
    ${InsightsAlertBaseMarketBenchmarkTemplateDataBase.fragment}
    ${InsightsAlertBaseOccupancyEfficiencyTemplateDataBase.fragment}
    ${InsightsAlertBaseSubleaseRiskTemplateDataBase.fragment}
    ${InsightsAlertBaseSameDayWorkOrderCompletionTemplateDataBase.fragment}
    ${UserInsightsAlertUserInfoBase.fragment}
  `,
};

export const UserInsightsAlertHeaderBase = {
  fragment: gql`
    fragment UserInsightsAlertHeaderBase on InsightsAlert {
      thread_id
      severity
      alert_type
      alert_status
      alert_count
      template_data {
        ...InsightsAlertWorkOrderVolumeTemplateDataBase
        ...InsightsAlertLeaseEventTemplateDataBase
        ...InsightsAlertBaseWorkOrderTemplateDataBase
        ...InsightsAlertBaseMarketBenchmarkTemplateDataBase
        ...InsightsAlertBaseOccupnacyEfficiencyTemplateDataBase
        ...InsightsAlertBaseSubleaseRiskTemplateDataBase
      }
      reason
      created_at
      assigned_user_id
      user_importance
      user_status
      is_roi_enabled
    }
    ${InsightsAlertWorkOrderVolumeTemplateDataBase.fragment}
    ${InsightsAlertLeaseEventTemplateDataBase.fragment}
    ${InsightsAlertBaseWorkOrderTemplateDataBase.fragment}
    ${InsightsAlertBaseMarketBenchmarkTemplateDataBase.fragment}
    ${InsightsAlertBaseOccupancyEfficiencyTemplateDataBase.fragment}
    ${InsightsAlertBaseSubleaseRiskTemplateDataBase.fragment}
    ${InsightsAlertBaseSameDayWorkOrderCompletionTemplateDataBase.fragment}
  `,
};

export const UserInsightsAlertsHeaderBase = {
  fragment: gql`
    fragment UserInsightsAlertsHeaderBase on InsightsAlerts {
      client_id
      user_id
      continuation_token
      alerts {
        ...UserInsightsAlertHeaderBase
      }
      has_next_page
    }
    ${UserInsightsAlertHeaderBase.fragment}
  `,
};

export const UserInsightsAlertsBase = {
  fragment: gql`
    fragment UserInsightsAlertsBase on InsightsAlerts {
      client_id
      user_id
      continuation_token
      alerts {
        ...UserInsightsAlertBase
      }
      has_next_page
    }
    ${UserInsightsAlertBase.fragment}
  `,
};

export const UserInsightsAlertHistoryBase = {
  fragment: gql`
    fragment UserInsightsAlertHistoryBase on InsightsAlertHistory {
      continuation_token
      history {
        reason
        created_at
      }
    }
  `,
};

export const UserInsightsAlertUserHistoryBase = {
  fragment: gql`
    fragment UserInsightsAlertUserHistoryBase on InsightsAlertUserHistory {
      continuation_token
      user_history {
        user_name
        commented_at
        action_performed
        comment
      }
    }
  `,
};

export const UserInsightsAlertUserROIDataBase = {
  fragment: gql`
    fragment UserInsightsAlertUserROIDataBase on InsightsAlertRoiData {
      comment
      frequency
      opportunity_type
      unit
      value
    }
  `,
};
