import { gql } from '@apollo/client';

export const PropertyDataRecordBase = {
  fragment: gql`
    fragment PropertyDataRecordBase on PropertyDataRecord {
      document_key
      property_id
      property_name
      address_line_1
      address_line_2
      address_line_3
      address_line_4
      city
      state
      postal_code
      country
      country_iso_code_2
      country_iso_code_3
      country_iso_numeric
      property_type
      property_sub_type
      latitude
      longitude
      status
      lease_owned
      property_area
      unit_of_measure
      service_end_date
      service_start_date
      site_group
      industry_sector
      business_unit
      region_hierarchy_1
      region_hierarchy_2
      region_hierarchy_3
      region_hierarchy_4
      region_hierarchy_5
      region_hierarchy_6
      region_hierarchy_7
      region_hierarchy_8
    }
  `,
};

export const PropertyDataBase = {
  fragment: gql`
    fragment PropertyDataBase on PropertyData {
      next_page
      tenant_id
      records {
        ...PropertyDataRecordBase
      }
    }
    ${PropertyDataRecordBase.fragment}
  `,
};
