import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  CircularProgress,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Theme,
  useTheme,
} from '@mui/material';
import { IconButton, Icon } from '@jll-labs/azara-ui-components';
import { useGetUserInfo } from '@apollo-red/hooks';
import { SystemStyleObject } from '@mui/system';
import { useRoutes } from '@routes';
import AppAvatar, { AvatarSize } from '@components/avatar';
import { useLogout } from '@components/okta';
import { TenantFeatureToggle } from '@components/featureToggle';
import UserPersonas from './UserPersonas';

const useStyles = (): Record<
  'menu' | 'item' | 'loader',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    menu: {
      textTransform: 'uppercase',
    },
    item: {
      fontSize: theme.typography.body2.fontSize,
    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  };
};

interface Props {
  fullName: string;
  size: keyof typeof AvatarSize;
  isExpanded?: boolean;
}

const AvatarButton: React.FC<Props> = ({ fullName, size }) => {
  const routes = useRoutes();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [personasAnchorEl, setPersonasAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [didLogOut, setDidLogOut] = React.useState(false);
  const history = useHistory();
  const { logout, loggingOut } = useLogout();
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    user: { user_picture_url },
  } = useGetUserInfo();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAccountSettingsClick = () => {
    history.push(routes.settings.accountSettings.getLink());
    handleClose();
  };

  const handleLogout = () => {
    logout();
    setDidLogOut(true);
  };

  const handlePersonasClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setPersonasAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (didLogOut && !loggingOut) {
      handleClose();
    }
  }, [didLogOut, loggingOut, handleClose]);

  return (
    <div>
      <IconButton
        label="Avatar"
        onClick={handleClick}
        dataTestId="avatarButton-button"
        size="large"
      >
        <AppAvatar
          fullName={fullName}
          size={size}
          src={user_picture_url?.[0]}
        />
      </IconButton>
      <Menu
        sx={styles.menu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={0}
        keepMounted={false}
        disableScrollLock={false}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem onClick={handleAccountSettingsClick} sx={styles.item}>
          {t('myAccount')}
        </MenuItem>
        <TenantFeatureToggle hasFeatures={['demo']}>
          <MenuItem onClick={handlePersonasClick} sx={styles.item}>
            <Grid container={true} alignItems="center">
              {t('myPersonas')}
              <Icon name="ArrowRight" />
            </Grid>
          </MenuItem>
        </TenantFeatureToggle>
        <Divider />
        <MenuItem onClick={handleLogout} sx={styles.item}>
          <div>
            {t('logOut')}
            {loggingOut && (
              <CircularProgress color="primary" size={24} sx={styles.loader} />
            )}
          </div>
        </MenuItem>
      </Menu>
      <UserPersonas
        handleParentClose={handleClose}
        personaAnchorEl={personasAnchorEl}
        setPersonasAnchorEl={setPersonasAnchorEl}
      />
    </div>
  );
};

export default AvatarButton;
