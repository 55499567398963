import * as React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  InsightsAlertTypeFilter,
  useGetUserDefaultTenantId,
  useGetUserAlertRuleSettings,
  useGetTenant,
} from '@apollo-red/hooks';
import {
  alertTypeTranslationKeys,
  insightsAlertTypeFilterToTenantAlertSettingsType,
} from '../utils';
import { useLaunchDarklyTenantAlertSettings } from '@pages/tenantAdmin/configDataSetup/alerts/utils';

const alertTypes: InsightsAlertTypeFilter[] = Object.values(
  InsightsAlertTypeFilter,
);

export const useGetEnabledAlertTypes = () => {
  const tenantId = useGetUserDefaultTenantId();
  const { tenant } = useGetTenant(tenantId);
  const { rules: userRules } = useGetUserAlertRuleSettings();
  const tenantEnabledAlerts = tenant?.tenant_alert_settings ?? [];
  const launchDarklyEnabledAlerts = useLaunchDarklyTenantAlertSettings();

  const enabledAlertTypes = alertTypes
    .filter(
      alertType =>
        tenantEnabledAlerts.find(
          tenantEnabledAlert =>
            tenantEnabledAlert?.id ===
            insightsAlertTypeFilterToTenantAlertSettingsType[alertType],
        )?.alert_enabled,
    )
    .filter(
      alertType =>
        userRules.find(
          userRule =>
            userRule.alert_type ===
            insightsAlertTypeFilterToTenantAlertSettingsType[alertType],
        )?.alert_enabled,
    )
    .filter(
      alertType =>
        launchDarklyEnabledAlerts[alertType] === undefined ||
        launchDarklyEnabledAlerts[alertType],
    )
    .sort((a, b) => a.localeCompare(b));

  return [InsightsAlertTypeFilter.All, ...enabledAlertTypes];
};

export const useAlertTypeOptionsConfig = () => {
  const alertTypes = useGetEnabledAlertTypes();
  return alertTypes.map(alertType => ({
    value: alertType,
    label: alertTypeTranslationKeys[alertType]
      ? alertTypeTranslationKeys[alertType]
      : alertType,
  }));
};

interface Props {
  label: string;
}

export const AlertTypePickListOption: React.FC<Props> = ({ label }) => {
  const { t } = useTranslation();
  return <Box mx={1}>{t(label)}</Box>;
};
