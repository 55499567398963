import * as React from 'react';
import { utils } from '@jll-labs/azara-ui-components';
import { Viz, FlattenedParameter } from '@components/tableau/types';

const { useToggleValue } = utils;

export const useGetParameters = (viz?: Viz) => {
  const [loading, toggleLoading] = useToggleValue(false);
  const [tableauParameters, setTableauParameters] = React.useState<
    FlattenedParameter[]
  >([]);

  React.useEffect(() => {
    if (!viz) {
      return;
    }

    const getParameters = async () => {
      try {
        toggleLoading();

        const parameters = await viz.getWorkbook().getParametersAsync();
        const flattened: FlattenedParameter[] = parameters.map(p => ({
          name: p.getName(),
          selectedValue: p.getCurrentValue(),
          options: p.getAllowableValues(),
          allowableType: p.getAllowableValuesType(),
          dataType: p.getDataType(),
          minValue: p.getMinValue(),
          maxValue: p.getMaxValue(),
          dateStepPeriod: p.getDateStepPeriod(),
          stepSize: p.getStepSize(),
        }));

        setTableauParameters(flattened);
      } catch {
        setTableauParameters([]);
      } finally {
        toggleLoading();
      }
    };

    getParameters();
  }, [toggleLoading, viz]);

  return { loading, tableauParameters };
};
