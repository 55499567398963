import { UserAlertRuleType } from '@apollo-red/hooks';

export const constants = {
  NAME: 'Client',
  HEADERS: ['Name', 'Categories Internal', 'Categories External', 'Status'],
};

export interface FormValues {
  id: string;
  alert_type: UserAlertRuleType;
  internal: string[];
  external: string[];
  alert_enabled: true;
}

export interface TableObject {
  name?: string;
  length?: number;
  selected?: string;
  unselected?: string;
}

export interface RuleOptions {
  value: UserAlertRuleType;
  label: string;
}

export const paramToAlertNameTranslation: {
  [key in UserAlertRuleType]: string;
} = {
  [UserAlertRuleType.WorkorderVolume]: 'workOrderVolume',
  [UserAlertRuleType.FacilitiesExperience]: 'facilitiesExperience',
  [UserAlertRuleType.LeaseEvent]: 'leaseEvent',
  [UserAlertRuleType.Covid_19WorkOrder]: 'covidWorkOrder',
  [UserAlertRuleType.MarketBenchmark]: 'marketBenchmark',
  [UserAlertRuleType.OccupancyEfficiency]: 'occupancyEfficiency',
  [UserAlertRuleType.SubleaseRisk]: 'subleaseRisk',
  [UserAlertRuleType.SameDayWorkOrderCompletion]: 'sameDayWorkOrderCompletion',
};

export const rulesOptions: RuleOptions[] = [
  {
    value: UserAlertRuleType.WorkorderVolume,
    label: paramToAlertNameTranslation[UserAlertRuleType.WorkorderVolume],
  },
  {
    value: UserAlertRuleType.FacilitiesExperience,
    label: paramToAlertNameTranslation[UserAlertRuleType.FacilitiesExperience],
  },
  {
    value: UserAlertRuleType.LeaseEvent,
    label: paramToAlertNameTranslation[UserAlertRuleType.LeaseEvent],
  },
  {
    value: UserAlertRuleType.Covid_19WorkOrder,
    label: paramToAlertNameTranslation[UserAlertRuleType.Covid_19WorkOrder],
  },
  {
    value: UserAlertRuleType.MarketBenchmark,
    label: paramToAlertNameTranslation[UserAlertRuleType.MarketBenchmark],
  },
  {
    value: UserAlertRuleType.OccupancyEfficiency,
    label: paramToAlertNameTranslation[UserAlertRuleType.OccupancyEfficiency],
  },
  {
    value: UserAlertRuleType.SubleaseRisk,
    label: paramToAlertNameTranslation[UserAlertRuleType.SubleaseRisk],
  },
  {
    value: UserAlertRuleType.SameDayWorkOrderCompletion,
    label:
      paramToAlertNameTranslation[UserAlertRuleType.SameDayWorkOrderCompletion],
  },
];

export const externalAlertsList = [UserAlertRuleType.WorkorderVolume];
