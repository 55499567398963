import { InsightsAlertSeverityFilter } from '../insightsAlerts.types';
import { useGetInsightsInboxSettings } from './useGetInsightsInboxSettings';

export const useGetInsightsInboxAlertSeverityFilter = () => {
  const { loading, inboxSettings } = useGetInsightsInboxSettings();

  return {
    alertSeverityFilter:
      inboxSettings?.insights_selected_filters.insights_alert_severity ??
      InsightsAlertSeverityFilter.All,
    loading,
  };
};
