import { isUserSystemAdmin, getUserPrimaryTenantRole } from '@utils/auth';
import { useGetUserInfo } from '../user';
import { useGetUserDefaultTenantId } from '../settings';

export const useIsSystemAdmin = () => {
  const { user } = useGetUserInfo();
  return isUserSystemAdmin(user.user_admin_settings);
};

export const useGetCurrentTenantRole = () => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const roleName = getUserPrimaryTenantRole(
    user.user_tenant_settings,
    tenantId,
  );
  return {
    hasRoleAssigned: !!roleName,
    roleName,
  };
};

export const useHasUserAssignedTenants = () => {
  const { user } = useGetUserInfo();
  return { hasTenantAssigned: user.user_tenant_settings.length > 0 };
};

export const useIsUserActiveForCurrentTenant = () => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const currentTenantSettings = user.user_tenant_settings.find(
    t => t.user_tenant.id === tenantId,
  );
  return !!currentTenantSettings?.user_enabled;
};
