import * as React from 'react';
import { OktaAuth } from '@okta/okta-auth-js';

export const useInitializeOktaTokens = (oktaAuth: OktaAuth) => {
  const [initialized, setInitialized] = React.useState(false);
  const { token, tokenManager } = oktaAuth;

  React.useEffect(() => {
    const initalizeTokens = async () => {
      try {
        const previousTokens = await tokenManager.getTokens();
        const { idToken, refreshToken } = previousTokens;

        if (refreshToken && !idToken) {
          const tokens = await token.renewTokensWithRefresh({}, refreshToken);

          await tokenManager.setTokens(tokens);
        }
      } finally {
        setInitialized(true);
      }
    };

    if (!initialized) {
      initalizeTokens();
    }
  }, [initialized, token, tokenManager]);

  return {
    initialized,
  };
};
