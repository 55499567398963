import * as React from 'react';
import { getElementOffset } from '@utils/htmlEvents';
import { useIfMountedCallback, useRefCallback } from '@utils/various';
import { Size, TableauScalingBehaviors, Viz } from '@components/tableau/types';
import { useTableauViz } from './useTableauViz';

interface UseTableauOptions {
  transform?: boolean;
}

export const fitToContainer = (
  container: HTMLElement | null,
  sheetSize: Size,
) => {
  if (container && sheetSize.behavior === TableauScalingBehaviors.EXACTLY) {
    const containerWidth = container.offsetWidth;

    const {
      maxSize: { width: dashboardWidth },
    } = sheetSize;

    const horizontalRatio = containerWidth / dashboardWidth;
    const verticalAdjustment = Math.max(1, horizontalRatio);

    return `scale(${horizontalRatio / verticalAdjustment})`;
  }
  return '';
};

export const getDashResize = (container, viz) => {
  if (viz) {
    const { sheetSize } = viz.getVizSize();
    return fitToContainer(container, sheetSize);
  }
  return '';
};

export const useTableau = (options?: UseTableauOptions) => {
  const { refElement, setRefElementCb } = useRefCallback();
  const { viz, vizLoading, handleLoadComplete } = useTableauViz();
  const [refElementOffset, setRefElementOffset] = React.useState(
    getElementOffset(refElement),
  );
  const [refElementTransform, setRefElementTransform] = React.useState('');

  const handleResize = () => {
    if (refElement && options && options.transform) {
      setRefElementTransform(getDashResize(refElement, viz) || '');
    }
    setRefElementOffset(getElementOffset(refElement));
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viz]);

  const handleFirstVizSizeKnown = useIfMountedCallback((loadedViz: Viz) => {
    if (refElement && options && options.transform) {
      setRefElementTransform(getDashResize(refElement, loadedViz));
    }
  });

  return {
    refElement,
    setRefElementCb,
    refElementOffset,
    setRefElementOffset,
    refElementTransform,
    setRefElementTransform,
    viz,
    vizLoading,
    handleFirstVizSizeKnown,
    handleLoadComplete,
    handleResize,
  };
};
