import * as React from 'react';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

const useStyles = (): Record<
  'title' | 'subTitle' | 'productName',
  SystemStyleObject<Theme>
> => {
  const theme = useTheme();
  return {
    title: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
    subTitle: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(1),
    },
    productName: {
      // For the sake of JLL branding, this color is hardcoded.
      color: '#ff0000',
      fontSize: theme.typography.h1.fontSize,
      marginLeft: theme.spacing(2),
    },
  };
};
interface Props {
  title: string;
  subtitle?: string;
}

const Title: React.FC<Props> = ({ title, subtitle }) => {
  const styles = useStyles();

  return (
    <div>
      <Typography sx={styles.title}>
        {title}
        <Box component="span" sx={styles.productName}>
          {'Azara'}
        </Box>
      </Typography>
      {subtitle && (
        <Typography variant="h6" sx={styles.subTitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default Title;
