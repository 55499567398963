import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();

  return {
    link: {
      color: theme.palette.text.secondary,
      textDecoration: 'none',
      display: 'block',
      margin: theme.spacing(1, 1, 1, 6),
      borderRadius: '2px',
      padding: theme.spacing(1, 1),
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    highlight: {
      color: theme.palette.action.active,
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
  };
};

interface Props {
  item: { label: string; link: string };
}

const MenuSubItem: React.FC<Props> = ({ item }) => {
  const styles = useStyles();

  return (
    <NavLink
      to={item.link}
      style={styles.link}
      activeStyle={styles.highlight}
      data-testid={`menusubitem-route-navlink-${item.link}`}
    >
      <div data-testid={`menusubitem-route-navlink-container-${item.label}`}>
        {item.label}
      </div>
    </NavLink>
  );
};

export default MenuSubItem;
