import * as React from 'react';
import Tiles from '@components/tiles';
import { BaseDashboardsProps } from '../types';
import BaseDashboardsPageWrapper from './BaseDashboardsPageWrapper';

/**
 * This component serves as a base for all types of dashboards rendering tiles
 */
const BaseDashboards: React.FC<BaseDashboardsProps> = ({
  reports,
  dashboardRoutes,
  useSaveHandler,
  isPATDashboard,
  showKeyMetrics,
  drag,
  categoryId,
  title,
  showAddReports,
  breadcrumbsTitle,
  breadcrumbsParts,
  autoOverflow,
  dashboardNotFound,
}) => {
  const handleDeleteTile = () => () => {
    /* Mock mutation */
  };

  return (
    <BaseDashboardsPageWrapper
      breadcrumbsTitle={breadcrumbsTitle}
      breadcrumbsParts={breadcrumbsParts}
      title={title}
      useSaveHandler={useSaveHandler}
      isPATDashboard={isPATDashboard}
      autoOverflow={autoOverflow}
      showKeyMetrics={showKeyMetrics}
      drag={drag}
      showAddReports={showAddReports}
    >
      {({ displayMode, deleteMode }) => {
        if (reports.length === 0 && dashboardNotFound) {
          return dashboardNotFound;
        }

        return (
          <Tiles
            reports={reports}
            deleteMode={deleteMode}
            displayMode={displayMode}
            categoryId={categoryId}
            handleDeleteTile={handleDeleteTile}
            draggable={drag}
            dashboardRoutes={dashboardRoutes}
          />
        );
      }}
    </BaseDashboardsPageWrapper>
  );
};

export default BaseDashboards;
