import { ReleaseBase, TenantBase } from '@apollo-red/fragments';
import { gql } from '@apollo/client';

export const GET_QA_TENANTS = gql`
  query getQaTenants($tenant_qa_type: TenantQaType!) {
    getQaTenants(tenant_qa_type: $tenant_qa_type) {
      ...TenantBase
    }
  }
  ${TenantBase.fragment}
`;

export const GET_RELEASES = gql`
  query getReleases {
    getReleases {
      ...ReleaseBase
    }
  }
  ${ReleaseBase.fragment}
`;
