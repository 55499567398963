import * as React from 'react';
import {
  InsightsAlertScope,
  InsightsAlertWorkOrderVolumeTemplateData,
  InsightsAlertInbox,
  InsightsAlertType,
} from '@apollo-red/hooks';
import { usePendoTrackInsightsAlertEvent } from '@utils/pendo';
import { getInternalLink } from './utils';
import OpenAlertReport from './OpenAlertReport';
import { useAlertTextStyles } from './useAlertTextStyles';
import { useRoutes } from '@routes';

interface WOVExternalAlertProps {
  threadId: string;
  template: InsightsAlertWorkOrderVolumeTemplateData;
  boldText?: boolean;
  inboxType?: InsightsAlertInbox;
  alertType: InsightsAlertType;
}

const WOVExternalAlert: React.FC<WOVExternalAlertProps> = ({
  template,
  threadId,
  inboxType,
  boldText,
  alertType,
}) => {
  const { property_name, trade } = template;
  const routes = useRoutes();
  const classes = useAlertTextStyles({ boldText });
  const { trackInsightsAlertEvent } = usePendoTrackInsightsAlertEvent();

  return (
    <OpenAlertReport>
      <div className={classes.fontWeight}>
        <b>{trade} </b>volume at your <b>{property_name} property </b> is
        <b> substantially above the {trade} volume </b>
        at similar properties managed by JLL.
        <br /> <br />
        {inboxType && (
          <>
            See notification details{' '}
            <a
              onClick={() => {
                trackInsightsAlertEvent('alertShowNotificationDetails', {
                  alertThreadId: threadId ?? '',
                  alertType: alertType ?? '',
                });
              }}
              href={getInternalLink(
                threadId,
                inboxType,
                InsightsAlertScope.External,
                routes,
              )}
              className={classes.anchorText}
            >
              here
            </a>
          </>
        )}
      </div>
    </OpenAlertReport>
  );
};

export default WOVExternalAlert;
