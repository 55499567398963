import { ReportControlType } from '@apollo-red/hooks';
import { PinParameter, ParameterExtended } from '../../types';
import { getSelectedOptionFromStaticData } from '../viz';

export const initialParameterState: PinParameter = {
  filter_id: '',
  name: '',
  selectedOption: {
    label: '',
    value: '',
  },
  isPinned: false,
  selectedValue: '',
};

export const getInitialParameterState = (
  parameter: ParameterExtended,
  initialParameter?: PinParameter,
) => {
  return (
    initialParameter || {
      ...initialParameterState,
      name: parameter.name,
      filter_id: parameter.filter_id ?? '',
      selectedOption:
        parameter.control_type === ReportControlType.SingleSelectListDef
          ? getSelectedOptionFromStaticData(parameter.static_data) || null
          : null,
      selectedValue: parameter.selectedValue.value,
    }
  );
};
