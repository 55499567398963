import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '@apollo-red/hooks/queries';
import { SET_INSIGHTS_INBOX_REMOVE_RECENT_ALERT } from '@apollo-red/mutations';
import { useGetUserDefaultTenantId } from '../../settings';
import { useGetUserInfo } from '../../user';
import { InsightsAlertInbox } from '../insightsAlerts.types';

export const useSetInsightsInboxRemoveRecentAlert = (
  inboxType: InsightsAlertInbox,
) => {
  const { user } = useGetUserInfo();
  const tenantId = useGetUserDefaultTenantId();
  const [setInboxRemoveRecentAlert] = useAppMutation<
    SchemaTypes.SetInsightsInboxRemoveRecentAlertMutation,
    SchemaTypes.SetInsightsInboxRemoveRecentAlertMutationVariables
  >(SET_INSIGHTS_INBOX_REMOVE_RECENT_ALERT);

  return {
    setInboxRemoveRecentAlert: () => {
      try {
        setInboxRemoveRecentAlert({
          variables: {
            userId: user.id,
            tenantId,
            inboxType,
          },
        });
      } catch (error) {
        // Recently selected alert is no longer available.
      }
    },
  };
};
