import { PROMOTE_RELEASE } from '@apollo-red/mutations';
import * as SchemaTypes from '@apollo-red/schema/generated/operations';
import { useAppMutation } from '../queries';
import { PromoteReleaseInput } from './types';

export const usePromoteRelease = () => {
  const [promoteRelease] = useAppMutation<
    SchemaTypes.PromoteReleaseMutation,
    SchemaTypes.PromoteReleaseMutationVariables
  >(PROMOTE_RELEASE);

  return {
    promoteRelease: (id: string, args: PromoteReleaseInput) =>
      promoteRelease({ variables: { data: { ...args }, where: { id } } }),
  };
};
