import {
  ReportControlType,
  ReportControlStaticListItem,
  ReportInteractionSection,
  ReportSectionType,
  ReportControl,
  BaselineReportControl,
  BaselineReportInteractionSection,
} from '@apollo-red/hooks';
import { WithoutTypename } from '@utils/various';
import {
  FilterUpdateType,
  FlattenedFilter,
  FlattenedParameter,
  ParameterValueType,
} from '@components/tableau/types';

export interface FilterValueMapping {
  [key: string]: string;
}

export type ParameterValueMapping = FilterValueMapping;

export interface ReportControlStaticListItemExtended
  extends ReportControlStaticListItem {
  isPreselectedUniversalFilterValue?: boolean;
}

export interface PinParameter {
  filter_id: string;
  name: string;
  selectedOption: ReportControlStaticListItem | null;
  isPinned: boolean;
  selectedValue: ParameterValueType;
  dataType?: 'number' | 'date' | 'string';
}

export interface PinFilter {
  filter_id: string;
  name: string;
  isPinned: boolean;
  selectedValues?: ReportControlStaticListItemExtended[];
  min?: number | Date;
  max?: number | Date;
  isExcluded?: boolean;
  staticData?: ReportControlStaticListItemExtended[];
  isUniversalFilter?: boolean;
}

export interface FilterExtended extends FlattenedFilter {
  id: string;
  filter_id: string;
  label: string;
  name: string;
  control_type: ReportControlType;
  static_data: ReportControlStaticListItemExtended[];
  cascade_update: boolean;
  sort_index: number;
}

export interface ParameterExtended extends FlattenedParameter {
  id: string;
  filter_id: string;
  label: string;
  name: string;
  control_type: ReportControlType;
  static_data: ReportControlStaticListItem[];
  cascade_update: boolean;
  sort_index: number;
}

export interface ApplyFilterProps {
  (
    name: string,
    values: string[],
    updateType: FilterUpdateType,
    isExcludeMode: boolean,
  ): void;
}

export interface RangeOption {
  max?: Date | number;
  min?: Date | number;
}

type ReportInteractionSectionWithoutTypenameAndReportControl = Omit<
  ReportInteractionSection,
  '__typename' | 'controls'
>;

type BaselineReportInteractionSectionWithoutTypenameAndReportControl = Omit<
  BaselineReportInteractionSection,
  '__typename' | 'controls'
>;

export interface ReportControlWithoutTypename
  extends WithoutTypename<ReportControl> {
  filter_id?: string;
}

export type BaselineReportControlWithoutTypename =
  WithoutTypename<BaselineReportControl>;

export interface InteractionSection
  extends ReportInteractionSectionWithoutTypenameAndReportControl {
  controls: ReportControlWithoutTypename[];
}

export interface BaselineInteractionSection
  extends BaselineReportInteractionSectionWithoutTypenameAndReportControl {
  controls: BaselineReportControlWithoutTypename[];
}

export type ReportFiltersInteractionSection =
  | BaselineInteractionSection
  | InteractionSection;

export type ReportFiltersControl =
  | BaselineReportControlWithoutTypename
  | ReportControlWithoutTypename;

export interface ToolbarSettings {
  help?: boolean;
  save?: boolean;
  load?: boolean;
  csv?: boolean;
  image?: boolean;
  pdf?: boolean;
  share?: boolean;
  favorites?: boolean;
  revert?: boolean;
}

export interface Offset {
  height: number;
  width: number;
}

export interface VizAndFloatingAreaOffset {
  vizContent: Offset;
  floatingContent: Offset;
}

export enum ReportType {
  CoreDashboard = 'CoreDashboard',
  PATDashboard = 'PATDashboard',
}

export interface InteractionSections {
  [ReportSectionType.Filter]?: PinFilter[];
  [ReportSectionType.Parameter]?: PinParameter[];
}

export type ReportInteractionSections = {
  [key in ReportType]?: InteractionSections;
};

export interface ReportFilterData {
  [key: string]: ReportInteractionSections;
}
